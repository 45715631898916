import { gql } from '@apollo/client';
import {
  NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS,
  RECIPIENTS_FILTER_OPTIONS,
} from 'components/Newsletter/localConstantsAndHelpers';
import { NEWSLETTER_FRAGMENT } from 'graphql/fragments';
import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';

export const GET_VENDOR_NEWSLETTERS = gql`
  query getNewsletters($ids: [ID!], $pageSize: Int, $after: Int) {
    getNewsletters(ids: $ids, pageSize: $pageSize, after: $after) {
      page
      totalPages
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
      totalResults
      results {
        _id
        title
        status
        subject
        sentAt
        statistics {
          Send
          Delivery
          Reject
          Open
          Click
          Bounce
          Complaint
        }
      }
    }
  }
`;

export const GET_VENDOR_NEWSLETTER_BY_ID = gql`
  ${NEWSLETTER_FRAGMENT}
  query getNewsletter($id: ID!) {
    getNewsletter(_id: $id) {
      ...NewsletterFragment
    }
  }
`;

export const GET_VENDOR_NEWSLETTER_FOR_RECIPIENTS_TABLE = gql`
  query getNewsletter($id: ID!) {
    getNewsletter(_id: $id) {
      _id
      title
      status
      subject
      statistics {
        Send
        Delivery
        Reject
        Open
        Click
        Bounce
        Complaint
      }
      assignedGroups {
        _id
        name
        color
        vendorSpecificId
        vendorId
        translations {
          lang
          value
        }
      }
      assignedToAll
      excludedGroups {
        _id
        name
        color
        vendorSpecificId
        vendorId
        translations {
          lang
          value
        }
      }
    }
  }
`;

export const GET_NEWSLETTER_RECIPIENTS = gql`
  query getNewsletterRecipients(
    $id: ID!
    $search: String
    $recipientAssignmentStatus: NewsletterRecipientAssignmentStatus  = ${NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED}
    $filter: NewsletterRecipientFilter = ${RECIPIENTS_FILTER_OPTIONS.NONE}
    $pageSize: Int = ${ROWS_PER_PAGE_OPTIONS[0]}
    $after: Int
  ) {
    getNewsletterRecipients(
      _id: $id
      search: $search
      recipientAssignmentStatus: $recipientAssignmentStatus
      filter: $filter
      pageSize: $pageSize
      after: $after
    ) {
      page
      totalPages
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
      totalResults
      results {
        email
        name
        businessName
        vendorClientId
        contactId
        accountId

        contactStatus {
          hasLists
          hasDeliveryDays
          isDisabled
          isUnsubscribed
          isValidEmail
          isChefslistEmail
          isValidContact
        }

        sendOutStatus {
          deliveryStatus
          lastUpdate
          events {
            _id
            eventType
            timestamp
            reject {
              reason
            }
            bounce {
              bounceType
              bounceSubType
            }
            complaint {
              arrivalDate
              complaintFeedbackType
              userAgent
            }
            deliveryDelay {
              delayType
              expirationTime
            }
            open {
              ipAddress
              userAgent
            }
            click {
              ipAddress
              userAgent
              link
            }
          }
        }
      }
    }
  }
`;
