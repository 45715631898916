import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useMutation, useQuery } from '@apollo/client';
import { EDIT_CAMPAIGN_RECIPIENTS } from 'graphql/mutation/campaigns';
import { GET_CUSTOMER_GROUPS } from 'graphql/query/groups';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { GET_CAMPAIGN_RECIPIENTS } from 'graphql/query/campaigns';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddBusinessIcon from '@material-ui/icons/GroupAdd';
import AddPersonIcon from '@material-ui/icons/PersonAddOutlined';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import CustomerGroupModalSelector from 'components/shared/CustomerGroupModalSelector';
import useMixPanel from 'helpers/useMixPanel';
import replaceDelimiters from 'helpers/replaceDelimiters';
import { EDIT_RECIPIENT_ACTIONS } from './campaignConstants';

const useStyles = makeStyles(
  ({ spacing, palette, breakpoints, typography }) => ({
    tableActionButtons: {
      minWidth: 130,
      height: 45,
      marginRight: spacing(1.5),
      border: palette.border.lightGrey,
      color: palette.secondary.darkGray,
      fontWeight: typography.fontWeightRegular,
    },
    activeActionButton: {
      color: palette.common.white,
      background: palette.primary.main,
      '&:hover': {
        background: palette.primary.light,
      },
    },
    defaultIcon: {
      color: palette.primary.main,
    },
    activeIcon: {
      color: palette.common.white,
    },
    headerAdditionalActionsWrapper: {
      minHeight: 85,
      borderBottom: palette.border.lightGrey,
    },
    headerAdditionalActionsContainer: {
      margin: spacing(2.5, 0),
    },
    addActionsInputContainer: {
      height: 85,
      backgroundColor: palette.background.container,
      padding: spacing(2.5, 3, 2.5, 1.5),
    },
    addActionsInput: {
      width: 340,
      height: 45,
      fontSize: 14,
      background: palette.background.paper,
      border: palette.border.lightModeOnly,
      [breakpoints.down('sm')]: {
        width: '100%',
        fontSize: 12,
      },
      [breakpoints.up('lg')]: {
        width: 400,
        fontSize: 16,
      },
      '&:hover': {
        border: `1px solid ${palette.primary.main}`,
      },
    },
    addActionsNotchedInput: {
      border: 'none',
    },
    addActionsConfirm: {
      height: 45,
      color: palette.text.inverted,
      margin: spacing(0, 2.5),
      textTransform: 'capitalize',
      minWidth: 192,
      boxShadow: 'none',
    },
    orTitle: {
      fontStyle: 'italic',
      margin: spacing(0, 2),
      color: palette.secondary.darkGray,
    },
  })
);

const ALL_RESTAURANTS = 'ALL';
export default function ManageRecipientsHeaderActions({
  campaignId,
  assignedToAll,
  assignedToGroups,
  queryState,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const mixPanel = useMixPanel();

  const [editCampaignRecipients] = useMutation(EDIT_CAMPAIGN_RECIPIENTS);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [actionType, setActionType] = React.useState(
    EDIT_RECIPIENT_ACTIONS.ASSIGN
  );
  const [inputValue, setInputValue] = React.useState('');
  const [selectedCustomerGroups, setSelectedCustomerGroups] = React.useState(
    []
  );

  const { data } = useQuery(GET_CUSTOMER_GROUPS);

  const customerGroups = (data && data.allCustomerGroups) || [];

  const handleChangeInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeGroupSelector = (groupId) => () => {
    setSelectedCustomerGroups(
      selectedCustomerGroups.includes(groupId)
        ? selectedCustomerGroups.filter((group) => group !== groupId)
        : selectedCustomerGroups.concat(groupId)
    );
  };

  const handleModeTypeSelector = (type) => () => {
    if (type === actionType) {
      setActionType(null);
    } else {
      setActionType(type);
    }
  };

  React.useEffect(() => {
    setInputValue('');
  }, [actionType]);

  React.useEffect(() => {
    const assignsToGroup = assignedToGroups?.map((group) => group._id) || [];
    setSelectedCustomerGroups(
      assignedToAll ? [ALL_RESTAURANTS].concat(assignsToGroup) : assignsToGroup
    );
  }, [assignedToAll, assignedToGroups]);

  const editCampaignAssignment = async () => {
    const assignmentOptions = {
      assignedToCustomers: replaceDelimiters(inputValue),
      connectionIds: [],
      groups: selectedCustomerGroups.filter((type) => type !== ALL_RESTAURANTS),
      assignedToAll: selectedCustomerGroups.includes(ALL_RESTAURANTS),
    };
    try {
      await editCampaignRecipients({
        variables: {
          ...assignmentOptions,
          campaignId,
          actionType,
        },
        refetchQueries: [{ query: GET_CAMPAIGN_RECIPIENTS, variables: { ...queryState } }]
        // update: (
        //   _,
        //   {
        //     data: {
        //       editCampaignRecipients: { assignedToCustomers, failedCustomerIds },
        //     },
        //   }
        // ) => {
        //   if (failedCustomerIds.length) {
        //     setNotification({
        //       variables: {
        //         timeout: 4000,
        //         message: t('campaigns.error add customers', {
        //           customers: failedCustomerIds.join(', '),
        //           count: failedCustomerIds.length,
        //         }),
        //         type: NOTIFICATION_STATUS.ALERT,
        //         isOpen: true,
        //       },
        //     });
        //   } else {
        //     setNotification({
        //       variables: {
        //         timeout: 4000,
        //         message: t('campaigns.success add customers', {
        //           customers: assignedToCustomers.join(', '),
        //           count: assignedToCustomers.length,
        //         }),
        //         type: NOTIFICATION_STATUS.SUCCESS,
        //         isOpen: true,
        //       },
        //     });
        //   }
        // },
      });
      mixPanel.track(`${actionType} CUSTOMERS TO CAMPAIGN`, {
        ...assignmentOptions,
        campaignId,
        actionType,
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error?.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleConfirm = () => {
    // NOTE check input on special chars and shout an error if so
    const isValidInput = !/[~`!#$%\\^&+=\\[\]\\';/{}|\\":<>\\?]/g.test(
      inputValue
    );

    if (isValidInput) {
      editCampaignAssignment();
    } else {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.errors.special chars'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  return (
    <Grid
      className={classes.headerAdditionalActionsWrapper}
      container
      wrap="nowrap"
      direction="column"
    >
      <Grid
        className={classes.headerAdditionalActionsContainer}
        container
        wrap="nowrap"
        alignItems="center"
      >
        <Button
          className={classNames(
            classes.tableActionButtons,
            actionType === EDIT_RECIPIENT_ACTIONS.ASSIGN &&
              classes.activeActionButton
          )}
          variant="outlined"
          startIcon={
            <AddBusinessIcon
              fontSize="large"
              className={
                actionType === EDIT_RECIPIENT_ACTIONS.ASSIGN
                  ? classes.activeIcon
                  : classes.defaultIcon
              }
            />
          }
          onClick={handleModeTypeSelector(EDIT_RECIPIENT_ACTIONS.ASSIGN)}
        >
          {t('campaigns.add business')}
        </Button>
        <Button
          className={classNames(
            classes.tableActionButtons,
            actionType === EDIT_RECIPIENT_ACTIONS.REMOVE &&
              classes.activeActionButton
          )}
          variant="outlined"
          startIcon={
            <AddPersonIcon
              fontSize="large"
              className={
                actionType === EDIT_RECIPIENT_ACTIONS.REMOVE
                  ? classes.activeIcon
                  : classes.defaultIcon
              }
            />
          }
          onClick={handleModeTypeSelector(EDIT_RECIPIENT_ACTIONS.REMOVE)}
        >
          {t('campaigns.remove business')}
        </Button>
      </Grid>

      <Grid
        className={classes.addActionsInputContainer}
        container
        alignItems="center"
        wrap="nowrap"
      >
        <TextField
          InputProps={{
            classes: {
              root: classes.addActionsInput,
              notchedOutline: classes.addActionsNotchedInput,
            },
          }}
          value={inputValue}
          onChange={handleChangeInput}
          variant="outlined"
          placeholder={t('campaigns.assignment placeholder')}
        />
        <Typography className={classes.orTitle} variant="body2">
          {t('lists.or')}
        </Typography>
        <CustomerGroupModalSelector
          customerGroups={customerGroups}
          selectedCustomerGroups={selectedCustomerGroups}
          handleChangeGroupSelector={handleChangeGroupSelector}
          handleConfirm={handleConfirm}
          withTextField
          withAllRestaurantsOption
        />
        <Button
          onClick={handleConfirm}
          className={classes.addActionsConfirm}
          variant="contained"
          color="secondary"
        >
          {t('common.confirm')}
        </Button>
      </Grid>
    </Grid>
  );
}

ManageRecipientsHeaderActions.propTypes = {
  campaignId: PropTypes.string.isRequired,
  assignedToAll: PropTypes.bool,
  assignedToGroups: PropTypes.array,
  queryState: PropTypes.object.isRequired,
};

ManageRecipientsHeaderActions.defaultProps = {
  assignedToAll: false,
  assignedToGroups: [],
};
