import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FieldArray, getIn } from 'formik';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

import ProductPackaging from 'components/ProductsTable/ProductPackaging';
import { calculateUnitPrice } from 'helpers/utils';
import EmptyTableField from 'components/shared/EmptyTableField';
import ProductInput from 'components/ProductsTable/ProductVariantInput';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 60,
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    maxWidth: 150,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  tableCellPanelOpen: {
    backgroundColor: theme.palette.background.container,
    borderBottom: 'none',
  },
  deleteButton: {
    margin: theme.spacing(0.5),
  },
}));

export default function ProductTableRowVariant({
  isEditMode,
  variants,
  isPanelOpen,
  setFieldValue,
}) {
  const classes = useStyles();

  const cellClasses = classNames(
    classes.tableCell,
    isPanelOpen && classes.tableCellPanelOpen
  );

  return (
    <>
      <FieldArray name="product variants">
        {({ form }) => (
          <>
            {variants.slice(1).map((variant, index) =>
              (isEditMode ? (
                <TableRow key={variant._id} className={classes.tableBodyRow}>
                  <TableCell className={cellClasses} colSpan={4} />
                  <TableCell className={cellClasses}>
                    <ProductInput
                      name={`variants[${index + 1}].packaging`}
                      value={variant.packaging}
                      setFieldValue={setFieldValue}
                      error={getIn(
                        form.errors,
                        `variants[${index + 1}].packaging`
                      )}
                    />
                  </TableCell>
                  <TableCell className={cellClasses} colSpan={2} />
                  <TableCell className={cellClasses}>
                    <ProductInput
                      name={`variants[${index + 1}].baseUnit`}
                      value={variant.baseUnit}
                      setFieldValue={setFieldValue}
                      error={getIn(
                        form.errors,
                        `variants[${index + 1}].baseUnit`
                      )}
                    />
                  </TableCell>
                  <TableCell className={cellClasses}>
                    <ProductInput
                      name={`variants[${index + 1}].unitsInPackaging`}
                      value={variant.unitsInPackaging}
                      setFieldValue={setFieldValue}
                      error={getIn(
                        form.errors,
                        `variants[${index + 1}].unitsInPackaging`
                      )}
                      type="number"
                    />
                  </TableCell>
                  <TableCell className={cellClasses}>
                    <ProductInput
                      name={`variants[${index + 1}].price`}
                      value={variant.price}
                      setFieldValue={setFieldValue}
                      isPrice
                      error={getIn(form.errors, `variants[${index + 1}].price`)}
                      type="number"
                    />
                  </TableCell>
                  <TableCell className={cellClasses}>
                    {calculateUnitPrice(
                      variant.price,
                      variant.unitsInPackaging
                    )}
                  </TableCell>
                  <TableCell className={cellClasses} colSpan={1} />
                  <TableCell align="right" className={cellClasses} colSpan={1}>
                    <IconButton
                      className={classes.deleteButton}
                      size="small"
                      onClick={() =>
                        setFieldValue(
                          'variants',
                          variants.filter(x => x._id !== variant._id)
                        )}
                    >
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={variant._id} className={classes.tableBodyRow}>
                  <TableCell className={cellClasses} colSpan={4} />
                  <TableCell className={cellClasses}>
                    <ProductPackaging unit={variant.packaging} />
                  </TableCell>
                  <TableCell className={cellClasses} />
                  <TableCell className={cellClasses}>
                    <Typography align="center">
                      {variant.description}
                    </Typography>
                  </TableCell>
                  <TableCell className={cellClasses}>
                    {variant.baseUnit ? (
                      <Typography variant="body2" align="center">
                        {variant.baseUnit}
                      </Typography>
                    ) : (
                      <EmptyTableField align="center" />
                    )}
                  </TableCell>
                  <TableCell className={cellClasses}>
                    {variant.unitsInPackaging ? (
                      <Typography variant="body2" align="center">
                        {variant.unitsInPackaging}
                      </Typography>
                    ) : (
                      <EmptyTableField align="center" />
                    )}
                  </TableCell>
                  <TableCell className={cellClasses}>
                    {variant.price ? (
                      <Typography variant="body2" align="center">
                        €{variant.price.toFixed(2)}
                      </Typography>
                    ) : (
                      <EmptyTableField align="center" />
                    )}
                  </TableCell>
                  <TableCell className={cellClasses}>
                    {calculateUnitPrice(
                      variant.price,
                      variant.unitsInPackaging
                    )}
                  </TableCell>
                  <TableCell className={cellClasses} colSpan={2} />
                </TableRow>
              ))
            )}
          </>
        )}
      </FieldArray>
    </>
  );
}

ProductTableRowVariant.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  variants: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
