export const paginatedRead = ({ existing, pageSize, fieldName }) => {
  if (!fieldName) {
    return console.error('ERROR: missed fieldName in paginatedRead');
  }

  return existing
    ? {
      ...existing,
      [fieldName]: existing?.[fieldName]?.slice(0, pageSize * existing.page)
    }
    : undefined;
};

export const paginatedMerge = ({
  existing,
  incoming,
  pageSize,
  after,
  fieldName,
}) => {
  if (!fieldName) {
    return console.error('ERROR: missed fieldName in paginatedMerge');
  }

  const merged = existing?.[fieldName] ? existing?.[fieldName]?.slice(0) : [];
  const page = after - 1;
  for (let i = 0; i < incoming?.[fieldName]?.length; i += 1) {
    merged[pageSize * page + i] = incoming?.[fieldName][i];
  }

  return {
    ...incoming,
    [fieldName]: merged,
  };
};
