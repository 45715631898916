import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import classNames from 'classnames';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  UPLOAD_SUB_CATEGORY_IMAGE,
  REMOVE_SUB_CATEGORY_IMAGE,
} from 'graphql/mutation/categories';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';

const useStyles = makeStyles(theme => ({
  input: {
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    paddingTop: '38px !important'
  },
  actionButton: {
    marginTop: theme.spacing(3.5),
    height: 50
  },
  imageContainer: {
    padding: `${theme.spacing(1.5)}px 0px ${theme.spacing(4.5)}px`
  },
  imagePreviewContainer: {
    height: 110,
    width: '100%',
    borderRadius: 4,
    border: theme.palette.border.darkGrey,
    position: 'relative'
  },
  noImage: {
    background: theme.palette.grey[50],
    color: '#DADADA'
  },
  dragNDropContainer: {
    height: 145,
    border: '1.5px dashed #D8D8D8',
    borderRadius: 4,
    outline: 'none',
    '&:focus': {
      border: `1.5px dashed ${theme.palette.primary.main}`
    }
  },
  uploadPhotoButton: {
    color: theme.palette.secondary.darkGray,
    textTransform: 'capitalize'
  },
  imagePreview: {
    height: 110,
    maxWidth: '100%',
    width: 'inherit',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  deleteButton: {
    position: 'absolute',
    top: -theme.spacing(2),
    right: -theme.spacing(2),
    width: 40,
    height: 40
  }
}));

export default function SubCategoryModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [uploadSubCategoryImage] = useMutation(UPLOAD_SUB_CATEGORY_IMAGE);
  const [removeSubCategoryImage] = useMutation(REMOVE_SUB_CATEGORY_IMAGE);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [files, setFiles] = React.useState([]);

  const { isOpen, handleClose, editCategory, setEditCategory } = props;

  const { getRootProps, getInputProps, open: openDragNDrop } = useDropzone({
    accept: 'image/*',
    onDrop: async acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    noClick: true,
    multiple: false
  });

  const uploadImage = async name => {
    const uploadedFile = files && files[0];
    const options = {
      maxSizeMb: 0.5,
      maxWidthOrHeight: 768,
      useWebWorker: true
    };
    const compressedFile = await imageCompression(uploadedFile, options);
    try {
      await uploadSubCategoryImage({
        variables: { file: compressedFile, categoryTitle: name },
        update: (
          store,
          {
            data: {
              uploadVendorSubCategoryImage: { name: title, image }
            }
          }
        ) => {
          setEditCategory({ title, image });
        }
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true
        }
      });
    }
  };

  const handleUpdateSubCategory = async () => {
    const { name } = editCategory;
    try {
      if (name) {
        if (files && files[0]) {
          await uploadImage(name);
        }

        setNotification({
          variables: {
            timeout: 4000,
            message: t('categories.category updated success'),
            type: NOTIFICATION_STATUS.SUCCESS,
            isOpen: true
          }
        });
      }
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true
        }
      });
    }
    handleClose();
    setFiles([]);
  };

  const handleDeletePhoto = async () => {
    if (!editCategory || !editCategory?.image) {
      setFiles([]);
      return;
    }
    if (editCategory?.image) {
      try {
        await removeSubCategoryImage({
          variables: { categoryTitle: editCategory?.name },
          update: (
            store,
            {
              data: {
                deleteVendorSubCategoryImage: { name: title, image }
              }
            }
          ) => {
            setEditCategory({ title, image });
            editCategory.image = '';
            setFiles([]);
            setNotification({
              variables: {
                timeout: 4000,
                message: t('products.image modal.delete message'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true
              }
            });
          }
        });
      } catch (error) {
        console.error('ERROR', error);
        setNotification({
          variables: {
            timeout: 4000,
            message: error.message,
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true
          }
        });
      }
    }
  };

  const imgSrc = (!!files.length && files[0].preview) || editCategory?.image;

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
        setFiles([]);
      }}
      modalTitle={t('categories.update category')}
    >
      <Grid
        className={classes.imageContainer}
        container
        alignItems="center"
        justifyContent="center"
      >
        {imgSrc && (
          <Grid
            container
            className={classNames(
              classes.imagePreviewContainer,
              !imgSrc && classes.noImage
            )}
            alignItems="center"
            justifyContent="center"
          >
            <Fab
              color="primary"
              className={classes.deleteButton}
              onClick={handleDeletePhoto}
              disabled={!imgSrc}
            >
              <DeleteIcon />
            </Fab>
            {imgSrc ? (
              <img
                className={classes.imagePreview}
                src={imgSrc}
                alt="product"
              />
            ) : (
              <ImageOutlinedIcon />
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        className={classes.dragNDropContainer}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        wrap="nowrap"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography variant="subtitle2" color="textSecondary">
          {t('products.image modal.drag and drop')}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t('products.image modal.or')}
        </Typography>
        <Button
          className={classes.uploadPhotoButton}
          variant="outlined"
          startIcon={<UploadIcon color="primary" />}
          onClick={openDragNDrop}
        >
          {t('products.image modal.upload photo')}
        </Button>
      </Grid>

      <Button
        className={classes.actionButton}
        fullWidth
        onClick={handleUpdateSubCategory}
        variant="contained"
        color="primary"
      >
        {t('deals.submit')}
      </Button>
    </ModalLayout>
  );
}

SubCategoryModal.defaultProps = {
  editCategory: {}
};

SubCategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  editCategory: PropTypes.object,
  setEditCategory: PropTypes.func.isRequired
};
