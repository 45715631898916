/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const GET_VENDOR_CATEGORIES = gql`
  query vendorCategories($vendorId: ID!) {
    vendorCategories(vendorId: $vendorId) {
      _id
      name
      vendorId
      image
      icon
      totalProducts
      mappedCategories
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query existingProductCategories($vendorId: ID) {
    existingProductCategories(vendorId: $vendorId) 
  }
`;

export const GET_IMAGES_FOR_PRODUCT_CATEGORIES = gql`
  query existingProductCategoriesWithImages($vendorId: ID!) {
    existingProductCategoriesWithImages(vendorId: $vendorId) {
      title
      image
    }
  }
`;
