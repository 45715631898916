import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';

import {
  useApolloClient,
  useMutation,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  GET_USER,
  GET_VENDOR_ACCOUNTS,
  IMPERSONATE_ACCOUNT,
} from 'graphql/query/user';
import { CHANGE_IMPERSONATE_ID } from 'graphql/mutation/user';
import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';

import { makeStyles, useTheme } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import WarningIcon from '@material-ui/icons/ErrorOutline';
// NOTE: another way how to use svg with Electron is to make them as React Component by hands if needed :)
import { ReactComponent as DarkThemeLogo } from 'assets/LogoWhite.svg';
import { ReactComponent as LightThemeLogo } from 'assets/Logo.svg';
import { ReactComponent as NoResultsLightThemeImage } from 'assets/noResults.svg';
import { ReactComponent as NoResultsDarkThemeImage } from 'assets/noResultsDark.svg';

const useStyles = makeStyles(theme => ({
  logoContainer: {
    width: 'auto',
    height: 60,
    borderRight: 'none',
    borderLeft: theme.palette.border.lightGrey,
    paddingLeft: theme.spacing(4.5),
    paddingRight: 0,
    marginRight: 0,
  },
  logo: {
    height: 36,
    width: 36,
  },
  noResultsImage: {
    width: 380,
    height: 280,
  },
  supplierContainer: {
    width: 520,
    padding: theme.spacing(4.5),
  },
  supplierTitle: {
    paddingBottom: theme.spacing(2),
    borderBottom: theme.palette.border.lightGrey,
  },
  supplierSearch: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
    border: theme.palette.border.lightGrey,
    borderRadius: 4,
  },
  accountButton: {
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
  },
  warnBadge: {
    position: 'absolute',
    // bottom: -80,
    left: 80,
    borderRadius: '50%',
    zIndex: 10000,
    height: 48,
    width: 48,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: `$badgeAnimation 1500ms ${theme.transitions.easing.easeInOut} infinite alternate`,
  },
  '@keyframes badgeAnimation': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1.2)',
    },
  },
}));

export default function HeaderLogo({ isAdmin }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const client = useApolloClient();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchPhrase, setSearchPhrase] = React.useState('');

  const [handleChangeImpersonateId] = useMutation(CHANGE_IMPERSONATE_ID);

  const [
    getVendorAccounts,
    { data, loading: accountsLoading, error: accountError },
  ] = useLazyQuery(GET_VENDOR_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const { data: userData } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-only',
  });

  const accountsData = data && data.allVendorAccounts;
  const initAccountId = userData?.user?.userData.accountId;

  const {
    data: {
      impersonate: { impersonateId },
    },
  } = useQuery(IMPERSONATE_ACCOUNT);

  const refreshData = () => {
    client.reFetchObservableQueries();
    setAnchorEl(null);
  };

  const handleChangeUserAccount = async (id, name) => {
    activeAccountIdVar(id);
    await handleChangeImpersonateId({ variables: { impersonateId: id, name } });
    refreshData();
  };

  const handleBackToAdminAccount = async () => {
    activeAccountIdVar(initAccountId);
    history.replace({ pathname: history.location?.pathname || '/' });
    await handleChangeImpersonateId({
      variables: { impersonateId: null, name: null },
    });
    refreshData();
  };

  const handleOpenSelector = event => {
    setAnchorEl(event.currentTarget);
    setSearchPhrase('');
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const handleOnSearchChange = ({ target }) => setSearchPhrase(target.value);

  const handleSearchAccounts = async () => {
    try {
      await getVendorAccounts({
        variables: {
          searchPhrase,
          pageSize: 5,
        },
      });
    } catch (error) {
      console.error('GET_ACCOUNTS_ERROR', error.message);
    }
  };

  const updateQuery = () => {
    if (searchPhrase.trim().length > 3) {
      handleSearchAccounts();
    }
  };

  const delayedQuery = React.useCallback(debounce(updateQuery, 500), [
    searchPhrase,
  ]);

  React.useEffect(() => {
    delayedQuery();

    return delayedQuery.cancel;
  }, [searchPhrase, delayedQuery]);

  const selectorIsOpen = Boolean(anchorEl);

  const noResults =
    accountsData && !accountsData.vendors.length && !accountError;
  const hasResults = searchPhrase !== '' && accountsData;
  const isImpersonating = !!impersonateId;
  const LogoIcon =
    theme?.palette?.type === 'dark' ? (
      <DarkThemeLogo className={classes.logo} />
    ) : (
      <LightThemeLogo className={classes.logo} />
    );

  return (
    <Grid className={classes.logoContainer} container alignItems="center">
      {isAdmin ? (
        <IconButton onClick={handleOpenSelector}>
          {isImpersonating && (
            <Tooltip title="You impersonating another account">
              <Badge
                badgeContent=""
                overlap="circular"
                className={classes.warnBadge}
              >
                <WarningIcon color="inherit" />
              </Badge>
            </Tooltip>
          )}
          {LogoIcon}
        </IconButton>
      ) : (
        LogoIcon
      )}
      <Popover
        open={selectorIsOpen}
        anchorEl={anchorEl}
        onClose={handleCloseSelector}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.supplierContainer}
        >
          <Grid
            className={classes.supplierTitle}
            container
            wrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{t('common.supplier')}</Typography>
            <Button onClick={handleBackToAdminAccount}>
              Back to admin account
            </Button>
          </Grid>
          <TextField
            classes={{ root: classes.supplierSearch }}
            value={searchPhrase}
            onChange={handleOnSearchChange}
            variant="outlined"
            placeholder={t('appbar.supplier placeholder')}
            fullWidth
          />
          {accountsLoading && <LinearProgress color="primary" />}
          {noResults && (
            <Grid
              style={{ padding: 16 }}
              container
              direction="column"
              alignItems="center"
            >
              <Typography color="primary">{t('common.no results')}</Typography>
              {theme?.palette?.type === 'dark' ? (
                <NoResultsDarkThemeImage className={classes.noResultsImage} />
              ) : (
                <NoResultsLightThemeImage className={classes.noResultsImage} />
              )}
            </Grid>
          )}
          {accountError && t('common.something wrong')}
          {hasResults &&
            accountsData.vendors.map(account => {
              const name =
                account.contactData.businessName || account.contacts[0].email;
              return (
                <Button
                  key={account._id}
                  className={classes.accountButton}
                  onClick={() => handleChangeUserAccount(account._id, name)}
                  style={{ color: account.branding?.primaryColor }}
                >
                  {name}
                </Button>
              );
            })}
        </Grid>
      </Popover>
    </Grid>
  );
}

HeaderLogo.propTypes = {
  isAdmin: PropTypes.bool,
};

HeaderLogo.defaultProps = {
  isAdmin: false,
};
