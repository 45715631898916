import i18next from 'i18next';
import * as Yup from 'yup';
import { CAMPAIGN_FREQUENCY } from 'helpers/constants';
import { REPEAT_MAX, REPEAT_MIN, MESSAGE_LENGTH } from './campaignConstants';

export default Yup.object().shape({
  title: Yup.string().required(() => i18next.t('common.errors.empty field')),
  schedule: Yup.object({
    repeat: Yup.mixed().oneOf(CAMPAIGN_FREQUENCY),
    // dayIndexes: Yup.array(),
    // repeatInterval: Yup.number()
    //   .min(REPEAT_MIN)
    //   .max(REPEAT_MAX)
    //   .transform((_, val) => (val === Number(val) ? val : 1)),
    endAfterCount: Yup.number()
      .min(REPEAT_MIN)
      .max(REPEAT_MAX)
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : undefined)),
    startDate: Yup.string(),
    endDate: Yup.string().nullable(true),
    time: Yup.object({
      minute: Yup.number().min(0).max(60).required(),
      hour: Yup.number().min(6).max(19).required(),
    }),
  }),
  message: Yup.string().max(MESSAGE_LENGTH).required(() => i18next.t('common.errors.empty field')),
  sender: Yup
    .string()
    .test(value => value?.length > 0 && /^[A-Za-z0-9\s]+$/.test(value))
    .required(() => i18next.t('common.errors.empty field'))
});
