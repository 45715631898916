import { createFilterOptions } from '@material-ui/lab';

export function translateGroup(group, t, i18n) {
  const translation = group.translations?.find(g => g.lang === i18n.language);
  const defaultTranslation = group.name;

  const name = translation ?
    translation.value
    : t(`customers.restaurant group.${defaultTranslation}`, {
      defaultValue: defaultTranslation,
    });

  return name;
}

export const filterOptions = createFilterOptions({
  ignoreAccents: true,
  ignoreCase: true,
  trim: true,
  // so that we can search by all translations, name and vendorSpecificId
  stringify: (option) => [
    ...new Set([
      option.name,
      ...(option.translations?.map(translation => translation.value) || []),
      option.vendorSpecificId,
    ].filter(Boolean)),
  ].join(' '),
});

export const ASSIGN_TO_ALL_GROUP = {
  _id: 'assignedToAll',
  name: 'everyone',
  translations: [
    {
      lang: 'de',
      value: 'Alle',
    },
    {
      lang: 'en',
      value: 'Everyone',
    },
  ],
  color: '#FF5100',
  vendorSpecificId: null,
  vendorId: null,
};
