import React from 'react';
import PropTypes from 'prop-types';

import './CampaignPreview.css';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ADDITIONAL_MESSAGE_TEXT } from 'components/Campaigns/campaignConstants';

export default function CampaignPreview({ businessName, message }) {
  return (
    <div className="campaign-preview-container">
      <div className="iphone-container">
        <div className="topbar">
          <div className="speaker" />
          <div className="flash" />
        </div>
        <div className="screen">
          <div className="time">
            {new Date().toLocaleTimeString('DE', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
          <div className="network">
            <div className="network2" />
            <div className="network3" />
            <div className="network4" />
            <div className="network5" />
            <div className="LTE">LTE</div>
            <div className="battery">
              <div className="battery1" />
              <div className="battery2" />
            </div>
          </div>
        </div>
        <div className="contact-section-wrapper">
          <div className="back-caret">
            <ChevronLeftIcon style={{ color: '#3478f6', width: 30, height: 30 }} />
          </div>
          <div className="contact-section">
            <div className="icon-with-number">
              <div className="icon-wrapper">
                <AccountCircleIcon style={{ color: '#868686', width: 50, height: 50 }} />
              </div>
              {businessName}
            </div>
          </div>
        </div>
        <div className="message-section">
          {message && (
            <div className="message-preview">
              {message.concat(ADDITIONAL_MESSAGE_TEXT)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CampaignPreview.propTypes = {
  businessName: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
