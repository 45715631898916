import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    verticalAlign: 'middle',
  },
  input: {
    height: 20,
    width: 60,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    padding: theme.spacing(0.75),
    border: theme.palette.border.lightGrey,
  },
  error: {
    border: `1.5px solid ${theme.palette.error.main}`,
  },
  inputContainer: {
    width: 'auto',
  },
}));

export default function PriceInput({
  price,
  handleChangePrice,
  isEditMode,
  justify,
  disabled,
  hidden,
}) {
  const classes = useStyles();

  const hasError = price < 0;

  const renderPrice = () => {
    if (hidden) {
      return null;
    } else if (disabled) {
      return <Typography>-</Typography>;
    } else if (price) {
      return (
        <Typography variant="body2">
          {new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'EUR',
          }).format(price)}
        </Typography>
      );
    }
    return null;
  };

  return (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      justifyContent={justify || 'flex-start'}
      className={classes.inputContainer}
    >
      {isEditMode && (
        <Grid item>
          <TextField
            type="number"
            defaultValue={Number(parseFloat(price)?.toFixed(2))}
            onBlur={handleChangePrice}
            classes={{ root: classes.textFieldRoot }}
            InputProps={{
              classes: {
                input: classNames(classes.input, hasError && classes.error),
              },
              disableUnderline: true,
              inputProps: {
                min: 0,
                step: '0.01',
                placeholder: '0.00',
              },
            }}
            error={hasError}
            fullWidth
          />
        </Grid>
      )}
      {!isEditMode && renderPrice()}
    </Grid>
  );
}

PriceInput.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEditMode: PropTypes.bool.isRequired,
  handleChangePrice: PropTypes.func.isRequired,
  justify: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

PriceInput.defaultProps = {
  price: 0,
  justify: null,
  disabled: false,
  hidden: false,
};
