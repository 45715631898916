import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import DateInput from 'components/DealsTable/DateInput';
import PriceInput from 'components/DealsTable/PriceInput';
import ProductPackaging from 'components/ProductsTable/ProductPackaging';
import { truncateTitle } from 'helpers/utils';
import { getDateStatus } from '../../helpers/dates';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: `0px ${theme.spacing(1.5)}px`,
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  dateFieldCellEdit: {
    paddingLeft: 0
  }
}));

export default function ExpiringProductsDealsTableRowCells({
  productData,
  variantIndex,
  isEditMode,
  handleChangePrice,
  handleChangeDate,
}) {
  const classes = useStyles();

  const {
    _id,
    packaging = null,
    dealDetails,
  } = productData.variants[variantIndex] || {};

  return (
    <>
      <TableCell className={classes.tableCell}>
        <Typography variant="body2" align="left">
          {productData.vendorSpecificId}
        </Typography>
      </TableCell>
      <TableCell
        className={classes.tableCell}
      >
        {truncateTitle(productData.name)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PriceInput
          price={dealDetails?.dealPrice || ''}
          isEditMode={isEditMode}
          justify="center"
          handleChangePrice={({ target: { value } }) => {
            handleChangePrice(value, _id);
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductPackaging
          unit={packaging || ''}
        />
      </TableCell>
      <TableCell className={classNames(
        classes.tableCell,
        isEditMode && classes.dateFieldCellEdit
      )}
      >
        <DateInput
          dateStatus={getDateStatus(new Date(), dealDetails?.start)}
          date={dealDetails?.start ?? ''}
          dateType="start"
          isEditMode={isEditMode}
          handleChangeDate={(date, label) => handleChangeDate(date, label, packaging)}
        />
      </TableCell>
      <TableCell className={classNames(
        classes.tableCell,
        isEditMode && classes.dateFieldCellEdit
      )}
      >
        <DateInput
          dateStatus={getDateStatus(dealDetails?.start, dealDetails?.end)}
          date={dealDetails?.end ?? ''}
          dateType="end"
          minDate={dealDetails?.start ?? ''}
          isEditMode={isEditMode}
          handleChangeDate={(date, label) => handleChangeDate(date, label, packaging)}
        />
      </TableCell>
      <TableCell className={classNames(
        classes.tableCell,
        isEditMode && classes.dateFieldCellEdit
      )}
      >
        <DateInput
          dateStatus={getDateStatus(dealDetails?.end, dealDetails?.expiringDetails?.expiresAt)}
          date={dealDetails?.expiringDetails.expiresAt ?? ''}
          dateType="expiresAt"
          minDate={dealDetails?.end ?? dealDetails?.start ?? ''}
          isEditMode={isEditMode}
          handleChangeDate={(date, label) => handleChangeDate(date, label, packaging)}
        />
      </TableCell>
    </>
  );
}

ExpiringProductsDealsTableRowCells.propTypes = {
  productData: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  handleChangePrice: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  variantIndex: PropTypes.number.isRequired,
};
