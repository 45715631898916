import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  ASSIGN_CUSTOMERS_TO_SALES_REP,
  DELETE_CUSTOMERS_FROM_SALES_REP,
} from 'graphql/mutation/salesReps';
import { GET_SALES_REP } from 'graphql/query/salesReps';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import AddPersonIcon from '@material-ui/icons/PersonAddOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutline';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  tableSearchBarButtons: {
    minWidth: 130,
    height: 45,
    marginRight: theme.spacing(1.5),
    border: theme.palette.border.lightGrey,
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  activeSearchBarButtons: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.light,
    }
  },
  defaultIcon: {
    color: theme.palette.primary.main
  },
  activeIcon: {
    color: theme.palette.common.white
  },
  headerAdditionalActionsWrapper: {
    minHeight: 85,
    borderBottom: theme.palette.border.lightGrey
  },
  headerAdditionalActionsContainer: {
    margin: `${theme.spacing(2.5)}px 0px`
  },
  addActionsInputContainer: {
    height: 85,
    backgroundColor: theme.palette.background.container,
    // eslint-disable-next-line max-len
    padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px ${theme.spacing(2.5)}px ${theme.spacing(1.5)}px`
  },
  addActionsInput: {
    width: 340,
    height: 45,
    fontSize: 14,
    background: theme.palette.background.paper,
    border: theme.palette.border.lightModeOnly,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: 12
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
      fontSize: 16
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`
    },
  },
  addActionsNotchedInput: {
    border: 'none'
  },
  addActionsConfirm: {
    height: 45,
    color: theme.palette.text.inverted,
    margin: `0px ${theme.spacing(2.5)}px`,
    textTransform: 'capitalize',
    minWidth: 192,
    boxShadow: 'none',
  },
}));

const MODE_TYPES = {
  EDIT_SALES_REP_ASSIGNMENT: 'EDIT SALES REP ASSIGNMENT',
  REMOVE_SALES_REP_ASSIGNMENT: 'REMOVE SALES REP'
};

export default function HeaderActions(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const mixPanel = useMixPanel();

  const [assignCustomers] = useMutation(ASSIGN_CUSTOMERS_TO_SALES_REP);
  const [removeCustomerAssignment] = useMutation(DELETE_CUSTOMERS_FROM_SALES_REP);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [modeType, setModeType] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  // NOTE: replace all white spaces and commas to get correct values
  const replaceDelimiters = () =>
    inputValue.replace(/\s/g, ',').split(',').filter(value => value !== '');

  const handleChangeInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleModeTypeSelector = (type) => () => {
    if (type === modeType) {
      setModeType(null);
    } else {
      setModeType(type);
    }
  };

  React.useEffect(() => {
    setInputValue('');
  }, [modeType]);

  const editAssignment = async () => {
    const parsedCustomerIds = replaceDelimiters();
    try {
      await assignCustomers({
        variables: {
          _id: props.salesRepId,
          customerIds: parsedCustomerIds
        },
        refetchQueries: [{ query: GET_SALES_REP, variables: { _id: props.salesRepId } }],
        update: (_, {
          data: {
            assignCustomersForSalesRep: { customerIds, failedCustomerIds, existingCustomerIds }
          }
        }) => {
          if (failedCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('salesReps.error add customers', {
                  customers: failedCustomerIds.join(', '), count: failedCustomerIds.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else if (existingCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('salesReps.customers already exist', {
                  customers: existingCustomerIds.join(', '), count: existingCustomerIds.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('Assign Customer To Sales Rep', {
              salesRepId: props.salesRepId,
              customers: customerIds.join(', '),
              count: customerIds.length
            });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('salesReps.success add customers', {
                  customers: customerIds.join(', '), count: customerIds.length
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
    }
  };

  const removeAssignment = async () => {
    const parsedCustomerIds = replaceDelimiters();
    try {
      await removeCustomerAssignment({
        variables: {
          _id: props.salesRepId,
          customerIds: parsedCustomerIds
        },
        refetchQueries: [{ query: GET_SALES_REP, variables: { _id: props.salesRepId } }],
        update: (_, {
          data: {
            unassignCustomersForSalesRep: { customerIds, failedCustomerIds }
          }
        }) => {
          if (failedCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('salesReps.error remove customers', {
                  customers: failedCustomerIds.join(', '), count: failedCustomerIds.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('Unassign Customers From Sales Rep', {
              salesRepId: props.salesRepId,
              customers: customerIds.join(', '),
              count: customerIds.length
            });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('salesReps.success remove customers', {
                  customers: customerIds.join(', '), count: customerIds.length
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleConfirm = () => {
    if (modeType === MODE_TYPES.EDIT_SALES_REP_ASSIGNMENT) {
      editAssignment();
    } else if (modeType === MODE_TYPES.REMOVE_SALES_REP_ASSIGNMENT) {
      removeAssignment();
    } else {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.errors.special chars'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const getTextFieldPlaceholder = () => {
    switch (modeType) {
      case MODE_TYPES.EDIT_SALES_REP_ASSIGNMENT:
        return t('lists.edit assignment placeholder');
      case MODE_TYPES.REMOVE_SALES_REP_ASSIGNMENT:
        return t('lists.edit assignment placeholder');
      default:
        return t('lists.default input placeholder');
    }
  };

  // TODO: edit salesRep details
  return (
    <Grid
      className={classes.headerAdditionalActionsWrapper}
      container
      wrap="nowrap"
      direction="column"
    >
      <Grid
        className={classes.headerAdditionalActionsContainer}
        container
        wrap="nowrap"
        alignItems="center"
      >

        <Button
          className={classNames(
            classes.tableSearchBarButtons,
            modeType === MODE_TYPES.EDIT_SALES_REP_ASSIGNMENT && classes.activeSearchBarButtons
          )}
          variant="outlined"
          startIcon={<AddPersonIcon className={
            modeType === MODE_TYPES.EDIT_SALES_REP_ASSIGNMENT ?
              classes.activeIcon
              : classes.defaultIcon
          }
          />}
          onClick={handleModeTypeSelector(MODE_TYPES.EDIT_SALES_REP_ASSIGNMENT)}
        >
          {t('salesReps.assign customers')}
        </Button>
        <Button
          className={classNames(
            classes.tableSearchBarButtons,
            modeType === MODE_TYPES.REMOVE_SALES_REP_ASSIGNMENT && classes.activeSearchBarButtons
          )}
          variant="outlined"
          startIcon={<PeopleIcon className={
            modeType === MODE_TYPES.REMOVE_SALES_REP_ASSIGNMENT ?
              classes.activeIcon
              : classes.defaultIcon
          }
          />}
          onClick={handleModeTypeSelector(MODE_TYPES.REMOVE_SALES_REP_ASSIGNMENT)}
        >
          {t('salesReps.delete customers')}
        </Button>
      </Grid>

      {modeType && (
        <Grid
          className={classes.addActionsInputContainer}
          container
          alignItems="center"
          wrap="nowrap"
        >
          <TextField
            InputProps={{
              classes: {
                root: classes.addActionsInput,
                notchedOutline: classes.addActionsNotchedInput,
              }
            }}
            value={inputValue}
            onChange={handleChangeInput}
            variant="outlined"
            placeholder={getTextFieldPlaceholder()}
          />
          <Button
            onClick={handleConfirm}
            className={classes.addActionsConfirm}
            variant="contained"
            color="secondary"
          >
            {t('lists.confirm')}
          </Button>
        </Grid>
      )}

    </Grid>
  );
}

HeaderActions.propTypes = {
  salesRepId: PropTypes.string.isRequired,
};
