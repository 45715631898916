/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { RESET_PASSWORD } from 'graphql/mutation/password';

import { makeStyles, useTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as DarkThemeLogo } from 'assets/LogoWhite.svg';
import { ReactComponent as LightThemeLogo } from 'assets/Logo.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { NOTIFICATION_STATUS } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  logo: {
    position: 'absolute',
    left: 24,
    top: 24
  },
  logoImage: {
    width: 40,
    height: 40
  },
  resetPasswordContainer: {
    boxShadow: theme.palette.customShadows.popover,
    borderRadius: 8,
    padding: `${theme.spacing(5.5)}px ${theme.spacing(9)}px`,
    width: 560,
    minHeight: 650,
    background: theme.palette.background.paper,
  },
  resetPasswordTitle: {
    fontSize: 22,
    lineHeight: '26px',
    letterSpacing: '-0.02em'
  },
  resetMessage: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(2),
    width: 320
  },
  textInput: {
    width: '100%',
    border: theme.palette.border.lightGrey,
    borderRadius: 4,
    margin: `${theme.spacing(0.75)}px 0px ${theme.spacing(0.75)}px`
  },
  input: {
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  formControl: {
    top: 16
  },
  resetButton: {
    height: 50,
    boxShadow: 'none',
    marginTop: theme.spacing(3.75)
  }
}));

const ResetPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);

  const [state, setState] = React.useState({
    token: null,
    email: '',
    password: '',
    showPassword: false,
  });

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD);

  React.useEffect(() => {
    if (queries.token) {
      setState({ ...state, token: queries.token });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckErrors = () => {
    const { token, email, password } = state;
    const passwordIsValid = password.length >= 8;
    const emailIsValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

    if (email === '' || password === '') {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('reset password.empty field'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return false;
    } else if (!token) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return false;
    } else if (!passwordIsValid) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('reset password.password note'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return false;
    } else if (!emailIsValid) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('login.invalid email'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return false;
    } else {
      return true;
    }
  };

  const handleResetPassword = async () => {
    const formIsCorrect = handleCheckErrors();
    if (formIsCorrect) {
      const { token, email, password } = state;
      try {
        const response = await resetPasswordMutation({
          variables: {
            email,
            password,
            token
          }
        });
        if (!response.data.errors) {
          setNotification({
            variables: {
              timeout: 4000,
              message: t('reset password.reset success'),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
          history.push('/');
        }
      } catch (error) {
        console.error('RESET_PASSWORD', error);
        setNotification({
          variables: {
            timeout: 4000,
            message: t('common.something wrong'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <IconButton className={classes.logo} onClick={() => history.push('/')}>
        {theme?.palette?.type === 'dark' ?
          <DarkThemeLogo className={classes.logoImage} />
          : <LightThemeLogo className={classes.logoImage} />}
      </IconButton>
      <Grid
        container
        className={classes.resetPasswordContainer}
        direction="column"
      >
        <Typography
          className={classes.resetPasswordTitle}
          align="center"
          variant="h6"
        >
          {t('reset password.set new password')}
        </Typography>
        <Typography
          variant="body1"
          className={classes.resetMessage}
        >
          {t('reset password.password note')}
        </Typography>
        <TextField
          label={t('common.email')}
          variant="outlined"
          className={classes.textInput}
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl
            }
          }}
          value={state.email}
          onChange={({ target }) => setState({ ...state, email: target.value.replace(/\s+/g, '') })}
        />
        <TextField
          label={t('reset password.new password')}
          variant="outlined"
          type={state.showPassword ? 'text' : 'password'}
          className={classes.textInput}
          InputProps={{
            classes: {
              input: classes.input
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl
            }
          }}
          value={state.password}
          onChange={({ target }) => setState({ ...state, password: target.value.replace(/\s+/g, '') })}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.resetButton}
          onClick={handleResetPassword}
        >
          {t('reset password.reset')}
        </Button>
      </Grid>
    </>
  );
};

export default ResetPassword;
