import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CancelIcon from '@material-ui/icons/CancelRounded';
import WallpaperRoundedIcon from '@material-ui/icons/WallpaperRounded';
// import { SET_NOTIFICATION } from 'graphql/mutation/user';
// import { NOTIFICATION_STATUS } from 'helpers/constants';

const useStyles = makeStyles(({ palette, spacing }) => ({
  uploadLogoContainer: {
    height: 300,
    borderRadius: 8,
    border: palette.border.lightGreyThinDark,
    position: 'relative',
    transition: 'all 0.25s ease-in',
  },
  uploadLogoImageContainer: {
    cursor: 'pointer',
  },
  uploadLogoImagePreview: {
    maxWidth: 250,
    height: 136,
    background: palette.background.mutedLight,
    borderRadius: 8,
    position: 'relative',
    marginBottom: spacing(1),
  },
  uploadLogoImageTitle: {
    height: 28,
    width: 200,
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: spacing(1),
  },
  cancelButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  logoCaption: {
    maxWidth: 200,
    textAlign: 'center',
  },
  uploadImagePreview: {
    maxHeight: 136,
    maxWidth: 250,
    display: 'block',
  },
}));

export default function NewsletterImageUploader({
  imageUrl,
  imageCaption,
  handleChangeImage,
  handleDeleteImage,
}) {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: acceptedFiles => {
      handleChangeImage(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => {
      if (imageUrl) {
        const filename = imageUrl.split('/').pop();
        setFiles([
          {
            name: filename,
            preview: imageUrl,
          },
        ]);
      }
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageUrl]
  );

  const handleResetImage = event => {
    event.stopPropagation();
    setFiles([]);
    handleDeleteImage();
  };

  const isImageUploaded = !!files[0];

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.uploadLogoContainer}
    >
      {isImageUploaded && (
        <IconButton
          size="small"
          className={classes.cancelButton}
          color="default"
          onClick={handleResetImage}
        >
          <CancelIcon color="inherit" />
        </IconButton>
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        alignItems="center"
        {...getRootProps({ className: classes.uploadLogoImageContainer })}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.uploadLogoImagePreview}
        >
          <input {...getInputProps()} />
          {files?.length ? (
            files.map(file => (
              <img
                key="uploaded-logo"
                src={file.preview}
                alt="vendor logo"
                className={classes.uploadImagePreview}
                // Revoke data uri after image is loaded
                onLoad={() => {
                  URL.revokeObjectURL(file.preview);
                }}
              />
            ))
          ) : (
            <WallpaperRoundedIcon color="action" fontSize="large" />
          )}
        </Grid>
        <Typography className={classes.uploadLogoImageTitle} variant="body2">
          {isImageUploaded ? files[0].name : ''}
        </Typography>
        <Typography
          className={classes.logoCaption}
          variant="caption"
          color="textSecondary"
        >
          {imageCaption}
        </Typography>
      </Grid>
    </Grid>
  );
}

NewsletterImageUploader.defaultProps = {
  imageUrl: undefined,
};

NewsletterImageUploader.propTypes = {
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageCaption: PropTypes.string.isRequired,
  handleChangeImage: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
};
