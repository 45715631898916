import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutline';

import EmptyTableField from 'components/shared/EmptyTableField';
import DeleteRecipientModal from 'components/Newsletter/Recipients/DeleteRecipientModal';
import RecipientStatusIcons from 'components/Newsletter/Recipients/RecipientStatusIcons';
import NewsletterStatusIcons from 'components/Newsletter/Recipients/NewsletterStatusIcons';

const useStyles = makeStyles(
  ({ palette, spacing, breakpoints, typography }) => ({
    tableBodyRow: {
      height: 56,
      borderBottom: palette.border.lightGrey,
      '&:nth-child(odd)': {
        background: palette.background.mutedLight,
      },
    },
    tableCell: {
      padding: spacing(1.5),
      maxWidth: 150,
      color: palette.secondary.textBlack,
      borderBottom: 'none',
      [breakpoints.up('lg')]: {
        padding: spacing(1.75, 1.5),
      },
    },
    columnName: {
      width: 150,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    columnEmail: {
      width: 250,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    columnBusinessName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    columnDate: {
      maxWidth: 'auto',
    },
    columnStatus: {

      display: 'flex',
      flexDirection: 'row',
      maxWidth: 'fit-content',
    },
    tableActionButton: {
      minWidth: 0,
      width: 32,
      height: 32,
      boxShadow: 'none',
      borderRadius: 6,
      padding: 0,
      margin: spacing(0, 0.5),
      backgroundColor: palette.background.button,
      '&:hover': {
        boxShadow: 'none',
      },
    },
    tableActionButtonIcon: {
      width: 20,
      height: 20,
      color: palette.secondary.darkGray,
    },
    statusBox: {
      padding: spacing(0.5, 1.5),
      borderRadius: 4,
      width: 'fit-content',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      ...typography.body2,
      fontWeight: typography.fontWeightBold,
    },

    sent: {
      background: 'rgba(0, 158, 35, 0.16)',
      color: '#009E23',
    },
    valid: {
      background: 'rgba(0, 158, 35, 0.16)',
      color: '#009E23',
    },
    invalid: {
      background: 'rgba(217, 19, 42, 0.16)',
      color: '#d9142a',
    },
    delivered: {
      background: 'rgba(0, 158, 35, 0.16)',
      color: '#009E23',
    },
    opened: {
      background: 'rgba(1, 112, 105, 0.16)',
      color: '#017069',
    },
    error: {
      background: 'rgba(204, 0, 0, 0.16)',
      color: '#CC0000',
    },
    pending: {
      background: 'rgba(52, 152, 219, 0.16)',
      color: '#3498DB',
    },
    attempted: {
      background: 'rgba(52, 152, 219, 0.16)',
      color: '#3498DB',
    },
    canceled: {
      background: 'rgba(52, 152, 219, 0.16)',
      color: '#3498DB',
    },
    clicked: {
      background: 'rgba(255, 168, 0, 0.16)',
      color: '#FFA800',
    },
    bounced: {
      background: 'rgba(255, 81, 0, 0.16)',
      color: '#FF5100',
    },
    not_sent: {
      background: 'rgba(121, 122, 124, 0.16)',
      color: '#797A7C',
    },
    draft: {
      background: 'rgba(121, 122, 124, 0.05)',
      color: '#797A7CAA',
    },
    no_email: {
      background: 'rgba(204, 0, 0, 0.16)',
      color: '#CC0000',
    },
  })
);

function RecipientsTableRow({
  rowData,
  isNewsletterSent,
  handleDeleteRecipient,
  assignmentStatus,
  setOpenLogModal,
  setRecipientEvents,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    name,
    email,
    businessName,
    contactStatus,
    sendOutStatus,
    vendorClientId,
  } = rowData;

  const { lastUpdate } = sendOutStatus || {};

  const lastUpdateMoment = lastUpdate && moment(lastUpdate);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const handleOpenEventLog = () => {
    setRecipientEvents(sendOutStatus?.events || []);
    setOpenLogModal(true);
  };

  const handleDeleteContact = () => {
    setOpenDeleteModal(false);
    handleDeleteRecipient({ recipientEmail: email });
  };

  const handleDeleteConnection = () => {
    setOpenDeleteModal(false);
    handleDeleteRecipient({ vendorClientIds: vendorClientId });
  };

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell className={classes.tableCell}>
        {name ? (
          <Tooltip title={name}>
            <Typography className={classes.columnName} variant="body2">
              {name}
            </Typography>
          </Tooltip>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {email ? (
          <Tooltip title={email}>
            <Typography className={classes.columnEmail} variant="body2">
              {email}
            </Typography>
          </Tooltip>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {businessName ? (
          <Tooltip title={businessName}>
            <Typography className={classes.columnBusinessName} variant="body2">
              {businessName}
            </Typography>
          </Tooltip>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {vendorClientId ? (
          <Typography variant="body2">{vendorClientId}</Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.columnDate)}>
        {lastUpdate ? (
          <Typography variant="body2" noWrap>
            {lastUpdateMoment.year === moment().year
              ? lastUpdateMoment.format('HH:mm, MMMM Do')
              : lastUpdateMoment.format('HH:mm, MMMM Do YYYY')}
          </Typography>
        ) : (
          <Typography
            className={classNames(classes.statusBox, classes.draft)}
            variant="body2"
          >
            {t('newsletter.filter options.not_sent')}
          </Typography>
        )}
      </TableCell>
      <TableCell
        className={classNames(classes.tableCell, classes.columnStatus)}
      >
        {isNewsletterSent ? (
          <NewsletterStatusIcons
            sendOutStatus={sendOutStatus}
            contactStatus={contactStatus}
            handleOpenEventLog={handleOpenEventLog}
          />
        ) : (
          <RecipientStatusIcons contactStatus={contactStatus} />
        )}
      </TableCell>
      {!isNewsletterSent && (
        <TableCell className={classes.tableCell}>
          <Grid container wrap="nowrap">
            <Button
              variant="contained"
              classes={{ root: classes.tableActionButton }}
              onClick={() => setOpenDeleteModal(true)}
            >
              <DeleteIcon className={classes.tableActionButtonIcon} />
            </Button>
          </Grid>
        </TableCell>
      )}
      <DeleteRecipientModal
        isOpen={openDeleteModal}
        message={t('newsletter.delete recipient message')}
        vendorClientId={vendorClientId}
        email={email}
        assignmentStatus={assignmentStatus}
        canDeleteEmail={contactStatus.isDisabled}
        handleCloseModal={() => setOpenDeleteModal(false)}
        handleDeleteContact={handleDeleteContact}
        handleDeleteConnection={handleDeleteConnection}
      />
    </TableRow>
  );
}

RecipientsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  isNewsletterSent: PropTypes.bool.isRequired,
  handleDeleteRecipient: PropTypes.func.isRequired,
  assignmentStatus: PropTypes.string.isRequired,
  setOpenLogModal: PropTypes.func.isRequired,
  setRecipientEvents: PropTypes.func.isRequired,
};

export default React.memo(
  RecipientsTableRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.rowData, nextProps.rowData) &&
    prevProps.isNewsletterSent === nextProps.isNewsletterSent
);
