import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { UPDATE_CUSTOM_VENDOR_GROUP } from 'graphql/mutation/groups';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import ModalLayout from 'components/shared/ModalLayout';
import StyledInput from 'components/shared/Inputs/StyledInput';
import NewsletterColorPicker from 'components/Newsletter/Constructor/NewsletterColorPicker';
import { NOTIFICATION_STATUS } from 'helpers/constants';

const useStyles = makeStyles(({ spacing, palette }) => ({
  modalContainerCustomClass: {
    width: 650,
    maxWidth: 650,
    maxHeight: '80%',
  },
  loader: {
    margin: '0 auto',
    position: 'absolute',
    top: 60,
    width: 'calc(100% - 96px)',
    left: 48,
  },
  modalContentCustomClass: {
    padding: spacing(6, 6, 0),
  },
  updateCustomGroupModalContainer: {
    marginBottom: spacing(1),
  },
  pickGroupTitle: {
    margin: spacing(2, 0),
  },
  actionButton: {
    height: 50,
    margin: spacing(4.5, 1.5, 1.5),
    boxShadow: 'none',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  updateCustomGroupModalActions: {
    background: palette.background.paper,
    position: 'sticky',
    bottom: 0,
    paddingBottom: spacing(2),
  },
  limitationCaption: {
    margin: spacing(0.5, 1),
  },
}));

export default function UpdateCustomGroupModal({
  isOpen,
  groupData,
  handleClose,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useState({ name: '', color: '' });

  const [
    updateCustomGroupMutation,
    { loading: isUpdateCustomGroupLoading },
  ] = useMutation(UPDATE_CUSTOM_VENDOR_GROUP);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  //
  // ------- EFFECTS --------
  //
  useEffect(() => {
    if (groupData) {
      setState({
        name: groupData.name,
        color: groupData.color,
      });
    }
  }, [groupData]);

  //
  // ------- HANDLERS --------
  //

  const handleChangeName = e => {
    e.persist();
    setState(prevState => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleColorChange = color => {
    setState(prevState => ({
      ...prevState,
      color,
    }));
  };

  const handleUpdateCustomGroup = async () => {
    if (state.name.length > 35) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.chars limitation', { count: 35 }),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    try {
      await updateCustomGroupMutation({
        variables: {
          groupId: groupData._id,
          name: state.name,
          color: state.color,
        },
      });
      handleClose();
    } catch (error) {
      console.error(error);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      classes={{
        containerClass: classes.modalContainerCustomClass,
        contentClass: classes.modalContentCustomClass,
      }}
      modalTitle={t('groups.update custom group')}
    >
      {isUpdateCustomGroupLoading && (
        <LinearProgress className={classes.loader} color="primary" />
      )}
      <Grid
        className={classes.updateCustomGroupModalContainer}
        container
        alignItems="flex-end"
      >
        <Grid container item xs={12}>
          <FormControl fullWidth className={classes.margin}>
            <Typography className={classes.nameFieldTitle} paragraph>
              {t('common.name')}
            </Typography>
            <StyledInput
              fullWidth
              variant="outlined"
              value={state.name}
              placeholder={t('groups.group name placeholder')}
              onChange={handleChangeName}
              inputProps={{
                maxLength: 35,
              }}
            />
            <Typography variant="caption" className={classes.limitationCaption}>
              {t('common.chars limitation', { count: 35 })}
            </Typography>
          </FormControl>
        </Grid>
        <Grid container item xs={12} alignItems="center" direction="column">
          <Typography className={classes.pickGroupTitle} variant="body2">
            {t('groups.pick a color for your group')}
          </Typography>
          <NewsletterColorPicker
            color={state.color}
            onChangeComplete={handleColorChange}
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.updateCustomGroupModalActions}
        container
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={handleUpdateCustomGroup}
          variant="contained"
          color="primary"
        >
          {t('common.save')}
        </Button>
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={handleClose}
          variant="contained"
        >
          {t('common.cancel')}
        </Button>
      </Grid>
    </ModalLayout>
  );
}

UpdateCustomGroupModal.defaultProps = {
  groupData: null,
};

UpdateCustomGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  groupData: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};
