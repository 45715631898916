import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TableLayout from 'components/shared/TableLayout';
import AddExpiringProductsModal from 'components/Deals/AddExpiringProductsModal';
import useDealTable from 'hooks/useDealTable';
import { TABLE_SKELETON_TYPES } from 'helpers/constants';
import ExpiringProductDealsTableRow from 'components/Deals/ExpiringProductDealsTableRow';

function ExpiringDealsTable(
  {
    classes,
    dealType
  }) {
  const { t } = useTranslation();

  const {
    dealsData,
    hasError,
    dealsLoading,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    handleSortRows,
    refetchDeals,

    selected,
    editModeId,
    isAddDealModalOpen,
    handleSelectItem,
    handleSetEditMode,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelectAllClick,

    editConfirmationModal,

    setConfirmEditChanges,
    setAddDealModalOpened,
  } = useDealTable(dealType);

  const handleClickAway = () => (editModeId ? setConfirmEditChanges(true) : null);

  const tableColumns = [
    {
      title: t('deals.number'),
      field: 'vendorSpecificId',
      sortable: true,
    },
    {
      title: t('deals.name'),
      field: 'name',
      sortable: true,
      columnStyles: classNames(classes.columnTitle, classes.dealTitle)
    },
    {
      title: t('deals.price'),
      field: 'price',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.price)
    },
    {
      title: t('deals.package'),
      field: 'variants[0].packaging',
      sortable: false,
    },
    {
      title: t('deals.start'),
      field: 'dealDetails.start',
      sortable: false,
    },
    {
      title: t('deals.end'),
      field: 'dealDetails.end',
      sortable: false,
    },
    {
      title: t('deals.expiresAt'),
      field: 'dealDetails.expiresAt',
      sortable: false,
    },
  ];

  return (
    <>
      <TableLayout
        handleClickAway={handleClickAway}
        isEditMode={Boolean(editModeId)}
        title={t('deals.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.DEALS}
        emptyTableData={hasError || !dealsData?.totalResults}
        emptyTableDataMessage={t('deals.empty deals')}
        tableIsLoading={dealsLoading}
        withDetailPanel
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={dealsData?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        headerActions={
          <Button
            className={classes.addDealButton}
            variant="outlined"
            startIcon={<AddCircleOutlineIcon color="primary"/>}
            onClick={() => setAddDealModalOpened(true)}
          >
            {t('deals.add new expiring product')}
          </Button>
        }
        // @TODO: add support bulk acitons
        // headerAdditionalActions={!!selected.length && (
        //   <MultiSelectButtons
        //     allDeals={dealsData.products}
        //     dealsIds={selected}
        //     setSelected={setSelected}
        //   />
        // )}
        //
        // @TODO: remove comments when support bulk actions will be implemented
        // hasCheckBox
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        itemsOnPage={dealsData?.products?.length}
      >
        {!!dealsData?.products?.length && dealsData.products
          .map((rowData) => (
            rowData && (
              <ExpiringProductDealsTableRow
                key={rowData._id}
                isEditMode={rowData._id === editModeId}
                setEditMode={handleSetEditMode}
                editConfirmationModalIsActive={editConfirmationModal}
                setConfirmEditChanges={setConfirmEditChanges}
                handleSelectItem={handleSelectItem}
                rowData={rowData}
                withDetailPanel
                selected={selected}
                dealType={dealType}
                onDealDeleted={refetchDeals}
              />
            )))}
      </TableLayout>
      <AddExpiringProductsModal
        isOpen={isAddDealModalOpen}
        handleClose={() => setAddDealModalOpened(false)}
        rowsPerPage={rowsPerPage}
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
        refetchDeals={refetchDeals}
      />
    </>
  );
}

ExpiringDealsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dealType: PropTypes.string.isRequired,
};

export default ExpiringDealsTable;
