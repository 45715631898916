/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  CREATE_PRODUCT_ATTRIBUTE,
  UPDATE_PRODUCT_ATTRIBUTE,
  ASSIGN_PRODUCT_ATTRIBUTE,
  UNASSIGN_PRODUCT_ATTRIBUTE,
} from 'graphql/mutation/products';
import {
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_ATTRIBUTE_BY_ID,
} from 'graphql/query/products';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';
import StyledInput from 'components/shared/Inputs/StyledInput';
import IOSSwitch from 'components/shared/IOSSwitch';
import ProductAttributeAssignment from 'components/ProductsTable/ProductAttributes/ProductAttributeAssignment';
import classNames from 'classnames';

const useStyles = makeStyles(({ spacing, palette }) => ({
  modalContainerCustomClass: {
    width: 720,
    maxWidth: 720,
    maxHeight: '80%',
  },
  loader: {
    margin: '0 auto',
    position: 'absolute',
    top: 60,
    width: 'calc(100% - 96px)',
    left: 48,
  },
  modalContentCustomClass: {
    padding: spacing(6, 6, 0),
  },
  attributeNameContainer: {
    marginBottom: spacing(1),
  },
  attributeOptionsContainer: {
    marginTop: spacing(2),
  },
  attributeOptionInputContainer: {
    marginBottom: spacing(1.5),
  },
  attributeOption: {
    margin: spacing(1, 0),
    border: palette.border.darkGrey,
    padding: spacing(0.5, 1.5),
    borderRadius: 8,
  },
  fieldTitle: {
    fontWeight: 500,
  },
  isRequiredSwitch: {
    marginLeft: spacing(0),
    marginTop: spacing(2),
    justifyContent: 'flex-end',
  },
  actionButton: {
    height: 50,
    margin: spacing(4.5, 1.5, 1.5),
    boxShadow: 'none',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  attributeDragNDrop: {
    cursor: 'grab',
  },
  updateProductAttrActions: {
    background: palette.background.paper,
    position: 'sticky',
    bottom: 0,
    paddingBottom: spacing(6),
  },
}));

const DEFAULT_ATTRIBUTE_STATE = {
  name: '',
  options: [],
  isRequired: false,
  products: {
    totalResults: 0,
    hasNextPage: false,
    page: 1,
    totalPages: 1,
    products: [],
  },
};

export default function UpdateProductAttributeModal({
  isOpen,
  handleClose,
  productsAttrsPageState,
  isNewAttribute,
  attributeData,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [attributeState, setAttributeState] = useState({
    ...DEFAULT_ATTRIBUTE_STATE,
  });
  const [attributeOption, setAttributeOption] = useState('');
  const [draggedIndex, setDraggedIndex] = useState(null);

  const { page, rowsPerPage, sortBy, sortOrder } = productsAttrsPageState;

  const [
    getProductAttributeById,
    { loading: isAttributeLoading },
  ] = useLazyQuery(GET_PRODUCT_ATTRIBUTE_BY_ID);

  const [createNewAttribute] = useMutation(CREATE_PRODUCT_ATTRIBUTE);
  const [
    updateNewAttribute,
    { loading: isUpdateAttributeLoading },
  ] = useMutation(UPDATE_PRODUCT_ATTRIBUTE);
  const [
    assignProductAttribute,
    { loading: isAssignAttributeLoading },
  ] = useMutation(ASSIGN_PRODUCT_ATTRIBUTE);
  const [
    unassignProductAttribute,
    { loading: isUnassignAttributeLoading },
  ] = useMutation(UNASSIGN_PRODUCT_ATTRIBUTE);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  useEffect(() => {
    if (!isNewAttribute && attributeData) {
      setAttributeState(attributeData);
    } else {
      setAttributeState(DEFAULT_ATTRIBUTE_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeData]);

  // ------- QUERY & MUTATIONS --------

  const handleCreateNewAttribute = async ({
    // skipClosingModal = false,
    vendorSpecificIds = [],
  }) => {
    const { name, options, isRequired, _id } = attributeState;

    if (!name) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t(
            'product attributes.notifications.attribute name is empty'
          ),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    try {
      if (isNewAttribute) {
        await createNewAttribute({
          variables: {
            attribute: {
              name,
              options,
              isRequired,
            },
            vendorSpecificIds,
          },
          refetchQueries: [
            {
              query: GET_PRODUCT_ATTRIBUTES,
              variables: {
                after: page + 1,
                pageSize: rowsPerPage,
                sortBy,
                sortOrder,
              },
            },
          ],
          // update: (_, { data: { createProductAttribute } }) => {
          //   if (skipClosingModal) {
          //     console.log('CRWEATE', createProductAttribute);
          //     setAttributeState(createProductAttribute);
          //   }
          // }
        });
      } else {
        await updateNewAttribute({
          variables: {
            id: _id,
            attribute: {
              name,
              options: options.map(
                ({ __typename, translations, ...attrOption }) => ({
                  ...attrOption,
                })
              ),
              isRequired,
            },
          },
          refetchQueries: [
            {
              query: GET_PRODUCT_ATTRIBUTES,
              variables: {
                after: page + 1,
                pageSize: rowsPerPage,
                sortBy,
                sortOrder,
              },
            },
          ],
        });
      }
      setAttributeState({ ...DEFAULT_ATTRIBUTE_STATE });
      setNotification({
        variables: {
          timeout: 4000,
          message: t(
            'product attributes.notifications.attribute created successfully',
            {
              productName: attributeState.name,
            }
          ),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }

    // if (!skipClosingModal) {
    //   handleClose();
    // }
    handleClose();
  };

  const handleLoadMoreProducts = async variables => {
    try {
      const result = await getProductAttributeById({ variables });
      const productsConnection = result?.data?.productAttribute?.products || {};
      const loadedProducts = productsConnection.products || [];
      const productsPage = productsConnection.page || 1;
      setAttributeState(prevState => ({
        ...prevState,
        products: {
          ...prevState.products,
          products: prevState.products.products.concat(loadedProducts),
          page: productsPage,
        },
      }));
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  // ------- HANDLERS --------

  const handleMouseDownOption = event => {
    event.preventDefault();
  };

  const handleChangeName = e => {
    e.persist();
    setAttributeState(prevState => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleChangeRequired = e => {
    e.persist();
    setAttributeState(prevState => ({
      ...prevState,
      isRequired: e.target.checked,
    }));
  };

  const handleAttributeOption = event => {
    setAttributeOption(event.target.value);
  };

  const handleAddAttributeOption = () => {
    setAttributeState(prevState => ({
      ...prevState,
      options: prevState.options.concat(
        isNewAttribute ? attributeOption : { name: attributeOption }
      ),
    }));
    setAttributeOption('');
  };

  const handleDeleteAttributeOption = optionToRemoveIndex => {
    setAttributeState(prevState => ({
      ...prevState,
      options: prevState.options.filter(
        (_, index) => index !== optionToRemoveIndex
      ),
    }));
  };

  const handleSetProducts = productsData => {
    const { totalResults, products } = productsData;
    setAttributeState(prevState => ({
      ...prevState,
      products: {
        ...prevState.products,
        totalResults,
        products,
      },
    }));
  };

  // ------- DRAG N DROP --------

  const handleDragStart = index => {
    setDraggedIndex(index);
  };

  const handleDragOver = e => {
    e.preventDefault(); // NOTE: Necessary to allow dropping
  };

  const handleDrop = index => {
    const draggedItem = attributeState.options[draggedIndex];
    const remainingItems = attributeState.options.filter(
      (_, i) => i !== draggedIndex
    );
    const reorderedItems = [
      ...remainingItems.slice(0, index),
      draggedItem,
      ...remainingItems.slice(index),
    ];

    setAttributeState(prevState => ({
      ...prevState,
      options: reorderedItems,
    }));
    setDraggedIndex(null);
  };

  //  ------- RETURN --------

  const isLoading =
    isAttributeLoading ||
    isUpdateAttributeLoading ||
    isAssignAttributeLoading ||
    isUnassignAttributeLoading;

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      classes={{
        containerClass: classes.modalContainerCustomClass,
        contentClass: classes.modalContentCustomClass,
      }}
      modalTitle={
        isNewAttribute
          ? t('product attributes.create attribute')
          : t('product attributes.update attribute')
      }
    >
      {isLoading && (
        <LinearProgress className={classes.loader} color="primary" />
      )}
      <Grid
        className={classes.attributeNameContainer}
        container
        alignItems="flex-end"
      >
        <Grid container item xs={12}>
          <FormControl fullWidth className={classes.margin}>
            <Typography className={classes.nameFieldTitle} paragraph>
              {t('product attributes.name')}
            </Typography>
            <StyledInput
              fullWidth
              variant="outlined"
              value={attributeState.name}
              placeholder={t('product attributes.type attribute name')}
              onChange={handleChangeName}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={12} direction="column">
          <Tooltip
            title={t('product attributes.attribute is required tooltip')}
          >
            <FormControlLabel
              className={classes.isRequiredSwitch}
              classes={{
                label: classes.fieldTitle,
              }}
              labelPlacement="start"
              control={
                <IOSSwitch
                  checked={attributeState.isRequired}
                  onChange={handleChangeRequired}
                  name="product label"
                />
              }
              label={t('product attributes.attribute is required')}
            />
          </Tooltip>
          <Typography variant="body2" paragraph>
            {t('product attributes.notifications.required attributes caption')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" direction="column">
        <Grid
          className={classes.attributeOptionsContainer}
          container
          direction="column"
          wrap="nowrap"
          item
          xs={12}
        >
          <Typography variant="h6" align="left">
            {t('product attributes.attribute options')}
          </Typography>
          <Typography variant="body2" align="left" paragraph>
            {t('product attributes.attribute options caption')}
          </Typography>
          <Grid
            className={classes.attributeOptionInputContainer}
            container
            item
            xs={12}
            wrap="nowrap"
          >
            <StyledInput
              variant="outlined"
              fullWidth
              value={attributeOption}
              placeholder={t('product attributes.attribute option placeholder')}
              onChange={handleAttributeOption}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={!attributeOption}
                    aria-label="add product attribute option"
                    color="primary"
                    onClick={handleAddAttributeOption}
                    onMouseDown={handleMouseDownOption}
                  >
                    <AddCircleRoundedIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid
            container
            id="target"
            item
            xs={12}
            wrap="nowrap"
            direction="column"
          >
            {attributeState.options.map((option, index) => (
              <Grid
                // eslint-disable-next-line react/no-array-index-key
                key={`${option}-${index}`}
                className={classNames(
                  classes.attributeOption,
                  classes.attributeDragNDrop
                )}
                container
                justifyContent="space-between"
                alignItems="center"
                draggable="true"
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
              >
                <Typography>{option?.name || option || ''}</Typography>
                <IconButton
                  onClick={() => handleDeleteAttributeOption(index)}
                  size="small"
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <ProductAttributeAssignment
          isNewAttribute={isNewAttribute}
          attributeState={attributeState}
          sharedClasses={classes}
          assignProductAttribute={assignProductAttribute}
          unassignProductAttribute={unassignProductAttribute}
          handleSetProducts={handleSetProducts}
          handleCreateNewAttribute={handleCreateNewAttribute}
          handleLoadMoreProducts={handleLoadMoreProducts}
        />
      </Grid>
      <Grid
        className={classes.updateProductAttrActions}
        container
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={handleCreateNewAttribute}
          variant="contained"
          color="primary"
        >
          {isNewAttribute
            ? t('product types.create')
            : t('common.modal save changes')}
        </Button>
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={handleClose}
          variant="contained"
        >
          {t('common.cancel')}
        </Button>
      </Grid>
    </ModalLayout>
  );
}

UpdateProductAttributeModal.defaultProps = {
  isNewAttribute: false,
  attributeData: null,
};

UpdateProductAttributeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  productsAttrsPageState: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.number.isRequired,
  }).isRequired,
  isNewAttribute: PropTypes.bool,
  attributeData: PropTypes.object,
};
