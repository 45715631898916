import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import AddPersonIcon from '@material-ui/icons/PersonAddOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutline';

import { NOTIFICATION_STATUS } from 'helpers/constants';

const useStyles = makeStyles(
  ({ spacing, palette, breakpoints, typography }) => ({
    tableSearchBarButtons: {
      minWidth: 130,
      height: 45,
      marginRight: spacing(1.5),
      fontWeight: typography.fontWeightRegular,
      border: `1px solid ${palette.primary.main}`,
    },
    activeSearchBarButtons: {
      color: palette.common.white,
      background: palette.primary.main,
      '&:hover': {
        background: palette.primary.light,
      },
    },
    defaultIcon: {
      color: palette.primary.main,
    },
    activeIcon: {
      color: palette.common.white,
    },
    headerAdditionalActionsWrapper: {
      minHeight: 85,
      borderBottom: palette.border.lightGrey,
    },
    headerAdditionalActionsContainer: {
      margin: spacing(2.5, 0),
    },
    addActionsInputContainer: {
      height: 85,
      backgroundColor: palette.background.container,
      padding: spacing(2.5, 1.5),
    },
    addActionsInput: {
      width: 340,
      height: 45,
      fontSize: 14,
      background: palette.background.paper,
      border: palette.border.lightModeOnly,
      [breakpoints.down('sm')]: {
        width: '100%',
        fontSize: 12,
      },
      [breakpoints.up('lg')]: {
        width: 400,
        fontSize: 16,
      },
      '&:hover': {
        border: `1px solid ${palette.primary.main}`,
      },
    },
    addActionsNotchedInput: {
      border: 'none',
    },
    addActionsConfirm: {
      height: 45,
      color: palette.text.inverted,
      marginLeft: spacing(2.5),
      textTransform: 'capitalize',
      minWidth: 192,
      boxShadow: 'none',
    },
  })
);

const MODE_TYPES = {
  ADD_GROUP_ASSIGNMENT: 'ADD_GROUP_ASSIGNMENT',
  REMOVE_GROUP_ASSIGNMENT: 'REMOVE_GROUP_ASSIGNMENT',
};

export default function GroupAssignment({
  inputValue,
  setInputValue,
  handleAssignCustomers,
  handleRemoveAssignment,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [modeType, setModeType] = React.useState(
    MODE_TYPES.ADD_GROUP_ASSIGNMENT
  );

  const handleChangeInput = event => {
    setInputValue(event.target.value);
  };

  const handleModeTypeSelector = type => () => {
    if (type !== modeType) {
      setModeType(type);
    }
  };

  React.useEffect(() => {
    setInputValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeType]);

  const handleConfirm = () => {
    if (modeType === MODE_TYPES.ADD_GROUP_ASSIGNMENT) {
      handleAssignCustomers();
    } else if (modeType === MODE_TYPES.REMOVE_GROUP_ASSIGNMENT) {
      handleRemoveAssignment();
    } else {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.errors.special chars'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  return (
    <Grid
      className={classes.headerAdditionalActionsWrapper}
      container
      wrap="nowrap"
      direction="column"
    >
      <Grid
        className={classes.addActionsInputContainer}
        container
        alignItems="center"
        wrap="nowrap"
      >
        <Grid
          className={classes.headerAdditionalActionsContainer}
          container
          wrap="nowrap"
          alignItems="center"
        >
          <Button
            className={classNames(
              classes.tableSearchBarButtons,
              modeType === MODE_TYPES.ADD_GROUP_ASSIGNMENT &&
              classes.activeSearchBarButtons
            )}
            variant="outlined"
            startIcon={
              <AddPersonIcon
                className={
                  modeType === MODE_TYPES.ADD_GROUP_ASSIGNMENT
                    ? classes.activeIcon
                    : classes.defaultIcon
                }
              />
            }
            onClick={handleModeTypeSelector(MODE_TYPES.ADD_GROUP_ASSIGNMENT)}
          >
            {t('salesReps.assign customers')}
          </Button>
          <Button
            className={classNames(
              classes.tableSearchBarButtons,
              modeType === MODE_TYPES.REMOVE_GROUP_ASSIGNMENT &&
              classes.activeSearchBarButtons
            )}
            variant="outlined"
            startIcon={
              <PeopleIcon
                className={
                  modeType === MODE_TYPES.REMOVE_GROUP_ASSIGNMENT
                    ? classes.activeIcon
                    : classes.defaultIcon
                }
              />
            }
            onClick={handleModeTypeSelector(MODE_TYPES.REMOVE_GROUP_ASSIGNMENT)}
          >
            {t('salesReps.delete customers')}
          </Button>
        </Grid>

        <Grid container wrap="nowrap" justifyContent="flex-end">
          <TextField
            InputProps={{
              classes: {
                root: classes.addActionsInput,
                notchedOutline: classes.addActionsNotchedInput,
              },
            }}
            value={inputValue}
            onChange={handleChangeInput}
            variant="outlined"
            placeholder={t('lists.edit assignment placeholder')}
          />
          <Button
            onClick={handleConfirm}
            className={classes.addActionsConfirm}
            variant="contained"
            color="secondary"
            disabled={!inputValue.trim()}
          >
            {t('common.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

GroupAssignment.propTypes = {
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  handleAssignCustomers: PropTypes.func.isRequired,
  handleRemoveAssignment: PropTypes.func.isRequired,
};
