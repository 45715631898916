import React from 'react';
import PropTypes from 'prop-types';

function DateRange({ startDate, endDate, delimiter, format }) {
  const bothDates = startDate && endDate;

  if (bothDates) {
    return (
      <>
        {startDate.format(format)} {delimiter} {endDate.format(format)}
        ({startDate.format('dddd')} {delimiter} {endDate.format('dddd')})
      </>
    );
  } else if (startDate) {
    return (
      <>
        {startDate.format('YYYY-MM-DD (dddd)')}
      </>
    );
  } else {
    return null;
  }
}

DateRange.propTypes = {
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any,
  delimiter: PropTypes.string,
  format: PropTypes.string,
};

DateRange.defaultProps = {
  delimiter: '-',
  format: 'DD.MM.YYYY',
  endDate: null,
};

export default DateRange;
