import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import DropDowIcon from '@material-ui/icons/ArrowDropDown';

import EmptyTableField from 'components/shared/EmptyTableField';
import GroupBadge from 'components/shared/GroupBadge';
import CustomerGroupModalSelector from 'components/shared/CustomerGroupModalSelector';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  customerGroupCellWrapper: {
    maxWidth: 140,
  },
  hasGroups: {
    color: palette.success.main,
  },
  groupSelectorContainer: {
    maxHeight: 500,
    overflowY: 'auto',
    padding: spacing(1.25, 2.5),
  },
  selectorTitle: {
    color: palette.secondary.darkGray,
    fontStyle: 'italic',
    padding: spacing(1.25, 0),
  },
  listLink: {
    textDecoration: 'none',
  },
  listButton: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: spacing(1.25, 0),
  },
  popoverPaper: {
    boxShadow: palette.customShadows.popover,
  },
  addListButton: {
    height: 45,
    color: palette.secondary.darkGray,
    border: palette.border.lightGrey,
    fontWeight: typography.fontWeightRegular,
  },
}));

function CustomerGroup({
  customerId,
  isEditMode,
  setFieldValue,
  customerGroups = [],
  allCustomerGroups,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenCustomGroup = groupId => () => {
    history.replace({
      pathname: '/customers',
      search: queryString.stringify({
        ...queryString.parse(history.location.search),
        id: customerId,
      }),
    });
    history.push(`group/${groupId}`);
  };

  const handleOpenSelector = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const handleChangeGroup = groupId => () => {
    const group = allCustomerGroups.find(({ _id }) => _id === groupId);
    if (!group) return;

    const groupIsInArray =
      customerGroups.findIndex(g => g._id === groupId) !== -1;

    if (groupIsInArray) {
      setFieldValue(
        'customerGroups',
        customerGroups.filter(({ _id }) => _id !== group._id)
      );
    } else {
      setFieldValue('customerGroups', customerGroups.concat(group));
    }
  };

  const selectorIsOpen = Boolean(anchorEl);

  return (
    <Grid
      container
      className={classes.customerGroupCellWrapper}
      wrap="nowrap"
      alignItems="center"
    >
      {customerGroups.length === 0 && <EmptyTableField />}
      {customerGroups.length === 1 && <GroupBadge groups={customerGroups} />}
      {customerGroups?.length > 1 && (
        <>
          <Typography className={classes.hasGroups}>
            {customerGroups.length}
          </Typography>
          <Grid item>
            <IconButton size="small" onClick={handleOpenSelector}>
              <DropDowIcon />
            </IconButton>
          </Grid>
          <Popover
            classes={{ paper: classes.popoverPaper }}
            open={selectorIsOpen}
            anchorEl={anchorEl}
            onClose={handleCloseSelector}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              className={classes.groupSelectorContainer}
            >
              <Typography className={classes.selectorTitle}>
                {t('groups.title')}
              </Typography>
              {customerGroups?.length > 0 ? (
                <GroupBadge
                  isButton
                  onClickAction={handleOpenCustomGroup}
                  groups={customerGroups}
                />
              ) : (
                <EmptyTableField />
              )}
            </Grid>
          </Popover>
        </>
      )}
      {isEditMode && (
        <CustomerGroupModalSelector
          customerGroups={allCustomerGroups}
          selectedCustomerGroups={customerGroups?.map(({ _id }) => _id)}
          handleChangeGroupSelector={handleChangeGroup}
          withEditIcon
        />
      )}
    </Grid>
  );
}

CustomerGroup.propTypes = {
  customerId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  customerGroups: PropTypes.array.isRequired,
  allCustomerGroups: PropTypes.array.isRequired,
};

export default React.memo(
  CustomerGroup,
  (prevProps, nextProps) =>
    isEqual(prevProps.customerGroups, nextProps.customerGroups) &&
    isEqual(prevProps.allCustomerGroups, nextProps.allCustomerGroups) &&
    prevProps.isEditMode === nextProps.isEditMode &&
    prevProps.isNewCustomer === nextProps.isNewCustomer
);
