import React from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 56,
    borderBottom: theme.palette.border.lightGrey
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    maxWidth: 150,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
}));

function ListTableRow(props) {
  const classes = useStyles();

  const { columns, rowData } = props;

  return (
    <TableRow className={classes.tableBodyRow}>
      {columns.map(column => (
        <TableCell
          key={column.field}
          className={classNames(classes.tableCell, column.columnStyles)}
        >
          {column.render ?
            column.render(rowData)
            : lodashGet(rowData, column.field)}
        </TableCell>
      ))}
    </TableRow>
  );
}

ListTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default React.memo(ListTableRow, (prevProps, nextProps) =>
  isEqual(prevProps.rowData, nextProps.rowData)
);
