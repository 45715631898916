/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import useMixPanel from 'helpers/useMixPanel';
import NewsletterProduct from './NewsletterProduct';
import {
  NEWSLETTER_PRODUCT,
  NEWSLETTER_PRODUCTS_LIMIT,
  updateNewsletterProductField,
} from '../localConstantsAndHelpers';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  fieldLabel: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2),
  },
  labelWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  productSection: {
    marginBottom: spacing(2),
  },
  addProductCardButtonContainer: {
    width: 'fit-content',
    padding: spacing(1),
  },
  addProductCardButton: {
    width: 'fit-content',
    minHeight: 120,
    border: palette.border.lightGreyThinDark,
    borderRadius: 8,
  },
}));

export const PRODUCT_SECTIONS = {
  FEATURED: 'featuredProducts',
  REGULAR: 'products',
};

export default function ProductsSection({
  state,
  defaultProductImage,
  setState,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const handleChangeProductField = (index, productSection) => (
    field,
    value,
    isVariantField
  ) => {
    if (productSection === PRODUCT_SECTIONS.FEATURED) {
      setState(prevState => ({
        ...prevState,
        productGrid: {
          ...prevState.productGrid,
          featuredProducts: updateNewsletterProductField(
            prevState.productGrid.featuredProducts,
            index,
            field,
            value,
            isVariantField
          ),
        },
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        productGrid: {
          ...prevState.productGrid,
          products: updateNewsletterProductField(
            prevState.productGrid.products,
            index,
            field,
            value,
            isVariantField
          ),
        },
      }));
    }
  };

  const handleDeleteProductImage = (index, productSection) => () => {
    setState(prevState => ({
      ...prevState,
      productGrid: {
        ...prevState.productGrid,
        [productSection]: prevState.productGrid[productSection].map(
          (product, pIndex) => {
            if (index === pIndex) {
              return {
                ...product,
                image: null,
                imageUrl: null,
              };
            } else return product;
          }
        ),
      },
    }));
  };

  const handleDeleteProductCard = (index, productSection) => () => {
    if (productSection === PRODUCT_SECTIONS.FEATURED) {
      setState(prevState => ({
        ...prevState,
        productGrid: {
          ...prevState.productGrid,
          featuredProducts: prevState.productGrid.featuredProducts.map(
            (featProduct, FPIndex) => {
              if (index === FPIndex) {
                return NEWSLETTER_PRODUCT();
              } else return featProduct;
            }
          ),
        },
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        productGrid: {
          ...prevState.productGrid,
          products: prevState.productGrid.products.filter(
            (_, PIndex) => PIndex !== index
          ),
        },
      }));
    }
  };

  const handleAddProductCard = () => {
    setState(prevState => ({
      ...prevState,
      productGrid: {
        ...prevState.productGrid,
        products: prevState.productGrid.products.concat(NEWSLETTER_PRODUCT()),
      },
    }));
  };

  const handleSelectProductFromSearch = (
    index,
    productSection
  ) => selectedProduct => {
    setState(prevState => ({
      ...prevState,
      productGrid: {
        ...prevState.productGrid,
        [productSection]: prevState.productGrid[productSection].map(
          (product, pIndex) => {
            if (index === pIndex) {
              return selectedProduct;
            } else return product;
          }
        ),
      },
    }));
    mixPanel.track('Newsletter - Select Product', {
      newsletterId: state._id,
      newsletter: state,
      product: selectedProduct,
      productSection,
      productIndex: index,
    });
  };

  const { featuredProducts, products } = state?.productGrid || {};
  const isProductsLimitReached = products?.length >= NEWSLETTER_PRODUCTS_LIMIT;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Typography className={classes.fieldLabel}>
        {t('newsletter.featured products')}
      </Typography>

      <Grid container spacing={2} className={classes.productSection}>
        {featuredProducts?.map((product, index) => {
          // Let's keep it disabled for now
          const isMainFeatProduct = false;
          return (
            <Grid
              container
              item
              xs={isMainFeatProduct ? 12 : 6}
              lg={isMainFeatProduct ? 12 : 4}
              key={`featured-${product?._id + index}`}
            >
              <NewsletterProduct
                product={product}
                productIndex={index}
                defaultProductImage={defaultProductImage}
                isFeatured
                isMainFeatProduct={isMainFeatProduct}
                handleChangeProductField={handleChangeProductField(
                  index,
                  PRODUCT_SECTIONS.FEATURED
                )}
                handleDeleteProductCard={handleDeleteProductCard(
                  index,
                  PRODUCT_SECTIONS.FEATURED
                )}
                handleDeleteProductImage={handleDeleteProductImage(
                  index,
                  PRODUCT_SECTIONS.FEATURED
                )}
                handleSelectProductFromSearch={handleSelectProductFromSearch(
                  index,
                  PRODUCT_SECTIONS.FEATURED
                )}
              />
            </Grid>
          );
        })}
      </Grid>

      <Typography
        className={classNames(classes.fieldLabel, classes.labelWithTooltip)}
      >
        {t('newsletter.other products')} &nbsp;
        <Tooltip placement="top-end" title="Max. 12">
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </Typography>

      <Grid container spacing={2}>
        {products?.map((product, index) => (
          <Grid container item xs={6} lg={4} key={`regular-${index}`}>
            <NewsletterProduct
              product={product}
              productIndex={index}
              defaultProductImage={defaultProductImage}
              handleChangeProductField={handleChangeProductField(
                index,
                PRODUCT_SECTIONS.REGULAR
              )}
              handleDeleteProductCard={handleDeleteProductCard(
                index,
                PRODUCT_SECTIONS.REGULAR
              )}
              handleDeleteProductImage={handleDeleteProductImage(
                index,
                PRODUCT_SECTIONS.REGULAR
              )}
              handleSelectProductFromSearch={handleSelectProductFromSearch(
                index,
                PRODUCT_SECTIONS.REGULAR
              )}
            />
          </Grid>
        ))}
        {!isProductsLimitReached && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.addProductCardButtonContainer}
          >
            <Button
              className={classes.addProductCardButton}
              startIcon={<AddRoundedIcon />}
              color="primary"
              onClick={handleAddProductCard}
            >
              {t('newsletter.add product')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

ProductsSection.defaultProps = {
  defaultProductImage: undefined,
};

ProductsSection.propTypes = {
  state: PropTypes.any.isRequired,
  defaultProductImage: PropTypes.string,
  setState: PropTypes.func.isRequired,
};
