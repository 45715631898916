import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from '../graphql/mutation/user';

export function useNotification(type) {
  const [setNotification] = useMutation(SET_NOTIFICATION);

  return function showNotification(message, timeout = 4000) {
    setNotification({
      variables: {
        timeout,
        message,
        type,
        isOpen: true,
      },
    });
  };
}
