import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import ProblemIcon from '@material-ui/icons/ReportProblemOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const useStyles = makeStyles(theme => ({
  noticeContainer: {
    padding: theme.spacing(1.5),
    paddingRight: 0,
    border: theme.palette.border.darkGrey,
    borderRadius: 4,
    color: theme.palette.secondary.darkGray,
    marginBottom: theme.spacing(14)
  },
  errorContainer: {
    maxHeight: 155,
    overflow: 'auto'
  },
  errorMessagesContainer: {
    margin: `${theme.spacing(0.5)}px 0px`
  },
  detailedErrorMessage: {
    marginLeft: theme.spacing(3.25)
  },
  successContainer: {
    height: 120,
    color: theme.palette.success.main
  }
}));

export default function CsvNotifications(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { errors, success } = props;

  const defaultNotification = !errors && !success;
  const hasError = errors && errors.list.length > 0;
  const isSuccess = success && success.message;

  return (
    <Grid
      className={classes.noticeContainer}
      container
      wrap="nowrap"
      justifyContent="center"
      direction="column"
    >
      {defaultNotification && (
        <>
          <Typography color="inherit">
            <InfoIcon color="inherit" style={{ marginRight: 4 }} />
            {t('products.csv notification')}
          </Typography>
        </>
      )}
      {hasError && (
        <>
          <Grid container alignItems="center">
            <ProblemIcon color="error" style={{ marginRight: 4 }} />
            <Typography variant="h6" color="primary">
              {`${t('common.total')}: ${errors.total}`}
            </Typography>
          </Grid>
          <Grid
            className={classes.errorContainer}
            container
            direction="column"
            wrap="nowrap"
          >
            {errors.list.map(error => {
              const parsedErrors = error.parseErrors ? error.parseErrors.map(
                jsonMessage => {
                  try {
                    return JSON.parse(jsonMessage);
                  } catch (err) {
                    return [];
                  }
                }
              ) : null;
              return (
                <Grid
                  key={uuid()}
                  container
                  className={classes.errorMessagesContainer}
                  direction="column"
                  wrap="nowrap"
                >
                  <Typography color="error">
                    {/* eslint-disable-next-line max-len */}
                    {`${error.message ? error.message : 'Error'} ${error.line ? `on line ${error.line}` : ''}${error.parseErrors ? ':' : ''}`}
                  </Typography>
                  {parsedErrors && parsedErrors.map(({ dataPath, message }) =>
                    <Typography key={uuid()} color="error">
                      &bull; <strong>{dataPath && dataPath.replace('.', '')}</strong> {message}
                    </Typography>
                  )}
                </Grid>
              );
            })}
            {errors.total > 10 && (
              <Typography color="error">
                ...
              </Typography>
            )}
          </Grid>
        </>
      )}
      {isSuccess && (
        <Grid container wrap="nowrap">
          <SuccessIcon color="secondary" style={{ marginRight: 4 }} />
          <Grid
            className={classes.successContainer}
            container
            direction="column"
            wrap="nowrap"
          >
            {success.message && (
              <Typography color="secondary">
                {success.message}
              </Typography>
            )}
            {success.added > 0 && (
              <Typography color="inherit">
                {`${t('common.added')}: ${success.added}`}
              </Typography>
            )}
            {success.lines > 0 && (
              <Typography color="inherit">
                {`${t('common.lines')}: ${success.lines}`}
              </Typography>
            )}
            {success.updated > 0 && (
              <Typography color="inherit">
                {`${t('common.updated')}: ${success.updated}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

CsvNotifications.propTypes = {
  errors: PropTypes.object,
  success: PropTypes.object
};

CsvNotifications.defaultProps = {
  errors: null,
  success: null
};
