import React from 'react';
import Color from 'color';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import EmptyTableField from 'components/shared/EmptyTableField';

// NOTE truncate max title's length and add ellipsis
export const truncateTitle = (title, maxLength = 64, align) => {
  if (!title) {
    return <EmptyTableField align={align || 'left'} />;
  } else if (title && title.length > maxLength) {
    return (
      <Tooltip title={title} placement="top">
        <Typography variant="body2" align={align || 'left'}>
          {title.slice(0, maxLength).concat('...')}
        </Typography>
      </Tooltip>
    );
  } else {
    return (
      <Typography variant="body2" align={align || 'left'}>
        {title}
      </Typography>
    );
  }
};

export const calculateUnitPrice = (price, unitsInPackaging, align = 'center') => {
  if (!price || !unitsInPackaging) {
    return (<EmptyTableField align={align} />);
  } else {
    return (
      <Typography variant="body2" align={align}>
        {`€${(+price / +unitsInPackaging).toFixed(2)}`}
      </Typography>
    );
  }
};

export const downloadFile = (file, fileName) => {
  if (!file) return;
  if (window.navigator.msSaveOrOpenBlob) {
    // IE 10+
    window.navigator.msSaveOrOpenBlob(file, fileName);
  } else {
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

const DEFAULT_COLOR = '#888888';

export const parseGroupColor = (color) => {
  if (!color) return DEFAULT_COLOR;
  try {
    const result = Color(color).hex();
    if (result) {
      return result;
    } else return DEFAULT_COLOR;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('UNABLE_TO_PARSE_COLOR', color, error);
    return DEFAULT_COLOR;
  }
};
