import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    verticalAlign: 'middle'
  },
  input: ({ width = 'auto' }) => ({
    maxWidth: width,
    height: 20,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    padding: theme.spacing(0.75),
    border: theme.palette.border.lightGrey
  }),
  error: {
    border: `1.5px solid ${theme.palette.error.main}`
  },
}));

export default function PriceInput({
  price, handleChangePrice, isEditMode, justify, width,
}) {
  const classes = useStyles({ width });

  const hasError = price < 0;

  return (
    <Grid container alignItems="center" wrap="nowrap" justifyContent={justify || 'flex-start'}>
      {isEditMode &&
        <Grid item>
          <TextField
            type="number"
            value={price}
            onChange={handleChangePrice}
            classes={{ root: classes.textFieldRoot }}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
              classes: {
                input: classNames(classes.input, hasError && classes.error),
              },
              disableUnderline: true,
              inputProps: {
                min: 0,
              }
            }}
            error={hasError}
            fullWidth={!width}
          />
        </Grid>}
      {!isEditMode && (price ? (
        <Typography variant="body2">
          {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'EUR' }).format(price)}
        </Typography>
      ) : (
        <EmptyTableField />
      ))}
    </Grid>
  );
}

PriceInput.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEditMode: PropTypes.bool.isRequired,
  handleChangePrice: PropTypes.func.isRequired,
  justify: PropTypes.string,
  width: PropTypes.number,
};

PriceInput.defaultProps = {
  price: 0,
  justify: null,
  width: undefined,
};
