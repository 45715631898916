import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';

import { contactsSchema } from 'components/Settings/ValidationSchemas';
import { NEW_VENDOR_CONTACT } from 'helpers/constants';
import SettingsInput from 'components/Settings/SettingsInput';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  teamContainer: {
    width: 518,
    padding: `${theme.spacing(5)}px ${theme.spacing(4)}px`,
    margin: `0px ${theme.spacing(2.5)}px`,
    boxSizing: 'border-box',
    boxShadow: theme.palette.customShadows.popover
  },
  titleContainer: {
    paddingBottom: theme.spacing(3)
  },
  actionButton: {
    fontSize: theme.typography.htmlFontSize
  },
  contactContainer: {
    padding: `${theme.spacing(2.5)}px 0px`
  },
  dialogPaper: {
    maxWidth: 'none',
    minWidth: 440,
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(6)}px`,
  },
  dialogBackdrop: {
    background: 'rgb(0 0 0 / 0.1)'
  },
  dialogTitle: {
    margin: `0px ${theme.spacing(3)}px ${theme.spacing(7)}px`,
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
  deleteMessage: {
    marginBottom: theme.spacing(4)
  },
  submitButton: {
    marginTop: theme.spacing(4),
    height: 50,
    borderRadius: 8,
    boxShadow: 'none'
  },
  deleteMemberAction: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
  },
}));

export default function TeamContacts({ vendorContacts, handleUpdateVendor }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [editContact, setEditContact] = React.useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [contacts, setLocalContacts] = React.useState([]);

  const setInitialMainData = React.useCallback(() => {
    setLocalContacts(vendorContacts.map(({ __typename, ...otherData }) => ({ ...otherData })));
  }, [vendorContacts]);

  React.useEffect(() => {
    setInitialMainData();
  }, [setInitialMainData]);

  const handleSubmitContacts = ({ isNewContact, password, ...values }) => {
    if (isNewContact) {
      handleUpdateVendor({
        contacts: [...contacts, {
          ...values,
          email: values.email.toLowerCase(),
          password
        }]
      });
    } else {
      handleUpdateVendor({
        contacts: [...contacts.map(contact => ((contact._id === values._id)
          ? ({
            ...values,
            email: values.email.toLowerCase()
          })
          : contact)
        )]
      });
    }
    setEditContact(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteContact = deleteId => {
    handleUpdateVendor({ contacts: contacts.filter(contact => contact._id !== deleteId) });
    setEditContact(null);
    setDeleteModalOpen(false);
  };

  return (
    <Paper elevation={1} className={classes.teamContainer}>
      <>
        <Grid className={classes.titleContainer} container justifyContent="space-between">
          <Typography variant="h6">
            {t('settings.team contact details')}
          </Typography>
          <Button
            className={classes.actionButton}
            variant="text"
            color="primary"
            endIcon={<AddIcon />}
            onClick={() => setEditContact({ ...NEW_VENDOR_CONTACT() })}
          >
            {t('settings.new contact')}
          </Button>
        </Grid>
        <Divider />
        {contacts.map(contact => (
          <React.Fragment key={contact._id}>
            <Grid
              container
              alignItems="center"
              className={classes.contactContainer}
              wrap="nowrap"
              direction="column"
            >
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  {t('settings.team member')}
                </Typography>
                <Button
                  className={classes.actionButton}
                  variant="text"
                  color="primary"
                  endIcon={<EditIcon />}
                  onClick={() => setEditContact(contact)}
                >
                  {t('common.edit')}
                </Button>
              </Grid>
              <Grid container direction="column" wrap="nowrap">
                <Typography variant="body1">
                  {contact.name}
                </Typography>
                <Typography variant="body1">
                  {contact.phone}
                </Typography>
                <Typography variant="body1">
                  {contact.email}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
        <Dialog
          open={!!editContact}
          TransitionComponent={Transition}
          onClose={() => setEditContact(null)}
          classes={{
            paper: classes.dialogPaper
          }}
          BackdropProps={{
            classes: {
              root: classes.dialogBackdrop
            }
          }}
        >
          <Formik
            initialValues={{
              ...editContact
            }}
            enableReinitialize
            onSubmit={(values) => handleSubmitContacts(values)}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={contactsSchema}
          >
            {({
              values, handleChange, handleSubmit, errors, handleBlur
            }) => (
              <>
                <DialogTitle disableTypography className={classes.dialogTitle}>
                  <Typography variant="h6">
                    {t('settings.team member')}
                  </Typography>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => {
                      setEditContact(null);
                      setDeleteModalOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {isDeleteModalOpen ? (
                    <>
                      <Grid container className={classes.deleteMessage}>
                        <Typography variant="h6">
                          {t('settings.delete contact message')}
                        </Typography>
                      </Grid>
                      <Grid container wrap="nowrap" justifyContent="space-around">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setDeleteModalOpen(false)}
                          disableElevation
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDeleteContact(values._id)}
                          disableElevation
                        >
                          Delete contact
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid container wrap="nowrap" direction="column">
                      <SettingsInput
                        value={values.name}
                        name="name"
                        label={t('common.name')}
                        onChange={handleChange}
                        error={errors.name}
                        handleBlur={handleBlur}
                      />
                      <SettingsInput
                        value={values.phone}
                        name="phone"
                        label={t('common.phone')}
                        onChange={handleChange}
                        error={errors.phone}
                        handleBlur={handleBlur}
                      />
                      <SettingsInput
                        value={values.email}
                        name="email"
                        label={t('common.email')}
                        onChange={handleChange}
                        error={errors.email}
                        handleBlur={handleBlur}
                      />
                      {values.isNewContact && (
                        <SettingsInput
                          value={values.password}
                          name="password"
                          label={t('common.password')}
                          onChange={handleChange}
                          error={errors.password}
                          handleBlur={handleBlur}
                        />
                      )}
                      <Button
                        className={classNames(
                          classes.actionButton,
                          classes.submitButton
                        )}
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                      >
                        {t('common.confirm')}
                      </Button>
                      <Button
                        className={classNames(
                          classes.actionButton,
                          classes.submitButton,
                          classes.deleteMemberAction
                        )}
                        fullWidth
                        onClick={() => setDeleteModalOpen(true)}
                        variant="text"
                      >
                        {t('settings.delete member')}
                      </Button>
                    </Grid>
                  )}
                </DialogContent>
              </>
            )}
          </Formik>
        </Dialog>
      </>
    </Paper>
  );
}

TeamContacts.propTypes = {
  vendorContacts: PropTypes.array.isRequired,
  handleUpdateVendor: PropTypes.func.isRequired,
};
