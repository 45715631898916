import { gql } from '@apollo/client';

export const GROUP_FRAGMENT = gql`
  fragment GroupFragment on CustomerGroup {
    _id
    name
    color
    vendorSpecificId
    vendorId
    translations {
      lang
      value
    }
  }
`;

export const CUTOFFTIME_FRAGMENT = gql`
  fragment CutOffTimeFragment on CutOffTime_v2 {
    enabled
    generic {
      hour
      minute
      leadTime {
        days
      }
    }
    perDay {
      _1 {
        enabled
        dayOfWeekIndex
        hour
        minute
        leadTime {
          days
        }
      }
      _2 {
        enabled
        dayOfWeekIndex
        hour
        minute
        leadTime {
          days
        }
      }
      _3 {
        enabled
        dayOfWeekIndex
        hour
        minute
        leadTime {
          days
        }
      }
      _4 {
        enabled
        dayOfWeekIndex
        hour
        minute
        leadTime {
          days
        }
      }
      _5 {
        enabled
        dayOfWeekIndex
        hour
        minute
        leadTime {
          days
        }
      }
      _6 {
        enabled
        dayOfWeekIndex
        hour
        minute
        leadTime {
          days
        }
      }
      _0 {
        enabled
        dayOfWeekIndex
        hour
        minute
        leadTime {
          days
        }
      }
    }
  }
`;

export const TRANSLATION_FRAGMENT = gql`
  fragment TranslationFragment on Translations {
    en
    de
    fr
    es
    ve
  }
`;

export const DELIVERY_DAYS_FRAGMENT = gql`
  fragment DeliveryDaysFragment on DeliveryDaysSchema_v2 {
    enabled
    days {
      _1 {
        dayOfWeekIndex
        enabled
        name
        weekParity
      }
      _2 {
        dayOfWeekIndex
        enabled
        name
        weekParity
      }
      _3 {
        dayOfWeekIndex
        enabled
        name
        weekParity
      }
      _4 {
        dayOfWeekIndex
        enabled
        name
        weekParity
      }
      _5 {
        dayOfWeekIndex
        enabled
        name
        weekParity
      }
      _6 {
        dayOfWeekIndex
        enabled
        name
        weekParity
      }
      _0 {
        dayOfWeekIndex
        enabled
        name
        weekParity
      }
    }
  }
`;

export const FULL_PRODUCT_TYPE_RESULT = gql`
  ${TRANSLATION_FRAGMENT}
  ${CUTOFFTIME_FRAGMENT}
  ${DELIVERY_DAYS_FRAGMENT}
  fragment FullProductTypeResultFragment on ProductType {
    _id
    createdAt
    updatedAt
    vendorId
    readableId
    name {
      ...TranslationFragment
    }
    subTitle {
      ...TranslationFragment
    }
    description {
      ...TranslationFragment
    }
    icon
    iconColor
    hideAtProduct
    isDefault
    deliveryDays {
      ...DeliveryDaysFragment
    }
    cutOffTime {
      ...CutOffTimeFragment
    }
    pickup {
      cutOffTime {
        ...CutOffTimeFragment
      }
      deliveryDays {
        ...DeliveryDaysFragment
      }
    }
  }
`;

export const CREATE_PRODUCT_TYPE_RESULT = gql`
  ${TRANSLATION_FRAGMENT}
  ${FULL_PRODUCT_TYPE_RESULT}
  fragment ProductTypeResultFragment on CreateProductTypeResult {
    results {
      ...FullProductTypeResultFragment
    }
    created
    updated
    existing {
      _id
      vendorId
      readableId
      name {
        ...TranslationFragment
      }
    }
    nonExisting {
      _id
      vendorId
      readableId
      name {
        ...TranslationFragment
      }
    }
  }
`;

export const NEWSLETTER_FRAGMENT = gql`
  fragment NewsletterFragment on Newsletter {
    _id
    title
    templateType
    status
    subject
    fromName
    fromEmail
  
    statistics {
      Send
      Delivery
      Reject
      Open
      Click

      Bounce
      Complaint
    }

    assignedGroups {
      _id
      name
      color
      vendorSpecificId
      vendorId
      translations {
        lang
        value
      }
    }
    assignedToAll
    excludedGroups {
      _id
      name
      color
      vendorSpecificId
      vendorId
      translations {
        lang
        value
      }
    }
    excludedRecipientCount
    recipientCount
    recipientsWithoutEmailCount
    staticInfo {
      workingHours
      workingHoursCaption
      phone
      email
      disclaimer
    }
    productGrid {
      color
      promoText
      promoStartDate
      promoEndDate
      logoUrl
      bannerUrl
      featuredProducts {
        _id
        enabled
        name
        imageUrl
        description
        label
        isLabelEnabled
        productCopy {
          _id
          name
          description
          vendorId
          vendorSpecificId
          enabled
          image
          imageUrl
          variants {
            _id
            baseUnit
            packaging
            unitsInPackaging
            price
            baseUnitMapped {
              en
              de
            }
            packagingMapped {
              en
              de
            }
            originalPrice
            dealPrice
          }
        }
        variants {
          baseUnit
          packaging
          originalPackaging
          price
          enabled
          unitsInPackaging
        }
      }
      products {
        _id
        enabled
        name
        imageUrl
        description
        label
        isLabelEnabled
        productCopy {
          _id
          name
          description
          vendorId
          vendorSpecificId
          enabled
          image
          imageUrl
          variants {
            _id
            baseUnit
            packaging
            unitsInPackaging
            price
            baseUnitMapped {
              en
              de
            }
            packagingMapped {
              en
              de
            }
            originalPrice
            dealPrice
          }
        }
        variants {
          baseUnit
          enabled
          packaging
          originalPackaging
          price
          unitsInPackaging
        }
      }
    }
  }
`;
