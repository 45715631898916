import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Input } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    margin: 0,
    padding: theme.spacing(0),
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    }
  },
  inputLabel: {
    display: 'block',
  },
  input: {
    // backgroundColor: 'yellow',
    border: '1px solid #C7C9CC',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 2),
    fontWeight: 400,
  },
  inputError: {
    background: theme.palette.error.background,
  },
  textarea: {
    minHeight: '275px !important',
    height: '100% !important',
    // padding: 0,
    marginTop: theme.spacing(2),
  }
}));

function SimpleTextInput({ id, name, value, handleChange, errorsTitle, placeholder, label, type, className, ...restProps }) {
  const classes = useStyles();

  return (
    <>
      <InputLabel
        htmlFor={id}
        className={classes.inputLabel}
      >
        {label}
      </InputLabel>
      <Input
        id={id}
        name={name}
        value={value}
        type={type}
        onChange={handleChange}
        error={!!errorsTitle}
        placeholder={placeholder}
        variant="filled"
        fullWidth
        disableUnderline={!errorsTitle}
        className={classes.inputRoot}
        inputProps={{
          className: classNames(classes.input, className, errorsTitle && classes.inputError, type === 'textarea' && classes.textarea),
        }}
        {...restProps}
      />
    </>
  );
}

SimpleTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errorsTitle: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SimpleTextInput.defaultProps = {
  errorsTitle: null,
  placeholder: null,
  label: null,
  className: null,
};

export default SimpleTextInput;
