import * as Yup from 'yup';
// import { isValidPhoneNumber } from 'react-phone-number-input';

// FIXME
// ADD i18next TRANSLATION INTO REQUIRED FUNC
export default Yup.object().shape({
  contactData: Yup.object().shape({
    businessName: Yup.string().required(),
    city: Yup.string().required(),
    country: Yup.string().required(),
    houseNumber: Yup.string().required(),
    street: Yup.string().required(),
    zip: Yup.string().required(),
  }),
  contacts: Yup.array().of(
    Yup.object().shape({
      additionalPhones: Yup.array().nullable().notRequired(),
      email: Yup.string().required().email(),
      name: Yup.string().required(),
      phone: Yup.string()
        .test(
          'phone',
          'Die eingegebene Nummer ist ungültig',
          value => (value && (value.length >= 5 && value.length <= 16))
          // FIXME: add country code checking and phone validation
          // value => (value.length >= 7 && value.length <= 16 && isValidPhoneNumber(value))
        ).required(),
    })
  ).required(),
  deliveryDays: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        enabled: Yup.boolean().required(),
      })
    )
    .test('deliveryDays', 'Sie müssen mindestens einen Liefertag für Ihren Kunden auswählen',
      function days (deliveryDays) {
        if (this.parent.status === 'draft') {
          return true;
        } else {
          return !deliveryDays.every(day => !day.enabled);
        }
      })
    .required('Sie müssen mindestens einen Liefertag für Ihren Kunden auswählen'),
  customerGroups: Yup.array().notRequired(),
  lists: Yup.array().notRequired(),
  status: Yup.string().required(),
  vendorClientId: Yup.string().required(),
});
