import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    verticalAlign: 'middle',
  },
  input: {
    height: 20,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    padding: theme.spacing(0.75),
    border: theme.palette.border.lightGrey,
  },
  error: {
    border: `1.5px solid ${theme.palette.error.main}`,
  },
  disabledInput: {
    backgroundColor: 'transparent',
    textAlign: 'center',
  },
}));

export default function ProductVariantInput(props) {
  const classes = useStyles();

  const getDecimalNumber = (value) => {
    if (!value) return 0;
    return parseFloat(parseFloat(value).toFixed(2));
  };

  const handleOnChange = (event) => {
    const input = event.target.value;
    props.setFieldValue(props.name,
      props.type === 'number' ? getDecimalNumber(input) : input.replace(/[;"]/g, ''));
  };

  const inputValue = props.value || (props.type === 'number' ? 0 : '');

  return (
    <TextField
      type={props.type}
      name={props.name}
      classes={{ root: classes.textFieldRoot }}
      InputProps={{
        classes: {
          input: classNames(classes.input, props.error && classes.error),
          disabled: classes.disabledInput,
        },
        disableUnderline: true,
        startAdornment: props.isPrice && (
          <InputAdornment position="start">€</InputAdornment>
        ),
        inputProps: {
          min: 0,
          maxLength: props.inputLimit,
        },
      }}
      disabled={props.disabled}
      value={inputValue}
      onChange={handleOnChange}
      fullWidth
    />
  );
}

ProductVariantInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  isPrice: PropTypes.bool,
  type: PropTypes.string,
  inputLimit: PropTypes.number,
  setFieldValue: PropTypes.func,
};

ProductVariantInput.defaultProps = {
  disabled: false,
  setFieldValue: () => {},
  error: null,
  isPrice: false,
  type: 'text',
  value: '',
  inputLimit: null,
};
