import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  errorLabel: {
    color: palette.error.main,
  },
  tableCell: {
    padding: `${spacing(1)}px ${spacing(1.5)}px`,
    maxWidth: 150,
    borderBottom: 'none',
    [breakpoints.up('lg')]: {
      padding: `0px ${spacing(1)}px`,
    },
    '&:first-child': {
      // paddingLeft: spacing(3.5),
    },
    '&:last-child': {
      // paddingRight: spacing(3.5),
    },
  },
}));

function ProductTableCell(
  {
    value,
    emptyValue,
    isEmpty,
    isError,
    useDiv,
    isPrice,
    extraClassNames,
  }
) {
  const classes = useStyles();

  const renderEmptyValue = () => {
    if (emptyValue) {
      return (
        <Typography
          variant="body2"
          className={isError ? classes.errorLabel : ''}
        >
          {emptyValue}
        </Typography>
      );
    } else {
      return (
        <Typography
          className={isError ? classes.errorLabel : ''}
          variant="body2"
        >
          N/A
        </Typography>
      );
    }
  };

  const renderValue = () => {
    if (isPrice) {
      return (
        <Typography variant="body2">
          {new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'EUR',
          }).format(value)}
        </Typography>
      );
    } else {
      return <Typography variant="body2">{value}</Typography>;
    }
  };

  return useDiv ? (
    <Grid className={classNames(...extraClassNames)}>
      {!isEmpty && value ? renderValue() : renderEmptyValue()}
    </Grid>
  ) : (
    <TableCell className={classNames(classes.tableCell, ...extraClassNames)}>
      {!isEmpty && value ? renderValue() : renderEmptyValue()}
    </TableCell>
  );
}

ProductTableCell.defaultProps = {
  value: null,
  emptyValue: '',
  isEmpty: false,
  isError: false,
  useDiv: false,
  isPrice: false,
  extraClassNames: [],
};

ProductTableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emptyValue: PropTypes.string,
  isEmpty: PropTypes.bool,
  isError: PropTypes.bool,
  useDiv: PropTypes.bool,
  isPrice: PropTypes.bool,
  extraClassNames: PropTypes.array,
};

export default ProductTableCell;
