import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import UploadIcon from '@material-ui/icons/PublishOutlined';

const useStyles = makeStyles(({ palette }) => ({
  dragNDropContainer: {
    height: 56,
    borderRadius: 4,
    width: '100%',
  },
  uploadPhotoButton: {
    background: palette.background.paper,
    color: palette.secondary.darkGray,
    height: '100%',
    width: '100%',
  },
}));

export default function UploadInput({ setFiles, isDisabled }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { getInputProps, open: openDragNDrop } = useDropzone({
    accept: 'application/*',
    onDrop: async acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    noClick: true,
    multiple: false,
  });

  return (
    <Grid container wrap="wrap">
      <Grid
        className={classes.dragNDropContainer}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        wrap="nowrap"
      >
        <input {...getInputProps()} />
        <Button
          className={classes.uploadPhotoButton}
          variant="outlined"
          startIcon={<UploadIcon color="primary" />}
          onClick={openDragNDrop}
          disabled={isDisabled}
        >
          {t('pdfCatalog.select')}
        </Button>
      </Grid>
    </Grid>
  );
}

UploadInput.propTypes = {
  isDisabled: PropTypes.bool,
  setFiles: PropTypes.func.isRequired,
};

UploadInput.defaultProps = {
  isDisabled: false,
};
