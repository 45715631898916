import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    verticalAlign: 'middle'
  },
  input: {
    height: 20,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    padding: theme.spacing(0.75),
    border: theme.palette.border.lightGrey
  },
  error: {
    border: `1.5px solid ${theme.palette.error.main}`
  },
  disabledInput: {
    backgroundColor: 'transparent',
    textAlign: 'center'
  }
}));

export default function TextInput(props) {
  const {
    isEditMode,
    name,
    type,
    value,
    disabled,
    error,
    isPrice,
    inputLimit,
    setFieldValue,
    staticValue,
    inputFieldProps,
    displayErrorHints,
  } = props;
  const {
    InputProps,
    ...otherInputFieldProps
  } = inputFieldProps;
  const classes = useStyles();

  const renderStaticValue = () => {
    if (isPrice) {
      return (
        <Typography variant="body2">
          {new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'EUR',
          }).format(value)}
        </Typography>
      );
    } else if (!isPrice && value) {
      return <Typography variant="body2">{staticValue || value}</Typography>;
    } else {
      return <EmptyTableField />;
    }
  };

  return (
    <>
      {isEditMode && <TextField
        type={type}
        name={name}
        classes={{ root: classes.textFieldRoot }}
        InputProps={{
          ...InputProps,
          classes: {
            input: classNames(classes.input, error && classes.error),
            disabled: classes.disabledInput,
            ...InputProps?.classes
          },
          disableUnderline: InputProps?.disableUnderline ?? true,
          startAdornment: InputProps?.startAdornment ?? (isPrice && <InputAdornment position="start">€</InputAdornment>),
          inputProps: {
            min: 0,
            maxLength: inputLimit,
            ...InputProps?.inputProps
          }
        }}
        disabled={disabled}
        value={value || ''}
        onChange={setFieldValue}
        fullWidth
        error={!!error}
        helperText={displayErrorHints ? error : null}
        {...otherInputFieldProps}
      />}

      {!isEditMode && renderStaticValue()}
    </>
  );
}

TextInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  isPrice: PropTypes.bool,
  type: PropTypes.string,
  inputLimit: PropTypes.number,
  setFieldValue: PropTypes.func,
  isEditMode: PropTypes.bool,
  staticValue: PropTypes.string,
  inputFieldProps: PropTypes.object,
  displayErrorHints: PropTypes.bool,
};

TextInput.defaultProps = {
  disabled: false,
  setFieldValue: () => {},
  error: null,
  isPrice: false,
  type: 'text',
  value: '',
  inputLimit: null,
  isEditMode: false,
  staticValue: null,
  inputFieldProps: {},
  displayErrorHints: false,
};
