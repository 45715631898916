/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_VENDOR_ACCOUNT } from 'graphql/query/vendors';
import {
  GET_PRODUCT_CATEGORIES,
  GET_VENDOR_CATEGORIES,
  GET_IMAGES_FOR_PRODUCT_CATEGORIES,
} from 'graphql/query/categories';

import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CategoryCard from 'components/Categories/CategoryCard';
import CategoryModal from 'components/Categories/CategoryModal';
import SubCategoryModal from 'components/Categories/SubCategoryModal';

const useStyles = makeStyles(theme => ({
  settingContainer: {
    width: 1080
  },
  vendorCategories: {
    width: '45%',
    padding: `${theme.spacing(5)}px ${theme.spacing(4)}px ${theme.spacing(
      6
    )}px`,
    margin: `0px ${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
    boxSizing: 'border-box',
    minHeight: 750,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.palette.customShadows.popover
  },
  productCategoryItem: {
    alignItems: 'center'
  }
}));

export default function Categories() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpenCategoryModal, setOpenCategoryModal] = React.useState(false);
  const [isOpenSubCategoryModal, setOpenSubCategoryModal] = React.useState(
    false
  );
  const [editCategory, setEditCategory] = React.useState(null);
  const [editSubCategory, setEditSubCategory] = React.useState(null);
  const [mappedCategories, setMappedCategories] = React.useState({});
  const [productCategoriesWithImagesMapped, setProductCategoriesWithImagesMapped] = React.useState({});
  const { data, loading } = useQuery(GET_VENDOR_ACCOUNT, {
    fetchPolicy: 'cache-first'
  });

  const [getVendorCategories, { data: vendorCategories }] = useLazyQuery(
    GET_VENDOR_CATEGORIES,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const [getProductCategories, { data: productCategories }] = useLazyQuery(
    GET_PRODUCT_CATEGORIES,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const [
    existingProductCategoriesWithImages,
    { data: productCategoriesWithImages },
  ] = useLazyQuery(GET_IMAGES_FOR_PRODUCT_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  });

  const vendorData = data && data.vendorAccount;

  React.useEffect(() => {
    if (vendorData?._id) {
      existingProductCategoriesWithImages({
        variables: {
          vendorId: vendorData._id
        }
      });
      getVendorCategories({
        variables: {
          vendorId: vendorData._id
        }
      });
      getProductCategories({
        variables: {
          vendorId: vendorData._id
        }
      });
    }
  }, [vendorData]);

  const mapCategoryData = () => {
    if (
      productCategories?.existingProductCategories?.length
    ) {
      const mapped = productCategories?.existingProductCategories?.reduce(
        (ac, a) => ({ ...ac, [a]: '' }),
        {}
      );
      if (isEmpty(productCategoriesWithImagesMapped) &&
        productCategoriesWithImages?.existingProductCategoriesWithImages?.length
      ) {
        productCategoriesWithImages.existingProductCategoriesWithImages.map(
          cat => (productCategoriesWithImagesMapped[cat.title] = cat.image)
        );
        setProductCategoriesWithImagesMapped(productCategoriesWithImagesMapped);
      }

      // eslint-disable-next-line no-unused-expressions
      vendorCategories?.vendorCategories?.map(category =>
        // eslint-disable-next-line array-callback-return
        category?.mappedCategories?.map(mappedCategory => {
          if (mapped[mappedCategory]?.length) {
            mapped[mappedCategory] = [...mapped[mappedCategory], category?.name];
          } else {
            mapped[mappedCategory] = [category?.name];
          }
        })
      );
      setMappedCategories(mapped);
    }
  };

  React.useEffect(() => {
    mapCategoryData();
  }, [productCategories, vendorCategories, productCategoriesWithImagesMapped]);

  const setEditSubCategoryHandler = ({ title, image }) => {
    if (productCategoriesWithImagesMapped) {
      const productCategoriesWithImagesMappedUpdated = { ...productCategoriesWithImagesMapped };
      productCategoriesWithImagesMappedUpdated[title] = image;
      setProductCategoriesWithImagesMapped(productCategoriesWithImagesMappedUpdated);
    }
  };

  const renderEmptyDataNotice = () =>
    (loading ? (
      <CircularProgress color="primary" />
    ) : (
      <Typography variant="h4">{t('settings.no data available')}</Typography>
    ));

  return vendorData ? (
    <Grid container wrap="nowrap" className={classes.settingContainer}>
      <Grid className={classes.vendorCategories}>
        <Typography variant="h6" paragraph>
          {t('categories.List of parsed product categories')}
        </Typography>
        {Object.entries(mappedCategories).map(([key, values]) => {
          const category = {
            _id: key,
            image: productCategoriesWithImagesMapped[key],
            icon: undefined,
            name: key,
            mappedCategories: values
          };
          return (
            <CategoryCard
              key={key}
              category={category}
              selectCategory={() => {
                setEditSubCategory(category);
                setOpenSubCategoryModal(true);
              }}
            />
          );
        })}
      </Grid>
      <Grid className={classes.vendorCategories}>
        <Typography variant="h6" paragraph>
          {t('categories.List of super-categories')}
        </Typography>
        <CategoryCard
          key="new-category"
          category={{
            _id: 'new',
            name: 'CREATE NEW CATEGORY +'
          }}
          selectCategory={() => {
            setEditCategory(null);
            setOpenCategoryModal(true);
          }}
        />
        {vendorCategories?.vendorCategories?.length > 0 &&
          vendorCategories.vendorCategories
            .filter(
              item =>
                !(
                  item._id.includes('deals') || item._id.includes('allProducts')
                )
            )
            .map(category => (
              <CategoryCard
                key={category._id}
                category={category}
                selectCategory={() => {
                  setEditCategory(category);
                  setOpenCategoryModal(true);
                }}
              />
            ))}
      </Grid>
      <CategoryModal
        vendorId={vendorData._id}
        isOpen={isOpenCategoryModal}
        setEditCategory={setEditCategory}
        handleClose={() => {
          setOpenCategoryModal(false);
          setEditCategory(null);
        }}
        productCategories={productCategories?.existingProductCategories || []}
        editCategory={editCategory}
      />
      <SubCategoryModal
        vendorId={vendorData._id}
        isOpen={isOpenSubCategoryModal}
        setEditCategory={setEditSubCategoryHandler}
        handleClose={() => {
          setOpenSubCategoryModal(false);
          setEditSubCategory(null);
        }}
        editCategory={editSubCategory}
      />
    </Grid>
  ) : (
    renderEmptyDataNotice()
  );
}
