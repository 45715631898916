import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import BackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import GroupSelector from 'components/shared/Inputs/GroupSelector/GroupSelector';
import { pricingTypes } from 'helpers/constants';

const useStyles = makeStyles(
  ({ spacing, palette, breakpoints, typography }) => ({
    headerControls: {
      minHeight: 58,
      display: 'flex',
      alignItems: 'center',
    },
    tableSearchBarButtons: {
      minWidth: 130,
      height: 45,
    },
    tableSearchBarIconButton: {
      minWidth: 90,

      height: 45,
      padding: spacing(0, 3),
      border: palette.border.grey,
      color: palette.secondary.black,
      fontWeight: typography.fontWeightBold,
      borderRadius: spacing(3),
    },

    headerAdditionalActionsWrapper: {},
    addActionsInputContainer: {
      width: '100%',
    },
    addActionsInput: {
      minWidth: 360,
      height: 45,
      fontSize: 14,
      background: palette.background.paper,
      border: palette.border.lightGrey,
      [breakpoints.down('sm')]: {
        width: '100%',
        fontSize: 12,
      },
      [breakpoints.up('lg')]: {
        width: 360,
        fontSize: 16,
      },
      '&:hover': {
        border: `1px solid ${palette.primary.main}`,
      },
    },
    addActionsNotchedInput: {
      border: 'none',
    },
    backButton: {
      marginRight: spacing(1),
    },
    addActionsConfirm: {
      height: 45,
      padding: spacing(0, 3),
      border: palette.border.grey,
      color: palette.secondary.black,
      fontWeight: typography.fontWeightBold,
      borderRadius: spacing(3),
      background: 'transparent',
      textTransform: 'capitalize',
      minWidth: 172,
      marginRight: spacing(1),
      boxShadow: 'none',
      '&:last-child': {
        marginRight: 0,
      },
    },
    activeSearchBarButtons: {
      color: palette.common.white,
      background: palette.primary.main,
      '&:hover': {
        background: palette.primary.light,
      },
      border: 'none',
    },
    cancelButton: {
      minWidth: 'initial',
    },
    pageIndex: {
      // height: 85,
      // backgroundColor: palette.background.container,
      padding: `0 ${spacing(3)}px`,
    },
    priceTypeForm: {
      width: '180px',
    },
    editIcon: {
      fontSize: spacing(2.5),
      marginRight: spacing(1),
    },
    tableTitle: {
      width: '75%',
      height: '100%',
      maxWidth: '500px',
      marginRight: spacing(2),
    },
    groupSelector: {
      display: 'inline-flex',
      minWidth: 300,
      maxWidth: 400,

      '& .MuiChip-root.Mui-disabled': {
        opacity: 1,
      },

      '& .Mui-disabled .MuiAutocomplete-endAdornment': {
        opacity: 0,
      },
    },
    groupSkeleton: {
      position: 'relative',
      top: 11,
    },
    groupSelectorInput: {
      borderRadius: 4,
      border: palette.border.grey,
    },
    groupSelectorInputDisabled: {
      border: '1px solid transparent !important',
    },
  })
);

export default function HeaderActions(props) {
  const {
    bulkEditMode,
    setBulkEditMode,
    handleSaveChanges,
    handleCancelChanges,
    handleAddProducts,
    handleGroupChanges,
    handleChangeCatalogPricingMode,
    isBaseUnitPrice,
    handleBackButtonAction,
    selectedGroups,
    pdfCatalog,
    updatingGroups,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [isAddProductsMode, setIsAddProductsMode] = useState(false);
  const [isChangePricingTypeMode, setIsChangePricingTypeMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [pricingType, setPricingType] = useState(
    isBaseUnitPrice ? pricingTypes.baseUnit : pricingTypes.packaging
  );

  useEffect(() => {
    if (isBaseUnitPrice) {
      setPricingType(pricingTypes.baseUnit);
    } else {
      setPricingType(pricingTypes.packaging);
    }
  }, [isBaseUnitPrice]);

  // NOTE: replace all white spaces and commas to get correct values
  const replaceDelimiters = () =>
    inputValue
      .replace(/\s/g, ',')
      .split(',')
      .filter(value => value !== '');

  const handleChangeInput = event => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue('');
  }, [isAddProductsMode]);

  const renderHeaderControls = () => {
    if (isChangePricingTypeMode) {
      return (
        <Grid container alignItems="center" wrap="nowrap">
          <Button
            onClick={() => {
              handleChangeCatalogPricingMode(
                pricingType || pricingTypes.packaging
              );
              setIsChangePricingTypeMode(false);
            }}
            className={classNames(
              classes.addActionsConfirm,
              classes.activeSearchBarButtons
            )}
          >
            {t('lists.confirm')}
          </Button>
          <Button
            onClick={() => {
              setIsChangePricingTypeMode(false);
              setPricingType(
                isBaseUnitPrice ? pricingTypes.baseUnit : pricingTypes.packaging
              );
            }}
            className={classes.addActionsConfirm}
          >
            {t('common.cancel')}
          </Button>

          <FormControl component="fieldset" className={classes.priceTypeForm}>
            {/* <FormLabel component="legend">Price type</FormLabel> */}
            {/* <InputLabel id="demo-controlled-open-select-label">
              {t('pdfCatalog.Price type')}
            </InputLabel> */}
            <Select
              value={pricingType}
              onChange={e => {
                setPricingType(e.target.value);
              }}
              defaultValue="packaging"
            >
              <MenuItem default value="packaging">
                {t('pdfCatalog.Per packaging')}
              </MenuItem>
              <MenuItem value="baseUnit">
                {t('pdfCatalog.Per base unit')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    } else if (isAddProductsMode) {
      return (
        <Grid container alignItems="center" wrap="nowrap">
          <Button
            onClick={() => {
              handleAddProducts(replaceDelimiters());
              setIsAddProductsMode(false);
            }}
            className={classNames(
              classes.addActionsConfirm,
              classes.activeSearchBarButtons
            )}
          >
            {t('lists.confirm')}
          </Button>
          <Button
            onClick={() => setIsAddProductsMode(false)}
            className={classes.addActionsConfirm}
          >
            {t('common.cancel')}
          </Button>

          <Grid
            className={classes.addActionsInputContainer}
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <TextField
              InputProps={{
                classes: {
                  root: classes.addActionsInput,
                  notchedOutline: classes.addActionsNotchedInput,
                },
              }}
              value={inputValue}
              onChange={handleChangeInput}
              variant="outlined"
              placeholder={t('pdfCatalog.Paste product ids')}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid>
          <Button
            className={classNames(
              classes.addActionsConfirm,
              classes.tableSearchBarButtons
            )}
            disableElevation
            onClick={() => setIsAddProductsMode(!isAddProductsMode)}
          >
            {t('pdfCatalog.Add products')}
          </Button>
          <Button
            className={classNames(
              classes.addActionsConfirm,
              classes.tableSearchBarButtons
            )}
            disableElevation
            onClick={() => setIsChangePricingTypeMode(!isChangePricingTypeMode)}
          >
            {isBaseUnitPrice
              ? t('pdfCatalog.pricingMode baseUnit')
              : t('pdfCatalog.pricingMode packaging')}
          </Button>
          {pdfCatalog ? (
            <GroupSelector
              handleChange={handleGroupChanges}
              selectedGroups={selectedGroups}
              showLoading={updatingGroups}
              autocompleteClass={classes.groupSelector}
              textFieldClass={
                bulkEditMode
                  ? classes.groupSelectorInput
                  : classes.groupSelectorInputDisabled
              }
              disabled={!bulkEditMode}
            />
          ) : (
            <Skeleton
              variant="rect"
              width={300}
              height={32}
              className={classNames(
                classes.groupSelector,
                classes.groupSkeleton
              )}
            />
          )}
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid
        className={classes.headerAdditionalActionsWrapper}
        container
        wrap="nowrap"
        alignItems="center"
        // direction="column"
      >
        <Typography className={classes.tableTitle} variant="h6">
          <IconButton
            className={classes.backButton}
            size="small"
            onClick={handleBackButtonAction}
          >
            <BackIcon color="primary" />
          </IconButton>
          {t('pdfCatalog.singlePdfTitle', {
            title: pdfCatalog?.title?.de
              ? pdfCatalog?.title?.de
              : 'PDF Catalog',
          })}
        </Typography>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid className={classes.headerControls}>
              {renderHeaderControls()}
            </Grid>
          </Grid>
          {bulkEditMode && (
            <Button
              className={classNames(
                classes.addActionsConfirm,
                classes.cancelButton
              )}
              variant="outlined"
              startIcon={<CancelOutlined className={classes.editIcon} />}
              onClick={() => {
                setIsAddProductsMode(false);
                setIsChangePricingTypeMode(false);
                handleCancelChanges();
              }}
            >
              {t('common.cancel')}
            </Button>
          )}
          <Button
            className={classNames(
              classes.tableSearchBarIconButton,
              bulkEditMode && classes.activeSearchBarButtons
            )}
            variant="outlined"
            onClick={() => {
              if (bulkEditMode) {
                handleSaveChanges();
              } else {
                setBulkEditMode(true);
              }
            }}
          >
            <EditIcon className={classes.editIcon} />
            {bulkEditMode ? t('pdfCatalog.Save') : t('pdfCatalog.Edit')}
          </Button>
        </Grid>
      </Grid>

      {/* {pdfCatalog?.parsedPerPage?.length > 1 && (
        <Grid
          className={classes.addActionsInputContainer}
          container
          alignItems="center"
          justifyContent="center"
          wrap="nowrap"
        >
          <Pagination
            count={pdfCatalog?.parsedPerPage?.length || 0}
            shape="round"
            page={productPage}
            color="primary"
            classes={{
              ul: classes.paginationUlElement,
            }}
            onChange={(e, pageNumber) => {
              setProductPage(pageNumber);
            }}
          />
        </Grid>
      )} */}
    </>
  );
}

HeaderActions.propTypes = {
  bulkEditMode: PropTypes.bool.isRequired,
  setBulkEditMode: PropTypes.func.isRequired,
  handleGroupChanges: PropTypes.func.isRequired,
  handleSaveChanges: PropTypes.func.isRequired,
  handleCancelChanges: PropTypes.func.isRequired,
  handleAddProducts: PropTypes.func.isRequired,
  handleChangeCatalogPricingMode: PropTypes.func.isRequired,
  isBaseUnitPrice: PropTypes.bool.isRequired,
  handleBackButtonAction: PropTypes.func.isRequired,
  pdfCatalog: PropTypes.object,
  selectedGroups: PropTypes.array,
  updatingGroups: PropTypes.bool,
};

HeaderActions.defaultProps = {
  pdfCatalog: undefined,
  selectedGroups: [],
  updatingGroups: false,
};
