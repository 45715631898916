/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { LOGIN_USER, CHECK_USER, SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as OrangeLogoIcon } from 'assets/LogoOrange.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  logo: {
    width: 36,
    height: 36,
  },
  loginHeader: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '26px',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2),
    borderRadius: 4,
    border: theme.palette.border.lightGrey,
  },
  input: {
    height: 20,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  formControl: {
    top: 16
  },
  signInButton: {
    height: 50,
    boxShadow: 'none'
  },
  pageContainer: {
    padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
    borderRadius: 8,
    maxWidth: 560,
    minHeight: 560,
    background: theme.palette.background.paper,
    boxShadow: theme.palette.customShadows.popover,
  },
  forgotPassword: {
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '-0.02em',
    width: '100%',
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  },
  rememberMeCheckbox: {
    fontSize: theme.typography.fontSize,
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.darkGray
  }
}));

const Login = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const mixPanel = useMixPanel();

  const [showPassword, setShowingPassword] = React.useState(false);
  const [isRememberChecked, checkRememberMe] = React.useState(true);
  const { register, handleSubmit, errors } = useForm();

  const [login, { error }] = useMutation(LOGIN_USER);
  const [checkUser] = useMutation(CHECK_USER);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  if (error) {
    setNotification({
      variables: {
        timeout: 4000,
        // eslint-disable-next-line camelcase
        message: error?.response?.data?.error_description || 'error',
        type: NOTIFICATION_STATUS.ALERT,
        isOpen: true,
      },
    });
  }

  React.useEffect(() => {
    if (props.error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: props.error,
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      checkUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  const handleLoginClick = async (data) => {
    setNotification({
      variables: {
        timeout: 0,
        message: '',
        type: NOTIFICATION_STATUS.SUCCESS,
        isOpen: false,
      },
    });
    const response = await login({ variables: { email: data.email.toLowerCase(), password: data.password, isRememberChecked } });
    if (response?.data?.login?.error) {
      mixPanel.track('Fresh Login Failed', {
        email: response?.data?.login?.email,
        error: response?.data?.login?.error,
        isRememberChecked: response?.data?.login?.isRememberChecked,
      });
    } else {
      mixPanel.track('Fresh Login Success', { email: data.email.toLowerCase() });
    }
    history.push('/customers');
  };

  const handlePasswordVisibility = () => {
    setShowingPassword(prevState => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeRememberMe = (event) => {
    checkRememberMe(event.target.checked);
  };

  return (
    <Grid container justifyContent="center" className={classes.pageContainer} direction="column">
      <div>
        <OrangeLogoIcon className={classes.logo} />
      </div>
      <Typography variant="h4" style={{ fontWeight: 'normal' }}>{t('login.welcome')}</Typography>
      <Typography className={classes.loginHeader}>
        {t('login.headerText')}
      </Typography>

      <form onSubmit={handleSubmit(handleLoginClick)} noValidate>
        <TextField
          className={classes.textField}
          id="email"
          label={t('common.email')}
          type="email"
          name="email"
          autoComplete="email"
          variant="outlined"
          autoFocus
          required
          fullWidth
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl
            }
          }}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
          inputRef={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i,
              message: t('login.invalid email'),
            },
          })}
        />
        <TextField
          className={classes.textField}
          id="password"
          label={t('common.password')}
          type={showPassword ? 'text' : 'password'}
          name="password"
          autoComplete="current-password"
          variant="outlined"
          required
          fullWidth
          InputProps={{
            classes: {
              input: classes.input,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handlePasswordVisibility}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl
            }
          }}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
          inputRef={register({
            required: 'Required',
            message: t('login.password required'),
          })}
        />
        <Grid container>
          <Typography
            onClick={() => history.push('/forgot-password')}
            className={classes.forgotPassword}
            variant="body1"
            color="primary"
            align="right"
          >
            {t('login.forgot password')}
          </Typography>

          <Grid item xs={12}>
            <FormControlLabel
              className={classes.rememberMeCheckbox}
              onChange={handleChangeRememberMe}
              control={<Checkbox checked={isRememberChecked} />}
              label={t('login.remember')}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.signInButton}
        >
          {t('login.sign in')}
        </Button>
      </form>
    </Grid>
  );
};

Login.defaultProps = {
  error: null
};

Login.propTypes = {
  error: PropTypes.string,
};

export default Login;
