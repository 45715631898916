import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteRounded';

const useStyles = makeStyles(({ palette, spacing }) => ({
  uploadLogoContainer: {
    width: 'fit-content',
    position: 'relative',
    transition: 'all 0.25s ease-in',
  },
  uploadLogoImageContainer: {
    cursor: 'pointer',
    marginBottom: spacing(2),
  },
  uploadLogoImagePreview: {
    width: 130,
    height: 130,
    background: palette.background.paper,
    border: `2px dashed ${palette.background.border}`,
    borderRadius: 8,
    position: 'relative',
    boxSizing: 'content-box',
    padding: spacing(0.25),
  },
  disableUploadImage: {
    background: palette.background.mutedLight,
    color: palette.text.secondary,
    pointerEvents: 'none',
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  selectProductImage: {
    border: `1px solid ${palette.primary.main}`,
    borderRadius: 120,
    padding: spacing(0.75, 1),
  },
  uploadImagePreview: {
    maxHeight: 130,
    maxWidth: 130,
    display: 'block',
  },
  imagePreview: {
    width: '100%',
    height: '100%',
    maxHeight: 130,
    maxWidth: 130,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}));

function NewsletterProductImageUploader({
  imageUrl,
  disableUploader,
  defaultProductImage,
  handleChangeImage,
  handleDeleteProductImage,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    disabled: disableUploader,
    onDrop: acceptedFiles => {
      handleChangeImage('image', acceptedFiles[0]);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => {
      if (imageUrl || defaultProductImage) {
        setFiles([
          {
            preview: imageUrl || defaultProductImage,
          },
        ]);
      }
      return () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageUrl]
  );

  const handleResetImage = event => {
    event.stopPropagation();
    if (defaultProductImage) {
      setFiles([{ preview: defaultProductImage }]);
      handleChangeImage('imageUrl', defaultProductImage);
    } else {
      setFiles([]);
      handleDeleteProductImage();
    }
  };

  const isImageUploaded = !!files[0];
  const isDefaultProductImage = isImageUploaded
    ? files[0].preview === defaultProductImage
    : false;

  return (
    <Grid className={classes.uploadLogoContainer}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        {...getRootProps({ className: classes.uploadLogoImageContainer })}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classNames(
            classes.uploadLogoImagePreview,
            disableUploader && classes.disableUploadImage
          )}
        >
          <input {...getInputProps()} />
          {files?.length ? (
            files.map(file => (
              <div
                key="uploaded-logo"
                className={classes.imagePreview}
                style={{ backgroundImage: `url(${file.preview})` }}
              />
            ))
          ) : (
            <Typography
              className={classes.selectProductImage}
              variant="caption"
              color="primary"
            >
              {t('newsletter.select image')}
            </Typography>
          )}
        </Grid>
      </Grid>
      {isImageUploaded && !isDefaultProductImage && (
        <IconButton
          size="small"
          className={classes.deleteButton}
          color="default"
          title={t('newsletter.delete image')}
          onClick={handleResetImage}
        >
          <DeleteIcon color="inherit" />
        </IconButton>
      )}
    </Grid>
  );
}

NewsletterProductImageUploader.defaultProps = {
  imageUrl: undefined,
  defaultProductImage: undefined,
  disableUploader: false,
};

NewsletterProductImageUploader.propTypes = {
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultProductImage: PropTypes.string,
  disableUploader: PropTypes.bool,
  handleChangeImage: PropTypes.func.isRequired,
  handleDeleteProductImage: PropTypes.func.isRequired,
};

export default React.memo(
  NewsletterProductImageUploader,
  (prevProps, nextProps) =>
    prevProps.imageUrl === nextProps.imageUrl &&
    prevProps.disableUploader === nextProps.disableUploader
);
