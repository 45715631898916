import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

export default function EmptyTableField({ align, className, text }) {
  return (
    <Typography align={align || 'left'} variant="body2" color="textSecondary" className={className}>
      {text}
    </Typography>
  );
}

EmptyTableField.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
};

EmptyTableField.defaultProps = {
  align: null,
  className: '',
  text: 'NA',
};
