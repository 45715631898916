import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';

import DeleteGroupModal from 'components/shared/DeleteModal';
import { useSharedTableStyles } from 'components/shared/styles/sharedTableStyles';
import GroupsAssignedCustomersCell from 'components/CustomGroups/GroupsAssignedCustomersCell';

const useStyles = makeStyles(({ palette, typography }) => ({
  groupName: {
    fontWeight: typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  colorSample: ({ color }) => ({
    background:
      color ||
      'linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)',
    border: palette.border.contrast,
    height: 20,
    width: 20,
    borderRadius: '50%',
  }),
}));

function GroupsTableRow({
  rowData,
  selectedGroup,
  executeScroll,
  groupRef,
  rowsPerPage,
  handleDeleteGroup,
  disableActionButtons,
}) {
  const { name, color, customers = {} } = rowData;

  const classes = useStyles({ color });
  const sharedTableStyles = useSharedTableStyles();

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const openListDetailsTable = async id => {
    history.replace({
      pathname: '/groups',
      search: queryString.stringify({
        ...queryString.parse(location.search),
        id,
      }),
    });
    if (rowsPerPage && rowsPerPage !== 0) {
      history.push(`group/${id}?rowsPerPage=${rowsPerPage}`);
    } else {
      history.push(`group/${id}`);
    }
  };

  React.useEffect(() => {
    if (selectedGroup === rowData._id) {
      executeScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRef = selectedGroup === rowData._id ? groupRef : null;

  return (
    <TableRow
      className={classNames(
        sharedTableStyles.tableBodyRow,
        setRef && sharedTableStyles.highlightRow
      )}
      ref={setRef}
    >
      <TableCell
        className={classNames(
          sharedTableStyles.tableCell,
          sharedTableStyles.tableLeftPadding
        )}
      >
        <Grid container direction="column" wrap="nowrap">
          <Typography className={classes.groupName} variant="body2">
            {name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${t('common.created')}: ${moment(rowData.createdAt).format(
              'DD/MM/YYYY'
            )}`}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <div className={classes.colorSample} />
      </TableCell>
      {/* TODO: Show customers list in a popup here */}
      <TableCell className={sharedTableStyles.tableCell}>
        <GroupsAssignedCustomersCell
          customers={customers}
          handleOpenAllCustomers={() => openListDetailsTable(rowData._id)}
        />
      </TableCell>
      <TableCell
        className={classNames(
          sharedTableStyles.tableCell,
          sharedTableStyles.actionButtonContainer
        )}
      >
        <Grid container justifyContent="center" wrap="nowrap">
          <Button
            variant="contained"
            classes={{ root: sharedTableStyles.tableActionButton }}
            onClick={() => openListDetailsTable(rowData._id)}
            disabled={disableActionButtons}
          >
            <EditIcon className={sharedTableStyles.tableActionButtonIcon} />
          </Button>
          <Button
            variant="contained"
            classes={{ root: sharedTableStyles.tableActionButton }}
            onClick={() => setOpenDeleteModal(true)}
            disabled={disableActionButtons}
          >
            <DeleteIcon className={sharedTableStyles.tableActionButtonIcon} />
          </Button>
        </Grid>
      </TableCell>
      <DeleteGroupModal
        isOpen={openDeleteModal}
        message={t('groups.delete group modal message', { name })}
        handleCloseModal={() => setOpenDeleteModal(false)}
        handleDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteGroup(rowData._id, rowData.name);
        }}
      />
    </TableRow>
  );
}

GroupsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  selectedGroup: PropTypes.string,
  groupRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  executeScroll: PropTypes.func.isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number,
  disableActionButtons: PropTypes.bool,
};

GroupsTableRow.defaultProps = {
  selectedGroup: null,
  rowsPerPage: null,
  disableActionButtons: false,
};

export default React.memo(
  GroupsTableRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.rowData, nextProps.rowData) &&
    prevProps.disableActionButtons === nextProps.disableActionButtons
);
