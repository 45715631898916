/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';

import {
  ASSIGN_CUSTOMERS_TO_CUSTOM_GROUP,
  UNASSIGN_CUSTOMERS_TO_CUSTOM_GROUP,
} from 'graphql/mutation/groups';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  GET_CUSTOM_VENDOR_GROUP_CONNECTION_BY_ID,
  GET_CUSTOM_VENDOR_GROUP_BY_ID,
  SEARCH_CUSTOMERS_IN_CUSTOM_VENDOR_GROUP,
} from 'graphql/query/groups';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import LinearProgress from '@material-ui/core/LinearProgress';

import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import useMixPanel from 'helpers/useMixPanel';
import {
  NOTIFICATION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import TableLayout from 'components/shared/TableLayout';
import GroupAssignment from 'components/CustomGroups/GroupAssignment';
import GroupDetailsTableRow from 'components/CustomGroups/GroupDetailsTableRow';
import UpdateCustomGroupModal from 'components/CustomGroups/UpdateCustomGroupModal';

const useStyles = makeStyles(({ spacing, typography }) => ({
  deleteButton: {
    textAlign: 'center',
  },
  updateGroupButton: {
    height: 45,
    fontWeight: typography.fontWeightRegular,
    textWrap: 'nowrap',
    padding: spacing(1, 2),
    marginLeft: spacing(2),
    borderRadius: 8,
  },
  removeSelectedButton: {
    minWidth: 'fit-content',
  },
}));

export default function Group({ groupId }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [
    assignCustomersMutation,
    { loading: isAssignmentMutationLoading },
  ] = useMutation(ASSIGN_CUSTOMERS_TO_CUSTOM_GROUP);
  const [
    removeCustomerAssignmentMutation,
    { loading: isRemoveAssignmentMutationLoading },
  ] = useMutation(UNASSIGN_CUSTOMERS_TO_CUSTOM_GROUP);

  const [inputValue, setInputValue] = React.useState('');
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
  });
  const [showSearchResults, setShowSearchResult] = useState(false);
  const [isEditGroupModalOpen, setEditGroupModalOpen] = useState(false);
  const [selected, setSelected] = React.useState([]);

  const [
    getCustomVendorGroup,
    { loading: isGroupLoading, error: isGroupError, data },
  ] = useLazyQuery(GET_CUSTOM_VENDOR_GROUP_CONNECTION_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
  const { error: isGroupDataError, data: groupById } = useQuery(
    GET_CUSTOM_VENDOR_GROUP_BY_ID,
    {
      variables: {
        _id: groupId,
      },
    }
  );
  const [
    searchCustomersInVendorGroup,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(SEARCH_CUSTOMERS_IN_CUSTOM_VENDOR_GROUP, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const groupConnectionData = data && data.getCustomGroupConnectionById;
  const groupData = groupById && groupById.getCustomGroupById;

  //
  // EFFECTS
  //

  useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;

    getCustomVendorGroup({
      variables: {
        _id: groupId,
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder,
      },
    });

    if (pageParam || rowsParam) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
      });
    }
  }, []);

  useEffect(() => {
    const totalPages = groupConnectionData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (
      groupConnectionData?.customers &&
      (pageNumberExceeded || rowsPerPageExceeded)
    ) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [groupConnectionData]);

  //
  // HANDLERS
  //

  // NOTE: replace all white spaces and commas to get correct values
  const replaceDelimiters = () =>
    inputValue
      .replace(/\s/g, ',')
      .split(',')
      .filter(value => value !== '');

  const handleAssignCustomers = async () => {
    const parsedCustomerIds = replaceDelimiters();
    if (!parsedCustomerIds.length) return;

    try {
      await assignCustomersMutation({
        variables: {
          groupId,
          customerIds: parsedCustomerIds,
        },
        refetchQueries: [
          {
            query: GET_CUSTOM_VENDOR_GROUP_CONNECTION_BY_ID,
            variables: {
              _id: groupId,
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
            },
          },
        ],
        update: (
          _,
          {
            data: {
              assignCustomersToCustomGroup: {
                failedCustomerIds,
                customerIds,
                existingCustomerIds,
                updatedCustomerIds,
              },
            },
          }
        ) => {
          if (failedCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('groups.error add customers', {
                  customers: failedCustomerIds.join(', '),
                  count: failedCustomerIds.length,
                  name: groupData?.name,
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else if (existingCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('groups.customers already exist', {
                  customers: existingCustomerIds.join(', '),
                  count: existingCustomerIds.length,
                  name: groupData?.name,
                  updated: updatedCustomerIds.length,
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('Vendor Custom Groups - Assign Customers', {
              groupId,
              customers: customerIds.join(', '),
              count: customerIds.length,
            });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('groups.success add customers', {
                  customers: customerIds.join(', '),
                  count: customerIds.length,
                  name: groupData?.name,
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        },
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleRemoveAssignment = async customerIdsToUnassign => {
    if (!customerIdsToUnassign.length) return;

    try {
      await removeCustomerAssignmentMutation({
        variables: {
          groupId,
          customerIds: customerIdsToUnassign,
        },
        refetchQueries: [
          {
            query: GET_CUSTOM_VENDOR_GROUP_CONNECTION_BY_ID,
            variables: {
              _id: groupId,
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
            },
          },
        ],
        update: (
          cache,
          {
            data: {
              unassignCustomersFromCustomGroup: {
                customerIds,
                failedCustomerIds,
                updatedCustomerIds,
              },
            },
          }
        ) => {
          const cacheData = cache.readQuery({
            query: GET_CUSTOM_VENDOR_GROUP_CONNECTION_BY_ID,
            variables: {
              _id: groupId,
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
            },
          });
          const filteredCustomers = cacheData?.getCustomGroupConnectionById?.customers?.filter(
            customer => !updatedCustomerIds.includes(customer.vendorClientId)
          );
          cache.writeQuery({
            query: GET_CUSTOM_VENDOR_GROUP_CONNECTION_BY_ID,
            variables: {
              _id: groupId,
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
            },
            data: {
              getCustomGroupConnectionById: {
                ...cacheData.getCustomGroupConnectionById,
                customers: filteredCustomers,
              },
            },
          });

          if (failedCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('groups.error remove customers', {
                  customers: failedCustomerIds.join(', '),
                  count: failedCustomerIds.length,
                  name: groupData?.name,
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('Vendor Custom Groups - Unassign Customers', {
              groupId,
              customers: customerIds.join(', '),
              count: customerIds.length,
            });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('groups.success remove customers', {
                  customers: customerIds.join(', '),
                  count: customerIds.length,
                  name: groupData?.name,
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        },
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleUnassignInBulk = () => {
    const parsedCustomerIds = replaceDelimiters();
    handleRemoveAssignment(parsedCustomerIds);
  };

  const handleRemoveSingleAssignment = vendorClientId => () => {
    if (!vendorClientId) return;
    handleRemoveAssignment([vendorClientId]);
  };

  const handleUnassignSelected = () => {
    handleRemoveAssignment(selected);
    setSelected([]);
  };

  const handleOpenNewGroupModal = () => {
    setEditGroupModalOpen(true);
  };

  const handleCloseEditGroupModal = () => {
    setEditGroupModalOpen(false);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked && groupConnectionData.customers?.length) {
      const newSelected = groupConnectionData.customers.map(
        n => n.vendorClientId
      );
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleSelectItem = id => () => {
    setSelected(
      selected.includes(id)
        ? selected.filter(selectedId => selectedId !== id)
        : selected.concat(id)
    );
  };

  //
  // PAGE HANDLERS
  //

  const handleChangePage = async (event, page) => {
    if (
      groupConnectionData &&
      (groupConnectionData.hasNextPage ||
        page + 1 < groupConnectionData.totalPages)
    ) {
      try {
        await getCustomVendorGroup({
          variables: {
            _id: groupId,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getCustomVendorGroup({
        variables: {
          _id: groupId,
          after: 1,
          pageSize: rowsPerPage,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }

    setState({ ...state, rowsPerPage, page: 0 });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getCustomVendorGroup({
        variables: {
          _id: groupId,
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const handleBackButtonAction = () => {
    history.goBack();
  };

  const handleSearchBar = async searchPhrase => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await searchCustomersInVendorGroup({
        variables: {
          searchPhrase,
          groupId,
          pageSize: state.rowsPerPage,
        },
      });
    } catch (error) {
      console.error('SEARCH_ERROR', error.message);
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: t('customers.customer number'),
        field: 'vendorClientId',
        sortable: false,
      },
      {
        title: t('settings.business'),
        field: 'contactData.businessName',
        sortable: false,
        columnStyles: classes.salesRepTableTitle,
      },
      {
        title: t('common.delete'),
        field: '',
        sortable: false,
        columnStyles: classes.deleteButton,
      },
    ],
    [classes]
  );

  const { page, rowsPerPage, sortBy, sortOrder } = state;

  const hasError =
    Boolean(isGroupError) ||
    Boolean(isGroupDataError) ||
    (!isGroupLoading && !groupConnectionData) ||
    (groupConnectionData && !groupConnectionData.customers?.length);

  const searchResultHasError =
    (showSearchResults &&
      !searchData?.searchCustomersInCustomVendorGroup?.customers?.length) ||
    searchError;

  const emptyTableData =
    (hasError && !showSearchResults) || searchResultHasError;

  const tableIsLoading = isGroupLoading || searchLoading;
  const isAssignMutationsLoading =
    isAssignmentMutationLoading || isRemoveAssignmentMutationLoading;

  const totalResults = () => {
    if (showSearchResults) {
      return searchResultHasError
        ? 0
        : searchData.searchCustomersInCustomVendorGroup?.customers?.length;
    } else {
      return hasError ? 0 : groupConnectionData?.totalResults;
    }
  };

  const showRegularResults =
    !showSearchResults &&
    groupConnectionData &&
    groupConnectionData?.customers?.length > 0;

  return (
    <>
      <TableLayout
        emptyTableData={emptyTableData}
        emptyTableDataMessage={t('groups.empty group data')}
        tableIsLoading={tableIsLoading}
        title={groupData && groupData?.name}
        columns={groupConnectionData ? tableColumns : []}
        skeletonType={TABLE_SKELETON_TYPES.LIST}
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={totalResults() || 0}
        withSearchBar
        searchPlaceholder={t('customers.search placeholder')}
        handleSearchBar={handleSearchBar}
        handleShowSearchResult={setShowSearchResult}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        backButtonAction={handleBackButtonAction}
        hasCheckBox
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        itemsOnPage={groupConnectionData?.customers?.length}
        headerActions={[
          <Grow key="remove selected" in={!!selected.length}>
            <Button
              onClick={handleUnassignSelected}
              className={classNames(
                classes.removeSelectedButton,
                classes.updateGroupButton
              )}
              variant="outlined"
              color="secondary"
              startIcon={<RemoveCircleRoundedIcon color="secondary" />}
            >
              {t('groups.remove selected')}
            </Button>
          </Grow>,
          <Button
            key="edit group"
            onClick={handleOpenNewGroupModal}
            className={classes.updateGroupButton}
            variant="outlined"
            color="primary"
            startIcon={<EditRoundedIcon color="primary" />}
          >
            {t('common.edit')}
          </Button>,
        ]}
        headerAdditionalActions={[
          <GroupAssignment
            key="group-assignment"
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleAssignCustomers={handleAssignCustomers}
            handleRemoveAssignment={handleUnassignInBulk}
          />,
          <Grid key="loader" container>
            {isAssignMutationsLoading && (
              <LinearProgress
                className={classes.groupsTableLoader}
                color="primary"
              />
            )}
          </Grid>,
        ]}
      >
        {showRegularResults &&
          groupConnectionData.customers.map(rowData => (
            <GroupDetailsTableRow
              key={rowData._id}
              rowData={rowData}
              handleRemoveSingleAssignment={handleRemoveSingleAssignment}
              isActionButtonDisabled={isRemoveAssignmentMutationLoading}
              selected={selected}
              handleSelectItem={handleSelectItem}
            />
          ))}
        {showSearchResults &&
          !searchResultHasError &&
          searchData?.searchCustomersInCustomVendorGroup?.customers?.map(
            rowData => (
              <GroupDetailsTableRow
                key={rowData._id}
                rowData={rowData}
                handleRemoveSingleAssignment={handleRemoveSingleAssignment}
                isActionButtonDisabled={isRemoveAssignmentMutationLoading}
                selected={selected}
                handleSelectItem={handleSelectItem}
              />
            )
          )}
      </TableLayout>
      <UpdateCustomGroupModal
        isOpen={isEditGroupModalOpen}
        groupData={groupData}
        handleClose={handleCloseEditGroupModal}
      />
    </>
  );
}

Group.propTypes = {
  groupId: PropTypes.string,
};

Group.defaultProps = {
  groupId: '',
};
