/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { POPULAR_COLORS } from '../localConstantsAndHelpers';

export default function NewsletterColorPicker({ color, onChangeComplete }) {
  const [colorState, setColorState] = useState('');

  useEffect(() => {
    if (color) {
      setColorState(color?.hex || color);
    }
  }, []);

  const handleColorChange = (colorValue) => {
    setColorState(colorValue.hex);
  };

  const handleColorChangeComplete = (colorValue) => {
    onChangeComplete(colorValue.hex);
  };

  return (
    <SketchPicker
      color={colorState}
      onChange={handleColorChange}
      onChangeComplete={handleColorChangeComplete}
      disableAlpha
      presetColors={POPULAR_COLORS}
      styles={{
        default: {
          picker: {
            borderRadius: 8,
            boxShadow: 'none',
            border: '1px solid #C7C9CC',
            padding: 16,
            width: 220,
            height: 300,
            boxSizing: 'border-box'
          },
        }
      }}
    />
  );
}

NewsletterColorPicker.propTypes = {
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChangeComplete: PropTypes.func.isRequired,
};

NewsletterColorPicker.defaultProps = {
  color: ''
};
