import React from 'react';
import queryString from 'query-string';
import { useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { ROWS_PER_PAGE_OPTIONS } from '../helpers/constants';
import { GET_DEALS } from '../graphql/query/deals';

function useDealTable(dealType) {
  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [selected, setSelected] = React.useState([]);
  const [isAddDealModalOpen, setAddDealModalOpened] = React.useState(false);
  const [editModeId, setEditModeId] = React.useState(null);
  const [editConfirmationModal, setConfirmEditChanges] = React.useState(false);
  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'name',
    sortOrder: -1
  });

  const [getVendorDeals, {
    loading: dealsLoading,
    error: dealsError,
    data,
  }] = useLazyQuery(
    GET_DEALS,
    {
      fetchPolicy: dealType ? 'no-cache' : 'cache-and-network',
      nextFetchPolicy: dealType ? 'no-cache' : 'cache-first',
    }
  );

  const dealsData = data?.vendorDealProducts;

  function refetchDeals() {
    getVendorDeals({
      variables: {
        dealType,
        pageSize: state.rowsPerPage,
        after: 1,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder
      },
    });
  }

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getVendorDeals({
      variables: {
        dealType,
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const totalPages = dealsData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && (totalPages <= state.page);
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(state.rowsPerPage);

    if (dealsData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.push({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        })
      });
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsData]);

  const handleSelectItem = (event, id) => {
    setSelected(selected.includes(id) ?
      selected.filter(selectedId => selectedId !== id) : selected.concat(id));
  };

  const handleSetEditMode = (id) => {
    if (!editModeId) {
      setEditModeId(id);
    } else if (id === null) {
      setEditModeId(null);
      setConfirmEditChanges(false);
    } else if (id !== editModeId) {
      setConfirmEditChanges(true);
    }
  };

  const handleChangePage = async (event, page) => {
    if (dealsData && (dealsData.hasNextPage || page + 1 < dealsData.totalPages)) {
      try {
        getVendorDeals({
          variables: {
            dealType,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({ ...queryString.parse(location.search), page })
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      getVendorDeals({
        variables: {
          dealType,
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0
      })
    });
  };

  const handleSelectAllClick = event => {
    if (event.target.checked && dealsData?.products?.length) {
      const newSelected = dealsData.products.map(n => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      getVendorDeals({
        variables: {
          dealType,
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, sortOrder, sortBy: sortLabel, page: 0
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder
      })
    });
  };

  const {
    page, rowsPerPage, sortBy, sortOrder
  } = state;

  const hasError = dealsError || (!dealsLoading && !dealsData);

  return {
    dealsData,
    hasError,
    dealsLoading,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    handleSortRows,
    refetchDeals,

    selected,
    editModeId,
    isAddDealModalOpen,
    handleSelectItem,
    handleSetEditMode,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelectAllClick,

    editConfirmationModal,

    setSelected,
    setConfirmEditChanges,
    setAddDealModalOpened,
  };
}

export default useDealTable;
