/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const CREATE_NEW_EXPIRING_DEAL = gql`
  mutation addExpiringDeals($deals: [ProductExpiringDealInput]!) {
    addExpiringDeals(deals: $deals) {
      notUpdated
      notUpdated
      products {
        _id
        name
        description
        vendorId
        vendorSpecificId
        enabled
        discontinued
        createdAt
        updatedAt
        image
        categories
        dealDetails{

          permanent
          start
          end
        }
        variants{
          _id
          baseUnit
          packaging
          unitsInPackaging
          price
          taxRate
          dealPrice
        }
      }
    }
  }
`;

export const CREATE_NEW_DEAL = gql`
  mutation addDeals($products: [ProductDealInput]!) {
    addDeals(products: $products){
      notUpdated
      products {
        _id
        name
        description
        vendorId
        vendorSpecificId
        enabled
        discontinued
        createdAt
        updatedAt
        image
        categories
        dealDetails{

          permanent
          start
          end
        }
        variants{
          _id
          baseUnit
          packaging
          unitsInPackaging
          price
          taxRate
          dealPrice
        }
      }
    }
  }
`;

export const DELETE_DEALS = gql`
mutation deleteDeals(
  $productIds: [ID]!,
){
  deleteDeals(
    productIds: $productIds
  ){
    products{
      _id
      name
      description
      vendorId
      vendorSpecificId
      enabled
      discontinued
      createdAt
      updatedAt
      image
      categories
      dealDetails{
        permanent
        end
        start
      }
      variants{
        _id
        baseUnit
        packaging
        unitsInPackaging
        price
        taxRate
        dealPrice
      }
    }
    notUpdated
  }
}`;

export const UPDATE_DEALS = gql`
  mutation  updateDeals(
    $products: [ProductDealInput]!,
  ){
    updateDeals(
      products: $products,
    ){
      products {
        _id
        name
        description
        vendorId
        vendorSpecificId
        enabled
        discontinued
        createdAt
        updatedAt
        image
        categories
        dealDetails{
          permanent
          end
          start
        }
        variants{
          _id
          baseUnit
          packaging
          unitsInPackaging
          price
          taxRate
          dealPrice
        }
      }
      notUpdated
    }
  }`;
export const UPDATE_EXPIRING_DEALS = gql`
  mutation  updateExpiringDeals(
    $products: [ProductUpdateVariantInput]!,
    $dealType: DealType!
  ){
    updateExpiringDeals(
      products: $products,
      dealType: $dealType
    ){
      products {
        _id
        name
        description
        vendorId
        vendorSpecificId
        enabled
        discontinued
        createdAt
        updatedAt
        image
        categories
        dealDetails{
          permanent
          end
          start
        }
        variants{
          _id
          baseUnit
          packaging
          unitsInPackaging
          price
          taxRate
          dealPrice
        }
      }
      notUpdated
    }
  }`;

export const DELETE_EXPIRING_DEALS = gql`
  mutation deleteExpiringDeals(
    $products: [DeleteExpiringDealsInput]!,
  ){
    deleteExpiringDeals(
      products: $products,
    ){
      products {
        _id
        name
        description
        vendorId
        vendorSpecificId
        enabled
        discontinued
        createdAt
        updatedAt
        image
        categories
        dealDetails{
          permanent
          end
          start
        }
        variants{
          _id
          baseUnit
          packaging
          unitsInPackaging
          price
          taxRate
          dealPrice
        }
      }
      notUpdated
    }
  }
`;
