import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import DropDowIcon from '@material-ui/icons/ArrowDropDown';

import COUNTRIES_LIST from 'helpers/listOfCountries';

const useStyles = makeStyles(theme => ({
  countrySelectorContainer: {
    height: 36
  },
  input: {
    height: 20,
    padding:
    `${theme.spacing(1)}px ${theme.spacing(1.75)}px ${theme.spacing(1)}px ${theme.spacing(0.75)}px`,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
  iconButton: {
    width: 36
  },
  selector: {
    width: '100%',
    height: 38,
    paddingLeft: theme.spacing(1.5),
  },
  popoverContainer: {
    width: 250,
    height: 300,
    boxShadow: theme.palette.customShadows.popover
  },
  activeSelector: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255, 81, 0, 0.15)'
  }
}));

export default function DetailPanelCountrySelector(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { setFormikFieldValue, value } = props;

  const handleOpenSelector = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const handleCountryChange = (country) => {
    handleCloseSelector();
    setFormikFieldValue('contactData.country', country);
  };

  const selectorIsOpen = Boolean(anchorEl);

  return (
    <Grid className={classes.countrySelectorContainer} item container wrap="nowrap">
      <TextField
        fullWidth
        value={value}
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.input,
          }
        }}
        disabled
      />
      <IconButton className={classes.iconButton} size="small" onClick={handleOpenSelector}>
        <DropDowIcon />
      </IconButton>
      <Popover
        open={selectorIsOpen}
        anchorEl={anchorEl}
        onClose={handleCloseSelector}
        className={classes.popoverContainer}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.selectorContainer}
        >
          {COUNTRIES_LIST.map(option => (
            <MenuItem
              key={option.code}
              value={option.code}
              className={option.code === value ? classes.activeSelector : ''}
              onClick={() => handleCountryChange(option.code)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Grid>
      </Popover>
    </Grid>
  );
}

DetailPanelCountrySelector.propTypes = {
  setFormikFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};
