import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { useDropzone } from 'react-dropzone';
// import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
// import PlaceholderIcon from '@material-ui/icons/ImageOutlined';
// import UploadIcon from '@material-ui/icons/PublishOutlined';
// import ImagePlaceholder from 'assets/imagePlaceholder.png';

import EmptyTableField from 'components/shared/EmptyTableField';
import MainInformationEditForm from 'components/Settings/MainInformationEditForm';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  mainInfoContainer: {
    width: 518,
    padding: `${theme.spacing(5)}px ${theme.spacing(4)}px ${theme.spacing(6)}px`,
    margin: `0px ${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.palette.customShadows.popover
  },
  editButton: {
    fontSize: theme.typography.htmlFontSize
  },
  infoSectionContainer: {
    padding: `${theme.spacing(2.5)}px 0px`
  },
  companyLogo: {
    height: 125
  },
  placeholderIcon: {
    fontSize: '4rem',
    height: 125,
    width: 100
  },
  rowTitle: {
    marginBottom: theme.spacing(1)
  },
  dialogPaper: {
    width: 1025,
    maxWidth: 'none',
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(6)}px`,
  },
  dialogBackdrop: {
    background: 'rgb(0 0 0 / 0.1)'
  },
  dialogTitle: {
    margin: `0px ${theme.spacing(3)}px ${theme.spacing(7)}px`,
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
  dragNDropContainer: {
    height: 145,
    border: '1.5px dashed #D8D8D8',
    borderRadius: 4,
    outline: 'none',
    '&:focus': {
      border: `1.5px dashed ${theme.palette.primary.main}`,
    }
  },
  uploadPhotoButton: {
    color: theme.palette.secondary.darkGray,
    textTransform: 'capitalize',
  },
  imagePreviewContainer: {
    height: 125,
    width: 310,
    borderRadius: 4,
    position: 'relative',
    marginBottom: theme.spacing(4.5)
  },
  noImage: {
    background: theme.palette.grey[50],
    border: theme.palette.border.darkGrey,
    color: '#DADADA'
  },
  imagePreview: {
    height: 146
  },
  noticeText: {
    width: '100%',
    color: theme.palette.secondary.darkGray,
    margin: `${theme.spacing(1)}px 0px`
  },
}));

const MAIN_DATA_ROWS = [
  {
    title: 'business',
    field: 'businessName'
  },
  {
    title: 'street and number',
    field: ['street', 'houseNumber']
  },
  {
    title: 'postal code',
    field: 'zip'
  },
  {
    title: 'city',
    field: 'city'
  },
  {
    title: 'country',
    field: 'country'
  },
];

export default function MainInformation({ contactData, handleUpdateVendor }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // const [files, setFiles] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [state, setState] = React.useState({
    businessName: '',
    city: '',
    country: '',
    houseNumber: '',
    street: '',
    zip: '',
  });

  // FIXME: add photo uploading when the API route will be ready
  // const { getRootProps, getInputProps, open: openDragNDrop } = useDropzone({
  //   accept: 'image/*',
  //   onDrop: async acceptedFiles => {
  //     setFiles(acceptedFiles.map(file => Object.assign(file, {
  //       preview: URL.createObjectURL(file)
  //     })));
  //   },
  //   noClick: true,
  //   maxSize: 8000000,
  //   multiple: false
  // });

  const setInitialMainData = React.useCallback(() => {
    const {
      businessName,
      city,
      country,
      houseNumber,
      street,
      zip,
    } = contactData;

    setState({
      businessName,
      city,
      country,
      houseNumber,
      street,
      zip,
    });
  }, [contactData]);

  React.useEffect(() => {
    setInitialMainData();
  }, [setInitialMainData]);

  // React.useEffect(() => () => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach(file => URL.revokeObjectURL(file.preview));
  // }, [files]);

  // const handleImagePlaceholder = ({ target }) => {
  //   // eslint-disable-next-line no-param-reassign
  //   target.src = ImagePlaceholder;
  // };

  const renderFieldValue = (fieldTitle) => {
    if (typeof (fieldTitle) === 'string') {
      return state[fieldTitle] ? (
        <Typography variant="body1">
          {state[fieldTitle]}
        </Typography>
      ) : (
        <EmptyTableField />
      );
    } else if (Array.isArray(fieldTitle)) {
      return (
        fieldTitle.every(field => !state[field])
          ? <EmptyTableField /> : (
            <Typography variant="body1">
              {fieldTitle.reduce((init, currField) => init.concat(state[currField], ' '), '')}
            </Typography>
          )
      );
    } return null;
  };

  // FIXME
  // GET LOGO COMPANY FROM QUERY
  // const imageSrc = (!!files.length && files[0].preview);

  return (
    <Grid container item>
      <Paper elevation={1} className={classes.mainInfoContainer}>
        <Grid container justifyContent="space-between">
          <Typography variant="h6">
            {t('settings.business details')}
          </Typography>
          <Button
            className={classes.editButton}
            variant="text"
            color="primary"
            endIcon={<EditIcon />}
            onClick={() => setEditMode(true)}
          >
            {t('common.edit')}
          </Button>
        </Grid>
        {/*
        FIXME: temporarily hidden
        <Grid
          className={classes.infoSectionContainer}
          container
          alignItems="center"
          justifyContent="center"
        >
          {imageSrc ? (
            <img
              className={classes.companyLogo}
              src={imageSrc}
              alt="company logo"
              onError={handleImagePlaceholder}
            />) : (
              <PlaceholderIcon className={classes.placeholderIcon} color="action" />
          )}
        </Grid>
        <Divider /> */}
        {MAIN_DATA_ROWS.map(row => (
          <Fragment key={row.title}>
            <Grid
              className={classes.infoSectionContainer}
              container
              direction="column"
            >
              <Typography color="textSecondary" variant="subtitle2" className={classes.rowTitle}>
                {t(`settings.${row.title}`)}
              </Typography>
              {renderFieldValue(row.field)}
            </Grid>
            <Divider />
          </Fragment>
        ))}
      </Paper>
      <Dialog
        open={editMode}
        TransitionComponent={Transition}
        onClose={() => setEditMode(false)}
        classes={{
          paper: classes.dialogPaper
        }}
        BackdropProps={{
          classes: {
            root: classes.dialogBackdrop
          }
        }}
      >
        <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
          <Typography variant="h6">
            {t('settings.business details')}
          </Typography>
          <IconButton size="small" color="inherit" onClick={() => setEditMode(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container wrap="nowrap">
            {/*
            FIXME: temporarily hidden
            <Grid container item xs={5} direction="column" wrap="nowrap" alignItems="center">
              <Grid
                container
                className={classNames(
                  classes.imagePreviewContainer,
                  !imageSrc && classes.noImage
                )}
                alignItems="center"
                justifyContent="center"
              >
                {imageSrc ?
                  <>
                    <img className={classes.imagePreview} src={imageSrc} alt="company logo" />
                  </>
                  : <PlaceholderIcon />}
              </Grid>
              <Grid
                className={classes.dragNDropContainer}
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                wrap="nowrap"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Typography variant="subtitle2" color="textSecondary">
                  {t('settings.image upload.drag and drop')}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {t('settings.image upload.or')}
                </Typography>
                <Button
                  className={classes.uploadPhotoButton}
                  variant="outlined"
                  startIcon={<UploadIcon color="primary" />}
                  onClick={openDragNDrop}
                >
                  {t('settings.image upload.upload photo')}
                </Button>
              </Grid>
              <Typography className={classes.noticeText} variant="caption">
                {t('settings.image upload.notice')}
              </Typography>
            </Grid>
            <Grid container item xs={2} justifyContent="center">
              <Divider orientation="vertical" flexItem />
            </Grid>
             */}
            <MainInformationEditForm
              contactData={state}
              handleUpdateVendor={handleUpdateVendor}
              setEditMode={setEditMode}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

MainInformation.propTypes = {
  contactData: PropTypes.object,
  handleUpdateVendor: PropTypes.func.isRequired
};

MainInformation.defaultProps = {
  contactData: {}
};
