import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { useMutation } from '@apollo/client';
import { DELETE_DEALS, UPDATE_DEALS } from 'graphql/mutation/deals';
import { GET_DEALS } from 'graphql/query/deals';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

import DateIcon from '@material-ui/icons/Event';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import DeleteModal from 'components/shared/DeleteModal';
import useMixPanel from 'helpers/useMixPanel';
import { useNotification } from '../../hooks/useNotification';

const useStyles = makeStyles(theme => ({
  changeDateButton: {
    height: 45,
    color: theme.palette.text.inverted,
  },
  deleteDealsButton: {
    height: 45,
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  buttonsContainer: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5)
  },
  dateChangeContainer: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    backgroundColor: theme.palette.background.container,
    paddingLeft: theme.spacing(4)
  },
  datePicker: {
    width: 160,
    marginRight: theme.spacing(5.5)
  },
  dateIcon: {
    color: theme.palette.secondary.darkGray
  }
}));

export default function MultiSelectButtons(props) {
  const { refetchDeals } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [deleteDealsMutation] = useMutation(DELETE_DEALS);
  const [updateDealMutation] = useMutation(UPDATE_DEALS);
  const successNotification = useNotification(NOTIFICATION_STATUS.SUCCESS);
  const alertNotification = useNotification(NOTIFICATION_STATUS.ALERT);

  const [isChangeDate, handleEditDate] = React.useState(false);
  const [date, setDate] = React.useState({
    startDate: moment().utc().toISOString(),
    endDate: moment().utc().toISOString()
  });
  const [isDeleteRowModalOpen, setDeleteRowModal] = React.useState(false);

  const { dealsIds, allDeals } = props;

  const handleChangeDate = (value, label) => {
    setDate({ ...date, [label]: moment(value).utc().startOf('day').toISOString() });
  };

  const handleDeleteDeals = async () => {
    const deleteDealsProducts = allDeals.filter(deal => dealsIds.includes(deal._id)).map(deal => {
      if (!deal.vendorSpecificId) return null;
      return deal.vendorSpecificId;
    });
    try {
      await deleteDealsMutation({
        variables: {
          productIds: deleteDealsProducts
        },
        update: (store, { data }) => {
          refetchDeals();
          const storeDeals = store.readQuery({ query: GET_DEALS });
          const deletedIds = data.deleteDeals.products.map(product => product.vendorSpecificId);

          store.writeQuery({
            query: GET_DEALS,
            data: {
              vendorDealProducts: {
                ...storeDeals.vendorDealProducts,
                totalResults:
                  storeDeals.vendorDealProducts.totalResults - deleteDealsProducts.length,
                products: storeDeals.vendorDealProducts.products
                  .filter(product => !deletedIds.includes(product.vendorSpecificId))
              }
            }
          });

          if (data.deleteDeals.notUpdated.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('deals.deal delete error', {
                  products: data.deleteDeals.notUpdated.join(', '),
                  count: data.deleteDeals.notUpdated.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('Delete deals', { products: dealsIds.toString() });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('deals.deal delete success', { dealsIds: dealsIds.toString() }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      props.setSelected([]);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSubmitDealData = async () => {
    const newDealsProducts = allDeals.filter(deal => dealsIds.includes(deal._id)).map(deal => {
      if (!deal.vendorSpecificId) return null;
      return {
        productId: deal.vendorSpecificId,
        start: date.startDate,
        end: date.endDate,
      };
    });
    try {
      await updateDealMutation({
        variables: {
          products: newDealsProducts
        },
        update: (store, { data }) => {
          refetchDeals();

          const updatedIds = data.updateDeals.products.map(deal => deal.vendorSpecificId);

          if (data.updateDeals.notUpdated.length) {
            alertNotification(t('deals.deal update error', {
              products: data.updateDeals.notUpdated.join(', '),
              count: data.updateDeals.notUpdated.length
            }));
          } else {
            successNotification(t('deals.deal update success', { ids: updatedIds.join(', ') }));
          }
        }
      });
      props.setSelected([]);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.buttonsContainer}
      >
        <Button
          className={classes.changeDateButton}
          onClick={() => handleEditDate(!isChangeDate)}
          variant="contained"
          color="primary"
          startIcon={<SettingsIcon/>}
        >
          {t('deals.change date')}
        </Button>
        <Button
          className={classes.deleteDealsButton}
          onClick={() => setDeleteRowModal(true)}
          variant="outlined"
          startIcon={<DeleteIcon color="primary"/>}
        >
          {t('deals.delete selected')}
        </Button>
      </Grid>
      {isChangeDate &&
      <Grid container className={classes.dateChangeContainer}>
        <DatePicker
          variant="inline"
          value={date.startDate}
          label={t('deals.start')}
          onChange={(dateValue) => handleChangeDate(dateValue, 'startDate')}
          autoOk
          format="DD-MM-YYYY"
          minDate={moment().subtract(1, 'year')}
          maxDate={moment().add(2, 'year')}
          minDateMessage={null}
          className={classes.datePicker}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DateIcon className={classes.dateIcon} fontSize="small" />
              </InputAdornment>
            )
          }}
        />
        <DatePicker
          variant="inline"
          value={date.endDate}
          label={t('deals.end')}
          onChange={(dateValue) => handleChangeDate(dateValue, 'endDate')}
          autoOk
          format="DD-MM-YYYY"
          minDate={date.startDate}
          maxDate={moment().add(2, 'year')}
          minDateMessage={null}
          className={classes.datePicker}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DateIcon className={classes.dateIcon} fontSize="small" />
              </InputAdornment>
            )
          }}
        />
        <Button
          className={classes.changeDateButton}
          onClick={handleSubmitDealData}
          variant="contained"
          color="secondary"
        >
          {t('deals.save')}
        </Button>
      </Grid>}
      {isDeleteRowModalOpen && (
        <DeleteModal
          isOpen={isDeleteRowModalOpen}
          message={t('deals.delete deal message')}
          handleCloseModal={() => setDeleteRowModal(false)}
          handleDelete={handleDeleteDeals}
        />
      )}
    </>
  );
}

MultiSelectButtons.propTypes = {
  dealsIds: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  allDeals: PropTypes.array,
  refetchDeals: PropTypes.func.isRequired,
};

MultiSelectButtons.defaultProps = {
  allDeals: [],
};
