import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import StyledInput from 'components/shared/Inputs/StyledInput';
import NewsletterModalLayout from 'components/Newsletter/Constructor/Modals/NewsletterModalLayout';
import NoFromEmailWarning from 'components/Newsletter/Constructor/NoFromEmailWarning';

const useStyles = makeStyles(({ spacing }) => ({
  newsletterTestSendModalContentContainer: {
    '& *': {
      fontWeight: '700 !important'
    }
  },
  newsletterTestSendModalInput: {
    margin: spacing(3, 0)
  },
  newsletterTestSendModalButtonAction: {
    borderRadius: 40,
    height: 48,
    boxShadow: 'none'
  },
}));

export default function NewsletterTestEmailSendModal({
  isOpen,
  isLoading,
  fromEmailField,
  testEmailInput,
  setTestEmailInput,
  handleClose,
  handleSendTestEmail,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isDisabled = !testEmailInput?.length || !fromEmailField || isLoading;

  return (
    <NewsletterModalLayout isOpen={isOpen} handleClose={handleClose}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.newsletterTestSendModalContentContainer}
      >
        <Typography variant="h4" gutterBottom>
          {t('newsletter.test send')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('newsletter.send email to')}
        </Typography>

        {!fromEmailField && <NoFromEmailWarning />}
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSendTestEmail();
          }}
        >
          <StyledInput
            className={classes.newsletterTestSendModalInput}
            placeholder="my-email@mail.com"
            value={testEmailInput}
            autoComplete="on"
            type="email"
            autoFocus
            onChange={e => setTestEmailInput(e.target.value)}
            fullWidth
          />

          <Button
            className={classes.newsletterTestSendModalButtonAction}
            color="primary"
            variant="contained"
            disabled={isDisabled}
            type="submit"
            fullWidth
          >
            {t('newsletter.send now')}
          </Button>
        </form>
      </Grid>
    </NewsletterModalLayout>
  );
}

NewsletterTestEmailSendModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fromEmailField: PropTypes.string.isRequired,
  testEmailInput: PropTypes.string.isRequired,
  setTestEmailInput: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSendTestEmail: PropTypes.func.isRequired,
};
