import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import HolidayBlockerPage from './HolidayBlockerPage';
import { specialDaysTypes } from '../../helpers/constants';
import TabsPanel from '../../components/shared/TabsPanel';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    fontFamily: '\'Manrope\', sans-serif',
    fontWeight: '700',
    '& *': {
      fontFamily: 'Manrope, sans-serif',
    }
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalMessage: {
    padding: theme.spacing(3, 3),
  },
  modalMessageContainer: {
    overflowY: 'unset',
  },
  button: {
    backgroundColor: '#FF5100',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 3),
    color: theme.palette.common.white,
    opacity: '1',
    '&:hover': {
      backgroundColor: '#FF5100',
      opacity: '0.8',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  drawerContainer: {
    width: '70vw',
    padding: theme.spacing(2, 3),
    fontFamily: 'Manrope, sans-serif',
    '& *': {
      fontFamily: 'Manrope, sans-serif',
    }
  },
  tableContainer: {
    borderRadius: theme.spacing(2),
    maxWidth: '100%',
  },
  tableBodyCell: {
    padding: theme.spacing(0.5, 1),
  },
  tableBodyRow: {
    '&:hover': {
      '& $editTableItemButton': {
        transform: 'translateX(3px)',
        opacity: 1,
      },
    },
  },
  editTableItemButton: {
    opacity: 0,
    transform: 'translateX(10px)',
    transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
  },
  tableBodyRowOdd: {
    background: 'linear-gradient(0deg, #F7F7F7, #F7F7F7), linear-gradient(0deg, #C7C9CC, #C7C9CC)'
  },
  tabs: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(2, 0),
    '& .Mui-selected': {
      '& .MuiTab-wrapper': {
        backgroundColor: '#0F1217',
        color: '#fff',
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      padding: theme.spacing(0),
      maxWidth: 'none',
      marginRight: theme.spacing(1),
      opacity: '1',
    },
    '& .MuiTab-wrapper': {
      border: '1px solid #C7C9CC',
      borderRadius: '48px',
      // padding: '10px 16px 10px 16px',
      padding: theme.spacing(1, 3),
      backgroundColor: '#F6F6F6',
      color: '#0F1217',
      width: 'auto',
    },
  },
  tableHeaderContainer: {
    backgroundColor: 'transparent',
  },
  tableHeaderRowCell: {
    color: '#0F1217',
  },
  tableHeaderActionsCell: {
    width: '150px',
  },
  columnTitle: {
    color: '#0F1217',
  },
  title: {
    fontSize: '32px',
    fontWeight: '700',
    color: '#0F1217',
  },
  disabledDate: {
    opacity: '0.55',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tooltip: {
    backgroundColor: '#FF5100',
    padding: theme.spacing(1, 1),
    color: theme.palette.common.white,
    fontSize: 14,
    maxWidth: 200,
  },
  modalContainer: {
    maxWidth: '90vw',
    minWidth: '650px',
  },
  flexHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  customerInputContainer: {
    padding: theme.spacing(1, 0),
  },
  addCustomerButton: {
    backgroundColor: '#FF5100',
    padding: theme.spacing(1, 3),
    color: theme.palette.common.white,
    opacity: '1',
    '&:hover': {
      backgroundColor: '#FF5100',
      opacity: '0.8',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  notificationBlock: {
    opacity: '0.85',
    maxWidth: '600px',
    paddingBottom: theme.spacing(0.5),
  }
}));

export default function SpecialDaysPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { type } = useParams();

  useEffect(() => {
    if (!specialDaysTypes[type]) {
      history.push('/delivery-days/blockedDates');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleTabChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <Grid className={classes.container}>
      <TabsPanel
        handleTabChange={handleTabChange}
        currentTab={location.pathname}
        tabs={[
          {
            label: t('specialDays.holidayBlockerTab'),
            value: '/delivery-days/blockedDates',
          },
          {
            label: t('specialDays.exceptionDaysTab'),
            value: '/delivery-days/exceptionDates',
          },
        ]}
      />
      {type && specialDaysTypes[type] && <HolidayBlockerPage
        classes={classes}
        type={type}
      />}
    </Grid>
  );
}

SpecialDaysPage.propTypes = {};
