/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import {
  GET_VENDOR_CUSTOMERS,
  SEARCH_VENDOR_CUSTOMERS,
} from 'graphql/query/vendors';

import CustomerLayout from 'components/CustomerTable/CustomerLayout';
import { GET_CUSTOMER_GROUPS } from 'graphql/query/groups';

export default function Customers({ vendorHasFtpIntegration, isAdmin }) {
  const location = useLocation();
  const queries = queryString.parse(location.search);

  const [editModeId, setEditModeId] = React.useState(null);
  const [editConfirmationModal, setConfirmEditChanges] = React.useState(false);

  const handleSetEditMode = (id) => {
    if (!editModeId) {
      setEditModeId(id);
    } else if (id === null) {
      setEditModeId(null);
      setConfirmEditChanges(false);
    } else if (id !== editModeId) {
      setConfirmEditChanges(true);
    }
  };

  const [
    getVendorCustomers,
    { loading: vendorsLoading, error: vendorsError, data },
  ] = useLazyQuery(GET_VENDOR_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const vendorCustomers = (data && data.vendorCustomers) || [];

  const [
    getCustomersSearch,
    { data: searchData, loading: searchLoading },
  ] = useLazyQuery(SEARCH_VENDOR_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [getCustomerGroups, { data: groupData }] = useLazyQuery(
    GET_CUSTOMER_GROUPS,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  const allCustomerGroups = (groupData && groupData.allCustomerGroups) || [];

  React.useEffect(() => {
    getCustomerGroups();
    getVendorCustomers({
      variables: {
        after: +queries.page + 1 || 1,
        pageSize: +queries.rowsPerPage || 10,
        sortBy: queries.sortBy || 'createdAt',
        sortOrder: +queries.sortOrder || -1,
      },
    });
  }, []);

  return (
    <CustomerLayout
      vendorCustomers={vendorCustomers}
      getVendorCustomers={getVendorCustomers}
      vendorsLoading={vendorsLoading}
      vendorsError={vendorsError}
      getCustomersSearch={getCustomersSearch}
      searchData={searchData}
      searchLoading={searchLoading}
      allCustomerGroups={allCustomerGroups}
      vendorHasFtpIntegration={vendorHasFtpIntegration}
      isAdmin={isAdmin}
      editModeId={editModeId}
      handleSetEditMode={handleSetEditMode}
      editConfirmationModal={editConfirmationModal}
      setConfirmEditChanges={setConfirmEditChanges}
    />
  );
}

Customers.propTypes = {
  vendorHasFtpIntegration: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

Customers.defaultProps = {
  vendorHasFtpIntegration: false,
  isAdmin: false,
};
