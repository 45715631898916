import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CampaignsSenderModal({
  isOpen,
  handleClose,
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-campaign-sender-dialog">
      <DialogTitle id="form-campaign-sender-dialog-title">
        {t('campaigns.sender name')}
      </DialogTitle>
      <DialogContent>
        MUTATION IS NOT CONNECTED YET
        <DialogContentText>
          {t('campaigns.sender title note')}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t('campaigns.add sender name')}
          type="email"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common.abort')}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CampaignsSenderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
