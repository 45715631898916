import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  RENAME_VENDOR_LIST,
  ADD_PRODUCTS_TO_VENDOR_LIST,
  DELETE_PRODUCTS_FROM_VENDOR_LIST,
  ASSIGN_CUSTOMERS_TO_VENDOR_LIST,
  DELETE_CUSTOMERS_FROM_VENDOR_LIST,
} from 'graphql/mutation/lists';
import { GET_VENDOR_LIST } from 'graphql/query/lists';
import { GET_CUSTOMER_GROUPS } from 'graphql/query/groups';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { useMutation, useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddPersonIcon from '@material-ui/icons/PersonAddOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutline';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import CustomerGroupModalSelector from 'components/shared/CustomerGroupModalSelector';
import useMixPanel from 'helpers/useMixPanel';
import replaceDelimiters from 'helpers/replaceDelimiters';

const useStyles = makeStyles(({ spacing, palette, typography, breakpoints }) => ({
  tableSearchBarButtons: {
    minWidth: 130,
    height: 45,
    marginRight: spacing(1.5),
    border: palette.border.lightGrey,
    color: palette.secondary.darkGray,
    fontWeight: typography.fontWeightRegular
  },
  activeSearchBarButtons: {
    color: palette.common.white,
    background: palette.primary.main,
    '&:hover': {
      background: palette.primary.light,
    }
  },
  defaultIcon: {
    color: palette.primary.main
  },
  activeIcon: {
    color: palette.common.white
  },
  headerAdditionalActionsWrapper: {
    minHeight: 85,
    borderBottom: palette.border.lightGrey
  },
  headerAdditionalActionsContainer: {
    margin: spacing(2.5, 0)
  },
  addActionsInputContainer: {
    height: 85,
    backgroundColor: palette.background.container,
    // eslint-disable-next-line max-len
    padding: spacing(2.5, 3, 2.5, 1.5)
  },
  addActionsInput: {
    width: 340,
    height: 45,
    fontSize: 14,
    background: palette.background.paper,
    border: palette.border.lightModeOnly,
    [breakpoints.down('sm')]: {
      width: '100%',
      fontSize: 12
    },
    [breakpoints.up('lg')]: {
      width: 400,
      fontSize: 16
    },
    '&:hover': {
      border: `1px solid ${palette.primary.main}`
    },
  },
  addActionsNotchedInput: {
    border: 'none'
  },
  addActionsConfirm: {
    height: 45,
    color: palette.text.inverted,
    margin: spacing(0, 2.5),
    textTransform: 'capitalize',
    minWidth: 192,
    boxShadow: 'none',
  },
  orTitle: {
    fontStyle: 'italic',
    margin: spacing(0, 2),
    color: palette.secondary.darkGray
  },
}));

const MODE_TYPES = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  EDIT_ASSIGNMENT: 'EDIT ASSIGNMENT',
  EDIT_TITLE: 'EDIT TITLE',
  REMOVE_RESTAURANT: 'REMOVE RESTAURANT'
};

const ALL_RESTAURANTS = 'ALL';

export default function ListDetailsHeaderActions(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const mixPanel = useMixPanel();

  const [renameVendorList] = useMutation(RENAME_VENDOR_LIST);
  const [addVendorProducts] = useMutation(ADD_PRODUCTS_TO_VENDOR_LIST);
  const [deleteVendorProducts] = useMutation(DELETE_PRODUCTS_FROM_VENDOR_LIST);
  const [assignCustomers] = useMutation(ASSIGN_CUSTOMERS_TO_VENDOR_LIST);
  const [removeCustomerAssignment] = useMutation(DELETE_CUSTOMERS_FROM_VENDOR_LIST);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [modeType, setModeType] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [selectedCustomerGroups, setSelectedCustomerGroups] = React.useState([]);

  const {
    // TODO: handle loading&error states
    data,
  } = useQuery(
    GET_CUSTOMER_GROUPS,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true
    }
  );

  const customerGroups = (data && data.allCustomerGroups) || [];

  const handleChangeInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeGroupSelector = (groupId) => () => {
    setSelectedCustomerGroups(selectedCustomerGroups.includes(groupId) ?
      selectedCustomerGroups.filter(group => group !== groupId)
      : selectedCustomerGroups.concat(groupId));
  };

  const handleModeTypeSelector = (type) => () => {
    if (type === modeType) {
      setModeType(null);
    } else {
      setModeType(type);
    }
  };

  React.useEffect(() => {
    setInputValue('');
  }, [modeType]);

  React.useEffect(() => {
    const assignsToGroup = props.assignedToGroups.map(group => group._id);
    setSelectedCustomerGroups(props.assignedToAll ?
      [ALL_RESTAURANTS].concat(assignsToGroup) : assignsToGroup);
  },
  [props.assignedToAll, props.assignedToGroups]);

  const renameList = async (newTitle) => {
    try {
      await renameVendorList({
        variables: { listId: props.listId, title: newTitle },
        update: (store, { data: { renameVendorList: { title } } }) => {
          setNotification({
            variables: {
              timeout: 4000,
              message: t('lists.success rename', { listName: title }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        }
      });
      mixPanel.track('Rename Vendor List', {
        listId: props.listId,
        title: newTitle
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
    }
  };

  const addProducts = async () => {
    const filteredInputValues = replaceDelimiters(inputValue);
    try {
      await addVendorProducts({
        variables: { listId: props.listId, productIds: filteredInputValues },
        refetchQueries: [{ query: GET_VENDOR_LIST, variables: { _id: props.listId } }],
        update: (_, {
          data: {
            addProductsToVendorList: {
              failedProductIds, productIds
            }
          }
        }) => {
          if (failedProductIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.error add products', {
                  products: failedProductIds.join(', '), count: failedProductIds.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
                isManual: true
              },
            });
          } else {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.success add products', {
                  count: productIds.length
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      mixPanel.track('Add Products To List', {
        listId: props.listId,
        productIds: filteredInputValues.toString(),
        productsLength: filteredInputValues.length,
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteProducts = async () => {
    const filteredInputValues = replaceDelimiters(inputValue);
    try {
      await deleteVendorProducts({
        variables: { listId: props.listId, productIds: filteredInputValues },
        refetchQueries: [{ query: GET_VENDOR_LIST, variables: { _id: props.listId } }],
        update: (_, {
          data: {
            removeProductsFromVendorList: {
              productIds, failedProductIds
            }
          }
        }) => {
          if (failedProductIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.error delete products', {
                  products: failedProductIds.join(', '), count: failedProductIds.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
                isManual: true
              },
            });
          } else {
            mixPanel.track('Delete Product', {
              products: productIds.join(', '),
              count: productIds.length
            });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.success delete products', {
                  products: productIds.join(', '), count: productIds.length
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      mixPanel.track('Delete Products From List', {
        listId: props.listId,
        productIds: filteredInputValues.toString(),
        count: filteredInputValues.length,
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
    }
  };

  const editAssignment = async () => {
    const customers = {
      customerIds: replaceDelimiters(inputValue),
      groupIds: selectedCustomerGroups.filter(type => type !== ALL_RESTAURANTS),
      assignedToAll: selectedCustomerGroups.includes(ALL_RESTAURANTS)
    };
    try {
      await assignCustomers({
        variables: { listId: props.listId, customers },
        refetchQueries: [{ query: GET_VENDOR_LIST, variables: { _id: props.listId } }],
        update: (store, {
          data: {
            assignCustomersToVendorList: { customerIds, failedCustomerIds }
          }
        }) => {
          if (failedCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.error add customers', {
                  customers: failedCustomerIds.join(', '), count: failedCustomerIds.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('Assign Customer To List', {
              listId: props.listId,
              customers: customerIds.join(', '),
              count: customerIds.length
            });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.success add customers', {
                  customers: customerIds.join(', '), count: customerIds.length
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
    }
  };

  const removeAssignment = async () => {
    const customers = {
      customerIds: replaceDelimiters(inputValue),
      groupIds: selectedCustomerGroups.filter(type => type !== ALL_RESTAURANTS),
      assignedToAll: selectedCustomerGroups.includes(ALL_RESTAURANTS)
    };
    try {
      await removeCustomerAssignment({
        variables: { listId: props.listId, customers },
        refetchQueries: [{ query: GET_VENDOR_LIST, variables: { _id: props.listId } }],
        update: (store, {
          data: {
            unassignCustomersFromVendorList: { customerIds, failedCustomerIds }
          }
        }) => {
          if (failedCustomerIds.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.error remove customers', {
                  customers: failedCustomerIds.join(', '), count: failedCustomerIds.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('Remove Customer From List', {
              listId: props.listId,
              customers: customerIds.join(', '),
              count: customerIds.length
            });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('lists.success remove customers', {
                  customers: customerIds.join(', '), count: customerIds.length
                }),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      setInputValue('');
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleConfirm = () => {
    // NOTE check input on special chars and shout an error if so
    const isValidInput = !/[~`!#$%\\^&+=\\[\]\\';/{}|\\":<>\\?]/g.test(inputValue);

    if (isValidInput || modeType === MODE_TYPES.EDIT_TITLE) {
      if (modeType === MODE_TYPES.ADD) {
        addProducts();
      }
      if (modeType === MODE_TYPES.DELETE) {
        deleteProducts();
      }
      if (modeType === MODE_TYPES.EDIT_ASSIGNMENT) {
        editAssignment();
      }
      if (modeType === MODE_TYPES.REMOVE_RESTAURANT) {
        removeAssignment();
      }
      if (modeType === MODE_TYPES.EDIT_TITLE) {
        renameList(inputValue);
      }
    } else {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.errors.special chars'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const getTextFieldPlaceholder = () => {
    switch (modeType) {
      case MODE_TYPES.EDIT_TITLE:
        return t('lists.title input placeholder');
      case MODE_TYPES.EDIT_ASSIGNMENT:
        return t('lists.edit assignment placeholder');
      case MODE_TYPES.REMOVE_RESTAURANT:
        return t('lists.edit assignment placeholder');
      default:
        return t('lists.default input placeholder');
    }
  };

  return (
    <Grid
      className={classes.headerAdditionalActionsWrapper}
      container
      wrap="nowrap"
      direction="column"
    >
      <Grid
        className={classes.headerAdditionalActionsContainer}
        container
        wrap="nowrap"
        alignItems="center"
      >
        <Button
          className={classNames(
            classes.tableSearchBarButtons,
            modeType === MODE_TYPES.ADD && classes.activeSearchBarButtons
          )}
          variant="outlined"
          startIcon={<AddIcon className={
            modeType === MODE_TYPES.ADD ?
              classes.activeIcon
              : classes.defaultIcon
          }
          />}
          onClick={handleModeTypeSelector(MODE_TYPES.ADD)}
        >
          {t('lists.details.add article')}
        </Button>
        <Button
          className={classNames(
            classes.tableSearchBarButtons,
            modeType === MODE_TYPES.DELETE && classes.activeSearchBarButtons
          )}
          variant="outlined"
          startIcon={<DeleteIcon className={
            modeType === MODE_TYPES.DELETE ?
              classes.activeIcon
              : classes.defaultIcon
          }
          />}
          onClick={handleModeTypeSelector(MODE_TYPES.DELETE)}
        >
          {t('lists.details.delete article')}
        </Button>
        <Button
          className={classNames(
            classes.tableSearchBarButtons,
            modeType === MODE_TYPES.EDIT_ASSIGNMENT && classes.activeSearchBarButtons
          )}
          variant="outlined"
          startIcon={<AddPersonIcon className={
            modeType === MODE_TYPES.EDIT_ASSIGNMENT ?
              classes.activeIcon
              : classes.defaultIcon
          }
          />}
          onClick={handleModeTypeSelector(MODE_TYPES.EDIT_ASSIGNMENT)}
        >
          {t('lists.details.edit assignment')}
        </Button>
        <Button
          className={classNames(
            classes.tableSearchBarButtons,
            modeType === MODE_TYPES.REMOVE_RESTAURANT && classes.activeSearchBarButtons
          )}
          variant="outlined"
          startIcon={<PeopleIcon className={
            modeType === MODE_TYPES.REMOVE_RESTAURANT ?
              classes.activeIcon
              : classes.defaultIcon
          }
          />}
          onClick={handleModeTypeSelector(MODE_TYPES.REMOVE_RESTAURANT)}
        >
          {t('lists.details.delete restaurant')}
        </Button>
        <Button
          className={classNames(
            classes.tableSearchBarButtons,
            modeType === MODE_TYPES.EDIT_TITLE && classes.activeSearchBarButtons
          )}
          variant="outlined"
          startIcon={<SettingsIcon className={
            modeType === MODE_TYPES.EDIT_TITLE ?
              classes.activeIcon
              : classes.defaultIcon
          }
          />}
          onClick={handleModeTypeSelector(MODE_TYPES.EDIT_TITLE)}
        >
          {t('lists.details.edit list name')}
        </Button>
      </Grid>

      {modeType && (
        <Grid
          className={classes.addActionsInputContainer}
          container
          alignItems="center"
          wrap="nowrap"
        >
          <TextField
            InputProps={{
              classes: {
                root: classes.addActionsInput,
                notchedOutline: classes.addActionsNotchedInput,
              }
            }}
            value={inputValue}
            onChange={handleChangeInput}
            variant="outlined"
            placeholder={getTextFieldPlaceholder()}
          />
          {(modeType === MODE_TYPES.EDIT_ASSIGNMENT || modeType === MODE_TYPES.REMOVE_RESTAURANT) && (
            <>
              <Typography className={classes.orTitle} variant="body2">
                {t('lists.or')}
              </Typography>
              <CustomerGroupModalSelector
                customerGroups={customerGroups}
                selectedCustomerGroups={selectedCustomerGroups}
                handleChangeGroupSelector={handleChangeGroupSelector}
                handleConfirm={handleConfirm}
                withTextField
                withAllRestaurantsOption
              />
            </>
          )}
          <Button
            onClick={handleConfirm}
            className={classes.addActionsConfirm}
            variant="contained"
            color="secondary"
          >
            {t('lists.confirm')}
          </Button>
        </Grid>
      )}

    </Grid>
  );
}

ListDetailsHeaderActions.propTypes = {
  listId: PropTypes.string.isRequired,
  assignedToAll: PropTypes.bool,
  assignedToGroups: PropTypes.array,
};

ListDetailsHeaderActions.defaultProps = {
  assignedToAll: false,
  assignedToGroups: []
};
