/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const GET_SALES_REPS = gql`
  query vendorSalesReps($pageSize: Int, $after: Int) {
    vendorSalesReps(after: $after, pageSize: $pageSize)
      @connection(key: "salesReps", filter: ["_id"]) {
      salesReps {
        _id
        createdAt
        contactData {
          name
          email
          phone
        }
        clientIds
      }
      page
      totalPages
      nextPage
      hasNextPage
      hasPrevPage
      prevPage
      totalResults
    }
  }
`;

export const GET_SALES_REP = gql`
  query vendorSalesRep($_id: ID!, $pageSize: Int, $after: Int) {
    vendorSalesRep(_id: $_id, after: $after, pageSize: $pageSize) {
      _id
      createdAt
      contactData {
        name
        email
        phone
      }
      clientIds
      clientConnections {
        page
        totalPages

        hasNextPage
        nextPage

        hasPrevPage
        prevPage

        totalResults
        connections {
          _id
          clientId
          vendorClientId
          vendorId
          contactData {
            businessName
            city
            street
            houseNumber
            zip
          }
          users {
            name
            email
          }
        }
      }
    }
  }
`;
