import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles(({ spacing }) => ({
  newsletterModalPaper: {
    padding: spacing(5),
    borderRadius: 16,
    position: 'relative'
  },
  newsletterModalCloseIcon: {
    position: 'absolute',
    top: 16,
    right: 16
  }
}));

export default function NewsletterModalLayout({ isOpen, handleClose, children, onKeyUp }) {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="newsletter-dialog-title"
      aria-describedby="newsletter-dialog-description"
      onKeyUp={onKeyUp}
      classes={{
        paper: classes.newsletterModalPaper
      }}
    >
      <IconButton
        className={classes.newsletterModalCloseIcon}
        onClick={handleClose}
        size="small"
      >
        <CloseRoundedIcon color="action" fontSize="medium" />
      </IconButton>
      <Grid container direction="column" wrap="nowrap">
        {children}
      </Grid>
    </Dialog>
  );
}

NewsletterModalLayout.defaultProps = {
  onKeyUp: null
};

NewsletterModalLayout.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  children: PropTypes.node.isRequired,
};
