import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getIn } from 'formik';

import { makeStyles } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import ProductImage from 'components/ProductsTable/ProductImage';
import ProductPackaging from 'components/ProductsTable/ProductPackaging';
import EmptyTableField from 'components/shared/EmptyTableField';
import { truncateTitle, calculateUnitPrice } from 'helpers/utils';
import ProductInput from 'components/ProductsTable/ProductVariantInput';
import ProductAttributesList from 'components/ProductsTable/ProductAttributesList';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1),
    },
  },
  categoryColumn: {
    width: 330,
  },
  nameColumn: {
    width: 400,
  },
}));

export default function ProductTableRowCells({
  rowData,
  handleUploadImage,
  isEditMode,
  errors,
  nonEditablePrice,
  setFieldValue,
  noImageUpload,
}) {
  const classes = useStyles();

  const {
    packaging = null,
    baseUnit = null,
    unitsInPackaging = null,
    price = null,
    attributes = [],
  } = rowData.variants[0] ? rowData.variants[0] : {};

  const renderPriceField = () =>
    (price ? (
      <Typography variant="body2" align="center">
        €{price.toFixed(2)}
      </Typography>
    ) : (
      <EmptyTableField align="center" />
    ));

  return isEditMode ? (
    <>
      <TableCell className={classes.tableCell}>
        <ProductInput
          name="vendorSpecificId"
          value={rowData.vendorSpecificId}
          setFieldValue={setFieldValue}
          error={getIn(errors, 'vendorSpecificId')}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        {noImageUpload && (
          <ProductImage
            image={rowData.image}
            productId={rowData._id}
            handleUploadImage={handleUploadImage}
          />
        )}
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.nameColumn)}>
        <ProductInput
          name="name"
          value={rowData.name}
          setFieldValue={setFieldValue}
          error={getIn(errors, 'name')}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductInput
          name="variants[0].packaging"
          value={packaging}
          setFieldValue={setFieldValue}
          error={getIn(errors, 'variants[0].packaging')}
        />
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        <ProductAttributesList attributes={attributes} />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductInput
          name="description"
          value={rowData.description}
          setFieldValue={setFieldValue}
          error={getIn(errors, 'description')}
          inputLimit={80}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductInput
          name="variants[0].baseUnit"
          value={baseUnit}
          setFieldValue={setFieldValue}
          error={getIn(errors, 'variants[0].baseUnit')}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductInput
          name="variants[0].unitsInPackaging"
          value={unitsInPackaging}
          setFieldValue={setFieldValue}
          error={getIn(errors, 'variants[0].unitsInPackaging')}
          type="number"
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        {nonEditablePrice ? (
          renderPriceField()
        ) : (
          <ProductInput
            name="variants[0].price"
            value={price}
            setFieldValue={setFieldValue}
            error={getIn(errors, 'variants[0].price')}
            type="number"
            isPrice
          />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {calculateUnitPrice(price, unitsInPackaging)}
      </TableCell>
      <TableCell
        className={classNames(classes.tableCell, classes.categoryColumn)}
      >
        <ProductInput
          name="categories"
          value={rowData.categories}
          setFieldValue={setFieldValue}
          error={getIn(errors, 'categories')}
          inputLimit={80}
        />
      </TableCell>
    </>
  ) : (
    <>
      <TableCell className={classes.tableCell}>
        {rowData.vendorSpecificId}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductImage
          image={rowData.image}
          productId={rowData._id}
          handleUploadImage={handleUploadImage}
        />
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.nameColumn)}>
        {truncateTitle(rowData.name)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductPackaging unit={packaging || ''} />
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        <ProductAttributesList attributes={attributes} />
      </TableCell>
      <TableCell className={classes.tableCell}>
        {truncateTitle(rowData.description, 64, 'center')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {baseUnit ? (
          <Typography variant="body2" align="center">
            {baseUnit}
          </Typography>
        ) : (
          <EmptyTableField align="center" />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {unitsInPackaging ? (
          <Typography variant="body2" align="center">
            {unitsInPackaging}
          </Typography>
        ) : (
          <EmptyTableField align="center" />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>{renderPriceField()}</TableCell>
      <TableCell className={classes.tableCell}>
        {calculateUnitPrice(price, unitsInPackaging)}
      </TableCell>
      <TableCell
        className={classNames(classes.tableCell, classes.categoryColumn)}
      >
        {rowData.categories?.length ? (
          <Typography variant="body2">{rowData.categories}</Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
    </>
  );
}

ProductTableRowCells.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  nonEditablePrice: PropTypes.bool.isRequired,
  noImageUpload: PropTypes.bool,
};

ProductTableRowCells.defaultProps = {
  noImageUpload: false,
};
