import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { CREATE_SALES_REP } from 'graphql/mutation/salesReps';
import { GET_SALES_REPS } from 'graphql/query/salesReps';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ModalLayout from 'components/shared/ModalLayout';

import { NOTIFICATION_STATUS, NEW_SALES_REP } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
import { newSalesRepSchema } from 'components/SalesRepsTable/SalesRepValidationSchema';

const useStyles = makeStyles(theme => ({
  actionButton: {
    height: 50,
    marginTop: theme.spacing(4.5)
  },
  textInput: {
    border: theme.palette.border.lightGrey,
    borderRadius: 4,
    margin: `${theme.spacing(1)}px 0px`
  },
  input: {
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  formControl: {
    top: 16
  },
}));

export default function AddSalesRepModal({
  isOpen,
  handleClose,
  page,
  rowsPerPage,
  sortBy,
  sortOrder
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const [salesRepValue, setSalesRepValue] = React.useState({ ...NEW_SALES_REP() });

  const [createSalesRep] = useMutation(CREATE_SALES_REP);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const handleSubmitNewSalesRep = async ({ name, email, password }) => {
    try {
      await createSalesRep({
        variables: {
          name,
          email,
          password
        },
        refetchQueries: [{
          query: GET_SALES_REPS,
          variables: {
            after: page + 1,
            pageSize: rowsPerPage,
            sortBy,
            sortOrder,
          }
        }],
        update: (_, {
          data: {
            createSalesRep: createSalesRepUpdate
          }
        }) => {
          if (createSalesRepUpdate) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('salesReps.create success'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      mixPanel.track('Add new sales rep', {
        name,
        email,
      });
      setSalesRepValue({ ...NEW_SALES_REP() });
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong extended', { errorMessage: error.message }),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      console.error(error.message);
    }
    handleClose();
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      modalTitle={t('salesReps.add new')}
    >
      <Formik
        initialValues={{
          ...salesRepValue
        }}
        enableReinitialize
        onSubmit={(values) => handleSubmitNewSalesRep(values)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={newSalesRepSchema}
      >
        {({
          values, handleChange, handleSubmit, errors, handleBlur
        }) => (
          <Grid container direction="column">
            <TextField
              label={t('common.name')}
              value={values.name}
              name="name"
              onChange={handleChange}
              variant="outlined"
              fullWidth
              className={classes.textInput}
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              InputLabelProps={{
                classes: {
                  formControl: classes.formControl
                }
              }}
              error={!!errors.name}
              onBlur={handleBlur}
              helperText={!!errors.name && t('salesReps.input error name')}
            />

            <TextField
              label={t('common.email')}
              value={values.email}
              name="email"
              onChange={handleChange}
              variant="outlined"
              className={classes.textInput}
              fullWidth
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              InputLabelProps={{
                classes: {
                  formControl: classes.formControl
                }
              }}
              error={!!errors.email}
              onBlur={handleBlur}
              helperText={!!errors.email && t('salesReps.input error email')}
            />

            <TextField
              label={t('common.password')}
              value={values.password}
              name="password"
              onChange={handleChange}
              variant="outlined"
              fullWidth
              className={classes.textInput}
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              InputLabelProps={{
                classes: {
                  formControl: classes.formControl
                }
              }}
              error={!!errors.password}
              onBlur={handleBlur}
              helperText={!!errors.password && t('salesReps.input error password')}
            />

            <Button
              className={classes.actionButton}
              fullWidth
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              {t('common.confirm')}
            </Button>
          </Grid>
        )}
      </Formik>
    </ModalLayout>
  );
}

AddSalesRepModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
};
