import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  IMPERSONATE_ACCOUNT,
  GET_USER_PROFILE,
  THEME_TYPE,
  GET_VENDOR_PROFILE,
} from 'graphql/query/user';

import { CHANGE_THEME_TYPE } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DarkModeIcon from '@material-ui/icons/EmojiObjectsOutlined';

import getAvatarCaption from 'helpers/getAvatarCaption';
import packageJson from '../../package.json';

const useStyles = makeStyles(theme => ({
  roundedAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    height: 45,
    width: 45
  },
  companyNameContainer: {
    padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  companyName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  profileMenu: {
    width: 340,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`
  },
  profileMenuTitle: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    borderBottom: '1px solid #EFEFEF',
    '&:focus': {
      outline: 'none'
    }
  },
  profileMenuItem: {
    padding: `${theme.spacing(1.75)}px ${theme.spacing(1)}px`
  },
  themeSwitchLabel: {
    margin: 0
  },
  versionText: {
    position: 'absolute',
    top: 0,
    right: 1
  },
  expandIcon: {
    margin: `0px ${theme.spacing(1.5)}px`
  },
  switchBase: {
    color: theme.palette.secondary.main
  },
  version: {
    marginTop: theme.spacing(1.5)
  },
  profileTitle: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  profileButton: {
    height: '100%',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '6px 24px 6px 6px',
  },
  icons: {
    marginRight: theme.spacing(0.75)
  }
}));

const ProfileMenu = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [language, setLanguage] = React.useState(i18n.language);

  const [changeThemeMutation] = useMutation(CHANGE_THEME_TYPE);

  const {
    data: { impersonate: { name: impersonateName } }
  } = useQuery(
    IMPERSONATE_ACCOUNT
  );

  const {
    data: { theme: { type } }
  } = useQuery(
    THEME_TYPE
  );

  const { data: userProfile } =
  useQuery(
    GET_USER_PROFILE,
    { fetchPolicy: 'cache-and-network' }
  );

  const [getVendorProfile, { data: vendorData }] = useLazyQuery(
    GET_VENDOR_PROFILE,
    { fetchPolicy: 'cache-and-network' }
  );

  React.useEffect(() => {
    getVendorProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenProfileMenu = event => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setMenuAnchor(null);
  };

  const handleChangeLanguage = () => {
    setLanguage(language === 'en' ? 'de' : 'en');
    i18n.changeLanguage(language === 'en' ? 'de' : 'en');
  };

  const handleChangeAppTheme = (event) => {
    changeThemeMutation({
      variables: {
        type: event.target.checked ? 'dark' : 'light'
      }
    });
  };

  const vendorName = vendorData?.vendorAccount?.contactData?.businessName || '';

  return (
    <>
      <Button className={classes.profileButton} onClick={handleOpenProfileMenu}>
        <Avatar
          variant="rounded"
          className={classes.roundedAvatar}
        >
          {getAvatarCaption(vendorName)}
        </Avatar>

        <Grid
          className={classes.companyNameContainer}
          container
          direction="column"
          alignItems="flex-start"
        >
          <Typography
            className={classNames(
              classes.companyName,
              classes.profileTitle
            )}
            color="textPrimary"
            align="left"
          >
            {impersonateName || vendorName}
          </Typography>
          <Typography
            color="textSecondary"
            align="left"
          >
            {userProfile?.userProfile?.email?.email}
          </Typography>
        </Grid>

        <ExpandMoreIcon className={classes.expandIcon} />
      </Button>

      <Menu
        classes={{ paper: classes.profileMenu }}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 8, horizontal: 'center' }}
        elevation={2}
        open={Boolean(menuAnchor)}
        onClose={handleCloseProfileMenu}
      >
        <Typography className={classes.profileMenuTitle} variant="h6">
          {t('appbar.settings')}
        </Typography>
        <MenuItem className={classes.profileMenuItem} onClick={() => handleChangeLanguage()}>
          <LanguageIcon className={classes.icons} />
          {t('appbar.change language')} {language === 'en' ? 'DE' : 'EN'}
        </MenuItem>
        <MenuItem
          className={classes.profileMenuItem}
          onClick={() => history.push('/settings')}
        >
          <SettingsIcon className={classes.icons} />
          {t('appbar.settings')}
        </MenuItem>
        <MenuItem className={classes.profileMenuItem}>
          <FormControlLabel
            className={classes.themeSwitchLabel}
            control={
              <Switch
                checked={type === 'dark'}
                onChange={handleChangeAppTheme}
                name="theme"
                inputProps={{ 'aria-label': 'theme switcher' }}
                color="primary"
                classes={{ switchBase: classes.switchBase }}
              />
            }
            label={
              <Grid container alignItems="center">
                <DarkModeIcon className={classes.icons} />
                {t('appbar.dark mode')}
              </Grid>
            }
            labelPlacement="start"
          />
        </MenuItem>
        <MenuItem className={classes.profileMenuItem} onClick={() => history.push('/logout')}>
          <ExitToAppIcon className={classes.icons} />
          {t('appbar.logout')}
        </MenuItem>
        <Typography className={classes.version} color="textSecondary" align="right">
          Version: {packageJson.version.replace(/[.]/g, '. ')}
        </Typography>
      </Menu>
    </>
  );
};

export default React.memo(ProfileMenu);
