import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import LogRocket from 'logrocket';

import NotFound from 'containers/404';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
    // TODO: log error messages to an error reporting service here
  }

  render() {
    const { history } = this.props;

    history.listen(() => {
      if (this.state.errorInfo) {
        this.setState({
          error: null,
          errorInfo: null,
        });
      }
    });

    if (this.state.errorInfo) {
      console.error('APP DOWN', this.state.error, this.state.errorInfo);
      LogRocket.captureMessage(this.state.error?.message, {
        tags: {
          location: window.location.href,
          message: this.state.error?.message
        },
        extra: {
          errorInfo: this.state.errorInfo?.componentStack
        },
      });
      return (
        <NotFound isError/>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(ErrorBoundary);
