import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import { REPORT_STATUSES } from 'components/Campaigns/campaignConstants';
import moment from 'moment';

const useStyles = makeStyles(({ palette, spacing }) => ({
  filterSelect: {
    maxWidth: 190,
    width: '100%',
    height: 45,
    marginRight: spacing(2.5),
    border: palette.border.lightGrey,
    borderRadius: 8,
    padding: spacing(0.5, 0),
  },
  dateIcon: {
    paddingLeft: 0,
    marginRight: -19,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  reportFiltersContainer: {
    height: 60,
    padding: spacing(1.5, 0),
  },
  input: {
    padding: '12.5px 14px',
  },
  selectLabel: {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  select: {
    '&:focus': {
      background: 'none',
    },
  },
}));

const ALL_STATUSES = 'ALL';
function CampaignReportFilters({
  endDate,
  startDate,
  status,
  handleFilters,
  clearFilters,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleStatusFilter = (event) => {
    const newStatus = event.target.value;
    if (newStatus === ALL_STATUSES) {
      handleFilters(null, 'status');
    } else {
      handleFilters(newStatus, 'status');
    }
  };

  const handleChangeDate = (date, fieldName) => {
    const ISODate = moment(date).toISOString();
    handleFilters(ISODate, fieldName);
  };

  return (
    <Grid
      container
      className={classes.reportFiltersContainer}
      alignItems="center"
    >
      {/* NOTE: leave it to make a customer filter

      <FormControl
        key="filter-by-outlet"
        variant="outlined"
        className={classes.filterSelect}
        classes={{}}
      >
        <InputLabel classes={{ outlined: classes.label }}>
          {t('orders.By Outlet')}
        </InputLabel>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          label="By Supplier"
          value={
            state?.filterBy?.find((item) => item?.name === 'customerId')?.values
          }
          variant="outlined"
          onChange={(e) => handleFilter(e, 'customerId')}
          onScroll={(e) => {
            const listNode = e.currentTarget;
            if (
              listNode.scrollTop + listNode.clientHeight ===
              listNode.scrollHeight
            ) {
              if (subAccountsData?.subAccountsPaginated?.nextPage) {
                fetchMore({
                  variables: {
                    after: subAccountsData?.subAccountsPaginated?.nextPage,
                  },
                });
              }
            }
          }}
          className={classes.filterSelect}
          inputProps={{
            classes: {
              root: classes.input,
            },
          }}
        >
          {subAccounts?.map((subAccount) => (
            <MenuItem key={subAccount._id} value={subAccount._id}>
              {subAccount.contactData.label ||
                subAccount.contactData.businessName}
            </MenuItem>
          ))}
          {subAccountsData?.subAccountsPaginated?.hasNextPage && (
          <Loading style={{ height: 'auto', padding: 10 }} noTimeouts />
          )}
        </Select>
      </FormControl> */}
      <KeyboardDatePicker
        key="filter-by-date-from"
        label={t('campaigns.report.from date')}
        value={startDate}
        onChange={(date) => handleChangeDate(date, 'startDate')}
        className={classes.filterSelect}
        variant="dialog"
        allowKeyboardControl
        inputVariant="outlined"
        format="DD-MM-YYYY"
        clearable
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.label,
          },
        }}
        maxDate={new Date()}
        minDate={moment().subtract(1, 'year')}
        KeyboardButtonProps={{
          disableFocusRipple: true,
          edge: 'end',
          disableRipple: true,
          disableTouchRipple: true,
          className: classes.dateIcon,
        }}
      />
      <KeyboardDatePicker
        key="filter-by-date-to"
        label={t('campaigns.report.to date')}
        value={endDate}
        onChange={(date) => handleChangeDate(date, 'endDate')}
        allowKeyboardControl
        className={classes.filterSelect}
        variant="dialog"
        format="DD-MM-YYYY"
        inputVariant="outlined"
        clearable
        maxDate={new Date()}
        minDate={moment().subtract(1, 'year')}
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.label,
          },
        }}
        KeyboardButtonProps={{
          disableFocusRipple: true,
          edge: 'end',
          disableRipple: true,
          disableTouchRipple: true,
          className: classes.dateIcon,
        }}
      />
      <FormControl className={classes.filterSelect}>
        <InputLabel
          classes={{ formControl: classes.selectLabel }}
          htmlFor="outlined-filter-by-status"
        >
          {t('campaigns.report.by status')}
        </InputLabel>
        <Select
          key="filter-by-status"
          value={status}
          onChange={handleStatusFilter}
          variant="outlined"
          label={t('campaigns.report.by status')}
          displayEmpty
          classes={{
            select: classes.select,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem key={ALL_STATUSES} value="">
            {t('common.all')}
          </MenuItem>
          {Object.values(REPORT_STATUSES).map((statusValue) => (
            <MenuItem key={statusValue} value={statusValue}>
              {t(`campaigns.report status.${statusValue}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="text" color="primary" onClick={clearFilters}>
        {t('campaigns.report.clear')}
      </Button>
    </Grid>
  );
}

CampaignReportFilters.propTypes = {
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  status: PropTypes.string,
  handleFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

CampaignReportFilters.defaultProps = {
  endDate: '',
  startDate: '',
  status: '',
};

export default React.memo(
  CampaignReportFilters,
  (prevProps, nextProps) =>
    prevProps.endDate === nextProps.endDate &&
    prevProps.startDate === nextProps.startDate &&
    prevProps.status === nextProps.status
);
