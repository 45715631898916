/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import NoColorIcon from '@material-ui/icons/RemoveCircleOutline';

import { brandingSchema } from 'components/Settings/ValidationSchemas';
import SettingsInput from 'components/Settings/SettingsInput';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { UPLOAD_VENDOR_IMAGE, UPLOAD_VENDOR_DEFAULT_PRODUCT_IMAGE } from 'graphql/mutation/fileUploading';
import { useMutation } from '@apollo/client';
import imageCompression from 'browser-image-compression';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  brandingContainer: {
    width: 518,
    padding: `${theme.spacing(5)}px ${theme.spacing(4)}px`,
    margin: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
    boxSizing: 'border-box',
    boxShadow: theme.palette.customShadows.popover
  },
  titleContainer: {
    marginBottom: theme.spacing(2.5)
  },
  actionButton: {
    fontSize: theme.typography.htmlFontSize
  },
  dialogPaper: {
    maxWidth: 'none',
    minWidth: 440,
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(6)}px`,
  },
  dialogBackdrop: {
    background: 'rgb(0 0 0 / 0.1)'
  },
  dialogTitle: {
    margin: `0px ${theme.spacing(3)}px ${theme.spacing(7)}px`,
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
  submitButton: {
    marginTop: theme.spacing(4),
    height: 50,
    borderRadius: 8,
    boxShadow: 'none'
  },
  brandingField: {
    margin: `${theme.spacing(2)}px 0px`
  },
  brandingColorPreview: {
    height: 22,
    width: 22,
    borderRadius: '50%',
    border: theme.palette.border.darkGrey,
    boxSizing: 'border-box',
    margin: `0px ${theme.spacing(0.25)}px`
  },
  noteContainer: {
    margin: `${theme.spacing(1)}px 0px`
  },
  imageContainer: {
    padding: `${theme.spacing(1.5)}px 0px ${theme.spacing(4.5)}px`,
  },
  imagePreviewContainer: {
    height: 110,
    width: '100%',
    borderRadius: 4,
    border: theme.palette.border.darkGrey,
    position: 'relative',
    marginRight: 'auto'
  },
  noImage: {
    background: theme.palette.grey[50],
    color: '#DADADA',
  },
  noticeText: {
    width: 170,
    color: theme.palette.secondary.darkGray,
    margin: `${theme.spacing(1)}px 0px`,
  },
  dragNDropContainer: {
    height: 110,
    border: '1.5px dashed #D8D8D8',
    borderRadius: 4,
    outline: 'none',
    '&:focus': {
      border: `1.5px dashed ${theme.palette.primary.main}`,
    },
  },
  uploadPhotoButton: {
    color: theme.palette.secondary.darkGray,
    textTransform: 'capitalize',
  },
  imagePreview: {
    height: 109,
    maxWidth: '100%',
    width: 'inherit',
    objectFit: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  imageLogoSmall: {
    width: 72,
    height: 72
  },
  imagePreviewSmall: {
    height: 72
  },
  deleteImageIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
  }
}));

export default function Branding({ brandingData, handleUpdateVendor, image, defaultProductImage }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [uploadImage] = useMutation(UPLOAD_VENDOR_IMAGE);
  const [uploadDefaultImage] = useMutation(UPLOAD_VENDOR_DEFAULT_PRODUCT_IMAGE);

  const BRANDING_FIELDS = [
    {
      title: t('common.primary color'),
      fieldName: 'primaryColor'
    },
    {
      title: t('common.secondary color'),
      fieldName: 'secondaryColor'
    },
    {
      title: t('common.font color'),
      fieldName: 'fontColor'
    },
  ];

  const [editMode, setEditMode] = React.useState(false);
  const [brandingState, setBrandingState] = React.useState({
    fontColor: null,
    primaryColor: null,
    secondaryColor: null
  });
  const [files, setFiles] = React.useState([]);
  const [filesDefaultImage, setFilesDefaultImage] = React.useState([]);
  const [defaultImgSrc, setDefaultImgSrc] = React.useState(null);

  const handleUploadImage = async () => {
    const uploadedFile = files && files[0];
    const options = {
      maxSizeMb: 0.5,
      maxWidthOrHeight: 768,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(uploadedFile, options);
    try {
      await uploadImage({
        variables: { file: compressedFile },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleUploadDefaultImage = async () => {
    const uploadedFile = filesDefaultImage && filesDefaultImage[0];
    const options = {
      maxSizeMb: 0.5,
      maxWidthOrHeight: 768,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(uploadedFile, options);
    try {
      await uploadDefaultImage({
        variables: { file: compressedFile },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const setInitialBranding = React.useCallback(() => {
    const {
      primaryColor = null,
      secondaryColor = null,
      fontColor = null
    } = brandingData;

    setBrandingState({
      primaryColor,
      secondaryColor,
      fontColor
    });
  }, [brandingData]);

  React.useEffect(() => {
    setInitialBranding();
  }, [setInitialBranding]);

  const handleSubmitBranding = (values) => {
    if (files?.length) {
      handleUploadImage();
    }
    const updateStatement = {
      branding:
        Object.entries(values).reduce((init, entry) => {
          const [key, value] = entry;
          return {
            ...init,
            [key]: value || null
          };
        }, {})
    };
    if (filesDefaultImage?.length) {
      handleUploadDefaultImage();
    } else {
      updateStatement.preferences = {
        defaultProductImage: null
      };
    }
    handleUpdateVendor(updateStatement);
    setEditMode(false);
  };

  const { getRootProps, getInputProps, open: openDragNDrop } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    noClick: true,
    multiple: false,
  });

  const {
    getRootProps: getRootPropsDefaultImage,
    getInputProps: getInputPropsDefaultImage,
    open: openDragNDropDefaultImage
  } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFilesDefaultImage(newFiles);
      setDefaultImgSrc(newFiles[0].preview);
    },
    noClick: true,
    multiple: false,
  });
  const imgSrc = (!!files.length && files[0].preview) || image;

  useEffect(() => {
    setDefaultImgSrc((!!filesDefaultImage.length && filesDefaultImage[0].preview)
      || defaultProductImage);
  }, []);

  return (
    <Paper className={classes.brandingContainer} elevation={1}>
      <Grid className={classes.titleContainer} container justifyContent="space-between">
        <Typography variant="h6">
          {t('settings.branding')}
        </Typography>
        <Button
          className={classes.actionButton}
          variant="text"
          color="primary"
          endIcon={<EditIcon />}
          onClick={() => setEditMode(true)}
        >
          {t('common.edit')}
        </Button>
      </Grid>
      <Grid container direction="column" wrap="nowrap">

        <Fragment key="logo">
          <Grid
            className={classes.brandingField}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1">
              {t('settings.Vendor Logo')}
            </Typography>
            <div>
              <Grid
                item
                container
                className={classNames(
                  classes.imagePreviewContainer,
                  classes.imageLogoSmall,
                  !imgSrc && classes.noImage
                )}
                alignItems="center"
                justifyContent="center"
              >
                {imgSrc ? (
                  <>
                    <img
                      className={classNames(
                        classes.imagePreview,
                        classes.imagePreviewSmall)}
                      src={imgSrc}
                      alt="logo"
                    />
                  </>
                ) : (
                  <ImageOutlinedIcon />
                )}
              </Grid>
            </div>
          </Grid>
          <Divider />
        </Fragment>
        <Fragment key="defaultProductImage">
          <Grid
            className={classes.brandingField}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1">
              {t('settings.Default product image')}
            </Typography>
            <div>
              <Grid
                item
                container
                className={classNames(
                  classes.imagePreviewContainer,
                  classes.imageLogoSmall,
                  !defaultImgSrc && classes.noImage
                )}
                alignItems="center"
                justifyContent="center"
              >
                {defaultImgSrc ? (
                  <>
                    <img
                      className={classNames(
                        classes.imagePreview,
                        classes.imagePreviewSmall)}
                      src={defaultImgSrc}
                      alt="product"
                    />
                  </>
                ) : (
                  <ImageOutlinedIcon />
                )}
              </Grid>
            </div>
          </Grid>
          <Divider />
        </Fragment>

        {BRANDING_FIELDS.map(field => (
          <Fragment key={field.title}>
            <Grid
              className={classes.brandingField}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1">
                {field.title}
              </Typography>
              {brandingState[field.fieldName] ?
                <div
                  className={classes.brandingColorPreview}
                  style={{ backgroundColor: brandingState[field.fieldName] || null }}
                /> :
                <NoColorIcon color="primary" />}
            </Grid>
            <Divider />
          </Fragment>
        ))}
      </Grid>
      <Dialog
        open={editMode}
        TransitionComponent={Transition}
        classes={{
          paper: classes.dialogPaper
        }}
        BackdropProps={{
          classes: {
            root: classes.dialogBackdrop
          }
        }}
      >
        <Formik
          initialValues={{
            ...brandingState
          }}
          enableReinitialize
          onSubmit={(values) => handleSubmitBranding(values)}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={brandingSchema}
        >
          {({
            values, handleChange, handleSubmit, errors, handleBlur
          }) => (
            <>
              <DialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="h6">
                  {t('settings.branding')}
                </Typography>
                <IconButton size="small" color="inherit" onClick={() => setEditMode(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container wrap="nowrap" direction="column">

                  <Grid container>
                    <Grid
                      xs={12}
                      item
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle1" color="textSecondary">
                        {t('settings.upload brand logo')}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={4}
                      item
                      container
                      className={classNames(
                        classes.imagePreviewContainer,
                        !imgSrc && classes.noImage
                      )}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {imgSrc ? (
                        <>
                          <img
                            className={classes.imagePreview}
                            src={imgSrc}
                            alt="product"
                          />
                        </>
                      ) : (
                        <ImageOutlinedIcon />
                      )}
                    </Grid>
                    <Grid
                      xs={7}
                      item
                      className={classes.dragNDropContainer}
                      container
                      alignItems="center"
                      justifyContent="center"
                      direction="column"
                      wrap="nowrap"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('products.image modal.drag and drop')}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t('products.image modal.or')}
                      </Typography>
                      <Button
                        className={classes.uploadPhotoButton}
                        variant="outlined"
                        startIcon={<UploadIcon color="primary" />}
                        onClick={openDragNDrop}
                      >
                        {t('products.image modal.upload photo')}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      xs={12}
                      item
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle1" color="textSecondary">
                        {t('settings.upload default product image')}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={4}
                      item
                      container
                      className={classNames(
                        classes.imagePreviewContainer,
                        !defaultImgSrc && classes.noImage
                      )}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {defaultImgSrc && (
                        <Grid
                          className={classes.deleteImageIcon}
                          onClick={() => {
                            setFilesDefaultImage([]);
                            setDefaultImgSrc(null);
                          }}
                        >
                          <CloseIcon color="primary" />
                        </Grid>
                      )}
                      {defaultImgSrc ? (
                        <>
                          <img
                            className={classes.imagePreview}
                            src={defaultImgSrc}
                            alt="product"
                          />
                        </>
                      ) : (
                        <ImageOutlinedIcon />
                      )}
                    </Grid>
                    <Grid
                      xs={7}
                      item
                      className={classes.dragNDropContainer}
                      container
                      alignItems="center"
                      justifyContent="center"
                      direction="column"
                      wrap="nowrap"
                      {...getRootPropsDefaultImage()}
                    >
                      <input {...getInputPropsDefaultImage()} />
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('products.image modal.drag and drop')}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t('products.image modal.or')}
                      </Typography>
                      <Button
                        className={classes.uploadPhotoButton}
                        variant="outlined"
                        startIcon={<UploadIcon color="primary" />}
                        onClick={openDragNDropDefaultImage}
                      >
                        {t('products.image modal.upload photo')}
                      </Button>
                    </Grid>
                  </Grid>

                  <SettingsInput
                    value={values.primaryColor}
                    name="primaryColor"
                    label={t('common.primary color')}
                    onChange={handleChange}
                    error={errors.primaryColor}
                    handleBlur={handleBlur}
                    placeholder="#000000"
                  />
                  <SettingsInput
                    value={values.secondaryColor}
                    name="secondaryColor"
                    label={t('common.secondary color')}
                    onChange={handleChange}
                    error={errors.secondaryColor}
                    handleBlur={handleBlur}
                    placeholder="#000000"
                  />
                  <SettingsInput
                    value={values.fontColor}
                    name="fontColor"
                    label={t('common.font color')}
                    onChange={handleChange}
                    error={errors.fontColor}
                    handleBlur={handleBlur}
                    placeholder="#000000"
                  />
                  <Grid
                    className={classes.noteContainer}
                    container
                    wrap="nowrap"
                    alignItems="center"
                  >
                    <InfoIcon color="primary" style={{ marginRight: 4 }} />
                    <Typography color="primary" variant="caption">
                      {t('settings.branding note')}
                    </Typography>
                  </Grid>
                  <Button
                    className={classNames(
                      classes.actionButton,
                      classes.submitButton
                    )}
                    fullWidth
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                  >
                    {t('common.confirm')}
                  </Button>
                </Grid>
              </DialogContent>
            </>
          )}
        </Formik>
      </Dialog>
    </Paper>
  );
}

Branding.propTypes = {
  brandingData: PropTypes.shape({
    fontColor: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  handleUpdateVendor: PropTypes.func.isRequired,
  image: PropTypes.string,
  defaultProductImage: PropTypes.string.isRequired,
};

Branding.defaultProps = {
  brandingData: {
    fontColor: null,
    primaryColor: null,
    secondaryColor: null
  },
  image: null
};
