import { gql } from '@apollo/client';

export const GET_VENDOR_CAMPAIGNS = gql`
  query Campaigns(
    $pageSize: Int
    $after: Int
    $sortBy: CampaignSortingOption
    $sortOrder: Int
  ) {
    campaigns(
      pageSize: $pageSize
      after: $after
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      campaigns {
        _id
        title
        vendorId
        schedule {
          repeat
          dayIndexes
          time {
            minute
            hour
          }
          repeatInterval
          endAfterCount
          startDate
          endDate
        }
        assignedToAll
        assignedToCustomers
        assignedToGroups {
          _id
        }
        enabled
        next
        status
        sender
        campaignType
        recipients {
          _id
          vendorSpecificId
          contactData {
            businessName
            label
            street
            houseNumber
            city
          }
          contacts {
            _id
            name
            phone
            additionalPhones
            email
            status
            disabled
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_VENDOR_CAMPAIGN = gql`
  query Campaign($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      _id
      title
      vendorId
      message
      schedule {
        repeat
        dayIndexes
        time {
          minute
          hour
        }
        repeatInterval
        endAfterCount
        startDate
        endDate
      }
      assignedToAll
      assignedToCustomers
      assignedToGroups {
        _id
      }
      enabled
      next
      status
      sender
      campaignType
      recipients {
        _id
        vendorSpecificId
        contactData {
          businessName
          street
          houseNumber
          city
          label
        }
        contacts {
          _id
          name
          phone
          additionalPhones
          email
          status
          disabled
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_RECIPIENTS = gql`
  query CampaignRecipients(
    $campaignId: ID!
    $pageSize: Int
    $after: Int
    $sortBy: CampaignSortingOption
    $sortOrder: Int
  ) {
    campaignRecipients(
      campaignId: $campaignId
      pageSize: $pageSize
      after: $after
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      totalResults
      prevPage
      hasPrevPage
      nextPage
      hasNextPage
      totalPages
      page
      recipients {
        _id
        vendorSpecificId
        contactData {
          businessName
          street
          houseNumber
          city
          label
        }
        contacts {
          _id
          name
          phone
          additionalPhones
          email
          status
          disabled
          registrationCode
          userMeta {
            name
            email
          }
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_REPORT = gql`
  query GetCampaignRecipientLog(
    $campaignId: String!
    $searchPhrase: String
    $after: Int
    $startDate: String
    $endDate: String
    $status: String
    $pageSize: Int
    $sortBy: CampaignRecipientLogSortOption
    $sortOrder: Int
  ) {
    getCampaignRecipientLog(
      campaignId: $campaignId
      searchPhrase: $searchPhrase
      after: $after
      startDate: $startDate
      endDate: $endDate
      status: $status
      pageSize: $pageSize
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      logs {
        body
        campaignId
        campaignTitle
        from
        status
        sentAt
        to
      }
      page
      totalPages
      totalResults
      prevPage
      nextPage
      hasPrevPage
      hasNextPage
    }
  }
`;

export const GET_CAMPAIGN_STATISTIC = gql`
  query GetCampaignStatistic($campaignId: String!) {
    getCampaignStatistic(campaignId: $campaignId) {
      _id
      failed
      sent
      sentAt
    }
  }
`;
