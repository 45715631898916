import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import DeleteModal from 'components/shared/DeleteModal';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  deleteCampaignButton: {
    height: 45,
    color: palette.secondary.darkGray,
    fontWeight: typography.fontWeightRegular,
    marginLeft: spacing(2)
  },
}));

export default function CampaignsMultiSelector({
  selectedCampaignIds,
  handleDeleteCampaign,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);

  return (
    <>
      <Button
        className={classes.deleteCampaignButton}
        onClick={() => setDeleteModalOpen(true)}
        variant="outlined"
        startIcon={<DeleteIcon color="primary"/>}
      >
        {t('common.delete')}
      </Button>
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          message={t('campaigns.delete campaigns message')}
          handleCloseModal={() => setDeleteModalOpen(false)}
          handleDelete={() => {
            setDeleteModalOpen(false);
            handleDeleteCampaign(selectedCampaignIds);
          }}
        />
      )}
    </>
  );
}

CampaignsMultiSelector.propTypes = {
  selectedCampaignIds: PropTypes.array.isRequired,
  handleDeleteCampaign: PropTypes.func.isRequired,
};
