import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  textInput: {
    width: '100%',
    border: theme.palette.border.darkGrey,
    borderRadius: 4,
    margin: `${theme.spacing(0.75)}px 0px`
  },
  input: {
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  error: {
    border: `1.5px solid ${theme.palette.error.main}`,
    borderRadius: 4,
  },
  formControl: {
    top: 16
  },
  errorMessage: {
    width: '100%',
    textAlign: 'left'
  }
}));

export default function SettingsInput({
  value,
  name,
  onChange,
  error,
  label,
  fullWidth,
  handleBlur,
  placeholder,
  inputEndAdornment,
}) {
  const classes = useStyles();

  return (
    <>
      <TextField
        label={label}
        name={name}
        variant="outlined"
        className={classes.textInput}
        InputProps={{
          classes: {
            input: classNames(classes.input, error && classes.error)
          },
          endAdornment: inputEndAdornment
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.formControl
          }
        }}
        value={value || ''}
        onChange={onChange}
        onBlur={handleBlur}
        fullWidth={fullWidth}
        error={!!error}
        placeholder={placeholder}
      />
      {error && (
        <Typography
          className={classes.errorMessage}
          variant="body2"
          color="error"
        >
          {error}
        </Typography>
      )}
    </>
  );
}

SettingsInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  inputEndAdornment: PropTypes.node,
};

SettingsInput.defaultProps = {
  error: null,
  fullWidth: true,
  value: '',
  placeholder: '',
  inputEndAdornment: null,
};
