/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query orders(
    $pageSize: Int
    $after: Int
    $sortBy: OrderSortingOption
    $sortOrder: Int
    $searchPhrase: String
  ) {
    vendorOrders(
      pageSize: $pageSize
      after: $after
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchPhrase: $searchPhrase
    ) {
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      orders {
        _id
        customer {
          _id
          vendorClientId
          contactData {
            businessName
          }
        }
        vendorId
        customerId
        status
        deliveryDate
        orderedAt
        deliveryDetails {
          message
        }
        orderNumber
        deliveryAddress {
          zip
          city
          houseNumber
          street
          country
        }
        userProfileName
        products {
          _id
          name
          variantId
          packaging
          qty
          price
        }
        net
        gross
        paymentMethod {
          name
          documents {
            type
            net
            gross
            documentNumber
            url
            createdAt
            deletedAt
            uuid
          }
          paymentInfo {
            status
            vendorPayoutAmount
            vendorPayoutDate
            deadline
            bic
            receiver
          }
        }
        totalPrice
      }
    }
  }
`;

export const GET_ORDER_CSV = gql`
  query downloadOrderCsv($orderId: ID!) {
    downloadOrderCsv(orderId: $orderId) {
      fileName
      csvString
    }
  }
`;

export const GET_ORDER_PDF = gql`
  query downloadOrderPdf($orderId: ID!) {
    downloadOrderPdf(orderId: $orderId) {
      fileName
      pdf
    }
  }
`;
