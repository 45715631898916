import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_NEW_EXPIRING_DEAL } from 'graphql/mutation/deals';
import { GET_PRODUCTS_FOR_DEALS } from 'graphql/query/deals';

import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';
import useMixPanel from 'helpers/useMixPanel';
import { getDateStatus } from 'helpers/dates';
import { useNotification } from 'hooks/useNotification';

import DateInput from 'components/DealsTable/DateInput';
import PriceInput from 'components/DealsTable/PriceInput';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    width: 920,
    maxWidth: 920,
  },
  modalText: {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 16,
    fontWeight: 400,
  },
  dateListItem: {
    margin: theme.spacing(0, 1),
  },
  shortListItem: {
    height: 40,
  },
  actionButton: {
    height: 50,
    marginTop: theme.spacing(4.5)
  },
  textInput: {
    width: '100%',
    border: theme.palette.border.lightGrey,
    borderRadius: 4,
    margin: `${theme.spacing(1)}px 0px`
  },
  input: {
    padding: theme.spacing(4.75, 2, 2),
  },
  formControl: {
    top: 16
  },
  disabledCheckbox: {
    color: theme.palette.text.disabled,
    opacity: 0.5,
    background: theme.palette.background.gray,
    '&$disabledCheckbox': {
      color: theme.palette.text.disabled,
    },
  },
  modalCell: {
    padding: theme.spacing(0.2),
  },
  explanation: {
    marginBottom: theme.spacing(1),
    fontWeight: 400,
  }
}));

// const DEFAULT_DEAL_STATE = {
//   ids: '',
//   startDate: moment().utc().toISOString(),
//   endDate: moment().utc().toISOString()
// };

export default function AddExpiringProductsModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();
  const [createNewExpiringDeal] = useMutation(CREATE_NEW_EXPIRING_DEAL);
  const [newDeals, setNewDeals] = React.useState([]);
  const [currentArticles, setCurrentArticles] = React.useState([]);
  const [currentArticlesInput, setCurrentArticlesInput] = React.useState('');
  const showSuccessNotification = useNotification(NOTIFICATION_STATUS.SUCCESS);
  const showAlertNotification = useNotification(NOTIFICATION_STATUS.ALERT);

  useEffect(() => {
    setCurrentArticles([]);
  }, [props.isOpen]);

  const [getProductsForDeals, {
    loading: productsForDealsLoading,
    // error: productsForDealsError,
    data: productsForDeals,
  }] = useLazyQuery(
    GET_PRODUCTS_FOR_DEALS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const {
    isOpen,
    handleClose,
    refetchDeals,
  } = props;

  const handleCreateNewDeal = async () => {
    const deals = newDeals.filter((deal) => deal.enabled);
    const isValid = deals.every((deal) => (
      deal.dealPrice > 0
        && deal.start
        && deal.end
        && deal.expiresAt
        && moment(deal.start).isSameOrBefore(deal.end, 'day')
        // ExpiresAt should be after end date at least for 1 day
        && moment(deal.end).add(1, 'day').isSameOrBefore(deal.expiresAt, 'day')
    ));
    if (deals.length === 0) {
      showAlertNotification(t('deals.error no products'));
      return;
    }

    if (!isValid) {
      showAlertNotification(t('deals.error invalid dates or price'));
      return;
    }

    try {
      await createNewExpiringDeal({
        variables: {
          deals,
        },
        update: (_, { data: { addExpiringDeals: addDeals } }) => {
          refetchDeals();
          if (addDeals.notUpdated.length) {
            showAlertNotification(
              t('deals.add deal error', {
                products: addDeals.notUpdated.join(', '),
                count: addDeals.notUpdated.length,
              })
            );
          } else {
            showSuccessNotification(t('deals.add deal success'));
          }
        }
      });
      mixPanel.track('Create Expiring Products Deal', {
        products: Object.keys(newDeals).toString()
      });
      setNewDeals([]);
    } catch (error) {
      showAlertNotification(t('deals.add deal network error'));
    }
    handleClose();
  };

  const handleDealChange = (fieldName, value, product, variant) => {
    const deals = [...newDeals];
    let dealIndex = deals
      .findIndex((deal) => (
        deal.productId === product.vendorSpecificId
        && deal.packaging === variant.packaging
      ));

    if (dealIndex < 0) {
      deals.push({
        productId: product.vendorSpecificId,
        enabled: true,
        baseUnit: variant.baseUnit,
        packaging: variant.packaging,
        dealPrice: 0,
        start: moment().utc().startOf('day').toISOString(),
        end: null,
        expiresAt: null,
        createVariantOnImport: true,
        autoGenerated: false,
      });
      dealIndex = deals.length - 1;
    }
    if (['dealPrice'].includes(fieldName)) {
      deals[dealIndex][fieldName] = parseFloat(value);
    } else {
      deals[dealIndex][fieldName] = value;
    }

    setNewDeals(deals);
  };

  const handlerArticlesInput = (event) => {
    setCurrentArticlesInput(event.target.value);
  };

  const handleCheckArticles = () => {
    if (currentArticlesInput.trim()) {
      const articlesArray = currentArticlesInput.replace(/\s/g, ',').split(',').filter(value => value !== '');
      setCurrentArticles(articlesArray.map((article) => article.trim()));
      getProductsForDeals(
        {
          variables: {
            productIds: articlesArray
          }
        }
      );
    }
  };

  const products = productsForDeals?.vendorVariantsForDeals?.products || [];

  const handleDateChange = (date, dateField, product, variant) => {
    switch (dateField) {
      case 'start':
        handleDealChange('start', date, product, variant);
        handleDealChange('end', null, product, variant);
        handleDealChange('expiresAt', null, product, variant);
        break;
      case 'end':
        handleDealChange('end', date, product, variant);
        handleDealChange('expiresAt', null, product, variant);
        break;
      case 'expiresAt':
        handleDealChange('expiresAt', date, product, variant);
        break;
      default:
        handleDealChange(dateField, date, product, variant);
        break;
    }
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      modalTitle={t('deals.add deal title')}
      width={600}
      classes={{ containerClass: classes.modalContainer }}
    >
      <>
        {productsForDealsLoading && (
          <Typography variant="h6" className={classes.modalText}>{t('deals.loading')}</Typography>
        )}
        {!productsForDealsLoading && currentArticles.length > 0 && products.length === 0 && (
          <Typography variant="h6" className={classes.modalText}>{t('deals.no products found')}</Typography>
        )}
        {!productsForDealsLoading && currentArticles.length > 0 && (
          <>
            <Typography variant="subtitle2" className={classes.explanation}>{t('deals.create variant button explanation')}</Typography>
            <Typography variant="subtitle2" className={classes.explanation}>
              {t('deals.create variant button explanation example')}
            </Typography>
            <Typography variant="subtitle2" className={classes.explanation}>
              {t('deals.create variant button explanation continued')}
            </Typography>
            {products.map((p) => (
              <Fragment key={p._id}>
                <Typography variant="h6" className={classes.modalText}>{p.vendorSpecificId} / {p.name}</Typography>
                <TableContainer>
                  <Table className={classes.table} size="small" aria-label="Variants">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.modalCell}>{t('deals.select')}</TableCell>
                        <TableCell align="left" className={classes.modalCell}>{t('deals.packaging')}</TableCell>
                        <TableCell align="left" className={classes.modalCell}>{t('deals.start')}</TableCell>
                        <TableCell align="left" className={classes.modalCell}>{t('deals.end')}</TableCell>
                        <TableCell align="left" className={classes.modalCell}>{t('deals.expiresAt')}</TableCell>
                        <TableCell align="left" className={classes.modalCell}>{t('deals.deal price')}</TableCell>
                        <TableCell align="left" className={classes.modalCell}>
                          {t('deals.create variant')}
                          <br/>
                          {t('deals.create variant-2')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {p?.variants?.map((v) => {
                        const dealData = newDeals.find((deal) =>
                          deal.productId === p.vendorSpecificId
                          && deal.packaging === v.packaging
                        ) || {};
                        const {
                          enabled = false,
                          start,
                          end,
                          expiresAt,
                          dealPrice,
                          createVariantOnImport = true,
                        } = dealData || {};
                        const canBeSelected = v.canHandleExpiringDeal;
                        return (
                          <TableRow key={p._id + v.packaging}>
                            <TableCell component="th" className={classes.modalCell} scope="row">
                              <Checkbox
                                edge="start"
                                checked={enabled}
                                disabled={!canBeSelected}
                                tabIndex={-1}
                                disableRipple
                                indeterminate={!canBeSelected}
                                inputProps={{ 'aria-labelledby': v._id }}
                                onChange={(evt, checked) => handleDealChange('enabled', checked, p, v)}
                                className={!canBeSelected ? classes.disabledCheckbox : ''}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.modalCell}>{v.packaging}</TableCell>
                            <TableCell align="left" className={classes.modalCell}>
                              <DateInput
                                dateStatus={getDateStatus(start, end)}
                                date={start}
                                dateType="start"
                                isEditMode={canBeSelected && enabled}
                                handleChangeDate={(date) => handleDateChange(date, 'start', p, v)}
                                noPadding
                                format="DD-MM-YYYY"
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.modalCell}>
                              <DateInput
                                dateStatus={getDateStatus(start, end)}
                                date={end}
                                dateType="end"
                                isEditMode={canBeSelected && enabled}
                                handleChangeDate={(date) => handleDateChange(date, 'end', p, v)}
                                noPadding
                                format="DD-MM-YYYY"
                                minDate={start}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.modalCell}>
                              <DateInput
                                dateStatus={getDateStatus(start, expiresAt)}
                                date={expiresAt}
                                dateType="expiresAt"
                                isEditMode={canBeSelected && enabled}
                                handleChangeDate={(date) => handleDateChange(date, 'expiresAt', p, v)}
                                noPadding
                                format="DD-MM-YYYY"
                                disabled={!end}
                                minDate={moment(end).add(1, 'd')}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.modalCell}>
                              { canBeSelected && enabled ? (
                                <PriceInput
                                  price={dealPrice || 0}
                                  justify="flex-start"
                                  handleChangePrice={({ target: { value } }) => {
                                    handleDealChange('dealPrice', value, p, v);
                                  }}
                                  width={60}
                                  isEditMode={canBeSelected && enabled}
                                />) : dealPrice || 0 }
                            </TableCell>
                            <TableCell align="left" className={classes.modalCell}>
                              <Checkbox
                                edge="start"
                                checked={createVariantOnImport}
                                onChange={(event, checked) => {
                                  handleDealChange('createVariantOnImport', checked, p, v);
                                }}
                                disabled={!enabled}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Fragment>
            ))}
          </>
        )}
        {!productsForDealsLoading && currentArticles.length === 0 && (
          <TextField
            label={t('deals.inputHelperText')}
            variant="outlined"
            className={classes.textInput}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.formControl
              }
            }}
            value={currentArticlesInput}
            onChange={handlerArticlesInput}
          />
        )}
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={currentArticles.length > 0 ? handleCreateNewDeal : handleCheckArticles}
          variant="contained"
          color="primary"
        >
          {currentArticles.length > 0 ? t('deals.submit') : t('deals.check articles')}
        </Button>
      </>
    </ModalLayout>
  );
}

AddExpiringProductsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refetchDeals: PropTypes.func.isRequired,
};
