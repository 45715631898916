import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';

import DeleteListModal from 'components/shared/DeleteModal';
import ListsTableRowCells from 'components/ListsTable/ListsTableRowCells';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 56,
    borderBottom: theme.palette.border.lightGrey
  },
  tableWithOpenedPanel: {
    background: theme.palette.background.container,
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    maxWidth: 150,
    color: theme.palette.secondary.textBlack,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1),
    },
  },
  tableCellPanelOpen: {
    backgroundColor: theme.palette.background.container,
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.button
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.darkGray
  },
  confirmButton: {
    color: theme.palette.success.main,
    margin: 4
  },
  cancelButton: {
    margin: 4,
    color: theme.palette.error.main
  },
  packageLabel: {
    height: 24,
    width: 56,
    borderRadius: 30,
  },
  hasLabel: {
    background: 'rgba(255, 81, 0, 0.15)'
  },
  emptyLabel: {
    background: theme.palette.error.main
  },
  packagingUnitLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 40,
  },
  highlightRow: {
    animation: `$highlighting 1800ms ${theme.transitions.easing.easeOut}`,
  },
  '@keyframes highlighting': {
    '0%': {
      backgroundColor: theme.palette.primary.main,
    },
    '100%': {
      backgroundColor: theme.palette.background.default,
    }
  }
}));

function ListTableRow({
  rowData,
  selectedList,
  executeScroll,
  listRef,
  handleDeleteList,
  rowsPerPage,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const openListDetailsTable = async (id) => {
    history.replace({
      pathname: '/lists',
      search:
          queryString.stringify({ ...queryString.parse(location.search), id })
    });
    if (rowsPerPage && rowsPerPage !== 0) {
      history.push(`list/${id}?rowsPerPage=${rowsPerPage}`);
    } else {
      history.push(`list/${id}`);
    }
  };

  React.useEffect(() => {
    if (selectedList === rowData._id) {
      executeScroll();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRef = selectedList === rowData._id ? listRef : null;

  return (
    <TableRow
      className={classNames(
        classes.tableBodyRow,
        setRef && classes.highlightRow
      )}
      ref={setRef}
    >
      <ListsTableRowCells rowData={rowData} />
      <TableCell className={classes.tableCell}>
        <Grid container wrap="nowrap">
          <Button
            variant="contained"
            classes={{ root: classes.tableActionButton }}
            onClick={() => openListDetailsTable(rowData._id)}
          >
            <EditIcon className={classes.tableActionButtonIcon}/>
          </Button>
          <Button
            variant="contained"
            classes={{ root: classes.tableActionButton }}
            onClick={() => setOpenDeleteModal(true)}
          >
            <DeleteIcon className={classes.tableActionButtonIcon}/>
          </Button>
        </Grid>
      </TableCell>
      <DeleteListModal
        isOpen={openDeleteModal}
        message={t('lists.delete modal message')}
        handleCloseModal={() => setOpenDeleteModal(false)}
        handleDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteList(rowData._id, rowData.title);
        }}
      />
    </TableRow>
  );
}

ListTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  selectedList: PropTypes.string,
  listRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  executeScroll: PropTypes.func.isRequired,
  handleDeleteList: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number,
};

ListTableRow.defaultProps = {
  selectedList: null,
  rowsPerPage: null
};

export default React.memo(ListTableRow, (prevProps, nextProps) =>
  isEqual(prevProps.rowData, nextProps.rowData)
);
