/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const REMOVE_PRODUCT_IMAGE = gql`
  mutation removeImage($productId: String!) {
    removeImage(productId: $productId)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($productId: ID!) {
    deleteProduct(productId: $productId) {
      _id
      name
      vendorId
      vendorSpecificId
      enabled
      discontinued
      image
      imageUrl
      description
      categories
      variants {
        _id
        baseUnit
        packaging
        price
        unitsInPackaging
        attributes {
          _id
          isRequired
          name
          options {
            name
            _id
            translations {
              en
              de
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($product: ProductUpdateInput!) {
    updateProduct(product: $product) {
      _id
      name
      vendorId
      vendorSpecificId
      enabled
      discontinued
      image
      imageUrl
      description
      categories
      variants {
        _id
        baseUnit
        packaging
        price
        unitsInPackaging
        attributes {
          _id
          isRequired
          name
          options {
            name
            _id
            translations {
              en
              de
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($product: ProductCreateInput!) {
    createProduct(product: $product) {
      _id
      name
      vendorId
      vendorSpecificId
      enabled
      discontinued
      image
      imageUrl
      description
      categories
      variants {
        _id
        baseUnit
        packaging
        price
        unitsInPackaging
        attributes {
          _id
          isRequired
          name
          options {
            name
            _id
            translations {
              en
              de
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_ATTRIBUTE = gql`
  mutation CreateProductAttribute(
    $attribute: ProductAttributeCreateInput
    $vendorSpecificIds: [ID!]
  ) {
    createProductAttribute(
      attribute: $attribute
      vendorSpecificIds: $vendorSpecificIds
    ) {
      _id
      isRequired
      name
      options {
        name
        _id
        translations {
          en
          de
          fr
          es
          ve
        }
      }
      products {
        totalResults
        page
        totalPages
        hasNextPage
        products {
          _id
          name
          vendorSpecificId
          variants {
            _id
            packaging
          }
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT_ATTRIBUTE = gql`
  mutation UpdateProductAttribute(
    $attribute: ProductAttributeUpdateInput
    $id: ID!
  ) {
    updateProductAttribute(_id: $id, attribute: $attribute) {
      _id
      isRequired
      name
      options {
        name
        _id
        translations {
          en
          de
          fr
          es
          ve
        }
      }
      products {
        totalResults
        page
        totalPages
        hasNextPage
        products {
          _id
          name
          vendorSpecificId
          variants {
            _id
            packaging
          }
        }
      }
    }
  }
`;

export const DELETE_PRODUCT_ATTRIBUTE = gql`
  mutation DeleteProductAttribute($_id: ID!) {
    deleteProductAttribute(_id: $_id) {
      _id
      name
    }
  }
`;

export const DELETE_PRODUCT_ATTRIBUTES = gql`
  mutation DeleteProductAttributes($ids: [ID!], $vendorSpecificIds: [ID!]) {
    deleteProductAttributes(ids: $ids, vendorSpecificIds: $vendorSpecificIds) {
      _id
      name
    }
  }
`;

export const ASSIGN_PRODUCT_ATTRIBUTE = gql`
  mutation AssignAttributeToProducts(
    $id: ID!
    $isRequired: Boolean!
    $vendorSpecificIds: [ID!]
    $productIds: [ID!]
  ) {
    assignAttributeToProducts(
      _id: $id
      isRequired: $isRequired
      vendorSpecificIds: $vendorSpecificIds
      productIds: $productIds
    ) {
      _id
      isRequired
      name
      options {
        name
        _id
        translations {
          en
          de
          fr
          es
          ve
        }
      }
      products {
        totalResults
        page
        totalPages
        hasNextPage
        products {
          _id
          name
          vendorSpecificId
          variants {
            _id
            packaging
          }
        }
      }
    }
  }
`;

export const UNASSIGN_PRODUCT_ATTRIBUTE = gql`
  mutation UnassignAttributeFromProducts(
    $id: ID!
    $vendorSpecificIds: [ID!]
    $productIds: [ID!]
  ) {
    unassignAttributeFromProducts(
      _id: $id
      vendorSpecificIds: $vendorSpecificIds
      productIds: $productIds
    ) {
      _id
      isRequired
      name
      options {
        name
        _id
        translations {
          en
          de
          fr
          es
          ve
        }
      }
      products {
        totalResults
        page
        totalPages
        hasNextPage
        products {
          _id
          name
          vendorSpecificId
          variants {
            _id
            packaging
          }
        }
      }
    }
  }
`;
