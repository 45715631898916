/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import MailRoundedIcon from '@material-ui/icons/MailRounded';

import useMixPanel from 'helpers/useMixPanel';
import newsletterPlaceholderImg from 'assets/newsletter-placeholder.png';

const useStyles = makeStyles(({ palette, typography, spacing, breakpoints }) => ({
  newsletterPlaceholderContainer: {
    height: '100%',
    padding: spacing(2, 6),
    [breakpoints.up('lg')]: {
      padding: spacing(2, 15)
    },
  },
  placeholderTitle: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2)
  },
  placeholderText: {
    lineHeight: '28px',
    fontWeight: typography.fontWeightBold,
  },
  placeholderList: {
    paddingLeft: spacing(1),
    margin: spacing(0, 0.75),
    '& li::marker': {
      color: palette.primary.main
    }
  },
  placeholderAction: {
    width: 'fit-content',
    borderRadius: 40,
    boxShadow: 'none',
    marginTop: spacing(3)
  },
  placeholderImage: {
    width: '100%',
    height: '100%',
    maxWidth: 550,
    [breakpoints.up('lg')]: {
      maxWidth: 750,
    },
  }
}));

export default function NewsletterPlaceholder({ vendorData, handleCreateNewsletter }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const handleMoveToNewsletters = () => {
    mixPanel.track('Newsletter - Placeholder Click', {
      ...(vendorData?.vendorAccount || {})
    });
    handleCreateNewsletter();
  };

  return (
    <Grid
      className={classes.newsletterPlaceholderContainer}
      container
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item xs={4}>
        <Typography className={classes.placeholderTitle} variant="h4">
          {t('newsletter.placeholder title')}
        </Typography>
        <Typography
          className={classes.placeholderText}
          variant="h6"
          color="textSecondary"
          gutterBottom
        >
          {t('newsletter.placeholder subtitle')}
        </Typography>
        <ul className={classes.placeholderList}>
          {[1, 2, 3].map(point => (
            <li key={point}>
              <Typography className={classes.placeholderText} variant="h6">
                {t(`newsletter.placeholder point ${point}`)}
              </Typography>
            </li>
          ))}
        </ul>
        <Button
          endIcon={<MailRoundedIcon color="inherit" fontSize="small" />}
          variant="contained"
          color="primary"
          className={classes.placeholderAction}
          onClick={handleMoveToNewsletters}
        >
          {t('newsletter.placeholder action')}
        </Button>
      </Grid>
      <Grid container item xs={8}>
        <img
          className={classes.placeholderImage}
          src={newsletterPlaceholderImg}
          alt="start first newsletter"
        />
      </Grid>
    </Grid>
  );
}

NewsletterPlaceholder.propTypes = {
  vendorData: PropTypes.object,
  handleCreateNewsletter: PropTypes.func.isRequired,
};

NewsletterPlaceholder.defaultProps = {
  vendorData: {}
};
