import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TableLayout from 'components/shared/TableLayout';
import { specialDaysTypes, TABLE_SKELETON_TYPES } from 'helpers/constants';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { GET_SPECIAL_DAYS } from 'graphql/query/specialDays';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PlusIcon from '@material-ui/icons/Add';
import Toolbar from '@material-ui/core/Toolbar';
import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';
import DeliveryDaysTableRow from 'components/DeliveryDays/DeliveryDaysTableRow';
import EditDialog from 'components/DeliveryDays/EditDialog';
import AddOrEditDeliveryDayDialog from 'components/DeliveryDays/AddOrEditDeliveryDayDialog';
import DeleteDeliveryDay from 'components/DeliveryDays/DeleteDeliveryDay';

function HolidayBlockerPage({ classes, type }) {
  const { t } = useTranslation();
  const [editModeId, setEditModeId] = React.useState(0);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = React.useState(false);
  const [isAddDialogOpened, setIsAddDialogOpened] = React.useState(false);
  const [editedDate, setEditedDate] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [getVendorSpecialDays, {
    loading: isLoading,
    // error: errors,
    data: specialDaysData,
  }] = useLazyQuery(
    GET_SPECIAL_DAYS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const updateList = () => {
    if (type && specialDaysTypes[type]) {
      getVendorSpecialDays({
        variables: {
          vendorId: activeAccountIdVar(),
          type: specialDaysTypes[type],
          after: page,
          pageSize: rowsPerPage,
        }
      });
    }
  };

  useEffect(() => {
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountIdVar, page, rowsPerPage, type]);

  const tableColumns = [
    {
      title: t('specialDays.tableHeaderEnabled'),
      field: 'enabled',
    },
    {
      title: t('specialDays.tableHeaderDate'),
      field: 'applyForDate',
    },
    {
      title: t('specialDays.tableHeaderAssignedTo'),
      field: 'customers',
      columnStyles: classNames(classes.columnTitle, classes.dealTitle)
    },
  ];

  const handleClickAway = () => {};
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => setRowsPerPage(parseInt(event.target.value, 10));

  const specialDays = specialDaysData?.getAllSpecialDays;

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h1" className={classes.title}>{t(`specialDays.${type}`)}</Typography>
        <Grid>
          <Button
            className={classes.button}
            onClick={() => setIsAddDialogOpened(true)}
            disabled={!specialDaysTypes[type]}
          >
            <PlusIcon />
            {t('specialDays.Add new')}
          </Button>
        </Grid>
      </Toolbar>
      <TableLayout
        handleClickAway={handleClickAway}
        isEditMode={Boolean(editModeId)}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.DEALS}
        emptyTableData={!specialDays?.totalResults}
        emptyTableDataMessage={t('specialDays.noSpecialDays')}
        tableIsLoading={isLoading}
        withDetailPanel
        withActions
        actionColumnTitle={t('specialDays.actions')}
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={specialDays?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        itemsOnPage={specialDays?.deliveryDays?.length}
        customClasses={classes}
        title=""
      >
        {!!specialDays?.deliveryDays?.length && specialDays?.deliveryDays
          .map((rowData, index) => (
            rowData && (
              <DeliveryDaysTableRow
                key={rowData._id}
                columns={tableColumns}
                rowData={rowData}
                isEditMode={rowData._id === editModeId}
                setEditMode={() => setEditModeId(rowData._id)}
                classes={classes}
                onDelete={() => {
                  setDeleteId(rowData._id);
                  setIsDeleteDialogOpened(true);
                }}
                rowIndex={index}
                openEditDialog={(data) => {
                  setEditedDate(data);
                  setIsAddDialogOpened(true);
                }}
                updateList={updateList}
              />
            )))}
      </TableLayout>
      {!!editModeId && (
        <EditDialog
          editModeId={editModeId}
          setEditModeId={setEditModeId}
          specialDays={specialDays?.deliveryDays.find((day) => day._id === editModeId)}
          type={specialDaysTypes[type]}
          classes={classes}
          onUpdated={() => updateList()}
        />
      )}
      {isAddDialogOpened && (
        <AddOrEditDeliveryDayDialog
          classes={classes}
          isOpen={isAddDialogOpened}
          onClose={() => {
            setIsAddDialogOpened(false);
            setEditedDate(null);
          }}
          type={specialDaysTypes[type]}
          onUpdate={() => updateList()}
          editData={editedDate}
        />
      )}
      {isDeleteDialogOpened && (
        <DeleteDeliveryDay
          classes={classes}
          isOpen={isDeleteDialogOpened}
          deleteId={deleteId}
          onDelete={() => {
            updateList();
            setIsDeleteDialogOpened(false);
            setDeleteId(null);
          }}
          onClose={() => setIsDeleteDialogOpened(false)}
          onUpdate={() => updateList()}
        />
      )}
    </>
  );
}

HolidayBlockerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default HolidayBlockerPage;
