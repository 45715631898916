import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { SINGLE_FILE_UPLOAD } from 'graphql/mutation/fileUploading';
import { GET_VENDOR_CUSTOMERS } from 'graphql/query/vendors';
import { GET_ALL_CUSTOMERS_CSV } from 'graphql/query/files';
import { useMutation, useLazyQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import ForwardIcon from '@material-ui/icons/ArrowForward';

import { NOTIFICATION_STATUS, MAX_CSV_FILE_SIZE } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';
import CsvNotifications from 'components/shared/CsvNotifications';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  modalText: {
    width: 355,
  },
  optionalMessage: {
    fontWeight: theme.typography.fontWeightLight,
    fontStyle: 'Italic',
    marginLeft: theme.spacing(0.5),
  },
  downloadButton: {
    margin: `${theme.spacing(3)}px 0px ${theme.spacing(4.5)}px`,
  },
  uploadButton: {
    margin: `${theme.spacing(3)}px 0px ${theme.spacing(1.5)}px`,
  },
  modalButtons: {
    height: 74,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    border: theme.palette.border.darkGrey,
    backgroundColor: '#FAFAFA'
  },
  actionButton: {
    height: 50,
    boxShadow: 'none',
  },
  noticeContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
    border: theme.palette.border.darkGrey,
    borderRadius: 4,
    color: theme.palette.secondary.darkGray,
    marginBottom: theme.spacing(14)
  },
  downloadProgress: {
    marginBottom: theme.spacing(0.5)
  }
}));

export default function ModalCsvCustomerUpload({ isOpen, handleClose, vendorHasFtpIntegration }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const [modalStep, setModalStep] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [uploadingErrors, setUploadingErrors] = React.useState(null);
  const [successUploading, setSuccessUploading] = React.useState(null);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [fileUploadMutation, { loading: fileLoading }] = useMutation(SINGLE_FILE_UPLOAD);

  const [downloadCsv, {
    data: downloadAllCustomersCsv,
    loading: csvDownloadLoading
  }] = useLazyQuery(
    GET_ALL_CUSTOMERS_CSV,
    { fetchPolicy: 'network-only' }
  );

  React.useEffect(() => {
    try {
      if (!downloadAllCustomersCsv || !downloadAllCustomersCsv?.downloadAllCustomersCsv) return;
      const csvContent = JSON.parse(downloadAllCustomersCsv.downloadAllCustomersCsv);

      const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const filename = `customer_export_${new Date(Date.now()).toLocaleString()
        .replace(/\s/g, '').replace(/,/g, '-')}.csv`;
      if (window.navigator.msSaveOrOpenBlob) {
        // IE 10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong extended', { errorMessage: error.message }),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadAllCustomersCsv]);

  function handleOnFileChange({ target: { validity, files } }) {
    if (validity.valid) {
      if (files[0].size > MAX_CSV_FILE_SIZE) {
        setNotification({
          variables: {
            timeout: 4000,
            message: t('common.file size exceeded'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      } else {
        setSuccessUploading(null);
        setUploadingErrors(null);
        setUploadedFile(files[0]);
      }
    }
  }

  const resetAllState = () => {
    setModalStep(0);
    setUploadedFile(null);
    setSuccessUploading(null);
    setUploadingErrors(null);
  };

  const handleCloseModal = () => {
    resetAllState();
    handleClose();
  };

  const handleUploadCsvCustomers = async () => {
    setSuccessUploading(null);
    setUploadingErrors(null);
    try {
      await fileUploadMutation({
        variables: { file: uploadedFile, collection: 'customers' },
        update: (store, { data: { ingestSingleFile: { errors, success } } }) => {
          if (errors && errors.length > 0) {
            setUploadingErrors({ list: errors.slice(0, 10), total: errors.length });
          }
          if (success) {
            mixPanel.track('Uploaded CSV Customers');
            setSuccessUploading({ ...success });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('customers.csv modal success message'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        },
        refetchQueries: [{ query: GET_VENDOR_CUSTOMERS }]
      });
    } catch (error) {
      console.error('ERROR', error);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message,
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const modalTitle = (modalStep === 0 && t('customers.csv modal create customer')) ||
    (modalStep === 1 && t('customers.csv modal edit customer'));

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleCloseModal}
      modalTitle={modalTitle}
      withBackButton={modalStep > 0}
      handleMoveBack={() => setModalStep(prevStep => prevStep - 1)}
    >
      {modalStep === 0 && (
        <>
          {csvDownloadLoading && (
            <LinearProgress className={classes.downloadProgress} color="primary" />
          )}
          <Typography className={classes.modalText}>
            {t('customers.template message')}
            <span className={classes.optionalMessage}>(Optional)</span>
          </Typography>
          <Link
            component={Button}
            className={classNames(classes.downloadButton, classes.modalButtons)}
            fullWidth
            rel="noreferrer"
            href="https://storage.googleapis.com/chfslst-csv-templates/customers_template_csv.csv"
            startIcon={<DownloadIcon color="primary" />}
          >
            {t('common.download template')}
          </Link>
          <Typography className={classes.modalText}>
            {t('products.backup message customers')}
            <span className={classes.optionalMessage}>(Optional)</span>
          </Typography>
          <Button
            className={classNames(classes.downloadButton, classes.modalButtons)}
            fullWidth
            variant="contained"
            color="default"
            startIcon={<DownloadIcon color="primary" />}
            disabled={csvDownloadLoading}
            onClick={async () => {
              try {
                await downloadCsv();
              } catch (error) {
                console.error('CSV_DOWNLOAD_ERROR', error.message);
              }
            }}
          >
            {t('common.download csv')}
          </Button>
          {!vendorHasFtpIntegration &&
            <Button
              className={classes.actionButton}
              fullWidth
              onClick={() => setModalStep(1)}
              variant="contained"
              color="primary"
              endIcon={<ForwardIcon />}
            >
              {t('products.next step')}
            </Button>}
        </>
      )}
      {modalStep === 1 && (
        <>
          <Typography className={classes.modalText}>
            {t('products.upload message')}
          </Typography>
          <label htmlFor="upload-csv">
            <input
              style={{ display: 'none' }}
              id="upload-csv"
              name="upload-csv"
              type="file"
              multiple
              required
              onChange={handleOnFileChange}
            />
            <Button
              className={classNames(classes.uploadButton, classes.modalButtons)}
              fullWidth
              variant="contained"
              color="default"
              startIcon={<UploadIcon color="primary" />}
              component="span"
            >
              {t('common.select csv')}
            </Button>
          </label>
          {uploadedFile && (
            <Typography gutterBottom noWrap>
              {`${t('common.selected file')}: ${uploadedFile.name}`}
            </Typography>
          )}
          {fileLoading && (
            <LinearProgress color="primary" />
          )}
          <CsvNotifications errors={uploadingErrors} success={successUploading} />
          {(!successUploading || uploadingErrors) ? (
            <Button
              className={classes.actionButton}
              fullWidth
              disabled={!uploadedFile}
              onClick={handleUploadCsvCustomers}
              variant="contained"
              color="primary"
            >
              {t('common.upload csv')}
            </Button>
          ) : (
            <Button
              className={classes.actionButton}
              fullWidth
              disabled={!successUploading}
              onClick={handleCloseModal}
              variant="contained"
              color="primary"
            >
              {t('common.confirm')}
            </Button>
          )}
        </>
      )}
    </ModalLayout>
  );
}

ModalCsvCustomerUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  vendorHasFtpIntegration: PropTypes.bool.isRequired,
};
