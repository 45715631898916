import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import ImagePlaceholder from 'assets/imagePlaceholder.png';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    height: 40,
    width: 40,
    borderRadius: 4,
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  noImage: {
    background: theme.palette.grey[50],
    color: '#DADADA',
  },
}));

export default function ProductImage({ image, productId, handleUploadImage }) {
  const classes = useStyles();

  const handleOpenImageUpload = () => {
    handleUploadImage({ id: productId, src: image, isOpen: true });
  };

  const handleImagePlaceholder = ({ target }) => {
    // eslint-disable-next-line no-param-reassign
    target.src = ImagePlaceholder;
  };

  return image ? (
    <Grid onClick={handleOpenImageUpload}>
      <img
        className={classes.imageContainer}
        src={image}
        alt="Product"
        onError={handleImagePlaceholder}
      />
    </Grid>
  ) : (
    <Grid
      container
      className={classNames(classes.imageContainer, classes.noImage)}
      alignItems="center"
      justifyContent="center"
      onClick={handleOpenImageUpload}
    >
      <ImageOutlinedIcon fontSize="small" />
    </Grid>
  );
}

ProductImage.propTypes = {
  image: PropTypes.string,
  productId: PropTypes.string,
  handleUploadImage: PropTypes.func.isRequired,
};

ProductImage.defaultProps = {
  image: null,
  productId: null,
};
