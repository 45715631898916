import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import DateIcon from '@material-ui/icons/Event';

import { DATE_STATUS } from 'helpers/constants';

const useStyles = makeStyles((theme) => ({
  datePickerInput: {
    width: 110,
    fontSize: theme.typography.fontSize,
    cursor: 'pointer',
    borderRadius: 4,
    background: theme.palette.background.paper
  },
  input: {
    height: 24,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    padding: theme.spacing(0.75),
    cursor: 'pointer',
  },
  inputNoPadding: {
    padding: 0
  },
  endAdornment: {
    margin: 0,
    paddingRight: theme.spacing(0.5)
  },
  hasDate: {
    color: theme.palette.text.primary
  },
  noDate: {
    color: theme.palette.text.secondary
  },
  outOfDate: {
    color: '#E3BA26'
  },
  active: {
    color: '#5AC17C'
  },
  upcoming: {
    color: '#5A96C1'
  }
}));

export default function DateInput({
  date,
  handleChangeDate,
  isEditMode,
  minDate,
  dateStatus,
  dateType,
  noPadding,
  format,
  disabled,
}) {
  const classes = useStyles();

  const getDateStatusStyles = () => {
    switch (dateStatus) {
      case DATE_STATUS.INACTIVE:
        return date ? classes.hasDate : classes.noDate;
      case DATE_STATUS.ACTIVE:
        return classes.active;
      case DATE_STATUS.OUT_OF_DATE:
        return classes.outOfDate;
      case DATE_STATUS.UPCOMING:
        return classes.upcoming;
      default:
        return date ? classes.hasDate : classes.noDate;
    }
  };

  return (
    <Grid container alignItems="center" wrap="nowrap">
      {isEditMode ?
        <Grid item>
          <DatePicker
            variant="inline"
            value={date ? moment(date).utc() : null}
            onChange={(value) => handleChangeDate(value, dateType)}
            autoOk
            disabled={disabled}
            format="DD-MM-YYYY"
            minDate={minDate ? moment(minDate) : moment().subtract(1, 'y')}
            maxDate={moment().add(2, 'y')}
            minDateMessage={null}
            fullWidth
            InputProps={{
              classes: {
                root: classes.datePickerInput,
                input: [classes.input, noPadding && classes.inputNoPadding].join(' '),
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment className={classes.endAdornment} position="end">
                  <DateIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        :
        <Typography className={getDateStatusStyles()} variant="body2">
          { date ? moment(date).utc().format(format) : '00/00/00'}
        </Typography>}
    </Grid>
  );
}

DateInput.propTypes = {
  date: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  dateStatus: PropTypes.string.isRequired,
  dateType: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
  format: PropTypes.string,
  disabled: PropTypes.bool,
};

DateInput.defaultProps = {
  date: '',
  minDate: null,
  noPadding: false,
  format: 'DD/MM/YYYY',
  disabled: false,
};
