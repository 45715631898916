import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  tableBodyRow: {
    height: 56,
  },
  evenTableRow: {
    background: palette.background.default,
  },
  tableCell: {
    padding: spacing(1, 1.5),
    maxWidth: 150,
    color: palette.secondary.textBlack,
    borderBottom: 'none',
    [breakpoints.up('lg')]: {
      padding: spacing(1),
    },
  },
  firstColumn: {
    paddingLeft: spacing(4),
  },
  formLabel: {
    width: 100
  }
}));

function ManageRecipientsTableMultiContactRow({
  contact,
  isEvenTableRow,
  isEditLoading,
  handleChangeContactStatus,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow
      className={classNames(
        classes.tableBodyRow,
        isEvenTableRow && classes.evenTableRow
      )}
    >
      <TableCell
        className={classNames(classes.tableCell, classes.firstColumn)}
        colSpan={2}
      />
      <TableCell className={classes.tableCell}>
        {contact.name ? (
          <Typography variant="body2">{contact.name}</Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {contact.phone ? (
          <Typography variant="body2">{contact.phone}</Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {contact.status ? (
          <Typography variant="body2">{contact.status}</Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {isEditLoading ? (
          <CircularProgress size={20} />
        ) : (
          <FormControlLabel
            classes={{ label: classes.formLabel }}
            label={contact.disabled ? t('common.inactive') : t('common.active')}
            control={
              <Switch
                checked={!contact.disabled}
                onChange={handleChangeContactStatus(contact.email)}
                color="primary"
              />
            }
          />
        )}
      </TableCell>
      <TableCell className={classes.tableCell} />
    </TableRow>
  );
}

ManageRecipientsTableMultiContactRow.propTypes = {
  contact: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    additionalPhones: PropTypes.array,
    email: PropTypes.string,
    status: PropTypes.string,
    disabled: PropTypes.bool,
    userMeta: PropTypes.object,
  }).isRequired,
  isEvenTableRow: PropTypes.bool.isRequired,
  isEditLoading: PropTypes.bool.isRequired,
  handleChangeContactStatus: PropTypes.func.isRequired,
};

export default React.memo(
  ManageRecipientsTableMultiContactRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.contact, nextProps.contact) &&
    prevProps.isEditLoading === nextProps.isEditLoading
);
