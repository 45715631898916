import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';

import NewsletterInformationEditForm from 'components/Settings/NewsletterInformationEditForm';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  mainInfoNewsletterContainer: {
    width: 518,
    padding: theme.spacing(5, 4, 6),
    margin: theme.spacing(0, 2.5, 3),
    boxSizing: 'border-box',
    // minHeight: 750,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.palette.customShadows.popover
  },
  editButton: {
    fontSize: theme.typography.htmlFontSize
  },
  infoSectionContainer: {
    padding: theme.spacing(2.5, 0)
  },
  rowTitle: {
    marginBottom: theme.spacing(1)
  },
  dialogPaper: {
    width: 1025,
    maxWidth: 'none',
    padding: theme.spacing(0, 2, 6)
  },
  dialogBackdrop: {
    background: 'rgb(0 0 0 / 0.1)'
  },
  dialogTitle: {
    margin: theme.spacing(0, 3, 7),
    padding: theme.spacing(2, 0, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
}));

export default function NewsletterInformation({
  contactData,
  newsletterData,
  handleUpdateVendor,
  isAdmin,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [editMode, setEditMode] = React.useState(false);
  const [state, setState] = React.useState({
    fromName: '',
    fromEmail: '',
    enabled: false,
    staticInfo: null
  });

  const setInitialMainData = React.useCallback(() => {
    const {
      fromName,
      fromEmail,
      enabled,
      staticInfo = {},
      // eslint-disable-next-line no-unused-vars
      __typename,
    } = newsletterData;

    const staticInfoCopy = { ...(staticInfo || {}) };

    delete staticInfoCopy?.__typename;

    setState({
      fromName: fromName || contactData.businessName,
      fromEmail: fromEmail?.replace(/@.*/g, ''),
      enabled,
      staticInfo: {
        ...staticInfoCopy
      }
    });
  }, [contactData, newsletterData]);

  React.useEffect(() => {
    setInitialMainData();
  }, [setInitialMainData]);

  return (
    <Grid container item>
      <Paper elevation={1} className={classes.mainInfoNewsletterContainer}>
        <Grid container justifyContent="space-between">
          <Typography variant="h6">
            {t('settings.newsletter email')}
          </Typography>
          <Button
            className={classes.editButton}
            variant="text"
            color="primary"
            endIcon={<EditIcon />}
            onClick={() => setEditMode(true)}
          >
            {t('common.edit')}
          </Button>
        </Grid>
        <Grid
          className={classes.infoSectionContainer}
          container
          direction="column"
        >
          <Typography color="textSecondary" variant="subtitle2" className={classes.rowTitle}>
            {t('settings.email sender')}
          </Typography>
          <Typography variant="body1">
            {state.fromName || 'N/A'}
          </Typography>
        </Grid>
        <Divider />
        <Grid
          className={classes.infoSectionContainer}
          container
          direction="column"
        >
          <Typography color="textSecondary" variant="subtitle2" className={classes.rowTitle}>
            {t('settings.from email')}
          </Typography>
          <Typography variant="body1">
            {state.fromEmail || 'N/A'}
          </Typography>
        </Grid>
      </Paper>
      <Dialog
        open={editMode}
        TransitionComponent={Transition}
        onClose={() => setEditMode(false)}
        classes={{
          paper: classes.dialogPaper
        }}
        BackdropProps={{
          classes: {
            root: classes.dialogBackdrop
          }
        }}
      >
        <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
          <Typography variant="h6">
            {t('settings.business details')}
          </Typography>
          <IconButton size="small" color="inherit" onClick={() => setEditMode(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container wrap="nowrap">
            <NewsletterInformationEditForm
              newsletterData={state}
              handleUpdateVendor={handleUpdateVendor}
              setEditMode={setEditMode}
              isAdmin={isAdmin}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

NewsletterInformation.propTypes = {
  contactData: PropTypes.object,
  newsletterData: PropTypes.object,
  handleUpdateVendor: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

NewsletterInformation.defaultProps = {
  contactData: {},
  newsletterData: {},
};
