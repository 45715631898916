import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { UPDATE_NEWSLETTER_RECIPIENTS } from 'graphql/mutation/newsletters';
import { useMutation } from '@apollo/client';
import { GET_NEWSLETTER_RECIPIENTS } from 'graphql/query/newsletters';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import PeopleAltIcon from '@material-ui/icons/PeopleAltRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import replaceDelimiters from 'helpers/replaceDelimiters';
import GroupSelector from 'components/shared/Inputs/GroupSelector/GroupSelector';
import StyledInput from 'components/shared/Inputs/StyledInput';
import {
  UPDATE_NEWSLETTER_RECIPIENT_ACTION,
  NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS,
} from 'components/Newsletter/localConstantsAndHelpers';
import { parseDataValidationErrors } from 'helpers/getErrorMessage';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(
  ({ spacing, palette, typography, breakpoints }) => ({
    assignmentActionsContainer: {
      marginBottom: spacing(2),
      [breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        gap: 16,
      },
    },
    orDelimiter: {
      margin: spacing(0, 1.5),
      fontWeight: typography.fontWeightBold,
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      borderRadius: 40,
      height: 40,
      minWidth: 150,
      width: '100%',
      marginRight: spacing(1.5),
      transition: 'all 0.25s ease-in',
      color: palette.background.paper,
      background: palette.text.primary,
      '&:hover': {
        color: palette.background.paper,
        background: palette.text.primary,
      },
      [breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    groupSelectorAutoComplete: {
      background: palette.background.paper,
      width: '100%',
      border: palette.border.lightGreyThinDark,
      borderRadius: 8,
      marginRight: spacing(2),
      [breakpoints.down('sm')]: {
        marginRight: spacing(0),
      },
    },
    assignmentActionsInputRootClass: {
      display: 'flex',
      alignItems: 'center',
      height: 42,
      padding: `${spacing(0.25, 1)} !important`,
    },
  })
);

function RecipientAssignmentSection({
  state,
  recipientAssignmentStatus,
  newsletterId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const mixPanel = useMixPanel();

  const [inputValue, setInputValue] = React.useState('');
  const [selectedCustomerGroups, setSelectedCustomerGroups] = React.useState(
    []
  );

  const [updateNewsletterRecipients] = useMutation(
    UPDATE_NEWSLETTER_RECIPIENTS
  );
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const BUTTON_ACTIONS =
    UPDATE_NEWSLETTER_RECIPIENT_ACTION[state.recipientAssignmentStatus];

  const handleChangeInput = event => {
    setInputValue(event.target.value);
  };

  const handleSelectedChangeGroups = groupsArray => {
    setSelectedCustomerGroups(groupsArray);
  };

  const updateRecipientsAssignment = action => async () => {
    const isValidInput = !/[~`!#$%\\^&+=\\[\]\\';/{}|\\":<>\\?]/g.test(
      inputValue
    );
    if (!isValidInput) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.errors.special chars'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    const vendorClientIds = replaceDelimiters(inputValue);
    if (!vendorClientIds?.length && !selectedCustomerGroups?.length) {
      return;
    }

    const recipients = {
      groupIds: selectedCustomerGroups.map(group => group._id),
      connectionIds: [],
      vendorClientIds,
      emails: [],
    };

    try {
      await updateNewsletterRecipients({
        variables: {
          id: newsletterId,
          recipients,
          action,
        },
        refetchQueries: [
          {
            query: GET_NEWSLETTER_RECIPIENTS,
            variables: {
              id: newsletterId,
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
              recipientAssignmentStatus,
              filter: state.filter,
              search: state.search,
            },
          },
        ],
        update: (_, response) => {
          const statistic =
            response?.data?.updateNewsletterRecipients?.statistics;
          const failedCustomers = statistic?.failed?.customers || [];
          // const failedGroupsIds = statistic?.failed?.groups || [];
          // const failedGroups = [];

          if (failedCustomers?.length > 0) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t(
                  `newsletter.notifications.${
                    action.includes('add')
                      ? 'error add customers'
                      : 'error remove customers'
                  }`,
                  {
                    customers: failedCustomers.join(', '),
                    count: failedCustomers.length,
                  }
                ),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('newsletter.notifications.recipients updated'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }

          mixPanel.track('Newsletter - Recipients -> Update Recipients', {
            id: newsletterId,
            newsletter: state,
            recipients,
            action,
            recipientAssignmentStatus,
            statistic,
            failedCustomers,
          });
        },
      });
      setInputValue('');
      setSelectedCustomerGroups([]);
    } catch (err) {
      const message = parseDataValidationErrors(err.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  return (
    <Grid
      className={classes.assignmentActionsContainer}
      container
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid container>
        <StyledInput
          variant="outlined"
          value={inputValue}
          onChange={handleChangeInput}
          placeholder={t('newsletter.recipient assignment placeholder')}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <PeopleAltIcon />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid container alignItems="center" wrap="nowrap">
        <Typography
          component={Grid}
          className={classes.orDelimiter}
          variant="body1"
        >
          {t('lists.or')}
        </Typography>
        <GroupSelector
          handleChange={handleSelectedChangeGroups}
          selectedGroups={selectedCustomerGroups}
          autocompleteClass={classes.groupSelectorAutoComplete}
          inputRootClass={classes.assignmentActionsInputRootClass}
          inputPlaceholder={t('common.select group')}
          withInputAutoHeight
          showSelectedFirst={false}
          withAssignToAll={
            state.recipientAssignmentStatus ===
            NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED
          }
          allowDeleteChip={false}
        />
      </Grid>

      <Grid container wrap="nowrap">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={updateRecipientsAssignment(BUTTON_ACTIONS.ADD)}
          startIcon={<AddCircleOutlineRoundedIcon />}
        >
          {t('newsletter.add')}
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={updateRecipientsAssignment(BUTTON_ACTIONS.DELETE)}
          startIcon={<DeleteOutlineRoundedIcon />}
        >
          {t('newsletter.delete')}
        </Button>
      </Grid>
    </Grid>
  );
}

RecipientAssignmentSection.propTypes = {
  state: PropTypes.object.isRequired,
  recipientAssignmentStatus: PropTypes.string.isRequired,
  newsletterId: PropTypes.string.isRequired,
};

export default memo(RecipientAssignmentSection, (prevProps, nextProps) =>
  isEqual(
    prevProps.recipientAssignmentStatus,
    nextProps.recipientAssignmentStatus
  )
);
