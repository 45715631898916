/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { GET_VENDOR_PROFILE } from 'graphql/query/user';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TabPanel from 'components/shared/TabPanel';
import ExpiringDealsTable from 'components/Deals/ExpiringDealsTable';
import RegularDealsTable from 'components/Deals/RegularDealsTable';
import LockIcon from '@material-ui/icons/Lock';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles(
  ({ spacing, breakpoints, palette, typography }) => ({
    childContainer: {
      [breakpoints.up('xl')]: {
        maxWidth: 1620,
      },
    },
    addDealButton: {
      border: palette.border.lightGrey,
      height: 45,
      color: palette.secondary.darkGray,
      fontWeight: typography.fontWeightRegular,
    },
    columnTitle: {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
    dealTitle: {
      width: 250,
      textAlign: 'left',
    },
    unitsInPackaging: {
      width: 145,
    },
    price: {
      width: 125,
    },
    tabs: {
      width: '100%',
    },
    tabPanel: {
      display: 'block',
      width: '100%',
      padding: 0,
    },
    tabInnerContainer: {
      padding: spacing(2),
    },
  })
);

export default function Deals() {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = React.useState(0);
  const { t } = useTranslation();

  const { data: vendorData } = useQuery(
    GET_VENDOR_PROFILE,
    { fetchPolicy: 'cache-and-network' }
  );

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isMhdAvailable = !!vendorData?.vendorAccount?.preferences
    ?.expiringProducts?.enabled;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      className={classes.childContainer}
    >
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        className={classes.tabs}
      >
        <Tab label={t('deals.Regular')} />
        <Tab
          disabled={isMhdAvailable === false}
          label={
            <p>
              {isMhdAvailable ? (
                ''
              ) : (
                <LockIcon style={{ position: 'relative', top: '5px' }} />
              )}
              {t('deals.Expiring')}
            </p>
          }
        />
      </Tabs>
      <TabPanel value={currentTab} index={0} className={classes.tabPanel}>
        <Grid className={classes.tabInnerContainer}>
          <RegularDealsTable classes={classes} />
        </Grid>
      </TabPanel>
      <TabPanel value={currentTab} index={1} className={classes.tabPanel}>
        <Grid className={classes.tabInnerContainer}>
          <ExpiringDealsTable classes={classes} dealType="expiring" />
        </Grid>
      </TabPanel>
    </Grid>
  );
}
