import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: theme.palette.secondary.darkGray,
    border: '1px solid #C7C9CC',
    borderRadius: '50%',
    fontWeight: theme.typography.fontWeightRegular,
    width: 32,
    height: 32,
    padding: 0,
    margin: theme.spacing(0, 0.5),
    '& svg': {
      fontSize: 16,
    }
  },
}));

function ActionButton({ children, onClick }) {
  const classes = useStyles();

  return (
    <IconButton
      onClick={onClick}
      size="small"
      className={classes.actionButton}
    >
      {children}
    </IconButton>
  );
}

ActionButton.propTypes = {
  onClick: PropTypes.func,
};

ActionButton.defaultProps = {
  onClick: null,
};

export default ActionButton;
