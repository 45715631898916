import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { Formik } from 'formik';
import { v1 as uuid } from 'uuid';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { GET_PRODUCTS } from 'graphql/query/products';
import { UPDATE_PRODUCT, CREATE_PRODUCT } from 'graphql/mutation/products';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import CancelIcon from '@material-ui/icons/CancelOutlined';
import ConfirmIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { PRODUCT_DATA, NOTIFICATION_STATUS } from 'helpers/constants';
import EditConfirmationModal from 'components/shared/EditConfirmationModal';
import DeleteProductModal from 'components/shared/DeleteModal';
import ProductTableRowCells from 'components/ProductsTable/ProductTableRowCells';
import ValidationSchema from 'components/ProductsTable/ValidationSchema';
import ProductVariants from 'components/ProductsTable/ProductTableRowVariants';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 60,
    borderBottom: theme.palette.border.lightGrey,
  },
  tableWithOpenedPanel: {
    background: theme.palette.background.container,
    borderBottom: 'none',
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    maxWidth: 150,
    color: theme.palette.secondary.textBlack,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  tableCellPanelOpen: {
    backgroundColor: theme.palette.background.container,
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.button,
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.darkGray,
  },
  addVariantButton: {
    margin: 4,
  },
  confirmButton: {
    color: theme.palette.success.main,
    margin: 4,
  },
  cancelButton: {
    margin: 4,
    color: theme.palette.error.main,
  },
  packageLabel: {
    height: 24,
    width: 56,
    borderRadius: 30,
  },
  hasLabel: {
    background: 'rgba(255, 81, 0, 0.15)',
  },
  emptyLabel: {
    background: theme.palette.error.main,
  },
  packagingUnitLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 40,
  },
  expandMoreCell: {
    width: 30,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const PRODUCT_VARIANT = () => ({
  _id: uuid(),
  baseUnit: '',
  packaging: '',
  price: null,
  unitsInPackaging: null,
});

function ProductTableRow({
  handleUploadImage,
  rowData,
  isEditMode,
  setEditMode,
  setConfirmEditChanges,
  editConfirmationModalIsActive,
  handleDeleteRow,
  nonEditablePrice,
  withCreateProduct,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [productData, setProductData] = React.useState({ ...PRODUCT_DATA() });
  const [isPanelOpen, setPanelOpen] = React.useState(false);
  const [isDeleteRowModalOpen, setDeleteRowModal] = React.useState(false);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [updateProductMutation] = useMutation(UPDATE_PRODUCT);
  const [createProductMutation] = useMutation(CREATE_PRODUCT, {
    refetchQueries: [{ query: GET_PRODUCTS }],
    fetchPolicy: 'network-only'
  });

  const setInitialProductData = React.useCallback(() => {
    const {
      _id,
      name,
      vendorSpecificId,
      image,
      description,
      categories,
      variants,
    } = rowData;

    setProductData({
      _id,
      name,
      vendorSpecificId,
      image,
      description: description || '',
      categories: categories?.join(', ') || '',
      variants: variants.map(({ __typename, ...variant }) => variant),
    });
  }, [rowData]);

  React.useEffect(() => {
    setInitialProductData();
  }, [setInitialProductData]);

  const handleCloseEditModal = () => {
    setInitialProductData();
    setConfirmEditChanges(false);
    setEditMode(null);
    setPanelOpen(false);
  };

  const handleCancelEditing = values => {
    if (!isEqual(productData, values)) {
      setConfirmEditChanges(true);
    } else {
      handleCloseEditModal();
    }
  };

  const handleUpdateProduct = async values => {
    try {
      await updateProductMutation({
        variables: {
          product: {
            ...values,
            categories: values?.categories
              .split(',')
              .map(category => category?.trim())
              .filter(Boolean),
            // NOTE: replace all "" values by null
            variants: values.variants.map(variant => {
              // eslint-disable-next-line no-param-reassign
              delete variant.attributes;

              return Object.entries(variant).reduce((init, entry) => {
                const [key, value] = entry;
                return {
                  ...init,
                  [key]: value === '' ? null : value,
                };
              }, {});
            }),
          },
        },
        update: (store, { data: { updateProduct } }) => {
          const allProducts = store.readQuery({ query: GET_PRODUCTS });
          store.writeQuery({
            query: GET_PRODUCTS,
            data: {
              vendorProducts: {
                ...allProducts.vendorProducts,
                products: allProducts.vendorProducts.products.map(product =>
                  (product._id === updateProduct._id ? updateProduct : product)
                ),
              },
            },
          });

          setNotification({
            variables: {
              timeout: 4000,
              message: t('products.update product success', {
                productName: updateProduct.name,
              }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
      setPanelOpen(false);
      setEditMode(null);
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleCreateProduct = async ({ _id, ...values }) => {
    try {
      await createProductMutation({
        variables: {
          product: {
            ...values,
            // NOTE: replace all "" values by null
            variants: values.variants.map(variant =>
              Object.entries(variant).reduce((init, entry) => {
                const [key, value] = entry;
                return {
                  ...init,
                  [key]: value === '' ? null : value,
                };
              }, {})
            ),
          },
        },
        update: (store, { data: { createProduct } }) => {
          const allProducts = store.readQuery({ query: GET_PRODUCTS });
          store.writeQuery({
            query: GET_PRODUCTS,
            data: {
              ...allProducts.vendorProducts,
              products: [createProduct, ...allProducts.vendorProducts.products],
            },
          });
          setNotification({
            variables: {
              timeout: 4000,
              message: t('products.crete product success', {
                productName: createProduct.name,
              }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
      setPanelOpen(false);
      setEditMode(null);
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleSubmitProductData = values => {
    if (withCreateProduct) {
      handleCreateProduct({ ...values });
    } else if (!isEqual(productData, values)) {
      handleUpdateProduct({ ...values });
    } else {
      handleCloseEditModal();
    }
  };

  const parseFormErrors = errors => {
    const parseErrorMessage = () => {
      if (errors.name) {
        return t('products.edit errors.name');
      } else if (errors.vendorSpecificId) {
        return t('products.edit errors.number');
      } else if (
        errors.variants &&
        errors.variants.some(variant => variant && variant.packaging)
      ) {
        return t('products.edit errors.package');
      } else if (
        errors.variants &&
        errors.variants.some(
          variant => variant && (variant.price || variant.unitsInPackaging)
        )
      ) {
        return t('common.errors.negative value');
      }
      return t('common.empty field error');
    };

    setNotification({
      variables: {
        timeout: 4000,
        message: parseErrorMessage(),
        type: NOTIFICATION_STATUS.ALERT,
        isOpen: true,
      },
    });
  };

  const cellClasses = classNames(
    classes.tableCell,
    isPanelOpen && classes.tableCellPanelOpen
  );

  return (
    <Formik
      initialValues={{
        ...productData,
      }}
      enableReinitialize
      onSubmit={values => handleSubmitProductData(values)}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ValidationSchema}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        errors,
        validateForm,
        resetForm,
      }) => (
        <>
          <TableRow
            className={classNames(
              classes.tableBodyRow,
              isPanelOpen && classes.tableWithOpenedPanel
            )}
          >
            {values.variants?.length > 1 ? (
              <TableCell
                className={classNames(cellClasses, classes.expandMoreCell)}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setPanelOpen(!isPanelOpen)}
                >
                  {isPanelOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell className={cellClasses} />
            )}
            <ProductTableRowCells
              rowData={values}
              isEditMode={isEditMode}
              errors={errors}
              nonEditablePrice={nonEditablePrice}
              handleUploadImage={handleUploadImage}
              setFieldValue={setFieldValue}
            />
            <TableCell className={cellClasses}>
              <Grid container wrap="nowrap">
                {isEditMode ? (
                  <>
                    <IconButton
                      onClick={() => {
                        setFieldValue(
                          'variants',
                          values.variants.concat(PRODUCT_VARIANT())
                        );
                        setPanelOpen(true);
                      }}
                      className={classes.addVariantButton}
                      aria-label="add variant"
                      size="small"
                    >
                      <AddIcon color="primary" />
                    </IconButton>
                    <IconButton
                      onClick={debounce(async () => {
                        const validate = await validateForm();
                        if (!isEmpty(validate) && isEditMode) {
                          parseFormErrors(validate);
                        } else {
                          handleSubmit();
                        }
                      }, 300)}
                      className={classes.confirmButton}
                      aria-label="confirm"
                      size="small"
                    >
                      <ConfirmIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleCancelEditing(values)}
                      className={classes.cancelButton}
                      aria-label="cancel"
                      size="small"
                    >
                      <CancelIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      classes={{ root: classes.tableActionButton }}
                      onClick={() => setEditMode(values._id)}
                    >
                      <EditIcon className={classes.tableActionButtonIcon} />
                    </Button>
                    <Button
                      variant="contained"
                      classes={{ root: classes.tableActionButton }}
                      onClick={() => setDeleteRowModal(true)}
                    >
                      <DeleteIcon className={classes.tableActionButtonIcon} />
                    </Button>
                  </>
                )}
              </Grid>
            </TableCell>
          </TableRow>
          {isPanelOpen && values.variants?.length > 1 && (
            <ProductVariants
              variants={values.variants}
              setFieldValue={setFieldValue}
              isEditMode={isEditMode}
              isPanelOpen={isPanelOpen}
              nonEditablePrice={nonEditablePrice}
            />
          )}
          {isEditMode && (
            <EditConfirmationModal
              isOpen={editConfirmationModalIsActive}
              handleCloseModal={setConfirmEditChanges}
              modalContentMessage={t('common.edit modal message')}
              onConfirm={async () => {
                const validate = await validateForm();
                if (!isEmpty(validate) && isEditMode) {
                  parseFormErrors(validate);
                } else {
                  handleSubmit();
                }
              }}
              onCancel={() => {
                handleCloseEditModal();
                resetForm();
              }}
            />
          )}
          {isDeleteRowModalOpen && (
            <DeleteProductModal
              isOpen={isDeleteRowModalOpen}
              message={t('products.delete product message')}
              handleCloseModal={() => setDeleteRowModal(false)}
              handleDelete={() => {
                setDeleteRowModal(false);
                handleDeleteRow(rowData._id);
              }}
            />
          )}
        </>
      )}
    </Formik>
  );
}

ProductTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setConfirmEditChanges: PropTypes.func.isRequired,
  editConfirmationModalIsActive: PropTypes.bool.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  nonEditablePrice: PropTypes.bool.isRequired,
  withCreateProduct: PropTypes.bool,
};

ProductTableRow.defaultProps = {
  withCreateProduct: false,
};

export default React.memo(
  ProductTableRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.rowData, nextProps.rowData) &&
    prevProps.isEditMode === nextProps.isEditMode &&
    prevProps.editConfirmationModalIsActive ===
      nextProps.editConfirmationModalIsActive
);
