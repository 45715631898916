import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { RECIPIENTS_FILTER_OPTIONS } from 'components/Newsletter/localConstantsAndHelpers';
import StyledInput from 'components/shared/Inputs/StyledInput';

const useStyles = makeStyles(({ spacing, palette }) => ({
  recipientsFilterInput: {
    height: 42,
    width: 150,
    marginRight: spacing(2)
  },
  recipientsFilterSelect: {
    '&:focus': {
      background: palette.background.paper
    }
  },
  recipientFilterSelectPaper: {
    marginTop: spacing(6),
  }
}));

function RecipientsTableFilter({ activeFilter, handleChangeFilter, filters }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let filter = activeFilter;

  if (!filters?.includes(filter)) {
    filter = RECIPIENTS_FILTER_OPTIONS.NONE;
  }

  return (
    <Select
      labelId="recipient-filter-select-outlined-label"
      id="recipient-filter-select-outlined"
      value={filter}
      onChange={handleChangeFilter}
      placeholder={t('newsletter.filter by')}
      input={<StyledInput className={classes.recipientsFilterInput} />}
      renderValue={value => {
        if (value === RECIPIENTS_FILTER_OPTIONS.NONE) {
          return t('newsletter.filter by');
        } else {
          return t(`newsletter.filter options.${value}`);
        }
      }}
      MenuProps={{
        classes: {
          paper: classes.recipientFilterSelectPaper
        }
      }}
      classes={{
        select: classes.recipientsFilterSelect,
      }}
    >
      {filters.map((filterOption) => (
        <MenuItem key={filterOption} value={filterOption}>
          {t(`newsletter.filter options.${filterOption}`)}
        </MenuItem>
      ))}
    </Select>
  );
}

RecipientsTableFilter.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired
};

export default memo(RecipientsTableFilter, (prevProps, nextProps) =>
  prevProps.activeFilter === nextProps.activeFilter &&
  isEqual(prevProps.filters, nextProps.filters)
);
