import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  tableBodyRow: {
    height: 64,
    borderTop: palette.border.lightGrey,
  },
  evenTableRow: {
    background: palette.background.default,
  },
  tableCell: {
    padding: spacing(1, 1.5),
    maxWidth: 150,
    color: palette.secondary.textBlack,
    borderBottom: 'none',
    [breakpoints.up('lg')]: {
      padding: spacing(1),
    },
  },
  firstColumn: {
    paddingLeft: spacing(3.5),
  },
  messageColumn: {
    width: 300,
    whiteSpace: 'break-spaces',
    padding: spacing(0, 1.5)
  },
}));

function CampaignReportTableRow({ rowData, isEvenTableRow }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { to, sentAt, body, status } = rowData;

  return (
    <TableRow
      className={classNames(
        classes.tableBodyRow,
        isEvenTableRow && classes.evenTableRow
      )}
    >
      <TableCell
        className={classNames(classes.tableCell, classes.firstColumn)}
      >
        {to ? (
          <Typography variant="body2">{to}</Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      {/* <TableCell className={classes.tableCell}>
          {contactData.businessName ? (
            <Typography variant="body2">{contactData.businessName}</Typography>
          ) : (
            <EmptyTableField />
          )}
        </TableCell> */}
      <TableCell className={classes.tableCell}>
        {sentAt ? (
          <Typography variant="body2">
            {moment(sentAt).format('DD-MM-YYYY, HH:mm')}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {body ? (
          <Typography className={classes.messageColumn} variant="body2">
            {body}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {status ? (
          <Typography variant="body2">
            {t(`campaigns.report status.${status}`)}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
    </TableRow>
  );
}

CampaignReportTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  isEvenTableRow: PropTypes.bool.isRequired,
};

export default React.memo(CampaignReportTableRow, (prevProps, nextProps) =>
  isEqual(prevProps.rowData, nextProps.rowData)
);
