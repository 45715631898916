import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { useQuery, useMutation } from '@apollo/client';
import { GET_SINGLE_VENDOR_CAMPAIGN } from 'graphql/query/campaigns';
import {
  UPDATE_CAMPAIGN,
  SEND_TEST_SMS,
} from 'graphql/mutation/campaigns';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import {
  INIT_CAMPAIGN_DATA,
  DEFAULT_PHONE_OPTIONS,
  removeEmoji,
} from 'components/Campaigns/campaignConstants';
import useMixPanel from 'helpers/useMixPanel';
import EditConfirmationModal from 'components/shared/EditConfirmationModal';
import SingleCampaignForm from './SingleCampaignForm';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  singleCampaignContainer: {
    maxWidth: 'unset',
    width: '100%',
    padding: spacing(2),
    minHeight: 850,
    [breakpoints.up('xl')]: {
      maxWidth: 1620,
    },
  },
}));

// TODO: use localized emoji pack like https://github.com/missive/emoji-mart
export default function SingleCampaign() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const params = useParams();

  const { campaignId } = params;
  const isNewCampaign = campaignId === INIT_CAMPAIGN_DATA._id;

  const [isEmojiOpen, setEmojiPickerOpen] = React.useState(true);
  const [testPhoneNumber, setTestPhoneNumber] = React.useState({
    ...DEFAULT_PHONE_OPTIONS,
  });
  // const [isCustomPeriodModalOpen, setCustomPeriodModalOpen] = React.useState(
  //   false
  // );
  const [isCloseModalOpen, setCloseModalOpen] = React.useState(false);

  const [updateCampaignMutation] = useMutation(UPDATE_CAMPAIGN);
  const [sendTestMessageMutation] = useMutation(SEND_TEST_SMS);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const {
    loading: campaignLoading,
    error: campaignError,
    data: campaignResponse,
  } = useQuery(GET_SINGLE_VENDOR_CAMPAIGN, {
    skip: isNewCampaign,
    fetchPolicy: 'cache-and-network',
    variables: {
      campaignId,
    },
  });

  const campaignData = isNewCampaign
    ? { ...INIT_CAMPAIGN_DATA }
    : {
      ...campaignResponse?.campaign,
      schedule: {
        ...campaignResponse?.campaign?.schedule,
      }
    };

  useEffect(() => {
    if (campaignError) {
      setNotification({
        variables: {
          timeout: 4000,
          message:
            campaignError?.message ||
            campaignError ||
            t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignError]);

  const handleSubmitCampaign = async (campaign, newRoute) => {
    try {
      await updateCampaignMutation({
        variables: {
          campaign: {
            _id: isNewCampaign ? undefined : campaign._id,
            title: campaign.title,
            schedule: {
              repeat: campaign.schedule.repeat,
              endAfterCount: campaign.schedule.endAfterCount,
              startDate: campaign.schedule.startDate,
              endDate: campaign.schedule.endDate,
              time: {
                minute: campaign.schedule.time.minute,
                hour: campaign.schedule.time.hour,
              },
            },
            message: removeEmoji(campaign.message),
            sender: campaign.sender,
          },
        },
        update: (_, { data: { addOrUpdateCampaign } = {} }) => {
          if (isNewCampaign) {
            mixPanel.track('CAMPAIGN - CREATED', campaign);
            setNotification({
              variables: {
                timeout: 4000,
                message: t('campaigns.notifications.campaign created'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          } else {
            mixPanel.track('CAMPAIGN - UPDATED', campaign);
            setNotification({
              variables: {
                timeout: 4000,
                message: t('campaigns.notifications.campaign updated'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }

          if (newRoute) {
            history.push(newRoute);
          } else if (isNewCampaign) {
            history.replace(`/campaign/${addOrUpdateCampaign._id}`);
          }
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleSendTestMessage = async (formikValues) => {
    const { sender, message } = formikValues;
    const { phone, dialCode } = testPhoneNumber;
    const formattedMessage = removeEmoji(message);

    if (sender === '') {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('campaigns.notifications.test campaign sender empty'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    } else if (formattedMessage === '') {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('campaigns.notifications.test campaign message empty'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    } else if (phone.replace(`+${dialCode}`, '').trim() === '') {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('campaigns.notifications.test campaign phone empty'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    try {
      await sendTestMessageMutation({
        variables: {
          sender,
          message: formattedMessage,
          to: phone,
        },
      });
      mixPanel.track('CAMPAIGN - TEST SMS SEND', {
        sender,
        message: formattedMessage,
        to: phone,
        vendorId: campaignData.vendorId,
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('campaigns.notifications.test campaign sent successfully'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleValidateBeforeSubmit = async (values, formik) => {
    const validate = await formik.validateForm();
    if (!isEmpty(validate) || isEqual(values, campaignData)) return;
    handleSubmitCampaign(values);
  };

  const handleOpenManageRecipients = async (values, validateFormCB) => {
    const formErrors = await validateFormCB();
    const hasErrors = Object.keys(formErrors).length > 0;

    if (hasErrors) return;

    const route = `${history.location.pathname}/edit-contacts`;

    if (isEqual(values, campaignData)) {
      history.push(route);
    } else {
      handleSubmitCampaign(values, route);
    }
  };

  const handleCloseEditCampaign = (formikValues) => {
    if (isEqual(formikValues, campaignData)) {
      history.goBack();
    } else {
      setCloseModalOpen(true);
    }
  };

  return (
    <Paper elevation={1} className={classes.singleCampaignContainer}>
      {campaignLoading ? (
        <Grid container alignItems="center" justifyContent="center">
          <CircularProgress size={50} color="primary" />
        </Grid>
      ) : (
        <SingleCampaignForm
          isNewCampaign={isNewCampaign}
          campaignState={campaignData}
          isEmojiOpen={isEmojiOpen}
          testPhoneNumber={testPhoneNumber}
          // isCustomPeriodModalOpen={isCustomPeriodModalOpen}
          handleValidateBeforeSubmit={handleValidateBeforeSubmit}
          handleCloseEditCampaign={handleCloseEditCampaign}
          // setCustomPeriodModalOpen={setCustomPeriodModalOpen}
          setEmojiPickerOpen={setEmojiPickerOpen}
          setTestPhoneNumber={setTestPhoneNumber}
          handleSendTestMessage={handleSendTestMessage}
          handleOpenManageRecipients={handleOpenManageRecipients}
        />
      )}
      <EditConfirmationModal
        isOpen={isCloseModalOpen}
        handleCloseModal={setCloseModalOpen}
        modalTitleMessage={t('common.router message')}
        modalContentMessage=""
        modalConfirmMessage={t('common.confirm')}
        modalDiscardMessage={t('common.abort')}
        onConfirm={history.goBack}
        onCancel={() => setCloseModalOpen(false)}
      />
    </Paper>
  );
}
