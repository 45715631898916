import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Transition from 'components/shared/Transition';
import { DatePicker } from 'components/shared/DatePicker';

const useStyles = makeStyles(({ spacing }) => ({
  dateRangeModalContent: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      padding: spacing(0, 2)
    }
  },
}));

function DatePickerModal({
  isOpen,
  onClose,
  startDate,
  endDate,
  handleChange,
  handleClearDateRange
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      hideBackdrop
      aria-labelledby="newsletter-date-modal"
      aria-describedby="newsletter-date-modal-description"
      onClose={onClose}
      maxWidth="lg"
    >
      <DialogContent className={classes.dateRangeModalContent}>
        <Box>
          <Typography variant="h6">{t('specialDays.startDate')}</Typography>
          <DatePicker
            selectedDate={startDate}
            handleDateChange={(date) => {
              handleChange('promoStartDate')(date.toISOString());
            }}
            desktopView
            variant="inline"
            maxDate={moment().add(6, 'M').endOf('month')}
            setIsOpen={() => {}}
          />
        </Box>
        <Box>
          <Typography variant="h6">{t('specialDays.endDate')}</Typography>
          <DatePicker
            selectedDate={endDate}
            handleDateChange={(date) => {
              handleChange('promoEndDate')(date.toISOString());
            }}
            desktopView
            variant="inline"
            minDate={moment()}
            maxDate={moment().add(6, 'M').endOf('month')}
            setIsOpen={() => {}}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClearDateRange}
          color="secondary"
        >
          {t('common.clear')}
        </Button>
        <Button
          onClick={onClose}
          color="primary"
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DatePickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleClearDateRange: PropTypes.func.isRequired,
};

DatePickerModal.defaultProps = {
  startDate: undefined,
  endDate: undefined,
};

export default DatePickerModal;
