import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  locationCard: (props) => ({
    marginBottom: theme.spacing(),
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    // eslint-disable-next-line max-len
    backgroundImage: `linear-gradient(0deg, rgba(8, 7, 7, 0.5) 0%, rgba(0, 0, 0, 0) 100%), url('${props.image}')`,
    height: 110,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${theme.palette.primary.main}`
    },
  }),
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  tabsRoot: {
    height: 'auto',
    minHeight: 'auto',
    marginLeft: theme.spacing(0.75)
  },
  chip: {
    backgroundColor: '#e0e0e0',
    padding: theme.spacing(),
    borderRadius: 16,
    margin: 3,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    minWidth: 'fit-content',
    opacity: 1,
    minHeight: 'fit-content',
    height: 32,
    ...theme.typography.subtitle2
  },
  emptyChip: {
    color: '#9b9b9b',
  },
  textContainer: {
    width: '100%',
    height: '48px',
    paddingLeft: 16,
    background: 'linear-gradient(0deg, rgba(8, 7, 7, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    alignItems: 'center',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8
  }
}));

const CategoryCard = ({ category, selectCategory }) => {
  const { t } = useTranslation();

  const correctImage = () => {
    if (category?.image) {
      return category?.image;
    } else if (category._id.split('-')[1] === 'allProducts') {
      return 'https://storage.googleapis.com/chfslst-static-files/allProducts.jpg';
    } else if (category._id.split('-')[1] === 'deals') {
      return 'https://storage.googleapis.com/chfslst-static-files/deals.jpg';
    }
    return null;
  };

  const classes = useStyles({ image: correctImage() });

  if (!category) return null;

  return (
    <Fade
      in
      mountOnEnter
      unmountOnExit
      timeout={500}
      key={category._id}
    >
      <Grid
        key={category._id}
        container
        className={classes.locationCard}
        onClick={() => selectCategory(category._id)}
        alignItems="flex-end"
        wrap="nowrap"
      >
        <Grid className={classes.textContainer}>

          <Typography className={classes.title}>
            {category?.icon} {category?.name}
          </Typography>
          <Tabs
            value={false}
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot
            }}
          >
            {category?.mappedCategories?.length ? (
              category?.mappedCategories?.map(subtitle => (
                <Tab key={subtitle} label={subtitle} className={classes.chip} />
              ))
            ) : (
              <Tab
                key="empty"
                label={t('categories.no super categories assigned')}
                className={classNames(
                  classes.emptyChip,
                  classes.chip
                )}
              />
            ) }
          </Tabs>
        </Grid>
      </Grid>
    </Fade>

  );
};

CategoryCard.propTypes = {
  category: PropTypes.object.isRequired,
  selectCategory: PropTypes.func.isRequired,
};

export default CategoryCard;
