import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { CREATE_NEWSLETTER } from 'graphql/mutation/newsletters';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import publishedImage from 'assets/published.png';

import moment from 'moment';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
import NewsletterModalLayout from './NewsletterModalLayout';

const useStyles = makeStyles(({ spacing }) => ({
  newsletterPublishedModalContentContainer: {
    '& *': {
      fontWeight: '700 !important',
    },
  },
  publishedImage: {
    width: 48,
    maxHeight: 60,
  },
  newsletterPublishedModalButtonAction: {
    borderRadius: 40,
    height: 48,
    boxShadow: 'none',
    margin: spacing(3, 0.5, 0),
  },
}));

export default function NewsletterEmailPublishedModal({ isOpen, handleClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const mixPanel = useMixPanel();

  const [createNewsletterMutation] = useMutation(CREATE_NEWSLETTER);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const handleCreateAnotherEmail = async () => {
    handleClose();

    try {
      const createdNewsletterResponse = await createNewsletterMutation({
        variables: {
          template: {
            title: 'Newsletter',
            subject: `Newsletter ${moment().format('DD-MM-YYYY HH:mm')}`,
          },
        },
      });
      mixPanel.track('Newsletter - Create new', {
        newsletterId: createdNewsletterResponse?.data?.createNewsletter?._id,
        newsletter: createdNewsletterResponse?.data,
      });
      history.push(
        `/newsletter/${createdNewsletterResponse?.data?.createNewsletter?._id}`
      );
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleEndWithEmails = () => {
    handleClose();
    history.push('/newsletters');
  };

  return (
    <NewsletterModalLayout isOpen={isOpen} handleClose={handleClose}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.newsletterPublishedModalContentContainer}
      >
        <img
          className={classes.publishedImage}
          src={publishedImage}
          alt="email successfully published"
        />

        <Typography variant="h4" gutterBottom>
          {t('newsletter.published')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('newsletter.email is live')}
        </Typography>

        <Grid container alignItems="center" justifyContent="space-around">
          <Button
            className={classes.newsletterPublishedModalButtonAction}
            color="primary"
            variant="outlined"
            onClick={handleCreateAnotherEmail}
          >
            {t('newsletter.create more')}
          </Button>

          <Button
            className={classes.newsletterPublishedModalButtonAction}
            color="primary"
            variant="contained"
            onClick={handleEndWithEmails}
          >
            {t('newsletter.end email creation')}
          </Button>
        </Grid>
      </Grid>
    </NewsletterModalLayout>
  );
}

NewsletterEmailPublishedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
