import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddCircleOutline';
import DropDowIcon from '@material-ui/icons/ArrowDropDown';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(theme => ({
  hasLists: {
    color: theme.palette.success.main
  },
  selectorContainer: {
    width: 300,
    maxHeight: 500,
    overflowY: 'auto',
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`
  },
  selectorTitle: {
    color: theme.palette.secondary.darkGray,
    fontStyle: 'italic',
    padding: `${theme.spacing(1.25)}px 0px`
  },
  listLink: {
    textDecoration: 'none'
  },
  listButton: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(1.25)}px 0px`
  },
  popoverPaper: {
    boxShadow: theme.palette.customShadows.popover
  },
  addListButton: {
    height: 45,
    color: theme.palette.secondary.darkGray,
    border: theme.palette.border.lightGrey,
    fontWeight: theme.typography.fontWeightRegular,
  }
}));

function CustomerLists({
  lists,
  customerId,
  handleOpenNewListEditor,
  isEditMode,
  handleCreateNewList,
  isNewCustomer,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCreateListAction = () => {
    if (isEditMode) {
      handleOpenNewListEditor();
    } else {
      handleCreateNewList();
    }
  };

  const handleMoveToList = (listId) => () => {
    history.replace({
      pathname: '/customers',
      search:
          queryString.stringify({ ...queryString.parse(history.location.search), id: customerId })
    });
    history.push(`list/${listId}`);
  };

  const handleOpenSelector = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const selectorIsOpen = Boolean(anchorEl);

  return (
    <Grid container wrap="nowrap" alignItems="center">
      {lists && lists.length <= 0 ? (
        <EmptyTableField />
      ) : (
        <Typography className={classes.hasLists}>{lists.length}</Typography>
      )}
      <Grid item>
        <IconButton size="small" onClick={handleOpenSelector}>
          <DropDowIcon />
        </IconButton>
      </Grid>
      <Popover
        classes={{ paper: classes.popoverPaper }}
        open={selectorIsOpen}
        anchorEl={anchorEl}
        onClose={handleCloseSelector}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.selectorContainer}
        >
          <Typography className={classes.selectorTitle}>
            {t('customers.lists')}
          </Typography>
          {lists.map(list => (
            <Button
              key={list._id}
              onClick={handleMoveToList(list._id)}
              className={classes.listButton}
              variant="text"
              fullWidth
            >
              {list.title === 'New List' ?
                t('lists.new list title') : list.title}
            </Button>
          ))}
          <Tooltip
            title={isNewCustomer ? t('customers.disable new list creation tooltip') : ''}
            placement="right-start"
          >
            <span>
              <Button
                key="add list"
                onClick={handleCreateListAction}
                className={classes.addListButton}
                disabled={isNewCustomer}
                fullWidth
                variant="text"
                color="default"
                startIcon={<AddIcon color={isNewCustomer ? 'disabled' : 'primary'} />}
              >
                {t('lists.add list')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Popover>
    </Grid>
  );
}

CustomerLists.propTypes = {
  lists: PropTypes.array,
  customerId: PropTypes.string.isRequired,
  handleOpenNewListEditor: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isNewCustomer: PropTypes.bool.isRequired,
  handleCreateNewList: PropTypes.func.isRequired,
};

CustomerLists.defaultProps = {
  lists: null,
};

export default React.memo(CustomerLists, (prevProps, nextProps) =>
  isEqual(prevProps.lists, nextProps.lists) &&
  prevProps.vendorClientId === nextProps.vendorClientId &&
  prevProps.isEditMode === nextProps.isEditMode &&
  prevProps.isNewCustomer === nextProps.isNewCustomer
);
