/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_CAMPAIGN_RECIPIENTS,
  GET_SINGLE_VENDOR_CAMPAIGN,
} from 'graphql/query/campaigns';

import { makeStyles } from '@material-ui/styles';

import TableLayout from 'components/shared/TableLayout';
import { ROWS_PER_PAGE_OPTIONS, TABLE_SKELETON_TYPES } from 'helpers/constants';
import ManageRecipientsTableRow from 'components/Campaigns/ManageRecipientsTableRow';
import ManageRecipientsHeaderActions from 'components/Campaigns/ManageRecipientsHeaderActions';

const useStyles = makeStyles(({ spacing }) => ({
  tableLeftPadding: {
    paddingLeft: spacing(4),
  },
  columnTitle: {
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  },
  regularWidth: {
    width: 150,
  },
  wideWidth: {
    width: 250,
  },
  infoIcon: {
    marginRight: spacing(1.5),
  },
}));

export default function ManageRecipientsTable() {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;
  const { campaignId } = params;

  // const [showSearchResults, setShowSearchResult] = React.useState(false);
  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
    campaignId,
  });

  const {
    data: campaignData,
    loading: campaignIsLoading,
    error: campaignError,
  } = useQuery(GET_SINGLE_VENDOR_CAMPAIGN, {
    variables: { campaignId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });
  const [
    getCampaignRecipients,
    {
      data: recipientsData,
      loading: recipientsLoading,
      error: recipientsError,
    },
  ] = useLazyQuery(GET_CAMPAIGN_RECIPIENTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  const campaign = (campaignData && campaignData.campaign) || [];
  const campaignRecipients =
    (recipientsData && recipientsData.campaignRecipients) || [];

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getCampaignRecipients({
      variables: {
        campaignId,
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = campaignRecipients?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (campaignRecipients && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.push({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [campaignRecipients]);

  const handleChangePage = async (event, page) => {
    if (
      campaignRecipients &&
      (campaignRecipients.hasNextPage ||
        page + 1 < campaignRecipients.totalPages)
    ) {
      try {
        getCampaignRecipients({
          variables: {
            campaignId,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      getCampaignRecipients({
        variables: {
          campaignId,
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      getCampaignRecipients({
        variables: {
          campaignId,
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  // CUSTOMER SEARCH

  // const handleSearchBar = async (searchPhrase) => {
  //   if (!showSearchResults) {
  //     setShowSearchResult(true);
  //   }
  //   setState({ ...state, page: 0 });
  //   try {
  //     await getCustomersSearch({
  //       variables: {
  //         searchPhrase,
  //         pageSize: state.rowsPerPage,
  //       },
  //     });
  //   } catch (error) {
  //     console.error('SEARCH_CUSTOMERS_ERROR', error.message);
  //   }
  // };

  // const handleResetSearch = () => {
  //   setShowSearchResult(false);
  // };

  const tableColumns = [
    {
      title: t('customers.customer number'),
      field: 'vendorSpecificId',
      sortable: true,
      columnStyles: classNames(
        classes.columnTitle,
        classes.tableLeftPadding,
        classes.regularWidth
      ),
    },
    {
      title: t('settings.business'),
      field: 'businessName',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.wideWidth),
    },
    {
      title: t('campaigns.person'),
      field: 'person',
      sortable: false,
    },
    // {
    //   title: t('campaigns.last order'),
    //   field: 'lastOrder',
    //   sortable: true,
    // },
    {
      title: t('common.phone'),
      field: 'phone',
      sortable: false,
    },
    {
      title: t('campaigns.registration status'),
      field: 'status',
      sortable: false,
    },
    {
      title: t('campaigns.campaign status'),
      field: 'disabled',
      sortable: false,
    },
    {
      title: t('common.action'),
      field: 'action',
      sortable: false,
    },
  ];

  const { page, rowsPerPage, sortBy, sortOrder } = state;

  // const searchResultHasError =
  //   showSearchResults && !searchData?.searchVendorCustomers?.customers;
  const hasError =
    !!campaignError ||
    (!campaignIsLoading && !campaignData) ||
    !!recipientsError ||
    (!recipientsLoading && !campaignRecipients);
  const tableIsLoading = campaignIsLoading || recipientsLoading;
  const tableIsEmpty =
    !tableIsLoading && !campaignRecipients?.recipients?.length;

  return (
    <TableLayout
      title={t('campaigns.manage recipients title', { title: campaign?.title })}
      columns={tableColumns}
      skeletonType={TABLE_SKELETON_TYPES.DEALS}
      emptyTableData={hasError || tableIsEmpty}
      emptyTableDataMessage={t('campaigns.manage recipients empty table')}
      tableIsLoading={tableIsLoading}
      page={page}
      rowsPerPage={rowsPerPage}
      totalResults={campaignRecipients?.totalResults ?? 0}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      backButtonAction={history.goBack}
      // withSearchBar
      // searchPlaceholder={t('campaigns.search placeholder')}
      // handleSearchBar={handleSearchBar}
      // handleShowSearchResult={handleResetSearch}
      sortBy={sortBy}
      sortOrder={sortOrder}
      handleSortRows={handleSortRows}
      itemsOnPage={campaignRecipients?.recipients?.length}
      headerAdditionalActions={
        <ManageRecipientsHeaderActions
          campaignId={campaignId}
          assignedToAll={campaign.assignedToAll}
          assignedToGroups={campaign.assignedToGroups}
          queryState={state}
        />
      }
    >
      {!!campaignRecipients?.recipients?.length &&
        campaignRecipients.recipients.map((rowData, rowIndex) => (
          <ManageRecipientsTableRow
            key={rowData._id}
            rowData={rowData}
            rowIndex={rowIndex}
            campaignId={campaignId}
          />
        ))}
    </TableLayout>
  );
}
