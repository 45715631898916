import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { UPLOAD_PDF_CATALOG } from 'graphql/mutation/pdfCatalogs';
import { GET_PDF_CATALOGS } from 'graphql/query/pdfCatalogs';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ModalLayout from 'components/shared/ModalLayout';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import * as Yup from 'yup';
import Loader from 'components/shared/Loader';
import { FormLabel } from '@material-ui/core';
import GroupSelector from 'components/shared/Inputs/GroupSelector/GroupSelector';
import UploadInput from './UploadInput';

export const pdfCatalogSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required(),
  specialIdPrompt: Yup.string().nullable(),
});

const useStyles = makeStyles(({ spacing, palette }) => ({
  actionButton: {
    height: 50,
    marginTop: spacing(4.5),
  },
  textInput: {
    border: palette.border.lightGrey,
    borderRadius: 4,
    margin: `${spacing(1)}px 0px`,
  },
  input: {
    padding: `${spacing(4.75)}px ${spacing(2)}px ${spacing(2)}px`,
  },
  formControl: {
    top: 16,
  },
  imagePreviewContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: spacing(3),
  },
  pdfPreviewEmbed: {
    width: '100%',
    minHeight: '500px',
  },
  inputLabel: {
    margin: spacing(1, 0),
    lineHeight: `${spacing(3)}px`,
  },
}));

export default function UploadPdfModal({
  isOpen,
  handleClose,
  page,
  rowsPerPage,
  sortBy,
  sortOrder,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [files, setFiles] = React.useState([]);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [uploadPdfCatalog, { loading }] = useMutation(UPLOAD_PDF_CATALOG);
  const [groups, setGroups] = React.useState([]);

  const handleUploadPdfCatalog = async ({ title, specialIdPrompt }) => {
    try {
      const uploadedFile = files && files[0];
      await uploadPdfCatalog({
        variables: {
          file: uploadedFile,
          title,
          groups: groups.map(group => group._id) || [],
          specialIdPrompt,
        },
        update: async (_, { data }) => {
          setFiles([]);
          setGroups([]);
          history.push(`pdfCatalog/${data?.uploadPdfCatalog?._id}`);
        },
        refetchQueries: [
          {
            query: GET_PDF_CATALOGS,
            variables: {
              after: page + 1,
              pageSize: rowsPerPage,
              sortBy,
              sortOrder,
            },
            update: () => {
              handleClose();
            },
          },
        ],
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong extended', {
            errorMessage: error.message ?? '',
          }),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const pdfSrc = !!files.length && files[0].preview;

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      modalTitle={t('pdfCatalog.modalTitle')}
    >
      <Formik
        initialValues={{ title: '', specialIdPrompt: '' }}
        enableReinitialize
        onSubmit={values => handleUploadPdfCatalog(values)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={pdfCatalogSchema}
      >
        {({ values, handleChange, handleSubmit, errors, handleBlur }) => (
          <Grid container direction="column">
            {loading ? (
              <Loader styles={{ height: '200px' }} />
            ) : (
              <>
                <TextField
                  label={t('common.title')}
                  value={values.title}
                  name="title"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  className={classes.textInput}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      formControl: classes.formControl,
                    },
                  }}
                  error={!!errors.title}
                  onBlur={handleBlur}
                  helperText={!!errors.title && t('salesReps.input error name')}
                />
                <GroupSelector
                  handleChange={setGroups}
                  selectedGroups={groups}
                  textFieldClass={classes.textInput}
                />
                <FormLabel className={classes.inputLabel}>
                  {t('pdfCatalog.specialIdPromptLabel')}
                </FormLabel>
                <TextField
                  label={t('pdfCatalog.specialIdPrompt')}
                  value={values.specialIdPrompt}
                  name="specialIdPrompt"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  className={classes.textInput}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      formControl: classes.formControl,
                    },
                  }}
                  error={!!errors.specialIdPrompt}
                  onBlur={handleBlur}
                  helperText={
                    !!errors.specialIdPrompt && t('salesReps.input error name')
                  }
                />

                <UploadInput files={files} setFiles={setFiles} />
                <Button
                  className={classes.actionButton}
                  fullWidth
                  disabled={!files.length || loading}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  {t('common.confirm')}
                </Button>

                {pdfSrc && (
                  <Grid item className={classes.imagePreviewContainer}>
                    <embed className={classes.pdfPreviewEmbed} src={pdfSrc} />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
      </Formik>
    </ModalLayout>
  );
}

UploadPdfModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
};
