import { gql } from '@apollo/client';

export const FORGOT_PASSWORD = gql`
mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $token:String!, $password:String!) {
    resetPassword(email: $email, token: $token, password: $password)
  }
`;
