import isEqual from 'lodash/isEqual';
import { PRODUCT_DATA } from 'helpers/constants';
import moment from 'moment';

export const NL_SECTIONS = {
  FLOWS: 'FLOWS',
  BROADCASTS: 'BROADCASTS',
};

export const NEWSLETTER_STATUS = {
  SENT: 'sent',
  DRAFT: 'draft',
};

export const RECIPIENTS_FILTER_OPTIONS = {
  NONE: 'none',
  SENT: 'sent',
  DELIVERED: 'delivered',
  VALID_EMAIL: 'valid_email',
  INVALID_EMAIL: 'invalid_email',
  OPENED: 'opened',
  CLICKED: 'clicked',
  BOUNCED: 'bounced',
  NOT_SENT: 'not_sent',
  NO_EMAIL: 'no_email',
  UNSUBSCRIBED: 'unsubscribed',
};

export const DRAFT_NEWSLETTER_RECIPIENT_FILTER_OPTIONS = {
  NONE: RECIPIENTS_FILTER_OPTIONS.NONE,
  VALID_EMAIL: RECIPIENTS_FILTER_OPTIONS.VALID_EMAIL,
  INVALID_EMAIL: RECIPIENTS_FILTER_OPTIONS.INVALID_EMAIL,
  UNSUBSCRIBED: RECIPIENTS_FILTER_OPTIONS.UNSUBSCRIBED,
};

export const SENT_NEWSLETTER_RECIPIENTS_FILTER_OPTIONS = {
  NONE: RECIPIENTS_FILTER_OPTIONS.NONE,
  SENT: RECIPIENTS_FILTER_OPTIONS.SENT,
  NOT_SENT: RECIPIENTS_FILTER_OPTIONS.NOT_SENT,
  DELIVERED: RECIPIENTS_FILTER_OPTIONS.DELIVERED,
  OPENED: RECIPIENTS_FILTER_OPTIONS.OPENED,
  CLICKED: RECIPIENTS_FILTER_OPTIONS.CLICKED,
  BOUNCED: RECIPIENTS_FILTER_OPTIONS.BOUNCED,
  VALID_EMAIL: RECIPIENTS_FILTER_OPTIONS.VALID_EMAIL,
  INVALID_EMAIL: RECIPIENTS_FILTER_OPTIONS.INVALID_EMAIL,
  UNSUBSCRIBED: RECIPIENTS_FILTER_OPTIONS.UNSUBSCRIBED,
};

export const NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS = {
  ASSIGNED: 'assigned',
  EXCLUDED: 'excluded',
};

export const UPDATE_NEWSLETTER_RECIPIENT_ACTION = {
  [NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED]: {
    ADD: 'addToAssigned',
    DELETE: 'deleteFromAssigned',
  },
  [NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.EXCLUDED]: {
    ADD: 'addToExcluded',
    DELETE: 'deleteFromExcluded',
  },
};

export const POPULAR_COLORS = [
  '#FF5733', // Red Orange
  '#FFC300', // Cyber Yellow
  '#DAF7A6', // Pastel Green
  '#C70039', // Red Violet
  '#900C3F', // Dark Magenta
  '#581845', // Eggplant
  '#3498DB', // Brilliant Blue
  '#1ABC9C', // Vivid Cyan
  '#2ECC71', // Emerald
  '#F1C40F', // Bright Yellow
  '#E74C3C', // Soft Red
  '#8E44AD', // Violet
  '#16A085', // Medium Aquamarine
  '#273746', // Dark Blue Gray
];

export const SES_EVENT_TYPE = {
  SEND: 'Send',
  REJECT: 'Reject',
  DELIVERY: 'Delivery',
  BOUNCE: 'Bounce',
  COMPLAINT: 'Complaint',
  DELIVERY_DELAY: 'DeliveryDelay',
  OPEN: 'Open',
  CLICK: 'Click',
};

// https://docs.aws.amazon.com/ses/latest/dg/event-publishing-retrieving-firehose-contents.html#event-publishing-retrieving-firehose-delivery-delay-object
// Proudly written by Vlad Mau aka Noone
export function getEventMetadata({ _id, eventType, timestamp, ...event }, translate) {
  const tsMoment = moment(timestamp);

  let time = tsMoment.format('HH:mm, MMMM Do');

  if (tsMoment.get('year') !== moment().get('year')) {
    time = tsMoment.format('HH:mm, MMMM Do YYYY');
  }

  const title = translate(`newsletter.event types.${eventType}`);
  let info = '';
  let clickUrl = '';
  let clickDomain = '';
  let delayReason = '';
  let userAgent = '';
  let bounceType = '';
  let isTransientBounce = false;

  switch (eventType) {
    case SES_EVENT_TYPE.CLICK:
      if (event?.click?.link) {
        clickUrl = event?.click?.link?.replace(/\?.*$/, '');
        clickDomain = event?.click?.link?.replace(/^https?:\/\/(.*?)[?/].*$/, '$1');
        info = translate('newsletter.events.click');
      }
      userAgent = event?.click?.userAgent;
      break;
    case SES_EVENT_TYPE.BOUNCE:
      info = translate(`newsletter.events.bounce.${event?.bounce?.bounceType}.${event?.bounce?.bounceSubType}`);
      isTransientBounce = event?.bounce?.bounceType === 'Transient';
      bounceType = translate(`newsletter.events.bounce type.${event.bounce.bounceType}`);
      break;
    case SES_EVENT_TYPE.DELIVERY_DELAY:
      info = translate('newsletter.events.delivery delay', {
        date: moment(event?.deliveryDelay?.expirationTime).format('HH:mm, MMMM Do YYYY'),
      });
      delayReason = translate(`newsletter.events.delay.${event?.deliveryDelay?.delayType}`);
      break;
    case SES_EVENT_TYPE.COMPLAINT:
      userAgent = event?.complaint?.userAgent;
      info = translate(`newsletter.events.complaint.${event?.complaint?.complaintFeedbackType}`);
      break;
    case SES_EVENT_TYPE.OPEN:
      userAgent = event?.open?.userAgent;
      break;
    default:
      break;
  }

  return {
    _id,
    time,
    title,
    info,
    clickUrl,
    clickDomain,
    delayReason,
    eventType,
    timestamp,
    userAgent,
    bounceType,
    isTransientBounce,
  };
}

export const NL_CHARS_LIMIT = 240;
export const NL_PROMO_TEXT_CHARS_LIMIT = 1000;

export const NEWSLETTER_PRODUCTS_LIMIT = 20;

export const NEWSLETTER_PRODUCT = () => ({
  _id: '',
  enabled: true,
  name: '',
  imageUrl: null,
  description: '',
  label: '',
  isLabelEnabled: false,
  productCopy: {
    ...PRODUCT_DATA(),
  },
  variants: [
    {
      baseUnit: '',
      enabled: true,
      packaging: '',
      price: 0,
      unitsInPackaging: null,
    },
  ],
});

export const NEWSLETTER_DEFAULT_DATA = (vendorId = '') => ({
  _id: '',
  title: '',
  vendorId,
  status: 'draft',
  subject: '',
  fromName: '',
  staticInfo: {
    workingHours: '',
    workingHoursCaption: '',
    phone: '',
    email: '',
    disclaimer: '',
  },
  productGrid: {
    color: null,
    promoText: '',
    promoStartDate: null,
    promoEndDate: null,
    logoUrl: '',
    bannerUrl: '',
    featuredProducts: [...Array(3)].map(() => NEWSLETTER_PRODUCT()),
    products: [],
  },
});

export function parseNewsletterStateFields(newsletter) {
  // NOTE 1: just removing __typename fields on newsletter object and returning object for state
  // NOTE 2: setting featured products qty to 3 by default, filling by default data

  const {
    productGrid: {
      bannerUrl,
      color,
      logoUrl,
      promoEndDate,
      promoStartDate,
      promoText,
      featuredProducts = [],
      products = [],
    } = {},
    assignedGroups,
    assignedToAll,
    excludedGroups,
    recipientCount,
    excludedRecipientCount,
    fromEmail,
    fromName,
    staticInfo,
    subject,
    status,
    vendorId,
    title,
    _id,
  } = newsletter || {};

  const newsletterDefaultData = NEWSLETTER_DEFAULT_DATA();

  const template = {
    _id,
    assignedGroups,
    assignedToAll,
    excludedGroups,
    recipientCount,
    excludedRecipientCount,
    fromEmail,
    fromName,
    staticInfo: {
      workingHours: staticInfo.workingHours,
      workingHoursCaption: staticInfo.workingHoursCaption,
      phone: staticInfo.phone,
      email: staticInfo.email,
      disclaimer: staticInfo.disclaimer,
    },
    productGrid: {
      bannerUrl,
      color,
      logoUrl,
      promoEndDate,
      promoStartDate,
      promoText,
      featuredProducts: newsletterDefaultData.productGrid.featuredProducts.map(
        (defaultFeatProduct, index) => {
          const featProduct = featuredProducts[index];
          if (featProduct) {
            const {
              __typename: prTN,
              variants = [],
              ...featProductFields
            } = featProduct;
            return {
              ...featProductFields,
              variants: variants.map(
                ({ __typename: varTN, ...ftProdFields }) => ({
                  ...ftProdFields,
                })
              ),
            };
          } else {
            return defaultFeatProduct;
          }
        }
      ),
      products: products?.map(product => {
        const { __typename: prTN, variants = [], ...productFields } = product;
        return {
          ...productFields,
          variants: variants.map(({ __typename: varTN, ...ftProdFields }) => ({
            ...ftProdFields,
          })),
        };
      }),
    },
    subject,
    status,
    vendorId,
    title,
  };

  return template;
}

export function updateNewsletterProductField(
  productsArray,
  productIndexToUpdate,
  fieldToUpdate,
  value,
  isVariantField
) {
  return productsArray.map((product, pIndex) => {
    // NOTE: find product we need to update

    if (productIndexToUpdate === pIndex) {
      // NOTE: check if this is variant field need to be updated

      if (isVariantField && Array.isArray(fieldToUpdate)) {
        const [variantIndexToUpdate, variantFieldToUpdate] = fieldToUpdate;
        return {
          ...product,
          variants: product.variants.map((variant, vIndex) => {
            const newValue =
              variantFieldToUpdate === 'price' ? parseFloat(value) : value;

            if (vIndex === variantIndexToUpdate) {
              return {
                ...variant,
                [variantFieldToUpdate]: newValue,
              };
            } else {
              return variant;
            }
          }),
        };
      } else {
        return {
          ...product,
          [fieldToUpdate]: value,
        };
      }

      // NOTE: return other products as it is
    } else return product;
  });
}

export function compareTemplates(template, initialResponse) {
  if (!initialResponse) return false;
  const initialNLData = initialResponse?.getNewsletter || {};
  const parsedInitialData = parseNewsletterStateFields(initialNLData);

  const { fromEmail, fromName, staticInfo, subject, title } =
    parsedInitialData || {};

  const initialTemplate = {
    fromEmail,
    fromName,
    staticInfo: {
      workingHours: staticInfo.workingHours,
      workingHoursCaption: staticInfo.workingHoursCaption,
      phone: staticInfo.phone,
      email: staticInfo.email,
      disclaimer: staticInfo.disclaimer,
    },
    subject,
    title,
    productGrid: {
      ...parsedInitialData.productGrid,
      products: parsedInitialData?.productGrid?.products?.map(
        ({ productCopy, ...productFields }) => ({
          ...productFields,
        })
      ),
      featuredProducts: parsedInitialData?.productGrid?.featuredProducts?.map(
        ({ productCopy, ...productFields }) => ({
          ...productFields,
        })
      ),
    },
  };

  return isEqual(template, initialTemplate);
}
