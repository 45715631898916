import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment/moment';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DocumentForm from './DocumentForm';
import { INVOICE_CANCEL } from '../../graphql/mutation/orders';
import { NOTIFICATION_STATUS } from '../../helpers/constants';
import { SET_NOTIFICATION } from '../../graphql/mutation/user';
import { GET_ORDERS } from '../../graphql/query/orders';
import { GET_VENDOR_ACCOUNT } from '../../graphql/query/vendors';

const useStyles = makeStyles(theme => ({
  detailPanelContainer: {
    background: theme.palette.background.container,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(5)}px ${theme.spacing(
      6.5
    )}px`,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(2.5)}px ${theme.spacing(
        10.5
      )}px ${theme.spacing(6.5)}px`
    }
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  historyType: {
    width: 100
  },
  tableContainer: {
    marginBottom: theme.spacing(4)
  },
  tableHeaderCell: {
    paddingBottom: theme.spacing(1.5),
    fontSize: theme.typography.htmlFontSize,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: theme.palette.border.grey
  },
  downloadFileButton: {
    background: 'rgba(255, 81, 0, 0.15)',
    color: theme.palette.primary.main,
    minWidth: 0,
    width: 28,
    height: 28,
    boxShadow: '0px 0px 10px rgba(202, 202, 202, 0.25)',
    borderRadius: 6,
    padding: 0,
    marginLeft: 5
  },
  tableBody: {
    padding: `${theme.spacing(0.5)}px 0px`,
    borderBottom: theme.palette.border.grey,
    fontSize: theme.typography.fontSize,
    alignItems: 'baseline',
    paddingBottom: 15,
    paddingTop: 15
  },
  historyBody: {
    alignItems: 'start'
  },
  historyCell: {
    minHeight: 50,
    marginTop: 10,
    color: `${theme.palette.secondary.darkGray}`,
    display: 'flex',
    alignItems: 'center'
  },
  vIBAN: {
    color: theme.palette.secondary.darkGray,
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyItems: 'start'
  },
  headerTitle: {
    maxWidth: '75%'
  },
  tableSearchBarButtons: {
    minWidth: 130,
    height: 45,
    marginRight: theme.spacing(1.5),
    border: theme.palette.border.darkGray,
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  activeIcon: {
    color: theme.palette.primary.main
  }
}));

export default function DetailPanel({
  orderId,
  historyData = [],
  order,
  bic = '',
  receiver = ''
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cancelMonduInvoice] = useMutation(INVOICE_CANCEL);
  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [getOrders] = useLazyQuery(GET_ORDERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });
  const { data } = useQuery(GET_VENDOR_ACCOUNT, {
    fetchPolicy: 'cache-first'
  });

  const vendorData = data && data.vendorAccount;
  const VAT_FEE_PERCENT = 0.19;

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'orderedAt',
    sortOrder: -1
  });

  const updateOrdersState = () => {
    // eslint-disable-next-line no-restricted-globals
    const queries = queryString.parse(location.search);

    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getOrders({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      }
    });
    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      });
    }
  };

  const hasInvoice =
    historyData.find(item => item.type === 'invoice' && !item.deletedAt) !==
    undefined;

  const handleCancelInvoice = async uuid => {
    await cancelMonduInvoice({
      variables: { orderId, monduInvoiceUUID: uuid }
    });
    updateOrdersState();
    setNotification({
      variables: {
        timeout: 5000,
        message: `${t('orders.successfully cancelled')}`,
        type: NOTIFICATION_STATUS.SUCCESS,
        isOpen: true
      }
    });
  };

  const orderGross = (order?.totalPrice * VAT_FEE_PERCENT + order?.totalPrice)?.toFixed(2);
  return (
    <Grid container className={classes.detailPanelContainer}>
      <DocumentForm
        orderId={orderId}
        type="invoice"
        // eslint-disable-next-line react/jsx-no-bind
        onSubmit={updateOrdersState}
      />
      {hasInvoice && (
        <DocumentForm
          orderId={orderId}
          type="creditNote"
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={updateOrdersState}
        />
      )}
      <Typography className={classes.sectionTitle} variant="h6">
        {t('orders.Upload History')}
      </Typography>
      <Grid container className={classes.tableContainer}>
        <Grid container className={classes.tableHeaderCell}>
          <Grid item xs={3}>
            <Typography>{t('orders.Type')}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>{t('orders.number')}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.header}>
            <Typography className={classes.headerTitle}>
              {t('orders.Total sum net')}
            </Typography>
            <Tooltip
              title={t('orders.Total sum net tooltip')}
              placement="right-end"
            >
              <InfoIcon
                style={{ marginLeft: 4 }}
                fontSize="small"
                color="primary"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={2} className={classes.header}>
            <Typography className={classes.headerTitle}>
              {t('orders.Total sum gross')}
            </Typography>
            <Tooltip
              title={t('orders.Total sum gross tooltip')}
              placement="right-end"
            >
              <InfoIcon
                style={{ marginLeft: 4 }}
                fontSize="small"
                color="primary"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Typography>{t('orders.Timestamp upload')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{t('State')}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          className={classNames(classes.tableBody, classes.historyBody)}
        >
          <Grid item xs={3} className={classes.historyCell}>
            <Grid container wrap="nowrap">
              <Typography className={classes.historyType}>
                {t('orders.order')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.historyCell}>
            <Typography>{order?.orderNumber}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.historyCell}>
            <Typography>{order?.totalPrice} €</Typography>
          </Grid>
          <Grid item xs={2} className={classes.historyCell}>
            <Typography>{orderGross} €</Typography>
          </Grid>
          <Grid item xs={2} className={classes.historyCell}>
            <Typography>
              {order?.orderedAt &&
                moment(order?.orderedAt).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.historyCell} />
          {historyData.map(item => (
            <Grid
              container
              wrap="nowrap"
              key={`document-${item?.documentNumber}`}
            >
              <Grid item xs={3} className={classes.historyCell}>
                <Grid container wrap="nowrap">
                  <Typography className={classes.historyType}>
                    {t(`orders.${item.type}`)}
                  </Typography>
                  <IconButton
                    size="small"
                    className={classes.downloadFileButton}
                    onClick={() => window.open(item.url, '_blank')}
                  >
                    <PdfIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={1} className={classes.historyCell}>
                <Typography>{item?.documentNumber}</Typography>
              </Grid>
              <Grid item xs={2} className={classes.historyCell}>
                <Typography>{item?.net?.toFixed(2)} €</Typography>
              </Grid>
              <Grid item xs={2} className={classes.historyCell}>
                <Typography>{item?.gross?.toFixed(2)} €</Typography>
              </Grid>
              <Grid item xs={2} className={classes.historyCell}>
                <Typography>
                  {item?.createdAt &&
                    moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}
                </Typography>
              </Grid>
              {item.type === 'invoice' || item.type === 'creditNote' ? (
                <Grid item xs={2} className={classes.historyCell}>
                  {item.deletedAt ? (
                    <Typography className={classes.historyType}>
                      {t('orders.canceled')}
                    </Typography>
                  ) : (
                    item.type === 'invoice' && (
                      <Button
                        className={classes.tableSearchBarButtons}
                        aria-label="confirm"
                        variant="outlined"
                        startIcon={
                          <DeleteIcon className={classes.activeIcon} />
                        }
                        onClick={async () => {
                          await handleCancelInvoice(item.uuid);
                        }}
                      >
                        {t('orders.cancel')}
                      </Button>
                    )
                  )}
                </Grid>
              ) : (
                <Grid item xs={2} className={classes.historyCell} />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={10}>
          <Typography className={classes.vIBAN} variant="h6">
            {t('orders.viban')}: {vendorData?.preferences?.mondu?.vIBAN}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.vIBAN} variant="h6">
            {t('orders.bic')}: {bic}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.vIBAN} variant="h6">
            {t('orders.receiver')}: {receiver}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

DetailPanel.propTypes = {
  historyData: PropTypes.array.isRequired,
  orderId: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  bic: PropTypes.string.isRequired,
  receiver: PropTypes.string.isRequired
};
