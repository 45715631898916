import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useMutation } from '@apollo/client';
import { DELETE_EXPIRING_DEALS, UPDATE_EXPIRING_DEALS } from 'graphql/mutation/deals';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ConfirmIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import EditConfirmationModal from 'components/shared/EditConfirmationModal';
import ProductPackaging from 'components/ProductsTable/ProductPackaging';
import DeleteModal from 'components/shared/DeleteModal';
import { truncateTitle } from 'helpers/utils';
import useMixPanel from 'helpers/useMixPanel';
import PriceInput from 'components/DealsTable/PriceInput';
import ExpiringProductsDealsTableRowCells from './ExpiringProductsDealsTableRowCells';
import DateInput from '../DealsTable/DateInput';
import { getDateStatus } from '../../helpers/dates';
import { useNotification } from '../../hooks/useNotification';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 60,
    borderBottom: theme.palette.border.lightGrey
  },
  variantRow: {
    height: 60,
  },
  isNotActiveRow: {
    background: theme.palette.type === 'dark' ?
      theme.palette.action.disabledBackground
      : theme.palette.background.default
  },
  tableWithOpenedPanel: {
    background: theme.palette.background.container,
    borderBottom: 'none'
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    maxWidth: 150,
    borderBottom: 'none',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  tableCellPanelOpen: {
    backgroundColor: theme.palette.background.container,
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.button
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.darkGray
  },
  confirmButton: {
    color: theme.palette.success.main,
    margin: theme.spacing(0.5)
  },
  cancelButton: {
    margin: theme.spacing(0.5),
    color: theme.palette.error.main
  },
  expandMoreCell: {
    width: 30,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function ExpiringProductDealsTableRow(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [updateDeal] = useMutation(UPDATE_EXPIRING_DEALS);
  const [deleteDealsMutation] = useMutation(DELETE_EXPIRING_DEALS);
  const mixPanel = useMixPanel();
  const showSuccessNotification = useNotification(NOTIFICATION_STATUS.SUCCESS);
  const showAlertNotification = useNotification(NOTIFICATION_STATUS.ALERT);

  const [isPanelOpen, setPanelOpen] = React.useState(false);
  const [isDeleteRowModalOpen, setDeleteRowModal] = React.useState(false);
  const [productData, setProductData] = React.useState(props.rowData);
  const [packagingToDelete, setPackagingToDelete] = React.useState(null);

  const {
    rowData,
    isEditMode,
    setEditMode,
    editConfirmationModalIsActive,
    setConfirmEditChanges,
  } = props;

  React.useEffect(() => {
    if (props.rowData) {
      setProductData(props.rowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData]);

  React.useEffect(() => setPanelOpen(isEditMode), [isEditMode]);

  const handleChangePrice = (value, id) => {
    setProductData({
      ...productData,
      variants: productData.variants.map(variant => {
        if (variant._id === id) {
          return ({
            ...variant,
            dealDetails: {
              ...variant.dealDetails,
              dealPrice: value,
            }
          });
        } else return variant;
      })
    });
  };

  const handleChangeDate = (value, dateType, packaging) => {
    const newProduct = {
      ...productData,
    };
    const variant = newProduct.variants.find(v => v.packaging === packaging);
    if (variant) {
      if (dateType === 'expiresAt') {
        variant.dealDetails = {
          ...variant.dealDetails,
          expiringDetails: {
            ...variant.dealDetails.expiringDetails,
            expiresAt: moment(value).utc().startOf('day').toISOString()
          }
        };
      } else {
        variant.dealDetails = {
          ...variant.dealDetails,
          [dateType]: moment(value).utc().startOf('day').toISOString()
        };
      }
    }
    setProductData(newProduct);
  };

  const handleDeleteDeal = async (id, packaging) => {
    try {
      if (!packaging) {
        setDeleteRowModal(false);
      }

      await deleteDealsMutation({
        variables: {
          products: [{
            _id: id,
            packaging
          }],
        },
        update: () => {
          props.onDealDeleted();
          setDeleteRowModal(false);
        }
      });
      mixPanel.track('Delete deals', { products: id });
      showSuccessNotification(`${t('deals.deal delete success')} ${productData.vendorSpecificId} - ${packaging}`);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCloseEditModal = () => {
    setProductData(rowData);
    setEditMode(null);
  };

  const handleSubmitDealData = async () => {
    const { vendorSpecificId } = productData;
    if (productData.variants.some((variant) => {
      const { start, end, expiringDetails } = variant.dealDetails || {};
      const { expiresAt } = expiringDetails || {};
      return !start || !end || !expiresAt || moment(start).isAfter(end, 'day') || moment(end).isAfter(expiresAt, 'day');
    })) {
      showAlertNotification(t('deals.date error'));
      return;
    }

    const newDealPrices = productData.variants
      .map(variant => ({ packaging: variant.packaging, dealPrice: +variant.dealPrice }));

    if (newDealPrices.some(variant => variant.dealPrice < 0)) {
      showAlertNotification(t('deals.price error'));
      return;
    }
    const newDealsProducts =
       [{
         productId: vendorSpecificId,
         variants: productData.variants.map(variant => ({
           packaging: variant.packaging,
           dealPrice: +variant.dealDetails.dealPrice,
           baseUnit: variant.baseUnit,
           dealType: variant.dealDetails.dealType,
           start: variant.dealDetails.start,
           end: variant.dealDetails.end,
           expiresAt: variant.dealDetails.expiringDetails.expiresAt,
           enabled: variant.dealDetails.enabled,
           availableQty: variant.availableQty,
           purchasedQty: variant.purchasedQty,
           isExpiring: variant.isExpiring,
           createVariantOnImport: variant.createVariantOnImport,
         })),
       }];
    try {
      await updateDeal({
        variables: {
          dealType: 'expiring',
          products: newDealsProducts
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setPanelOpen(false);
    setEditMode(null);
  };

  const hasDetailPanel = productData.variants.length > 1;

  const cellClasses = classNames(
    classes.tableCell,
    isPanelOpen && classes.tableCellPanelOpen);

  const isNotActiveRow = !rowData.variants.length
    || rowData.variants.every(variant => !variant.dealPrice)
    || !rowData.dealDetails.start || !rowData.dealDetails.end;

  const dateStatus = getDateStatus();

  return (
    <>
      <TableRow className={classNames(
        classes.tableBodyRow,
        (isNotActiveRow && !isPanelOpen) && classes.isNotActiveRow,
        isPanelOpen && classes.tableWithOpenedPanel
      )}
      >
        {hasDetailPanel ? (
          <TableCell className={classNames(cellClasses, classes.expandMoreCell)}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setPanelOpen(!isPanelOpen)}
            >
              {isPanelOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell className={cellClasses}/>
        )}
        {/* @TODO: remove comments when support bulk actions will be implemented */}
        {/* <TableCell className={cellClasses}> */}
        {/*  <Checkbox */}
        {/*    checked={selected.includes(productData._id)} */}
        {/*    onClick={event => handleSelectItem(event, productData._id)} */}
        {/*  /> */}
        {/* </TableCell> */}
        <ExpiringProductsDealsTableRowCells
          productData={productData}
          isEditMode={isEditMode}
          handleChangePrice={handleChangePrice}
          handleChangeDate={handleChangeDate}
          dateStatus={dateStatus}
          variantIndex={0}
        />
        <TableCell className={classes.tableCell}>
          <Grid container wrap="nowrap">
            {
              isEditMode ? (
                <>
                  <IconButton
                    onClick={handleSubmitDealData}
                    className={classes.confirmButton}
                    aria-label="confirm"
                    size="small"
                  >
                    <ConfirmIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setConfirmEditChanges(true)}
                    className={classes.cancelButton}
                    aria-label="cancel"
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    classes={{ root: classes.tableActionButton }}
                    onClick={() => setEditMode(productData._id)}
                  >
                    <EditIcon className={classes.tableActionButtonIcon}/>
                  </Button>
                  <Button
                    variant="contained"
                    classes={{ root: classes.tableActionButton }}
                    onClick={() => {
                      setDeleteRowModal(true);
                      setPackagingToDelete(productData.variants[0]?.packaging);
                    }}
                  >
                    <DeleteIcon className={classes.tableActionButtonIcon}/>
                  </Button>
                </>
              )
            }
          </Grid>
        </TableCell>
      </TableRow>
      {isPanelOpen && productData.variants.slice(1).map(variant => (
        <TableRow key={variant._id} className={classes.variantRow}>
          <TableCell className={cellClasses} colSpan={1} />
          <TableCell className={cellClasses}>
            <Typography variant="body2" align="left">
              {productData.vendorSpecificId}
            </Typography>
          </TableCell>
          <TableCell className={cellClasses}>
            {truncateTitle(productData.name)}
          </TableCell>
          <TableCell className={cellClasses}>
            <PriceInput
              price={variant.dealDetails?.dealPrice || ''}
              justify="center"
              handleChangePrice={({ target: { value } }) =>
                handleChangePrice(value, variant._id)}
              isEditMode={isEditMode}
            />
          </TableCell>
          <TableCell className={cellClasses}>
            <ProductPackaging unit={variant.packaging} />
          </TableCell>
          <TableCell className={cellClasses}>
            <DateInput
              dateStatus={getDateStatus(new Date(), variant.dealDetails?.start)}
              date={variant.dealDetails?.start ?? ''}
              dateType="start"
              isEditMode={isEditMode}
              handleChangeDate={(value, label) => handleChangeDate(value, label, variant.packaging)}
            />
          </TableCell>
          <TableCell className={cellClasses}>
            <DateInput
              dateStatus={getDateStatus(variant.dealDetails?.start, variant.dealDetails?.end)}
              date={variant.dealDetails?.end ?? ''}
              dateType="end"
              minDate={variant.dealDetails?.start ?? ''}
              isEditMode={isEditMode}
              handleChangeDate={(value, label) => handleChangeDate(value, label, variant.packaging)}
            />
          </TableCell>
          <TableCell className={cellClasses}>
            <DateInput
              dateStatus={getDateStatus(variant.dealDetails?.start, variant.dealDetails?.expiringDetails?.expiresAt)}
              date={variant.dealDetails?.expiringDetails?.expiresAt ?? ''}
              dateType="expiresAt"
              minDate={variant.dealDetails?.end ?? variant.dealDetails?.start ?? ''}
              isEditMode={isEditMode}
              handleChangeDate={(value, label) => handleChangeDate(value, label, variant.packaging)}
            />
          </TableCell>
          <TableCell className={cellClasses}>
            <Grid container wrap="nowrap">
              {
                isEditMode ? (
                  <>
                    <IconButton
                      onClick={handleSubmitDealData}
                      className={classes.confirmButton}
                      aria-label="confirm"
                      size="small"
                    >
                      <ConfirmIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setConfirmEditChanges(true)}
                      className={classes.cancelButton}
                      aria-label="cancel"
                      size="small"
                    >
                      <CancelIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      classes={{ root: classes.tableActionButton }}
                      onClick={() => setEditMode(productData._id)}
                    >
                      <EditIcon className={classes.tableActionButtonIcon}/>
                    </Button>
                    <Button
                      variant="contained"
                      classes={{ root: classes.tableActionButton }}
                      onClick={() => {
                        setDeleteRowModal(true);
                        setPackagingToDelete(variant.packaging);
                      }}
                    >
                      <DeleteIcon className={classes.tableActionButtonIcon}/>
                    </Button>
                  </>
                )
              }
            </Grid>
          </TableCell>
        </TableRow>
      )
      )}
      {isEditMode && (
        <EditConfirmationModal
          isOpen={editConfirmationModalIsActive}
          handleCloseModal={setConfirmEditChanges}
          modalContentMessage={t('deals.confirm edit modal message')}
          onConfirm={handleSubmitDealData}
          onCancel={handleCloseEditModal}
        />
      )}
      {isDeleteRowModalOpen && (
        <DeleteModal
          isOpen={isDeleteRowModalOpen}
          message={t('deals.delete deal message')}
          handleCloseModal={() => setDeleteRowModal(false)}
          handleDelete={() => handleDeleteDeal(productData._id, packagingToDelete)}
        />
      )}
    </>
  );
}

ExpiringProductDealsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  editConfirmationModalIsActive: PropTypes.bool.isRequired,
  setConfirmEditChanges: PropTypes.func.isRequired,
  onDealDeleted: PropTypes.func.isRequired,
};

export default ExpiringProductDealsTableRow;
