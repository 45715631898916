/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DateRangeIcon from '@material-ui/icons/DateRangeRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import PersonAddDisabledRoundedIcon from '@material-ui/icons/PersonAddDisabledRounded';

import StyledInput from 'components/shared/Inputs/StyledInput';
import NewsletterImageUploader from 'components/Newsletter/Constructor/NewsletterImageUploader';
import DatePickerModal from 'components/Newsletter/Constructor/DateRangeModal';
import useNewsletterStyles from 'components/Newsletter/NewsletterStyling';
import RecipientsList from 'components/Newsletter/Constructor/RecipientsList';
import NewsletterColorPicker from 'components/Newsletter/Constructor/NewsletterColorPicker';
import {
  NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS,
  NL_CHARS_LIMIT,
  NL_PROMO_TEXT_CHARS_LIMIT,
  RECIPIENTS_FILTER_OPTIONS,
} from 'components/Newsletter/localConstantsAndHelpers';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  newsletterConstructorContainer: {
    width: '100%',
    height: '100%',
  },

  nlConstructorPaper: {
    borderRadius: 8,
    padding: spacing(3),
  },
  nlConstructorSection: {
    marginBottom: spacing(2),
    boxSizing: 'border-box',
  },
  fieldLabel: {
    fontWeight: typography.fontWeightBold,
  },
  recipientsButton: {
    ...typography.body1,
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(0.5),
  },
  vendorWorkingHoursRoot: {
    width: '100%',
    border: palette.border.lightGreyThinDark,
    borderRadius: 8,
    '&:before': {
      display: 'none',
    },
    '&:last-child': {
      borderRadius: 8,
    },
    '&:first-child': {
      borderRadius: 8,
    },
    '& .Mui-expanded': {
      minHeight: 48,
      height: 48,
      margin: 0,
      alignItems: 'center',
    },
  },
  editVendorSettingsButton: {
    marginLeft: spacing(-1),
  },
  promoTextContainer: {
    padding: spacing(2),
    border: palette.border.lightGreyThinDark,
    borderRadius: 8,
  },
  newsletterTextContainer: {
    paddingRight: spacing(2),
  },
  dateRangeButton: {
    height: 42,
    justifyContent: 'flex-start',
    borderRadius: 8,
  },
  requiredStar: {
    paddingLeft: '0.1rem',
    color: 'rgba(222, 17, 63, 0.72)'
  },
  multilineInput: {
    height: 'auto'
  },
  promoTextInfoIcon: {
    marginLeft: spacing(1)
  }
}));

export default function MainSection({
  state,
  setState,
  handleUpdateNewsletter,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useNewsletterStyles();
  const history = useHistory();

  const [isDatePickerOpen, setOpenDatePicker] = useState(false);

  const {
    staticInfo,
    assignedToAll,
    excludedGroups = [],
    assignedGroups = [],
    recipientCount,
    excludedRecipientCount,
    title,
    _id,
    status,
  } = state;
  const { promoText, promoStartDate, promoEndDate, bannerUrl, logoUrl, color } =
    state.productGrid || {};

  const isSentStatus = status === RECIPIENTS_FILTER_OPTIONS.SENT;

  const handleChangeStateField = fieldName => value => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleColorChange = colorValue => {
    setState(prevState => ({
      ...prevState,
      productGrid: {
        ...prevState.productGrid,
        color: colorValue,
      },
    }));
  };

  const handleChangeSupportInfoField = fieldName => event => {
    event.persist();
    setState(prevState => ({
      ...prevState,
      staticInfo: {
        ...prevState.staticInfo,
        [fieldName]: event.target.value,
      },
    }));
  };

  const handleChangeProductGridField = fieldName => value => {
    setState(prevState => ({
      ...prevState,
      productGrid: {
        ...prevState.productGrid,
        [fieldName]: value,
      },
    }));
  };

  const handleDeleteProductGridImages = (field, fieldUrl) => () => {
    setState(prevState => ({
      ...prevState,
      productGrid: {
        ...prevState.productGrid,
        [field]: null,
        [fieldUrl]: null,
      },
    }));
  };

  const handleOpenDateRangeModal = () => {
    setOpenDatePicker(true);
  };

  const handleCloseDateRangeModal = () => {
    setOpenDatePicker(false);
  };

  const handleClearDateRange = () => {
    setState(prevState => ({
      ...prevState,
      productGrid: {
        ...prevState.productGrid,
        promoStartDate: undefined,
        promoEndDate: undefined,
      },
    }));
  };

  const handleShowRecipients = () => {
    const historyAction = () =>
      history.push(
        `/newsletter/${_id}/recipients?assignmentStatus=${NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED}`,
        {
          fromPath: `/newsletter/${_id}/`,
        }
      );

    if (isSentStatus) {
      historyAction();
    } else {
      handleUpdateNewsletter({}, historyAction);
    }
  };

  const handleShowExcludedRecipients = () => {
    const historyAction = () =>
      history.push(
        `/newsletter/${_id}/recipients?assignmentStatus=${NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.EXCLUDED}`,
        {
          fromPath: `/newsletter/${_id}/`,
        }
      );

    if (isSentStatus) {
      historyAction();
    } else {
      handleUpdateNewsletter({}, historyAction);
    }
  };

  const handlePushToVendorSettings = () => {
    history.push('/settings');
  };

  const getDateRangeInputValue = useCallback(() => {
    const formattedStartDate = promoStartDate
      ? moment(promoStartDate).format('DD/MM/YYYY')
      : '';
    const formattedEndDate = promoEndDate
      ? moment(promoEndDate).format('DD/MM/YYYY')
      : '';
    return `${formattedStartDate} - ${formattedEndDate}`;
  }, [promoStartDate, promoEndDate]);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid className={commonClasses.nlConstructorSection} container>
        <Typography className={commonClasses.fieldLabel}>
          {t('newsletter.email subject')}
        </Typography>
        <StyledInput
          variant="outlined"
          value={state.subject}
          placeholder={t('newsletter.email subject placeholder')}
          onChange={event =>
            handleChangeStateField('subject')(event.target.value)}
          inputProps={{
            maxLength: 120,
          }}
          fullWidth
        />
      </Grid>

      <Grid
        className={commonClasses.nlConstructorSection}
        container
        spacing={2}
      >
        <Grid container item xs={6}>
          <Button
            className={classes.recipientsButton}
            onClick={handleShowRecipients}
            color="default"
            endIcon={<PersonAddRoundedIcon />}
          >
            {t('newsletter.recipients title')}
          </Button>
          <RecipientsList
            assignedToAll={assignedToAll}
            assignedToCustomersCount={recipientCount}
            assignedToGroups={assignedGroups}
          />
        </Grid>

        <Grid container item xs={6}>
          <Button
            className={classes.recipientsButton}
            onClick={handleShowExcludedRecipients}
            color="secondary"
            endIcon={<PersonAddDisabledRoundedIcon />}
          >
            {t('newsletter.exclude users')}
          </Button>
          <RecipientsList
            assignedToCustomersCount={excludedRecipientCount}
            assignedToGroups={excludedGroups}
          />
        </Grid>
      </Grid>

      <Grid className={commonClasses.nlConstructorSection} container>
        <Accordion
          classes={{
            root: classes.vendorWorkingHoursRoot,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="vendor-working-hours"
            id="panel-of-working-hours"
          >
            <Typography className={commonClasses.boldText}>
              {t('newsletter.working hours title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  className={classes.editVendorSettingsButton}
                  color="secondary"
                  endIcon={<EditRoundedIcon fontSize="small" />}
                  onClick={handlePushToVendorSettings}
                >
                  {t('newsletter.sender name')}
                </Button>
                <Typography className={commonClasses.fieldLabel} gutterBottom>
                  {state.fromName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.editVendorSettingsButton}
                  color="secondary"
                  endIcon={<EditRoundedIcon fontSize="small" />}
                  onClick={handlePushToVendorSettings}
                >
                  {t('newsletter.sender email')}
                </Button>
                <Typography className={commonClasses.fieldLabel} gutterBottom>
                  {state.fromEmail}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  placement="top-start"
                  title={t('common.chars limitation', {
                    count: NL_CHARS_LIMIT / 2,
                  })}
                >
                  <Typography color="textSecondary" gutterBottom>
                    {t('newsletter.working hours')}
                  </Typography>
                </Tooltip>
                <StyledInput
                  variant="outlined"
                  value={staticInfo.workingHours}
                  placeholder={t('newsletter.working hours placeholder')}
                  onChange={handleChangeSupportInfoField('workingHours')}
                  inputProps={{
                    maxLength: NL_CHARS_LIMIT / 2,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  placement="top-start"
                  title={t('common.chars limitation', {
                    count: NL_CHARS_LIMIT / 2,
                  })}
                >
                  <Typography color="textSecondary" gutterBottom>
                    {t('newsletter.working hours caption')}
                  </Typography>
                </Tooltip>
                <StyledInput
                  variant="outlined"
                  value={staticInfo.workingHoursCaption}
                  placeholder={t(
                    'newsletter.working hours caption placeholder'
                  )}
                  onChange={handleChangeSupportInfoField('workingHoursCaption')}
                  inputProps={{
                    maxLength: NL_CHARS_LIMIT / 2,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary" gutterBottom>
                  {t('customers.phone')}
                </Typography>
                <StyledInput
                  variant="outlined"
                  value={staticInfo.phone}
                  onChange={handleChangeSupportInfoField('phone')}
                  inputProps={{
                    maxLength: 48,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary" gutterBottom>
                  {t('newsletter.contact email')}
                  <span className={classes.requiredStar}>*</span>
                </Typography>
                <StyledInput
                  variant="outlined"
                  value={staticInfo.email}
                  onChange={handleChangeSupportInfoField('email')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Tooltip
                  placement="top-start"
                  title={t('common.chars limitation', {
                    count: NL_CHARS_LIMIT * 2,
                  })}
                >
                  <Typography color="textSecondary" gutterBottom>
                    {t('newsletter.email disclaimer')}
                  </Typography>
                </Tooltip>
                <StyledInput
                  variant="outlined"
                  value={staticInfo.disclaimer}
                  multiline
                  minRows={4}
                  maxRows={8}
                  className={classes.multilineInput}
                  placeholder={t('newsletter.emailDisclaimerPlaceholder')}
                  onChange={handleChangeSupportInfoField('disclaimer')}
                  inputProps={{
                    maxLength: NL_CHARS_LIMIT * 2,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid
        className={commonClasses.nlConstructorSection}
        container
        wrap="nowrap"
      >
        <Grid item className={commonClasses.gridItem}>
          <Typography className={commonClasses.fieldLabel} gutterBottom>
            {t('newsletter.interface selection')}
          </Typography>
          <NewsletterColorPicker
            color={color}
            onChangeComplete={handleColorChange}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          className={commonClasses.gridItem}
        >
          <Typography className={commonClasses.fieldLabel} gutterBottom>
            {t('newsletter.logo')}
          </Typography>
          <NewsletterImageUploader
            imageUrl={logoUrl}
            imageCaption={t('newsletter.logo caption')}
            handleChangeImage={handleChangeProductGridField('logo')}
            handleDeleteImage={handleDeleteProductGridImages('logo', 'logoUrl')}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          className={commonClasses.gridItem}
        >
          <Typography className={commonClasses.fieldLabel} gutterBottom>
            {t('newsletter.banner image')}
          </Typography>
          <NewsletterImageUploader
            imageUrl={bannerUrl}
            imageCaption={t('newsletter.banner caption')}
            handleChangeImage={handleChangeProductGridField('banner')}
            handleDeleteImage={handleDeleteProductGridImages(
              'banner',
              'bannerUrl'
            )}
          />
        </Grid>
      </Grid>

      <Grid className={commonClasses.nlConstructorSection} container>
        <Typography className={commonClasses.fieldLabel} gutterBottom>
          {t('common.title')}
        </Typography>
        <StyledInput
          variant="outlined"
          value={title}
          inputProps={{
            maxLength: NL_CHARS_LIMIT,
          }}
          onChange={event =>
            handleChangeStateField('title')(event.target.value)}
          fullWidth
        />
      </Grid>

      <Grid
        className={commonClasses.nlConstructorSection}
        container
        direction="column"
        wrap="nowrap"
      >
        <Typography className={commonClasses.fieldLabel} paragraph>
          {t('newsletter.promo text')}
        </Typography>
        <Grid className={classes.promoTextContainer} container wrap="nowrap">
          <Grid
            className={classes.newsletterTextContainer}
            item
            container
            xs={8}
          >
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t('newsletter.promo text title')}
            </Typography>
            <Tooltip
              placement="top-start"
              title={t('common.chars limitation', { count: NL_PROMO_TEXT_CHARS_LIMIT })}
            >
              <InfoRoundedIcon className={classes.promoTextInfoIcon} color="disabled" fontSize="small" />
            </Tooltip>
            <StyledInput
              variant="outlined"
              value={promoText}
              placeholder={t('newsletter.promo text placeholder')}
              inputProps={{
                maxLength: NL_PROMO_TEXT_CHARS_LIMIT,
              }}
              multiline
              maxRows={4}
              className={classes.multilineInput}
              onChange={event =>
                handleChangeProductGridField('promoText')(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item container xs={4} direction="column">
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t('specialDays.tableHeaderDate')}
            </Typography>
            <Button
              variant="outlined"
              className={classes.dateRangeButton}
              startIcon={<DateRangeIcon />}
              onClick={handleOpenDateRangeModal}
              fullWidth
            >
              {getDateRangeInputValue()}
            </Button>
            <DatePickerModal
              isOpen={isDatePickerOpen}
              startDate={promoStartDate}
              endDate={promoEndDate}
              onClose={handleCloseDateRangeModal}
              handleChange={handleChangeProductGridField}
              handleClearDateRange={handleClearDateRange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MainSection.propTypes = {
  state: PropTypes.any.isRequired,
  setState: PropTypes.func.isRequired,
  handleUpdateNewsletter: PropTypes.func.isRequired,
};
