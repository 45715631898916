import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import Transition from '../shared/Transition';
import { DELETE_SPECIAL_DAY } from '../../graphql/mutation/specialDays';
import { NOTIFICATION_STATUS } from '../../helpers/constants';
import { SET_NOTIFICATION } from '../../graphql/mutation/user';

function DeleteDeliveryDay({ classes, isOpen, onDelete, onClose, deleteId }) {
  const { t } = useTranslation();
  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [deleteDeliveryDay] = useMutation(DELETE_SPECIAL_DAY, {
    onCompleted: () => {
      onClose();
    }
  });

  const handleDelete = async () => {
    try {
      await deleteDeliveryDay({
        variables: {
          dayId: deleteId
        }
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Day added'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
      onDelete();
      onClose();
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message:
                    error?.message ||
                    t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.modalContainer }}
      BackdropProps={{ classes: { root: classes.modalBackdrop } }}
      open={isOpen}
      TransitionComponent={Transition}
      aria-labelledby="status-modal"
      aria-describedby="status-modal-description"
      onClose={onClose}
    >
      <DialogTitle className={classes.modalTitle} id="status-modal">
        {t('specialDays.Delete day')}
      </DialogTitle>
      <DialogContent className={classes.modalMessageContainer}>
        <DialogContentText
          id="status-modal-description"
          className={classes.modalMessage}
        >
          {t('specialDays.Delete day message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        <Button
          onClick={onClose}
          color="primary"
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleDelete}
          color="primary"
        >
          {t('common.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDeliveryDay.defaultProps = {
  deleteId: null,
};

DeleteDeliveryDay.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteId: PropTypes.string,
};

export default DeleteDeliveryDay;
