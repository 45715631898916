import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import SentIcon from '@material-ui/icons/SendRounded';
import DeliveredIcon from '@material-ui/icons/MarkunreadMailboxRounded';
import OpenedIcon from '@material-ui/icons/DraftsRounded';
import ClickedIcon from '@material-ui/icons/TouchAppRounded';
import DelayIcon from '@material-ui/icons/TimelapseRounded';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';

import {
  SES_EVENT_TYPE,
  getEventMetadata,
} from 'components/Newsletter/localConstantsAndHelpers';

const useStyles = makeStyles(({ spacing, palette }) => ({
  dialogContainer: {
    height: '70%',
  },
  backdropRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  headingText: {
    padding: spacing(2),
  },
  bodyTooltip: {
    lineHeight: 1.4,
    margin: spacing(1, 0),
    opacity: 0.8,
  },
  dialogBody: {
    padding: spacing(0, 2),
    overflowX: 'hidden',
    maxHeight: 400,
  },
  dialogActions: {
    padding: spacing(1, 2, 2, 2),
  },
  logContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    border: palette.border.lightGrey,
    borderRadius: 4,
    minWidth: 400,
    marginBottom: spacing(1),
  },
  eventContainer: {
    marginBottom: spacing(1),
    padding: spacing(1),
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },
  eventHeader: {
    //
  },
  eventTime: {
    display: 'flex',
    alignItems: 'center',
  },
  muted: {
    opacity: 0.7,
  },
  eventTitle: {
    display: 'flex',
    marginLeft: spacing(0.5),
    fontWeight: 400,
  },
  eventInfo: {
    marginTop: spacing(0.5),
    display: 'flex',
  },
  eventSubInfo: {
    marginTop: spacing(0.5),
    opacity: 0.7,
  },
  clickLink: {
    display: 'inline-flex',
    marginLeft: spacing(1),
  },
  iconValid: {
    fontSize: 18,
    color: '#11d663',
    paddingRight: spacing(0.5),
  },
  iconInvalid: {
    color: '#f54065',
    fontSize: 18,
    paddingRight: spacing(0.5),
  },
  iconInfo: {
    color: '#3498DB',
    fontSize: 18,
    paddingRight: spacing(0.5),
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function RecipientEventLogModal({
  isOpen,
  handleCloseModal,
  events,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const eventData = events.map((event) => getEventMetadata(event, t));

  const iconMap = {
    [SES_EVENT_TYPE.SEND]: <SentIcon className={classes.iconValid} />,
    [SES_EVENT_TYPE.DELIVERY]: <DeliveredIcon className={classes.iconValid} />,
    [SES_EVENT_TYPE.OPEN]: <OpenedIcon className={classes.iconValid} />,
    [SES_EVENT_TYPE.CLICK]: <ClickedIcon className={classes.iconValid} />,
    [SES_EVENT_TYPE.DELIVERY_DELAY]: <DelayIcon className={classes.iconInfo} />,
    [SES_EVENT_TYPE.BOUNCE_TRANSIENT]: <ErrorIcon className={classes.iconInfo} />,
    [SES_EVENT_TYPE.REJECT]: <ErrorIcon className={classes.iconInvalid} />,
    [SES_EVENT_TYPE.BOUNCE]: <ErrorIcon className={classes.iconInvalid} />,
    [SES_EVENT_TYPE.COMPLAINT]: <ErrorIcon className={classes.iconInvalid} />,
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      classes={{
        container: classes.dialogContainer,
      }}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        },
      }}
      PaperProps={{
        elevation: 1,
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.headingText}>
        {t('newsletter.recipient events')}
      </DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <Grid container className={classes.logContainer}>
          {eventData.map((event) => {
            let Icon = () => iconMap[event.eventType] || null;
            if (event.eventType === SES_EVENT_TYPE.BOUNCE && event.isTransientBounce) {
              Icon = () => iconMap[SES_EVENT_TYPE.BOUNCE_TRANSIENT] || null;
            }

            return (
              <Grid key={event._id} className={classes.eventContainer}>
                <Grid container alignItems="center" className={classes.eventHeader}>
                  <Grid item className={classes.eventTime}>
                    <Icon className={classes.iconInfo} />
                    <Typography className={classes.muted} variant="body2">
                      {event.time}<span>:</span>
                    </Typography>
                  </Grid>
                  <Typography className={classes.eventTitle} variant="body1">
                    <span>{event.title}</span>
                  </Typography>
                </Grid>

                {event.eventType === SES_EVENT_TYPE.CLICK && (
                  <Typography className={classes.eventInfo} variant="body1">
                    {event.info}
                    <Link
                      className={classes.clickLink}
                      href={event.clickUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event.clickDomain}
                    </Link>
                  </Typography>
                )}

                {event.eventType === SES_EVENT_TYPE.DELIVERY_DELAY && (
                  <>
                    <Typography className={classes.eventInfo} variant="body1">
                      {event.delayReason}
                    </Typography>
                    <Typography className={classes.eventSubInfo} variant="body1">
                      {event.info}
                    </Typography>
                  </>
                )}

                {event.eventType === SES_EVENT_TYPE.BOUNCE && (
                  <Typography className={classes.eventSubInfo} variant="body1">
                    {event.bounceType}: {event.info}
                  </Typography>
                )}

                {event.eventType === SES_EVENT_TYPE.COMPLAINT && event.info && (
                  <Typography className={classes.eventSubInfo} variant="body1">
                    {event.info}
                  </Typography>
                )}
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleCloseModal}
          color="secondary"
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RecipientEventLogModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
};
