import { gql } from '@apollo/client';

export const CREATE_SALES_REP = gql`
  mutation createSalesRep($name: String!, $email: String!, $password: String!) {
    createSalesRep(name: $name, email: $email, password: $password)
  }
`;

export const DELETE_SALES_REP = gql`
  mutation deleteSalesRep($_id: ID!) {
    deleteSalesRep(_id: $_id)
  }
`;

export const ASSIGN_CUSTOMERS_TO_SALES_REP = gql`
mutation assignCustomersForSalesRep($_id: ID!, $customerIds: [String]!) {
    assignCustomersForSalesRep(_id: $_id, customerIds: $customerIds) {
        customerIds
        failedCustomerIds
        existingCustomerIds
  }
}
`;

export const DELETE_CUSTOMERS_FROM_SALES_REP = gql`
mutation unassignCustomersForSalesRep($_id: ID!, $customerIds: [String]!) {
    unassignCustomersForSalesRep(_id: $_id, customerIds: $customerIds) {
        customerIds
        failedCustomerIds
  }
}
`;
