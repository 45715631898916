/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import axios from 'axios';

import {
  GET_USER,
  NETWORK_STATUS,
  GET_NOTIFICATION,
  IMPERSONATE_ACCOUNT,
  THEME_TYPE,
} from 'graphql/query/user';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import { activeAccountIdVar } from './apolloReactiveVariables';

const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const authUrl = process.env.REACT_APP_AUTH0_POST_URL;
const userUrl = process.env.REACT_APP_AUTH0_USER_URL;
const realm = process.env.REACT_APP_AUTH0_REALM;
const realmDev = process.env.REACT_APP_AUTH0_REALM_DEV;
const environment = process.env.REACT_APP_ENV_NAME;

export const typeDefs = gql`
  extend type Query {
    user: User!
    networkStatus: NetworkStatus
    notification: Notification
    theme: Theme
  }
  type Impersonate {
    impersonateId: String
    name: String
  }
  type Theme {
    type: String
  }
  type User {
    userData: UserData
    accessToken: String
    refreshToken: String
    idToken: String
    error: String
    isRememberChecked: Boolean
  }
  type UserData {
    name: String
    email: String
    userId: String
    picture: String,
    isAdmin: Boolean
  }
  type NetworkStatus {
    isOnline: Boolean
  }
  type Notification {
    timeout: Int
    message: String
    type: NotificationType
    isOpen: Boolean
    isManual: Boolean
  }
  extend type Mutation {
    login(email: String, password: String, isRememberChecked: Boolean): Boolean!
    checkUser: User!
    isOnline(isOnline: Boolean):  Boolean!
    setNotification(
      timeout: Int
      message: String
      type: NotificationType
      isOpen: Boolean
      isManual: Boolean
    ): Boolean!
    changeImpersonateId(impersonateId: String, name: String): Boolean!
    changeTheme(type: String): Boolean!
  }
`;

const fetchUser = async (accessToken, cache) => {
  let userResponse;
  try {
    userResponse =
    await axios.get(userUrl,
      { headers: { authorization: `Bearer ${accessToken}` } });
  } catch (error) {
    throw new Error(error?.response?.data?.error_description || error);
  }
  const adminField = userResponse.data.profile.isAdmin || false;
  const accountId = userResponse.data.profile.accounts[0];

  const impersonateResponse = cache.readQuery({ query: IMPERSONATE_ACCOUNT });
  const impersonateId = impersonateResponse?.impersonate.impersonateId;

  activeAccountIdVar(impersonateId || accountId);

  const userData = {
    __typename: 'UserData',
    name: userResponse.data.profile.name,
    email: userResponse.data.profile.email.email,
    userId: userResponse.data.profile.userId,
    isAdmin: (typeof (adminField) === 'boolean' && adminField) ||
    (typeof (adminField) === 'string' && adminField === 'true'),
    accountId,
    picture: userResponse.data.picture,
  };
  return userData;
};

export const initialUserData = {
  user: {
    __typename: 'User',
    userData: {
      __typename: 'UserData',
      name: null,
      email: null,
      userId: null,
      picture: null,
      isAdmin: false,
      accountId: null,
    },
    accessToken: null,
    refreshToken: null,
    idToken: null,
    error: null,
    isRememberChecked: true,
  }
};

export const resolvers = {
  Query: {},

  Mutation: {
    login: async (_, { email, password, isRememberChecked }, { cache }) => {
      let signInResponse;
      let userDataResponse;
      try {
        signInResponse = await axios.post(authUrl, {
          username: email,
          password,
          grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
          client_id: clientID,
          realm: environment === 'prod' ? realm : realmDev,
          scope: 'offline_access'
        });
        userDataResponse = await fetchUser(signInResponse.data.access_token, cache);
      } catch (error) {
        cache.writeQuery({
          query: GET_NOTIFICATION,
          data: {
            notification: {
              timeout: 4000,
              message: error?.response?.data?.error_description || error,
              type: NOTIFICATION_STATUS.ALERT,
              isOpen: true,
              isManual: false,
              __typename: 'Notification',
            }
          }
        });
        return {
          email,
          isRememberChecked,
          error: error?.response?.data
        };
      }
      const userFullData = {
        user: {
          __typename: 'User',
          accessToken: signInResponse.data.access_token,
          refreshToken: signInResponse.data.refresh_token,
          idToken: signInResponse.data.id_token,
          error: null,
          isRememberChecked,
          userData: {
            __typename: 'UserData',
            name: userDataResponse.name,
            email: userDataResponse.email,
            userId: userDataResponse.userId,
            picture: userDataResponse.picture,
            isAdmin: userDataResponse.isAdmin || false,
            accountId: userDataResponse.accountId,
          }
        }
      };
      if (isRememberChecked) {
        cache.writeQuery({ query: GET_USER, data: userFullData });
      } else {
        await sessionStorage.setItem('user', JSON.stringify(userFullData));
        cache.writeQuery({
          query: GET_USER,
          data: {
            ...initialUserData,
            user: {
              ...initialUserData.user,
              isRememberChecked
            }
          }
        });
      }
      return userFullData;
    },
    // eslint-disable-next-line consistent-return
    checkUser: async (_, args, { cache }) => {
      const data = cache.readQuery({ query: GET_USER });
      const sessionStorageData = JSON.parse(sessionStorage.getItem('user'));
      const { networkStatus: { isOnline } } = cache.readQuery({ query: NETWORK_STATUS });

      if (isOnline === false) {
        return data;
      }
      if (sessionStorageData || data) {
        const user = sessionStorageData?.user || data.user;
        if (user && user.refreshToken) {
          let refreshTokenResponse;
          let userDataResponse;
          try {
            userDataResponse = await fetchUser(user.accessToken, cache);
            const userFullData = {
              user: {
                __typename: 'User',
                accessToken: user.accessToken,
                refreshToken: user.refreshToken,
                idToken: user.idToken,
                error: null,
                isRememberChecked: !sessionStorageData,
                userData: {
                  __typename: 'UserData',
                  name: userDataResponse.name,
                  email: userDataResponse.email,
                  userId: userDataResponse.userId,
                  picture: userDataResponse.picture,
                  isAdmin: userDataResponse.isAdmin || false,
                  accountId: userDataResponse.accountId,

                }
              }
            };
            if (sessionStorageData) {
              sessionStorage.setItem('user', JSON.stringify(userFullData));
            } else {
              cache.writeQuery({ query: GET_USER, data: userFullData });
            }
            return userFullData;
          } catch (error) {
            // NOTE: error with accessToken try to refresh

            try {
              refreshTokenResponse = await axios.post(authUrl, {
                grant_type: 'refresh_token',
                client_id: clientID,
                realm: environment === 'prod' ? realm : realmDev,
                refresh_token: user.refreshToken,
              });
              userDataResponse = await fetchUser(refreshTokenResponse.data.access_token, cache);
            } catch (refreshTokenError) {
              cache.writeQuery({
                query: GET_USER,
                data: { user: { ...initialUserData.user, error: 'Error in login. Try again' } }
              });
              return initialUserData;
            }
          }

          const userFullData = {
            user: {
              __typename: 'User',
              accessToken: refreshTokenResponse.data.access_token,
              refreshToken: user.refresh_token,
              idToken: refreshTokenResponse.data.id_token,
              error: null,
              isRememberChecked: !sessionStorageData,
              userData: {
                __typename: 'UserData',
                name: userDataResponse.name,
                email: userDataResponse.email,
                userId: userDataResponse.userId,
                picture: userDataResponse.picture,
                isAdmin: userDataResponse.isAdmin || false,
                accountId: userDataResponse.accountId,
              }
            }
          };
          if (sessionStorageData) {
            sessionStorage.setItem('user', JSON.stringify(userFullData));
          } else {
            cache.writeQuery({ query: GET_USER, data: userFullData });
          }
          return userFullData;
        } else {
          cache.writeQuery({
            query: GET_USER,
            data: {
              ...initialUserData,
            }
          }
          );
          return initialUserData;
        }
      }
    },
    isOnline: async (_, { isOnline }, { cache }) => {
      const data = {
        networkStatus: {
          __typename: 'NetworkStatus',
          isOnline
        }
      };
      cache.writeQuery({ query: NETWORK_STATUS, data });
      return data;
    },
    setNotification: async (
      _,
      {
        timeout, message, type, isOpen, isManual
      },
      { cache }
    ) => {
      const data = {
        notification: {
          __typename: 'Notification',
          timeout,
          message,
          type,
          isOpen,
          isManual: isManual || false
        },
      };
      cache.writeQuery({ query: GET_NOTIFICATION, data });
    },
    changeImpersonateId: (
      _,
      { impersonateId, name },
      { cache }
    ) => {
      cache.writeQuery({
        query: IMPERSONATE_ACCOUNT,
        data: {
          impersonate: {
            impersonateId,
            name,
            __typename: 'Impersonate',
          }
        }
      });
    },
    changeTheme: (
      _,
      { type },
      { cache }
    ) => {
      cache.writeQuery({
        query: THEME_TYPE,
        data: {
          theme: {
            type,
            __typename: 'Theme',
          }
        }
      });
    }
  }
};
