import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    verticalAlign: 'middle'
  },
  input: {
    height: 20,
    padding:
    `${theme.spacing(1)}px ${theme.spacing(1.75)}px ${theme.spacing(1)}px ${theme.spacing(0.75)}px`,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    textOverflow: 'ellipsis'
  },
  error: {
    border: `1.5px solid ${theme.palette.error.main}`
  },
  disabledInput: {
    backgroundColor: 'transparent'
  },
}));

export default function DetailPanelInput(props) {
  const classes = useStyles();
  const displayTooltip = props.withTooltip && props.disabled;

  return (
    <Tooltip
      title={displayTooltip ? props.value : ''}
      placement="top"
    >
      <TextField
        name={props.name}
        classes={{ root: classes.textFieldRoot }}
        InputProps={{
          classes: {
            input: classNames(classes.input, props.error && classes.error),
            disabled: classes.disabledInput
          },
          disableUnderline: true
        }}
        disabled={props.disabled}
        value={props.value || ''}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
      />
    </Tooltip>
  );
}

DetailPanelInput.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  withTooltip: PropTypes.bool,
};

DetailPanelInput.defaultProps = {
  disabled: false,
  onChange: () => {},
  error: null,
  fullWidth: false,
  withTooltip: false,
  value: ''
};
