import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    width: 560,
    borderRadius: 12
  },
  modalTitle: {
    padding: theme.spacing(1.5)
  },
  backButton: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.darkGray
  },
  modalContent: {
    padding: theme.spacing(6),
  }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ModalLayout(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    modalTitle,
    children,
    isOpen,
    handleClose,
    withBackButton,
    handleMoveBack,
    classes: {
      containerClass = '',
      contentClass = ''
    }
  } = props;

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      classes={{ paper: classNames(classes.modalContainer, containerClass) }}
      aria-labelledby="product-dialog-layout"
      aria-describedby="product-dialog-description"
    >
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        className={classes.modalTitle}
        id="product-dialog-layout"
      >
        <Grid item container xs={2}>
          {withBackButton ? (
            <Button
              onClick={handleMoveBack}
              className={classes.backButton}
              startIcon={<BackIcon color="secondary" fontSize="small" />}
            >
              {t('common.back')}
            </Button>
          ) :
            ''}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" align="center">
            {modalTitle}
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" xs={2}>
          <IconButton onClick={handleClose}>
            <CloseIcon color="secondary" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent className={classNames(classes.modalContent, contentClass)}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

ModalLayout.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  withBackButton: PropTypes.bool,
  handleMoveBack: PropTypes.func,
  classes: PropTypes.shape({
    containerClass: PropTypes.string,
  }),
};

ModalLayout.defaultProps = {
  withBackButton: false,
  handleMoveBack: noop,
  classes: {
    containerClass: null,
  }
};
