import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/client';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';
import { GET_CUSTOMER_GROUPS } from '../../graphql/query/groups';

function EditGroups({ data, toggleGroup, classes }) {
  const { t } = useTranslation();
  const { data: customerGroupsData, isLoading } = useQuery(GET_CUSTOMER_GROUPS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <>
      <Typography variant="h2">{t('specialDays.editGroups')}</Typography>
      {isLoading ? (
        <Typography variant="h2">Loading...</Typography>
      ) : (
        customerGroupsData?.allCustomerGroups?.map((group) => {
          const isSelected = data?.includes(group._id);
          return (
            <Chip
              key={group._id}
              label={group.name}
              color={isSelected ? 'primary' : 'default'}
              onClick={() => toggleGroup(group._id)}
              variant={isSelected ? 'default' : 'outlined'}
              className={classes.chip}
            />
          );
        })
      )}
    </>
  );
}

EditGroups.propTypes = {
  data: PropTypes.array.isRequired,
  toggleGroup: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

EditGroups.defaultProps = {
  classes: {},
};

export default EditGroups;
