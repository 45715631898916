import { makeStyles } from '@material-ui/core/styles';

export const useSharedTableStyles = makeStyles(
  ({ spacing, palette, transitions }) => ({
    tableBodyRow: {
      height: 56,
      borderBottom: palette.border.lightGrey,
    },
    tableCell: {
      padding: spacing(1, 1.5),
      maxWidth: 150,
      color: palette.secondary.textBlack,
      borderBottom: 'none',
    },
    tableLeftPadding: {
      paddingLeft: `${spacing(4)}px !important`,
    },
    actionButtonContainer: {
      width: 120,
    },
    tableActionButton: {
      minWidth: 0,
      width: 32,
      height: 32,
      boxShadow: palette.customShadows.button,
      borderRadius: 6,
      padding: 0,
      margin: spacing(0, 0.5),
      backgroundColor: palette.background.button,
    },
    tableActionButtonIcon: {
      width: 20,
      height: 20,
      color: palette.secondary.darkGray,
    },
    highlightRow: {
      animation: `$highlighting 1000ms ${transitions.easing.easeOut}`,
    },
    '@keyframes highlighting': {
      '0%': {
        backgroundColor: palette.primary.main,
      },
      '100%': {
        backgroundColor: palette.background.default,
      },
    },
  })
);
