import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getNewsletterVendorSettingsSchema } from 'components/Settings/ValidationSchemas';
import SettingsInput from 'components/Settings/SettingsInput';
import CustomCheckbox from 'components/shared/CustomCheckbox';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginTop: theme.spacing(3),
    height: 50,
    borderRadius: 8,
    boxShadow: 'none',
  },
}));

export default function NewsletterInformationEditForm({
  newsletterData,
  handleUpdateVendor,
  setEditMode,
  isAdmin
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmitMainInformation = values => {
    handleUpdateVendor({
      preferences: {
        newsletter: {
          ...newsletterData,
          ...values,
          fromEmail: `${values.fromEmail}@contact.chefslist.de`,
        },
      },
    });
    setEditMode(false);
  };

  const handleFromEmailInput = (e, formikChangeCB) => {
    e.persist();
    const filteredValue = e.target.value
      .replace(/@.*/g, '')
      .replace(/\s+/g, '_');

    e.target.value = filteredValue;

    formikChangeCB(e);
  };

  return (
    <Formik
      initialValues={{
        ...newsletterData,
      }}
      enableReinitialize
      onSubmit={values => handleSubmitMainInformation(values)}
      validateOnChange={false}
      validationSchema={getNewsletterVendorSettingsSchema(isAdmin)}
    >
      {({ values, handleChange, handleSubmit, errors, handleBlur }) => (
        <Grid container item xs={8} direction="column" wrap="nowrap">
          {isAdmin && (
            <Grid>
              <CustomCheckbox
                label={t('newsletter.notifications.newsletter enable toggle title')}
                checked={values.enabled}
                name="enabled"
                classes={classes}
                setFieldValue={(name, checked) => {
                  handleChange({
                    target: {
                      name,
                      value: checked,
                    },
                  });
                }}
              />
            </Grid>
          )}
          <SettingsInput
            value={values.fromName}
            name="fromName"
            label={t('settings.email sender')}
            onChange={handleChange}
            error={errors.fromName}
            handleBlur={handleBlur}
          />
          <SettingsInput
            value={values.fromEmail?.replace(/@.*/g, '')}
            name="fromEmail"
            label={t('common.from email')}
            onChange={(e) => handleFromEmailInput(e, handleChange)}
            error={errors.fromEmail}
            handleBlur={handleBlur}
            inputEndAdornment="@contact.chefslist.de"
          />
          <Typography variant="body2" color="textSecondary">
            *{t('newsletter.vendor from email caption')}
          </Typography>
          <Button
            className={classes.actionButton}
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            {t('common.confirm')}
          </Button>
        </Grid>
      )}
    </Formik>
  );
}

NewsletterInformationEditForm.propTypes = {
  newsletterData: PropTypes.object.isRequired,
  handleUpdateVendor: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
