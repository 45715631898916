/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($campaign: CampaignInput!) {
    addOrUpdateCampaign(campaign: $campaign) {
      _id
      title
      vendorId
      message
      schedule {
        repeat
        time {
          minute
          hour
        }
        endAfterCount
        startDate
        endDate
      }
      assignedToAll
      assignedToCustomers
      assignedToGroups {
        _id
      }
      enabled
      next
      status
      sender
      campaignType
      recipients {
        _id
        vendorSpecificId
        contactData {
          businessName
          street
          houseNumber
          city
          label
        }
        contacts {
          _id
          name
          phone
          additionalPhones
          email
          status
          disabled
        }
      }
    }
  }
`;

export const EDIT_CAMPAIGN_RECIPIENTS = gql`
  mutation EditCampaignRecipients(
    $campaignId: ID!
    $actionType: String!
    $connectionIds: [RecipientIds]
    $groups: [String]
    $assignedToCustomers: [String]
    $assignedToAll: Boolean
  ) {
    editCampaignRecipients(
      campaignId: $campaignId
      actionType: $actionType
      connectionIds: $connectionIds
      assignedToCustomers: $assignedToCustomers
      groups: $groups
      assignedToAll: $assignedToAll
    ) {
      _id
      title
      vendorId
      message
      schedule {
        repeat
        time {
          minute
          hour
        }
        endAfterCount
        startDate
        endDate
      }
      assignedToAll
      assignedToCustomers
      assignedToGroups {
        _id
      }
      enabled
      next
      status
      sender
      campaignType
      recipients {
        _id
        vendorSpecificId
        contactData {
          businessName
          street
          houseNumber
          city
          label
        }
        contacts {
          _id
          name
          phone
          additionalPhones
          email
          status
          disabled
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_STATUS = gql`
  mutation UpdateCampaignStatus($campaignId: ID!, $status: String!) {
    updateCampaignStatus(campaignId: $campaignId, status: $status) {
      _id
      title
      vendorId
      message
      schedule {
        repeat
        time {
          minute
          hour
        }
        endAfterCount
        startDate
        endDate
      }
      assignedToAll
      assignedToCustomers
      assignedToGroups {
        _id
      }
      enabled
      next
      status
      sender
      campaignType
      recipients {
        _id
        vendorSpecificId
        contactData {
          businessName
          street
          houseNumber
          city
          label
        }
        contacts {
          _id
          name
          phone
          additionalPhones
          email
          status
          disabled
        }
      }
    }
  }
`;

export const SEND_TEST_SMS = gql`
  mutation SendTestSms($sender: String!, $to: String!, $message: String) {
    sendTestSms(sender: $sender, to: $to, message: $message)
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($campaignIds: [ID]!) {
    deleteCampaign(campaignIds: $campaignIds) {
      _id
      title
      vendorId
      message
      schedule {
        repeat
        time {
          minute
          hour
        }
        endAfterCount
        startDate
        endDate
      }
      assignedToAll
      assignedToCustomers
      assignedToGroups {
        _id
      }
      enabled
      next
      status
      sender
      campaignType
      recipients {
        _id
        vendorSpecificId
        contactData {
          businessName
          street
          houseNumber
          city
          label
        }
        contacts {
          _id
          name
          phone
          additionalPhones
          email
          status
          disabled
        }
      }
    }
  }
`;

export const EDIT_CAMPAIGN_RECIPIENT_CONTACT_STATUS = gql`
  mutation EditCampaignRecipientContactStatus(
    $campaignId: ID!
    $actionType: String!
    $connectionId: RecipientIds
  ) {
    changeStatusForCampaignRecipient(
      campaignId: $campaignId
      actionType: $actionType
      connectionId: $connectionId
    ) {
      _id
      name
      role
      phone
      additionalPhones
      email
      inviteLink
      registrationCode
      status
      userMeta {
        name
        email
      }
      disabled
    }
  }
`;
