/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  GET_CUSTOM_VENDOR_GROUPS,
  SEARCH_CUSTOMER_GROUPS,
} from 'graphql/query/groups';
import {
  CREATE_CUSTOM_VENDOR_GROUP,
  DELETE_CUSTOM_VENDOR_GROUP,
} from 'graphql/mutation/groups';
import { GET_USER, IMPERSONATE_ACCOUNT } from 'graphql/query/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import AddIcon from '@material-ui/icons/AddCircleOutline';

import {
  NOTIFICATION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
import TableLayout from 'components/shared/TableLayout';
import CreateCustomGroupModal from 'components/CustomGroups/CreateCustomGroupModal';
import GroupsTableRow from 'components/CustomGroups/GroupsTableRow';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  addGroupActionButton: {
    height: 45,
    border: palette.border.lightGrey,
    fontWeight: typography.fontWeightRegular,
    textWrap: 'nowrap',
    padding: spacing(1, 2),
    marginLeft: spacing(2),
    borderRadius: 8,
  },
  groupsTableLoader: {
    width: '100%',
  },
  tableLeftPadding: {
    paddingLeft: spacing(4),
  },
  totalResultColumn: {
    width: 420,
  },
}));

const DEFAULT_GROUP_STATE = {
  name: '',
  color: '#FF5100',
  isOpen: false,
};

export default function Groups() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const queries = queryString.parse(location.search);

  const groupRef = useRef(null);
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

  const [
    createCustomVendorGroup,
    { loading: isCreateCustomGroupLoading },
  ] = useMutation(CREATE_CUSTOM_VENDOR_GROUP);
  const [
    deleteCustomGroupMutation,
    { loading: deleteMutationIsLoading },
  ] = useMutation(DELETE_CUSTOM_VENDOR_GROUP);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
    selectedId: null,
  });
  const [newGroupState, setNewGroupState] = useState(DEFAULT_GROUP_STATE);
  const [showSearchResults, setShowSearchResult] = useState(false);

  const { data: user } = useQuery(GET_USER);
  const {
    data: {
      impersonate: { impersonateId = null },
    },
  } = useQuery(IMPERSONATE_ACCOUNT);

  const [
    getVendorGroups,
    { loading: groupsLoading, error: groupsError, data },
  ] = useLazyQuery(GET_CUSTOM_VENDOR_GROUPS, {
    variables: {
      withAssignedCustomers: true,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [
    searchCustomVendorGroups,
    {
      loading: searchLoading,
      error: searchError,
      data: searchData,
      variables: { searchPhrase: searchPhraseInRequest } = {},
    },
  ] = useLazyQuery(SEARCH_CUSTOMER_GROUPS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const accountId = user.user?.userData?.accountId;
  const groupsData = data && data.getCustomVendorGroups;
  const vendorId = impersonateId || accountId;

  //
  //  ------ USE EFFECTS ------
  //

  useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const selectedId = queries.id || null;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getVendorGroups({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
        withAssignedCustomers: true,
      },
    });
    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        selectedId,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
  }, []);

  useEffect(() => {
    const totalPages = groupsData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (groupsData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [groupsData]);

  //
  //  ------ GROUP HANDLERS ------
  //

  const handleCreateCustomGroup = async () => {
    const { name = '', color } = newGroupState;

    if (name.length > 35) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.chars limitation', { count: 35 }),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    try {
      const response = await createCustomVendorGroup({
        variables: {
          name,
          color,
          // translations: {
          //   de: 'Default german translation',
          //   en: null,
          // },
        },
      });
      const createdGroupId =
        response.data && response.data.createCustomVendorGroup._id;
      mixPanel.track('Vendor Custom Groups - Create Custom Group', {
        createdGroupId,
        vendorId,
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('groups.create custom group success', {
            name,
          }),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
      if (createdGroupId) {
        history.push(`group/${createdGroupId}`);
      }
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleDeleteGroup = async (groupId, name) => {
    try {
      await deleteCustomGroupMutation({
        variables: { groupId },
        refetchQueries: [
          showSearchResults
            ? {
              query: SEARCH_CUSTOMER_GROUPS,
              variables: {
                searchPhrase: searchPhraseInRequest,
              },
            }
            : {
              query: GET_CUSTOM_VENDOR_GROUPS,
              variables: {
                after: state.page + 1,
                pageSize: state.rowsPerPage,
                sortBy: state.sortBy,
                sortOrder: state.sortOrder,
                withAssignedCustomers: true,
              },
            },
        ],
        update: (cache, { data: deleteCustomVendorGroup }) => {
          if (deleteCustomVendorGroup) {
            cache.writeQuery({
              query: GET_CUSTOM_VENDOR_GROUPS,
              variables: {
                after: state.page + 1,
                pageSize: state.rowsPerPage,
                sortBy: state.sortBy,
                sortOrder: state.sortOrder,
                withAssignedCustomers: true,
              },
              data: {
                getCustomVendorGroups: {
                  ...groupsData,
                  groups: groupsData.groups.filter(
                    group => group._id !== groupId
                  ),
                },
              },
            });
          }
        },
      });
      mixPanel.track('Vendor Custom Groups - Delete Custom Group', {
        groupId,
        name,
        vendorId,
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('groups.delete group success message', {
            name,
          }),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleOpenNewGroupModal = () => {
    setNewGroupState({ ...newGroupState, isOpen: true });
  };

  const handleCloseNewGroupModal = () => {
    setNewGroupState(DEFAULT_GROUP_STATE);
  };

  //
  //  ------ PAGE HANDLERS ------
  //

  const handleChangePage = async (event, page) => {
    if (
      groupsData &&
      (groupsData.hasNextPage || page + 1 < groupsData.totalPages)
    ) {
      try {
        await getVendorGroups({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
            withAssignedCustomers: true,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page, selectedId: null });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
        id: '',
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getVendorGroups({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
          withAssignedCustomers: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      rowsPerPage,
      page: 0,
      selectedId: null,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getVendorGroups({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
          withAssignedCustomers: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const handleSearchBar = async searchPhrase => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await searchCustomVendorGroups({
        variables: {
          searchPhrase,
        },
      });
    } catch (error) {
      console.error('SEARCH_ERROR', error.message);
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: t('common.name'),
        field: 'name',
        sortable: true,
        columnStyles: classes.tableLeftPadding,
      },
      {
        title: t('common.color'),
        field: 'color',
        sortable: false,
      },
      {
        title: t('groups.assigned customers'),
        field: 'customers',
        sortable: false,
      },
    ],
    [classes]
  );

  const { page, rowsPerPage, sortBy, sortOrder, selectedId } = state;

  const hasError =
    groupsError ||
    (!groupsLoading && !groupsData) ||
    (groupsData && !groupsData?.groups?.length);

  const searchResultHasError =
    (showSearchResults && !searchData?.searchCustomerGroups?.length) ||
    searchError;
  const emptyTableData =
    (hasError && !showSearchResults) || searchResultHasError;
  const tableIsLoading = groupsLoading || searchLoading;

  const totalResults = () => {
    if (showSearchResults) {
      return searchResultHasError ? 0 : searchData.searchCustomerGroups.length;
    } else {
      return hasError ? 0 : groupsData?.totalResults;
    }
  };

  return (
    <>
      <TableLayout
        title={t('groups.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.LISTS}
        emptyTableData={emptyTableData}
        emptyTableDataMessage={t('groups.no results')}
        tableIsLoading={tableIsLoading}
        withSearchBar
        searchPlaceholder={t('groups.search placeholder')}
        handleSearchBar={handleSearchBar}
        handleShowSearchResult={setShowSearchResult}
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={totalResults() || 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        headerActions={[
          <Button
            key="add group"
            onClick={handleOpenNewGroupModal}
            className={classes.addGroupActionButton}
            variant="outlined"
            color="default"
            startIcon={<AddIcon color="primary" />}
          >
            {t('groups.add group')}
          </Button>,
        ]}
        headerAdditionalActions={
          <Grid container>
            {deleteMutationIsLoading && (
              <LinearProgress
                className={classes.groupsTableLoader}
                color="primary"
              />
            )}
          </Grid>
        }
      >
        {!showSearchResults &&
          groupsData &&
          groupsData.groups.map(rowData => (
            <GroupsTableRow
              key={rowData._id}
              selectedGroup={selectedId}
              rowData={rowData}
              executeScroll={() => scrollToRef(groupRef)}
              groupRef={groupRef}
              rowsPerPage={+queries.rowsPerPage}
              handleDeleteGroup={handleDeleteGroup}
              disableActionButtons={deleteMutationIsLoading}
            />
          ))}
        {showSearchResults &&
          !searchResultHasError &&
          searchData?.searchCustomerGroups?.map(rowData => (
            <GroupsTableRow
              key={rowData._id}
              selectedGroup={selectedId}
              rowData={rowData}
              executeScroll={() => scrollToRef(groupRef)}
              groupRef={groupRef}
              rowsPerPage={+queries.rowsPerPage}
              handleDeleteGroup={handleDeleteGroup}
              disableActionButtons={deleteMutationIsLoading}
            />
          ))}
      </TableLayout>
      <CreateCustomGroupModal
        isOpen={newGroupState.isOpen}
        handleClose={handleCloseNewGroupModal}
        state={newGroupState}
        setState={setNewGroupState}
        isLoading={isCreateCustomGroupLoading}
        handleCreateCustomGroup={handleCreateCustomGroup}
      />
    </>
  );
}
