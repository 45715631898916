/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_LISTS } from 'graphql/query/lists';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { CREATE_NEW_LIST, DELETE_LIST } from 'graphql/mutation/lists';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/AddCircleOutline';

import TableLayout from 'components/shared/TableLayout';
import ListsTableRow from 'components/ListsTable/ListsTableRow';
import {
  NOTIFICATION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  tableSearchBarButtons: {
    height: 45,
    color: theme.palette.secondary.darkGray,
    border: theme.palette.border.lightGrey,
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableLeftPadding: {
    paddingLeft: theme.spacing(5)
  },
  totalResultColumn: {
    width: 420
  },
  creationDateTitle: {
    color: theme.palette.secondary.darkGray
  },
}));

export default function Lists() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const queries = queryString.parse(location.search);

  const listRef = React.useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const [deleteVendorListMutation] = useMutation(DELETE_LIST);
  const [createVendorList] = useMutation(CREATE_NEW_LIST);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
    selectedId: null
  });

  const [getVendorLists, {
    loading: listsLoading,
    error: listsError,
    data,
  }] = useLazyQuery(
    GET_LISTS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const listsData = data && data.vendorLists;

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const selectedId = queries.id || null;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getVendorLists({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      }
    });
    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        selectedId,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = listsData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && (totalPages <= state.page);
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(state.rowsPerPage);

    if (listsData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        })
      });
      window.location.reload();
    }
  }, [listsData]);

  const createList = async () => {
    try {
      const response = await createVendorList({
        variables: { title: 'New List' },
      });
      mixPanel.track('Create New List');
      const id = response.data && response.data.createVendorList._id;
      if (id) {
        history.push(`list/${id}`);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteList = async (listId, listTitle) => {
    try {
      await deleteVendorListMutation({
        variables: { listId },
        refetchQueries: [{
          query: GET_LISTS,
          variables: {
            after: state.page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        }],
      });
      mixPanel.track('Delete Vendor List', { listId });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('lists.delete list success message', {
            listName: listTitle === 'New List' ?
              t('lists.new list title') : listTitle
          }),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleChangePage = async (event, page) => {
    if (listsData && (listsData.hasNextPage || page + 1 < listsData.totalPages)) {
      try {
        await getVendorLists({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page, selectedId: null });
    history.push({
      pathname: currentPath,
      search:
          queryString.stringify({ ...queryString.parse(location.search), page, id: '' })
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      await getVendorLists({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, rowsPerPage, page: 0, selectedId: null
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0
      })
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getVendorLists({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, sortOrder, sortBy: sortLabel, page: 0
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder
      })
    });
  };

  const tableColumns = [
    // FIXME: temporary hide this column
    // {
    //   title: t('lists.number'),
    //   field: '_id',
    //   sortable: false,
    //   columnStyles: classes.tableLeftPadding,
    // },
    {
      title: t('common.created'),
      field: 'createdAt',
      sortable: true,
      columnStyles: classes.tableLeftPadding
    },
    {
      title: t('lists.assignment'),
      field: 'assignment',
      sortable: false,
    },
    {
      title: t('lists.products'),
      field: 'products',
      sortable: true,
      columnStyles: classes.totalResultColumn,
    },
  ];

  const {
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    selectedId
  } = state;

  const hasError = listsError || (!listsLoading && !listsData)
    || (listsData && !listsData.lists.length);

  return (
    <TableLayout
      title={t('lists.title')}
      columns={tableColumns}
      skeletonType={TABLE_SKELETON_TYPES.LISTS}
      emptyTableData={hasError}
      emptyTableDataMessage={t('lists.empty lists data')}
      tableIsLoading={listsLoading}
      withActions
      page={page}
      rowsPerPage={rowsPerPage}
      totalResults={(listsData && listsData.totalResults) || 0}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      sortBy={sortBy}
      sortOrder={sortOrder}
      handleSortRows={handleSortRows}
      headerActions={[
        <Button
          key="add list"
          onClick={createList}
          className={classes.tableSearchBarButtons}
          variant="outlined"
          color="default"
          startIcon={<AddIcon color="primary" />}
        >
          {t('lists.add list')}
        </Button>,
      ]}
    >
      {listsData && listsData.lists
        .map((rowData) => (
          <ListsTableRow
            key={rowData._id}
            selectedList={selectedId}
            rowData={rowData}
            executeScroll={() => scrollToRef(listRef)}
            listRef={listRef}
            rowsPerPage={+queries.rowsPerPage}
            handleDeleteList={handleDeleteList}
          />
        ))}
    </TableLayout>
  );
}
