import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  tabs: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(2, 0),
    '& .Mui-selected': {
      '& .MuiTab-wrapper': {
        backgroundColor: '#0F1217',
        color: '#fff',
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      padding: theme.spacing(0),
      maxWidth: 'none',
      marginRight: theme.spacing(1),
      opacity: '1',
    },
    '& .MuiTab-wrapper': {
      border: '1px solid #C7C9CC',
      borderRadius: '48px',
      // padding: '10px 16px 10px 16px',
      padding: theme.spacing(1, 3),
      backgroundColor: '#F6F6F6',
      color: '#0F1217',
      // width: 'auto',
    },
  },
}));

function TabsPanel({ tabs, currentTab, handleTabChange, orientation }) {
  const classes = useStyles();

  return (
    <Tabs
      value={currentTab}
      onChange={handleTabChange}
      className={classes.tabs}
      orientation={orientation}
      variant="scrollable"
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
}

TabsPanel.defaultProps = {
  orientation: 'horizontal',
};

TabsPanel.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  orientation: PropTypes.string,
};

export default TabsPanel;
