import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import classNames from 'classnames';
import { NEWSLETTER_STATUS } from './localConstantsAndHelpers';

const useStyles = makeStyles(({ palette, spacing }) => ({
  statusContainer: {
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: 4,
    padding: spacing(0.5, 1.5),
    display: 'flex',
    '& > *': {
      fontWeight: '700 !important'
    }
  },
  sentStatus: {
    background: 'rgba(0, 158, 35, 0.16)',
    color: 'rgba(0, 158, 35, 1)'
  },
  draftStatus: {
    background: 'rgba(15, 18, 23, 0.16)',
    color: palette.text.primary
  }
})
);

function NewsletterStatus({ status, sentAt }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (NEWSLETTER_STATUS.SENT === status) {
    return (
      <Grid className={classNames(
        classes.statusContainer,
        classes.sentStatus
      )}
      >
        <Typography color="inherit" variant="body2" noWrap>
          {`${t('newsletter.sent')} ${sentAt ? moment(sentAt).format('DD.MM.YYYY') : ''}`}
        </Typography>
      </Grid>
    );
  } else {
    return (
      <Grid className={classNames(
        classes.statusContainer,
        classes.draftStatus
      )}
      >
        <Typography color="inherit" variant="body2" noWrap>
          {t('customers.status type.draft')}
        </Typography>
      </Grid>
    );
  }
}

NewsletterStatus.defaultProps = {
  sentAt: null
};

NewsletterStatus.propTypes = {
  status: PropTypes.string.isRequired,
  sentAt: PropTypes.string,
};

export default NewsletterStatus;
