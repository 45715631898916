import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorIcon from '@material-ui/icons/NotificationImportantOutlined';
import CloseIcon from '@material-ui/icons/Close';

import { NOTIFICATION_STATUS } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  notificationContainer: {
    minHeight: 85,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    background: 'white',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    maxHeight: 300,
  },
  errorBar: {
    background: '#F8E5E6',
  },
  infoBar: {
    background: theme.palette.common.black,
  },
  iconContainer: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    color: theme.palette.common.white,
  },
  alertIconBackground: {
    backgroundColor: theme.palette.error.main
  },
  message: {
    margin: theme.spacing(1.5),
    overflowY: 'auto',
    fontWeight: theme.typography.fontWeightMedium,
    maxHeight: 280,
    width: 430,
    whiteSpace: 'pre-line'
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  infoMessage: {
    color: theme.palette.common.white
  },
  closeButton: {
    marginLeft: theme.spacing(4)
  }
}));

export default function NotificationBar(props) {
  const {
    timeout,
    message,
    type,
    closeNotification,
    isOpen,
    isManual
  } = props;
  const classes = useStyles();

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (!isManual && (isOpen && timeout !== 0)) {
      const timer = setTimeout(() => {
        closeNotification();
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, message]);

  const handleClose = () => {
    closeNotification();
  };

  const containerBackgroundReducer = {
    SUCCESS: classes.infoBar,
    ALERT: classes.errorBar,
  };

  const messageReducer = {
    SUCCESS: classes.infoMessage,
    ALERT: classes.errorMessage,
  };

  const iconReducer = iconName => {
    switch (iconName) {
      case NOTIFICATION_STATUS.SUCCESS:
        return <SuccessIcon className={classNames()} />;

      case NOTIFICATION_STATUS.ALERT:
        return <ErrorIcon />;

      default:
        return <SuccessIcon />;
    }
  };

  const messageText = typeof message === 'object' ? message?.message : message;

  return (
    <Slide direction="down" in={isOpen}>
      <Grid
        className={classNames(
          classes.notificationContainer,
          containerBackgroundReducer[type]
        )}
        container
        alignItems="center"
        justifyContent="center"
        onClick={isManual ? null : handleClose}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classNames(
            classes.iconContainer,
            NOTIFICATION_STATUS.ALERT === type && classes.alertIconBackground
          )}
        >
          {iconReducer(type)}
        </Grid>
        <Typography className={classNames(classes.message, messageReducer[type])}>
          {messageText}
        </Typography>
        {isManual && (
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon color="error" />
          </IconButton>
        )}
      </Grid>
    </Slide>
  );
}

NotificationBar.propTypes = {
  message: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isManual: PropTypes.bool,
  timeout: PropTypes.number,
  type: PropTypes.oneOf([NOTIFICATION_STATUS.ALERT, NOTIFICATION_STATUS.SUCCESS]),
  closeNotification: PropTypes.func.isRequired,
};

NotificationBar.defaultProps = {
  timeout: 0,
  type: NOTIFICATION_STATUS.SUCCESS,
  message: '',
  isManual: false
};
