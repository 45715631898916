import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import GroupBadge from 'components/shared/GroupBadge';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  recipientListContainer: {
    borderRadius: 8,
    backgroundColor: palette.background.paper,
    border: palette.border.lightGreyThinDark,
    fontSize: 14,
    height: 48,
    padding: spacing(1),
    overflow: 'hidden'
  },
  assignedToAllChip: {
    background: 'rgba(255, 81, 0, 0.16)',
    color: palette.primary.main,
    padding: spacing(0.5, 1),
    margin: spacing(0, 0.5),
    borderRadius: 4,
    ...typography.body2,
    fontWeight: `${typography.fontWeightBold} !important`
  },
  assignedToCustomerChip: {
    background: 'rgba(121, 122, 124, 0.16)',
    color: palette.text.secondary,
    padding: spacing(0.5, 1),
    margin: spacing(0, 0.5),
    borderRadius: 4,
    ...typography.body2,
    fontWeight: `${typography.fontWeightBold} !important`
  },
}));

export default function RecipientsList({
  assignedToAll,
  assignedToCustomersCount,
  assignedToGroups,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const hasGroups = !assignedToAll && !!assignedToGroups?.length;
  const hasCustomers = !assignedToAll && assignedToCustomersCount > 0;

  return (
    <Grid
      className={classes.recipientListContainer}
      container
      alignItems="center"
      wrap="nowrap"
    >
      {assignedToAll && (
        <Grid className={classes.assignedToAllChip}>
          <Typography variant="inherit">
            {t('newsletter.recipients everyone', { count: assignedToCustomersCount })}
          </Typography>
        </Grid>
      )}
      {hasCustomers && (
        <Grid className={classes.assignedToCustomerChip}>
          <Typography variant="inherit" noWrap>
            {t('newsletter.number of recipient users', { count: assignedToCustomersCount })}
          </Typography>
        </Grid>
      )}
      {assignedToGroups?.length > 4 ? (
        <>
          <GroupBadge groups={assignedToGroups.slice(0, 4)} onlySideMargin />
          <Grid className={classes.assignedToAllChip}>
            <Typography variant="inherit" noWrap>
              {`... ${t('newsletter.other groups', { count: assignedToGroups.length - 4 })}`}
            </Typography>
          </Grid>
        </>
      ) : (
        <GroupBadge groups={assignedToGroups} onlySideMargin />
      )}
      {!assignedToAll && !hasCustomers && !hasGroups && (
        <Typography variant="body2" color="textSecondary">
          {t('newsletter.recipients placeholder')}
        </Typography>
      )}
    </Grid>
  );
}

RecipientsList.propTypes = {
  assignedToAll: PropTypes.bool,
  assignedToCustomersCount: PropTypes.number,
  assignedToGroups: PropTypes.array,
};

RecipientsList.defaultProps = {
  assignedToAll: false,
  assignedToCustomersCount: 0,
  assignedToGroups: [],
};
