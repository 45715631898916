/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { TABLE_SKELETON_TYPES } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  tableRow: {
    height: 56,
    borderBottom: theme.palette.border.lightGrey
  },
  tableCell: {
    padding: `0px ${theme.spacing(1.5)}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 140,
    width: 70,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  actionButtonsContainer: {
    width: 160
  },
  actionButtons: {
    width: 32,
    height: 32,
    margin: `0px ${theme.spacing(0.5)}px`,
    padding: 0,
  },
  detailPanelCell: {
    width: 60
  },
  connectionColumn: {
    width: 340
  },
  customerListColumn: {
    width: 90
  },
  deliveryDayContainer: {
    width: 160,
    padding: `0px ${theme.spacing(1)}px`,
  },
  deliveryDaySkeleton: {
    width: 27,
    height: 20,
    margin: theme.spacing(0.25)
  },
  imageSkeleton: {
    width: 40,
    height: 56,
    marginLeft: theme.spacing(1),
    borderRadius: 4
  },
  productPackage: {
    width: 120,
    height: 24,
    borderRadius: 30,
  },
  productCategoryColumn: {
    width: 330
  },
  productNameColumn: {
    width: 400
  },
  listsIdTitle: {
    paddingLeft: theme.spacing(5),
    width: 250
  },
  listsAssignmentColumn: {
    width: 290,
  },
  listsTotalResultColumn: {
    width: 420
  },
  listProductNumberColumn: {
    paddingLeft: theme.spacing(5),
    width: 100
  },
  listNameColumn: {
    width: 330,
  },
  listAssignmentColumn: {
    width: 380,
  },
  dealTitleColumn: {
    width: 250,
    textAlign: 'left'
  },
  unitsInPackagingColumn: {
    width: 145,
  },
  priceColumn: {
    width: 125,
  },
}));

export default function TableSkeletons({ type, rowsPerPage, numberOfCells }) {
  const classes = useStyles();

  const renderActionButtons = (number) => {
    const amountOfActions = number || 2;
    return (
      <TableCell className={classes.tableCell}>
        <Grid
          className={classes.actionButtonsContainer}
          container
          wrap="nowrap"
          justifyContent="center"
        >
          {[...Array(amountOfActions)].map((_, index) => (
            <Skeleton key={index} variant="rect" className={classes.actionButtons} />
          ))}
        </Grid>
      </TableCell>
    );
  };

  const skeletonRows = Array.from({ length: rowsPerPage });

  switch (type) {
    case TABLE_SKELETON_TYPES.CUSTOMERS:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          <TableCell className={classNames(
            classes.tableCell,
            classes.detailPanelCell
          )}
          />
          <TableCell className={classes.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell
            className={classNames(
              classes.tableCell,
              classes.connectionColumn
            )}
          >
            <Skeleton />
            <Skeleton width={150} />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.customerListColumn
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton variant="rect"/>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton variant="rect"/>
          </TableCell>
          <TableCell className={classes.tableCell} style={{ width: 170 }}>
            <Grid
              container
              className={classes.deliveryDayContainer}
            >
              {Array.from({ length: 7 }).map((__, dayIndex) => (
                <Skeleton
                  key={dayIndex}
                  className={classes.deliveryDaySkeleton}
                  variant="rect"
                />
              ))}
            </Grid>
          </TableCell>
          {renderActionButtons()}
        </TableRow>
      ));
    case TABLE_SKELETON_TYPES.ORDERS:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          <TableCell
            className={classNames(
              classes.tableCell,
              classes.connectionColumn
            )}
          >
            <Skeleton />
            <Skeleton width={180}/>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton/>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton/>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton/>
          </TableCell>
          {renderActionButtons()}
          <TableCell className={classes.tableCell}>
            <Skeleton variant="rect"/>
          </TableCell>
          {renderActionButtons()}
        </TableRow>
      ));
    case TABLE_SKELETON_TYPES.PRODUCTS:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          <TableCell className={classNames(
            classes.tableCell,
            classes.detailPanelCell
          )}
          />
          <TableCell className={classes.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton className={classes.imageSkeleton}/>
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.productNameColumn
          )}
          >
            <Skeleton/>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton className={classes.productPackage}/>
          </TableCell>
          {Array.from({ length: 5 }).map((__, productIndex) => (
            <TableCell key={productIndex} className={classes.tableCell}>
              <Skeleton />
            </TableCell>
          ))}
          <TableCell className={classNames(
            classes.tableCell,
            classes.productCategoryColumn
          )}
          >
            <Skeleton />
          </TableCell>
          {renderActionButtons()}
        </TableRow>
      ));
    case TABLE_SKELETON_TYPES.LISTS:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          <TableCell className={classNames(
            classes.tableCell,
            classes.listsIdTitle
          )}
          >
            <Skeleton />
            <Skeleton width={150} />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.listsAssignmentColumn
          )}
          >
            <Skeleton variant="rect" />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.listsTotalResultColumn
          )}
          >
            <Skeleton />
          </TableCell>
          {renderActionButtons()}
        </TableRow>
      ));
    case TABLE_SKELETON_TYPES.LIST:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          <TableCell className={classNames(
            classes.tableCell,
            classes.listProductNumberColumn
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.listNameColumn
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.listAssignmentColumn
          )}
          >
            <Skeleton />
          </TableCell>
        </TableRow>
      ));
    case TABLE_SKELETON_TYPES.DEALS:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          <TableCell className={classNames(
            classes.tableCell,
            classes.detailPanelCell
          )}
          />
          <TableCell className={classes.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.dealTitleColumn
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.priceColumn
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton className={classes.productPackage}/>
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.priceColumn
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton variant="rect" />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.unitsInPackagingColumn
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton variant="rect" />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton variant="rect" />
          </TableCell>
          {renderActionButtons()}
        </TableRow>
      ));
    case TABLE_SKELETON_TYPES.NEWSLETTER_RECIPIENTS:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          <TableCell className={classNames(
            classes.tableCell,
            classes.actionButtonsContainer
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classNames(
            classes.tableCell,
            classes.listsIdTitle
          )}
          >
            <Skeleton />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton/>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton/>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Skeleton/>
          </TableCell>
          {renderActionButtons(1)}
        </TableRow>
      ));
    default:
      return skeletonRows.map((_, index) => (
        <TableRow className={classes.tableRow} key={index}>
          {Array.from({ length: numberOfCells || 1 }).map((__, cellIndex) => (
            <TableCell key={cellIndex} className={classes.tableCell}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ));
  }
}

TableSkeletons.propTypes = {
  type: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  numberOfCells: PropTypes.number.isRequired
};
