/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_NEWSLETTER_RECIPIENTS } from 'graphql/query/newsletters';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import RecipientsTable from 'components/Newsletter/Recipients/RecipientsTable';
import RecipientsTableActions from 'components/Newsletter/Recipients/RecipientsTableActions';
import { NOTIFICATION_STATUS, ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';
import {
  NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS,
  RECIPIENTS_FILTER_OPTIONS,
} from 'components/Newsletter/localConstantsAndHelpers';
import { parseDataValidationErrors } from 'helpers/getErrorMessage';

const useStyles = makeStyles(({ spacing }) => ({
  newsletterRecipientsContainer: {
    width: '100%',
    height: '100%',
    marginTop: spacing(1)
  },
}));

export default function NewsletterRecipients({ newsletterId }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [setNotification] = useMutation(SET_NOTIFICATION);

  const previousRoute = useRef('');

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'name',
    sortOrder: -1,
    filter: RECIPIENTS_FILTER_OPTIONS.NONE,
    search: '',
    recipientAssignmentStatus: NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED
  });

  const [
    getNewsletterRecipients,
    { loading: newsletterRecipientsLoading, error: newsletterRecipientError, data },
  ] = useLazyQuery(GET_NEWSLETTER_RECIPIENTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      id: newsletterId
    },
    onError: (getNewsletterRecipientsError) => {
      const errorMessage = parseDataValidationErrors(getNewsletterRecipientsError.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: errorMessage,
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  });

  const newsletterRecipients = (data && data.getNewsletterRecipients);

  useEffect(() => {
    const { filter, assignmentStatus } = queries;
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;
    const recipientAssignmentStatus = assignmentStatus || NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED;

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
        recipientAssignmentStatus,
        filter: filter || state.filter
      });
    }

    const previousPathname = location?.state?.fromPath;
    if (previousPathname) {
      previousRoute.current = previousPathname;
    }
  }, []);

  useEffect(() => {
    const totalPages = newsletterRecipients?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (newsletterRecipients && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.push({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [newsletterRecipients]);

  useEffect(() => {
    getNewsletterRecipients({
      variables: {
        after: state.page + 1,
        pageSize: state.rowsPerPage,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder,
        filter: state.filter,
        search: state.search,
        recipientAssignmentStatus: state.recipientAssignmentStatus
      },
    });
  }, [state]);

  const handleChangePage = async (event, page) => {
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  return (
    <Grid className={classes.newsletterRecipientsContainer}>

      <RecipientsTableActions
        state={state}
        newsletterId={newsletterId}
        previousRoute={previousRoute?.current}
        setState={setState}
      />

      <RecipientsTable
        data={newsletterRecipients}
        state={state}
        error={newsletterRecipientError}
        loading={newsletterRecipientsLoading}
        newsletterId={newsletterId}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortRows={handleSortRows}
      />
    </Grid>
  );
}

NewsletterRecipients.propTypes = {
  newsletterId: PropTypes.string.isRequired
};
