import { gql } from '@apollo/client';

export const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus($orderId: ID!, $status: OrderStatus!) {
    updateOrderStatus(orderId: $orderId, status: $status) {
      _id
      vendorId
      customerId
      customer {
        _id
        contactData {
          businessName
        }
      }
      status
      deliveryDate
      orderedAt
      deliveryDetails {
        message
      }
      orderNumber
      deliveryAddress {
        zip
        city
        houseNumber
        street
        country
      }
      userProfileName
      products {
        _id
        name
        variantId
        packaging
        qty
        price
      }
      totalPrice
    }
  }`;

export const DOCUMENT_CREATE = gql`
  mutation(
    $type: String!
    $document: Upload!
    $orderId: ID!
    $gross: Float!
    $net: Float!
    $documentNumber: String
  ) {
    createMonduDocument(
      type: $type
      document: $document
      orderId: $orderId
      gross: $gross
      net: $net
      documentNumber: $documentNumber
    ) {
      errors {
        details
        name
      }
    }
  }
`;

export const INVOICE_CANCEL = gql`
  mutation($orderId: ID!, $monduInvoiceUUID: String!) {
    cancelMonduInvoice(orderId: $orderId, monduInvoiceUUID: $monduInvoiceUUID) {
      uuid
    }
  }
`;
