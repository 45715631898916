import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutline';

import EmptyTableField from 'components/shared/EmptyTableField';
import { useSharedTableStyles } from 'components/shared/styles/sharedTableStyles';

function GroupDetailsTableRow({
  rowData,
  handleRemoveSingleAssignment,
  isActionButtonDisabled,
  selected,
  handleSelectItem,
}) {
  const sharedTableStyles = useSharedTableStyles();

  const { vendorClientId, contactData } = rowData;

  return (
    <TableRow className={sharedTableStyles.tableBodyRow}>
      <TableCell className={sharedTableStyles.tableCell}>
        <Checkbox
          checked={selected.includes(vendorClientId)}
          onClick={handleSelectItem(vendorClientId)}
        />
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell} >
        <Grid container direction="column" wrap="nowrap">
          <Typography variant="body2">{vendorClientId}</Typography>
        </Grid>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        {contactData?.businessName ? (
          <Typography variant="body2">{contactData.businessName}</Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell
        className={classNames(
          sharedTableStyles.tableCell,
          sharedTableStyles.actionButtonContainer
        )}
      >
        <Grid container justifyContent="center" wrap="nowrap">
          <Button
            variant="contained"
            classes={{ root: sharedTableStyles.tableActionButton }}
            onClick={handleRemoveSingleAssignment(vendorClientId)}
            disabled={isActionButtonDisabled}
          >
            <DeleteIcon className={sharedTableStyles.tableActionButtonIcon} />
          </Button>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

GroupDetailsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleRemoveSingleAssignment: PropTypes.func.isRequired,
  isActionButtonDisabled: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  handleSelectItem: PropTypes.func.isRequired,
};

export default React.memo(
  GroupDetailsTableRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.rowData, nextProps.rowData) &&
    prevProps.isActionButtonDisabled === nextProps.isActionButtonDisabled &&
    prevProps.selected === nextProps.selected
);
