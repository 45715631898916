import { makeStyles } from '@material-ui/styles';

const useNewsletterStyles = makeStyles(({ spacing, typography }) => ({
  nlConstructorSection: {
    marginBottom: spacing(3),
  },
  fieldLabel: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(1),
  },
  boldText: {
    fontWeight: typography.fontWeightBold,
  },
  gridItem: {
    marginRight: spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export default useNewsletterStyles;
