/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SALES_REPS } from 'graphql/query/salesReps';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { DELETE_SALES_REP } from 'graphql/mutation/salesReps';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddSalesRepModal from 'components/SalesRepsTable/AddSalesRepModal';

import TableLayout from 'components/shared/TableLayout';
import SalesRepTableRow from 'components/SalesRepsTable/SalesRepTableRow';
import {
  NOTIFICATION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  tableLeftPadding: {
    paddingLeft: theme.spacing(5),
  },
  addSalesRepButton: {
    border: theme.palette.border.lightGrey,
    height: 45,
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Lists() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const [isSalesRepModalOpen, setAddSalesRepModal] = React.useState(false);

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const queries = queryString.parse(location.search);

  const salesRepRef = React.useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const [deleteSalesRep] = useMutation(DELETE_SALES_REP);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
    selectedId: null
  });

  const [getSalesReps, {
    loading: salesRepsLoading,
    error: salesRepsError,
    data: salesRepsData,
  }] = useLazyQuery(
    GET_SALES_REPS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const salesReps = salesRepsData?.vendorSalesReps;

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const selectedId = queries.id || null;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getSalesReps({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      }
    });
    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        selectedId,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = salesReps?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && (totalPages <= state.page);
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(state.rowsPerPage);

    if (salesReps && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        })
      });
      window.location.reload();
    }
  }, [salesReps]);

  const handleDeleteSalesRep = async (_id) => {
    try {
      await deleteSalesRep({
        variables: { _id },
        refetchQueries: [{
          query: GET_SALES_REPS,
          variables: {
            after: state.page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        }],
      });
      mixPanel.track('Delete Sales Rep', { _id });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('lists.delete list success message'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleChangePage = async (event, page) => {
    if (salesReps && (salesReps.hasNextPage || page + 1 < salesReps.totalPages)) {
      try {
        await getSalesReps({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page, selectedId: null });
    history.push({
      pathname: currentPath,
      search:
          queryString.stringify({ ...queryString.parse(location.search), page, id: '' })
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      await getSalesReps({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, rowsPerPage, page: 0, selectedId: null
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0
      })
    });
  };

  const tableColumns = [
    {
      title: t('common.created'),
      field: 'createdAt',
      sortable: false,
      columnStyles: classes.tableLeftPadding
    },
    {
      title: t('common.email'),
      field: 'email',
      sortable: false,
    },
    {
      title: t('salesReps.assignment'),
      field: 'assignment',
      sortable: false,
    },
  ];

  const {
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    selectedId
  } = state;

  const hasError = salesRepsError || (!salesRepsLoading && !salesReps)
    || (salesReps && !salesReps.salesReps.length);

  return (
    <>
      <TableLayout
        title={t('salesReps.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.LISTS}
        emptyTableData={hasError}
        emptyTableDataMessage={t('lists.empty lists data')}
        tableIsLoading={salesRepsLoading}
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={(salesReps && salesReps.totalResults) || 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        headerActions={[
          <Button
            key="add sales rep"
            className={classes.addSalesRepButton}
            variant="outlined"
            startIcon={<AddCircleOutlineIcon color="primary"/>}
            onClick={() => setAddSalesRepModal(true)}
          >
            {t('salesReps.addNew')}
          </Button>,
        ]}
      >
        {salesReps && salesReps.salesReps
          .map((rowData) => (
            <SalesRepTableRow
              key={rowData._id}
              selectedList={selectedId}
              rowData={rowData}
              executeScroll={() => scrollToRef(salesRepRef)}
              salesRepRef={salesRepRef}
              rowsPerPage={+queries.rowsPerPage}
              handleDeleteSalesRep={handleDeleteSalesRep}
            />
          ))}
      </TableLayout>
      <AddSalesRepModal
        isOpen={isSalesRepModalOpen}
        handleClose={() => setAddSalesRepModal(false)}
        rowsPerPage={rowsPerPage}
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
    </>
  );
}
