import { gql } from '@apollo/client';
import { TRANSLATION_FRAGMENT, GROUP_FRAGMENT } from 'graphql/fragments';

export const ADD_PDF_CATALOG_PRODUCTS = gql`
  mutation addPdfCatalogProducts(
    $catalogId: ID!
    $pageNumber: Int!
    $products: [PdfCatalogProductInput]!
  ) {
    addPdfCatalogProducts(
      catalogId: $catalogId
      pageNumber: $pageNumber
      products: $products
    ) {
      _id
      vendorId
      catalogId
      isActivated
      vendorSpecificId
      productId
      page
      pagePosition
      price
      isBaseUnitPrice
      name
      packaging
      baseUnit
      unitsInPackaging

      productCopy {
        _id
        name
        vendorSpecificId
        variants {
          packaging
          price
          baseUnit
          unitsInPackaging
        }
      }
    }
  }
`;

export const UPDATE_PDF_CATALOG_PRODUCTS = gql`
  mutation updatePdfCatalogProducts(
    $catalogId: ID!
    $productUpdates: [PdfCatalogProductUpdatesInput]!
  ) {
    updatePdfCatalogProducts(
      catalogId: $catalogId
      productUpdates: $productUpdates
    ) {
      _id
      vendorId
      catalogId
      isActivated
      vendorSpecificId
      productId
      page
      pagePosition
      price
      isBaseUnitPrice
      name
      packaging
      baseUnit
      unitsInPackaging

      productCopy {
        _id
        name
        vendorSpecificId
        variants {
          packaging
          price
          baseUnit
          unitsInPackaging
        }
      }
    }
  }
`;

export const DELETE_PDF_CATALOG = gql`
  mutation deletePdfCatalog($_id: ID!) {
    deletePdfCatalog(_id: $_id) {
      _id
    }
  }
`;

export const PDF_CATALOG_FRAGMENT = gql`
  ${TRANSLATION_FRAGMENT}
  ${GROUP_FRAGMENT}
  fragment PdfCatalogFragment on PdfCatalog {
    _id
    createdAt
    updatedAt
    vendorId
    url
    enabled
    isBaseUnitPrice
    status
    groups {
      ...GroupFragment
    }
    events {
      view {
        total
        users {
          _id
          timestamp
        }
      }
      open {
        total
        users {
          _id
          timestamp
        }
      }
      addToCart {
        total
        users {
          _id
          timestamp
        }
      }
    }
    title {
      ...TranslationFragment
    }
    subTitle {
      ...TranslationFragment
    }
    description {
      ...TranslationFragment
    }
  }
`;

export const UPLOAD_PDF_CATALOG = gql`
  ${PDF_CATALOG_FRAGMENT}
  mutation uploadPdfCatalog($file: Upload!, $title: String, $groups: [ID], $specialIdPrompt: String) {
    uploadPdfCatalog(file: $file, title: $title, groups: $groups, specialIdPrompt: $specialIdPrompt) {
      ...PdfCatalogFragment
    }
  }
`;

export const UPDATE_PDF_CATALOG = gql`
  ${PDF_CATALOG_FRAGMENT}
  mutation updatePdfCatalog(
    $catalogId: ID!
    $catalogUpdates: PdfCatalogUpdateInput!
  ) {
    updatePdfCatalog(catalogId: $catalogId, catalogUpdates: $catalogUpdates) {
      ...PdfCatalogFragment
    }
  }
`;
