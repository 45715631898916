import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MultiSelectButtons from 'components/DealsTable/MultiSelectButtons';
import DealsTableRow from 'components/DealsTable/DealsTableRow';
import TableLayout from 'components/shared/TableLayout';
import useDealTable from 'hooks/useDealTable';
import { TABLE_SKELETON_TYPES } from 'helpers/constants';
import AddDealModal from 'components/DealsTable/AddDealModal';

function RegularDealsTable({ classes }) {
  const { t } = useTranslation();

  const {
    dealsData,
    hasError,
    dealsLoading,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    handleSortRows,
    refetchDeals,

    selected,
    editModeId,
    isAddDealModalOpen,
    handleSelectItem,
    handleSetEditMode,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelectAllClick,

    editConfirmationModal,

    setSelected,
    setConfirmEditChanges,
    setAddDealModalOpened,
  } = useDealTable();

  const handleClickAway = () => (editModeId ? setConfirmEditChanges(true) : null);

  const tableColumns = [
    {
      title: t('deals.number'),
      field: 'vendorSpecificId',
      sortable: true,
    },
    {
      title: t('deals.name'),
      field: 'name',
      sortable: true,
      columnStyles: classNames(classes.columnTitle, classes.dealTitle)
    },
    {
      title: t('deals.price'),
      field: 'price',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.price)
    },
    {
      title: t('deals.package'),
      field: 'variants[0].packaging',
      sortable: false,
    },
    {
      title: t('deals.price per unit'),
      field: 'variants[0].price',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.price)
    },
    {
      title: t('common.base unit'),
      field: 'variants[0].baseUnit',
      sortable: false,
    },
    {
      title: t('common.quantity per unit'),
      field: 'variants[0].unitsInPackaging',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.unitsInPackaging)
    },
    {
      title: t('deals.start'),
      field: 'dealDetails.start',
      sortable: false,
    },
    {
      title: t('deals.end'),
      field: 'dealDetails.end',
      sortable: false,
    },
  ];

  return (
    <>
      <TableLayout
        handleClickAway={handleClickAway}
        isEditMode={Boolean(editModeId)}
        title={t('deals.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.DEALS}
        emptyTableData={hasError || !dealsData?.totalResults}
        emptyTableDataMessage={t('deals.empty deals')}
        tableIsLoading={dealsLoading}
        withDetailPanel
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={dealsData?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        headerActions={
          <Button
            className={classes.addDealButton}
            variant="outlined"
            startIcon={<AddCircleOutlineIcon color="primary"/>}
            onClick={() => setAddDealModalOpened(true)}
          >
            {t('deals.addNewDeal')}
          </Button>
        }
        headerAdditionalActions={!!selected.length && (
          <MultiSelectButtons
            allDeals={dealsData?.products}
            dealsIds={selected}
            setSelected={setSelected}
            refetchDeals={refetchDeals}
          />
        )}
        hasCheckBox
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        itemsOnPage={dealsData?.products?.length}
      >
        {!!dealsData?.products?.length && dealsData.products
          .map((rowData) => (
            rowData && (
              <DealsTableRow
                key={rowData._id}
                isEditMode={rowData._id === editModeId}
                setEditMode={handleSetEditMode}
                editConfirmationModalIsActive={editConfirmationModal}
                setConfirmEditChanges={setConfirmEditChanges}
                handleSelectItem={handleSelectItem}
                rowData={rowData}
                withDetailPanel
                selected={selected}
                refetchDeals={refetchDeals}
              />
            )))}
      </TableLayout>
      <AddDealModal
        isOpen={isAddDealModalOpen}
        handleClose={() => setAddDealModalOpened(false)}
        rowsPerPage={rowsPerPage}
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
    </>
  );
}

RegularDealsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default RegularDealsTable;
