/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  GET_PDF_CATALOG,
  GET_PDF_CATALOG_PRODUCTS,
} from 'graphql/query/pdfCatalogs';
import {
  ADD_PDF_CATALOG_PRODUCTS,
  UPDATE_PDF_CATALOG_PRODUCTS,
  UPDATE_PDF_CATALOG,
} from 'graphql/mutation/pdfCatalogs';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, CircularProgress } from '@material-ui/core';

import TableLayout from 'components/shared/TableLayout';
import DetailsTableRow from 'components/PdfCatalogs/DetailsTableRow';
import HeaderActions from 'components/PdfCatalogs/HeaderActions';
import EmptyTableRow from 'components/shared/EmptyTableRow';

import useMixPanel from 'helpers/useMixPanel';

import { TABLE_SKELETON_TYPES, NOTIFICATION_STATUS } from 'helpers/constants';
import { Pagination } from '@material-ui/lab';
import classNames from 'classnames';

import Lottie from 'react-lottie';
import animationData from 'components/Lottie/processingAi.json';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  smallColumn: {
    paddingLeft: spacing(1),
    width: 60,
    [breakpoints.up('xxl')]: {
      width: 80,
    },
  },
  mediumColumn: {
    paddingLeft: spacing(1),
    width: 100,
    [breakpoints.up('xxl')]: {
      width: 120,
    },
  },
  largeColumn: {
    paddingLeft: spacing(1),
    width: 140,
    [breakpoints.up('xxl')]: {
      width: 160,
    },
  },
  xxLargeColumn: {
    paddingLeft: spacing(1),
    width: 180,
    [breakpoints.up('xxl')]: {
      width: 200,
    },
  },
  imagePreviewContainer: {
    width: '75%',
    maxWidth: '500px',
    height: '100%',
    marginRight: spacing(2),
  },
  pdfPreviewEmbed: {
    width: '100%',
    minHeight: '790px',
  },
  container: {
    width: '100%',
    maxWidth: 'unset',
    background: 'transparent',
    boxShadow: 'none',
    [breakpoints.up('xl')]: {
      maxWidth: 1620,
    },
  },
  pdfTableContainer: {
    width: '100%',
    height: '100%',
    padding: spacing(3, 0),
  },
  addActionsInputContainer: {
    padding: `${spacing(2)}px 0`,
    justifyContent: 'flex-start',
    paddingLeft: spacing(3.5),
  },
  leftMostColumn: {
    paddingLeft: spacing(3.5),
    [breakpoints.up('lg')]: {
      paddingLeft: spacing(3.5),
    },
  },
  tableContainer: {
    width: '100%',
  },
  paginationUlElement: {
    '& .MuiPaginationItem-root': {
      border: palette.border.grey,
      height: spacing(4),
    },
    '& .Mui-selected': {
      border: 'none',
    },
  },
  tablePreloader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  aiLabel: {
    marginTop: 0,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    lineHeight: `${spacing(3)}px`,
  },
}));

export default function Index({ id }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [productPage, setProductPage] = useState(1);
  const [productChanges, setProductChanges] = useState({});
  const [groups, setGroups] = useState([]);
  const [updatingGroups, setUpdatingGroups] = useState(false);

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
  });

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [getPdfCatalog, { loading, error: gqlError, data }] = useLazyQuery(
    GET_PDF_CATALOG,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      onCompleted: catalog => {
        setGroups(catalog?.getPdfCatalogById?.groups || []);
      },
    }
  );

  const [
    getPdfCatalogProducts,
    { loading: productsLoading, error: productsGqlError, data: productsData },
  ] = useLazyQuery(GET_PDF_CATALOG_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [addPdfCatalogProducts] = useMutation(ADD_PDF_CATALOG_PRODUCTS);
  const [updatePdfCatalogProducts] = useMutation(UPDATE_PDF_CATALOG_PRODUCTS);
  const [updatePdfCatalog] = useMutation(UPDATE_PDF_CATALOG);

  useEffect(() => {
    const pageParam = +queries.page;

    getPdfCatalogProducts({
      variables: {
        catalogId: id,
        pageNumber: productPage - 1,
        after: pageParam + 1 || state.page + 1,
        pageSize: 100,
      },
    });
  }, [productPage]);

  const pdfCatalog = data?.getPdfCatalogById;
  const pdfCatalogProducts = productsData?.getPdfCatalogProducts?.results;

  useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;

    getPdfCatalog({
      variables: {
        _id: id,
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        // sortBy: state.sortBy,
        // sortOrder: state.sortOrder
      },
    });

    if (pageParam || rowsParam) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
      });
    }
  }, []);

  useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;

    const timeout = setTimeout(() => {
      // @TODO: add status tracking to only reload if it's not processed yet
      if (
        !pdfCatalogProducts?.length &&
        !productsLoading &&
        !productsGqlError
      ) {
        getPdfCatalogProducts({
          variables: {
            catalogId: id,
            pageNumber: productPage - 1,
            after: pageParam + 1 || state.page + 1,
            // pageSize: rowsParam || state.rowsPerPage,
            pageSize: 100,
          },
        });
        getPdfCatalog({
          variables: {
            _id: id,
            after: pageParam + 1 || state.page + 1,
            pageSize: rowsParam || state.rowsPerPage,
            // sortBy: state.sortBy,
            // sortOrder: state.sortOrder
          },
        });
      }
    }, 30000);
    return () => clearTimeout(timeout);
  }, [pdfCatalogProducts, productsLoading, productsGqlError]);

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getPdfCatalog({
        variables: {
          _id: id,
          after: 1,
          pageSize: rowsPerPage,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }

    setState({ ...state, rowsPerPage, page: 0 });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getPdfCatalog({
        variables: {
          _id: id,
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const handleBackButtonAction = () => {
    if (history.length > 2) {
      // NOTE: if length > 2 it means this is not the first page user has visited
      history.goBack();
    } else {
      history.push('/pdfCatalogs');
    }
  };

  const handleAddProducts = async parsedIds => {
    try {
      if (!parsedIds?.length) return;
      const pageParam = +queries.page;
      await addPdfCatalogProducts({
        variables: {
          catalogId: id,
          pageNumber: productPage - 1,
          products: parsedIds?.map(x => ({
            vendorSpecificId: x,
            isActivated: true,
          })),
        },
        update: async (store, { data: newData }) => {
          mixPanel.track('Add PdfCatalog Products', {
            catalogId: id,
            parsedIds,
            productsAdded: newData?.addPdfCatalogProducts?.length,
          });
          setNotification({
            variables: {
              timeout: 4000,
              message: t('pdfCatalog.addProductsSuccess', {
                count: newData?.addPdfCatalogProducts?.length,
              }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
      getPdfCatalogProducts({
        variables: {
          catalogId: id,
          pageNumber: productPage - 1,
          after: pageParam + 1 || state.page + 1,
          pageSize: 100,
        },
      });
    } catch (error) {
      console.error({ error });
    }
  };

  const handleChangeCatalogPricingMode = async pricingType => {
    const pageParam = +queries.page;
    try {
      await updatePdfCatalog({
        variables: {
          catalogId: id,
          catalogUpdates: {
            isBaseUnitPrice: pricingType === 'baseUnit',
          },
        },
        update: async () => {
          mixPanel.track('Update PdfCatalog pricingMode', {
            catalogId: id,
            pricingType,
          });
        },
        refetchQueries: [
          {
            query: GET_PDF_CATALOG_PRODUCTS,
            variables: {
              catalogId: id,
              pageNumber: productPage - 1,
              after: pageParam + 1 || state.page + 1,
              pageSize: 50,
            },
          },
        ],
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCellChange = (_id, name, value) => {
    let cleanValue = value;
    if (name === 'page') {
      cleanValue = parseInt(cleanValue, 10) - 1;
      cleanValue = cleanValue < 0 ? 0 : cleanValue;
    }
    if (name === 'price') {
      cleanValue = parseFloat(parseFloat(cleanValue).toFixed(2));
      cleanValue = cleanValue < 0 ? 0 : cleanValue;
    }
    if (name === 'toDelete') {
      const existingChange = productChanges[_id]?.toDelete;
      if (existingChange === true) {
        cleanValue = false;
      } else {
        cleanValue = true;
      }
    }
    setProductChanges(prevState => ({
      ...prevState,
      [_id]: {
        ...prevState[_id],
        _id,
        [name]: cleanValue,
      },
    }));
  };

  const handleCancelChanges = () => {
    setBulkEditMode(false);
    setProductChanges({});
    setGroups(pdfCatalog?.groups || []);
  };

  const handleSaveChanges = async () => {
    setBulkEditMode(false);

    // Save group updates
    const oldGroupIds = pdfCatalog?.groups?.map(g => g._id) || [];
    const newGroupIds = groups?.map(g => g._id) || [];

    // this comparison will update the order in which groups are set.
    const groupsChanged = oldGroupIds.join(',') !== newGroupIds.join(',');

    if (groupsChanged) {
      setUpdatingGroups(true);

      await updatePdfCatalog({
        variables: {
          catalogId: id,
          catalogUpdates: {
            groups: newGroupIds,
          },
        },
        onCompleted: response => {
          setGroups(response?.updatePdfCatalog?.groups || []);
          setUpdatingGroups(false);
        },
        onError: () => {
          setGroups(pdfCatalog?.groups || []);
          setUpdatingGroups(false);
        },
        update: async () => {
          mixPanel.track('Update PdfCatalog Groups', {
            catalogId: id,
            groups,
          });
        },
      });
    }

    // Save product updates
    const changes = Object.keys(productChanges).map(key => productChanges[key]);

    if (!changes?.length) return;
    if (productChanges) {
      const pageParam = +queries.page;

      await updatePdfCatalogProducts({
        variables: { catalogId: id, productUpdates: changes },
        update: async () => {
          setProductChanges({});
        },
      });
      mixPanel.track('Update PdfCatalog Products', { id });
      getPdfCatalogProducts({
        variables: {
          catalogId: id,
          pageNumber: productPage - 1,
          after: pageParam + 1 || state.page + 1,
          pageSize: 100,
        },
      });
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: t('pdfCatalog.found'),
        field: '1',
        sortable: false,
        columnStyles: classNames(classes.mediumColumn, classes.leftMostColumn),
      },
      {
        title: t('pdfCatalog.id'),
        field: 'vendorSpecificId',
        sortable: false,
        columnStyles: classes.largeColumn,
      },
      {
        title: t('pdfCatalog.name'),
        field: 'name',
        sortable: false,
        columnStyles: classes.xxLargeColumn,
      },
      {
        title: t('pdfCatalog.baseUnitPrice'),
        field: 'price',
        sortable: false,
        columnStyles: classes.largeColumn,
      },
      {
        title: t('pdfCatalog.packagingPrice'),
        field: 'price',
        sortable: false,
        columnStyles: classes.largeColumn,
      },
      {
        title: t('pdfCatalog.PBU'),
        field: 'isBaseUnitPrice',
        sortable: false,
        columnStyles: classes.largeColumn,
      },
      {
        title: t('pdfCatalog.Action'),
        field: 'none',
        sortable: false,
        columnStyles: classes.smallColumn,
      },
    ],
    []
  );

  const { page, sortBy, sortOrder } = state;

  const hasError = Boolean(gqlError) || (!loading && !pdfCatalog);

  const pdfSrc = pdfCatalog?.url;
  const currentPage = pdfCatalog?.parsedPerPage?.[productPage - 1];
  const currentPageStatus = currentPage?.status;

  if ((!pdfCatalog && loading) || loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Paper elevation={1} className={classes.container}>
      <Grid container direction="column">
        <HeaderActions
          pdfCatalogId={id}
          bulkEditMode={bulkEditMode}
          setBulkEditMode={setBulkEditMode}
          handleGroupChanges={setGroups}
          handleSaveChanges={handleSaveChanges}
          handleCancelChanges={handleCancelChanges}
          productPage={productPage}
          setProductPage={setProductPage}
          productChanges={productChanges}
          handleAddProducts={handleAddProducts}
          totalPages={pdfCatalog?.parsedPerPage?.length || 0}
          currentPageStatus={currentPageStatus}
          handleChangeCatalogPricingMode={handleChangeCatalogPricingMode}
          isBaseUnitPrice={pdfCatalog?.isBaseUnitPrice || false}
          handleBackButtonAction={handleBackButtonAction}
          pdfCatalog={pdfCatalog}
          updatingGroups={updatingGroups}
          selectedGroups={groups}
        />
      </Grid>

      <Grid container wrap="nowrap" className={classes.pdfTableContainer}>
        {pdfSrc && (
          <Grid item className={classes.imagePreviewContainer}>
            <embed className={classes.pdfPreviewEmbed} src={pdfSrc} />
          </Grid>
        )}
        <Grid className={classes.tableContainer}>
          <TableLayout
            emptyTableData={hasError}
            emptyTableDataMessage={t('pdfCatalog.noProductResults')}
            tableIsLoading={loading}
            columns={pdfCatalog ? tableColumns : []}
            skeletonType={TABLE_SKELETON_TYPES.LIST}
            page={page}
            rowsPerPage={100}
            totalResults={1}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleSortRows={handleSortRows}
            noHeaderPaddings
            headerAdditionalActions={
              pdfCatalog?.parsedPerPage?.length > 1 && (
                <Grid
                  className={classes.addActionsInputContainer}
                  container
                  alignItems="center"
                  justifyContent="center"
                  wrap="nowrap"
                >
                  <Pagination
                    count={pdfCatalog?.parsedPerPage?.length || 0}
                    shape="round"
                    page={productPage}
                    color="primary"
                    classes={{
                      ul: classes.paginationUlElement,
                    }}
                    onChange={(e, pageNumber) => {
                      setProductPage(pageNumber);
                    }}
                  />
                </Grid>
              )
            }
            footerAdditionalActions={
              pdfCatalog?.parsedPerPage?.length > 1 && (
                <Grid
                  className={classes.addActionsInputContainer}
                  container
                  alignItems="center"
                  justifyContent="center"
                  wrap="nowrap"
                >
                  <Pagination
                    count={pdfCatalog?.parsedPerPage?.length || 0}
                    shape="round"
                    page={productPage}
                    color="primary"
                    classes={{
                      ul: classes.paginationUlElement,
                    }}
                    onChange={(e, pageNumber) => {
                      setProductPage(pageNumber);
                    }}
                  />
                </Grid>
              )
            }
          >
            {currentPageStatus === 'pending' && (
              <Grid
                container
                className={classes.tablePreloader}
                alignItems="center"
                direction="column"
                justifyContent="center"
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={120}
                  width={160}
                />
                <h4 className={classes.aiLabel}>
                  {t('pdfCatalog.AIProcessing')}
                </h4>
              </Grid>
            )}
            {pdfCatalogProducts?.length > 0 ? (
              pdfCatalogProducts?.map((rowData, i) => {
                const pdfCatalogProductId = rowData?._id;

                return (
                  <DetailsTableRow
                    key={pdfCatalogProductId}
                    isOddRow={i % 2 === 0}
                    rowData={rowData}
                    isEditMode={bulkEditMode}
                    productChanges={productChanges?.[pdfCatalogProductId] || {}}
                    handleDeleteRow={() =>
                      handleCellChange(pdfCatalogProductId, 'toDelete')}
                    handleCellChange={handleCellChange}
                    isCatalogPricePerBaseUnit={pdfCatalog?.isBaseUnitPrice}
                  />
                );
              })
            ) : (
              <EmptyTableRow customHeight={300} />
            )}
          </TableLayout>
        </Grid>
      </Grid>
    </Paper>
  );
}

Index.propTypes = {
  id: PropTypes.string,
};

Index.defaultProps = {
  id: '',
};
