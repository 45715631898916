import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  CLONE_NEWSLETTER,
  SEND_NEWSLETTER,
} from 'graphql/mutation/newsletters';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import MailRoundedIcon from '@material-ui/icons/MailRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';

import NewsletterSendEmailModal from 'components/Newsletter/Constructor/Modals/NewsletterSendEmailModal';
import NewsletterTestEmailSendModal from 'components/Newsletter/Constructor/Modals/NewsletterTestEmailSendModal';
import NewsletterEmailPublishedModal from 'components/Newsletter/Constructor/Modals/NewsletterEmailPublishedModal';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import { parseDataValidationErrors } from 'helpers/getErrorMessage';
import useMixPanel from 'helpers/useMixPanel';
import {
  NEWSLETTER_STATUS,
  RECIPIENTS_FILTER_OPTIONS,
} from '../localConstantsAndHelpers';

const useStyles = makeStyles(({ spacing, palette }) => ({
  newsletterConstructorActionContainer: {
    marginTop: spacing(3),
  },
  newsletterConstructorActionButton: {
    width: 'fit-content',
    borderRadius: 40,
    boxShadow: 'none',
    margin: spacing(0, 0.5),
  },
  testSendButton: {
    color: palette.text.secondary,
    border: `1px solid ${palette.text.secondary}`,
  },
  newsletterConstructorLoader: {
    position: 'absolute',
    borderRadius: 8,
    marginTop: 70,
    height: 2,
    width: 'calc(100% - 24px)',
    right: 12,
  },
}));

export default function ActionButtonsSection({
  state,
  withTopMargin,
  withLoader,
  loading,
  handleUpdateNewsletter,
  setState,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const mixPanel = useMixPanel();

  const newsletterId = state?._id;
  const isSentStatus = state?.status === RECIPIENTS_FILTER_OPTIONS.SENT;

  const [sendNewsletter, { loading: isSendLoading }] = useMutation(
    SEND_NEWSLETTER
  );
  const [duplicateNewsletterMutation] = useMutation(CLONE_NEWSLETTER);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [testEmailInput, setTestEmailInput] = useState('');

  const [isTestEmailModalOpen, setTestEmailModalOpen] = useState(false);
  const [isPublishedModalOpen, setPublishedModalOpen] = useState(false);
  const [isSendEmailModalOpen, setEmailModalOpen] = useState(false);

  const handleSendEmail = async ({
    isTestSend = false,
    testEmail,
    saveTemplate = false,
  }) => {
    if (isTestSend && !testEmail) return;

    try {
      if (saveTemplate) {
        await handleUpdateNewsletter({ withSilentSave: true });
      }
      await sendNewsletter({
        variables: {
          id: newsletterId,
          isTestSend,
          testEmails: testEmail && [testEmail],
        },
        update: (_, response) => {
          const nlResponseStatus = response?.data?.sendNewsletter?.status;
          if (nlResponseStatus !== state.status) {
            setState(prevState => ({
              ...prevState,
              status: nlResponseStatus,
            }));
          }
          if (nlResponseStatus === NEWSLETTER_STATUS.SENT) {
            setPublishedModalOpen(true);
          }

          // NOTE: Tracking
          mixPanel.track(
            isTestSend ? 'Newsletter - Test Send' : 'Newsletter - Send',
            {
              newsletterId,
              isTestSend,
              testEmail,
              newsletter: response?.data?.sendNewsletter,
            }
          );
        },
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t(
            'newsletter.notifications.test newsletter sent successfully'
          ),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      const errorMsg = parseDataValidationErrors(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: errorMsg || error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleDuplicateNewsletter = async () => {
    try {
      const duplicatedNewsletterResponse = await duplicateNewsletterMutation({
        variables: {
          id: newsletterId,
        },
      });
      const newNewsletterId =
        duplicatedNewsletterResponse?.data?.cloneNewsletter?._id;
      if (newNewsletterId) {
        mixPanel.track('Newsletter - Duplicate', {
          newsletterId: newNewsletterId,
          newsletter: duplicatedNewsletterResponse?.data,
        });
        history.push(`/newsletter/${newNewsletterId}`);
      }
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleOpenEmailModal = () => {
    handleUpdateNewsletter({ withSilentSave: true });
    setEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  const handleOpenTestEmailModal = () => {
    setTestEmailModalOpen(true);
  };

  const handleCloseTestEmailModal = () => {
    setTestEmailModalOpen(false);
  };

  const handleClosePublishedModal = () => {
    setPublishedModalOpen(false);
  };

  const handleOpenTest = () => {
    handleCloseEmailModal();
    handleOpenTestEmailModal();
  };

  const handleOpenNoEmailRecipients = () => {
    history.push(
      `/newsletter/${newsletterId}/recipients?filter=${RECIPIENTS_FILTER_OPTIONS.NO_EMAIL}`
    );
  };

  const handleSendTestEmail = () => {
    handleCloseTestEmailModal();
    handleSendEmail({
      isTestSend: true,
      testEmail: testEmailInput,
      saveTemplate: true,
    });
  };

  const handleSendNewsletterEmail = () => {
    handleCloseEmailModal();
    handleSendEmail({
      isTestSend: false,
      saveTemplate: true,
    });
  };

  if (loading || isSendLoading) {
    return (
      <Grid container justifyContent="flex-end">
        <CircularProgress color="primary" />
      </Grid>
    );
  }

  return (
    <Grid
      container
      className={
        withTopMargin ? classes.newsletterConstructorActionContainer : ''
      }
      alignItems="center"
      justifyContent="flex-end"
    >
      {isSentStatus ? (
        <Button
          endIcon={<FileCopyRoundedIcon color="inherit" fontSize="small" />}
          variant="contained"
          color="primary"
          className={classes.newsletterConstructorActionButton}
          onClick={handleDuplicateNewsletter}
        >
          {t('newsletter.duplicate')}
        </Button>
      ) : (
        <>
          <Button
            variant="outlined"
            color="inherit"
            className={classNames(
              classes.newsletterConstructorActionButton,
              classes.testSendButton
            )}
            onClick={handleOpenTestEmailModal}
          >
            {t('newsletter.test send')}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classes.newsletterConstructorActionButton}
            onClick={handleUpdateNewsletter}
          >
            {t('newsletter.save draft')}
          </Button>

          <Button
            endIcon={<MailRoundedIcon color="inherit" fontSize="small" />}
            variant="contained"
            color="primary"
            className={classes.newsletterConstructorActionButton}
            onClick={handleOpenEmailModal}
          >
            {t('newsletter.send')}
          </Button>
        </>
      )}

      {/* ---- MODALS ---- */}

      <NewsletterSendEmailModal
        state={state}
        isOpen={isSendEmailModalOpen}
        fromEmailField={state?.fromEmail || ''}
        handleClose={handleCloseEmailModal}
        totalRecipients={state?.recipientCount || 0}
        isLoading={isSendLoading || loading}
        noEmailRecipients={state?.recipientsWithoutEmailCount || 0}
        handleOpenNoEmailRecipients={handleOpenNoEmailRecipients}
        handleSendNewsletterEmail={handleSendNewsletterEmail}
        handleOpenTest={handleOpenTest}
      />

      <NewsletterTestEmailSendModal
        isOpen={isTestEmailModalOpen}
        fromEmailField={state?.fromEmail || ''}
        testEmailInput={testEmailInput}
        isLoading={isSendLoading || loading}
        setTestEmailInput={setTestEmailInput}
        handleClose={handleCloseTestEmailModal}
        handleSendTestEmail={handleSendTestEmail}
      />

      <NewsletterEmailPublishedModal
        isOpen={isPublishedModalOpen}
        handleClose={handleClosePublishedModal}
      />

      {withLoader && loading && (
        <LinearProgress
          className={classes.newsletterConstructorLoader}
          color="primary"
        />
      )}
    </Grid>
  );
}

ActionButtonsSection.propTypes = {
  state: PropTypes.object,
  withTopMargin: PropTypes.bool,
  withLoader: PropTypes.bool,
  loading: PropTypes.bool,
  handleUpdateNewsletter: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};

ActionButtonsSection.defaultProps = {
  state: {},
  withTopMargin: false,
  withLoader: false,
  loading: false,
};
