import { gql } from '@apollo/client';

export const CREATE_VENDOR = gql`
mutation createVendor($vendorData: CreateVendorInput!) {
  createVendor(vendorData: $vendorData) {
    contacts {
      name
    }
    contactData {
      businessName
    }
  }
}`;

export const UPDATE_VENDOR = gql`
mutation updateVendor($vendorData: UpdateVendorInput!) {
  updateVendor(vendorData: $vendorData)  {
    _id
    # isPremium
    ccEmails
    contacts {
      _id
      phone
      name
      additionalPhones
      email
    }
    contactData {
      businessName
      street
      houseNumber
      zip
      city
      country
    }
    # users {
    #   userId
    #   role
    #   permissions
    #   status
    # }
    branding {
      primaryColor
      secondaryColor
      fontColor
    }
    # createdAt
    # updatedAt
    deliveryDays {
      name
      enabled
    }
    preferences {
      newsletter {
        fromName
        fromEmail
      }
    }
  }
}`;

export const UPDATE_INGESTION_SCHEDULE = gql`
mutation updateVendorJobSchedule($jobSchedule: JobScheduleInput!) {
  updateVendorJobSchedule(jobSchedule: $jobSchedule)  {
    _id
    vendorId
    jobType
    jobDetails {
      vendorName
    }
    repeatEvery
    runAtTime {
      minute
      hour
      day
    }
  }
}`;

export const CREATE_API_KEY_FOR_VENDOR = gql`
mutation createApiKeyForVendor($vendorId: String!) {
  createApiKeyForVendor(vendorId: $vendorId) {
    authToken
    isNewAuthToken
  }
}`;
