import i18next from 'i18next';

export const getErrorCode = (error) => {
  const { errorCode = null, translation = null, exception, code, ...props } = error?.graphQLErrors?.[0]?.extensions || {};
  // NOTE: we have 2 different versions of error codes that comes from our BE
  return { errorCode: errorCode || translation, props };
};

const getErrorMessage = (error) => {
  const { errorCode, props } = getErrorCode(error);

  if (errorCode && i18next.exists(`error.${errorCode}`)) {
    return i18next.t(`error.${errorCode}`, { ...(props || {}) });
  } else {
    return error?.message;
  }
};

export const parseDataValidationErrors = (error) => {
  let errorMsg;
  try {
    const errJson = JSON.parse(error);
    if (errJson?.length) {
      errorMsg = errJson?.map(m => m?.[i18next.language])?.join('\n');
    }
    return errorMsg;
  } catch (e) {
    return error;
  }
};

export default getErrorMessage;
