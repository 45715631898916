import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import { SEARCH_CUSTOMER_GROUPS } from 'graphql/query/groups';
import { useLazyQuery } from '@apollo/client';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {
  ASSIGN_TO_ALL_GROUP,
  translateGroup,
  filterOptions,
} from 'components/shared/Inputs/GroupSelector/helpers';
import { Listbox } from 'components/shared/Inputs/GroupSelector/Listbox';

const useStyles = makeStyles(({ palette, spacing }) => ({
  listbox: {
    padding: 0,
    boxSizing: 'border-box',
    display: 'flex',
    '& .MuiInputLabel-outlined': {
      padding: spacing(0, 0.5),
      color: palette.text.primary,
      transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      padding: spacing(0, 0.5),
      color: palette.text.primary,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -12px) scale(0.75)'
    },
    '& .MuiAutocomplete-tag.Mui-disabled': {
      pointerEvents: 'auto',
      cursor: 'default',
    }
  },
  autocompleteTextField: {
    // for future use
  },
  hideTextField: {
    '& .Mui-disabled.MuiAutocomplete-input': {
      display: 'none',
    }
  },
  listboxList: {
    padding: 0,
    margin: 0,
  },
  showAllSelectedChipEnabled: {
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  textFieldLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    justifyItems: 'center',
    gap: 5,
  },
  externalUpdateSpinner: {
    margin: 'auto 5px',
  },
  showAllSelectedChip: {
    margin: 3,
    backgroundColor: palette.background.container,
    color: palette.getContrastText(palette.background.container),

    '& .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 3,

      '& svg': {
        fontSize: '1rem',
      }
    }
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flex: 1,
  },
  genericGroupTag: {
    fontWeight: 900,
    fontSize: 11,
    fontFamily: 'courier new, courier, monaco, monospace',
    marginRight: 4,
    borderRadius: 7,
    backgroundColor: palette.type === 'dark' ?
      palette.background.default
      : palette.primary.main,
    padding: '0 6px',
    color: palette.type === 'dark' ?
      palette.primary.main
      : palette.getContrastText(palette.primary.main),
  },
  groupVendorSpecificId: {
    color: palette.text.primary,
    fontSize: 14,
    opacity: 0.7,
    minWidth: 0,
    maxWidth: 60,
    display: 'flex',
    marginLeft: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  inputRootClassHeightLimitNone: {
    height: 'auto !important'
  }
}));

/**
 * GroupSelector
 *
 * @param {Function} handleChange - Function to handle change of selected groups. Returns full current selected group array.
 * @param {Array} [selectedGroups=[]] - array of selected groups (i.e. what is/will be set on the edited object).\
 * Used for initial value and to show selected groups first.
 * @param {String} [autocompleteClass=] - class for the underlying Autocomplete.
 * @param {String} [textFieldClass=] - class for the Autocomplete's TextField.
 * @param {Boolean} [showLoading=false] - show loading spinner, when doing an external update.
 * @param {Boolean} [disabled=false] - disable the input.
 * @param {Boolean} [showSelectedFirst=true] - show selected groups first.
 * @param {Boolean} [allowDeleteChip=true] - allow to delete chips when not disabled.
 * @param {Boolean} [useChipColors=true] - use group colors for chips, otherwise use default color.
 * @param {Boolean} [showAllSelectedByDefault=false] - Hide items only on demand.
 */
function GroupSelector({
  handleChange,
  selectedGroups,
  textFieldClass,
  autocompleteClass,
  showLoading,
  disabled,
  showSelectedFirst,
  allowDeleteChip,
  useChipColors,
  showAllSelectedByDefault,
  withInputAutoHeight,
  inputRootClass,
  inputPlaceholder,
  withAssignToAll
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [showAllSelected, setShowAllSelected] = useState(showAllSelectedByDefault);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [groups, setGroups] = useState(selectedGroups);
  const [inputValue, setInputValue] = useState(selectedGroups);

  const [
    getGroups,
    { loading },
  ] = useLazyQuery(SEARCH_CUSTOMER_GROUPS, {
    variables: { searchPhrase },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.searchCustomerGroups) {
        const groupsResponse = data.searchCustomerGroups || [];
        setGroups(withAssignToAll ? [ASSIGN_TO_ALL_GROUP, ...groupsResponse] : groupsResponse);
      }
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getGroupsLater = useCallback(debounce(getGroups, 700), [getGroups]);

  useEffect(() => {
    if (withAssignToAll) {
      setGroups([
        ASSIGN_TO_ALL_GROUP,
        ...groups.filter(group => group._id !== ASSIGN_TO_ALL_GROUP._id),
      ]);
    } else {
      setGroups(
        groups.filter(group => group._id !== ASSIGN_TO_ALL_GROUP._id)
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withAssignToAll]);

  useEffect(() => {
    getGroupsLater({ variables: { searchPhrase } });
  }, [searchPhrase, getGroupsLater]);

  useEffect(() => {
    setInputValue(selectedGroups);
  }, [setInputValue, selectedGroups]);

  const toggleShowAllSelected = () => {
    setShowAllSelected(!showAllSelected);
    if (!disabled && inputValue.length > 3) {
      const oldSearchPhrase = searchPhrase;
      // if the input width changed after changing the view, we need to re-render the list
      setIsOpen(false);
      process.nextTick(() => {
        setIsOpen(true);
        setSearchPhrase(oldSearchPhrase);
      });
    }
  };

  const options = showSelectedFirst
    ? uniqBy(selectedGroups.concat(groups), '_id')
    : uniqBy(groups.concat(selectedGroups), '_id');

  const hasVendorSpecificGroups = options.some(group => !!group.vendorId);

  return (
    <Autocomplete
      options={options.filter(opt => opt._id && opt.name)}
      value={inputValue}
      inputValue={searchPhrase}
      onChange={(_, newValue) => {
        handleChange(newValue);
        setSearchPhrase(searchPhrase);
      }}
      multiple
      loading={loading}
      className={classNames(classes.listbox, autocompleteClass)}
      role="listbox"
      ListboxComponent={Listbox}
      filterOptions={filterOptions}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false);
        setSearchPhrase('');
      }}
      disabled={disabled}
      getOptionSelected={(option, value) => option._id === value._id}
      getOptionLabel={(option) => translateGroup(option, t, i18n)}
      disableClearable
      forcePopupIcon={false}
      disableCloseOnSelect
      renderOption={(option, { selected }) => {
        const groupName = translateGroup(option, t, i18n);
        return (
          <Box className={classes.option}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={selected}
            />
            {/* show the generic group tag for convenience */}
            {hasVendorSpecificGroups && !option.vendorId &&
              <span
                className={classes.genericGroupTag}
                title={`ChefsList ${t('customers.group').toLocaleLowerCase()}`}
              >
                CL
              </span>}
            <span>{groupName}</span>
            {option.vendorSpecificId && groupName !== option.vendorSpecificId &&
              <div className={classes.groupVendorSpecificId}>
                <span title={option.vendorSpecificId}>
                  {`#${option.vendorSpecificId}`}
                </span>
              </div>}
          </Box>
        );
      }}
      renderTags={(value, getTagProps) => (
        <>
          {(showAllSelected ? value : value.slice(0, 2)).map((option, index) => {
            const backgroundColor = (useChipColors && option.color)
              || theme.palette.background.container;
            const color = theme.palette.getContrastText(backgroundColor);
            const name = translateGroup(option, t, i18n);
            const label = name?.slice(0, 10) + (name?.length > 10 ? '...' : '');

            return <Chip
              key={option._id}
              label={label}
              style={{ backgroundColor, color, margin: 3 }}
              title={name}
              {...getTagProps({ index })}
              onDelete={!disabled && allowDeleteChip ? getTagProps({ index })?.onDelete : null}
            />;
          })}
          {value.length > 2 && (
            <Chip
              key="showAllSelectedChip"
              label={showAllSelected ?
                <VisibilityOffIcon className={classes.showAllIcon} /> : (
                  <>
                    <span>{`+ ${value.length - 2}`}</span>
                    <VisibilityIcon className={classes.showAllIcon} />
                  </>
                )}
              className={classNames(
                { [classes.showAllSelectedChipEnabled]: !disabled },
                classes.showAllSelectedChip
              )}
              onDelete={null}
              onClick={toggleShowAllSelected}
            />
          )}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <div className={classes.textFieldLabel}>
              {inputPlaceholder && !inputValue.length ? (
                <Typography variant="body2" color="textSecondary">
                  {inputPlaceholder}
                </Typography>
              ) : (
                <span>{`${t('common.customer groups')}: ${inputValue.length || t('common.none')}`}</span>
              )}
              {showLoading &&
                <CircularProgress
                  color="inherit"
                  size={14}
                  className={classes.externalUpdateSpinner}
                />}
            </div>
          }
          variant="outlined"
          onInput={(e) => setSearchPhrase(e.target.value)}
          className={classNames(
            textFieldClass,
            classes.autocompleteTextField,
            { [classes.hideTextField]: disabled && inputValue.length > 0 }
          )}
          InputProps={{
            classes: {
              root: classNames(
                inputRootClass,
                withInputAutoHeight && showAllSelected && classes.inputRootClassHeightLimitNone
              )
            },
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {loading && isOpen ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

GroupSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedGroups: PropTypes.array,
  textFieldClass: PropTypes.string,
  autocompleteClass: PropTypes.string,
  disabled: PropTypes.bool,
  showLoading: PropTypes.bool,
  showSelectedFirst: PropTypes.bool,
  allowDeleteChip: PropTypes.bool,
  useChipColors: PropTypes.bool,
  showAllSelectedByDefault: PropTypes.bool,
  withInputAutoHeight: PropTypes.bool,
  inputRootClass: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  withAssignToAll: PropTypes.bool,
};

GroupSelector.defaultProps = {
  textFieldClass: '',
  autocompleteClass: '',
  disabled: false,
  selectedGroups: [],
  showLoading: false,
  showSelectedFirst: true,
  allowDeleteChip: true,
  useChipColors: true,
  showAllSelectedByDefault: false,
  withInputAutoHeight: false,
  inputRootClass: '',
  inputPlaceholder: null,
  withAssignToAll: false,
};

export default GroupSelector;
