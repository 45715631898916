import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ListIcon from '@material-ui/icons/ListAltRounded';
import EmailIcon from '@material-ui/icons/AlternateEmailRounded';
import CalendarIcon from '@material-ui/icons/DateRangeRounded';
import UnsubscribeIcon from '@material-ui/icons/EmailRounded';
import DisabledIcon from '@material-ui/icons/RemoveCircleRounded';

const useStyles = makeStyles(({ spacing, typography }) => ({
  iconValid: {
    color: '#16db5a',
    marginRight: spacing(0.2),
  },
  iconInvalid: {
    color: '#f54065',
    marginRight: spacing(0.2),
  },
  hidden: {
    display: 'none',
  },
  iconMuted: {
    opacity: 0.3,
    transition: 'opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1)',

    '&:hover': {
      opacity: 1,
    },
  },
  message: {
    boxSizing: 'border-box',
    maxHeight: 26,
    display: 'inline-flex',
    padding: spacing(0.5, 1.5),
    color: '#797A7CAA',
    textTransform: 'capitalize',
    ...typography.body2,
    fontWeight: typography.fontWeightBold,
  },
  noHorizontalPadding: {
    padding: spacing(0.5, 0),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  }
}));

export default function RecipientStatusIcons ({ contactStatus, hideValidIcons, message }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    isValidContact,
    hasLists,
    hasDeliveryDays,
    isValidEmail,
    isUnsubscribed,
    isChefslistEmail,
    isDisabled,
  } = contactStatus;

  const getIconClasses = (isValidField) => {
    if (hideValidIcons && isValidField) {
      return classes.hidden;
    }

    if (isValidContact) {
      return classes.iconValid;
    }

    return classNames(
      classes.iconValid,
      isValidField ? classes.iconMuted : classes.iconInvalid
    );
  };

  const listStatus = hasLists ? 'has_lists' : 'no_lists';
  const deliveryDaysStatus = hasDeliveryDays ? 'has_delivery_days' : 'no_delivery_days';
  const unsubscribedStatus = isUnsubscribed ? 'unsubscribed' : 'not_unsubscribed';
  const disabledStatus = isDisabled ? 'manually_disabled' : 'is_enabled';

  let emailStatus = isValidEmail ? 'valid_email' : 'invalid_email';

  if (!isValidContact && !isValidEmail && isChefslistEmail) {
    emailStatus = 'chefslist_internal_email';
  }

  return (
    <Grid className={classes.container}>
      <Tooltip title={t(`newsletter.filter options.${listStatus}`)}>
        <ListIcon className={getIconClasses(hasLists)}/>
      </Tooltip>
      <Tooltip title={t(`newsletter.filter options.${deliveryDaysStatus}`)}>
        <CalendarIcon className={getIconClasses(hasDeliveryDays)}/>
      </Tooltip>
      <Tooltip title={t(`newsletter.filter options.${emailStatus}`)}>
        <EmailIcon className={getIconClasses(isValidEmail)}/>
      </Tooltip>
      <Tooltip title={t(`newsletter.filter options.${unsubscribedStatus}`)}>
        <UnsubscribeIcon className={getIconClasses(!isUnsubscribed)}/>
      </Tooltip>
      {isDisabled && (
        <Tooltip title={t(`newsletter.filter options.${disabledStatus}`)}>
          <DisabledIcon className={classes.iconInvalid}/>
        </Tooltip>
      )}
      {message && (
        <Typography
          variant="body2"
          className={classNames(
            classes.message,
            isValidContact && classes.noHorizontalPadding
          )}
        >
          {message}
        </Typography>
      )}
    </Grid>
  );
}

RecipientStatusIcons.propTypes = {
  contactStatus: PropTypes.object,
  hideValidIcons: PropTypes.bool,
  message: PropTypes.string,
};

RecipientStatusIcons.defaultProps = {
  contactStatus: {},
  hideValidIcons: false,
  message: '',
};
