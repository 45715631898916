import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';

import { parseGroupColor } from 'helpers/utils';

const useStyles = makeStyles(({ spacing, typography }) => ({
  badgeContainer: {
    padding: spacing(0.5, 1),
    margin: spacing(0.75, 0.5),
    borderRadius: 8,
    width: 'fit-content',
    height: 24,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'content-box',
    maxWidth: '-webkit-fill-available',
  },
  openGroupIcon: {
    marginLeft: spacing(0.75),
    fontSize: typography.fontSize,
  },
  groupTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default function GroupBadge({
  groups,
  isButton,
  onClickAction,
  onlySideMargin,
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return groups.map(group => {
    const translation =
      group.translations &&
      group.translations.find(option => option.lang === i18n.language);
    const defaultTranslation = group.name;
    const groupBackgroundColor = parseGroupColor(group.color || '');
    const groupTextColor = theme.palette.getContrastText(groupBackgroundColor);

    const isClickable = isButton && group.vendorId;

    const groupTitle = translation
      ? translation.value
      : t(`customers.restaurant group.${defaultTranslation}`, {
        defaultValue:
            group.name.slice(0, 1).toUpperCase() + group.name.slice(1),
      });

    return (
      <Grid
        key={group._id}
        container
        wrap="nowrap"
        component={isClickable ? Button : 'div'}
        onClick={isClickable ? onClickAction(group._id) : null}
        className={classes.badgeContainer}
        style={{
          background: groupBackgroundColor,
          color: groupTextColor,
          margin: onlySideMargin && '0px 4px',
        }}
      >
        <Tooltip title={groupTitle}>
          <Typography variant="body2" className={classes.groupTitle}>
            {groupTitle}
          </Typography>
        </Tooltip>
        {isClickable && (
          <OpenInNewRoundedIcon
            className={classes.openGroupIcon}
            style={{ color: groupTextColor }}
            fontSize="inherit"
          />
        )}
      </Grid>
    );
  });
}

GroupBadge.propTypes = {
  onlySideMargin: PropTypes.bool,
  isButton: PropTypes.bool,
  onClickAction: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      translations: PropTypes.array,
      color: PropTypes.string,
    })
  ),
};

GroupBadge.defaultProps = {
  onlySideMargin: false,
  isButton: false,
  onClickAction: () => {},
  groups: [
    {
      _id: null,
      name: '',
      translations: null,
      color: null,
    },
  ],
};
