import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { makeStyles, useTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';

import { parseGroupColor } from 'helpers/utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  groupSelectorRoot: {
    width: 220,
    height: 45,
    fontSize: 14,
    background: palette.background.paper,
    border: palette.border.lightModeOnly,
    color: palette.text.primary,
    [breakpoints.down('sm')]: {
      width: '100%',
      fontSize: 12,
    },
    [breakpoints.up('lg')]: {
      fontSize: 16,
    },
    '&:hover': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
  groupSelectorNotched: {
    border: 'none',
  },
  groupSelectorInput: {
    cursor: 'pointer',
    marginRight: spacing(1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  groupSelectorAdornment: {
    cursor: 'pointer',
  },
  dialogPaper: {
    width: 560,
    padding: spacing(3, 3, 10),
  },
  dialogBackdrop: {
    background: 'rgb(0 0 0 / 0.1)',
  },
  dialogTitle: {
    marginBottom: spacing(7.5),
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: palette.text.primary,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    marginTop: spacing(7),
    height: 48,
    borderRadius: 8,
    boxShadow: 'none',
  },
  groupChipsContainer: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  groupChip: {
    margin: spacing(0.5),
  },
  allRestChipIsActive: {
    background: palette.primary.main,
    color: palette.text.primary,
    '&:hover': {
      background: palette.primary.main,
    },
    '&:focus': {
      background: palette.primary.main,
    },
  },
  inactiveChip: {
    background: 'rgba(202, 202, 202, 0.15)',
    color: palette.secondary.darkGray,
    boxSizing: 'border-box',
    border: `1px solid ${palette.primary.main}`,
    '&:focus': {
      color: palette.text.primary,
    },
    '&:hover': {
      color: palette.text.primary,
    },
  },
  allRestChipContainer: {
    marginBottom: spacing(2),
    paddingBottom: spacing(2),
    borderBottom: palette.border.darkGrey,
  },
  allRestChip: {
    width: '100%',
    height: 48,
    borderRadius: 8,
  },
}));

const ALL_RESTAURANTS = 'ALL';

export default function CustomerGroupModalSelector({
  customerGroups,
  selectedCustomerGroups,
  handleChangeGroupSelector,
  handleConfirm,
  withTextField,
  withEditIcon,
  withAllRestaurantsOption,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [editMode, setEditMode] = React.useState(false);

  return (
    <>
      {withTextField && (
        <TextField
          id="group-selector"
          placeholder={t('common.select group')}
          InputProps={{
            readOnly: true,
            classes: {
              root: classes.groupSelectorRoot,
              notchedOutline: classes.groupSelectorNotched,
              input: classes.groupSelectorInput,
              adornedEnd: classes.groupSelectorAdornment,
            },
            endAdornment: <EditIcon color="inherit" fontSize="small" />,
          }}
          variant="outlined"
          value={selectedCustomerGroups
            .map(selectedId => {
              if (selectedId === ALL_RESTAURANTS) {
                return t('lists.all restaurants');
              } else {
                const groupData = customerGroups.find(
                  group => group._id === selectedId
                );
                if (groupData) {
                  const translation =
                    groupData?.translations &&
                    groupData?.translations.find(
                      option => option.lang === i18n.language
                    );
                  const defaultTranslation = groupData?.name;

                  return translation
                    ? translation.value
                    : t(`customers.restaurant group.${defaultTranslation}`);
                } else return null;
              }
            })
            .filter(group => group)
            .join(', ')}
          onClick={() => setEditMode(true)}
        />
      )}
      {withEditIcon && (
        <Grid item container justifyContent="center">
          <IconButton size="small" onClick={() => setEditMode(true)}>
            <EditIcon color="inherit" fontSize="small" />
          </IconButton>
        </Grid>
      )}
      <Dialog
        open={editMode}
        TransitionComponent={Transition}
        classes={{
          paper: classes.dialogPaper,
        }}
        BackdropProps={{
          classes: {
            root: classes.dialogBackdrop,
          },
        }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <div />
          <Typography variant="h6">{t('common.customer group')}</Typography>
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setEditMode(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid
            className={classes.groupChipsContainer}
            container
            alignItems="center"
          >
            {withAllRestaurantsOption && (
              <Grid container className={classes.allRestChipContainer}>
                <Chip
                  className={classNames(
                    classes.groupChip,
                    selectedCustomerGroups.indexOf(ALL_RESTAURANTS) > -1
                      ? classes.allRestChipIsActive
                      : classes.inactiveChip.at,
                    classes.allRestChip
                  )}
                  label={t('lists.all restaurants')}
                  clickable
                  onClick={handleChangeGroupSelector(ALL_RESTAURANTS)}
                />
              </Grid>
            )}
            {customerGroups &&
              customerGroups.map(group => {
                const translation =
                  group.translations &&
                  group.translations.find(
                    option => option.lang === i18n.language
                  );
                const defaultTranslation = group.name;

                const groupBackgroundColor = parseGroupColor(group.color || '');
                const groupTextColor = theme.palette.getContrastText(
                  groupBackgroundColor
                );

                const groupIsActive =
                  selectedCustomerGroups.indexOf(group._id) > -1;

                return (
                  <Chip
                    key={group._id}
                    className={classNames(
                      classes.groupChip,
                      !groupIsActive && classes.inactiveChip
                    )}
                    clickable
                    onClick={handleChangeGroupSelector(group._id)}
                    label={
                      translation
                        ? translation.value
                        : t(
                            `customers.restaurant group.${defaultTranslation}`,
                            {
                              defaultValue:
                                group.name.slice(0, 1).toUpperCase() +
                                group.name.slice(1),
                            }
                        )
                    }
                    style={{
                      background: groupIsActive && groupBackgroundColor,
                      color: groupIsActive && groupTextColor,
                    }}
                  />
                );
              })}
          </Grid>
          <Button
            className={classes.submitButton}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              setEditMode(false);
              handleConfirm();
            }}
          >
            {t('common.confirm')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

CustomerGroupModalSelector.propTypes = {
  customerGroups: PropTypes.array,
  selectedCustomerGroups: PropTypes.array.isRequired,
  handleChangeGroupSelector: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  withTextField: PropTypes.bool,
  withEditIcon: PropTypes.bool,
  withAllRestaurantsOption: PropTypes.bool,
};

CustomerGroupModalSelector.defaultProps = {
  customerGroups: [],
  withTextField: false,
  withEditIcon: false,
  withAllRestaurantsOption: false,
  handleConfirm: noop,
};
