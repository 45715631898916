import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS } from '../localConstantsAndHelpers';

const useStyles = makeStyles(({ spacing }) => ({
  dialogContainer: {
    height: '70%',
  },
  backdropRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  headingText: {
    padding: spacing(2),
  },
  bodyTooltip: {
    lineHeight: 1.4,
    margin: spacing(1, 0),
    opacity: 0.8,
  }
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const { EXCLUDED, ASSIGNED } = NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS;

export default function DeleteRecipientModal({
  isOpen,
  handleCloseModal,
  vendorClientId,
  email,
  assignmentStatus,
  canDeleteEmail,
  handleDeleteContact,
  handleDeleteConnection,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  let message = '';
  if (assignmentStatus === ASSIGNED) {
    message = t('newsletter.exclude message', { email, vendorClientId });
  } else if (canDeleteEmail) {
    message = t('newsletter.include message with email', { email, vendorClientId });
  } else {
    message = t('newsletter.include message', { vendorClientId });
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      classes={{
        container: classes.dialogContainer,
      }}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        },
      }}
      PaperProps={{
        elevation: 1,
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.headingText}>
        {t('newsletter.confirm action')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {message}
        </Typography>
        {assignmentStatus === EXCLUDED && canDeleteEmail && (
          <div>
            <Typography variant="body2" className={classes.bodyTooltip}>
              {t('newsletter.include with email tooltip 1')}
            </Typography>
            <Typography variant="body2" className={classes.bodyTooltip}>
              {t('newsletter.include with email tooltip 2')}
            </Typography>
          </div>
        )}
        {assignmentStatus === ASSIGNED && (
          <div>
            <Typography variant="body2" className={classes.bodyTooltip}>
              {t('newsletter.exclude message tooltip 1')}
            </Typography>
            <Typography variant="body2" className={classes.bodyTooltip}>
              {t('newsletter.exclude message tooltip 2')}
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {(assignmentStatus === ASSIGNED || canDeleteEmail) && (
          <Button
            onClick={handleDeleteContact}
            color="primary"
          >
            {assignmentStatus === ASSIGNED
              ? t('newsletter.exclude email', { email })
              : t('newsletter.include email', { email })}
          </Button>
        )}
        <Tooltip
          enterDelay={500}
          title={t('newsletter.remove connection tooltip')}
        >
          <Button
            onClick={handleDeleteConnection}
            color="primary"
          >
            {assignmentStatus === ASSIGNED
              ? t('newsletter.exclude customer', { vendorClientId })
              : t('newsletter.include customer', { vendorClientId })}
          </Button>
        </Tooltip>
        <Button
          onClick={handleCloseModal}
          color="secondary"
        >
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteRecipientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired,
  handleDeleteConnection: PropTypes.func.isRequired,
  vendorClientId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  assignmentStatus: PropTypes.string.isRequired,
  canDeleteEmail: PropTypes.bool.isRequired,
};
