import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';

import ListAssignment from 'components/ListsTable/ListAssignment';
import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: `0px ${theme.spacing(1.5)}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1),
    },
  },
  listIdTitle: {
    paddingLeft: theme.spacing(5)
  },
  totalResultColumn: {
    width: 420
  },
}));

export default function ListsTableRowCells({ rowData }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {/*
        FIXME: temporary hide this column
      <TableCell className={classNames(
        classes.tableCell,
        classes.listIdTitle
      )}
      >
        {rowData.listId || <EmptyTableField />}
      </TableCell> */}
      <TableCell
        className={classNames(
          classes.tableCell,
          classes.listIdTitle
        )}
      >
        <Grid container direction="column" wrap="nowrap">
          <Typography variant="body2">
            {rowData.title && rowData.title === 'New List' ?
              t('lists.new list title') : rowData.title}
          </Typography>
          <Typography variant="body2" className={classes.creationDateTitle}>
            {`${t('common.created')}: ${moment(rowData.createdAt).format('DD/MM/YYYY')}`}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ListAssignment
          assignedToAll={rowData.assignedToAll}
          assignedToCustomers={rowData.assignedToCustomers}
          assignedToGroups={rowData.assignedToGroups}
        />
      </TableCell>
      <TableCell className={classNames(
        classes.tableCell,
        classes.totalResultColumn
      )}
      >
        {rowData.products?.totalResults ? (
          <Typography variant="body2">
            {rowData.products.totalResults}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
    </>
  );
}

ListsTableRowCells.propTypes = {
  rowData: PropTypes.object.isRequired,
};
