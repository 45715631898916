import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import EmptyTableField from 'components/shared/EmptyTableField';
import GroupSelector from 'components/shared/Inputs/GroupSelector/GroupSelector';
import IOSSwitch from 'components/shared/IOSSwitch';
import { ReactComponent as PdfIcon } from 'assets/PdfIcon.svg';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: `0px ${theme.spacing(1.5)}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1),
    },
  },
  listIdTitle: {
    paddingLeft: theme.spacing(5),
  },
  pdfIcon: {
    marginRight: theme.spacing(0.5),
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.textBlack,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  groupSelector: {
    display: 'inline-flex',
    maxWidth: 300,
    paddingTop: theme.spacing(2.5),
    marginLeft: -12,

    '& .MuiChip-root.Mui-disabled': {
      opacity: 1,
    },

    '& .Mui-disabled .MuiAutocomplete-endAdornment': {
      opacity: 0,
    },
  },
}));

export default function TableRowCells({
  rowData,
  handleChangeCatalogPricingMode,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleEnableCatalogChange = (e, _id) => {
    const enabled = e.target.checked;
    handleChangeCatalogPricingMode(_id, enabled);
  };

  return (
    <>
      <TableCell className={classNames(classes.tableCell, classes.listIdTitle)}>
        <Grid container direction="column" wrap="nowrap">
          <Typography variant="body2" className={classes.creationDateTitle}>
            {`${t('common.created')}: ${moment(rowData.createdAt).format(
              'DD/MM/YYYY'
            )}`}
          </Typography>
        </Grid>
      </TableCell>

      <TableCell className={classNames(classes.tableCell)}>
        {rowData?.title?.de || <EmptyTableField />}
      </TableCell>

      <TableCell className={classNames(classes.tableCell)}>
        {rowData?.url ? (
          // eslint-disable-next-line
          <a
            href={rowData?.url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <PdfIcon className={classes.pdfIcon} />
            {rowData?.url
              ?.split('/')
              ?.pop()
              ?.split('?')
              ?.shift()
              ?.split('-')
              ?.slice(1)
              ?.join('-')}
          </a>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>

      <TableCell className={classNames(classes.tableCell)}>
        {rowData?.groups?.length ? (
          <GroupSelector
            disabled
            autocompleteClass={classes.groupSelector}
            selectedGroups={rowData.groups}
            handleChange={() => {}}
          />
        ) : (
          <EmptyTableField />
        )}
      </TableCell>

      <TableCell className={classNames(classes.tableCell)}>
        <IOSSwitch
          name="enabled"
          checked={rowData?.enabled}
          onChange={e => handleEnableCatalogChange(e, rowData?._id)}
        />
      </TableCell>
    </>
  );
}

TableRowCells.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleChangeCatalogPricingMode: PropTypes.func.isRequired,
};
