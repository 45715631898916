import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { useMutation } from '@apollo/client';
import { UPDATE_DEALS, DELETE_DEALS } from 'graphql/mutation/deals';
import { GET_DEALS } from 'graphql/query/deals';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import ConfirmIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { NOTIFICATION_STATUS, DATE_STATUS } from 'helpers/constants';
import EditConfirmationModal from 'components/shared/EditConfirmationModal';
import ProductPackaging from 'components/ProductsTable/ProductPackaging';
import DeleteModal from 'components/shared/DeleteModal';
import DealsTableRowCells from 'components/DealsTable/DealsTableRowCells';
import EmptyTableField from 'components/shared/EmptyTableField';
import { truncateTitle, calculateUnitPrice } from 'helpers/utils';
import useMixPanel from 'helpers/useMixPanel';
import PriceInput from './PriceInput';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 60,
    borderBottom: theme.palette.border.lightGrey
  },
  variantRow: {
    height: 60,
  },
  isNotActiveRow: {
    background: theme.palette.type === 'dark' ?
      theme.palette.action.disabledBackground
      : theme.palette.background.default
  },
  tableWithOpenedPanel: {
    background: theme.palette.background.container,
    borderBottom: 'none'
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    maxWidth: 150,
    borderBottom: 'none',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  tableCellPanelOpen: {
    backgroundColor: theme.palette.background.container,
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.button
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.darkGray
  },
  confirmButton: {
    color: theme.palette.success.main,
    margin: theme.spacing(0.5)
  },
  cancelButton: {
    margin: theme.spacing(0.5),
    color: theme.palette.error.main
  },
  expandMoreCell: {
    width: 30,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function DealsTableRow(props) {
  const { refetchDeals } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [updateDeal] = useMutation(UPDATE_DEALS);
  const [deleteDealsMutation] = useMutation(DELETE_DEALS);
  const mixPanel = useMixPanel();

  const [isPanelOpen, setPanelOpen] = React.useState(false);
  const [isDeleteRowModalOpen, setDeleteRowModal] = React.useState(false);
  const [productData, setProductData] = React.useState({
    name: '',
    price: 0,
    dealDetails: {
      start: '',
      end: ''
    },
    variants: [],
    _id: null,
  });

  const {
    rowData,
    isEditMode,
    selected,
    handleSelectItem,
    setEditMode,
    editConfirmationModalIsActive,
    setConfirmEditChanges,
  } = props;

  React.useEffect(() => {
    if (props.rowData) {
      setProductData({
        ...props.rowData,
        variants: props.rowData.variants.filter(variant => (
          !variant.dealDetails?.dealType || variant.dealDetails?.dealType === 'regular'
        )),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData]);

  React.useEffect(() => setPanelOpen(isEditMode), [isEditMode]);

  const handleChangePrice = (value, id) => {
    setProductData({
      ...productData,
      variants: productData.variants.map(variant => {
        if (variant._id === id) {
          return ({
            ...variant,
            dealPrice: value
          });
        } else return variant;
      })
    });
  };

  const handleChangeDate = (value, dateType) => {
    setProductData({
      ...productData,
      dealDetails: {
        ...productData.dealDetails,
        [dateType]: moment(value).utc().startOf('day').toISOString()
      }
    });
  };

  const handleDeleteDeal = async (id) => {
    try {
      await deleteDealsMutation({
        variables: {
          productIds: [id]
        },
        update: (store, { data }) => {
          refetchDeals();
          const allDeals = store.readQuery({ query: GET_DEALS });
          store.writeQuery({
            query: GET_DEALS,
            data: {
              vendorDealProducts: {
                ...allDeals.vendorDealProducts,
                totalResults: allDeals.vendorDealProducts.totalResults - 1,
                products:
                allDeals.vendorDealProducts.products.filter(
                  product =>
                    product.vendorSpecificId !== data?.deleteDeals?.products[0]?.vendorSpecificId
                )
              }
            }
          });
        }
      });
      mixPanel.track('Delete deals', { products: id });
      setNotification({
        variables: {
          timeout: 4000,
          message: `${t('deals.deal delete success')} ${id}`,
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCloseEditModal = () => {
    setProductData(rowData);
    setEditMode(null);
  };

  const handleSubmitDealData = async () => {
    const { vendorSpecificId, dealDetails: { start, end } } = productData;
    if (start && end && moment(start).isAfter(end)) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('deals.date error'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    const newDealPrices = productData.variants
      .map(variant => ({ packaging: variant.packaging, dealPrice: +variant.dealPrice }));

    if (newDealPrices.some(variant => variant.dealPrice < 0)) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('deals.price error'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }
    const newDealsProducts =
       [{
         productId: vendorSpecificId,
         start,
         end,
         dealPrices: newDealPrices
       }];
    try {
      await updateDeal({
        variables: {
          products: newDealsProducts
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setPanelOpen(false);
    setEditMode(null);
  };

  const hasDetailPanel = productData.variants.length > 1;

  const cellClasses = classNames(
    classes.tableCell,
    isPanelOpen && classes.tableCellPanelOpen);

  const isNotActiveRow = !rowData.variants.length
    || rowData.variants.every(variant => !variant.dealPrice)
    || !rowData.dealDetails.start || !rowData.dealDetails.end;

  const getDateStatus = () => {
    const { start, end } = productData.dealDetails || {};
    if (isNotActiveRow) {
      return DATE_STATUS.INACTIVE;
    } else if (moment().isBetween(start, end)) {
      return DATE_STATUS.ACTIVE;
    } else if (moment().isAfter(end)) {
      return DATE_STATUS.OUT_OF_DATE;
    } else if (moment().isBefore(start)) {
      return DATE_STATUS.UPCOMING;
    }

    return DATE_STATUS.INACTIVE;
  };

  const dateStatus = getDateStatus();

  return (
    <>
      <TableRow className={classNames(
        classes.tableBodyRow,
        (isNotActiveRow && !isPanelOpen) && classes.isNotActiveRow,
        isPanelOpen && classes.tableWithOpenedPanel
      )}
      >
        {hasDetailPanel ? (
          <TableCell className={classNames(cellClasses, classes.expandMoreCell)}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setPanelOpen(!isPanelOpen)}
            >
              {isPanelOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell className={cellClasses}/>
        )}
        <TableCell className={cellClasses}>
          <Checkbox
            checked={selected.includes(productData._id)}
            onClick={event => handleSelectItem(event, productData._id)}
          />
        </TableCell>
        <DealsTableRowCells
          productData={productData}
          isEditMode={isEditMode}
          handleChangePrice={handleChangePrice}
          handleChangeDate={handleChangeDate}
          dateStatus={dateStatus}
        />
        <TableCell className={classes.tableCell}>
          <Grid container wrap="nowrap">
            {
              isEditMode ? (
                <>
                  <IconButton
                    onClick={handleSubmitDealData}
                    className={classes.confirmButton}
                    aria-label="confirm"
                    size="small"
                  >
                    <ConfirmIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setConfirmEditChanges(true)}
                    className={classes.cancelButton}
                    aria-label="cancel"
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    classes={{ root: classes.tableActionButton }}
                    onClick={() => setEditMode(productData._id)}
                  >
                    <EditIcon className={classes.tableActionButtonIcon}/>
                  </Button>
                  <Button
                    variant="contained"
                    classes={{ root: classes.tableActionButton }}
                    onClick={() => setDeleteRowModal(true)}
                  >
                    <DeleteIcon className={classes.tableActionButtonIcon}/>
                  </Button>
                </>
              )
            }
          </Grid>
        </TableCell>
      </TableRow>
      {isPanelOpen && productData.variants.slice(1).map(variant => (
        <TableRow key={variant._id} className={classes.variantRow}>
          <TableCell className={cellClasses} colSpan={2} />
          <TableCell className={cellClasses}>
            <Typography variant="body2" align="left">
              {productData.vendorSpecificId}
            </Typography>
          </TableCell>
          <TableCell className={cellClasses}>
            {truncateTitle(productData.name)}
          </TableCell>
          <TableCell className={cellClasses}>
            <PriceInput
              price={variant.dealPrice || ''}
              justify="center"
              handleChangePrice={({ target: { value } }) =>
                handleChangePrice(value, variant._id)}
              isEditMode={isEditMode}
            />
          </TableCell>
          <TableCell className={cellClasses}>
            <ProductPackaging unit={variant.packaging} />
          </TableCell>
          <TableCell className={cellClasses}>
            {calculateUnitPrice(variant.dealPrice, variant.unitsInPackaging)}
          </TableCell>
          <TableCell className={cellClasses}>
            {variant.baseUnit || <EmptyTableField align="center" />}
          </TableCell>
          <TableCell className={cellClasses}>
            {variant.unitsInPackaging || <EmptyTableField align="center" />}
          </TableCell>
          <TableCell colSpan={3} className={cellClasses}/>
        </TableRow>
      )
      )}
      {isEditMode && (
        <EditConfirmationModal
          isOpen={editConfirmationModalIsActive}
          handleCloseModal={setConfirmEditChanges}
          modalContentMessage={t('deals.confirm edit modal message')}
          onConfirm={handleSubmitDealData}
          onCancel={handleCloseEditModal}
        />
      )}
      {isDeleteRowModalOpen && (
        <DeleteModal
          isOpen={isDeleteRowModalOpen}
          message={t('deals.delete deal message')}
          handleCloseModal={() => setDeleteRowModal(false)}
          handleDelete={() => handleDeleteDeal(productData.vendorSpecificId)}
        />
      )}
    </>
  );
}

DealsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  handleSelectItem: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  editConfirmationModalIsActive: PropTypes.bool.isRequired,
  setConfirmEditChanges: PropTypes.func.isRequired,
  refetchDeals: PropTypes.func.isRequired,
};

export default DealsTableRow;
