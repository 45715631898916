import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import ReportIcon from '@material-ui/icons/Assessment';

import DeleteCampaignModal from 'components/shared/DeleteModal';
import EmptyTableField from 'components/shared/EmptyTableField';
import { CAMPAIGN_STATUS } from './campaignConstants';
import getCampaignPeriodTitle from './getCampaignPeriodTitle';

const useStyles = makeStyles(
  ({ palette, spacing, breakpoints }) => ({
    tableBodyRow: {
      height: 56,
      borderBottom: palette.border.lightGrey,
    },
    tableCell: {
      padding: spacing(1, 1.5),
      maxWidth: 150,
      color: palette.secondary.textBlack,
      borderBottom: 'none',
      [breakpoints.up('lg')]: {
        padding: spacing(1),
      },
    },
    campaignTitle: {
      // cursor: 'pointer',
      // fontWeight: typography.fontWeightBold,
      paddingLeft: spacing(2),
    },
    tableActionButton: {
      minWidth: 0,
      width: 32,
      height: 32,
      boxShadow: palette.customShadows.button,
      borderRadius: 6,
      padding: 0,
      margin: spacing(0, 0.5),
      backgroundColor: palette.background.button,
    },
    tableActionButtonIcon: {
      width: 20,
      height: 20,
      color: palette.secondary.darkGray,
    },
  })
);

function CampaignTableRow({
  rowData,
  handleDeleteCampaign,
  handleChangeCampaignStatus,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const handleEditCampaign = async (id) => {
    history.push(`/campaign/${id}`);
  };

  const isActiveStatus = rowData.status === CAMPAIGN_STATUS.ACTIVE;

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell className={classes.tableCell}>
        {rowData.title ? (
          <Typography
            // TODO: temp hide Report page
            onClick={() => history.push(`/campaign/${rowData._id}/report`)}
            className={classes.campaignTitle}
            variant="body2"
          >
            {rowData.title}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography variant="body2">
          {rowData.recipients?.length || 0}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {rowData.schedule ? (
          <Typography variant="body2">
            {getCampaignPeriodTitle(
              rowData.schedule,
              rowData.schedule?.repeat,
              true
            )}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <FormControlLabel
          label={isActiveStatus ? t('common.active') : t('common.inactive')}
          control={
            <Switch
              checked={isActiveStatus || false}
              onChange={(event) =>
                handleChangeCampaignStatus(event, rowData._id)}
              color="primary"
            />
          }
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        {rowData.next ? (
          <Typography variant="body2">
            {moment(rowData.next).format('dddd, MMMM Do')}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Grid container wrap="nowrap">
          <Button
            variant="contained"
            classes={{ root: classes.tableActionButton }}
            onClick={() => handleEditCampaign(rowData._id)}
          >
            <EditIcon className={classes.tableActionButtonIcon} />
          </Button>
          <Button
            variant="contained"
            classes={{ root: classes.tableActionButton }}
            onClick={() => history.push(`/campaign/${rowData._id}/report`)}
          >
            <ReportIcon className={classes.tableActionButtonIcon} />
          </Button>
          <Button
            variant="contained"
            classes={{ root: classes.tableActionButton }}
            onClick={() => setOpenDeleteModal(true)}
          >
            <DeleteIcon className={classes.tableActionButtonIcon} />
          </Button>
        </Grid>
      </TableCell>
      <DeleteCampaignModal
        isOpen={openDeleteModal}
        message={t('campaigns.delete campaign message')}
        handleCloseModal={() => setOpenDeleteModal(false)}
        handleDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteCampaign(rowData._id);
        }}
      />
    </TableRow>
  );
}

CampaignTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleDeleteCampaign: PropTypes.func.isRequired,
  handleChangeCampaignStatus: PropTypes.func.isRequired,
};

export default React.memo(CampaignTableRow, (prevProps, nextProps) =>
  isEqual(prevProps.rowData, nextProps.rowData)
);
