import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: `0px ${theme.spacing(1.5)}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1),
    },
  },
  listIdTitle: {
    paddingLeft: theme.spacing(5)
  },
}));

export default function SalesRepTableRowCells({ rowData }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <TableCell
        className={classNames(
          classes.tableCell,
          classes.listIdTitle
        )}
      >
        <Grid container direction="column" wrap="nowrap">
          <Typography variant="body2">
            {rowData?.contactData?.name || 'n/a'}
          </Typography>
          <Typography variant="body2" className={classes.creationDateTitle}>
            {`${t('common.created')}: ${moment(rowData.createdAt).format('DD/MM/YYYY')}`}
          </Typography>
        </Grid>
      </TableCell>

      <TableCell className={classNames(classes.tableCell)}>
        {rowData?.contactData?.email || <EmptyTableField />}
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Typography variant="body2">
          {rowData?.clientIds?.length}
        </Typography>
      </TableCell>
    </>
  );
}

SalesRepTableRowCells.propTypes = {
  rowData: PropTypes.object.isRequired,
};
