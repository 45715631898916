import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FieldArray, getIn } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';

import { useMutation } from '@apollo/client';
import { GET_VENDOR_CUSTOMERS } from 'graphql/query/vendors';
import { SEND_CONTACT_INVITATION } from 'graphql/mutation/customer';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateInvitationIcon from '@material-ui/icons/PostAddOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import MoreIcon from '@material-ui/icons/MoreVertOutlined';
import MailIcon from '@material-ui/icons/MailOutline';

import {
  NEW_CONTACT,
  NOTIFICATION_STATUS,
  //  USER_ROLES
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
import CustomerStatus from 'components/CustomerTable/CustomerStatus';
import EmptyTableField from 'components/shared/EmptyTableField';
import DetailPanelInput from './DetailPanelInput';
// FIXME: use for roles
// import DetailPanelSelect from './DetailPanelSelect';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(4),
  },
  tableHeaderCell: {
    paddingBottom: theme.spacing(1.5),
    fontSize: theme.typography.htmlFontSize,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: theme.palette.border.grey,
  },
  tableBody: {
    padding: `${theme.spacing(0.5)}px 0px`,
    borderBottom: theme.palette.border.grey,
    fontSize: theme.typography.fontSize,
  },
  addContactButton: {
    padding: `${theme.spacing(0.5)}px 0px`,
    borderBottom: 'none',
  },
  phoneInput: {
    height: 20,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.75)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0.75)}px`,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
  emailContainer: {
    paddingRight: theme.spacing(1.5),
  },
  errorBorder: {
    border: `1.5px solid ${theme.palette.error.main}`,
  },
  popoverPaper: {
    boxShadow: theme.palette.customShadows.popover,
    minWidth: 240,
  },
  actionButton: {
    boxShadow: 'none',
    height: 45,
    color: theme.palette.text.inverted,
  },
  actionButtonLabel: {
    whiteSpace: 'nowrap',
  },
  statusDot: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
    background: 'transparent',
  },

  invited: {
    background: '#D7804F',
  },
  draft: {
    background: theme.palette.secondary.darkGray,
  },
  accepted: {
    background: '#E3BA26',
  },
  ordered: {
    background: theme.palette.success.main,
  },
  rejected: {
    background: theme.palette.error.main,
  },
  registrationCode: {
    border: 0,
    height: 30,
    padding: `
    ${theme.spacing(1)}px 
    ${theme.spacing(1.75)}px 
    ${theme.spacing(1)}px 
    ${theme.spacing(0.75)}px`,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    borderRadius: 4,
    textOverflow: 'ellipsis',
    backgroundColor: 'unset',
  },
}));

export default function DetailPanelCustomerInfo({
  formikChangeHandler,
  formikValues,
  disableInput,
  isEditModeActive,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [contactInvitationMutation] = useMutation(SEND_CONTACT_INVITATION);
  const mixPanel = useMixPanel();

  const [actionsAnchorEl, setActionsAnchorEl] = React.useState(null);
  const [magicCodeCopied, setMagicCodeCopied] = React.useState(0);
  //  HANDLERS

  const handleOpenSelector = (event) => {
    setActionsAnchorEl({
      anchor: event.currentTarget,
      rowId: event.currentTarget.id,
    });
  };

  const handleCloseSelector = () => {
    setActionsAnchorEl(null);
  };

  const copyToClipBoard = (link) => {
    navigator.clipboard.writeText(link);
    setMagicCodeCopied(prevStep => (prevStep !== 5 ? prevStep + 1 : prevStep));
  };

  const handleSendContactInvite = (
    contactId,
    contactEmail,
    notify = true
  ) => async () => {
    if (
      !formikValues.lists.length ||
      formikValues.deliveryDays.every((day) => !day.enabled)
    ) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('customers.invitation message'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    } else {
      const customerId = formikValues._id;
      setActionsAnchorEl(null);

      try {
        await contactInvitationMutation({
          variables: {
            connectionId: customerId,
            contactId,
            notify,
          },
          update: (store, { data: { inviteCustomerContact } }) => {
            const allCustomers = store.readQuery({
              query: GET_VENDOR_CUSTOMERS,
            });
            store.writeQuery({
              query: GET_VENDOR_CUSTOMERS,
              data: {
                vendorCustomers: {
                  ...allCustomers.vendorCustomers,
                  customers: allCustomers.vendorCustomers.customers.map(
                    (customer) =>
                      (inviteCustomerContact &&
                      customer._id === inviteCustomerContact._id
                        ? { ...inviteCustomerContact }
                        : customer)
                  ),
                },
              },
            });
            mixPanel.track('Invite Customer\'s Contact', {
              invitedEmail: contactEmail,
              businessName: inviteCustomerContact?.contactData?.businessName,
              customerId,
              contactId,
              vendorId: inviteCustomerContact?.vendorId,
              vendorClientId: inviteCustomerContact?.vendorClientId,
              ccEmails: inviteCustomerContact?.ccEmails,
              contactsEmails: inviteCustomerContact?.contacts?.map(
                (item) => item?.email
              ),
            });
            if (inviteCustomerContact) {
              setNotification({
                variables: {
                  timeout: 5000,
                  message: `${contactEmail} ${t(
                    'customers.contact successfully invited'
                  )}`,
                  type: NOTIFICATION_STATUS.SUCCESS,
                  isOpen: true,
                },
              });
            }
          },
        });
      } catch (error) {
        console.error(error.message);
        setNotification({
          variables: {
            timeout: 5000,
            message: error.message || t('common.something wrong'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }
  };

  // CONSTANTS

  const ContactRowTitles = [
    {
      title: t('customers.name'),
      widthXs: 2,
    },
    {
      title: t('customers.phone'),
      widthXs: 2,
    },
    {
      title: t('customers.email'),
      widthXs: 2,
    },
    {
      title: t('customers.status'),
      widthXs: 2,
    },
    // FIXME: temporary hide roles while it will be available
    //  {
    //   title: t('common.role'),
    //   widthXs: 2,
    // },
    {
      title: t('customers.Registration Code'),
      widthXs: 2,
    },
    {
      title: t('common.action'),
      widthXs: 2,
    },
  ];

  return (
    <Grid container className={classes.tableContainer}>
      <Grid container className={classes.tableHeaderCell}>
        {ContactRowTitles.map((cell) => (
          <Grid key={cell.title} item xs={cell.widthXs}>
            {cell.title}
          </Grid>
        ))}
      </Grid>
      <FieldArray name="contacts">
        {({ form, push, remove }) => (
          <>
            {formikValues.contacts.map((contact, index) => {
              const selectorIsOpen = Boolean(
                actionsAnchorEl?.rowId === contact._id
              );
              //   FIXME: when roles will be available fix handlers and values
              return (
                <Grid
                  key={contact._id || contact.id}
                  className={classes.tableBody}
                  container
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid item xs={2}>
                    <DetailPanelInput
                      name={`contacts[${index}].name`}
                      value={contact.name}
                      disabled={disableInput}
                      onChange={formikChangeHandler}
                      error={getIn(form.errors, `contacts[${index}].name`)}
                      withTooltip
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {disableInput ? (
                      <DetailPanelInput
                        name={`contacts[${index}].phone`}
                        value={contact.phone}
                        disabled={disableInput}
                        onChange={formikChangeHandler}
                        withTooltip
                      />
                    ) : (
                      <MuiPhoneNumber
                        value={contact.phone}
                        onChange={(phone) =>
                          form.setFieldValue(`contacts[${index}].phone`, phone)}
                        autoFormat={false}
                        defaultCountry="de"
                        regions="europe"
                        style={{ paddingRight: 12 }}
                        InputProps={{
                          classes: {
                            input: classNames(
                              classes.phoneInput,
                              getIn(form.errors, `contacts[${index}].phone`) &&
                                classes.errorBorder
                            ),
                          },
                          disableUnderline: true,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid className={classes.emailContainer} item xs={2}>
                    <DetailPanelInput
                      name={`contacts[${index}].email`}
                      value={contact.email}
                      fullWidth
                      disabled={disableInput}
                      onChange={formikChangeHandler}
                      error={getIn(form.errors, `contacts[${index}].email`)}
                      withTooltip
                    />
                  </Grid>
                  <Grid item xs={2} container alignItems="center" wrap="nowrap">
                    {contact.isNewContact && isEditModeActive ? (
                      <Tooltip
                        title={t('customers.need to create contact')}
                        placement="right"
                      >
                        <span>
                          <EmptyTableField />
                        </span>
                      </Tooltip>
                    ) : (
                      <CustomerStatus
                        customerStatus={contact.status}
                        handleSendInvite={handleSendContactInvite(
                          contact._id,
                          contact.email
                        )}
                        isContact
                      />
                    )}
                  </Grid>
                  {/* FIXME: add when roles will be available */}
                  {/* <Grid item xs={2}>
                    {disableInput ? (
                      <DetailPanelInput
                        name="role"
                        value={USER_ROLES[index]}
                        disabled
                      />
                    ) : (
                      <DetailPanelSelect
                        name="role"
                        onChange={() => {}}
                        value={USER_ROLES[index]}
                        options={USER_ROLES}
                      />)}
                  </Grid> */}
                  <Grid item xs={2}>
                    {classes.registrationCode && (
                      <Tooltip
                        title={t(`customers.copy registration magic code.${magicCodeCopied}`)}
                        placement="right"
                      >
                        <Typography
                          className={classes.registrationCode}
                          component="button"
                          onClick={() => {
                            copyToClipBoard(contact.registrationCode);
                          }}
                        >
                          {contact.registrationCode}
                        </Typography>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton id={contact._id} onClick={handleOpenSelector}>
                      <MoreIcon color="primary" />
                    </IconButton>
                  </Grid>
                  <Popover
                    classes={{ paper: classes.popoverPaper }}
                    open={selectorIsOpen}
                    anchorEl={actionsAnchorEl?.anchor ?? null}
                    onClose={handleCloseSelector}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <List className={classes.root}>
                      <Tooltip
                        title={
                          disableInput ? t('customers.delete tooltip') : ''
                        }
                        placement="left-start"
                      >
                        <span>
                          <ListItem
                            button
                            disabled={disableInput}
                            onClick={async () => {
                              await setActionsAnchorEl(null);
                              remove(index);
                            }}
                          >
                            <ListItemIcon>
                              <DeleteIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={t('common.delete')} />
                          </ListItem>
                        </span>
                      </Tooltip>
                      {contact.inviteLink && (
                        <Tooltip
                          title={t('customers.invite tooltip')}
                          placement="left-start"
                        >
                          <ListItem
                            button
                            onClick={() => {
                              copyToClipBoard(contact.inviteLink);
                              setActionsAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <CopyIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={t('common.copy link')} />
                          </ListItem>
                        </Tooltip>
                      )}
                      {!contact.isNewContact &&
                      !contact.inviteLink &&
                      contact.status !== 'accepted' && (
                        <Tooltip
                          title={t(
                            // eslint-disable-next-line max-len
                            'customers.status message.be sure that you select delivery days and assign list'
                          )}
                          placement="left-start"
                        >
                          <span>
                            <ListItem button disabled>
                              <ListItemIcon>
                                <CreateInvitationIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary={t('common.copy link')} />
                            </ListItem>
                          </span>
                        </Tooltip>
                      )}
                      {!contact.isNewContact && contact.status !== 'accepted' && (
                        <Tooltip
                          title={t('customers.status message.draft')}
                          placement="left-start"
                        >
                          <ListItem
                            button
                            onClick={handleSendContactInvite(
                              contact._id,
                              contact.email,
                              true
                            )}
                          >
                            <ListItemIcon>
                              <MailIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={t(
                                'customers.status action button.draft'
                              )}
                            />
                          </ListItem>
                        </Tooltip>
                      )}
                    </List>
                  </Popover>
                </Grid>
              );
            })}
            <Grid
              container
              className={classNames(
                classes.tableBody,
                classes.addContactButton
              )}
            >
              <Button
                disabled={disableInput}
                variant="text"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => push(NEW_CONTACT())}
              >
                {t('customers.add contact')}
              </Button>
            </Grid>
          </>
        )}
      </FieldArray>
    </Grid>
  );
}

DetailPanelCustomerInfo.propTypes = {
  formikChangeHandler: PropTypes.func.isRequired,
  disableInput: PropTypes.bool.isRequired,
  formikValues: PropTypes.object.isRequired,
  isEditModeActive: PropTypes.bool.isRequired,
};
