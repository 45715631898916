import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import ProcessIcon from '@material-ui/icons/ArchiveOutlined';
import InTheWayIcon from '@material-ui/icons/LocalShippingOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import ConfirmIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { ORDER_STATUS } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  deliveredIcon: {
    color: theme.palette.success.main
  },
  errorIcon: {
    color: theme.palette.error.main
  },
  statusText: {
    marginLeft: theme.spacing(0.5)
  },
  defaultColor: {
    color: theme.palette.text.secondary
  }
}));

const OrderStatusReducer = ({ status }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getOrderStatus = () => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return (
          <>
            <ProcessIcon className={classes.defaultColor} />
            <Typography variant="body2" className={classes.statusText}>
              {t('orders.inProcess')}
            </Typography>
          </>);

      case ORDER_STATUS.DELIVERED:
        return (
          <>
            <ConfirmIcon className={classes.deliveredIcon}/>
            <Typography variant="body2" className={classes.statusText}>
              {t('orders.delivered')}
            </Typography>
          </>);
      case ORDER_STATUS.ACCEPTED:
        return (
          <>
            <InTheWayIcon className={classes.defaultColor}/>
            <Typography variant="body2" className={classes.statusText}>
              {t('orders.onTheWay')}
            </Typography>
          </>);
      case ORDER_STATUS.CANCELED:
        return (
          <>
            <Typography variant="body2" className={classes.statusText}>
              {t('orders.canceled')}
            </Typography>
          </>);
      case ORDER_STATUS.DRAFT:
        return (
          <>
            <Typography variant="body2" className={classes.statusText}>
              {t('orders.draft')}
            </Typography>
          </>);
      default:
        return (
          <>
            <ErrorIcon className={classes.errorIcon}/>
            <Typography variant="body2" className={classes.statusText}>
              Problem
            </Typography>
          </>);
    }
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      {getOrderStatus()}
    </Grid>
  );
};

OrderStatusReducer.propTypes = {
  status: PropTypes.string.isRequired,
};

export default OrderStatusReducer;
