/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_NEWSLETTER_RECIPIENTS } from 'graphql/mutation/newsletters';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  GET_NEWSLETTER_RECIPIENTS,
  GET_VENDOR_NEWSLETTER_FOR_RECIPIENTS_TABLE,
} from 'graphql/query/newsletters';

import { makeStyles } from '@material-ui/styles';

import TableLayout from 'components/shared/TableLayout';
import { NOTIFICATION_STATUS, TABLE_SKELETON_TYPES } from 'helpers/constants';
import RecipientsTableRow from 'components/Newsletter/Recipients/RecipientsTableRow';
import {
  NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS,
  NEWSLETTER_STATUS,
  RECIPIENTS_FILTER_OPTIONS,
  UPDATE_NEWSLETTER_RECIPIENT_ACTION,
} from 'components/Newsletter/localConstantsAndHelpers';
import { parseDataValidationErrors } from 'helpers/getErrorMessage';
import RecipientTableActiveGroups from 'components/Newsletter/Recipients/RecipientTableActiveGroups';
import RecipientEventLogModal from 'components/Newsletter/Recipients/RecipientEventLogModal';

import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(({ spacing }) => ({
  recipientTableContainer: {
    border: 'none',
    padding: spacing(0, 2),
    boxSizing: 'border-box',
  },
  recipientsTableSearchBar: {
    padding: 0,
    height: 'auto',
    width: 'auto',
  },
  columnName: {
    width: 150,
  },
  columnEmail: {
    width: 250,
  },
  columnStatus: {
    maxWidth: 300,
    width: 'auto',
  },
}));

export default function RecipientsTable({
  data,
  state,
  error,
  loading,
  newsletterId,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSortRows,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const assignmentStatus = state?.recipientAssignmentStatus;

  const [updateNewsletterRecipients] = useMutation(
    UPDATE_NEWSLETTER_RECIPIENTS
  );
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const { data: newsletterResponseData } = useQuery(
    GET_VENDOR_NEWSLETTER_FOR_RECIPIENTS_TABLE,
    {
      variables: { id: newsletterId },
    }
  );

  const [isLogModalOpen, setOpenLogModal] = React.useState(false);
  const [recipientEvents, setRecipientEvents] = React.useState([]);

  const newsletterData = newsletterResponseData?.getNewsletter || {};
  const isNewsletterSent = NEWSLETTER_STATUS.SENT === newsletterData?.status;

  const handleDeleteRecipient = async ({ recipientEmail, vendorClientIds }) => {
    const recipients = {
      groupIds: [],
      connectionIds: [],
      vendorClientIds: vendorClientIds ? [vendorClientIds] : [],
      emails: recipientEmail ? [recipientEmail] : [],
    };
    const action =
      assignmentStatus === NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED
        ? UPDATE_NEWSLETTER_RECIPIENT_ACTION[
          NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.EXCLUDED
        ].ADD
        : UPDATE_NEWSLETTER_RECIPIENT_ACTION[
          NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.EXCLUDED
        ].DELETE;

    try {
      await updateNewsletterRecipients({
        variables: {
          id: newsletterId,
          recipients,
          action,
        },
        refetchQueries: [
          {
            query: GET_NEWSLETTER_RECIPIENTS,
            variables: {
              id: newsletterId,
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
              recipientAssignmentStatus: assignmentStatus,
              filter: state.filter,
              search: state.search,
            },
          },
        ],
      });
      mixPanel.track('Newsletter - Recipients -> Delete Recipients', {
        id: newsletterId,
        newsletter: newsletterData,
        recipients,
        action,
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('newsletter.notifications.recipients updated'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (err) {
      const message = parseDataValidationErrors(err.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const tableColumns = [
    {
      title: t('common.name'),
      field: 'name',
      sortable: false,
      columnStyles: classes.columnName,
    },
    {
      title: t('common.email'),
      field: 'email',
      sortable: false,
      columnStyles: classes.columnEmail,
    },
    {
      title: t('settings.business'),
      field: 'businessName',
      sortable: false,
    },
    {
      title: t('customers.customer number'),
      field: 'vendorClientId',
      sortable: false,
    },
    {
      title: t('newsletter.date and time'),
      field: 'date',
      sortable: false,
    },
    {
      title: t('newsletter.status'),
      field: 'status',
      sortable: false,
      columnStyles: classes.columnStatus,
    },
  ];

  const {
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    filter,
    search,
    recipientAssignmentStatus,
  } = state;

  const hasError = !!error || (!loading && !data?.results);

  const getEmptyDataMessage = () => {
    if (filter !== RECIPIENTS_FILTER_OPTIONS.NONE || search !== '') {
      return t('newsletter.empty filters or search results');
    } else if (
      recipientAssignmentStatus ===
      NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED
    ) {
      return t('newsletter.manage assigned recipients empty table');
    } else if (
      recipientAssignmentStatus ===
      NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.EXCLUDED
    ) {
      return t('newsletter.manage excluded recipients empty table');
    } else {
      return t('common.no results');
    }
  };
  const emptyTableDataMessage = getEmptyDataMessage();

  return (
    <>
      <TableLayout
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.NEWSLETTER_RECIPIENTS}
        emptyTableData={hasError || !data?.totalResults}
        emptyTableDataMessage={emptyTableDataMessage}
        tableIsLoading={loading}
        withActions={!isNewsletterSent && !loading}
        noTableHeadUnderline
        noHeaderPaddings
        page={page}
        headerActions={
          <RecipientTableActiveGroups
            recipientAssignmentStatus={state.recipientAssignmentStatus}
            newsletterData={newsletterData}
          />
        }
        tableSearchBarContainerCustomClass={classes.recipientsTableSearchBar}
        rowsPerPage={rowsPerPage}
        customRowsPerPageOptions={[10, 25, 50]}
        totalResults={data?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        itemsOnPage={data?.results?.length}
        tableContainerCustomClass={classes.recipientTableContainer}
      >
        {data?.results?.length &&
          data.results.map(rowData => (
            <RecipientsTableRow
              key={rowData.contactId}
              rowData={rowData}
              assignmentStatus={assignmentStatus}
              isNewsletterSent={isNewsletterSent}
              handleDeleteRecipient={handleDeleteRecipient}
              setOpenLogModal={setOpenLogModal}
              setRecipientEvents={setRecipientEvents}
            />
          ))}
      </TableLayout>
      <RecipientEventLogModal
        isOpen={isLogModalOpen}
        events={recipientEvents}
        handleCloseModal={() => setOpenLogModal(false)}
      />
    </>
  );
}

RecipientsTable.propTypes = {
  data: PropTypes.object,
  state: PropTypes.object.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  newsletterId: PropTypes.string.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleSortRows: PropTypes.func.isRequired,
};

RecipientsTable.defaultProps = {
  data: undefined,
  error: undefined,
};
