import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ADD_CUSTOMERS_TO_SPECIAL_DAY, REMOVE_CUSTOMER_FROM_SPECIAL_DAY } from 'graphql/mutation/specialDays';
import ActionButton from 'components/shared/Buttons/ActionButton';
import { GET_CUSTOMERS_FOR_DAY } from 'graphql/query/specialDays';
import TableLayout from 'components/shared/TableLayout';
import SimpleTextInput from 'components/shared/Inputs/SimpleTextInput';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

function EditCustomers({ dayId, classes, onUpdate }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [removeCustomerFromSpecialDay] = useMutation(REMOVE_CUSTOMER_FROM_SPECIAL_DAY);
  const [assignCustomerFromSpecialDay] = useMutation(ADD_CUSTOMERS_TO_SPECIAL_DAY);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [getCustomersForDay, { data: customersForDay }] = useLazyQuery(GET_CUSTOMERS_FOR_DAY, {
    fetchPolicy: 'network-only',
    variables: {
      searchPhrase: null,
      pageSize: 10,
      after: 0,
    },
  });

  const updateList = () => {
    if (dayId) {
      getCustomersForDay({
        variables: {
          dayId,
          pageSize: rowsPerPage,
          after: page * rowsPerPage,
        },
      });
    }
  };

  const handleDelete = async (customerId) => {
    try {
      await removeCustomerFromSpecialDay({
        variables: {
          dayId,
          customerId,
        },
      });
      await updateList();
      onUpdate();
      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Customers deleted'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (e) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Customers were not deleted'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleAdd = async () => {
    try {
      await assignCustomerFromSpecialDay({
        variables: {
          dayId,
          customerIds: inputValue.split(' ')
        },
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Customers added'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
      await updateList();
      onUpdate();
    } catch (e) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Customers was not added'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  useEffect(() => {
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const columns = [
    {
      title: t('specialDays.customerTableColumnID'),
      field: 'vendorSpecificId',
      sortable: false,
    },
    {
      title: t('specialDays.customerTableColumnBusinessName'),
      field: 'contactData.businessName',
      sortable: false,
    },
    {
      title: t('specialDays.customerTableColumnActions'),
      field: '_id',
      sortable: false,
    },
  ];

  return (
    <>
      <Grid container>
        <Typography variant="h2">{t('specialDays.editCustomers')}</Typography>
      </Grid>
      <Grid container className={classes.customerInputContainer}>
        <SimpleTextInput
          value={inputValue}
          handleChange={(e) => setInputValue(e.target.value)}
          errorsTitle={null}
          placeholder={t('specialDays.Insert customer ids')}
          id="searchCustomer"
          name="searchCustomer"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <Button
                onClick={() => { handleAdd(); }}
                onMouseDown={() => {}}
                edge="end"
                color="secondary"
                className={classes.addCustomerButton}
              >
                {t('specialDays.Add customers')}
              </Button>
            </InputAdornment>
          }
        />
      </Grid>
      <TableLayout
        columns={columns}
        title={t('specialDays.customers')}
        skeletonType=""
        handleChangePage={(newPage) => { setPage(newPage); }}
        handleChangeRowsPerPage={(newRowsPerPage) => { setRowsPerPage(newRowsPerPage); }}
      >
        {customersForDay?.getSpecialDayCustomersPaginated?.customers?.map((customer) => (
          <TableRow key={customer._id}>
            <TableCell>{customer.vendorClientId}</TableCell>
            <TableCell>{customer.contactData.businessName}</TableCell>
            <TableCell>
              <ActionButton
                onClick={() => { handleDelete(customer._id); }}
              >
                <DeleteIcon />
              </ActionButton>
            </TableCell>
          </TableRow>
        ))}
      </TableLayout>
    </>
  );
}

EditCustomers.propTypes = {
  dayId: PropTypes.string.isRequired,
  classes: PropTypes.object,
  onUpdate: PropTypes.func,
};

EditCustomers.defaultProps = {
  classes: {},
  onUpdate: () => {},
};

export default EditCustomers;
