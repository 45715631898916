import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 44,
    height: 26,
    // display: 'flex',
    // alignItems: 'center',
    padding: 0,
    overflow: 'visible',
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    // height: 26,
    '&$checked': {
      transform: 'translateX(17px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#009E23',
        opacity: 1,
        border: '1px solid transparent',
      },
    },
    '&$focusVisible $thumb': {
      color: '#009E23',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 25,
    height: 25,
    border: theme.palette.border.lightGrey,
    color: theme.palette.background.white,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

export default IOSSwitch;
