/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useMutation } from '@apollo/client';
import { GET_VENDOR_CUSTOMERS } from 'graphql/query/vendors';
import { DELETE_VENDOR_CUSTOMER, SEND_VENDOR_INVITATION } from 'graphql/mutation/customer';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import TableLayout from 'components/shared/TableLayout';
import CustomerTableRow from 'components/CustomerTable/CustomerTableRow';
import {
  CUSTOMER_DATA,
  NOTIFICATION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import ModalCsvCustomerUpload from 'components/CustomerTable/ModalCsvCustomerUpload';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  tableSearchBarButtons: {
    height: 45,
  },
  addCustomerButton: {
    margin: `0px ${theme.spacing(2.5)}px`,
    padding: `0px ${theme.spacing(2.5)}px`,
    border: theme.palette.border.lightGrey,
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  customerBtnLabel: {
    whiteSpace: 'nowrap',
    padding: `0px ${theme.spacing(2.5)}px`
  },
  creationDateTitle: {
    color: theme.palette.secondary.darkGray
  },
  connectionColumn: {
    width: 340
  }
}));

export default function CustomerLayout({
  vendorCustomers,
  getVendorCustomers,
  vendorsLoading,
  vendorsError,
  getCustomersSearch,
  searchData,
  searchLoading,
  allCustomerGroups,
  editModeId,
  editConfirmationModal,
  setConfirmEditChanges,
  handleSetEditMode,
  vendorHasFtpIntegration,
  isAdmin
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [deleteCustomerMutation] = useMutation(DELETE_VENDOR_CUSTOMER);
  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [customerInvitationMutation] = useMutation(SEND_VENDOR_INVITATION);

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
    selectedCustomerId: null
  });

  const [addNewCustomer, setAddingNewCustomer] = React.useState(false);
  const [isCsvModalOpen, setCsvModalOpen] = React.useState(false);

  const [showSearchResults, setShowSearchResult] = React.useState(false);
  const [addNewListIsActive, setAddNewListIsActive] = React.useState(false);

  const customerRef = React.useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const handleSetDefaultSorting = ({ sortBy, sortOrder, page }) =>
    setState({
      ...state, sortBy, sortOrder, page
    });

  const handleClickAway = () => (editModeId ? setConfirmEditChanges(true) : null);

  const handleOpenNewListEditor = () => {
    setAddNewListIsActive(true);
    setConfirmEditChanges(true);
  };

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;
    const selectedCustomerId = queries.id || null;

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
        selectedCustomerId
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = vendorCustomers?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && (totalPages <= state.page);
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(state.rowsPerPage);

    if (vendorCustomers && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        })
      });
      window.location.reload();
    }
  }, [vendorCustomers]);

  // PAGINATION HANDLERS

  const handleChangePage = async (event, page, search) => {
    if (showSearchResults) {
      const searchPhrase = search || queries.search;
      try {
        await getCustomersSearch({
          variables: {
            searchPhrase,
            pageSize: state.rowsPerPage,
            after: page + 1,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    } else if (vendorCustomers &&
            (vendorCustomers.hasNextPage || page + 1 < vendorCustomers.totalPages)
    ) {
      try {
        await getVendorCustomers({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page, selectedCustomerId: null });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({ ...queryString.parse(location.search), page, id: '' })
    });
  };

  const handleChangeRowsPerPage = async (event, searchPhrase) => {
    const rowsPerPage = +event.target.value;
    if (showSearchResults) {
      try {
        await getCustomersSearch({
          variables: {
            searchPhrase,
            pageSize: rowsPerPage,
            after: 1,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    } else {
      try {
        await getVendorCustomers({
          variables: {
            pageSize: rowsPerPage,
            after: 1,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({
      ...state, rowsPerPage, page: 0, selectedCustomerId: null
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0
      })
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getVendorCustomers({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, sortOrder, sortBy: sortLabel, page: 0
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder
      })
    });
  };

  // CUSTOMER SEARCH

  const handleSearchBar = async (searchPhrase) => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await getCustomersSearch({
        variables: {
          searchPhrase,
          pageSize: state.rowsPerPage,
        }
      });
    } catch (error) {
      console.error('SEARCH_CUSTOMERS_ERROR', error.message);
    }
  };

  const handleResetSearch = () => {
    setShowSearchResult(false);
  };

  // CUSTOMER ROW MUTATIONS

  const handleDeleteRow = async (connectionId, customerName) => {
    try {
      await deleteCustomerMutation({
        variables: { connectionId },
        refetchQueries: [{
          query: GET_VENDOR_CUSTOMERS,
          variables: {
            pageSize: state.rowsPerPage,
            after: state.page + 1,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        }],
      });
      mixPanel.track('Delete customer', {
        connectionId,
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('customers.delete customer success', { customerName }),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleSendInvitation = async (customerData, notify = true) => {
    if (!customerData.lists.length
            || customerData.deliveryDays.every(day => !day.enabled)) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('customers.invitation message'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    } else {
      try {
        await customerInvitationMutation({
          variables: {
            connectionIds: [customerData._id],
            notify
          },
          update: (store, { data: { inviteCustomers } }) => {
            const allCustomers = store.readQuery({
              query: GET_VENDOR_CUSTOMERS,
            });

            const invitedCustomer = inviteCustomers[0];
            store.writeQuery({
              query: GET_VENDOR_CUSTOMERS,
              data: {
                vendorCustomers: {
                  ...allCustomers.vendorCustomers,
                  customers: allCustomers.vendorCustomers.customers.map(
                    customer => (customer._id === invitedCustomer._id ? invitedCustomer : customer)
                  )
                }
              }
            });
            if (invitedCustomer) {
              setNotification({
                variables: {
                  timeout: 5000,
                  // eslint-disable-next-line max-len
                  message: `${invitedCustomer.contactData.businessName} ${t(`customers.status notification.${invitedCustomer.status}`)}`,
                  type: NOTIFICATION_STATUS.SUCCESS,
                  isOpen: true,
                },
              });
            }
          }
        });
      } catch (error) {
        console.error(error.message);
        setNotification({
          variables: {
            timeout: 5000,
            message: t('common.something wrong'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }
  };

  const tableColumns = [
    {
      title: t('customers.number'),
      field: 'vendorClientId',
      sortable: true,
    },
    {
      title: t('common.created'),
      field: 'createdAt',
      sortable: true,
      columnStyles: classes.connectionColumn,
    },
    {
      title: t('customers.lists'),
      field: 'lists',
      sortable: true,
    },
    // FIXME Temporary hide pricing
    // {
    //   title: t('customers.pricing'),
    //   field: 'pricing',
    //   sortable: false,
    // },
    {
      title: t('customers.status'),
      field: 'status',
      sortable: true,
    },
    {
      title: t('customers.group'),
      field: 'group',
      sortable: true,
    },
    {
      title: t('customers.delivery days'),
      field: 'deliveryDays',
      sortable: false,
    },
  ];

  const {
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    selectedCustomerId
  } = state;

  const hasError = vendorsError ||
        (!vendorsLoading && !vendorCustomers) || !vendorCustomers.customers;
  const searchResultHasError = showSearchResults && !searchData?.searchVendorCustomers?.customers;
  const tableIsLoading = vendorsLoading || searchLoading;

  const getTotalResults = () => {
    if (showSearchResults) {
      return searchResultHasError ? 0 : searchData.searchVendorCustomers.totalResults;
    } else {
      return hasError ? 0 : vendorCustomers.totalResults;
    }
  };

  return (
    <>
      <TableLayout
        handleClickAway={handleClickAway}
        isEditMode={Boolean(editModeId)}
        title={t('customers.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.CUSTOMERS}
        emptyTableData={
          ((hasError && !showSearchResults) || searchResultHasError) && !addNewCustomer
        }
        emptyTableDataMessage={t('customers.empty customer data')}
        tableIsLoading={tableIsLoading}
        withSearchBar
        withDetailPanel
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={getTotalResults()}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        searchPlaceholder={t('customers.search placeholder')}
        handleSearchBar={handleSearchBar}
        handleShowSearchResult={handleResetSearch}
        urlSearchParameter={queries.search || null}
        addNewCustomer={addNewCustomer}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        headerActions={[
          <Button
            key="add customer action"
            className={classNames(classes.addCustomerButton, classes.tableSearchBarButtons)}
            classes={{ label: classes.customerBtnLabel }}
            variant="outlined"
            startIcon={<AddCircleOutlineIcon color="primary" />}
            onClick={() => setAddingNewCustomer(true)}
          >
            {t('customers.add customer')}
          </Button>,
          <Button
            key="upload csv action"
            className={classes.tableSearchBarButtons}
            classes={{ label: classes.customerBtnLabel }}
            variant="text"
            color="primary"
            onClick={() => setCsvModalOpen(true)}
          >
            {t(vendorHasFtpIntegration ? 'customers.download csv' : 'customers.upload csv')}
          </Button>,
        ]}
      >
        {addNewCustomer &&
        <CustomerTableRow
          isNewCustomer
          rowData={CUSTOMER_DATA}
          tableIsLoading={false}
          setAddingNewCustomer={setAddingNewCustomer}
          handleSendInvitation={handleSendInvitation}
          isEditMode={CUSTOMER_DATA._id === editModeId}
          setEditMode={handleSetEditMode}
          editConfirmationModalIsActive={editConfirmationModal}
          setConfirmEditChanges={setConfirmEditChanges}
          handleSetDefaultSorting={handleSetDefaultSorting}
          handleOpenNewListEditor={handleOpenNewListEditor}
          addNewListIsActive={addNewListIsActive}
          allCustomerGroups={allCustomerGroups}
        />}
        {(showSearchResults && !searchResultHasError) &&
        searchData.searchVendorCustomers.customers
          .map((rowData) => rowData && (
            <CustomerTableRow
              key={rowData._id}
              executeScroll={() => scrollToRef(customerRef)}
              customerRef={customerRef}
              selectedCustomerId={selectedCustomerId}
              isEditMode={rowData._id === editModeId}
              setEditMode={handleSetEditMode}
              editConfirmationModalIsActive={editConfirmationModal}
              setConfirmEditChanges={setConfirmEditChanges}
              handleSetDefaultSorting={handleSetDefaultSorting}
              handleOpenNewListEditor={handleOpenNewListEditor}
              addNewListIsActive={addNewListIsActive}
              handleDeleteRow={handleDeleteRow}
              handleSendInvitation={handleSendInvitation}
              rowData={rowData}
              allCustomerGroups={allCustomerGroups}
            />
          ))}
        {(!hasError && !showSearchResults) && vendorCustomers.customers
          .map((rowData) => rowData && (
            <CustomerTableRow
              key={rowData._id}
              executeScroll={() => scrollToRef(customerRef)}
              customerRef={customerRef}
              selectedCustomerId={selectedCustomerId}
              isEditMode={rowData._id === editModeId}
              setEditMode={handleSetEditMode}
              editConfirmationModalIsActive={editConfirmationModal}
              setConfirmEditChanges={setConfirmEditChanges}
              handleSetDefaultSorting={handleSetDefaultSorting}
              handleOpenNewListEditor={handleOpenNewListEditor}
              addNewListIsActive={addNewListIsActive}
              handleDeleteRow={handleDeleteRow}
              handleSendInvitation={handleSendInvitation}
              rowData={rowData}
              allCustomerGroups={allCustomerGroups}
            />
          ))}
      </TableLayout>
      <Prompt
        when={Boolean(editModeId) && !addNewListIsActive}
        message={t('common.router message')}
      />
      {isCsvModalOpen && (
        <ModalCsvCustomerUpload
          isOpen={isCsvModalOpen}
          isAdmin={isAdmin}
          vendorHasFtpIntegration={vendorHasFtpIntegration}
          handleClose={() => setCsvModalOpen(false)}
        />
      )}
    </>
  );
}

CustomerLayout.propTypes = {
  vendorCustomers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getVendorCustomers: PropTypes.func.isRequired,
  vendorsLoading: PropTypes.bool.isRequired,
  vendorsError: PropTypes.object,
  getCustomersSearch: PropTypes.func.isRequired,
  searchData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  searchLoading: PropTypes.bool.isRequired,
  allCustomerGroups: PropTypes.array,
  editModeId: PropTypes.string,
  handleSetEditMode: PropTypes.func.isRequired,
  editConfirmationModal: PropTypes.bool.isRequired,
  setConfirmEditChanges: PropTypes.func.isRequired,
  vendorHasFtpIntegration: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

CustomerLayout.defaultProps = {
  searchData: null,
  vendorsError: null,
  editModeId: null,
  vendorCustomers: {},
  allCustomerGroups: [],
};
