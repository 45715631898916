/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation createVendorCategory($data: VendorCategoryInput!) {
    createVendorCategory(data: $data) {
      _id
      name
      vendorId
      icon
      image
      totalProducts
      mappedCategories
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteVendorCategory($id: ID!) {
    deleteVendorCategory(_id: $id) {
      _id
      name
      image
      vendorId
      totalProducts
      icon
      mappedCategories
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateVendorCategory($data: UpdateVendorCategoryInput!) {
    updateVendorCategory(data: $data) {
      _id
      name
      vendorId
      image
      icon
      totalProducts
      mappedCategories
    }
  }
`;

export const UPLOAD_CATEGORY_IMAGE = gql`
mutation uploadVendorCategoryImage($file: Upload!, $categoryId: ID!) {
  uploadVendorCategoryImage(file: $file, categoryId: $categoryId) {
    _id
    name
    vendorId
    image
    totalProducts
    mappedCategories
    icon
  }
}
`;

export const UPLOAD_SUB_CATEGORY_IMAGE = gql`
  mutation uploadVendorSubCategoryImage($file: Upload!, $categoryTitle: String!) {
    uploadVendorSubCategoryImage(file: $file, categoryTitle: $categoryTitle) {
      _id
      name
      vendorId
      image
      mappedCategories
      icon
    }
  }
`;

export const REMOVE_SUB_CATEGORY_IMAGE = gql`
  mutation deleteVendorSubCategoryImage($categoryTitle: String!) {
    deleteVendorSubCategoryImage(categoryTitle: $categoryTitle) {
      _id
      name
      vendorId
      image
      icon
      totalProducts
      mappedCategories
    }
  }
`;

export const REMOVE_CATEGORY_IMAGE = gql`
mutation deleteVendorCategoryImage($categoryId: ID!) {
deleteVendorCategoryImage(categoryId: $categoryId) {
  _id
  name
  vendorId
  image
  icon
  totalProducts
  mappedCategories
}
}
`;
