import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CustomPopover from 'components/shared/Popover/CustomPopover';
import { NEWSLETTER_STATUS } from '../localConstantsAndHelpers';

const useStyles = makeStyles(({ spacing, typography }) => ({
  newsletterActionListItem: {
    padding: spacing(1.5, 3),
    minWidth: 165
  },
  newsletterActionListItemText: {
    ...typography.body2,
    fontWeight: typography.fontWeightBold
  }
}));

function NewsletterActionMenu({
  anchorEl,
  status,
  handleCloseSelector,
  handleViewNewsletterRecipients,
  handleDuplicateNewsletter,
  handleDeleteNewsletter
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectorIsOpen = Boolean(anchorEl);
  const isDeleteAvailable = status === NEWSLETTER_STATUS.DRAFT;

  return (
    <CustomPopover
      selectorIsOpen={selectorIsOpen}
      anchorEl={anchorEl}
      handleCloseSelector={handleCloseSelector}
    >
      <List
        component="nav"
        aria-label="list titles"
        disablePadding
        onClick={handleCloseSelector}
      >
        <ListItem
          className={classes.newsletterActionListItem}
          button
          onClick={handleDuplicateNewsletter}
        >
          <ListItemText
            classes={{ primary: classes.newsletterActionListItemText }}
            primary={t('newsletter.duplicate')}
          />
        </ListItem>
        <ListItem
          className={classes.newsletterActionListItem}
          button
          onClick={handleViewNewsletterRecipients}
        >
          <ListItemText
            classes={{ primary: classes.newsletterActionListItemText }}
            primary={t('newsletter.view recipients')}
          />
        </ListItem>
        {isDeleteAvailable && (
          <ListItem
            className={classes.newsletterActionListItem}
            button
            onClick={handleDeleteNewsletter}
          >
            <ListItemText
              classes={{ primary: classes.newsletterActionListItemText }}
              primary={t('common.delete')}
            />
          </ListItem>
        )}
      </List>
    </CustomPopover>
  );
}

NewsletterActionMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  status: PropTypes.string.isRequired,
  handleCloseSelector: PropTypes.func.isRequired,
  handleViewNewsletterRecipients: PropTypes.func.isRequired,
  handleDeleteNewsletter: PropTypes.func.isRequired,
  handleDuplicateNewsletter: PropTypes.func.isRequired,
};

NewsletterActionMenu.defaultProps = {
  anchorEl: null,
};

export default NewsletterActionMenu;
