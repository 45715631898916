import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import RequiredIcon from '@material-ui/icons/CheckCircleRounded';
import NotRequiredIcon from '@material-ui/icons/CancelRounded';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import DeleteModal from 'components/shared/DeleteModal';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 60,
    borderBottom: theme.palette.border.lightGrey,
  },
  variantRow: {
    height: 60,
  },
  tableWithOpenedPanel: {
    background: theme.palette.background.container,
    borderBottom: 'none',
  },
  tableCell: {
    padding: theme.spacing(1, 1.5),
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 1),
    },
  },
  tableCellPanelOpen: {
    backgroundColor: theme.palette.background.container,
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: theme.spacing(0, 0.5),
    backgroundColor: theme.palette.background.button,
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.darkGray,
  },
  expandMoreCell: {
    width: 30,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  productAttributesActions: {
    width: 100,
  },
}));

function ProductAttributeTableRow({
  rowData,
  selected,
  handleSelectItem,
  handleDeleteAttribute,
  handleOpenEditModal,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isPanelOpen, setPanelOpen] = React.useState(false);
  const [isDeleteRowModalOpen, setDeleteRowModal] = React.useState(false);

  const handleEditAttributeModal = () => {
    handleOpenEditModal(rowData);
  };

  const hasDetailPanel = rowData.options.length > 1;

  const cellClasses = classNames(
    classes.tableCell,
    isPanelOpen && classes.tableCellPanelOpen
  );

  return (
    <>
      <TableRow
        className={classNames(
          classes.tableBodyRow,
          isPanelOpen && classes.tableWithOpenedPanel
        )}
      >
        {hasDetailPanel ? (
          <TableCell
            className={classNames(cellClasses, classes.expandMoreCell)}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setPanelOpen(!isPanelOpen)}
            >
              {isPanelOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell className={cellClasses} />
        )}
        <TableCell align="center" className={cellClasses}>
          <Checkbox
            checked={selected.includes(rowData._id)}
            onClick={event => handleSelectItem(event, rowData._id)}
          />
        </TableCell>
        <TableCell className={cellClasses}>
          <Typography variant="body2" align="left">
            {rowData.name}
          </Typography>
        </TableCell>
        <TableCell className={cellClasses}>
          <Typography variant="body2" align="left">
            {rowData.options?.[0]?.name || ''}
          </Typography>
        </TableCell>
        <TableCell className={cellClasses}>
          {rowData.isRequired ? (
            <RequiredIcon color="primary" />
          ) : (
            <NotRequiredIcon color="secondary" />
          )}
        </TableCell>
        <TableCell className={cellClasses}>
          <Typography variant="body2" align="left">
            {rowData?.products?.totalResults || 0}
          </Typography>
        </TableCell>
        <TableCell
          className={classNames(
            classes.tableCell,
            classes.productAttributesActions
          )}
        >
          <Grid container wrap="nowrap">
            <Button
              variant="contained"
              classes={{ root: classes.tableActionButton }}
              onClick={handleEditAttributeModal}
            >
              <EditIcon className={classes.tableActionButtonIcon} />
            </Button>
            <Button
              variant="contained"
              classes={{ root: classes.tableActionButton }}
              onClick={() => setDeleteRowModal(true)}
            >
              <DeleteIcon className={classes.tableActionButtonIcon} />
            </Button>
          </Grid>
        </TableCell>
      </TableRow>
      {isPanelOpen &&
        rowData.options.slice(1).map(option => (
          <TableRow key={option._id} className={classes.variantRow}>
            <TableCell className={cellClasses} colSpan={3} />
            <TableCell className={cellClasses}>{option.name}</TableCell>

            <TableCell colSpan={3} className={cellClasses} />
          </TableRow>
        ))}
      {isDeleteRowModalOpen && (
        <DeleteModal
          isOpen={isDeleteRowModalOpen}
          message={t('product attributes.delete attribute modal message', {
            attributeName: rowData.name,
          })}
          handleCloseModal={() => setDeleteRowModal(false)}
          handleDelete={() => handleDeleteAttribute([rowData._id])}
        />
      )}
    </>
  );
}

ProductAttributeTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  handleSelectItem: PropTypes.func.isRequired,
  handleDeleteAttribute: PropTypes.func.isRequired,
  handleOpenEditModal: PropTypes.func.isRequired,
};

export default ProductAttributeTableRow;
