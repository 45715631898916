import { gql } from '@apollo/client';
import { GROUP_FRAGMENT } from 'graphql/fragments';

export const CREATE_CUSTOM_VENDOR_GROUP = gql`
  ${GROUP_FRAGMENT}
  mutation createCustomVendorGroup($name: String!, $color: String) {
    createCustomVendorGroup(name: $name, color: $color) {
      ...GroupFragment
    }
  }
`;

export const UPDATE_CUSTOM_VENDOR_GROUP = gql`
  ${GROUP_FRAGMENT}
  mutation updateCustomVendorGroup(
    $groupId: String!
    $name: String!
    $color: String
  ) {
    updateCustomVendorGroup(groupId: $groupId, name: $name, color: $color) {
      ...GroupFragment
    }
  }
`;

export const ASSIGN_CUSTOMERS_TO_CUSTOM_GROUP = gql`
  mutation assignCustomersToCustomGroup(
    $groupId: ID!
    $customerIds: [String]!
  ) {
    assignCustomersToCustomGroup(groupId: $groupId, customerIds: $customerIds) {
      groupId
      customerIds
      failedCustomerIds
      updatedCustomerIds
      existingCustomerIds
    }
  }
`;

export const UNASSIGN_CUSTOMERS_TO_CUSTOM_GROUP = gql`
  mutation UnassignCustomersFromCustomGroup(
    $groupId: ID!
    $customerIds: [String]
  ) {
    unassignCustomersFromCustomGroup(
      groupId: $groupId
      customerIds: $customerIds
    ) {
      groupId
      customerIds
      failedCustomerIds
      updatedCustomerIds
    }
  }
`;

export const DELETE_CUSTOM_VENDOR_GROUP = gql`
  ${GROUP_FRAGMENT}
  mutation deleteCustomVendorGroup($groupId: String!) {
    deleteCustomVendorGroup(groupId: $groupId) {
      ...GroupFragment
    }
  }
`;
