import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  tabPanel: {
    padding: theme.spacing(2),
    display: 'none',
  },
  tabPanelActive: {
    display: 'flex',
  }
}));

function TabPanel({ children, value, index, className }) {
  const classes = useStyles();

  return (
    <Grid
      container
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className={classNames({
        [className]: true,
        [classes.tabPanel]: true,
        [classes.tabPanelActive]: value === index,
      })}
    >
      {value === index && children}
    </Grid>
  );
}

TabPanel.defaultProps = {
  className: '',
};

TabPanel.propTypes = {
  children: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TabPanel;
