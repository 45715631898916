import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

import ModalLayout from 'components/shared/ModalLayout';
import { NOTIFICATION_STATUS } from 'helpers/constants';

import { useMutation } from '@apollo/client';
import { SINGLE_IMAGE_UPLOAD } from 'graphql/mutation/fileUploading';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { REMOVE_PRODUCT_IMAGE } from 'graphql/mutation/products';
import { GET_PRODUCTS } from 'graphql/query/products';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginTop: theme.spacing(3.5),
    height: 50,
  },
  imageContainer: {
    padding: `${theme.spacing(1.5)}px 0px ${theme.spacing(4.5)}px`,
  },
  imagePreviewContainer: {
    height: 150,
    width: 150,
    borderRadius: 4,
    border: theme.palette.border.darkGrey,
    position: 'relative',
  },
  noImage: {
    background: theme.palette.grey[50],
    color: '#DADADA',
  },
  noticeText: {
    width: 170,
    color: theme.palette.secondary.darkGray,
    margin: `${theme.spacing(1)}px 0px`,
  },
  dragNDropContainer: {
    height: 145,
    border: '1.5px dashed #D8D8D8',
    borderRadius: 4,
    outline: 'none',
    '&:focus': {
      border: `1.5px dashed ${theme.palette.primary.main}`,
    },
  },
  uploadPhotoButton: {
    color: theme.palette.secondary.darkGray,
    textTransform: 'capitalize',
  },
  imagePreview: {
    height: 146,
    maxWidth: '100%',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    width: 40,
    height: 40,
  },
}));

export default function ModalImageUpload({ isOpen, imageData, handleClose }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [files, setFiles] = React.useState([]);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [fileUploadMutation, { loading: fileLoading }] = useMutation(
    SINGLE_IMAGE_UPLOAD
  );
  const [removeImageMutation] = useMutation(REMOVE_PRODUCT_IMAGE);

  const productId = imageData?.id;

  const handleCloseUploadModal = () => {
    setFiles([]);
    handleClose();
  };

  const { getRootProps, getInputProps, open: openDragNDrop } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    onDrop: async acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    noClick: true,
    multiple: false,
  });

  const processImageUpload = async () => {
    try {
      const uploadedFile = files && files[0];
      const options = {
        maxSizeMb: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(uploadedFile, options);
      await fileUploadMutation({
        variables: { file: compressedFile, productId },
        update: (
          store,
          {
            data: {
              uploadImage: { image },
            },
          }
        ) => {
          if (image) {
            const { vendorProducts } = store.readQuery({ query: GET_PRODUCTS });

            store.writeQuery({
              query: GET_PRODUCTS,
              data: {
                vendorProducts: {
                  ...vendorProducts,
                  products: vendorProducts.products.map(product => {
                    if (product._id === productId) {
                      return {
                        ...product,
                        image,
                      };
                    } else return product;
                  }),
                },
              },
            });

            setNotification({
              variables: {
                timeout: 4000,
                message: t('products.image modal.success message'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
            handleCloseUploadModal();
          }
        },
      });
    } catch (error) {
      console.error('ERROR', error);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message,
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleDeletePhoto = async () => {
    try {
      await removeImageMutation({
        variables: { productId },
        update: (store, { data: { removeImage } }) => {
          if (removeImage) {
            const { vendorProducts } = store.readQuery({ query: GET_PRODUCTS });

            store.writeQuery({
              query: GET_PRODUCTS,
              data: {
                vendorProducts: {
                  ...vendorProducts,
                  products: vendorProducts.products.map(product => {
                    if (product._id === productId) {
                      return {
                        ...product,
                        image: null,
                      };
                    } else return product;
                  }),
                },
              },
            });

            setNotification({
              variables: {
                timeout: 4000,
                message: t('products.image modal.delete message'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
            handleCloseUploadModal();
          }
        },
      });
    } catch (error) {
      console.error('ERROR', error);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message,
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const imgSrc = (!!files.length && files[0].preview) || imageData.src;

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleCloseUploadModal}
      modalTitle={t('products.image modal.title')}
    >
      <Grid
        className={classes.imageContainer}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Fab
          color="primary"
          className={classes.deleteButton}
          onClick={handleDeletePhoto}
          disabled={!imgSrc}
        >
          <DeleteIcon />
        </Fab>
        <Grid
          container
          className={classNames(
            classes.imagePreviewContainer,
            !imgSrc && classes.noImage
          )}
          alignItems="center"
          justifyContent="center"
        >
          {imgSrc ? (
            <>
              <img
                className={classes.imagePreview}
                src={imgSrc}
                alt="product"
              />
            </>
          ) : (
            <ImageOutlinedIcon />
          )}
        </Grid>
      </Grid>
      <Grid
        className={classes.dragNDropContainer}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        wrap="nowrap"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography variant="subtitle2" color="textSecondary">
          {t('products.image modal.drag and drop')}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t('products.image modal.or')}
        </Typography>
        <Button
          className={classes.uploadPhotoButton}
          variant="outlined"
          startIcon={<UploadIcon color="primary" />}
          onClick={openDragNDrop}
        >
          {t('products.image modal.upload photo')}
        </Button>
      </Grid>
      <Typography className={classes.noticeText} variant="caption">
        {t('products.image modal.notice')}
      </Typography>
      <Button
        className={classes.actionButton}
        fullWidth
        disabled={fileLoading}
        onClick={processImageUpload}
        variant="contained"
        color="primary"
      >
        {fileLoading ? 'Uploading...' : t('common.confirm')}
      </Button>
    </ModalLayout>
  );
}

ModalImageUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  imageData: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

ModalImageUpload.defaultProps = {
  imageData: {
    id: null,
    src: null,
    isOpen: false,
  },
};
