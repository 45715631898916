import { gql } from '@apollo/client';

export const SINGLE_FILE_UPLOAD = gql`
mutation($file: Upload!, $collection: CollectionType!, $options: CsvIngestionOptionsInput) {
    ingestSingleFile(file: $file, collection: $collection, options: $options) {
      success {
        message
        lines
        updated
        added
      }
      errors {
        message
        line
        errors
        parseErrors
      }
      filename
      mimetype
      filesize
      encoding
    }
  }
`;

export const SINGLE_IMAGE_UPLOAD = gql`
mutation($file: Upload!, $productId: String!) {
  uploadImage(file: $file, productId: $productId) {
    image
    filename
    mimetype
    filesize
    encoding
  }
}
`;

export const UPLOAD_VENDOR_IMAGE = gql`
mutation UploadVendorLogo($file: Upload!) {
  uploadVendorLogo(file: $file) {
    image
    filename
    mimetype
    filesize
    encoding
  }
}
`;

export const UPLOAD_VENDOR_DEFAULT_PRODUCT_IMAGE = gql`
mutation UploadVendorDefaultImage($file: Upload!) {
  uploadVendorDefaultImage(file: $file) {
    image
    filename
    mimetype
    filesize
    encoding
  }
}
`;

export const UPLOAD_FILE_STREAM = gql`
  mutation streamSingleFile($file: Upload!) {
    streamSingleFile(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

export const INGEST_FTP_DATA = gql`
mutation ingestFtpData($vendorId: ID!, $collection: CollectionType!, $vendorName: String!) {
  ingestFtpData(vendorId: $vendorId, collection: $collection, vendorName: $vendorName) {
    success {
      added
      updated
    }
    errors {
      line
      message
      errors
    }
    filesize
  }
}
`;
