import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import ModalLayout from 'components/shared/ModalLayout';
import StyledInput from 'components/shared/Inputs/StyledInput';
import NewsletterColorPicker from 'components/Newsletter/Constructor/NewsletterColorPicker';

const useStyles = makeStyles(({ spacing, palette }) => ({
  modalContainerCustomClass: {
    width: 650,
    maxWidth: 650,
    maxHeight: '80%',
  },
  loader: {
    margin: '0 auto',
    position: 'absolute',
    top: 60,
    width: 'calc(100% - 96px)',
    left: 48,
  },
  modalContentCustomClass: {
    padding: spacing(6, 6, 0),
  },
  createCustomGroupModalContainer: {
    marginBottom: spacing(1),
  },
  pickGroupTitle: {
    margin: spacing(2, 0),
  },
  actionButton: {
    height: 50,
    margin: spacing(4.5, 1.5, 1.5),
    boxShadow: 'none',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  createCustomGroupModalActions: {
    background: palette.background.paper,
    position: 'sticky',
    bottom: 0,
    paddingBottom: spacing(2),
  },
  limitationCaption: {
    margin: spacing(0.5, 1),
  },
}));

export default function CreateCustomGroupModal({
  isOpen,
  handleClose,
  state,
  setState,
  isLoading,
  handleCreateCustomGroup,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  // ------- HANDLERS --------

  const handleChangeName = e => {
    e.persist();
    setState(prevState => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleColorChange = color => {
    setState(prevState => ({
      ...prevState,
      color,
    }));
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      classes={{
        containerClass: classes.modalContainerCustomClass,
        contentClass: classes.modalContentCustomClass,
      }}
      modalTitle={t('groups.create custom group')}
    >
      {isLoading && (
        <LinearProgress className={classes.loader} color="primary" />
      )}
      <Grid
        className={classes.createCustomGroupModalContainer}
        container
        alignItems="flex-end"
      >
        <Grid container item xs={12}>
          <FormControl fullWidth className={classes.margin}>
            <Typography className={classes.nameFieldTitle} paragraph>
              {t('common.name')}
            </Typography>
            <StyledInput
              fullWidth
              variant="outlined"
              value={state.name}
              placeholder={t('groups.group name placeholder')}
              onChange={handleChangeName}
              inputProps={{
                maxLength: 35,
              }}
            />
            <Typography variant="caption" className={classes.limitationCaption}>
              {t('common.chars limitation', { count: 35 })}
            </Typography>
          </FormControl>
        </Grid>
        <Grid container item xs={12} alignItems="center" direction="column">
          <Typography className={classes.pickGroupTitle} variant="body2">
            {t('groups.pick a color for your group')}
          </Typography>
          <NewsletterColorPicker
            color={state.color}
            onChangeComplete={handleColorChange}
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.createCustomGroupModalActions}
        container
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={handleCreateCustomGroup}
          variant="contained"
          color="primary"
        >
          {t('common.confirm')}
        </Button>
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={handleClose}
          variant="contained"
        >
          {t('common.cancel')}
        </Button>
      </Grid>
    </ModalLayout>
  );
}

CreateCustomGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  state: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleCreateCustomGroup: PropTypes.func.isRequired,
};
