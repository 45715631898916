/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_VENDOR_LIST, GET_LISTS } from 'graphql/query/lists';
import { DELETE_LIST } from 'graphql/mutation/lists';

import { makeStyles } from '@material-ui/styles';

import TableLayout from 'components/shared/TableLayout';
import ListPackaging from 'components/ListsTable/ListPackaging';
import ListDetailsTableRow from 'components/ListsTable/ListDetailsTableRow';
import ListDetailsHeaderActions from 'components/ListsTable/ListDetailsHeaderActions';
import EmptyTableRow from 'components/shared/EmptyTableRow';
import EditConfirmationModal from 'components/shared/EditConfirmationModal';
import { ROWS_PER_PAGE_OPTIONS, TABLE_SKELETON_TYPES } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  listProductNumber: {
    paddingLeft: theme.spacing(5),
    width: 100
  },
  listName: {
    width: 330,
  },
  listAssignment: {
    width: 380,
  }
}));

export default function ListDetails({ listId }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [deleteVendorList] = useMutation(DELETE_LIST);

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1
  });
  const [isSaveModalOpen, setSaveModalOpen] = React.useState(false);

  const [getVendorList, {
    loading: listLoading,
    error: listError,
    data,
  }] = useLazyQuery(
    GET_VENDOR_LIST,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;

    getVendorList({
      variables: {
        _id: listId,
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder
      }
    });

    if (pageParam || rowsParam) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage
      });
    }
  }, []);

  const listData = data && data.vendorList;
  const defaultTitle = listData && (listData.title === 'New List' || listData.title === '');
  const noProducts = listData && !listData.products?.products?.length;
  const hasAssigning = listData && (!!listData.assignedToGroups?.length || listData.assignedToAll);

  React.useEffect(() => {
    const totalPages = listData?.products?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && (totalPages <= state.page);
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(state.rowsPerPage);

    if (listData?.products && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        })
      });
      window.location.reload();
    }
  }, [listData]);

  const handleChangePage = async (event, page) => {
    if (listData && (listData.products.hasNextPage || page + 1 < listData.products.totalPages)) {
      try {
        await getVendorList({
          variables: {
            _id: listId,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({ ...queryString.parse(location.search), page })
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      await getVendorList({
        variables: {
          _id: listId,
          after: 1,
          pageSize: rowsPerPage,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }

    setState({ ...state, rowsPerPage, page: 0 });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0
      })
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getVendorList({
        variables: {
          _id: listId,
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, sortOrder, sortBy: sortLabel, page: 0
    });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder
      })
    });
  };

  const handleBackButtonAction = () => {
    if (!hasAssigning && noProducts && defaultTitle) {
      setSaveModalOpen(true);
    } else {
      history.goBack();
    }
  };

  const handleDeleteCurrentList = async () => {
    setSaveModalOpen(false);
    try {
      await deleteVendorList({
        variables: { listId },
        update: (store, { data: response }) => {
          const allLists = store.readQuery({ query: GET_LISTS });
          store.writeQuery({
            query: GET_LISTS,
            data: {
              vendorLists: {
                ...allLists.vendorLists,
                lists: allLists.vendorLists.lists.filter(
                  list => list._id !== response.deleteVendorList._id
                ),
              }
            }
          });
        }
      });
      mixPanel.track('Delete Vendor List', { listId });
      history.goBack();
    } catch (error) {
      console.error(error.message);
    }
  };

  /* eslint-disable react/prop-types */
  const tableColumns = [
    {
      title: t('lists.details.article number'),
      field: 'vendorSpecificId',
      sortable: false,
      columnStyles: classes.listProductNumber,
    },
    {
      title: t('lists.details.article name'),
      field: 'name',
      sortable: false,
      columnStyles: classes.listName,
    },
    {
      title: t('lists.details.package'),
      field: 'assignment',
      sortable: false,
      columnStyles: classes.listAssignment,
      render: (values) => (
        <ListPackaging variants={values.variants} />
      )
    },
  ];

  const {
    page, rowsPerPage, sortBy, sortOrder
  } = state;

  const hasError = Boolean(listError) || (!listLoading && !listData)
    || (listData && !listData.products?.products?.length);

  const title = listData && listData.title === 'New List' ?
    t('lists.new list title') : (listData?.title || '');

  return (
    <>
      <TableLayout
        emptyTableData={hasError}
        emptyTableDataMessage={t('lists.empty list data')}
        tableIsLoading={listLoading}
        title={title}
        columns={listData ? tableColumns : []}
        skeletonType={TABLE_SKELETON_TYPES.LIST}
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={(listData && listData.products.totalResults) || 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        backButtonAction={handleBackButtonAction}
        headerAdditionalActions={listData && (
          <ListDetailsHeaderActions
            listId={listId}
            assignedToAll={listData.assignedToAll}
            assignedToGroups={listData.assignedToGroups}
          />
        )}
      >
        {listData && listData.products?.products?.length > 0 ?
          listData.products.products
            .map((rowData) => (
              <ListDetailsTableRow
                key={rowData._id}
                columns={tableColumns}
                rowData={rowData}
              />
            )) : <EmptyTableRow />}
      </TableLayout>
      {isSaveModalOpen && (
        <EditConfirmationModal
          isOpen={isSaveModalOpen}
          handleCloseModal={setSaveModalOpen}
          onConfirm={history.goBack}
          onCancel={handleDeleteCurrentList}
          modalTitleMessage={t('lists.edit modal title')}
          modalContentMessage={t('lists.edit modal message')}
          modalConfirmMessage={t('common.save')}
          modalDiscardMessage={t('lists.edit modal delete')}
        />
      )}
    </>
  );
}

ListDetails.propTypes = {
  listId: PropTypes.string,
};

ListDetails.defaultProps = {
  listId: ''
};
