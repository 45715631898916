/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation } from '@apollo/client';

import { GET_PDF_CATALOGS } from 'graphql/query/pdfCatalogs';
import {
  DELETE_PDF_CATALOG,
  UPDATE_PDF_CATALOG,
} from 'graphql/mutation/pdfCatalogs';

import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UploadPdfModal from 'components/PdfCatalogs/UploadPdfModal';

import TableLayout from 'components/shared/TableLayout';
import TableRow from 'components/PdfCatalogs/TableRow';

import {
  NOTIFICATION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  tableLeftPadding: {
    paddingLeft: theme.spacing(5),
  },
  addButton: {
    border: theme.palette.border.lightGrey,
    height: 45,
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function PdfCatalogs() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const queries = queryString.parse(location.search);

  const tableItemRef = useRef(null);
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

  const [deletePdfCatalog] = useMutation(DELETE_PDF_CATALOG);
  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [updatePdfCatalog] = useMutation(UPDATE_PDF_CATALOG);

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
    selectedId: null,
  });

  const [
    getPdfCatalogs,
    {
      loading: pdfCatalogsLoading,
      error: pdfCatalogsError,
      data: pdfCatalogsData,
    },
  ] = useLazyQuery(GET_PDF_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const selectedId = queries.id || null;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getPdfCatalogs({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });
    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        selectedId,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
  }, []);

  const handleChangeCatalogPricingMode = async (_id, enabled) => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    try {
      await updatePdfCatalog({
        variables: {
          catalogId: _id,
          catalogUpdates: {
            enabled: !!enabled,
          },
        },
        // @TODO: Refetch queries
        // refetchQueries: [
        //   {
        //     query: GET_PDF_CATALOGS,
        //     variables: {
        //       after: pageParam + 1 || 1,
        //       pageSize: rowsParam,
        //       sortBy: sortByParam,
        //       sortOrder: sortOrderParam,
        //     },
        //   },
        // ],
        update: async () => {
          mixPanel.track('Enabled PdfCatalog', {
            catalogId: _id,
            enabled,
          });
        },
      });
      await getPdfCatalogs({
        variables: {
          after: pageParam + 1 || state.page + 1,
          pageSize: rowsParam || state.rowsPerPage,
          sortBy: sortByParam || state.sortBy,
          sortOrder: sortOrderParam || state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const pdfCatalogs = pdfCatalogsData?.getAllPdfCatalogs;

  useEffect(() => {
    const totalPages = pdfCatalogs?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (pdfCatalogs && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [pdfCatalogs]);

  const handleChangePage = async (event, page) => {
    if (
      pdfCatalogs &&
      (pdfCatalogs.hasNextPage || page + 1 < pdfCatalogs.totalPages)
    ) {
      try {
        await getPdfCatalogs({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page, selectedId: null });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
        id: '',
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getPdfCatalogs({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      rowsPerPage,
      page: 0,
      selectedId: null,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleDelete = async _id => {
    try {
      await deletePdfCatalog({
        variables: { _id },
        refetchQueries: [
          {
            query: GET_PDF_CATALOGS,
            variables: {
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
            },
          },
        ],
      });
      mixPanel.track('Delete Pdf Catalog', { _id });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('pdfCatalog.deleted successfully'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const tableColumns = [
    {
      title: t('pdfCatalog.createdAt'),
      field: 'createdAt',
      sortable: false,
      columnStyles: classes.tableLeftPadding,
    },
    {
      title: t('pdfCatalog.title'),
      field: 'title',
      sortable: false,
    },
    {
      title: t('pdfCatalog.url'),
      field: 'url',
      sortable: false,
    },
    {
      title: t('common.customer groups'),
      field: 'groups',
      sortable: false,
    },
    {
      title: t('pdfCatalog.enabled'),
      field: 'enabled',
      sortable: false,
    },
  ];

  const { page, rowsPerPage, sortBy, sortOrder, selectedId } = state;

  const hasError =
    pdfCatalogsError || (!pdfCatalogsLoading && !pdfCatalogs?.results?.length);

  return (
    <>
      <TableLayout
        title={t('pdfCatalog.pageTitle')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.LISTS}
        emptyTableData={hasError}
        emptyTableDataMessage={t('pdfCatalog.noResults')}
        tableIsLoading={pdfCatalogsLoading}
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={(pdfCatalogs && pdfCatalogs.totalResults) || 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        headerActions={[
          <Button
            key="add pdf catalog"
            className={classes.addButton}
            variant="outlined"
            startIcon={<AddCircleOutlineIcon color="primary" />}
            onClick={() => setIsUploadModalOpen(true)}
          >
            {t('pdfCatalog.upload')}
          </Button>,
        ]}
      >
        {pdfCatalogs?.results?.length > 0 &&
          pdfCatalogs?.results.map((rowData, i) => (
            <TableRow
              isOddRow={i % 2 !== 0}
              key={rowData._id}
              selectedList={selectedId}
              rowData={rowData}
              executeScroll={() => scrollToRef(tableItemRef)}
              tableItemRef={tableItemRef}
              rowsPerPage={+queries.rowsPerPage}
              handleDelete={handleDelete}
              handleChangeCatalogPricingMode={handleChangeCatalogPricingMode}
            />
          ))}
      </TableLayout>
      <UploadPdfModal
        isOpen={isUploadModalOpen}
        handleClose={() => setIsUploadModalOpen(false)}
        rowsPerPage={rowsPerPage}
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
    </>
  );
}
