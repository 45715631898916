import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import DropDowIcon from '@material-ui/icons/ArrowDropDown';

import { DELIVERY_DAYS } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  deliveryDayWrapper: {
    width: 155
  },
  deliveryDayDetailWrapper: {
    width: '100%'
  },
  deliveryDayContainer: {
    width: 130,
  },
  deliveryDayDetailContainer: {
    width: 220,
  },
  deliveryDay: {
    width: 27,
    height: 20,
    margin: theme.spacing(0.25),
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box'
  },
  deliveryIsNotAvailable: {
    background: 'rgba(202, 202, 202, 0.15)',
    color: theme.palette.text.secondary,
  },
  deliveryDayEditingIsNotAvailable: {
    background: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    border: `1px dotted ${theme.palette.primary.main}`
  },
  deliveryIsAvailable: {
    background: 'rgba(255, 81, 0, 0.15)',
    color: theme.palette.primary.main
  },
  iconButton: {
    margin: 0,
    padding: 0
  },
  selectorContainer: {
    width: 200,
    height: 332,
  },
  selectorContainerError: {
    border: `1.5px solid ${theme.palette.error.main}`,
    boxSizing: 'content-box'
  },
  selectorTitle: {
    color: theme.palette.secondary.darkGray,
    padding: `${theme.spacing(2.5)}px 0 ${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    fontStyle: 'italic'
  },
  selectorTitleError: {
    color: theme.palette.error.main
  },
  selector: {
    width: '100%',
    height: 38,
    paddingLeft: theme.spacing(2.5)
  },
  activeSelector: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255, 81, 0, 0.15)'
  },
  checkbox: {
    color: theme.palette.secondary.darkGray,
  },
  popoverPaper: {
    boxShadow: theme.palette.customShadows.popover
  },
  errorBorder: {
    display: 'flex',
    alignItems: 'center',
    border: `1.5px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    height: 36
  },
}));

export default function DeliveryDays(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    setFieldValue, days, errors, isEditMode, inDetailPanel
  } = props;

  const handleOpenSelector = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const handleChangeDeliveryDay = (chosenDay) => (event) => {
    const enabled = event.target.checked;

    setFieldValue('deliveryDays', days.map(day =>
      (day.name === chosenDay ?
        ({ ...day, enabled }) : day)
    ));
  };

  const selectorIsOpen = Boolean(anchorEl);
  const hasError = errors.deliveryDays;

  const parsedDays = days.reduce((prev, day) => ({
    ...prev,
    [day.name]: day.enabled

  }), {});

  const getDeliveryDayStyle = (day) => {
    if (parsedDays[day]) {
      return classes.deliveryIsAvailable;
    } else {
      return isEditMode ?
        classes.deliveryDayEditingIsNotAvailable : classes.deliveryIsNotAvailable;
    }
  };

  return (
    <Grid
      className={
        inDetailPanel ? classes.deliveryDayDetailWrapper : classes.deliveryDayWrapper
      }
      container
      wrap="nowrap"
      alignItems="center"
    >
      <Grid
        container
        className={classNames(
          inDetailPanel ? classes.deliveryDayDetailContainer : classes.deliveryDayContainer,
          (hasError && inDetailPanel) && classes.errorBorder
        )}
      >
        {DELIVERY_DAYS.map(deliveryDay => (
          <Grid
            key={deliveryDay}
            className={classNames(classes.deliveryDay, getDeliveryDayStyle(deliveryDay))}
          >
            <Typography variant="caption">
              {t(`daysOfTheWeek.${deliveryDay}`).slice(0, 2)}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {isEditMode && (
        <>
          <Grid item>
            <IconButton className={classes.iconButton} size="small" onClick={handleOpenSelector}>
              <DropDowIcon />
            </IconButton>
          </Grid>
          <Popover
            classes={{ paper: classes.popoverPaper }}
            open={selectorIsOpen}
            anchorEl={anchorEl}
            onClose={handleCloseSelector}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              className={classNames(
                classes.selectorContainer,
                hasError && classes.selectorContainerError
              )}
            >
              <Typography className={
                classNames(classes.selectorTitle, hasError && classes.selectorTitleError)
              }
              >
                {t('customers.choose')}
              </Typography>
              {DELIVERY_DAYS.map(deliveryDay => (
                <FormControlLabel
                  key={deliveryDay}
                  className={classNames(
                    classes.selector,
                    parsedDays[deliveryDay] && classes.activeSelector
                  )}
                  control={
                    <Checkbox
                      classes={{ root: classes.checkbox }}
                      checked={parsedDays[deliveryDay]}
                      onChange={handleChangeDeliveryDay(deliveryDay)}
                      color="primary"
                    />
                  }
                  label={t(`daysOfTheWeek.${deliveryDay}`)}
                />
              ))}
            </Grid>
          </Popover>
        </>
      )}
    </Grid>
  );
}

DeliveryDays.propTypes = {
  days: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  inDetailPanel: PropTypes.bool,
};

DeliveryDays.defaultProps = {
  isEditMode: false,
  inDetailPanel: false,
};
