import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS_CSV = gql`
  query getAllProductsCSV {
    downloadAllProductsCsv
  }
`;

export const GET_ALL_CUSTOMERS_CSV = gql`
  query getAllCustomersCSV {
    downloadAllCustomersCsv
  }
`;
