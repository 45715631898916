import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import BackIcon from '@material-ui/icons/ArrowBack';
import noResultImage from 'assets/MessyDoodle.png';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    maxWidth: 'unset',
    minHeight: 'calc( 100vh - 265px )',
    display: 'flex',
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1320,
    },
  },
  backButtonContainer: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
  },
  backButton: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'normal',
  },
  reloadButton: {
    width: 280,
    height: 50,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 700,
    marginTop: theme.spacing(4)
  },
  contentContainer: {
    marginTop: 'auto'
  }
}));

const NotFound = ({ isError }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles();

  return (
    <TableContainer component={Paper} elevation={1} className={classes.tableContainer}>
      <Grid className={classes.backButtonContainer}>
        <Button className={classes.backButton}>
          <BackIcon onClick={history.goBack} color="primary"/>
          {t('notFound.back')}
        </Button>
      </Grid>

      <Grid
        className={classes.contentContainer}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <img src={noResultImage} alt="no result found" />

        <Typography className={classes.text} variant="h4">
          {isError ? t('notFound.errorText') : t('notFound.notFoundText')}
        </Typography>

        <Button
          className={classes.reloadButton}
          onClick={() => document.location.reload()}
          variant="contained"
          color="primary"
        >
          {t('notFound.Reload the page')}
        </Button>

      </Grid>

    </TableContainer>
  );
};

NotFound.propTypes = {
  isError: PropTypes.bool
};

NotFound.defaultProps = {
  isError: false,
};
export default NotFound;
