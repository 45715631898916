import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_ATTRIBUTE_BY_ID,
} from 'graphql/query/products';
import { DELETE_PRODUCT_ATTRIBUTES } from 'graphql/mutation/products';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';

import { TABLE_SKELETON_TYPES, NOTIFICATION_STATUS } from 'helpers/constants';
import TableLayout from 'components/shared/TableLayout';
import UpdateProductAttributeModal from 'components/ProductsTable/ProductAttributes/UpdateProductAttributeModal';
import ProductAttributeTableRow from 'components/ProductsTable/ProductAttributes/ProductAttributeTableRow';
import DeleteModal from 'components/shared/DeleteModal';
import parseValidationErrors from 'helpers/parseValidationErrors';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  addAttributeButton: {
    border: palette.border.lightGrey,
    height: 45,
    color: palette.secondary.darkGray,
    fontWeight: typography.fontWeightRegular,
    marginRight: spacing(1.5),
  },
  columnTitle: {
    whiteSpace: 'break-spaces',
  },
  isAttributeMandatoryField: {
    width: 200,
  },
}));

function ProductAttributesTable() {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const queries = queryString.parse(location.search);
  const { attributeId: urlAttributeId } = queries;

  const [selected, setSelected] = React.useState([]);
  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'name',
    sortOrder: -1,
  });
  const [editAttributeData, setEditAttributeData] = React.useState(null);
  const [isDeleteSelectedModalOpen, setDeleteSelectedModal] = React.useState(
    false
  );

  const [deleteProductAttributesMutation] = useMutation(
    DELETE_PRODUCT_ATTRIBUTES
  );
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [
    getProductAttributes,
    { loading: productAttrsLoading, error: productAttrsError, data },
  ] = useLazyQuery(GET_PRODUCT_ATTRIBUTES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getProductAttributeById,
    { loading: productAttrByIdLoading },
  ] = useLazyQuery(GET_PRODUCT_ATTRIBUTE_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: attrByIdData => {
      if (attrByIdData.productAttribute) {
        setEditAttributeData(attrByIdData.productAttribute);
      }
    },
  });

  const productAttrsData = data && data.productAttributes;

  // ------- USE EFFECTS -------

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getProductAttributes({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAttributeById = async () => {
    try {
      getProductAttributeById({
        variables: {
          productAttributeId: urlAttributeId,
          productsAfter: 1,
        },
      });
      history.replace(currentPath);
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  React.useEffect(() => {
    if (urlAttributeId) {
      getAttributeById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlAttributeId]);

  // ------- HANDLERS -------

  const handleSelectItem = (event, id) => {
    setSelected(
      selected.includes(id)
        ? selected.filter(selectedId => selectedId !== id)
        : selected.concat(id)
    );
  };

  const handleSelectAllClick = event => {
    if (event.target.checked && productAttrsData?.results?.length) {
      const newSelected = productAttrsData.results.map(n => n._id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleOpenEditModal = attribute => {
    setEditAttributeData(attribute);
  };

  const handleCloseEditModal = () => {
    setEditAttributeData(null);
  };

  const handleOpenDeleteSelectedModal = () => {
    setDeleteSelectedModal(true);
  };

  const handleCloseDeleteSelectedModal = () => {
    setDeleteSelectedModal(false);
  };

  const handleDeleteAttribute = async productAttributeIds => {
    try {
      await deleteProductAttributesMutation({
        variables: { ids: productAttributeIds },
        refetchQueries: [
          {
            query: GET_PRODUCT_ATTRIBUTES,
            variables: {
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
            },
          },
        ],
        update: (_, { data: { deleteProductAttributes } }) => {
          const successMessage =
            deleteProductAttributes.length > 1
              ? t('product attributes.notifications.mass delete success', {
                attributesQty: deleteProductAttributes.length,
              })
              : t('product attributes.notifications.single delete success', {
                attributeName: deleteProductAttributes[0].name,
              });
          setNotification({
            variables: {
              timeout: 4000,
              message: successMessage,
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleMassDelete = () => {
    handleDeleteAttribute(selected);
    handleCloseDeleteSelectedModal(false);
    setSelected([]);
  };

  // -------- PRODUCT PAGE HANDLERS -------

  const handleChangePage = async (event, page) => {
    // NOTE: fetch more search query or products
    if (
      productAttrsData &&
      (productAttrsData.hasNextPage || page + 1 < productAttrsData.totalPages)
    ) {
      try {
        await getProductAttributes({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    // NOTE: fetch more search query or products

    try {
      await getProductAttributes({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }

    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getProductAttributes({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const tableColumns = [
    {
      title: t('common.name'),
      field: 'name',
      sortable: false,
      columnStyles: classes.columnTitle,
    },
    {
      title: t('product attributes.option'),
      field: 'option',
      sortable: false,
      columnStyles: classes.columnTitle,
    },
    {
      title: t('product attributes.is mandatory'),
      field: 'isRequired',
      sortable: false,
      columnStyles: classNames(
        classes.columnTitle,
        classes.isAttributeMandatoryField
      ),
    },
    {
      title: t('product attributes.assigned products'),
      field: 'products',
      sortable: false,
      columnStyles: classes.columnTitle,
    },
  ];

  const { page, rowsPerPage, sortBy, sortOrder } = state;
  const isLoading = productAttrsLoading || productAttrByIdLoading;

  return (
    <>
      <TableLayout
        title={t('product attributes.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.PRODUCTS}
        emptyTableData={productAttrsError || !productAttrsData?.totalResults}
        emptyTableDataMessage={t('product attributes.empty table message')}
        tableIsLoading={isLoading}
        withDetailPanel
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={productAttrsData?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        headerActions={[
          <Button
            key="create attribute"
            className={classes.addAttributeButton}
            variant="outlined"
            startIcon={<AddCircleOutlineIcon color="primary" />}
            onClick={() => setEditAttributeData({})}
          >
            {t('product attributes.create attribute')}
          </Button>,
          !!selected.length && (
            <Button
              key="delete attribute"
              className={classes.addAttributeButton}
              variant="outlined"
              startIcon={<DeleteRoundedIcon color="error" />}
              onClick={handleOpenDeleteSelectedModal}
            >
              {t('common.delete')}
            </Button>
          ),
        ]}
        hasCheckBox
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        itemsOnPage={productAttrsData?.results?.length}
      >
        {productAttrsData?.results?.map(
          rowData =>
            rowData && (
              <ProductAttributeTableRow
                key={rowData._id}
                rowData={rowData}
                withDetailPanel
                selected={selected}
                handleSelectItem={handleSelectItem}
                handleDeleteAttribute={handleDeleteAttribute}
                handleOpenEditModal={handleOpenEditModal}
              />
            )
        )}
      </TableLayout>
      <UpdateProductAttributeModal
        isOpen={!!editAttributeData}
        handleClose={handleCloseEditModal}
        productsAttrsPageState={state}
        attributeData={editAttributeData}
        isNewAttribute={!editAttributeData?._id}
      />
      <DeleteModal
        isOpen={isDeleteSelectedModalOpen}
        message={t('product attributes.delete attributes modal message', {
          attributesQty: selected.length,
        })}
        handleCloseModal={handleCloseDeleteSelectedModal}
        handleDelete={handleMassDelete}
      />
    </>
  );
}

export default ProductAttributesTable;
