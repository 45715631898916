/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { FORGOT_PASSWORD } from 'graphql/mutation/password';

import { makeStyles, useTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as DarkThemeLogo } from 'assets/LogoWhite.svg';
import { ReactComponent as LightThemeLogo } from 'assets/Logo.svg';
import BackIcon from '@material-ui/icons/ChevronLeft';

import { NOTIFICATION_STATUS } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  goBackButton: {
    position: 'fixed'
  },
  logo: {
    position: 'absolute',
    left: 24,
    top: 24
  },
  logoImage: {
    width: 40,
    height: 40
  },
  resetPasswordContainer: {
    boxShadow: theme.palette.customShadows.popover,
    borderRadius: 8,
    padding: `${theme.spacing(4)}px ${theme.spacing(9)}px`,
    width: 560,
    minHeight: 650,
    background: theme.palette.background.paper,
  },
  resetPasswordTitle: {
    fontSize: 22,
    lineHeight: '26px',
    letterSpacing: '-0.02em',
    paddingTop: theme.spacing(1.5)
  },
  resetMessage: {
    marginTop: theme.spacing(16),
    width: 320
  },
  textInput: {
    width: '100%',
    border: theme.palette.border.lightGrey,
    borderRadius: 4,
    margin: `${theme.spacing(2.5)}px 0px ${theme.spacing(4.5)}px`
  },
  input: {
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  formControl: {
    top: 16
  },
  resetButton: {
    height: 50,
    boxShadow: 'none'
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();

  const [email, setEmail] = React.useState('');

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD);

  const handleSendTheResetLink = async () => {
    if (email.trim() === '') {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.empty email'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    } else {
      try {
        const response = await forgotPasswordMutation({
          variables: {
            email,
          }
        });
        if (!response.data.errors) {
          setNotification({
            variables: {
              timeout: 4000,
              message: t('reset password.send success', { email }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        }
        history.push('/login');
      } catch (error) {
        console.error('forgot_password', error);
        setNotification({
          variables: {
            timeout: 4000,
            message: t('reset password.email not found'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }
  };

  return (
    <>
      <IconButton className={classes.logo} onClick={() => history.push('/')}>
        {theme?.palette?.type === 'dark' ?
          <DarkThemeLogo className={classes.logoImage} />
          : <LightThemeLogo className={classes.logoImage} />}
      </IconButton>
      <Grid
        container
        className={classes.resetPasswordContainer}
        direction="column"
      >
        <IconButton className={classes.goBackButton} onClick={() => history.push('/')}>
          <BackIcon />
        </IconButton>
        <Typography
          className={classes.resetPasswordTitle}
          align="center"
          variant="h6"
        >
          {t('reset password.title')}
        </Typography>
        <Typography
          variant="body1"
          className={classes.resetMessage}
        >{t('reset password.reset message')}
        </Typography>
        <TextField
          label={t('common.email')}
          variant="outlined"
          className={classes.textInput}
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl
            }
          }}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.resetButton}
          onClick={handleSendTheResetLink}
        >
          {t('reset password.send the link')}
        </Button>
      </Grid>
    </>
  );
};

export default ForgotPassword;
