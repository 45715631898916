import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(theme => ({
  packageLabel: {
    height: 24,
    width: 100,
    borderRadius: 30,
    color: theme.palette.primary.main,
    margin: theme.spacing(0.5),
    background: 'rgba(255, 81, 0, 0.15)'
  },
  packagingUnitLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 84,
  },
}));

export default function ListPackaging({ variants }) {
  const classes = useStyles();

  return variants.length > 0 ? (
    <Grid container spacing={2}>
      {variants.map(variant => (
        <Grid
          key={uuid()}
          className={classes.packageLabel}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title={variant.packaging} placement="top">
            <Typography
              className={classes.packagingUnitLabel}
              align="center"
              variant="body2"
            >
              {variant.packaging}
            </Typography>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  ) : (
    <EmptyTableField />
  );
}

ListPackaging.propTypes = {
  variants: PropTypes.array,
};

ListPackaging.defaultProps = {
  variants: []
};
