import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { SINGLE_FILE_UPLOAD } from 'graphql/mutation/fileUploading';
import { GET_PRODUCTS } from 'graphql/query/products';
import { GET_ALL_PRODUCTS_CSV } from 'graphql/query/files';
import { useLazyQuery, useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { NOTIFICATION_STATUS, MAX_CSV_FILE_SIZE } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';
import CsvNotifications from 'components/shared/CsvNotifications';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  modalText: {
    width: 355,
  },
  optionalMessage: {
    fontWeight: theme.typography.fontWeightLight,
    fontStyle: 'Italic',
    marginLeft: theme.spacing(0.5),
  },
  downloadButton: {
    margin: `${theme.spacing(3)}px 0px ${theme.spacing(4.5)}px`,
  },
  uploadButton: {
    margin: `${theme.spacing(3)}px 0px ${theme.spacing(1.5)}px`,
  },
  modalButtons: {
    height: 74,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    border: theme.palette.border.darkGrey,
    backgroundColor: '#FAFAFA',
  },
  actionButton: {
    height: 50,
    boxShadow: 'none',
  },
  noticeContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
    border: theme.palette.border.darkGrey,
    borderRadius: 4,
    color: theme.palette.secondary.darkGray,
    marginBottom: theme.spacing(14),
  },
  productTooltips: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    border: theme.palette.border.darkGrey,
    fontSize: theme.typography.fontSize,
    maxWidth: 220,
  },
  replaceButtonContainer: {
    marginTop: theme.spacing(1.5),
  },
  replaceWarning: {
    margin: `${theme.spacing(1.5)}px 0px`,
  },
  downloadProgress: {
    marginBottom: theme.spacing(0.5),
  },
}));

export default function ModalCsvProductsUpload(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();
  const [modalStep, setModalStep] = React.useState(0);
  const [showReplaceConfirmation, setReplaceConfirmationOpen] = React.useState(
    false
  );

  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [uploadingErrors, setUploadingErrors] = React.useState(null);
  const [successUploading, setSuccessUploading] = React.useState(null);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [fileUploadMutation, { loading: fileLoading }] = useMutation(
    SINGLE_FILE_UPLOAD
  );

  const { isOpen, handleClose, vendorHasFtpIntegration } = props;

  const [
    downloadCsv,
    { data: downloadAllProductsCsv, loading: csvDownloadLoading },
  ] = useLazyQuery(GET_ALL_PRODUCTS_CSV, { fetchPolicy: 'network-only' });

  function handleOnFileChange({ target: { validity, files } }) {
    if (validity.valid) {
      if (files[0].size > MAX_CSV_FILE_SIZE) {
        setNotification({
          variables: {
            timeout: 4000,
            message: t('common.file size exceeded'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      } else {
        setSuccessUploading(null);
        setUploadingErrors(null);
        setUploadedFile(files[0]);
      }
    }
  }

  const resetAllState = () => {
    setModalStep(0);
    setUploadedFile(null);
    setSuccessUploading(null);
    setUploadingErrors(null);
    setReplaceConfirmationOpen(false);
  };

  const handleCloseModal = () => {
    resetAllState();
    handleClose();
  };

  const handleUploadProducts = async ({ removeNonPresent }) => {
    setReplaceConfirmationOpen(false);
    setSuccessUploading(null);
    setUploadingErrors(null);
    try {
      await fileUploadMutation({
        variables: {
          file: uploadedFile,
          collection: 'products',
          options: { removeNonPresent },
        },
        update: (
          store,
          {
            data: {
              ingestSingleFile: { errors, success },
            },
          }
        ) => {
          if (errors && errors.length > 0) {
            setUploadingErrors({
              list: errors.slice(0, 10),
              total: errors.length,
            });
          }
          if (success) {
            mixPanel.track('Uploaded CSV Products');
            setSuccessUploading({ ...success });
            setNotification({
              variables: {
                timeout: 4000,
                message: t('products.csv product modal.success message'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        },
        refetchQueries: [{ query: GET_PRODUCTS }],
      });
    } catch (error) {
      console.error('ERROR', error);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message,
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  React.useEffect(() => {
    try {
      if (
        !downloadAllProductsCsv ||
        !downloadAllProductsCsv?.downloadAllProductsCsv
      ) return;
      const csvContent = JSON.parse(
        downloadAllProductsCsv.downloadAllProductsCsv
      );

      const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const filename = `product_export_${new Date(Date.now())
        .toLocaleString()
        .replace(/\s/g, '')
        .replace(/,/g, '-')}.csv`;
      if (window.navigator.msSaveOrOpenBlob) {
        // IE 10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong extended', {
            errorMessage: error.message,
          }),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadAllProductsCsv]);

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleCloseModal}
      modalTitle={t('products.csv product modal.title')}
      withBackButton={modalStep > 0}
      handleMoveBack={resetAllState}
    >
      {modalStep === 0 && (
        <>
          {csvDownloadLoading && (
            <LinearProgress
              className={classes.downloadProgress}
              color="primary"
            />
          )}
          <Typography className={classes.modalText}>
            {t('products.template message')}
            <span className={classes.optionalMessage}>(Optional)</span>
          </Typography>
          <Link
            component={Button}
            className={classNames(classes.downloadButton, classes.modalButtons)}
            fullWidth
            rel="noreferrer"
            href="https://storage.googleapis.com/chfslst-csv-templates/products_template_csv.csv"
            startIcon={<DownloadIcon color="primary" />}
          >
            {t('common.download template')}
          </Link>
          <Typography className={classes.modalText}>
            {t('products.backup message')}
            <span className={classes.optionalMessage}>(Optional)</span>
          </Typography>
          <Button
            className={classNames(classes.downloadButton, classes.modalButtons)}
            fullWidth
            variant="contained"
            color="default"
            disabled={csvDownloadLoading}
            onClick={async () => {
              try {
                await downloadCsv();
              } catch (error) {
                console.error('CSV_DOWNLOAD_ERROR', error.message);
              }
            }}
            startIcon={<DownloadIcon color="primary" />}
          >
            {t('common.download csv')}
          </Button>
          {!vendorHasFtpIntegration && (
            <Button
              className={classes.actionButton}
              fullWidth
              onClick={() => setModalStep(1)}
              variant="contained"
              color="primary"
              endIcon={<ForwardIcon />}
            >
              {t('products.next step')}
            </Button>
          )}
        </>
      )}
      {modalStep === 1 && (
        <>
          <Typography className={classes.modalText}>
            {t('products.upload message')}
          </Typography>
          <label htmlFor="upload-csv">
            <input
              style={{ display: 'none' }}
              id="upload-csv"
              name="upload-csv"
              type="file"
              multiple
              required
              onChange={handleOnFileChange}
            />
            <Button
              className={classNames(classes.uploadButton, classes.modalButtons)}
              fullWidth
              variant="contained"
              color="default"
              startIcon={<UploadIcon color="primary" />}
              component="span"
            >
              {t('common.select csv')}
            </Button>
          </label>
          {uploadedFile && (
            <Typography gutterBottom noWrap>
              {`${t('common.selected file')}: ${uploadedFile.name}`}
            </Typography>
          )}
          {fileLoading && <LinearProgress color="primary" />}
          <CsvNotifications
            errors={uploadingErrors}
            success={successUploading}
          />
          {!showReplaceConfirmation &&
            !fileLoading &&
            (!successUploading || uploadingErrors ? (
              <>
                <Grid container alignItems="flex-end" direction="column">
                  <Tooltip
                    classes={{
                      tooltip: classes.productTooltips,
                    }}
                    title={t('products.update products tooltip')}
                    placement="left-end"
                  >
                    <InfoIcon
                      style={{ marginBottom: 4 }}
                      fontSize="small"
                      color="primary"
                    />
                  </Tooltip>
                  <Button
                    className={classes.actionButton}
                    fullWidth
                    disabled={!uploadedFile}
                    onClick={() =>
                      handleUploadProducts({ removeNonPresent: false })}
                    variant="contained"
                    color="primary"
                  >
                    {t('products.update products')}
                  </Button>
                </Grid>
                <Grid
                  className={classes.replaceButtonContainer}
                  container
                  alignItems="flex-end"
                  direction="column"
                >
                  <Tooltip
                    classes={{
                      tooltip: classes.productTooltips,
                    }}
                    title={t('products.replace products tooltip')}
                    placement="left-end"
                  >
                    <InfoIcon
                      style={{ marginBottom: 4 }}
                      fontSize="small"
                      color="primary"
                    />
                  </Tooltip>
                  <Button
                    className={classes.actionButton}
                    fullWidth
                    disabled={!uploadedFile}
                    onClick={() => setReplaceConfirmationOpen(true)}
                    variant="contained"
                    color="primary"
                  >
                    {t('products.replace products')}
                  </Button>
                </Grid>
              </>
            ) : (
              <Button
                className={classes.actionButton}
                fullWidth
                disabled={!successUploading}
                onClick={handleCloseModal}
                variant="contained"
                color="primary"
              >
                {t('common.confirm')}
              </Button>
            ))}
          {showReplaceConfirmation && (
            <Grid container wrap="nowrap" direction="column">
              <Typography color="error" className={classes.replaceWarning}>
                {t('products.replace products warning')}
              </Typography>
              <Button
                className={classes.actionButton}
                fullWidth
                disabled={!uploadedFile}
                onClick={() => handleUploadProducts({ removeNonPresent: true })}
                variant="contained"
                color="primary"
              >
                {t('products.replace products')}
              </Button>
            </Grid>
          )}
        </>
      )}
    </ModalLayout>
  );
}

ModalCsvProductsUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  vendorHasFtpIntegration: PropTypes.bool.isRequired,
};
