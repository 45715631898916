import React from 'react';

import debounce from 'lodash.debounce';

export default function useDebounce(timeout = 1000) {
  const debounceRequest = React.useRef(
    debounce((callback) => {
      callback();
    }, timeout)
  ).current;

  return debounceRequest;
}
