import { gql } from '@apollo/client';

export const UPDATE_SPECIAL_DAY = gql`
  mutation updateSpecialDays($dayId: ID!, $day: DeliveryDayInput!) {
    updateSpecialDays(dayId: $dayId, day: $day) {
      _id
      enabled
    }
  }
`;

export const CREATE_SPECIAL_DAY = gql`
  mutation createSpecialDays($day: DeliveryDayInput!) {
    createSpecialDays(day: $day) {
      _id
    }
  }
`;

export const DELETE_SPECIAL_DAY = gql`
  mutation deleteSpecialDays($dayId: ID!) {
    deleteSpecialDays(dayId: $dayId) {
      _id
    }
  }
`;

export const ADD_GROUP_TO_SPECIAL_DAY = gql`
  mutation addGroupToSpecialDay($dayId: ID!, $groupId: ID!) {
    addGroupToSpecialDay(dayId: $dayId, groupId: $groupId) {
      _id
    }
  }
`;

export const REMOVE_CUSTOMER_FROM_SPECIAL_DAY = gql`
  mutation removeCustomerFromSpecialDay($dayId: ID!, $customerId: ID!) {
    removeCustomerFromSpecialDay(dayId: $dayId, customerId: $customerId) {
      _id
    }
  }
`;

export const ADD_CUSTOMERS_TO_SPECIAL_DAY = gql`
  mutation addCustomersToSpecialDay($dayId: ID!, $customerIds: [ID]!) {
    addCustomersToSpecialDay(dayId: $dayId, customerIds: $customerIds) {
      _id
    }
  }
`;
