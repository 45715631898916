import { gql } from '@apollo/client';

export const GET_SPECIAL_DAYS = gql`
  query GetAllSpecialDays($type: String!) {
    getAllSpecialDays(type: $type) {
      deliveryDays {
        _id
        vendorId
        type
        enabled
        applyForDate
        customerGroups {
          _id
          name
          color
          translations {
            lang
            value
          }
        }
        customers {
          _id
          contactData {
            businessName
          }
        }
      }
      totalResults
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
    }
  }
`;

export const GET_CUSTOMERS_FOR_DAY = gql`
  query GetCustomerForDay($dayId: ID!) {
    getSpecialDayCustomersPaginated(dayId: $dayId) {
      customers {
        _id
        vendorClientId
        contactData {
          businessName
        }
      }
      totalResults
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
    }
  }
`;
