import React from 'react';
import { Formik, getIn } from 'formik';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useValidationSchemaFunction } from './ValidationSchema';
import DetailPanelInput from './DetailPanelInput';
import { NOTIFICATION_STATUS } from '../../helpers/constants';
import { DOCUMENT_CREATE } from '../../graphql/mutation/orders';
import { SET_NOTIFICATION } from '../../graphql/mutation/user';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  confirmButton: {
    color: theme.palette.success.main,
    margin: 4,
    marginLeft: 'auto'
  },
  tableContainer: {
    marginBottom: theme.spacing(4)
  },
  tableHeaderCell: {
    paddingBottom: theme.spacing(1.5),
    fontSize: theme.typography.htmlFontSize,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: theme.palette.border.grey
  },
  dragNDropContainer: {
    minHeight: 60,
    height: 'auto',
    border: '1.5px dashed #D8D8D8',
    marginBottom: 10,
    // marginTop: 0,
    flexDirection: 'row',
    borderRadius: 4,
    outline: 'none',
    '&:focus': {
      border: `1.5px dashed ${theme.palette.primary.main}`
    }
  },
  uploadPhotoButton: {
    color: theme.palette.secondary.darkGray,
    textTransform: 'capitalize',
    marginLeft: 10,
    height: 45
  },
  tableBody: {
    padding: `${theme.spacing(0.5)}px 0px`,
    borderBottom: theme.palette.border.grey,
    fontSize: theme.typography.fontSize,
    alignItems: 'baseline',
    paddingBottom: 15,
    paddingTop: 15
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyItems: 'start'
  },
  gridInput: {
    paddingRight: 10
  },
  dropZoneTitle: {
    maxWidth: '50%'
  },
  headerTitle: {
    maxWidth: '75%'
  },
  tableSearchBarButtons: {
    minWidth: 130,
    height: 45,
    marginRight: theme.spacing(1.5),
    border: theme.palette.border.darkGray,
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  activeIcon: {
    color: theme.palette.primary.main
  }
}));

export default function DocumentForm({
  orderId,
  type = 'invoice',
  onSubmit = () => {}
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const validationSchema = useValidationSchemaFunction();
  const [documents, setDocuments] = React.useState([]);
  const [createMonduDocument] = useMutation(DOCUMENT_CREATE);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const onDrop = async (acceptedFiles, resetForm) => {
    if (documents.length > 0) {
      resetForm();
    }
    setDocuments(
      acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          file: file.name
        })
      )
    );
  };

  const handleSubmitOrder = async (values, callback) => {
    try {
      const uploadedDocument = documents && documents[0];
      if (uploadedDocument) {
        const { gross = 0, net = 0, documentNumber = '' } = values;
        const createdDocument = await createMonduDocument({
          variables: {
            type,
            document: uploadedDocument,
            orderId,
            gross: +gross,
            net: +net,
            documentNumber
          }
        });

        if (createdDocument.data.createMonduDocument.errors.length > 0) {
          const {
            details = '',
            name = ''
          } = createdDocument.data.createMonduDocument.errors[0];
          setNotification({
            variables: {
              timeout: 5000,
              message: `${t(`orders.${name}`)} ${details}`,
              type: NOTIFICATION_STATUS.ALERT,
              isOpen: true
            }
          });
          return;
        } else {
          document.getElementsByName('file')[0].value = '';
          setDocuments([]);
          setNotification({
            variables: {
              timeout: 5000,
              message: `${t('orders.successfully uploaded')}`,
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true
            }
          });
        }
      }

      if (uploadedDocument) {
        callback();
        onSubmit();
      }
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 5000,
          message: t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      key={`formik-${type}-${orderId}`}
    >
      {({
        values: formikValues,
        handleChange: formikChangeHandler,
        errors,
        validateForm,
        resetForm
      }) => (
        <>
          <Typography className={classes.sectionTitle} variant="h6">
            {t(`orders.${type}Type.sectionTitle`)}
          </Typography>
          <Grid container className={classes.tableContainer}>
            <Grid container className={classes.tableHeaderCell}>
              <Grid item xs={4}>
                <Typography>{t(`orders.${type}Type.document`)}</Typography>
              </Grid>
              <Grid item xs={2} className={classes.header}>
                <Typography className={classes.headerTitle}>
                  {t(`orders.${type}Type.total sum net`)}
                </Typography>
                <Tooltip
                  title={t(`orders.${type}Type.net tooltip`)}
                  placement="right-end"
                >
                  <InfoIcon
                    style={{ marginLeft: 4 }}
                    fontSize="small"
                    color="primary"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={2} className={classes.header}>
                <Typography className={classes.headerTitle}>
                  {t(`orders.${type}Type.total sum gross`)}
                </Typography>
                <Tooltip
                  title={t(`orders.${type}Type.gross tooltip`)}
                  placement="right-start"
                >
                  <InfoIcon
                    style={{ marginLeft: 4 }}
                    fontSize="small"
                    color="primary"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={2} className={classes.header}>
                <Typography>
                  {t(`orders.${type}Type.documentNumber`)}
                </Typography>
                <Tooltip
                  title={t(`orders.${type}Type.documentNumber tooltip`)}
                  placement="right-end"
                >
                  <InfoIcon
                    style={{ marginLeft: 4 }}
                    fontSize="small"
                    color="primary"
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.tableBody}>
              <Grid item xs={4}>
                <Dropzone
                  onDrop={acceptedFiles => onDrop(acceptedFiles, resetForm)}
                  noClick
                  multiple={false}
                >
                  {({ getRootProps, getInputProps, open }) => (
                    <Grid
                      xs={11}
                      item
                      className={classes.dragNDropContainer}
                      container
                      alignItems="center"
                      justifyContent="center"
                      direction="column"
                      wrap="nowrap"
                      {...getRootProps()}
                    >
                      <input name="file" {...getInputProps()} />
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className={classes.dropZoneTitle}
                      >
                        {t(`orders.${type}Type.drag and drop`)}
                      </Typography>
                      <Button
                        className={classes.uploadPhotoButton}
                        variant="outlined"
                        startIcon={<UploadIcon color="primary" />}
                        onClick={open}
                      >
                        {t('orders.upload')}
                      </Button>
                    </Grid>
                  )}
                </Dropzone>

                <Grid item xs={12}>
                  {documents.map(invoice => (
                    <Typography variant="subtitle2" color="textSecondary">
                      {invoice.name}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={2} className={classes.gridInput}>
                <DetailPanelInput
                  name="net"
                  className={classes.panelInput}
                  value={formikValues.net}
                  onChange={formikChangeHandler}
                  error={getIn(errors, 'net')}
                />
              </Grid>
              <Grid item xs={2} className={classes.gridInput}>
                <DetailPanelInput
                  name="gross"
                  className={classes.panelInput}
                  value={formikValues.gross}
                  onChange={formikChangeHandler}
                  error={getIn(errors, 'gross')}
                />
              </Grid>
              <Grid item xs={2} className={classes.gridInput}>
                <DetailPanelInput
                  name="documentNumber"
                  className={classes.panelInput}
                  value={formikValues.documentNumber}
                  onChange={formikChangeHandler}
                  error={getIn(errors, 'documentNumber')}
                />
              </Grid>
              <Grid item xs={1} className={classes.confirmButton}>
                <Button
                  aria-label="confirm"
                  className={classNames(classes.tableSearchBarButtons)}
                  variant="outlined"
                  startIcon={<AddIcon className={classes.activeIcon} />}
                  onClick={async () => {
                    const validate = await validateForm();
                    if (isEmpty(validate)) {
                      handleSubmitOrder(formikValues, resetForm);
                    }
                  }}
                >
                  <Typography>{t('orders.submit')}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}

DocumentForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};
