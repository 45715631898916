import React, {
  useRef,
  useEffect,
  createContext,
  forwardRef,
  useContext,
  cloneElement,
  Children,
} from 'react';

import { VariableSizeList } from 'react-window';

import List from '@material-ui/core/List';

const LISTBOX_PADDING = 8; // px

// #region Virtual list from mui docs
// TODO: don't use cloneElement
// TODO: try virtuoso
function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props) {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

export const Listbox = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = Children.toArray(children);
  const itemSize = 45;
  const itemCount = itemData.length;
  const height = itemCount > 8 ? 8 * itemSize : itemCount * itemSize;

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={height + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType={List}
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
// #endregion
