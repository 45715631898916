import { gql } from '@apollo/client';
import { GROUP_FRAGMENT } from 'graphql/fragments';

export const CREATE_VENDOR_CUSTOMER = gql`
  ${GROUP_FRAGMENT}
  mutation createCustomer($customerData: CreateCustomerInput!) {
    createCustomer(customerData: $customerData) {
      _id
      vendorClientId
      vendorId
      status
      ccEmails
      customerGroups {
        ...GroupFragment
      }
      contacts {
        email
        _id
        inviteLink
        registrationCode
        status
        userMeta {
          name
          email
        }
      }
      contactData {
        businessName
        country
        street
        zip
        houseNumber
        city
      }
      deliveryDays {
        enabled
        name
      }
      lists {
        _id
        title
      }
      createdAt
      orders
    }
  }
`;

export const UPDATE_VENDOR_CUSTOMER = gql`
  ${GROUP_FRAGMENT}
  mutation updateCustomer(
    $connectionId: ID!
    $customerData: UpdateCustomerInput!
  ) {
    updateCustomer(connectionId: $connectionId, customerData: $customerData) {
      _id
      vendorClientId
      vendorId
      status
      ccEmails
      contacts {
        name
        email
        phone
        additionalPhones
        _id
        inviteLink
        registrationCode
        status
        userMeta {
          name
          email
        }
      }
      users {
        name
        email
      }
      contactData {
        businessName
        street
        houseNumber
        zip
        city
        country
      }
      deliveryDays {
        enabled
        name
      }
      lists {
        _id
        title
      }
      customerGroups {
        ...GroupFragment
      }
      blockedDates {
        start
        end
      }
      createdAt
      orders
    }
  }
`;

export const DELETE_VENDOR_CUSTOMER = gql`
  mutation deleteCustomer($connectionId: ID!) {
    deleteCustomer(connectionId: $connectionId) {
      _id
      vendorClientId
      vendorId
      status
      contactData {
        businessName
        country
        street
        zip
        houseNumber
        city
      }
    }
  }
`;

export const SEND_VENDOR_INVITATION = gql`
  mutation inviteCustomers($connectionIds: [ID]!, $notify: Boolean) {
    inviteCustomers(connectionIds: $connectionIds, notify: $notify) {
      _id
      vendorClientId
      vendorId
      status
      ccEmails
      contacts {
        _id
        email
        inviteLink
        registrationCode
        status
        userMeta {
          name
          email
        }
      }
      contactData {
        businessName
        country
        street
        zip
        houseNumber
        city
      }
      deliveryDays {
        enabled
        name
      }
      lists {
        _id
        title
      }
      createdAt
      updatedAt
      orders
    }
  }
`;

export const SEND_CONTACT_INVITATION = gql`
  mutation inviteCustomerContact(
    $connectionId: ID!
    $contactId: ID!
    $notify: Boolean
  ) {
    inviteCustomerContact(
      connectionId: $connectionId
      contactId: $contactId
      notify: $notify
    ) {
      _id
      vendorClientId
      vendorId
      status
      ccEmails
      contacts {
        _id
        email
        inviteLink
        registrationCode
        status
        userMeta {
          name
          email
        }
      }
      contactData {
        businessName
        country
        street
        zip
        houseNumber
        city
      }
      deliveryDays {
        enabled
        name
      }
      lists {
        _id
        title
      }
      createdAt
      updatedAt
      orders
    }
  }
`;
