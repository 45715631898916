import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

function Loader({ styles }) {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100vh', color: '#FF5100', ...styles }}
    >
      <CircularProgress color="inherit" />
    </Grid>
  );
}

Loader.defaultProps = {
  styles: {}
};

Loader.propTypes = {
  styles: PropTypes.any,
};

export default Loader;
