import moment from 'moment';
import { CAMPAIGN_FREQUENCY } from 'helpers/constants';

export const CUSTOM_FREQUENCY_INIT_STATE = {
  _id: '1',
  repeat: 'Weekly',
  dayIndexes: [0],
  repeatInterval: 1,
  endAfterCount: 3,
  startDate: moment().toISOString(),
  endDate: undefined,
  time: {
    minute: 0,
    hour: 12,
  },
};

export const REPEAT_MAX = 50;
export const REPEAT_MIN = 1;

export const END_OPTIONS = {
  NEVER: 'never',
  ON: 'on',
  AFTER: 'after'
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const INIT_CAMPAIGN_DATA = {
  _id: 'add',
  title: '',
  schedule: {
    repeat: CAMPAIGN_FREQUENCY[0],
    dayIndexes: [],
    repeatInterval: undefined,
    endAfterCount: undefined,
    startDate: moment().toISOString(),
    endDate: undefined,
    time: {
      minute: 0,
      hour: 12,
    },
  },
  assignedToAll: false,
  assignedToCustomers: [],
  assignedToGroups: [],
  enabled: false,
  status: CAMPAIGN_STATUS.INACTIVE,
  next: undefined,
  message: '',
  sender: '',
  recipients: [],
};

export const EDIT_RECIPIENT_ACTIONS = {
  ASSIGN: 'assign',
  REMOVE: 'remove'
};

export const MAX_SENDER_NAME_LENGTH = 11;

export const DEFAULT_PHONE_OPTIONS = {
  phone: '',
  name: 'Germany',
  dialCode: '49',
  countryCode: 'de'
};

export const ADDITIONAL_MESSAGE_TEXT = '\n\nPer App bestellen:\nhttps://chefsl.ist/app\nMagic Code: 123456';

export const CUSTOM_CAMPAIGN_INTERVAL = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  ONCE: 'OneTime'
};

export const CUSTOM_PERIOD = 'Custom';

export const MESSAGE_LENGTH = 185;

export const removeEmoji = (message) => message.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

export const REPORT_INIT_STATE = {
  after: 0,
  pageSize: 10,
  sortBy: 'sendAt',
  sortOrder: -1,
  searchPhrase: '',
  startDate: null,
  endDate: null,
  status: '',
};

export const REPORT_STATUSES = {
  sent: 'Sent',
  failed: 'Failed',
  // NOTE: other statuses are for the future and need to replace properties with lowercase
  // success: 'success',
  // error: 'error',
  // received: 'received',
  // read: 'read',
};
