import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required(),
  vendorSpecificId: Yup.string().required(),
  image: Yup.string()
    .nullable()
    .notRequired(),
  description: Yup.string()
    .nullable()
    .notRequired(),
  categories: Yup.array()
    .nullable()
    .notRequired(),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        baseUnit: Yup.string()
          .nullable()
          .notRequired(),
        packaging: Yup.string().required(),
        price: Yup.number()
          .moreThan(-1)
          .nullable()
          .notRequired(),
        unitsInPackaging: Yup.number()
          .moreThan(0)
          .nullable()
          .notRequired(),
      })
    )
    .required(),
});
