import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    height: '70%'
  },
  backdropRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  actionButton: {
    textTransform: 'uppercase'
  }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function DeleteModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    isOpen,
    handleCloseModal,
    handleDelete,
    message
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      classes={{
        container: classes.dialogContainer
      }}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        }
      }}
      PaperProps={{
        elevation: 1
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        {message}
      </DialogTitle>
      <DialogActions>
        <Button className={classes.actionButton} onClick={handleCloseModal} color="primary">
          {t('common.abort')}
        </Button>
        <Button className={classes.actionButton} onClick={handleDelete} color="primary">
          {t('common.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};
