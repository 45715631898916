import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import DateInput from 'components/DealsTable/DateInput';
import PriceInput from 'components/DealsTable/PriceInput';
import ProductPackaging from 'components/ProductsTable/ProductPackaging';
import EmptyTableField from 'components/shared/EmptyTableField';
import { truncateTitle, calculateUnitPrice } from 'helpers/utils';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: `0px ${theme.spacing(1.5)}px`,
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  dateFieldCellEdit: {
    paddingLeft: 0
  }
}));

export default function DealsTableRowCells({
  productData,
  isEditMode,
  handleChangePrice,
  handleChangeDate,
  dateStatus
}) {
  const classes = useStyles();

  const {
    packaging = null,
    baseUnit = null,
    unitsInPackaging = null,
    dealPrice = null,
  } = productData.variants[0] ? productData.variants[0] : {};

  return (
    <>
      <TableCell className={classes.tableCell}>
        <Typography variant="body2" align="left">
          {productData.vendorSpecificId}
        </Typography>
      </TableCell>
      <TableCell
        className={classes.tableCell}
      >
        {truncateTitle(productData.name)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PriceInput
          price={dealPrice || ''}
          isEditMode={isEditMode}
          justify="center"
          handleChangePrice={({ target: { value } }) =>
            handleChangePrice(value, productData.variants[0]._id)}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ProductPackaging
          unit={packaging || ''}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        {calculateUnitPrice(dealPrice, unitsInPackaging)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {baseUnit || <EmptyTableField align="center" />}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {unitsInPackaging || <EmptyTableField align="center" />}
      </TableCell>
      <TableCell className={classNames(
        classes.tableCell,
        isEditMode && classes.dateFieldCellEdit
      )}
      >
        <DateInput
          dateStatus={dateStatus}
          date={productData?.dealDetails?.start ?? ''}
          dateType="start"
          isEditMode={isEditMode}
          handleChangeDate={handleChangeDate}
        />
      </TableCell>
      <TableCell className={classNames(
        classes.tableCell,
        isEditMode && classes.dateFieldCellEdit
      )}
      >
        <DateInput
          dateStatus={dateStatus}
          date={productData?.dealDetails?.end ?? ''}
          dateType="end"
          minDate={productData?.dealDetails?.start ?? ''}
          isEditMode={isEditMode}
          handleChangeDate={handleChangeDate}
        />
      </TableCell>
    </>
  );
}

DealsTableRowCells.propTypes = {
  productData: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  handleChangePrice: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  dateStatus: PropTypes.string.isRequired
};
