import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import DropDowIcon from '@material-ui/icons/ArrowDropDown';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(({ palette, spacing }) => ({
  hasAttributes: {
    color: palette.success.main,
  },
  selectorContainer: {
    width: 300,
    maxHeight: 500,
    overflowY: 'auto',
    padding: spacing(1.25, 2.5),
  },
  selectorTitle: {
    color: palette.secondary.darkGray,
    fontStyle: 'italic',
    padding: spacing(1.25, 0),
  },
  listButton: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: spacing(1.25, 0),
  },
  popoverPaper: {
    boxShadow: palette.customShadows.popover,
  },
}));

function ProductAttributesList({ attributes }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoveToAttribute = attributeId => () => {
    history.push(`/products/attributes?attributeId=${attributeId}`);
  };

  const handleOpenSelector = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const selectorIsOpen = Boolean(anchorEl);
  const hasAttributes = attributes && attributes.length > 0;

  return (
    <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
      {hasAttributes ? (
        <Typography className={classes.hasAttributes}>
          {attributes.length}
        </Typography>
      ) : (
        <EmptyTableField />
      )}
      {hasAttributes && (
        <Grid item>
          <IconButton size="small" onClick={handleOpenSelector}>
            <DropDowIcon />
          </IconButton>
        </Grid>
      )}
      <Popover
        classes={{ paper: classes.popoverPaper }}
        open={selectorIsOpen}
        anchorEl={anchorEl}
        onClose={handleCloseSelector}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.selectorContainer}
        >
          <Typography className={classes.selectorTitle}>
            {t('product attributes.title')}
          </Typography>
          {attributes
            .filter(attr => attr)
            .map(attribute => (
              <Button
                key={attribute?._id}
                onClick={handleMoveToAttribute(attribute?._id)}
                className={classes.listButton}
                variant="text"
                fullWidth
              >
                {attribute?.name}
              </Button>
            ))}
        </Grid>
      </Popover>
    </Grid>
  );
}

ProductAttributesList.propTypes = {
  attributes: PropTypes.array,
};

ProductAttributesList.defaultProps = {
  attributes: null,
};

export default React.memo(ProductAttributesList, (prevProps, nextProps) =>
  isEqual(prevProps.attributes, nextProps.attributes)
);
