import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { DatePicker as DatePickerMaterial } from '@material-ui/pickers';
import defaultTheme from '../../theme';

const useMaterialStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'none',
  },
  inputRoot: {
    maxWidth: '120px',
    color: '#000',
  },
  input: {
    padding: 0,
    cursor: 'pointer',
  },
  desktopContainer: {
    marginTop: spacing(2),
    borderRadius: spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const theme = defaultTheme();

const desktopCalendarTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPickersDatePickerRoot: {
      toolbarLandscape: {
        backgroundColor: `${theme.palette.background.default} !important`,
        color: 'black'
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: theme.palette.text.primary
      },
      toolbarBtnSelected: {
        color: theme.palette.text.primary
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: `${theme.palette.background.default} !important`,
        borderRadius: '8px',
        border: `1px solid ${theme.palette.common.black}`,
        minWidth: 300
      }
    }
  }
});

export const DatePicker = props => {
  const {
    selectedDate,
    handleDateChange,
    minDate,
    maxDate,
    disabledDates,
    isOpen,
    setIsOpen,
    desktopView
  } = props;
  const classes = useMaterialStyles();
  const { t } = useTranslation();

  const noDeliveryAvailable = disabledDates?.length >= 7;

  return desktopView ? (
    <Grid container className={classes.desktopContainer}>
      <ThemeProvider theme={desktopCalendarTheme}>
        <DatePickerMaterial
          open={isOpen}
          variant="static"
          orientation="portrait"
          onOpen={() => {
            setIsOpen(true);
          }}
          disabled={noDeliveryAvailable}
          onClose={() => setIsOpen(false)}
          format="DD.MM.YYYY"
          value={selectedDate}
          onChange={handleDateChange}
          inputVariant="standard"
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.inputRoot,
              input: classes.input,
            },
          }}
          classes={{
            root: classes.staticClass,
            toolbar: classes.toolbar
          }}
          disableToolbar
          maxDate={maxDate}
          minDate={minDate || moment()}
          shouldDisableDate={date => {
            if (!disabledDates) return false;
            return disabledDates.includes(date.day()) || disabledDates.includes(date.toISOString());
          }}
          okLabel={t('cart.Save')}
          cancelLabel={t('cart.Cancel')}
        />
      </ThemeProvider>

    </Grid>
  ) : (
    <DatePickerMaterial
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => setIsOpen(false)}
      format="DD.MM.YYYY"
      value={selectedDate}
      disabled={noDeliveryAvailable}
      onChange={handleDateChange}
      inputVariant="standard"
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
      }}
      classes={{
        root: classes.root,
      }}
      maxDate={maxDate}
      minDate={minDate || moment()}
      shouldDisableDate={date => {
        if (!disabledDates) return false;
        return disabledDates.includes(date.day()) || disabledDates.includes(date.toISOString());
      }}
      okLabel={t('cart.Save')}
      cancelLabel={t('cart.Cancel')}
    />
  );
};

DatePicker.defaultProps = {
  selectedDate: new Date(Date.now()),
  minDate: moment(new Date(Date.now())),
  maxDate: moment(new Date(Date.now())).add(3, 'M'),
  isOpen: false,
  disabledDates: null,
  desktopView: false,
};

DatePicker.propTypes = {
  desktopView: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  selectedDate: PropTypes.any,
  handleDateChange: PropTypes.func.isRequired,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  disabledDates: PropTypes.array,
};
