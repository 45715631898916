import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FieldArray, getIn } from 'formik';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AccountIcon from '@material-ui/icons/AccountCircle';

import DeliveryDays from 'components/CustomerTable/DeliveryDays';
import DetailPanelInput from './DetailPanelInput';
import DetailPanelCountrySelector from './DetailPanelCountrySelect';
import DetailPanelCustomerInfo from './DetailPanelCustomerInfo';

const useStyles = makeStyles(theme => ({
  detailPanelContainer: {
    background: theme.palette.background.container,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(5)}px ${theme.spacing(6.5)}px`,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(2.5)}px ${theme.spacing(10.5)}px ${theme.spacing(6.5)}px`
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  tableContainer: {
    marginBottom: theme.spacing(4)
  },
  tableHeaderCell: {
    paddingBottom: theme.spacing(1.5),
    fontSize: theme.typography.htmlFontSize,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: theme.palette.border.grey
  },
  tableBody: {
    padding: `${theme.spacing(0.5)}px 0px`,
    borderBottom: theme.palette.border.grey,
    fontSize: theme.typography.fontSize,
  },
  addContactButton: {
    padding: `${theme.spacing(0.5)}px 0px`,
    borderBottom: 'none',
  },
  statusPanel: {
    width: '100%'
  },
  userChip: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    height: 'auto',
    minHeight: 50,
    borderRadius: 32
  }
}));

export default function DetailPanel({
  isEditModeActive,
  formikChangeHandler,
  formikValues,
  errors,
  setFormikFieldValue,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const DeliveryAddressRowTitles = [
    {
      title: t('customers.street'),
      widthXs: 5,
    },
    {
      title: t('customers.house'),
      widthXs: 2,
    },
    {
      title: t('customers.post code'),
      widthXs: 2,
    },
    {
      title: t('customers.city'),
      widthXs: 3,
    },
  ];

  const disableInput = !isEditModeActive;

  return formikValues.contactData ? (
    <Grid container className={classes.detailPanelContainer} >

      <Typography
        className={classes.sectionTitle}
        variant="h6"
      >
        {t('customers.main information')}
      </Typography>
      <Grid container className={classes.tableContainer}>
        <Grid container className={classes.tableHeaderCell}>
          <Grid item xs={3}>
            {t('customers.business')}
          </Grid>
          <Grid item xs={3}>
            {t('customers.customer number')}
          </Grid>
          <Grid item xs={5}>
            {t('customers.delivery days')}
          </Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.tableBody}>
          <Grid item xs={3}>
            <DetailPanelInput
              name="contactData.businessName"
              value={formikValues.contactData.businessName}
              disabled={disableInput}
              onChange={formikChangeHandler}
              error={getIn(errors, 'contactData.businessName')}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailPanelInput
              name="vendorClientId"
              value={formikValues.vendorClientId}
              disabled={disableInput}
              onChange={formikChangeHandler}
              error={getIn(errors, 'vendorClientId')}
            />
          </Grid>
          <Grid
            item
            xs={5}
            container
            alignItems="center"
          >
            <DeliveryDays
              days={formikValues.deliveryDays}
              isEditMode={isEditModeActive}
              setFieldValue={setFormikFieldValue}
              errors={errors}
              inDetailPanel
            />
          </Grid>
        </Grid>
      </Grid>

      <DetailPanelCustomerInfo
        formikChangeHandler={formikChangeHandler}
        formikValues={formikValues}
        disableInput={disableInput}
        isEditModeActive={isEditModeActive}
      />

      {formikValues.users?.length > 0 && (
        <Grid container className={classes.tableContainer}>
          <Grid container className={classes.tableHeaderCell}>
            <Grid item xs={3}>
              {t('customers.registered user names')}
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.tableBody}>
            <Grid item xs={12}>
              {formikValues.users.map((user, index) => (
                <Chip
                // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  icon={<AccountIcon />}
                  label={
                    <Grid container wrap="nowrap" direction="column">
                      <Typography>
                        {user.name}
                      </Typography>
                      <Typography>
                        {user.email}
                      </Typography>
                    </Grid>
                  }
                  className={classes.userChip}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Typography
        className={classes.sectionTitle}
        variant="h6"
      >
        {t('customers.delivery address')}
      </Typography>
      <Grid container className={classes.tableContainer}>
        <Grid container className={classes.tableHeaderCell}>
          {DeliveryAddressRowTitles.map(cell => (
            <Grid key={cell.title} item xs={cell.widthXs}>
              {cell.title}
            </Grid>
          ))}
        </Grid>
        <Grid container className={classes.tableBody} wrap="nowrap" spacing={2}>
          <Grid className={classes.tableBodyCell} item xs={5}>
            <DetailPanelInput
              name="contactData.street"
              value={formikValues.contactData.street}
              disabled={disableInput}
              onChange={formikChangeHandler}
              error={getIn(errors, 'contactData.street')}
              fullWidth
            />
          </Grid>
          <Grid className={classes.tableBodyCell} item xs={2}>
            <DetailPanelInput
              name="contactData.houseNumber"
              value={formikValues.contactData.houseNumber}
              disabled={disableInput}
              onChange={formikChangeHandler}
              error={getIn(errors, 'contactData.houseNumber')}
            />
          </Grid>
          <Grid className={classes.tableBodyCell} item xs={2}>
            <DetailPanelInput
              name="contactData.zip"
              value={formikValues.contactData.zip}
              disabled={disableInput}
              onChange={formikChangeHandler}
              error={getIn(errors, 'contactData.zip')}
            />
          </Grid>
          <Grid className={classes.tableBodyCell} item xs={3}>
            <DetailPanelInput
              name="contactData.city"
              value={formikValues.contactData.city}
              disabled={disableInput}
              onChange={formikChangeHandler}
              error={getIn(errors, 'contactData.city')}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.tableContainer}>
        <Grid container className={classes.tableHeaderCell}>
          <Grid item xs={3}>
            {t('customers.country')}
          </Grid>
        </Grid>
        <Grid container className={classes.tableBody}>
          <Grid className={classes.tableBodyCell} item xs={3}>
            {isEditModeActive ?
              (
                <DetailPanelCountrySelector
                  name="contactData.country"
                  value={formikValues.contactData.country}
                  setFormikFieldValue={setFormikFieldValue}
                />
              ) : (
                <DetailPanelInput
                  name="contactData.country"
                  value={formikValues.contactData.country}
                  disabled
                />
              )}
          </Grid>
        </Grid>
      </Grid>

      <Typography
        className={classes.sectionTitle}
        variant="h6"
      >
        {t('customers.communication')}
      </Typography>
      <Grid container className={classes.tableContainer}>
        <Grid container className={classes.tableHeaderCell}>
          <Grid container item xs={3} alignItems="center">
            {t('customers.cc email')}
            <Tooltip
              title={t('customers.email tooltip')}
              placement="right-end"
            >
              <InfoIcon style={{ marginLeft: 4 }} fontSize="small" color="primary" />
            </Tooltip>
          </Grid>
        </Grid>
        <FieldArray name="ccEmails">
          {({ push, remove }) => (
            <>
              {formikValues.ccEmails.map((email, index) => (
                <Grid
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  container
                  alignItems="center"
                  className={classes.tableBody}
                >
                  <Grid item xs={5}>
                    <DetailPanelInput
                      name={`ccEmails[${index}]`}
                      value={email}
                      disabled={disableInput}
                      onChange={formikChangeHandler}
                      fullWidth
                    />
                  </Grid>
                  {isEditModeActive &&
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon color="primary" />
                  </IconButton>}
                </Grid>
              ))}
              <Grid
                container
                className={classNames(classes.tableBody, classes.addContactButton)}
              >
                <Button
                  disabled={disableInput}
                  variant="text"
                  color="primary"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => push('')}
                >
                  {t('customers.add ccEmail')}
                </Button>
              </Grid>
            </>
          )}
        </FieldArray>
      </Grid>

    </Grid>
  ) : (
    <div>No Data Available</div>
  );
}

DetailPanel.propTypes = {
  isEditModeActive: PropTypes.bool.isRequired,
  formikChangeHandler: PropTypes.func.isRequired,
  setFormikFieldValue: PropTypes.func.isRequired,
  formikValues: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
