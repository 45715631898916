import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
// import EmojiPicker from 'emoji-picker-react';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
// import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import BackIcon from '@material-ui/icons/ArrowBack';
import DateIcon from '@material-ui/icons/Event';
import InfoIcon from '@material-ui/icons/InfoOutlined';
// import EmojiIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import TimeIcon from '@material-ui/icons/Schedule';
import SendIcon from '@material-ui/icons/Send';

import { CAMPAIGN_FREQUENCY } from 'helpers/constants';
import {
  INIT_CAMPAIGN_DATA,
  MAX_SENDER_NAME_LENGTH,
  CUSTOM_PERIOD,
  MESSAGE_LENGTH,
} from 'components/Campaigns/campaignConstants';
import CampaignPreview from 'components/Campaigns/CampaignPreview';
import CampaignValidationSchema from './CampaignValidationSchema';
import getCampaignPeriodTitle from './getCampaignPeriodTitle';

const useStyles = makeStyles(({ palette, spacing }) => ({
  campaignTopMenu: {
    paddingBottom: spacing(2),
    borderBottom: palette.border.lightGrey,
  },
  backButton: {
    marginRight: spacing(1),
    width: 50,
    height: 50,
  },
  actionButtons: {
    minWidth: 120,
    height: 56,
    boxShadow: 'none',
    margin: spacing(0, 2),
  },
  endAdornment: {
    margin: 0,
    paddingRight: spacing(0.5),
    cursor: 'pointer',
  },
  singleCampaignSection: {
    marginBottom: spacing(2),
  },
  timeContainer: {
    position: 'relative',
  },
  selectMenu: {
    maxWidth: 250,
    whiteSpace: 'pre-wrap',
  },
  multilineInput: {
    padding: spacing(2, 0, 0),
  },
  emojiIconButton: {
    position: 'absolute',
    top: 24,
    right: 8,
  },
  inputError: {
    background: palette.error.background,
  },
  recipientsSection: {
    paddingBottom: spacing(2.5),
  },
  manageRecipientsButton: {
    width: 315,
  },
  frequencySelector: {
    width: 'fit-content',
    minWidth: 120,
  },
  timeErrorMessage: {
    width: 200,
    position: 'absolute',
    top: 65,
    left: 0,
  },
  limitationContainer: {
    marginTop: spacing(1)
  },
}));

function SingleCampaignForm({
  isNewCampaign,
  campaignState,
  // isEmojiOpen,
  testPhoneNumber,
  // isCustomPeriodModalOpen,
  handleValidateBeforeSubmit,
  handleCloseEditCampaign,
  // setCustomPeriodModalOpen,
  // setEmojiPickerOpen,
  setTestPhoneNumber,
  handleSendTestMessage,
  handleOpenManageRecipients,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  // NOTE: Emojis are disabled
  return (
    <Formik
      initialValues={{
        ...campaignState,
      }}
      enableReinitialize
      onSubmit={handleValidateBeforeSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CampaignValidationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        validateForm,
      }) => {
        const {
          time = {},
          repeatInterval,
          dayIndexes = [],
          startDate = moment(),
          repeat,
        } = values?.schedule || {};

        const campaignTime = moment(startDate).set({
          hour: time?.hour || 12,
          minute: time?.minute || 0,
        });
        const numberOfRecipients = values?.recipients?.length || 0;

        const isCustomPeriod = !!repeatInterval || dayIndexes?.length > 0;

        const isFormHasChanges = !isEqual(campaignState, values);
        const isTimeError =
          errors?.schedule &&
          (errors.schedule.time?.hour || errors.schedule.time?.minute);

        return (
          <>
            <Grid
              container
              className={classNames(
                classes.singleCampaignSection,
                classes.campaignTopMenu
              )}
              justifyContent="space-between"
            >
              <Grid container item xs={6} wrap="nowrap">
                <IconButton
                  className={classes.backButton}
                  size="small"
                  onClick={() => handleCloseEditCampaign(values)}
                >
                  <BackIcon color="primary" />
                </IconButton>
                <TextField
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  error={!!errors.title}
                  placeholder={t('campaigns.add campaign title')}
                  label={t('common.title')}
                  variant="filled"
                  fullWidth
                  InputProps={{
                    disableUnderline: !errors.title,
                    classes: { error: classes.inputError },
                  }}
                />
              </Grid>
              <Grid container item xs={5} wrap="nowrap" justifyContent="flex-end">
                <Button
                  className={classes.actionButtons}
                  variant="contained"
                  color="secondary"
                  disabled={isNewCampaign}
                  onClick={() => handleCloseEditCampaign(values)}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  className={classes.actionButtons}
                  variant="contained"
                  color="primary"
                  disabled={!isFormHasChanges}
                  onClick={handleSubmit}
                >
                  {t('common.save')}
                </Button>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid
                container
                item
                xs={6}
                className={classes.singleCampaignSection}
                direction="column"
                wrap="nowrap"
              >
                <Grid
                  className={classes.singleCampaignSection}
                  container
                  direction="row"
                  spacing={1}
                >
                  <Grid item xs={5}>
                    <TextField
                      name="sender"
                      value={values.sender}
                      onChange={handleChange}
                      placeholder={t('campaigns.add sender name')}
                      label={t('campaigns.sender')}
                      variant="filled"
                      fullWidth
                      autoComplete="off"
                      error={!!errors.sender}
                      InputProps={{
                        disableUnderline: !errors.sender,
                        classes: { error: classes.inputError },
                        endAdornment: (
                          <InputAdornment
                            className={classes.endAdornment}
                            position="end"
                          >
                            <Tooltip
                              title={t('campaigns.sender title note')}
                              placement="right-start"
                            >
                              <InfoIcon color="primary" />
                            </Tooltip>
                          </InputAdornment>
                        ),
                        inputProps: {
                          maxLength: MAX_SENDER_NAME_LENGTH,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                      variant="inline"
                      value={startDate}
                      onChange={(value) =>
                        setFieldValue(
                          'schedule.startDate',
                          moment(value).toISOString()
                        )}
                      autoOk
                      format="DD-MM-YYYY"
                      maxDate={moment().add(1, 'y')}
                      minDate={isNewCampaign ? moment() : startDate || moment()}
                      label={t('campaigns.start date')}
                      fullWidth
                      inputVariant="filled"
                      disabled={!isNewCampaign}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            className={classes.endAdornment}
                            position="end"
                          >
                            <DateIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.timeContainer}>
                    <TimePicker
                      clearable
                      ampm={false}
                      label={t('campaigns.time')}
                      inputVariant="filled"
                      value={campaignTime}
                      onChange={(value) => {
                        const minute = moment(value).get('minute');
                        const hour = moment(value).get('hour');
                        setFieldValue('schedule.time', {
                          minute,
                          hour,
                        });
                      }}
                      cancelLabel={t('common.cancel')}
                      clearLabel={t('common.clear')}
                      okLabel="Ok"
                      InputProps={{
                        error: !!isTimeError,
                        disableUnderline: !isTimeError,
                        classes: { error: classes.inputError },
                        endAdornment: (
                          <InputAdornment
                            className={classes.endAdornment}
                            position="end"
                          >
                            <TimeIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {isTimeError && (
                      <Typography
                        className={classes.timeErrorMessage}
                        color="error"
                        variant="caption"
                      >
                        {t('campaigns.notifications.invalid time')}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      classes={{ root: classes.frequencySelector }}
                      variant="filled"
                      fullWidth
                    >
                      <InputLabel>{t('campaigns.frequency')}</InputLabel>
                      <Select
                        classes={{
                          selectMenu: classes.selectMenu,
                        }}
                        value={isCustomPeriod ? 'ACTIVE' : repeat}
                        renderValue={(label) =>
                          getCampaignPeriodTitle(values.schedule, label)}
                        onChange={(event) => {
                          const newFrequency = event.target.value;
                          if (newFrequency === 'ACTIVE') {
                            return;
                          }
                          if (newFrequency === CUSTOM_PERIOD) {
                            // setCustomPeriodModalOpen(true);
                            setFieldValue('schedule.repeat', newFrequency);
                          } else if (isCustomPeriod) {
                            setFieldValue('schedule', {
                              ...INIT_CAMPAIGN_DATA.schedule,
                              startDate,
                              time,
                              repeat: newFrequency,
                            });
                          } else {
                            setFieldValue('schedule.repeat', newFrequency);
                          }
                        }}
                        disableUnderline
                      >
                        {CAMPAIGN_FREQUENCY.map((period) => (
                          <MenuItem key={period} value={period}>
                            {t(`campaigns.period.${period}`)}
                          </MenuItem>
                        ))}
                        {/* {isCustomPeriod && (
                          <MenuItem key="ACTIVE" value="ACTIVE">
                            {customPeriodTitle}
                          </MenuItem>
                        )}
                        <MenuItem key={CUSTOM_PERIOD} value={CUSTOM_PERIOD}>
                          {t(`campaigns.period.${CUSTOM_PERIOD}`)}
                        </MenuItem> */}
                      </Select>
                    </FormControl>
                    {/* {isCustomPeriodModalOpen && (
                      <SingleCampaignCustomPeriodModal
                        isOpen={isCustomPeriodModalOpen}
                        campaignSchedule={values?.schedule}
                        setFormikFields={setFieldValue}
                        handleClose={() => setCustomPeriodModalOpen(false)}
                      />
                    )} */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  className={classes.singleCampaignSection}
                >
                  <TextField
                    label={t('campaigns.message')}
                    multiline
                    rows={12}
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    variant="filled"
                    error={!!errors.message}
                    InputProps={{
                      disableUnderline: !errors.sender,
                      classes: {
                        inputMultiline: classes.multilineInput,
                        error: classes.inputError,
                      },
                      // endAdornment: (
                      //   <InputAdornment className={classes.emojiIconButton}>
                      //     <IconButton
                      //       onClick={(e) => {
                      //         e.stopPropagation();
                      //         setEmojiPickerOpen((open) => !open);
                      //       }}
                      //     >
                      //       <EmojiIcon />
                      //     </IconButton>
                      //   </InputAdornment>
                      // ),
                    }}
                  />
                  {/* <Collapse in={isEmojiOpen}>
                    <EmojiPicker
                      preload
                      pickerStyle={{ width: '100%', boxShadow: 'unset' }}
                      native
                      name="message"
                      onEmojiClick={(_, emojiData) =>
                        setFieldValue(
                          'message',
                          values.message.concat(emojiData.emoji)
                        )}
                    />
                  </Collapse> */}
                  <Grid className={classes.limitationContainer}>
                    <Typography gutterBottom>
                      {t('campaigns.message limit')}
                    </Typography>
                    <Typography gutterBottom color={values.message.length > MESSAGE_LENGTH ? 'error' : 'textPrimary'}>
                      {`${t('campaigns.message length')} ${values.message.length || 0} / ${MESSAGE_LENGTH}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={5}
                direction="column"
                wrap="nowrap"
                className={classes.singleCampaignSection}
              >
                <CampaignPreview
                  businessName={values.sender}
                  message={values.message}
                />
                <Grid
                  className={classes.recipientsSection}
                  container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    className={classes.actionButtons}
                  >
                    {numberOfRecipients === 0 ? (
                      <Typography variant="h6" gutterBottom>
                        {t('campaigns.no recipients')}
                      </Typography>
                    ) : (
                      <Typography variant="h6" gutterBottom>
                        {t('campaigns.amount of recipients', {
                          numberOfRecipients,
                        })}
                      </Typography>
                    )}
                  </Grid>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classNames(
                      classes.actionButtons,
                      classes.manageRecipientsButton
                    )}
                    disabled={isNewCampaign}
                    onClick={() =>
                      handleOpenManageRecipients(values, validateForm)}
                  >
                    {t('campaigns.manage recipients')}
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  className={classes.singleCampaignSection}
                  wrap="nowrap"
                >
                  <MuiPhoneNumber
                    value={testPhoneNumber?.phone || ''}
                    onChange={(phone, phoneOptions) =>
                      setTestPhoneNumber({ phone, ...phoneOptions })}
                    autoFormat={false}
                    defaultCountry="de"
                    regions="europe"
                    variant="filled"
                    label={t('campaigns.phone for test')}
                    style={{ width: 275 }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment
                          className={classes.endAdornment}
                          position="end"
                        >
                          <Tooltip
                            title={t('campaigns.phone for test note')}
                            placement="top"
                          >
                            <InfoIcon color="primary" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    className={classes.actionButtons}
                    variant="contained"
                    color="secondary"
                    endIcon={<SendIcon />}
                    onClick={() => handleSendTestMessage(values)}
                  >
                    {t('campaigns.send test sms')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
}

export default React.memo(
  SingleCampaignForm,
  (prevProps, nextProps) =>
    isEqual(prevProps.campaignState, nextProps.campaignState) &&
    prevProps.isNewCampaign === nextProps.isNewCampaign &&
    prevProps.isEmojiOpen === nextProps.isEmojiOpen &&
    prevProps.testPhoneNumber === nextProps.testPhoneNumber
    // && prevProps.isCustomPeriodModalOpen === nextProps.isCustomPeriodModalOpen
);

SingleCampaignForm.propTypes = {
  isNewCampaign: PropTypes.bool.isRequired,
  campaignState: PropTypes.object.isRequired,
  // isEmojiOpen: PropTypes.bool.isRequired,
  testPhoneNumber: PropTypes.object.isRequired,
  // isCustomPeriodModalOpen: PropTypes.bool.isRequired,
  handleValidateBeforeSubmit: PropTypes.func.isRequired,
  handleCloseEditCampaign: PropTypes.func.isRequired,
  // setCustomPeriodModalOpen: PropTypes.func.isRequired,
  // setEmojiPickerOpen: PropTypes.func.isRequired,
  setTestPhoneNumber: PropTypes.func.isRequired,
  handleSendTestMessage: PropTypes.func.isRequired,
  handleOpenManageRecipients: PropTypes.func.isRequired,
};
