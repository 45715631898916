import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Picker from 'emoji-picker-react';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import classNames from 'classnames';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  REMOVE_CATEGORY_IMAGE,
  UPDATE_CATEGORY,
  UPLOAD_CATEGORY_IMAGE,
} from 'graphql/mutation/categories';
import { GET_VENDOR_CATEGORIES } from 'graphql/query/categories';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles((theme) => ({
  modalText: {
    width: 355,
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(1),
  },
  optionalMessage: {
    fontWeight: theme.typography.fontWeightLight,
    fontStyle: 'Italic',
    marginLeft: theme.spacing(0.5),
  },
  textInput: {
    width: '100%',
    border: theme.palette.border.lightGrey,
    borderRadius: 4,
    margin: `${theme.spacing(1)}px 0px`,
  },
  input: {
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    paddingTop: '38px !important',
  },
  formControl: {
    top: 16,
  },
  actionButton: {
    marginTop: theme.spacing(3.5),
    height: 50,
  },
  imageContainer: {
    padding: `${theme.spacing(1.5)}px 0px ${theme.spacing(4.5)}px`,
  },
  imagePreviewContainer: {
    height: 110,
    width: '100%',
    borderRadius: 4,
    border: theme.palette.border.darkGrey,
    position: 'relative',
  },
  noImage: {
    background: theme.palette.grey[50],
    color: '#DADADA',
  },
  noticeText: {
    width: 170,
    color: theme.palette.secondary.darkGray,
    margin: `${theme.spacing(1)}px 0px`,
  },
  dragNDropContainer: {
    height: 145,
    border: '1.5px dashed #D8D8D8',
    borderRadius: 4,
    outline: 'none',
    '&:focus': {
      border: `1.5px dashed ${theme.palette.primary.main}`,
    },
  },
  uploadPhotoButton: {
    color: theme.palette.secondary.darkGray,
    textTransform: 'capitalize',
  },
  imagePreview: {
    height: 110,
    maxWidth: '100%',
    width: 'inherit',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  deleteButton: {
    position: 'absolute',
    top: -theme.spacing(2),
    right: -theme.spacing(2),
    width: 40,
    height: 40,
  },
}));

const DEFAULT_CATEGORY_STATE = {
  name: '',
  mappedCategories: [],
  icon: '',
};

export default function CategoryModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const [createNewCategory] = useMutation(CREATE_CATEGORY);
  const [uploadCategoryImage] = useMutation(UPLOAD_CATEGORY_IMAGE);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [removeCategoryImage] = useMutation(REMOVE_CATEGORY_IMAGE);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [isOpenEmoji, setOpenEmojiPicker] = React.useState(false);
  const [isDeleteModalOpen, setOpenDeleteModal] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [newCategory, setNewCategory] = React.useState({
    ...DEFAULT_CATEGORY_STATE,
  });

  const {
    isOpen,
    handleClose,
    productCategories,
    vendorId,
    editCategory,
    setEditCategory
  } = props;

  const { getRootProps, getInputProps, open: openDragNDrop } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    noClick: true,
    multiple: false,
  });

  const uploadImage = async (categoryId) => {
    const uploadedFile = files && files[0];
    const options = {
      maxSizeMb: 0.5,
      maxWidthOrHeight: 768,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(uploadedFile, options);
    try {
      await uploadCategoryImage({
        variables: { file: compressedFile, categoryId },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCreateNewCategory = async () => {
    const { name, mappedCategories, icon } = newCategory;
    if (!mappedCategories.length) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('categories.please select at least one product category'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }
    if (!name) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('lists.please input the name of category'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    try {
      await createNewCategory({
        variables: {
          data: {
            name,
            mappedCategories,
            icon,
          },
        },
        refetchQueries: [
          {
            query: GET_VENDOR_CATEGORIES,
            variables: {
              vendorId,
            },
          },
        ],
        update: async (_, { data: newCategories }) => {
          if (newCategories?.createVendorCategory?._id) {
            if (files && files[0]) {
              await uploadImage(newCategories?.createVendorCategory?._id);
            }

            setNotification({
              variables: {
                timeout: 4000,
                message: t('categories.add category success'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        },
      });
      mixPanel.track('Create Category', {
        name,
        mappedCategories,
      });
      setNewCategory({ ...DEFAULT_CATEGORY_STATE });
    } catch (error) {
      console.error(error.message);
    }
    handleClose();
    setNewCategory({ ...DEFAULT_CATEGORY_STATE });
    setFiles([]);
  };

  const handleUpdateCategory = async () => {
    const { name, mappedCategories, icon } = newCategory;
    if (!mappedCategories.length) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('categories.please select at least one product category'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }
    if (!name) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('lists.please input the name of category'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    try {
      await updateCategory({
        variables: {
          data: {
            _id: editCategory._id,
            name,
            mappedCategories,
            icon: icon || '',
          },
        },
        refetchQueries: [
          {
            query: GET_VENDOR_CATEGORIES,
            variables: {
              vendorId,
            },
          },
        ],
        update: async (_, { data: newCategories }) => {
          if (newCategories?.updateVendorCategory?._id) {
            if (files && files[0]) {
              await uploadImage(newCategories?.updateVendorCategory?._id);
            }

            setNotification({
              variables: {
                timeout: 4000,
                message: t('categories.category updated success'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        },
      });
      mixPanel.track('Create Category', {
        name,
        mappedCategories,
      });
      setNewCategory({ ...DEFAULT_CATEGORY_STATE });
    } catch (error) {
      console.error(error.message);
    }
    handleClose();
    setNewCategory({ ...DEFAULT_CATEGORY_STATE });
    setFiles([]);
  };

  const handleDeletePhoto = async () => {
    if (!editCategory || !editCategory?.image) {
      setFiles([]);
      return;
    }
    if (editCategory?.image) {
      try {
        await removeCategoryImage({
          variables: { categoryId: editCategory?._id },
          update: (store, { data: { deleteVendorCategoryImage } }) => {
            if (deleteVendorCategoryImage) {
              setEditCategory(deleteVendorCategoryImage);
              setNotification({
                variables: {
                  timeout: 4000,
                  message: t('products.image modal.delete message'),
                  type: NOTIFICATION_STATUS.SUCCESS,
                  isOpen: true,
                },
              });
            }
          },
        });
      } catch (error) {
        console.error('ERROR', error);
        setNotification({
          variables: {
            timeout: 4000,
            message: error.message,
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }
  };

  const handleDeleteCategory = async () => {
    try {
      deleteCategory({
        variables: {
          id: editCategory._id
        }
      });
      handleClose();
      setOpenDeleteModal(false);
      setNewCategory({ ...DEFAULT_CATEGORY_STATE });
      setFiles([]);
      setNotification({
        variables: {
          timeout: 4000,
          message: t('categories.category deleted'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  React.useEffect(() => {
    if (editCategory) {
      setNewCategory(editCategory);
    }
    return () => {
      setNewCategory({ ...DEFAULT_CATEGORY_STATE });
    };
  }, [editCategory]);

  const imgSrc = (!!files.length && files[0].preview) || editCategory?.image;

  return (
    <>
      <ModalLayout
        isOpen={isOpen}
        handleClose={() => {
          handleClose();
          setFiles([]);
          setNewCategory({ ...DEFAULT_CATEGORY_STATE });
        }}
        modalTitle={t('categories.create category')}
      >
        <TextField
          label={t('categories.category name')}
          variant="outlined"
          className={classes.textInput}
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl,
            },
          }}
          value={newCategory.name || ''}
          onChange={(event) =>
            setNewCategory({ ...newCategory, name: event.target.value })}
        />
        <TextField
          label={t('categories.category icon')}
          variant="outlined"
          className={classes.textInput}
          InputProps={{
            classes: {
              input: classes.input,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenEmojiPicker(false);
                  }}
                >
                  {isOpenEmoji && <CloseIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl,
            },
          }}
          onClick={() => {
            setOpenEmojiPicker(true);
          }}
          value={newCategory.icon || ''}
        />
        <Collapse in={isOpenEmoji}>
          <Picker
            preload
            pickerStyle={{ width: '100%', boxShadow: 'unset' }}
            native
            onEmojiClick={(event, emoji) => {
              setNewCategory({ ...newCategory, icon: emoji.emoji });
              setOpenEmojiPicker(false);
            }}
          />
        </Collapse>
        {productCategories && (
          <Autocomplete
            multiple
            id="tags-standard"
            options={productCategories}
            value={newCategory.mappedCategories}
            onChange={(event, selected) => {
              setNewCategory({
                ...newCategory,
                mappedCategories: selected,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textInput}
                style={{ minHeight: 73 }}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                  ...params.InputProps,
                }}
                InputLabelProps={{
                  classes: {
                    formControl: classes.formControl,
                  },
                  ...params.InputLabelProps,
                }}
                variant="outlined"
                label={t('categories.product categories')}
                placeholder={t('categories.category')}
              />
            )}
          />
        )}
        <Grid
          className={classes.imageContainer}
          container
          alignItems="center"
          justifyContent="center"
        >
          {imgSrc && (
            <Grid
              container
              className={classNames(
                classes.imagePreviewContainer,
                !imgSrc && classes.noImage
              )}
              alignItems="center"
              justifyContent="center"
            >
              <Fab
                color="primary"
                className={classes.deleteButton}
                onClick={handleDeletePhoto}
                disabled={!imgSrc}
              >
                <DeleteIcon />
              </Fab>
              {imgSrc ? (
                <>
                  <img
                    className={classes.imagePreview}
                    src={imgSrc}
                    alt="product"
                  />
                </>
              ) : (
                <ImageOutlinedIcon />
              )}
            </Grid>
          )}
        </Grid>
        <Grid
          className={classes.dragNDropContainer}
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          wrap="nowrap"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Typography variant="subtitle2" color="textSecondary">
            {t('products.image modal.drag and drop')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {t('products.image modal.or')}
          </Typography>
          <Button
            className={classes.uploadPhotoButton}
            variant="outlined"
            startIcon={<UploadIcon color="primary" />}
            onClick={openDragNDrop}
          >
            {t('products.image modal.upload photo')}
          </Button>
        </Grid>

        <Button
          className={classes.actionButton}
          fullWidth
          onClick={editCategory ? handleUpdateCategory : handleCreateNewCategory}
          variant="contained"
          color="primary"
        >
          {t('deals.submit')}
        </Button>
        {editCategory && (
          <Button
            className={classes.actionButton}
            fullWidth
            onClick={() => setOpenDeleteModal(true)}
            variant="contained"
            color="secondary"
          >
            {t('categories.delete category')}
          </Button>
        )}
      </ModalLayout>
      <ModalLayout
        isOpen={isDeleteModalOpen}
        handleClose={() => setOpenDeleteModal(false)}
        modalTitle={t('categories.delete category')}
      >
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={handleDeleteCategory}
          variant="contained"
          color="primary"
        >
          {t('categories.delete')}
        </Button>
        <Button
          className={classes.actionButton}
          fullWidth
          onClick={() => setOpenDeleteModal(false)}
          variant="contained"
          color="secondary"
        >
          {t('categories.cancel')}
        </Button>
      </ModalLayout>
    </>
  );
}

CategoryModal.defaultProps = {
  editCategory: {},
};

CategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  productCategories: PropTypes.array.isRequired,
  vendorId: PropTypes.string.isRequired,
  editCategory: PropTypes.object,
  setEditCategory: PropTypes.func.isRequired,
};
