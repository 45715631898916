import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import { makeStyles, useTheme } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS } from '../localConstantsAndHelpers';

const useStyles = makeStyles(({ spacing, typography }) => ({
  recipientTableActiveContainer: {
    padding: spacing(2, 1, 0),
  },
  activeGroupsTitle: {
    fontWeight: typography.fontWeightMedium,
    paddingLeft: spacing(0.5),
    marginBottom: spacing(1),
  },
  recipientTableGroupChip: {
    margin: spacing(0, 1, 1, 0),
  },
}));

function RecipientTableActiveGroups({
  recipientAssignmentStatus,
  newsletterData,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const selectedGroups =
    (recipientAssignmentStatus ===
    NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED
      ? newsletterData?.assignedGroups
      : newsletterData?.excludedGroups) || [];

  const displayAssignedToAll =
    recipientAssignmentStatus ===
      NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED &&
    newsletterData?.assignedToAll;

  if (selectedGroups?.length === 0 && !displayAssignedToAll) {
    return null;
  }

  return (
    <Grid
      className={classes.recipientTableActiveContainer}
      container
      wrap="nowrap"
      direction="column"
    >
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.activeGroupsTitle}
      >
        {recipientAssignmentStatus ===
        NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED
          ? t('newsletter.assigned groups')
          : t('newsletter.excluded groups')}
      </Typography>
      <Grid container>
        {displayAssignedToAll && (
          <Chip
            key="everyone"
            className={classes.recipientTableGroupChip}
            label={t('newsletter.everyone')}
            color="primary"
          />
        )}
        {selectedGroups?.map(group => {
          const translation =
            group.translations &&
            group.translations.find(option => option.lang === i18n.language);
          const defaultTranslation = group.name;

          const backgroundColor =
            group.color || theme.palette.background.container;
          const textColor = theme.palette.getContrastText(backgroundColor);

          return (
            <Chip
              key={group._id}
              className={classes.recipientTableGroupChip}
              label={
                translation
                  ? translation.value
                  : t(`customers.restaurant group.${defaultTranslation}`, {
                    defaultValue:
                        group.name.slice(0, 1).toUpperCase() +
                        group.name.slice(1),
                  })
              }
              style={{
                background: backgroundColor,
                color: textColor,
              }}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

RecipientTableActiveGroups.propTypes = {
  recipientAssignmentStatus: PropTypes.string.isRequired,
  newsletterData: PropTypes.object,
};

RecipientTableActiveGroups.defaultProps = {
  newsletterData: {},
};

export default memo(
  RecipientTableActiveGroups,
  (prevProps, nextProps) =>
    prevProps.recipientAssignmentStatus ===
      nextProps.recipientAssignmentStatus &&
    isEqual(prevProps.newsletterData, nextProps.newsletterData)
);
