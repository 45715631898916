/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_VENDOR_CAMPAIGNS } from 'graphql/query/campaigns';
import { UPDATE_CAMPAIGN_STATUS, DELETE_CAMPAIGN } from 'graphql/mutation/campaigns';
import { GET_VENDOR_PROFILE } from 'graphql/query/user';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';
// import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

import TableLayout from 'components/shared/TableLayout';
import CampaignTableRow from 'components/Campaigns/CampaignTableRow';
import CampaignsMultiSelector from 'components/Campaigns/CampaignsMultiSelector';

import {
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
  NOTIFICATION_STATUS,
} from 'helpers/constants';
import { CAMPAIGN_STATUS } from 'components/Campaigns/campaignConstants';
import useMixPanel from 'helpers/useMixPanel';
import CampaignsSenderModal from 'components/Campaigns/CampaignsSenderModal';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  additionalActionButton: {
    height: 45,
    color: palette.secondary.darkGray,
    fontWeight: typography.fontWeightRegular,
  },
  manageContactsButton: {
    marginLeft: spacing(2),
  },
  columnTitle: {
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  },
  firstColumn: {
    paddingLeft: spacing(2.5),
  },
  smallWidth: {
    width: 80,
  },
  wideWidth: {
    width: 250,
  },
}));

export default function Campaigns() {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const mixPanel = useMixPanel();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [selected, setSelected] = React.useState([]);
  const [isSenderModalOpen, setSenderModalOpen] = React.useState(false);
  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'title',
    sortOrder: -1,
  });

  const [updateCampaignStatusMutation] = useMutation(UPDATE_CAMPAIGN_STATUS);
  const [deleteCampaignMutation] = useMutation(DELETE_CAMPAIGN);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [
    getVendorCampaigns,
    { loading: campaignsLoading, error: campaignsError, data },
  ] = useLazyQuery(GET_VENDOR_CAMPAIGNS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const { data: vendorData } = useQuery(GET_VENDOR_PROFILE);

  const vendorCampaigns = (data && data.campaigns) || [];

  const handleChangeCampaignStatus = async (event, campaignId) => {
    const status = event.target.checked
      ? CAMPAIGN_STATUS.ACTIVE
      : CAMPAIGN_STATUS.INACTIVE;

    try {
      await updateCampaignStatusMutation({
        variables: {
          campaignId,
          status,
        },
      });
      mixPanel.track('CAMPAIGN STATUS UPDATE', {
        campaignId,
        status,
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('campaigns.notifications.campaign status successfully updated'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error?.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleDeleteCampaign = async (campaignIds) => {
    try {
      await deleteCampaignMutation({
        variables: {
          campaignIds
        },
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('campaigns.notifications.campaign deleted'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getVendorCampaigns({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = vendorCampaigns?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (vendorCampaigns && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.push({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [vendorCampaigns]);

  const handleChangePage = async (event, page) => {
    if (
      vendorCampaigns &&
      (vendorCampaigns.hasNextPage || page + 1 < vendorCampaigns.totalPages)
    ) {
      try {
        getVendorCampaigns({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      getVendorCampaigns({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      getVendorCampaigns({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const tableColumns = [
    {
      title: t('common.title'),
      field: 'title',
      sortable: true,
      columnStyles: classNames(
        classes.columnTitle,
        classes.firstColumn,
        classes.wideWidth
      ),
    },
    {
      title: t('campaigns.recipients'),
      field: 'recipients.length',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.smallWidth),
    },
    {
      title: t('campaigns.schedule'),
      field: 'schedule',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.wideWidth),
    },
    {
      title: t('campaigns.activated'),
      field: 'status',
      sortable: true,
      columnStyles: classes.columnTitle,
    },
    {
      title: t('campaigns.next'),
      field: 'next',
      sortable: true,
      columnStyles: classes.columnTitle,
    },
  ];

  const { page, rowsPerPage, sortBy, sortOrder } = state;

  const hasError = campaignsError || (!campaignsLoading && !vendorCampaigns);
  const campaignsFeatureEnabled = vendorData?.vendorAccount?.preferences?.twilio?.enabled;

  return (
    <>
      <TableLayout
        title={t('campaigns.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.DEALS}
        emptyTableData={hasError || !vendorCampaigns?.totalResults}
        emptyTableDataMessage={t('campaigns.empty campaigns data')}
        tableIsLoading={campaignsLoading}
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={vendorCampaigns?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        headerActions={[
          campaignsFeatureEnabled ? (
            <Button
              key="addNewCampaign"
              className={classes.additionalActionButton}
              variant="outlined"
              startIcon={<AddCircleOutlineIcon color="primary" />}
              onClick={() => history.push('/campaign/add')}
            >
              {t('campaigns.new campaign')}
            </Button>
          ) : (
            <Typography>
              This feature is not enabled on your account :(
            </Typography>
          ),
          !!selected.length && (
            <CampaignsMultiSelector
              key="deleteButton"
              selectedCampaignIds={selected}
              setSelected={setSelected}
              handleDeleteCampaign={handleDeleteCampaign}
            />
          ),
          // <Button
          //   key="senderName"
          //   className={classNames(
          //     classes.additionalActionButton,
          //     classes.manageContactsButton
          //   )}
          //   variant="outlined"
          //   startIcon={<ContactPhoneIcon color="primary" />}
          //   onClick={() => setSenderModalOpen(true)}
          // >
          //   {t('campaigns.sender')}
          // </Button>,
        ]}
        itemsOnPage={vendorCampaigns?.campaigns?.length}
      >
        {!!vendorCampaigns?.campaigns?.length &&
          vendorCampaigns.campaigns.map((rowData) => (
            <CampaignTableRow
              key={rowData._id}
              rowData={rowData}
              handleDeleteCampaign={handleDeleteCampaign}
              handleChangeCampaignStatus={handleChangeCampaignStatus}
            />
          ))}
      </TableLayout>
      <CampaignsSenderModal
        isOpen={isSenderModalOpen}
        handleClose={() => setSenderModalOpen(false)}
      />
    </>
  );
}
