import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const StyledInput = withStyles(({ palette, transitions }) => ({
  root: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: palette.background.paper,
    border: '1px solid #C7C9CC',
    fontSize: 14,
    height: 42,
    boxSizing: 'border-box',
    // width: 'auto',
    padding: '10px 4px 10px 12px',
    transition: transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 8,
      borderColor: '#ff5100',
      boxShadow: '0 0 0 0.2rem rgba(255,81,0,.25)',
    },
  },
  input: {

  }
}))(InputBase);

export default StyledInput;
