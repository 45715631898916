/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_SINGLE_VENDOR_CAMPAIGN,
  GET_CAMPAIGN_REPORT,
} from 'graphql/query/campaigns';

import { makeStyles } from '@material-ui/styles';

import TableLayout from 'components/shared/TableLayout';
import CampaignReportTableRow from 'components/Campaigns/CampaignReportTableRow';
import CampaignReportFilters from 'components/Campaigns/CampaignReportFilters';

import { ROWS_PER_PAGE_OPTIONS, TABLE_SKELETON_TYPES } from 'helpers/constants';
import { REPORT_INIT_STATE } from 'components/Campaigns/campaignConstants';
import CampaignReportStatistic from 'components/Campaigns/CampaignReportStatistic';

const useStyles = makeStyles(({ spacing }) => ({
  tableLeftPadding: {
    paddingLeft: spacing(3.5),
  },
  columnTitle: {
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  },
  smallColumn: {
    width: 80,
  },
  messageColumn: {
    textAlign: 'center',
    width: 300,
    whiteSpace: 'break-spaces',
  },
}));

export default function CampaignReport() {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;
  const { campaignId } = params;

  const [state, setState] = React.useState(REPORT_INIT_STATE);

  const { data: campaignData } = useQuery(GET_SINGLE_VENDOR_CAMPAIGN, {
    variables: { campaignId },
    fetchPolicy: 'cache-only',
  });
  const [
    getCampaignReport,
    {
      data: campaignReportData,
      loading: campaignReportLoading,
      error: campaignReportError,
    },
  ] = useLazyQuery(GET_CAMPAIGN_REPORT, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (campaignReportResponse) => {
      const totalPages =
        campaignReportResponse?.getCampaignRecipientLog?.totalPages ?? 0;
      const pageNumberExceeded = !!totalPages && totalPages <= state.after;
      const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
        state.pageSize
      );

      if (
        campaignReportResponse &&
        (pageNumberExceeded || rowsPerPageExceeded)
      ) {
        history.push({
          pathname: currentPath,
          search: queryString.stringify({
            ...queryString.parse(location.search),
            after: state.after !== 0 && pageNumberExceeded ? 0 : state.after,
            pageSize: rowsPerPageExceeded ? 10 : state.pageSize,
          }),
        });
        window.location.reload();
      }
    },
  });

  const campaignTitle = campaignData && campaignData.campaign?.title;
  const campaignReport = campaignReportData?.getCampaignRecipientLog;

  // APPLY FILTERS -------------------

  const applyFilters = async () => {
    try {
      await getCampaignReport({
        variables: { ...state, after: state.after + 1, campaignId },
      });
    } catch (error) {
      console.error('CAMPAIGN_REPORT_FILTER_ERROR', error.message);
    }
  };

  // USE EFFECTS ---------------------

  // NOTE: USE THIS USE EFFECT WHEN WE WILL CONNECT URL PARAMS MANAGING
  // React.useEffect(() => {
  //   const pageParam = +queries.after;
  //   const rowsParam = +queries.pageSize;
  //   const sortByParam = queries.sortBy;
  //   const sortOrderParam = +queries.sortOrder;

  //       console.log('INIT___USE___EFFECT__');

  //   if (Object.keys(queries).length) {
  //     setState({
  //       ...state,
  //       after: pageParam || state.after,
  //       pageSize: rowsParam || state.pageSize,
  //       sortBy: sortByParam || state.sortBy,
  //       sortOrder: sortOrderParam || state.sortOrder,
  //     });
  //   }
  // }, []);

  React.useEffect(() => {
    applyFilters();
  }, [state]);

  // HANDLERS --------------------------

  const loadInitReport = () => {
    getCampaignReport({
      variables: {
        campaignId,
        ...state,
        after: +queries.after + 1 || 1,
        pageSize: +queries.pageSize || 10,
        sortBy: queries.sortBy || state.sortBy,
        sortOrder: +queries.sortOrder || -1,
      },
    });
  };

  const handleChangePage = async (event, page) => {
    if (
      campaignReport &&
      (campaignReport.hasNextPage || page + 1 < campaignReport.totalPages)
    ) {
      try {
        getCampaignReport({
          variables: {
            campaignId,
            ...state,
            after: page + 1,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, after: page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        after: page,
      }),
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      getCampaignReport({
        variables: {
          campaignId,
          ...state,
          pageSize: rowsPerPage,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, pageSize: rowsPerPage, after: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        pageSize: rowsPerPage,
        after: 0,
      }),
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      getCampaignReport({
        variables: {
          campaignId,
          ...state,
          after: state.after + 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      after: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        pageSize: state.pageSize,
        after: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  // CAMPAIGN SEARCH PHRASE SEARCH

  const handleSearchBar = async (searchPhrase) => {
    setState((prevState) => ({ ...prevState, searchPhrase, after: 0 }));
  };

  // HANDLE REPORT FILTERS

  const handleFilters = (fieldValue, field) => {
    setState((prevState) => ({
      ...prevState,
      [field]: fieldValue,
    }));
  };

  const clearFilters = () => {
    loadInitReport();
    setState(REPORT_INIT_STATE);
    // TODO: add url params support
    // history.push({
    //   pathname: currentPath,
    // });
  };

  // TODO: when connection fields will be available in logs
  // need to add vendorClientId field in the table and set it as a sortable option
  const tableColumns = [
    // {
    //   title: t('customers.customer number'),
    //   field: 'vendorClientId',
    //   sortable: true,
    //   columnStyles: classNames(classes.columnTitle, classes.tableLeftPadding),
    // },
    {
      title: t('campaigns.report.receiver'),
      field: 'to',
      sortable: false,
      columnStyles: classNames(classes.columnTitle, classes.tableLeftPadding),
    },
    {
      title: t('campaigns.send date'),
      field: 'sendAt',
      sortable: true,
    },
    {
      title: t('campaigns.message'),
      field: 'sms.text',
      sortable: false,
      columnStyles: classes.messageColumn,
    },
    {
      title: t('campaigns.status'),
      field: 'status',
      sortable: true,
    },
  ];

  const {
    after,
    pageSize,
    sortBy,
    sortOrder,
    searchPhrase,
    endDate,
    startDate,
    status,
  } = state;

  const hasError =
    !!campaignReportError || (!campaignReportLoading && !campaignReport);
  const tableIsEmpty = !campaignReport?.logs?.length;

  return (
    <TableLayout
      title={t('campaigns.report title', { title: campaignTitle })}
      columns={tableColumns}
      skeletonType={TABLE_SKELETON_TYPES.DEALS}
      emptyTableData={hasError || tableIsEmpty}
      emptyTableDataMessage={t('campaigns.manage recipients empty table')}
      tableIsLoading={campaignReportLoading}
      page={after}
      rowsPerPage={pageSize}
      totalResults={campaignReport?.totalResults ?? 0}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      backButtonAction={history.goBack}
      sortBy={sortBy}
      sortOrder={sortOrder}
      handleSortRows={handleSortRows}
      itemsOnPage={campaignReport?.logs?.length}
      headerActions={
        <CampaignReportStatistic
          campaignId={campaignId}
          searchPhrase={searchPhrase}
          handleSearchBar={handleSearchBar}
        />
      }
      headerAdditionalActions={
        <CampaignReportFilters
          startDate={startDate}
          endDate={endDate}
          status={status}
          handleFilters={handleFilters}
          clearFilters={clearFilters}
        />
      }
    >
      {!!campaignReport?.logs?.length &&
        campaignReport.logs.map((rowData, rowIndex) => (
          <CampaignReportTableRow
            key={`${rowData.to} + ${rowData.sentAt}`}
            rowData={rowData}
            isEvenTableRow={rowIndex % 2 === 0}
          />
        ))}
    </TableLayout>
  );
}
