import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TableCell, TableRow } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';

import DeleteModal from 'components/shared/DeleteModal';
import RowCells from './RowCells';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 56,
    borderBottom: theme.palette.border.lightGrey,
  },
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    maxWidth: 150,
    color: theme.palette.secondary.textBlack,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1),
    },
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.button,
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.darkGray,
  },
  highlightRow: {
    animation: `$highlighting 1800ms ${theme.transitions.easing.easeOut}`,
  },
  '@keyframes highlighting': {
    '0%': {
      backgroundColor: theme.palette.primary.main,
    },
    '100%': {
      backgroundColor: theme.palette.background.default,
    },
  },
  tableRowOdd: {
    backgroundColor: theme.palette.background.mutedLight,
  },
}));

function PdfCatalogTableRow({
  rowData,
  selectedList,
  executeScroll,
  tableItemRef,
  handleDelete,
  rowsPerPage,
  isOddRow,
  handleChangeCatalogPricingMode,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const openDetailsPage = async id => {
    history.replace({
      pathname: '/pdfCatalogs',
      search: queryString.stringify({
        ...queryString.parse(location.search),
        id,
      }),
    });
    if (rowsPerPage && rowsPerPage !== 0) {
      history.push(`pdfCatalog/${id}?rowsPerPage=${rowsPerPage}`);
    } else {
      history.push(`pdfCatalog/${id}`);
    }
  };

  useEffect(() => {
    if (selectedList === rowData._id) {
      executeScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRef = selectedList === rowData._id ? tableItemRef : null;

  return (
    <TableRow
      className={classNames(
        classes.tableBodyRow,
        isOddRow && classes.tableRowOdd,
        setRef && classes.highlightRow
      )}
      ref={setRef}
    >
      <RowCells
        rowData={rowData}
        handleChangeCatalogPricingMode={handleChangeCatalogPricingMode}
      />
      <TableCell className={classes.tableCell}>
        <Grid container wrap="nowrap">
          <Button
            variant="contained"
            classes={{ root: classes.tableActionButton }}
            onClick={() => openDetailsPage(rowData._id)}
          >
            <EditIcon className={classes.tableActionButtonIcon} />
          </Button>
          <Button
            variant="contained"
            classes={{ root: classes.tableActionButton }}
            onClick={() => setOpenDeleteModal(true)}
          >
            <DeleteIcon className={classes.tableActionButtonIcon} />
          </Button>
        </Grid>
      </TableCell>
      <DeleteModal
        isOpen={openDeleteModal}
        message={t('pdf.delete pdf')}
        handleCloseModal={() => setOpenDeleteModal(false)}
        handleDelete={() => {
          setOpenDeleteModal(false);
          handleDelete(rowData._id);
        }}
      />
    </TableRow>
  );
}

PdfCatalogTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  selectedList: PropTypes.string,
  tableItemRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  executeScroll: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number,
  handleChangeCatalogPricingMode: PropTypes.func.isRequired,
  isOddRow: PropTypes.bool.isRequired,
};

PdfCatalogTableRow.defaultProps = {
  selectedList: null,
  rowsPerPage: null,
};

export default memo(PdfCatalogTableRow, (prevProps, nextProps) =>
  isEqual(prevProps.rowData, nextProps.rowData)
);
