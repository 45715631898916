import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

import DropDowIcon from '@material-ui/icons/ArrowDropDown';
import GroupBadge from 'components/shared/GroupBadge';
import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(theme => ({
  selectorContainer: {
    width: 200,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`
  },
  selectorTitle: {
    color: theme.palette.secondary.darkGray,
    fontStyle: 'italic'
  },
  selectorText: {
    padding: `${theme.spacing(1.25)}px 0px`
  },
  popoverPaper: {
    boxShadow: theme.palette.customShadows.popover
  },
  customerAssignTitle: {
    padding: `${theme.spacing(1)}px 0px`
  }
}));

export default function ListAssignment(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenSelector = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const selectorIsOpen = Boolean(anchorEl);

  const { assignedToAll, assignedToCustomers, assignedToGroups } = props;

  const hasGroups = !assignedToAll && assignedToGroups && !!assignedToGroups.length;
  const hasCustomers = !assignedToAll && assignedToCustomers;

  return (
    <Grid container alignItems="center">
      {assignedToAll && <Typography variant="body2">{t('lists.all restaurants')}</Typography>}
      {hasGroups && (
        <GroupBadge groups={assignedToGroups} />
      )}
      {hasCustomers && (
        assignedToCustomers.length === 1 ? (
          <Grid container className={classes.customerAssignTitle}>
            <Typography variant="body2">
              {assignedToCustomers[0] || ''}
            </Typography>
          </Grid>
        ) : (
          <Grid container alignItems="center">
            <Typography variant="body2">
              {`${assignedToCustomers.length} ${t('lists.customers')}`}
            </Typography>
            <Grid item>
              <IconButton size="small" onClick={handleOpenSelector}>
                <DropDowIcon />
              </IconButton>
            </Grid>
            <Popover
              classes={{ paper: classes.popoverPaper }}
              open={selectorIsOpen}
              anchorEl={anchorEl}
              onClose={handleCloseSelector}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Grid
                container
                direction="column"
                wrap="nowrap"
                className={classes.selectorContainer}
              >
                <Typography
                  className={classNames(classes.selectorTitle, classes.selectorText)}
                >
                  {t('customers.lists')}
                </Typography>
                {assignedToCustomers.map(customer => (
                  <Typography
                    key={uuid()}
                    variant="body2"
                    className={classes.selectorText}
                  >
                    {customer}
                  </Typography>
                ))}
              </Grid>
            </Popover>
          </Grid>
        )
      )}
      {(!assignedToAll && !hasCustomers && !hasGroups) && <EmptyTableField /> }
    </Grid>
  );
}

ListAssignment.propTypes = {
  assignedToAll: PropTypes.bool,
  assignedToCustomers: PropTypes.array,
  assignedToGroups: PropTypes.array,
};

ListAssignment.defaultProps = {
  assignedToAll: false,
  assignedToCustomers: [],
  assignedToGroups: []
};
