import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from 'react-router';

import { ApolloProvider, InMemoryCache } from '@apollo/client';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as moment from 'moment';
import 'moment/locale/de';
import { useTranslation } from 'react-i18next';

import LinearProgress from '@material-ui/core/LinearProgress';

import apolloClientConfig from 'AppApolloClient';
import AppRouter from 'AppRouter';
import Logout from 'containers/Logout';
import { paginatedMerge, paginatedRead } from 'helpers/memoryCachePagination';
import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';

const basename = `${
  document.location.pathname.split('index.html')[0]
}index.html`;

const correctHistory =
  process.env.REACT_APP_IS_ELECTRON === 'true'
    ? createBrowserHistory({ basename })
    : createBrowserHistory();

export default function App() {
  const [client, setClient] = React.useState(undefined);
  const { i18n } = useTranslation();

  moment.locale(i18n.language);

  React.useEffect(() => {
    const apolloCache = new InMemoryCache({
      typePolicies: {
        EmailEntry: {
          keyFields: false,
        },
        VendorList: {
          fields: {
            products: {
              merge: true,
            },
          },
        },
        Customer: {
          fields: {
            contactData: {
              merge: true,
            },
          },
        },
        VendorAccount: {
          fields: {
            contactData: {
              merge: true,
            },
            apiIntegration: {
              merge: true,
            },
            ftpIntegration: {
              merge: true,
            },
            preferences: {
              merge: true,
            },
          },
        },
        VendorAccountFull: {
          fields: {
            contactData: {
              merge: true,
            },
            apiIntegration: {
              merge: true,
            },
            ftpIntegration: {
              merge: true,
            },
            preferences: {
              merge: true,
            },
            orderProcessingOptions: {
              merge: true,
            },
            featureFlags: {
              merge: true,
            },
          },
        },
        Variant: {
          keyFields: false,
        },
        Campaign: {
          fields: {
            assignedToCustomers: {
              merge: false,
            },
            assignedToGroups: {
              merge: false,
            },
          },
        },
        RecipientsConnection: {
          keyFields: false,
          fields: {
            recipients: {
              merge: false,
            },
          },
        },
        ProductType: {
          keyFields: ['_id', 'vendorId'],
        },
        Newsletter: {
          fields: {
            assignedGroups: {
              merge: false,
            },
            excludedGroups: {
              merge: false,
            },
          },
        },
        VendorProductAttribute: {
          merge: false,
        },
        CustomerGroupTranslation: {
          keyFields: false,
        },
        Query: {
          queryType: true,
          fields: {
            vendorCustomers: {
              keyArgs: [['_id']],
              merge: true,
            },
            user: {
              merge: true,
            },
            vendorAccount: {
              merge: true,
            },
            vendorCategories: {
              merge: false,
            },
            getAllProductTypes: {
              keyArgs: () => activeAccountIdVar() ?? false,
              read(existing, { args: { pageSize } }) {
                const paginatedResult = paginatedRead({
                  existing,
                  pageSize,
                  fieldName: 'results',
                });

                return {
                  ...paginatedResult,
                  results: paginatedResult?.results.slice(
                    0,
                    existing?.totalResults
                  ),
                };
              },
              merge(existing, incoming, { args: { pageSize, after }, cache }) {
                if (after === 1) {
                  const currentVendorId = activeAccountIdVar();
                  const productTypeKeys = Object.keys(cache.extract()).filter(
                    key =>
                      key.includes('ProductType:') &&
                      !key.includes(currentVendorId)
                  );

                  cache.modify({
                    id: 'ROOT_QUERY',
                    fields: {
                      getAllProductTypes(existingDataRef, { toReference }) {
                        cache.evict({ id: toReference(existingDataRef) });
                        return undefined;
                      },
                    },
                  });

                  productTypeKeys.forEach(key => {
                    cache.evict({ id: key });
                  });
                }

                return paginatedMerge({
                  existing,
                  incoming,
                  pageSize,
                  after,
                  fieldName: 'results',
                });
              },
            },
          },
        },
      },
    });

    apolloClientConfig(apolloCache, setClient);
  }, []);

  if (client === undefined) return <LinearProgress color="primary" />;

  return (
    <React.Suspense fallback={<LinearProgress color="primary" />}>
      <ApolloProvider client={client}>
        <Router history={correctHistory}>
          <Switch>
            <Route path="/logout" exact component={Logout} />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <AppRouter />
            </MuiPickersUtilsProvider>
          </Switch>
        </Router>
      </ApolloProvider>
    </React.Suspense>
  );
}
