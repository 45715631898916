import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ConfirmIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// FIXME: when API be done import MessageIcon from '@material-ui/icons/MailOutlined';

import OrdersTableRowCells from 'components/OrdersTable/OrdersTableRowCells';
import DetailPanel from './DetailPanel';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 56,
    borderBottom: theme.palette.border.lightGrey
  },
  tableCellDetailPanel: {
    padding: 0,
  },
  tableCell: {
    padding: `0px ${theme.spacing(1.5)}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  expandMoreCell: {
    width: 30,
    borderTop: 'none',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  tableActionButton: {
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    margin: `0px ${theme.spacing(0.5)}px`,
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  confirmButton: {
    color: theme.palette.success.main
  },
  cancelRow: {
    background: theme.palette.type === 'dark' ?
      theme.palette.action.disabledBackground
      : theme.palette.background.default
  },
  actionsCell: {
    maxWidth: 170
  }
}));

const MONDU = 'mondu';
const STATUS_DRAFT = 'draft';

const OrdersTableRow = React.memo((props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isPanelOpen, setPanelOpen] = React.useState(false);

  const {
    rowData,
    acceptOrder,
    cancelOrder,
    isCanceled,
    isPending,
    // FIXME: when API be done messageOrder
  } = props;
  const isPendingOrderMondu = rowData?.paymentMethod?.name === MONDU && rowData?.status !== STATUS_DRAFT;
  return (
    <>
      <TableRow className={classNames(
        classes.tableBodyRow,
        isCanceled && classes.cancelRow)}
      >
        <TableCell className={isPendingOrderMondu && classNames(classes.tableCell, classes.expandMoreCell)}>
          { isPendingOrderMondu &&
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setPanelOpen(!isPanelOpen)}
          >
            {isPanelOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>}
        </TableCell>
        <OrdersTableRowCells rowData={rowData} />
        <TableCell className={classNames(classes.tableCell, classes.actionsCell)}>
          <>
            {isPending &&
            <Grid container wrap="nowrap" alignItems="center">
              <IconButton
                className={classNames(
                  classes.tableActionButton,
                  classes.confirmButton
                )}
                onClick={() => acceptOrder(rowData)}
                title={t('orders.confirm')}
                size="small"
              >
                <ConfirmIcon/>
              </IconButton>
              <IconButton
                className={classNames(
                  classes.tableActionButton,
                  classes.cancelButton
                )}
                onClick={() => cancelOrder(rowData)}
                title={t('orders.order title')}
                size="small"
              >
                <CancelIcon/>
              </IconButton>
            </Grid>}
            {/* FIXME: when API be done {isProblem &&
          <Grid container wrap="nowrap">
            <Button
              variant="contained"
              classes={{ root: classes.tableActionSuccessButton }}
              onClick={() => messageOrder(rowData)}
              startIcon={<MessageIcon/>}
            >
              {t('orders.message')}
            </Button>
          </Grid>} */}
          </>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.tableCellDetailPanel} colSpan={13}>
          <Collapse in={isPanelOpen} timeout="auto" unmountOnExit>
            <DetailPanel
              isEditModeActive
              orderId={rowData._id}
              order={rowData}
              historyData={rowData?.paymentMethod?.documents ?? []}
              bic={rowData?.paymentMethod?.paymentInfo?.bic}
              receiver={rowData?.paymentMethod?.paymentInfo?.receiver}
              key={`order-detail-${rowData._id}`}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
},
(prevProps, nextProps) => isEqual(prevProps.rowData, nextProps.rowData));

OrdersTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  acceptOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  isCanceled: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  // FIXME: when API be done messageOrder: PropTypes.func.isRequired
};
export default OrdersTableRow;
