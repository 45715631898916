/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { GET_VENDOR_ACCOUNT } from 'graphql/query/vendors';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { UPDATE_VENDOR } from 'graphql/mutation/vendor';

import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import MainInformation from 'components/Settings/MainInformation';
import TeamContacts from 'components/Settings/TeamContacts';
// import DeliveryDaysSettings from 'components/Settings/DeliveryDaysSettings';
import Branding from 'components/Settings/Branding';
import NewsletterInformation from 'components/Settings/NewsletterInformation';

const useStyles = makeStyles({
  settingContainer: {
    width: 1080,
  },
});

export default function Settings({ isAdmin }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [updateVendorMutation] = useMutation(UPDATE_VENDOR);

  const [getVendorAccount, { data, loading }] = useLazyQuery(
    GET_VENDOR_ACCOUNT,
    { fetchPolicy: 'cache-and-network' }
  );

  const vendorData = data && data.vendorAccount;

  React.useEffect(() => {
    getVendorAccount();
  }, []);

  const handleUpdateVendor = async (values) => {
    try {
      await updateVendorMutation({
        variables: {
          vendorData: {
            ...values
          }
        },
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('settings.successfully updated'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: t(`errors.${error.message}`) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const renderEmptyDataNotice = () => (loading ? (
    <CircularProgress color="primary" />
  ) : (<Typography variant="h4">{t('settings.no data available')}</Typography>));

  return vendorData ? (
    <Grid container wrap="nowrap" className={classes.settingContainer}>
      <Grid item xs={6} container direction="column" wrap="nowrap">
        <MainInformation
          contactData={vendorData.contactData || {}}
          handleUpdateVendor={handleUpdateVendor}
        />
        <NewsletterInformation
          contactData={vendorData.contactData || {}}
          newsletterData={vendorData.preferences?.newsletter || {}}
          handleUpdateVendor={handleUpdateVendor}
          isAdmin={isAdmin}
        />
      </Grid>
      <Grid item xs={6} container direction="column" wrap="nowrap">
        <TeamContacts
          vendorContacts={vendorData.contacts || []}
          handleUpdateVendor={handleUpdateVendor}
        />
        {/*
            FIXME: temporarily hide delivery days
            <DeliveryDaysSettings
              deliveryDays={vendorData.deliveryDays || []}
              handleUpdateVendor={handleUpdateVendor}
            />
            */}
        {isAdmin && (
          <Branding
            brandingData={vendorData.branding || {}}
            handleUpdateVendor={handleUpdateVendor}
            image={vendorData?.contactData?.image}
            defaultProductImage={vendorData?.preferences?.defaultProductImage}
          />
        )}
      </Grid>
    </Grid>
  )
    : renderEmptyDataNotice();
}

Settings.propTypes = {
  isAdmin: PropTypes.bool,
};

Settings.defaultProps = {
  isAdmin: false
};
