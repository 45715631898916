import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

import DropDowIcon from '@material-ui/icons/ArrowDropDown';
import MailIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles((theme) => ({
  statusDot: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
    background: 'transparent',
  },
  selectorContainer: {
    width: 248,
    padding: theme.spacing(2.5),
  },
  selectorTitle: {
    color: theme.palette.secondary.darkGray,
    fontStyle: 'italic',
  },
  selectorText: {
    paddingBottom: theme.spacing(2),
  },
  actionButton: {
    boxShadow: 'none',
    height: 45,
    color: theme.palette.text.inverted,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  popoverPaper: {
    boxShadow: theme.palette.customShadows.popover,
  },

  invited: {
    background: '#D7804F',
  },
  draft: {
    background: theme.palette.secondary.darkGray,
  },
  accepted: {
    background: '#E3BA26',
  },
  ordered: {
    background: theme.palette.success.main,
  },
  rejected: {
    background: theme.palette.error.main,
  },
}));

export default function CustomerStatus({
  customerStatus,
  orders,
  handleSendInvite,
  withDropDown,
  isContact,
  withList,
  withDeliveryDays,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenSelector = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const selectorIsOpen = Boolean(anchorEl);

  const handleInviteAction = async (notify) => {
    await handleSendInvite(notify);
    handleCloseSelector();
  };

  const CUSTOMER_STATUS = {
    draft: {
      message: t('customers.status message.draft'),
      action: () => handleInviteAction(true),
      additionalAction: () => handleInviteAction(false),
      actionMessage: t('customers.status action button.draft'),
    },
    invited: {
      message: t('customers.status message.invited'),
      action: () => handleInviteAction(true),
      actionMessage: t('customers.status action button.invited'),
    },
    accepted: {
      message: t('customers.status message.accepted'),
    },
    ordered: {
      message: t('customers.status message.ordered'),
    },
  };

  const status = !isContact && orders.length > 0 ? 'ordered' : customerStatus;

  if (!status) {
    return (
      <Typography variant="body2" color="error">
        {t('customers.status is unavailable')}
      </Typography>
    );
  }

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid className={classNames(classes.statusDot, classes[status])} />
      {t(`customers.status type.${status}`)}&nbsp;
      {orders.length > 0 && `(${orders.length}x)`}
      {withDropDown && CUSTOMER_STATUS[status] && (
        <>
          <Grid item>
            <IconButton size="small" onClick={handleOpenSelector}>
              <DropDowIcon />
            </IconButton>
          </Grid>
          <Popover
            classes={{ paper: classes.popoverPaper }}
            open={selectorIsOpen}
            anchorEl={anchorEl}
            onClose={handleCloseSelector}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              className={classes.selectorContainer}
            >
              <Typography
                className={classNames(
                  classes.selectorTitle,
                  classes.selectorText
                )}
              >
                {t('customers.status')}
              </Typography>
              <Typography className={classes.selectorText}>
                {CUSTOMER_STATUS[status].message}
              </Typography>
              {CUSTOMER_STATUS[status].action && (
                <Button
                  onClick={CUSTOMER_STATUS[status].action}
                  className={classes.actionButton}
                  color="secondary"
                  variant="contained"
                  disabled={!withList || withDeliveryDays}
                  fullWidth
                  startIcon={<MailIcon />}
                >
                  {CUSTOMER_STATUS[status].actionMessage}
                </Button>
              )}
              {(!withList || withDeliveryDays) && (
                <Typography className={classes.selectorText}>
                  {t('customers.be sure that you have selected days and lists')}
                </Typography>
              )}
              {/* {CUSTOMER_STATUS[status].additionalAction && status === 'draft' && (
              <Tooltip
                title={t('customers.status message.create invite')}
                placement="left-start"
              >
                <Button
                  onClick={CUSTOMER_STATUS[status].additionalAction}
                  className={classes.actionButton}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  startIcon={<CreateInvitationIcon />}
                >
                  {t('customers.status action button.create invite')}
                </Button>
              </Tooltip>
              )} */}
            </Grid>
          </Popover>
        </>
      )}
    </Grid>
  );
}

CustomerStatus.propTypes = {
  customerStatus: PropTypes.string,
  handleSendInvite: PropTypes.func.isRequired,
  orders: PropTypes.array,
  withDropDown: PropTypes.bool,
  isContact: PropTypes.bool,
  withList: PropTypes.bool,
  withDeliveryDays: PropTypes.bool,
};

CustomerStatus.defaultProps = {
  customerStatus: null,
  orders: [],
  withDropDown: false,
  isContact: false,
  withList: false,
  withDeliveryDays: false,
};
