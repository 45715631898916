import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import UploadIcon from '@material-ui/icons/PublishOutlined';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';

const useStyles = makeStyles(theme => ({
  modalText: {
    width: 355,
  },
  optionalMessage: {
    fontWeight: theme.typography.fontWeightLight,
    fontStyle: 'Italic',
    marginLeft: theme.spacing(0.5),
  },
  downloadButton: {
    margin: `${theme.spacing(3)}px 0px ${theme.spacing(4.5)}px`,
  },
  uploadButton: {
    margin: `${theme.spacing(3)}px 0px ${theme.spacing(1.5)}px`,
  },
  modalButtons: {
    height: 74,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    border: theme.palette.border.darkGrey,
    backgroundColor: '#FAFAFA',
  },
  actionButton: {
    height: 50,
    boxShadow: 'none',
  },
  noticeContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
    border: theme.palette.border.darkGrey,
    borderRadius: 4,
    color: theme.palette.secondary.darkGray,
    marginBottom: theme.spacing(14),
  },
}));

export default function ModalCsvPhotosUpload(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalStep, setModalStep] = React.useState(0);

  const [setNotification] = useMutation(SET_NOTIFICATION);

  const { isOpen, handleClose } = props;

  const handleUploadPhotos = () => {
    // TODO add mutation here
    handleClose();
    setNotification({
      variables: {
        timeout: 4000,
        message: t('products.csv photo modal.success message'),
        type: NOTIFICATION_STATUS.SUCCESS,
        isOpen: true,
      },
    });
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      modalTitle={t('products.csv photo modal.title')}
      withBackButton={modalStep > 0}
      handleMoveBack={() => setModalStep(prevStep => prevStep - 1)}
    >
      {modalStep === 0 && (
        <>
          <Typography className={classes.modalText}>
            {t('products.template message')}
            <span className={classes.optionalMessage}>(Optional)</span>
          </Typography>
          <Button
            className={classNames(classes.downloadButton, classes.modalButtons)}
            fullWidth
            variant="contained"
            color="default"
            startIcon={<DownloadIcon color="primary" />}
          >
            {t('common.download template')}
          </Button>
          <Typography className={classes.modalText}>
            {t('products.backup message')}
            <span className={classes.optionalMessage}>(Optional)</span>
          </Typography>
          <Button
            className={classNames(classes.downloadButton, classes.modalButtons)}
            fullWidth
            variant="contained"
            color="default"
            startIcon={<DownloadIcon color="primary" />}
          >
            {t('common.download csv')}
          </Button>
          <Button
            className={classes.actionButton}
            fullWidth
            onClick={() => setModalStep(1)}
            variant="contained"
            color="primary"
            endIcon={<ForwardIcon />}
          >
            {t('products.next step')}
          </Button>
        </>
      )}
      {modalStep === 1 && (
        <>
          <Typography className={classes.modalText}>
            {t('products.upload message')}
          </Typography>
          <Button
            className={classNames(classes.uploadButton, classes.modalButtons)}
            fullWidth
            variant="contained"
            color="default"
            startIcon={<UploadIcon color="primary" />}
          >
            {t('common.upload csv')}
          </Button>
          <Grid
            className={classes.noticeContainer}
            container
            wrap="nowrap"
            justifyContent="center"
          >
            <InfoIcon color="inherit" style={{ marginRight: 4 }} />
            <Typography color="inherit">
              {t('products.csv notification')}
            </Typography>
          </Grid>
          <Button
            className={classes.actionButton}
            fullWidth
            onClick={handleUploadPhotos}
            variant="contained"
            color="primary"
          >
            {t('common.confirm')}
          </Button>
        </>
      )}
    </ModalLayout>
  );
}

ModalCsvPhotosUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
