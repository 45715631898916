import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  selectorContainer: {
    maxWidth: 300,
    maxHeight: 475,
    overflowY: 'auto',
    padding: spacing(1, 0)
  },
  popoverPaper: {
    borderRadius: 16,
    background: palette.background.default,
  }
}));

export default function CustomPopover({
  selectorIsOpen,
  anchorEl,
  handleCloseSelector,
  children,
  popoverContainerStyling,
}) {
  const classes = useStyles();

  return (
    <Popover
      classes={{
        paper: classes.popoverPaper,
      }}
      open={selectorIsOpen}
      anchorEl={anchorEl}
      onClose={handleCloseSelector}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classNames(
          classes.selectorContainer,
          typeof (popoverContainerStyling) === 'string' && popoverContainerStyling
        )}
        style={typeof (popoverContainerStyling) === 'object' ?
          popoverContainerStyling
          : {}}
      >
        {children}
      </Grid>
    </Popover>
  );
}

CustomPopover.propTypes = {
  selectorIsOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  handleCloseSelector: PropTypes.func.isRequired,
  popoverContainerStyling: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CustomPopover.defaultProps = {
  anchorEl: null,
  popoverContainerStyling: null
};
