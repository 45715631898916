import i18next from 'i18next';
import moment from 'moment';

import { DELIVERY_DAYS } from 'helpers/constants';
import { CUSTOM_CAMPAIGN_INTERVAL } from './campaignConstants';

export default function getCampaignPeriodTitle(
  schedule,
  label,
  withTimeAndDate
) {
  const campaignTime =
    withTimeAndDate && schedule
      ? moment(schedule.startDate)
        .set({
          hour: schedule.time?.hour || 0,
          minute: schedule.time?.minute || 0,
        })
        .format('HH:mm')
      : '';
  const dayOfTheWeek = moment(schedule.startDate).isoWeekday() - 1;

  if (!schedule && label) {
    return i18next.t(`campaigns.period.${label}`);
  } else if (!!schedule?.repeatInterval || schedule?.dayIndexes?.length > 0) {
    // ------------------------------------------------------------
    // NOTE: CHECKING FOR CUSTOM PERIOD INTERVALS
    const count = schedule.repeatInterval;
    let customPeriodTitle = '';

    if (schedule?.endAfterCount === 1) {
      customPeriodTitle = i18next.t('campaigns.extra titles.once');
      return withTimeAndDate
        ? customPeriodTitle.concat(
            ` ${i18next.t('campaigns.extra titles.at')} ${campaignTime}`
        )
        : customPeriodTitle;
    }

    const startPeriod =
      count > 1
        ? i18next
          .t('campaigns.extra titles.every')
          .concat(
              ` ${count} ${i18next.t(
                `campaigns.custom period.${schedule.repeat}`,
                { count }
              )}`
          )
        : i18next.t(`campaigns.custom period.${schedule.repeat}`, { count });

    let endPeriod = '';
    if (schedule.endDate || schedule.endAfterCount) {
      endPeriod = schedule.endDate
        ? `, ${i18next.t('campaigns.extra titles.until')} ${moment(
          schedule.endDate
        ).format('MMM D, YYYY')}`
        : `, ${schedule.endAfterCount} ${i18next.t(
          'campaigns.extra titles.times'
        )}`;
    }

    if (schedule?.repeat === CUSTOM_CAMPAIGN_INTERVAL.DAILY) {
      // NOTE: CUSTOM PERIOD WITH DAILY INTERVAL
      customPeriodTitle = startPeriod.concat(endPeriod);
    } else if (schedule?.repeat === CUSTOM_CAMPAIGN_INTERVAL.MONTHLY) {
      // NOTE: CUSTOM PERIOD WITH MONTHLY INTERVAL
      customPeriodTitle = startPeriod
        .concat(` ${i18next.t('campaigns.extra titles.on')} `)
        .concat(
          `${i18next.t('campaigns.extra titles.day')} ${moment(
            schedule.startDate
          ).get('date')}`
        )
        .concat(endPeriod);
    } else if (schedule?.repeat === CUSTOM_CAMPAIGN_INTERVAL.WEEKLY) {
      // NOTE: CUSTOM PERIOD WITH WEEKLY INTERVAL
      const daysOfWeek = schedule.dayIndexes
        .map((dayIndex) =>
          i18next.t(`daysOfTheWeek.${DELIVERY_DAYS[dayIndex]}`)
        )
        .join(', ');

      if (count === 1) {
        customPeriodTitle = startPeriod
          .concat(` ${i18next.t('campaigns.extra titles.on')} `)
          .concat(`${daysOfWeek}`)
          .concat(endPeriod);
      } else {
        customPeriodTitle = startPeriod
          .concat(` ${i18next.t('campaigns.extra titles.on')} `)
          .concat(`${daysOfWeek}`)
          .concat(endPeriod);
      }
    } else {
      customPeriodTitle = i18next.t(`campaigns.period.${schedule.repeat}`);
    }

    return withTimeAndDate
      ? customPeriodTitle.concat(
          ` ${i18next.t('campaigns.extra titles.at')} ${campaignTime}`
      )
      : customPeriodTitle;
  } else {
    // ------------------------------------------------------------
    // NOTE: REGULAR PERIOD
    let regularPeriodTitle = '';

    if (schedule.repeat === CUSTOM_CAMPAIGN_INTERVAL.ONCE) {
      regularPeriodTitle = i18next
        .t('campaigns.extra titles.once')
        .concat(` ${i18next.t('campaigns.extra titles.on')} `)
        .concat(
          `${i18next.t('campaigns.extra titles.day')} ${moment(
            schedule.startDate
          ).get('date')}`
        );
    } else if (schedule.repeat === CUSTOM_CAMPAIGN_INTERVAL.DAILY) {
      regularPeriodTitle = i18next.t(`campaigns.period.${schedule.repeat}`);
    } else if (schedule.repeat === CUSTOM_CAMPAIGN_INTERVAL.WEEKLY) {
      regularPeriodTitle = withTimeAndDate
        ? i18next
          .t(`campaigns.period.${schedule.repeat}`)
          .concat(` ${i18next.t('campaigns.extra titles.on')} `)
          .concat(i18next.t(`daysOfTheWeek.${DELIVERY_DAYS[dayOfTheWeek]}`))
        : i18next.t(`campaigns.period.${schedule.repeat}`);
    } else if (schedule.repeat === CUSTOM_CAMPAIGN_INTERVAL.MONTHLY) {
      regularPeriodTitle = i18next
        .t(`campaigns.period.${schedule.repeat}`)
        .concat(` ${i18next.t('campaigns.extra titles.on')} `)
        .concat(
          `${i18next.t('campaigns.extra titles.day')} ${moment(
            schedule.startDate
          ).get('date')}`
        );
    } else {
      regularPeriodTitle = i18next.t(`campaigns.period.${schedule.repeat}`);
    }

    return withTimeAndDate
      ? regularPeriodTitle.concat(
          ` ${i18next.t('campaigns.extra titles.at')} ${campaignTime}`
      )
      : regularPeriodTitle;
  }
}
