/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import classNames from 'classnames';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_PRODUCTS, SEARCH_VENDOR_PRODUCTS } from 'graphql/query/products';
import { DELETE_PRODUCT } from 'graphql/mutation/products';
import { GET_USER, IMPERSONATE_ACCOUNT } from 'graphql/query/user';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import TableLayout from 'components/shared/TableLayout';
import ProductTableRow from 'components/ProductsTable/ProductTableRow';
import ModalCsvPhotosUpload from 'components/ProductsTable/ModalCsvPhotosUpload';
import ModalCsvProductsUpload from 'components/ProductsTable/ModalCsvProductsUpload';
import ModalImageUpload from 'components/ProductsTable/ModalImageUpload';
import {
  NOTIFICATION_STATUS,
  PRODUCT_DATA,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
// import UpdateAlgoliaIndexButton from '../../components/shared/UpdateAlgoliaIndexButton';

const useStyles = makeStyles(theme => ({
  tableSearchBarButtons: {
    minWidth: 142,
    height: 45,
    margin: theme.spacing(0, 1),
    border: theme.palette.border.lightGrey,
    borderRadius: 8,
    color: theme.palette.secondary.darkGray,
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 2.5),
    '&:first-child': {
      marginLeft: theme.spacing(2.5),
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  categoryColumn: {
    width: 330,
  },
  nameColumn: {
    width: 400,
  },
  uploadPhotos: {
    marginLeft: theme.spacing(1.5),
  },
  unitsInPackaging: {
    width: 145,
    whiteSpace: 'break-spaces',
    textAlign: 'center',
  },
}));

const DEFAULT_IMAGE_STATE = {
  id: null,
  src: null,
  isOpen: false,
};

export default function ProductsTable({ vendorHasFtpIntegration, isAdmin }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const queries = queryString.parse(location.search);

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'name',
    sortOrder: -1,
  });
  const [imageData, setImageData] = React.useState(DEFAULT_IMAGE_STATE);
  const [isCsvProductModalOpen, setCsvProductModalOpen] = React.useState(false);
  const [isCsvPhotoModalOpen, setCsvPhotoModalOpen] = React.useState(false);
  const [showSearchResults, setShowSearchResult] = React.useState(false);

  const [editModeId, setEditModeId] = React.useState(null);
  const [editConfirmationModal, setConfirmEditChanges] = React.useState(false);
  const [isAddProductOpen, setAddProductOpen] = React.useState(false);

  const [deleteProductMutation] = useMutation(DELETE_PRODUCT);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [
    getProducts,
    { loading: productsLoading, error: productsError, data },
  ] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const productsData = data && data.vendorProducts;

  const { data: user } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: {
      impersonate: { impersonateId = null },
    },
  } = useQuery(IMPERSONATE_ACCOUNT);

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getProducts({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
  }, []);

  const [
    getSearchProducts,
    { data: searchData, loading: searchLoading },
  ] = useLazyQuery(SEARCH_VENDOR_PRODUCTS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  React.useEffect(() => {
    const totalPages = productsData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (productsData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [productsData]);

  const setEditMode = id => {
    if (!editModeId) {
      setEditModeId(id);
    } else if (id === null) {
      setEditModeId(null);
      setConfirmEditChanges(false);
    } else if (id !== editModeId) {
      setConfirmEditChanges(true);
    }
  };

  const handleClickAway = () =>
    (editModeId && !imageData.isOpen ? setConfirmEditChanges(true) : null);

  // PRODUCT HANDLERS

  const handleDeleteRow = async productId => {
    try {
      await deleteProductMutation({
        variables: { productId },
        refetchQueries: [
          {
            query: GET_PRODUCTS,
            variables: {
              after: state.page + 1,
              pageSize: state.rowsPerPage,
              sortBy: state.sortBy,
              sortOrder: state.sortOrder,
            },
          },
        ],
        update: (_, { data: { deleteProduct } }) => {
          mixPanel.track('Delete Product', {
            products: productId,
            count: 1,
          });
          setNotification({
            variables: {
              timeout: 4000,
              message: t('products.delete product success', {
                productName: deleteProduct.name,
              }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  // PRODUCT PAGE HANDLERS

  const handleChangePage = async (event, page, searchPhrase) => {
    // NOTE: fetch more search query or products
    if (showSearchResults) {
      try {
        await getSearchProducts({
          variables: {
            searchPhrase,
            vendorId: impersonateId || user.user?.userData?.accountId,
            pageSize: state.rowsPerPage,
            after: page + 1,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    } else if (
      productsData &&
      (productsData.hasNextPage || page + 1 < productsData.totalPages)
    ) {
      try {
        await getProducts({
          variables: {
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async (event, searchPhrase) => {
    const rowsPerPage = +event.target.value;
    // NOTE: fetch more search query or products
    if (showSearchResults) {
      try {
        await getSearchProducts({
          variables: {
            searchPhrase,
            vendorId: impersonateId || user.user?.userData?.accountId,
            pageSize: rowsPerPage,
            after: 1,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    } else {
      try {
        await getProducts({
          variables: {
            pageSize: rowsPerPage,
            after: 1,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getProducts({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const handleSearchBar = async searchPhrase => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await getSearchProducts({
        variables: {
          searchPhrase,
          vendorId: impersonateId || user.user?.userData?.accountId,
          pageSize: state.rowsPerPage,
        },
      });
    } catch (error) {
      console.error('SEARCH_ERROR', error.message);
    }
  };

  const tableColumns = [
    {
      title: t('products.number'),
      field: 'vendorSpecificId',
      sortable: true,
    },
    {
      title: t('products.photo'),
      field: 'photo',
      sortable: false,
    },
    {
      title: t('products.name'),
      field: 'name',
      sortable: true,
      columnStyles: classes.nameColumn,
    },
    {
      title: t('products.package'),
      field: 'variants[0].packaging',
      sortable: false,
      columnStyles: classes.alignCenter,
    },
    {
      title: t('product attributes.attributes'),
      field: 'variants[0].attributes',
      sortable: false,
      columnStyles: classes.alignCenter,
    },
    {
      title: t('products.package content'),
      field: 'description',
      sortable: false,
    },
    {
      title: t('common.base unit'),
      field: 'variants[0].baseUnit',
      sortable: false,
    },
    {
      title: t('common.quantity per unit'),
      field: 'variants[0].unitsInPackaging',
      sortable: false,
      columnStyles: classes.unitsInPackaging,
    },
    {
      title: t('common.price'),
      field: 'price',
      sortable: false,
    },
    {
      title: t('common.unit price'),
      field: 'variants[0].price',
      sortable: false,
    },
    {
      title: t('products.category'),
      field: 'categories',
      sortable: true,
      columnStyles: classes.categoryColumn,
    },
  ];

  const { page, rowsPerPage, sortBy, sortOrder } = state;

  const hasError =
    productsError ||
    (!productsLoading && !productsData) ||
    !productsData?.products?.length;
  const searchResultHasError =
    showSearchResults && !searchData?.searchAllVendorProducts?.products?.length;
  const emptyTableData =
    (hasError && !showSearchResults) || searchResultHasError;
  const tableIsLoading = productsLoading || searchLoading;

  const totalResults = () => {
    if (showSearchResults) {
      return searchResultHasError
        ? 0
        : searchData.searchAllVendorProducts.totalResults;
    } else {
      return hasError ? 0 : productsData.totalResults;
    }
  };

  return (
    <>
      <TableLayout
        title={t('products.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.PRODUCTS}
        handleClickAway={handleClickAway}
        isEditMode={Boolean(editModeId)}
        emptyTableData={emptyTableData}
        emptyTableDataMessage={t('products.empty product data')}
        tableIsLoading={tableIsLoading}
        withDetailPanel
        withSearchBar
        searchPlaceholder={t('products.search placeholder')}
        handleSearchBar={handleSearchBar}
        handleShowSearchResult={setShowSearchResult}
        withActions
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={totalResults()}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        headerActions={[
          // FIXME temporary hide this option
          // <Button
          //   key="upload csv photo action"
          //   onClick={() => setCsvPhotoModalOpen(true)}
          //   className={classNames(classes.uploadPhotos, classes.tableSearchBarButtons)}
          //   variant="text"
          //   color="primary"
          // >
          //   {t('products.upload csv photo')}
          // </Button>,
          <Grid
            key="actions container"
            container
            wrap="nowrap"
            alignItems="flex-end"
            item
            xs={5}
          >
            <Button
              key="add product"
              className={classes.tableSearchBarButtons}
              variant="outlined"
              startIcon={<AddCircleOutlineIcon color="primary" />}
              onClick={() => setAddProductOpen(true)}
            >
              {t('products.add product')}
            </Button>
            {(!vendorHasFtpIntegration ||
              (vendorHasFtpIntegration && isAdmin)) && (
              <Button
                key="upload csv product action"
                className={classes.tableSearchBarButtons}
                startIcon={<GetAppRoundedIcon color="primary" />}
                variant="outlined"
                onClick={() => setCsvProductModalOpen(true)}
              >
                {t('products.upload csv product')}
              </Button>
            )}
          </Grid>,
          // <UpdateAlgoliaIndexButton
          //   vendorId={vendor._id}
          //   classes={{
          //     button: classNames(classes.addProductButton, classes.tableSearchBarButtons),
          //   }}
          // />,
        ]}
      >
        {isAddProductOpen && (
          <ProductTableRow
            key="newProduct"
            rowData={PRODUCT_DATA()}
            withCreateProduct
            isEditMode
            noImageUpload
            editConfirmationModalIsActive={editConfirmationModal}
            handleUploadImage={setImageData}
            setEditMode={() => setAddProductOpen(false)}
            setConfirmEditChanges={() => setAddProductOpen(false)}
            nonEditablePrice={vendorHasFtpIntegration}
          />
        )}
        {!hasError &&
          !showSearchResults &&
          productsData.products.map(rowData => (
            <ProductTableRow
              key={rowData._id}
              rowData={rowData}
              isEditMode={rowData._id === editModeId}
              editConfirmationModalIsActive={editConfirmationModal}
              handleUploadImage={setImageData}
              setEditMode={setEditMode}
              setConfirmEditChanges={setConfirmEditChanges}
              handleDeleteRow={handleDeleteRow}
              nonEditablePrice={vendorHasFtpIntegration}
            />
          ))}
        {showSearchResults &&
          !searchResultHasError &&
          searchData.searchAllVendorProducts.products.map(rowData => (
            <ProductTableRow
              key={rowData._id}
              rowData={rowData}
              isEditMode={rowData._id === editModeId}
              editConfirmationModalIsActive={editConfirmationModal}
              handleUploadImage={setImageData}
              setEditMode={setEditMode}
              setConfirmEditChanges={setConfirmEditChanges}
              handleDeleteRow={handleDeleteRow}
              nonEditablePrice={vendorHasFtpIntegration}
            />
          ))}
      </TableLayout>
      <ModalCsvPhotosUpload
        isOpen={isCsvPhotoModalOpen}
        handleClose={() => setCsvPhotoModalOpen(false)}
      />
      {isCsvProductModalOpen && (
        <ModalCsvProductsUpload
          isOpen={isCsvProductModalOpen}
          vendorHasFtpIntegration={vendorHasFtpIntegration}
          handleClose={() => setCsvProductModalOpen(false)}
        />
      )}
      <ModalImageUpload
        isOpen={imageData.isOpen}
        imageData={imageData}
        handleClose={() => setImageData(DEFAULT_IMAGE_STATE)}
      />
      <Prompt when={Boolean(editModeId)} message={t('common.router message')} />
    </>
  );
}

ProductsTable.propTypes = {
  vendorHasFtpIntegration: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

ProductsTable.defaultProps = {
  vendorHasFtpIntegration: false,
  isAdmin: false,
};
