import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import classNames from 'classnames';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  CREATE_VENDOR_CUSTOMER,
  UPDATE_VENDOR_CUSTOMER,
} from 'graphql/mutation/customer';
import { CREATE_NEW_LIST, ASSIGN_CUSTOMERS_TO_VENDOR_LIST } from 'graphql/mutation/lists';
import { GET_VENDOR_CUSTOMERS } from 'graphql/query/vendors';
import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ConfirmIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';

import DetailPanel from 'components/CustomerTable/DetailPanel';
import { CUSTOMER_DATA, NOTIFICATION_STATUS, DELIVERY_DAYS } from 'helpers/constants';
import ValidationSchema from 'components/CustomerTable/ValidationSchema';
import EditConfirmationModal from 'components/shared/EditConfirmationModal';
import DeleteCustomerModal from 'components/shared/DeleteModal';
import useMixPanel from 'helpers/useMixPanel';
import StatusModal from './StatusModal';
import CustomerTableRowCells from './CustomerTableRowCells';

import { CUSTOMER_MANAGEMENT_ERRORS } from 'helpers/constants';
import { useNotification } from '../../hooks/useNotification';

const useStyles = makeStyles(theme => ({
  tableBodyRow: {
    height: 56
  },
  tableWithOpenedPanel: {
    background: theme.palette.background.container
  },
  tableCell: {
    padding: theme.spacing(0, 1.5),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
  },
  expandMoreCell: {
    width: 30,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  tableCellDetailPanel: {
    padding: 0,
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: theme.spacing(0, 0.5),
    backgroundColor: theme.palette.background.button
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.darkGray
  },
  confirmButton: {
    color: theme.palette.success.main,
    margin: 4
  },
  cancelButton: {
    margin: 4,
    color: theme.palette.error.main
  },
  highlightRow: {
    animation: `$highlighting 1800ms ${theme.transitions.easing.easeOut}`,
  },
  '@keyframes highlighting': {
    '0%': {
      backgroundColor: theme.palette.primary.main,
    },
    '100%': {
      backgroundColor: theme.palette.background.default,
    }
  }
}));

function SupplierTableRow({
  rowData,
  allCustomerGroups,
  editConfirmationModalIsActive,
  isEditMode,
  setEditMode,
  setConfirmEditChanges,
  setAddingNewCustomer,
  handleDeleteRow,
  handleSendInvitation,
  handleOpenNewListEditor,
  addNewListIsActive,
  isNewCustomer,
  handleSetDefaultSorting,
  selectedCustomerId,
  executeScroll,
  customerRef,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const mixPanel = useMixPanel();

  const [isPanelOpen, setPanelOpen] = React.useState(false);
  const [isDeleteRowModalOpen, setDeleteRowModal] = React.useState(false);
  const [localCustomerData, setLocalCustomerData] = React.useState({ ...CUSTOMER_DATA });
  const [isStatusModalOpen, setStatusModalOpen] = React.useState(false);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [createCustomerMutation] = useMutation(CREATE_VENDOR_CUSTOMER);
  const [updateCustomerMutation] = useMutation(UPDATE_VENDOR_CUSTOMER);
  const [createVendorListMutation] = useMutation(CREATE_NEW_LIST);
  const [assignCustomersMutation] = useMutation(ASSIGN_CUSTOMERS_TO_VENDOR_LIST);

  const showSuccessNotification = useNotification(NOTIFICATION_STATUS.SUCCESS);
  const showAlertNotification = useNotification(NOTIFICATION_STATUS.ALERT);

  // SAVE INIT CUSTOMER DATA

  const setInitialCustomerData = React.useCallback(() => {
    const {
      ccEmails,
      contactData,
      contacts,
      vendorClientId,
      deliveryDays,
      lists,
      customerGroups,
      status,
      _id,
      orders,
      createdAt,
      users
    } = rowData;

    setLocalCustomerData({
      _id,
      contactData,
      contacts,
      vendorClientId,
      deliveryDays: deliveryDays.length < 7 ?
        DELIVERY_DAYS.map(defaultDay => ({
          name: defaultDay,
          enabled: Boolean(deliveryDays.find(day => day.name === defaultDay))
        }))
        : deliveryDays,
      lists,
      customerGroups,
      status,
      ccEmails,
      orders,
      createdAt,
      users
    });
  }, [rowData]);

  React.useEffect(() => {
    setInitialCustomerData();
  }, [setInitialCustomerData]);

  // CREATE NEW VENDOR LIST

  const assignCustomerToList = async (listId) => {
    try {
      await assignCustomersMutation({
        variables: {
          listId,
          customers: {
            customerIds: [rowData.vendorClientId]
          }
        },
        update: (store, {
          data: {
            assignCustomersToVendorList: {
              customerIds,
              failedCustomerIds
            }
          }
        }) => {
          if (failedCustomerIds.length) {
            showAlertNotification(t('lists.error add customers', {
              customers: failedCustomerIds.join(', '), count: failedCustomerIds.length
            }));
          } else {
            mixPanel.track('Assign Customer To List', {
              listId,
              customers: customerIds.join(', '),
              count: customerIds.length
            });
            showSuccessNotification(t('lists.success add customers', {
              customers: customerIds.join(', '), count: customerIds.length
            }));
          }
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCreateNewList = async () => {
    try {
      const response = await createVendorListMutation({
        variables: { title: 'New List' },
      });
      mixPanel.track('Create New List');
      const id = response.data && response.data.createVendorList._id;
      if (id) {
        history.replace({
          pathname: '/customers',
          search: queryString.stringify({
            ...queryString.parse(history.location.search),
            id: rowData._id
          })
        });
        await assignCustomerToList(id);
        history.push(`list/${id}`);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // CUSTOMER DATA HANDLERS

  const handleSaveCustomer = async (status, values) => {
    const {
      vendorClientId,
      contactData,
      deliveryDays,
      ccEmails,
      customerGroups,
      contacts
    } = values;

    try {
      await createCustomerMutation({
        variables: {
          customerData: {
            vendorClientId,
            contactData,
            deliveryDays,
            ccEmails: ccEmails
              .filter(email => email.trim() !== ''),
            groups: customerGroups.map(({ _id }) => _id),
            contacts: contacts.map(({ name, email, phone }) => ({ name, email, phone }))
          }
        },
        refetchQueries: [{
          query: GET_VENDOR_CUSTOMERS,
        }],
        update: (_, { data: { createCustomer } }) => {
          if (status === 'invited') {
            handleSendInvitation(createCustomer);
          }
          setAddingNewCustomer(false);
          setEditMode(null);
        },
        onError: (error) => {
          showAlertNotification(t(CUSTOMER_MANAGEMENT_ERRORS[error.message]));
        },
      });
      mixPanel.track('Create Customer', {
        vendorClientId,
        businessName: contactData?.businessName,
        email: contacts[0].email,
        phone: contacts[0].phone
      });

      handleSetDefaultSorting({
        sortBy: 'createdAt',
        sortOrder: -1,
        page: 0,
      });

      if (addNewListIsActive) {
        handleCreateNewList();
      }
    } catch (error) {
      console.error(error.message);
    }
    setStatusModalOpen(false);
  };

  const handleUpdateCustomer = async (values) => {
    const {
      vendorClientId,
      contactData: {
        businessName, street, houseNumber, zip, city, country
      },
      deliveryDays,
      ccEmails,
      customerGroups,
      contacts
    } = values;

    try {
      await updateCustomerMutation({
        variables: {
          connectionId: values._id,
          customerData: {
            vendorClientId,
            contactData: {
              businessName, street, houseNumber, zip, city, country
            },
            deliveryDays: deliveryDays.map(day => ({
              name: day.name,
              enabled: day.enabled
            })),
            ccEmails: ccEmails
              .filter(email => email.trim() !== ''),
            groups: customerGroups.map(({ _id }) => _id),
            contacts: contacts.map(({ name, email, phone }) => ({ name, email, phone }))
          }
        },
        update: (store, { data: { updateCustomer } }) => {
          const allCustomers = store.readQuery({ query: GET_VENDOR_CUSTOMERS });
          store.writeQuery({
            query: GET_VENDOR_CUSTOMERS,
            data: {
              vendorCustomers: {
                ...allCustomers.vendorCustomers,
                customers: allCustomers.vendorCustomers.customers.map(
                  customer => (customer._id === updateCustomer._id ?
                    ({ ...updateCustomer }) : customer)
                ),
              }
            }
          });
        }
      });
      // FIXME: check if we need to close details immediately
      // setPanelOpen(false);
      setEditMode(null);

      if (addNewListIsActive) {
        handleCreateNewList();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCloseEditModal = () => {
    setInitialCustomerData();
    setConfirmEditChanges(false);
    setAddingNewCustomer(false);
    setEditMode(null);
    if (addNewListIsActive) {
      handleCreateNewList();
    }
  };

  const handleSubmitCustomerData = (values) => {
    if (isNewCustomer) {
      // Temporary hide customer invitation status modal
      // setStatusModalOpen(true);
      handleSaveCustomer('draft', values);
    } else if (!isEqual(localCustomerData, values)) {
      handleUpdateCustomer({ ...values });
    } else {
      handleCloseEditModal();
    }
  };

  const handleErrors = (errors) => {
    const parseErrorMessage = () => {
      if (errors.deliveryDays) {
        return t('customers.delivery days error');
      } else if (errors.contacts && typeof errors.contacts === 'string') {
        return t('customers.empty contacts field error');
      } else if (errors.contacts && errors.contacts?.filter(contact => contact).some(contact => contact?.phone)) {
        return t('customers.phone error');
      } else return t('customers.empty field error');
    };

    setNotification({
      variables: {
        timeout: 4000,
        message: parseErrorMessage(),
        type: NOTIFICATION_STATUS.ALERT,
        isOpen: true,
      },
    });
  };

  const handleCancelEditing = (values) => {
    if (!isEqual(localCustomerData, values)) {
      setConfirmEditChanges(true);
    } else {
      handleCloseEditModal();
    }
  };

  // HANDLE SELECTED OR NEW CUSTOMER

  React.useEffect(() => {
    if (isNewCustomer) {
      setPanelOpen(true);
      setEditMode(rowData._id);
    }
  }, [isNewCustomer, rowData._id, setEditMode]);

  React.useEffect(() => {
    if (selectedCustomerId === rowData._id) {
      executeScroll();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasRef = selectedCustomerId === rowData._id ? customerRef : null;

  return (
    <Formik
      initialValues={{
        ...localCustomerData
      }}
      enableReinitialize
      onSubmit={(values) =>
        handleSubmitCustomerData(values)}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ValidationSchema}
    >
      {({
        values, handleChange, handleSubmit, setFieldValue, errors, validateForm, resetForm
      }) => (
        <>
          <TableRow
            className={classNames(
              classes.tableBodyRow,
              isEditMode && classes.tableWithOpenedPanel,
              hasRef && classes.highlightRow
            )}
            ref={hasRef}
          >
            <TableCell className={classNames(classes.tableCell, classes.expandMoreCell)}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setPanelOpen(!isPanelOpen)}
              >
                {isPanelOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </TableCell>
            <CustomerTableRowCells
              tableData={values}
              tableErrors={errors}
              allCustomerGroups={allCustomerGroups}
              isEditMode={isEditMode}
              isNewCustomer={isNewCustomer}
              setFieldValue={setFieldValue}
              handleOpenNewListEditor={handleOpenNewListEditor}
              handleCreateNewList={handleCreateNewList}
            />
            <TableCell className={classes.tableCell}>
              <Grid container wrap="nowrap">
                {
                  isEditMode ? (
                    <>
                      <IconButton
                        onClick={async () => {
                          const validate = await validateForm();
                          if (!isEmpty(validate) && isEditMode) {
                            handleErrors(validate);
                          } else {
                            handleSubmit();
                          }
                        }}
                        className={classes.confirmButton}
                        aria-label="confirm"
                        size="small"
                      >
                        <ConfirmIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleCancelEditing(values)}
                        className={classes.cancelButton}
                        aria-label="cancel"
                        size="small"
                      >
                        <CancelIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        classes={{ root: classes.tableActionButton }}
                        onClick={() => setEditMode(rowData._id)}
                      >
                        <EditIcon className={classes.tableActionButtonIcon}/>
                      </Button>
                      <Button
                        variant="contained"
                        classes={{ root: classes.tableActionButton }}
                        onClick={() => setDeleteRowModal(true)}
                      >
                        <DeleteIcon className={classes.tableActionButtonIcon}/>
                      </Button>
                    </>
                  )
                }
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellDetailPanel} colSpan={12}>
              <Collapse in={isPanelOpen} timeout="auto" unmountOnExit>
                <DetailPanel
                  isEditModeActive={isEditMode}
                  formikChangeHandler={handleChange}
                  formikValues={values}
                  errors={errors}
                  setFormikFieldValue={setFieldValue}
                />
              </Collapse>
            </TableCell>
          </TableRow>
          {isEditMode && (
            <EditConfirmationModal
              isOpen={editConfirmationModalIsActive}
              handleCloseModal={setConfirmEditChanges}
              modalContentMessage={t('customers.modal message')}
              onConfirm={async () => {
                const validate = await validateForm();
                if (!isEmpty(validate) && isEditMode) {
                  handleErrors(validate);
                } else {
                  handleSubmit();
                }
              }}
              onCancel={() => {
                resetForm();
                handleCloseEditModal();
              }}
            />
          )}
          {isStatusModalOpen && (
            <StatusModal
              isOpen={isStatusModalOpen}
              submitCustomer={status => handleSaveCustomer(status, values)}
            />
          )}
          {isDeleteRowModalOpen && (
            <DeleteCustomerModal
              isOpen={isDeleteRowModalOpen}
              message={t('customers.delete customer message')}
              handleCloseModal={() => setDeleteRowModal(false)}
              handleDelete={() => {
                setDeleteRowModal(false);
                handleDeleteRow(values._id, values.contactData?.businessName);
              }}
            />
          )}
        </>
      )}
    </Formik>
  );
}

SupplierTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  allCustomerGroups: PropTypes.array.isRequired,
  isNewCustomer: PropTypes.bool,
  setAddingNewCustomer: PropTypes.func,
  isEditMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setConfirmEditChanges: PropTypes.func.isRequired,
  editConfirmationModalIsActive: PropTypes.bool,
  handleSetDefaultSorting: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func,
  handleSendInvitation: PropTypes.func.isRequired,
  selectedCustomerId: PropTypes.string,
  customerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  executeScroll: PropTypes.func,
  handleOpenNewListEditor: PropTypes.func.isRequired,
  addNewListIsActive: PropTypes.bool.isRequired,
};

SupplierTableRow.defaultProps = {
  isNewCustomer: false,
  setAddingNewCustomer: noop,
  editConfirmationModalIsActive: false,
  handleDeleteRow: noop,
  selectedCustomerId: null,
  executeScroll: noop,
  customerRef: null
};

export default SupplierTableRow;
