import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  packageLabel: {
    height: 24,
    width: 120,
    borderRadius: 30,
  },
  hasLabel: {
    background: 'rgba(255, 81, 0, 0.15)',
  },
  emptyLabel: {
    background: theme.palette.error.main,
  },
  packagingUnitLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 100,
  },
  hasLabelTitle: {
    color: theme.palette.primary.main,
  },
  emptyLabelTitle: {
    color: theme.palette.common.white,
  },
}));

export default function ProductPackaging({ unit }) {
  const classes = useStyles();

  return (
    <Grid
      className={classNames(
        classes.packageLabel,
        unit ? classes.hasLabel : classes.emptyLabel
      )}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Tooltip title={unit || 'NA'} placement="top">
        <Typography
          className={classNames(
            classes.packagingUnitLabel,
            unit ? classes.hasLabelTitle : classes.emptyLabelTitle
          )}
          variant="button"
          align="center"
        >
          {unit || 'NA'}
        </Typography>
      </Tooltip>
    </Grid>
  );
}

ProductPackaging.propTypes = {
  unit: PropTypes.string,
};

ProductPackaging.defaultProps = {
  unit: null,
};
