import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

import enTranslation from 'locales/en/translation.json';
import deTranslation from 'locales/de/translation.json';

const supportedLanguages = ['de', 'en'];

i18n

  .use(Backend)
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    supportedLngs: supportedLanguages,
    // when no language is detected, fallback to de
    fallbackLng: 'de',
    debug: false,
    whitelist: supportedLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: enTranslation
      },
      de: {
        translation: deTranslation
      }
    }
  });

export default i18n;
