import React from 'react';

import { useApolloClient } from '@apollo/client';
import useMixPanel from 'helpers/useMixPanel';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  logoutContainer: {
    height: '100%',
    padding: '8px 0px 24px',
  },
  backButton: {
    alignSelf: 'flex-start',
    marginBottom: 24,
  },
}));

function Logout() {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const mixPanel = useMixPanel();

  const handleLogout = async () => {
    mixPanel.track('Logout');
    if (localStorage.getItem('apollo-cache-persist')) {
      localStorage.removeItem('apollo-cache-persist');
    }
    await sessionStorage.clear();
    await client.clearStore();
    history.push('/');
  };

  React.useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      className={classes.logoutContainer}
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Button
        className={classes.backButton}
        startIcon={<BackIcon />}
        onClick={history.goBack}
      >
        Back
      </Button>
      <Typography>LOGGING OUT...</Typography>
    </Grid>
  );
}

export default Logout;
