/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_NEWSLETTER } from 'graphql/mutation/newsletters';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { GET_VENDOR_PROFILE } from 'graphql/query/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MailIcon from '@material-ui/icons/MailRounded';

import NewsletterPlaceholder from 'components/Newsletter/NewsletterPlaceholder';
import BroadcastsTable from 'components/Newsletter/Broadcasts/BroadcastsTable';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(({ palette, spacing }) => ({
  newsletterContainer: {
    width: '100%',
    height: '100%',
    marginTop: spacing(1),
  },
  actionButtonsContainer: {
    marginBottom: spacing(2),
  },
  createEmail: {
    width: 'fit-content',
    borderRadius: 40,
    boxShadow: 'none',
  },
  button: {
    borderRadius: 40,
    color: palette.text.primary,
    background: palette.background.paper,
    marginRight: spacing(1.5),
    transition: 'all 0.25s ease-in',
  },
  activeButton: {
    color: palette.background.paper,
    background: palette.text.primary,
    '&:hover': {
      color: palette.background.paper,
      background: palette.text.primary,
    },
  },
}));

export default function Newsletters() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const mixPanel = useMixPanel();

  const [isDisplayingPlaceholder, setDisplayPlaceholder] = React.useState(
    false
  );
  // const [newsletterSection, setNewsletterSection] = React.useState(NL_SECTIONS.BROADCASTS);

  const { data: vendorData } = useQuery(GET_VENDOR_PROFILE);
  const [createNewsletterMutation] = useMutation(CREATE_NEWSLETTER);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const handleCreateNewsletter = async () => {
    try {
      const createdNewsletterResponse = await createNewsletterMutation({
        variables: {
          template: {
            title: 'Newsletter',
            subject: `Newsletter ${moment().format('DD-MM-YYYY HH:mm')}`,
          },
        },
      });
      mixPanel.track('Newsletter - Create new', {
        newsletterId: createdNewsletterResponse?.data?.createNewsletter?._id,
        newsletter: createdNewsletterResponse?.data,
      });
      history.push(
        `/newsletter/${createdNewsletterResponse?.data?.createNewsletter?._id}`
      );
    } catch (error) {
      setNotification({
        variables: {
          timeout: 4000,
          message: error.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  if (isDisplayingPlaceholder) {
    return (
      <NewsletterPlaceholder
        vendorData={vendorData}
        handleCreateNewsletter={handleCreateNewsletter}
      />
    );
  }

  return (
    <Grid className={classes.newsletterContainer}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.actionButtonsContainer}
      >
        <Grid>
          {/* <Button
            className={classNames(
              classes.button,
              newsletterSection === NL_SECTIONS.FLOWS && classes.activeButton
            )}
            variant="outlined"
            onClick={() => setNewsletterSection(NL_SECTIONS.FLOWS)}
          >
            {t('newsletter.flows option')}
          </Button>
          <Button
            className={classNames(
              classes.button,
              newsletterSection === NL_SECTIONS.BROADCASTS && classes.activeButton
            )}
            variant="outlined"
            onClick={() => setNewsletterSection(NL_SECTIONS.BROADCASTS)}
          >
            {t('newsletter.broadcasts option')}
          </Button> */}
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.createEmail}
            onClick={handleCreateNewsletter}
            endIcon={<MailIcon color="inherit" fontSize="small" />}
          >
            {t('newsletter.create email')}
          </Button>
        </Grid>
      </Grid>
      {/* {newsletterSection === NL_SECTIONS.FLOWS && (
        <FlowsTable />
      )}
      {newsletterSection === NL_SECTIONS.BROADCASTS && (
        <BroadcastsTable />
      )} */}
      <BroadcastsTable setDisplayPlaceholder={setDisplayPlaceholder} />
    </Grid>
  );
}
