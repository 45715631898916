/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { GROUP_FRAGMENT, TRANSLATION_FRAGMENT } from 'graphql/fragments';

export const GET_PDF_CATALOGS = gql`
  ${GROUP_FRAGMENT}
  ${TRANSLATION_FRAGMENT}
  query getAllPdfCatalogs($pageSize: Int, $after: Int) {
    getAllPdfCatalogs(after: $after, pageSize: $pageSize) {
      results {
        _id
        createdAt
        updatedAt
        vendorId
        title {
          ...TranslationFragment
        }
        groups {
          ...GroupFragment
        }
        url
        status
        enabled
        isBaseUnitPrice
      }
      page
      totalPages
      nextPage
      hasNextPage
      hasPrevPage
      prevPage
      totalResults
    }
  }
`;

export const GET_PDF_CATALOG = gql`
  ${GROUP_FRAGMENT}
  ${TRANSLATION_FRAGMENT}
  query getPdfCatalogById($_id: ID!, $pageSize: Int, $after: Int) {
    getPdfCatalogById(_id: $_id, after: $after, pageSize: $pageSize) {
      _id
      createdAt
      updatedAt
      vendorId
      title {
        ...TranslationFragment
      }
      groups {
        ...GroupFragment
      }
      url
      status
      enabled
      isBaseUnitPrice
      parsedPerPage {
        page
        text
        status
        products {
          _id
          price
          name
          packaging
          baseUnit
          unitsInPackaging
        }
      }
    }
  }
`;

export const GET_PDF_CATALOG_PRODUCTS = gql`
  query getPdfCatalogProducts(
    $catalogId: ID!
    $pageNumber: Int!
    $pageSize: Int
    $after: Int
    $searchPhrase: String
  ) {
    getPdfCatalogProducts(
      catalogId: $catalogId
      pageNumber: $pageNumber
      pageSize: $pageSize
      after: $after
      searchPhrase: $searchPhrase
    ) {
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      pageNumber
      results {
        _id
        vendorId
        catalogId
        isActivated
        vendorSpecificId
        productId
        page
        pagePosition
        price
        isBaseUnitPrice
        name
        packaging
        baseUnit
        unitsInPackaging

        productCopy {
          _id
          name
          vendorSpecificId
          variants {
            packaging
            price
            baseUnit
            unitsInPackaging
          }
        }
      }
    }
  }
`;
