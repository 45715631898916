/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grow from '@material-ui/core/Grow';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import StyledInput from 'components/shared/Inputs/StyledInput';
import useDebounce from 'helpers/useDebounce';

function RecipientsTableSearch({ search, handleInputChange }) {
  const { t } = useTranslation();

  const debounceRequest = useDebounce(1000);

  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setSearchInput(search);
  }, []);

  useEffect(() => {
    debounceRequest(() => handleInputChange(searchInput));
  }, [searchInput]);

  const handleLocalSearchState = event => {
    setSearchInput(event.target.value);
  };

  const handleClearInput = () => {
    setSearchInput('');
  };

  return (
    <StyledInput
      variant="outlined"
      value={searchInput}
      placeholder={t('newsletter.search recipient')}
      onChange={handleLocalSearchState}
      fullWidth
      startAdornment={(
        <InputAdornment position="start">
          <SearchRoundedIcon />
        </InputAdornment>
      )}
      endAdornment={(
        <InputAdornment position="end">
          <Grow in={Boolean(searchInput)} timeout={500}>
            <IconButton onClick={handleClearInput} size="small">
              <HighlightOffRoundedIcon />
            </IconButton>
          </Grow>
        </InputAdornment>
      )}
    />
  );
}

RecipientsTableSearch.propTypes = {
  search: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired
};

export default memo(RecipientsTableSearch, (prevProps, nextProps) =>
  prevProps.search === nextProps.search
);
