import { gql } from '@apollo/client';

export const RENAME_VENDOR_LIST = gql`
mutation renameVendorList($listId: ID!, $title: String!) {
    renameVendorList(listId: $listId, title: $title) {
        _id
        title
    }
  }
`;

export const CREATE_NEW_LIST = gql`
mutation createVendorList($title: String!) {
  createVendorList(title: $title) {
    _id
    title
  }
}
`;

export const DELETE_LIST = gql`
mutation deleteVendorList($listId: ID!) {
  deleteVendorList(listId: $listId) {
    _id
    title
  }
}
`;

export const ADD_PRODUCTS_TO_VENDOR_LIST = gql`
mutation addProductsToVendorList($listId: ID!, $productIds: [ID]!) {
  addProductsToVendorList(listId: $listId, productIds: $productIds) {
    productIds
    failedProductIds
    list {
      _id
      title
      products {
        totalResults
        page
        totalPages
        hasNextPage
        nextPage
        hasPrevPage
        products {
          _id
          vendorSpecificId
        }
      }
    }
  }
}
`;

export const DELETE_PRODUCTS_FROM_VENDOR_LIST = gql`
mutation removeProductsFromVendorList($listId: ID!, $productIds: [ID]!) {
  removeProductsFromVendorList(listId: $listId, productIds: $productIds) {
    productIds
    failedProductIds
    list {
      _id
      title
      products {
        totalResults
        page
        totalPages
        hasNextPage
        nextPage
        hasPrevPage
        products {
          _id
          vendorSpecificId
        }
      }
    }
  }
}
`;

export const ASSIGN_CUSTOMERS_TO_VENDOR_LIST = gql`
mutation assignCustomersToVendorList($listId: ID!, $customers: ListAssignmentInput!) {
  assignCustomersToVendorList(listId: $listId, customers: $customers) {
    customerIds
    groupIds
    assignedToAll
    failedCustomerIds
    failedGroupIds
    list {
      assignedToGroups {
        _id
        name
        color
        translations {
          lang
          value
        }
      }
      assignedToCustomers
      assignedToAll
      _id
      title
      products {
        totalResults
        page
        totalPages
        hasNextPage
        nextPage
        hasPrevPage
        products {
          _id
        }
      }
    }
  }
}
`;

export const DELETE_CUSTOMERS_FROM_VENDOR_LIST = gql`
mutation unassignCustomersFromVendorList($listId: ID!, $customers: ListAssignmentInput!) {
  unassignCustomersFromVendorList(listId: $listId, customers: $customers) {
    customerIds
    groupIds
    assignedToAll
    failedCustomerIds
    failedGroupIds
    list {
      assignedToGroups {
        _id
        name
        color
        translations {
          lang
          value
        }
      }
      assignedToCustomers
      assignedToAll
      _id
      title
      products {
        totalResults
        page
        totalPages
        hasNextPage
        nextPage
        hasPrevPage
        products {
          _id
        }
      }
    }
  }
}
`;
