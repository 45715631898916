/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_VENDOR_PROFILE } from 'graphql/query/user';
import { GET_VENDOR_NEWSLETTER_BY_ID } from 'graphql/query/newsletters';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { UPDATE_NEWSLETTER } from 'graphql/mutation/newsletters';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';

import MainSection from 'components/Newsletter/Constructor/MainSection';
import ProductsSection from 'components/Newsletter/Constructor/ProductsSection';
import ActionButtonsSection from 'components/Newsletter/Constructor/ActionButtonsSection';
import {
  NEWSLETTER_DEFAULT_DATA,
  RECIPIENTS_FILTER_OPTIONS,
  compareTemplates,
  parseNewsletterStateFields,
} from 'components/Newsletter/localConstantsAndHelpers';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import { parseDataValidationErrors } from 'helpers/getErrorMessage';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(({ spacing, palette }) => ({
  newsletterConstructorContainer: {
    width: '100%',
    height: '100%',
    marginTop: spacing(1),
    position: 'relative',
  },
  newsletterConstructorTopActionsContainer: {
    marginBottom: spacing(2),
  },
  newsletterConstructorBackButton: {
    whiteSpace: 'nowrap',
  },
  nlConstructorPaper: {
    borderRadius: 8,
    padding: spacing(3),
    position: 'relative',
  },
  nlConstructorIsLoading: {
    pointerEvents: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      borderRadius: 8,
      background: palette.action.disabledBackground,
    },
  },
}));

export default function NewsletterConstructor({ vendor, newsletterId }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const mixPanel = useMixPanel();

  const { _id: vendorId } = vendor || {};

  const [state, setState] = useState(NEWSLETTER_DEFAULT_DATA(vendorId));

  const [
    updateNewsletterMutation,
    { loading: updateNewsletterLoading },
  ] = useMutation(UPDATE_NEWSLETTER);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const { data: vendorProfileData } = useQuery(GET_VENDOR_PROFILE, {
    fetchPolicy: 'cache-and-network',
  });
  const [
    getVendorNewsletter,
    { data: vendorNewsletter, loading: isNewsletterLoading },
  ] = useLazyQuery(GET_VENDOR_NEWSLETTER_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    canonizeResults: false,
    notifyOnNetworkStatusChange: true,
    onCompleted: vendorNLDataResponse => {
      if (vendorNLDataResponse?.getNewsletter) {
        const responseTemplate = parseNewsletterStateFields(
          vendorNLDataResponse?.getNewsletter
        );
        setState(responseTemplate);
      }
    },
  });

  useEffect(() => {
    if (newsletterId === 'new') {
      setState(NEWSLETTER_DEFAULT_DATA(vendorId));
    } else {
      getVendorNewsletter({
        variables: { id: newsletterId },
      });
    }
  }, [newsletterId]);

  const handleUpdateNewsletter = async (
    { withSilentSave = false },
    callbackFn
  ) => {
    if (state?.status === RECIPIENTS_FILTER_OPTIONS.SENT) return;

    const { fromEmail, fromName, staticInfo, subject, title } = state || {};

    const template = {
      fromEmail,
      fromName,
      staticInfo: {
        workingHours: staticInfo.workingHours,
        workingHoursCaption: staticInfo.workingHoursCaption,
        phone: staticInfo.phone,
        email: staticInfo.email,
        disclaimer: staticInfo.disclaimer,
      },
      subject,
      title,
      productGrid: {
        ...state.productGrid,
        products: state?.productGrid?.products?.reduce(
          (products, { productCopy, ...product }) =>
            (product._id ? products.concat({ ...product }) : products),
          []
        ),
        featuredProducts: state?.productGrid?.featuredProducts?.reduce(
          (products, { productCopy, ...product }) =>
            (product._id ? products.concat({ ...product }) : products),
          []
        ),
      },
    };

    const templatesAreEqual = compareTemplates(template, vendorNewsletter);

    if (!templatesAreEqual) {
      try {
        await updateNewsletterMutation({
          variables: {
            id: state._id,
            template,
          },
          update: () => {
            if (!withSilentSave) {
              setNotification({
                variables: {
                  timeout: 4000,
                  message: t(
                    'newsletter.notifications.newsletter status successfully updated'
                  ),
                  type: NOTIFICATION_STATUS.SUCCESS,
                  isOpen: true,
                },
              });
            }

            mixPanel.track('Newsletter - Save', {
              newsletterId,
              originalNewsletter: vendorNewsletter?.getNewsletter,
              savedNewsletter: state,
              withSilentSave,
              withSilentSaveNote:
                'If this was an autosave the value will be "true" otherwise that means user made save by his own and value is "false"',
            });
          },
        });
      } catch (error) {
        const message = parseDataValidationErrors(error.message);
        setNotification({
          variables: {
            timeout: 4000,
            message: message || t('common.something wrong'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }

    if (callbackFn) {
      callbackFn();
    }
  };

  const vendorDefaultProductImage =
    vendorProfileData?.vendorAccount?.preferences?.defaultProductImage;

  const handleBackToNewsletters = () => {
    history.push('/newsletters');
  };

  const isPageLoading = isNewsletterLoading || !vendorNewsletter?.getNewsletter;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.newsletterConstructorContainer}
    >
      <Grid
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.newsletterConstructorTopActionsContainer}
        container
        wrap="nowrap"
      >
        <Button
          className={classes.newsletterConstructorBackButton}
          startIcon={<ArrowBackIcon />}
          onClick={handleBackToNewsletters}
        >
          {t('newsletter.newsletters')}
        </Button>

        <ActionButtonsSection
          state={state}
          withLoader
          loading={isNewsletterLoading || updateNewsletterLoading}
          handleUpdateNewsletter={handleUpdateNewsletter}
          setState={setState}
        />
      </Grid>
      <Paper
        className={classNames(
          classes.nlConstructorPaper,
          isPageLoading && classes.nlConstructorIsLoading
        )}
      >
        <MainSection
          state={state}
          setState={setState}
          handleUpdateNewsletter={handleUpdateNewsletter}
        />

        <ProductsSection
          state={state}
          defaultProductImage={vendorDefaultProductImage}
          setState={setState}
        />

        <ActionButtonsSection
          state={state}
          withTopMargin
          handleUpdateNewsletter={handleUpdateNewsletter}
          setState={setState}
        />
      </Paper>
    </Grid>
  );
}

NewsletterConstructor.defaultProps = {
  vendor: {},
};

NewsletterConstructor.propTypes = {
  vendor: PropTypes.object,
  newsletterId: PropTypes.string.isRequired,
};
