/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery } from '@apollo/client';
import { GET_SALES_REP } from 'graphql/query/salesReps';

import { makeStyles } from '@material-ui/styles';

import TableLayout from 'components/shared/TableLayout';
import DetailsTableRow from 'components/SalesRepsTable/DetailsTableRow';
import HeaderActions from 'components/SalesRepsTable/HeaderActions';
import EmptyTableRow from 'components/shared/EmptyTableRow';

import { ROWS_PER_PAGE_OPTIONS, TABLE_SKELETON_TYPES } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  salesRepId: {
    paddingLeft: theme.spacing(5),
    width: 100
  },
  salesRepTableTitle: {
    width: 330,
  },
}));

export default function SalesRepDetails({ id }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: -1
  });

  const [getSalesRep, {
    loading: salesRepLoading,
    error: salesRepError,
    data,
  }] = useLazyQuery(
    GET_SALES_REP,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const salesRep = data?.vendorSalesRep;

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;

    getSalesRep({
      variables: {
        _id: id,
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        // sortBy: state.sortBy,
        // sortOrder: state.sortOrder
      }
    });

    if (pageParam || rowsParam) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = salesRep?.clientConnections?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && (totalPages <= state.page);
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(state.rowsPerPage);

    if (salesRep?.clientConnections && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        })
      });
      window.location.reload();
    }
  }, [salesRep]);

  const handleChangePage = async (event, page) => {
    if (
      salesRep && (
        salesRep?.clientConnections?.hasNextPage ||
        page + 1 < salesRep?.clientConnections?.totalPages
      )
    ) {
      try {
        await getSalesRep({
          variables: {
            _id: id,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({ ...queryString.parse(location.search), page })
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      await getSalesRep({
        variables: {
          _id: id,
          after: 1,
          pageSize: rowsPerPage,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }

    setState({ ...state, rowsPerPage, page: 0 });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0
      })
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getSalesRep({
        variables: {
          _id: id,
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, sortOrder, sortBy: sortLabel, page: 0
    });
    history.replace({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder
      })
    });
  };

  const handleBackButtonAction = () => {
    history.push('/salesReps');
  };

  /* eslint-disable react/prop-types */
  const tableColumns = [
    {
      title: t('customers.customer number'),
      field: 'vendorClientId',
      sortable: false,
      columnStyles: classes.salesRepId,
    },
    {
      title: t('settings.business'),
      field: 'contactData.businessName',
      sortable: false,
      columnStyles: classes.salesRepTableTitle,
    },
    {
      title: t('common.city'),
      field: 'contactData.city',
      sortable: false,
      columnStyles: classes.salesRepTableTitle,
    },
    {
      title: t('salesReps.address'),
      field: 'contactData.street',
      sortable: false,
      columnStyles: classes.salesRepTableTitle,
      render: (rowData) => (
        `${rowData?.contactData.street}, ${rowData?.contactData.houseNumber}${
          rowData?.contactData.zip ? `, ${rowData?.contactData.zip}` : ''
        }`
      )
    },
    {
      title: t('salesReps.hasUsers'),
      sortable: false,
      columnStyles: classes.salesRepTableTitle,
      render: (rowData) => (
        rowData?.users?.length ?
          <span style={{ color: '#089600' }}>{rowData?.users?.length}</span> :
          <span style={{ color: '#FF5100' }}>None</span>
      )
    },

  ];

  const {
    page, rowsPerPage, sortBy, sortOrder
  } = state;

  const hasError = Boolean(salesRepError) || (!salesRepLoading && !salesRep)
    || (salesRep && !salesRep?.clientConnections?.connections.length);

  return (
    <TableLayout
      emptyTableData={hasError}
      emptyTableDataMessage={t('lists.empty list data')}
      tableIsLoading={salesRepLoading}
      title={`${salesRep?.contactData?.name} | ${salesRep?.contactData?.email}`}
      columns={salesRep ? tableColumns : []}
      skeletonType={TABLE_SKELETON_TYPES.LIST}
      page={page}
      rowsPerPage={rowsPerPage}
      totalResults={(salesRep?.clientConnections?.totalResults) || 0}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      sortBy={sortBy}
      sortOrder={sortOrder}
      handleSortRows={handleSortRows}
      backButtonAction={handleBackButtonAction}
      headerAdditionalActions={salesRep && (
        <HeaderActions salesRepId={id} />
      )}
    >
      {salesRep?.clientConnections?.connections?.length > 0 ?
        salesRep?.clientConnections?.connections?.map((rowData) => (
          <DetailsTableRow
            key={rowData._id}
            columns={tableColumns}
            rowData={rowData}
          />
        )) : <EmptyTableRow />}
    </TableLayout>
  );
}

SalesRepDetails.propTypes = {
  id: PropTypes.string,
};

SalesRepDetails.defaultProps = {
  id: ''
};
