import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidationSchemaFunction = () => {
  const { t } = useTranslation();
  const isCondition = name => () =>
    document.getElementsByName(name)[0]?.value?.length > 0;
  const condition = name => ({
    is: isCondition(name),
    then: Yup.number().required(t('orders.field required'))
  });
  const conditionString = name => ({
    is: isCondition(name),
    then: Yup.string().required(t('orders.field required'))
  });

  return Yup.object().shape({
    invoiceFile: Yup.mixed(),
    invoiceNet: Yup.number().when('invoiceFile', condition('invoiceFile')),
    invoiceGross: Yup.number().when('invoiceFile', condition('invoiceFile')),
    invoiceNumber: Yup.string().when('invoiceFile', conditionString('invoiceFile')),
    creditNet: Yup.number().when('creditNote', condition('creditNote')),
    creditGross: Yup.number().when('creditNote', condition('creditNote'))
  });
};
