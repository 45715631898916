import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import MoreIcon from '@material-ui/icons/MoreVertRounded';
import MailRoundedIcon from '@material-ui/icons/MailRounded';

import DeleteNewsletterModal from 'components/shared/DeleteModal';
import EmptyTableField from 'components/shared/EmptyTableField';
import NewsletterStatus from '../NewsletterStatus';
import NewsletterActionMenu from './TableActionMenu';

const useStyles = makeStyles(
  ({ palette, spacing, breakpoints, typography }) => ({
    tableBodyRow: {
      height: 60,
      '&:nth-child(odd)': {
        '& > td': {
          background: palette.background.mutedLight,
        },
      },
      '&:first-child': {
        '& td:first-child': {
          borderTopLeftRadius: 8,
        },
        '& td:last-child': {
          borderTopRightRadius: 8,
        },
      },
      '&:last-child': {
        '& td:first-child': {
          borderBottomLeftRadius: 8,
        },
        '& td:last-child': {
          borderBottomRightRadius: 8,
        },
      },
    },
    tableCell: {
      padding: spacing(1.5),
      maxWidth: 150,
      color: palette.secondary.textBlack,
      borderBottom: 'none',
      [breakpoints.up('lg')]: {
        padding: spacing(1.5, 2),
      },
      '& > p': {
        fontWeight: typography.fontWeightBold,
      },
    },
    newsletterTitle: {
      paddingLeft: spacing(0.5),
      cursor: 'pointer',
      fontWeight: typography.fontWeightBold,
    },
    semiTransparent: {
      opacity: 0.5,
    },
  })
);

function BroadcastTableRow({
  rowData,
  handleDeleteNewsletter,
  handleDuplicateNewsletter,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    _id: newsletterId,
    subject,
    status,
    statistics = {},
    sentAt,
  } = rowData;

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenSelector = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const handleViewNewsletter = () => {
    history.push(`/newsletter/${newsletterId}`);
  };

  const handleViewNewsletterRecipients = () => {
    history.push(`/newsletter/${newsletterId}/recipients`);
  };

  const handleDuplicate = () => {
    handleCloseSelector();
    handleDuplicateNewsletter(newsletterId);
  };

  const handleDelete = () => {
    handleCloseSelector();
    setOpenDeleteModal(false);
    handleDeleteNewsletter(newsletterId);
  };

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell className={classes.tableCell}>
        {subject ? (
          <Grid container wrap="nowrap">
            <MailRoundedIcon fontSize="small" />
            <Typography
              onClick={handleViewNewsletter}
              className={classes.newsletterTitle}
              variant="body2"
            >
              {subject}
            </Typography>
          </Grid>
        ) : (
          <EmptyTableField />
        )}
      </TableCell>
      {(['Send', 'Open', 'Click', 'Bounce']).map((event) => (
        <TableCell className={classes.tableCell} key={event}>
          {typeof statistics?.[event] === 'number' ? (
            <Typography variant="body2">{statistics[event]}</Typography>
          ) : (
            <EmptyTableField className={classes.semiTransparent} />
          )}
        </TableCell>
      ))}
      <TableCell className={classes.tableCell}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          {status ? (
            <NewsletterStatus status={status} sentAt={sentAt} />
          ) : (
            <EmptyTableField />
          )}
          <IconButton onClick={handleOpenSelector}>
            <MoreIcon />
          </IconButton>
          <NewsletterActionMenu
            anchorEl={anchorEl}
            handleCloseSelector={handleCloseSelector}
            status={status}
            handleViewNewsletterRecipients={handleViewNewsletterRecipients}
            handleDeleteNewsletter={() => setOpenDeleteModal(true)}
            handleDuplicateNewsletter={handleDuplicate}
          />
        </Grid>
      </TableCell>

      <DeleteNewsletterModal
        isOpen={openDeleteModal}
        message={t('newsletter.delete newsletter message')}
        handleCloseModal={() => setOpenDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </TableRow>
  );
}

BroadcastTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleDeleteNewsletter: PropTypes.func.isRequired,
  handleDuplicateNewsletter: PropTypes.func.isRequired,
};

export default React.memo(BroadcastTableRow, (prevProps, nextProps) =>
  isEqual(prevProps.rowData, nextProps.rowData)
);
