import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import LoadMoreIcon from '@material-ui/icons/LibraryBooksRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

import { ASSIGN_ACTIONS, NOTIFICATION_STATUS } from 'helpers/constants';
import replaceDelimiters from 'helpers/replaceDelimiters';
import StyledInput from 'components/shared/Inputs/StyledInput';
import { parseDataValidationErrors } from 'helpers/getErrorMessage';
import useMixPanel from 'helpers/useMixPanel';

const DEFAULT_PRODUCT_RESPONSE = {
  totalResults: 0,
  products: [],
};

export default function ProductAttributeAssignment({
  isNewAttribute,
  attributeState,
  sharedClasses,
  assignProductAttribute,
  unassignProductAttribute,
  handleSetProducts,
  handleCreateNewAttribute,
  handleLoadMoreProducts,
}) {
  const { t } = useTranslation();
  const mixPanel = useMixPanel();

  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [inputValue, setInputValue] = React.useState('');

  const { hasNextPage, page, totalPages } = attributeState?.products || {};

  const handleChangeInput = event => {
    setInputValue(event.target.value);
  };

  const handleUpdateProductAttributeAssignment = action => async () => {
    const isValidInput = !/[~`!#$%\\^&+=\\[\]\\';/{}|\\":<>\\?]/g.test(
      inputValue
    );
    if (!isValidInput) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.errors.special chars'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    const vendorSpecificIds = replaceDelimiters(inputValue);
    if (!vendorSpecificIds?.length) {
      return;
    }

    if (isNewAttribute) {
      handleCreateNewAttribute({ skipClosingModal: true, vendorSpecificIds });
      setInputValue('');
      return;
    }

    try {
      const { isRequired, _id: attributeId } = attributeState;

      if (action === ASSIGN_ACTIONS.ASSIGN) {
        await assignProductAttribute({
          variables: {
            id: attributeId,
            isRequired,
            vendorSpecificIds,
          },
          update: (_, { data: { assignAttributeToProducts } }) => {
            const products =
              assignAttributeToProducts?.products || DEFAULT_PRODUCT_RESPONSE;
            handleSetProducts(products);

            mixPanel.track('Attributes - Assignment -> Add Products', {
              attributeId,
              isRequired,
              vendorSpecificIds,
            });
          },
        });
      } else {
        await unassignProductAttribute({
          variables: {
            id: attributeId,
            vendorSpecificIds,
          },
          update: (_, { data: { unassignAttributeFromProducts } }) => {
            const products =
              unassignAttributeFromProducts?.products ||
              DEFAULT_PRODUCT_RESPONSE;
            handleSetProducts(products);

            mixPanel.track('Attributes - Assignment -> Remove Products', {
              attributeId,
              vendorSpecificIds,
            });
          },
        });
      }
      setInputValue('');
    } catch (err) {
      const message = parseDataValidationErrors(err.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleUnassignSingleProduct = productId => async () => {
    try {
      const { _id: attributeId } = attributeState;
      await unassignProductAttribute({
        variables: {
          id: attributeId,
          productIds: [productId],
        },
        update: (_, { data: { unassignAttributeFromProducts } }) => {
          const products =
            unassignAttributeFromProducts?.products || DEFAULT_PRODUCT_RESPONSE;
          handleSetProducts(products);

          mixPanel.track('Attributes - Assignment -> Remove Single Product', {
            attributeId,
            productId,
          });
        },
      });

      setInputValue('');
    } catch (err) {
      const message = parseDataValidationErrors(err.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleFetchMore = () => {
    handleLoadMoreProducts({
      productAttributeId: attributeState._id,
      productsAfter: page + 1,
    });
  };

  const displayLoadMoreButton =
    hasNextPage && page < totalPages && !isNewAttribute;

  return (
    <Grid
      className={sharedClasses.attributeOptionsContainer}
      container
      direction="column"
      wrap="nowrap"
      item
      xs={12}
    >
      <Typography variant="h6" align="left">
        {t('product attributes.assigned products')}
      </Typography>
      <Typography variant="body2" align="left" paragraph>
        {t('product attributes.assigned products caption')}
      </Typography>
      <Grid
        className={sharedClasses.attributeOptionInputContainer}
        container
        item
        xs={12}
        wrap="nowrap"
      >
        <Grid container item>
          <StyledInput
            variant="outlined"
            fullWidth
            value={inputValue}
            placeholder={t('product attributes.assign products placeholder')}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid container item xs={6} wrap="nowrap" justifyContent="flex-end">
          <Button
            style={{ height: 42, margin: '0 4px 0 8px' }}
            onClick={handleUpdateProductAttributeAssignment(
              ASSIGN_ACTIONS.ASSIGN
            )}
            variant="outlined"
            color="primary"
            endIcon={<AddCircleRoundedIcon />}
            disabled={!inputValue}
          >
            {t('product attributes.assign')}
          </Button>
          <Button
            style={{ height: 42, margin: '0 4px 0 8px' }}
            onClick={handleUpdateProductAttributeAssignment(
              ASSIGN_ACTIONS.UNASSIGN
            )}
            variant="outlined"
            color="secondary"
            endIcon={<RemoveCircleOutlineRoundedIcon />}
            disabled={!inputValue || isNewAttribute}
          >
            {t('product attributes.remove')}
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} wrap="nowrap" direction="column">
        {attributeState?.products?.products?.map(product => (
          <Grid
            key={product._id}
            className={sharedClasses.attributeOption}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              {product.vendorSpecificId} | {product.name}
            </Typography>
            <IconButton
              onClick={handleUnassignSingleProduct(product._id)}
              size="small"
            >
              <DeleteRoundedIcon />
            </IconButton>
          </Grid>
        ))}
        {displayLoadMoreButton && (
          <Grid container alignItems="center" justifyContent="center">
            <Button
              onClick={handleFetchMore}
              variant="outlined"
              endIcon={<LoadMoreIcon />}
            >
              {t('common.load more')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

ProductAttributeAssignment.defaultProps = {
  isNewAttribute: false,
};

ProductAttributeAssignment.propTypes = {
  isNewAttribute: PropTypes.bool,
  sharedClasses: PropTypes.object.isRequired,
  attributeState: PropTypes.object.isRequired,
  assignProductAttribute: PropTypes.func.isRequired,
  unassignProductAttribute: PropTypes.func.isRequired,
  handleSetProducts: PropTypes.func.isRequired,
  handleCreateNewAttribute: PropTypes.func.isRequired,
  handleLoadMoreProducts: PropTypes.func.isRequired,
};
