import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { Alert } from '@material-ui/lab';
import { NOTIFICATION_STATUS } from '../../helpers/constants';
import { SET_NOTIFICATION } from '../../graphql/mutation/user';
import { DatePicker } from '../shared/DatePicker';
import SimpleTextInput from '../shared/Inputs/SimpleTextInput';
import { CREATE_SPECIAL_DAY, UPDATE_SPECIAL_DAY } from '../../graphql/mutation/specialDays';
import Transition from '../shared/Transition';

const SPECIAL_DAY_INITIAL_STATE = {
  _id: undefined,
  applyForDate: [new Date()],
  enabled: false,
  type: null,
  comment: '',
};

function AddOrEditDeliveryDayDialog({ classes, isOpen, onClose, type, onUpdate, editData }) {
  const { t } = useTranslation();
  const [data, setData] = React.useState(editData || SPECIAL_DAY_INITIAL_STATE);
  const [isDateRange, setDateRangeMode] = React.useState(data.applyForDate[1]);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [createDeliveryDay] = useMutation(CREATE_SPECIAL_DAY, {
    onCompleted: () => {
      onClose();
    },
  });

  const [updateDeliveryDay] = useMutation(UPDATE_SPECIAL_DAY, {
    onCompleted: () => {
      onClose();
    },
  });

  useEffect(() => {
    setData({ ...data, type: editData?.type || type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, type]);

  const handleSubmit = async () => {
    try {
      if (data._id) {
        await updateDeliveryDay({
          variables: {
            dayId: data._id,
            day: {
              ...data,
              _id: undefined,
            },
          }
        });
        setNotification({
          variables: {
            timeout: 4000,
            message: t('specialDays.Day updated'),
            type: NOTIFICATION_STATUS.SUCCESS,
            isOpen: true,
          },
        });
      } else {
        await createDeliveryDay({ variables: { day: data } });
        setNotification({
          variables: {
            timeout: 4000,
            message: t('specialDays.Day added'),
            type: NOTIFICATION_STATUS.SUCCESS,
            isOpen: true,
          },
        });
      }
      onUpdate();
      onClose();
    } catch (err) {
      setNotification({
        variables: {
          timeout: 4000,
          message:
            err?.message ||
            t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.modalContainer }}
      BackdropProps={{ classes: { root: classes.modalBackdrop } }}
      open={isOpen}
      TransitionComponent={Transition}
      aria-labelledby="status-modal"
      aria-describedby="status-modal-description"
      onClose={onClose}
    >
      <DialogTitle className={classes.modalTitle} id="status-modal">
        {t('specialDays.Add new day')}
      </DialogTitle>
      <DialogContent className={classes.modalMessageContainer}>
        <Alert severity="info" className={classes.notificationBlock}>
          {t('specialDays.Add new day message')}
        </Alert>
        <Box className={classes.flexHorizontal}>
          <Typography>
            {t('specialDays.enabled')}:
          </Typography>
          <Switch
            checked={data.enabled}
            onChange={(e) => setData({ ...data, enabled: e.target.checked })}
            color="primary"
          />
          <Typography>
            {t('specialDays.dateRange')}:
          </Typography>
          <Switch
            label="Range"
            checked={isDateRange}
            onChange={(e) => {
              if (e.target.checked) {
                setData({
                  ...data,
                  applyForDate: [data.applyForDate[0], moment(data.applyForDate[0]).add(1, 'day')],
                });
              } else {
                setData({ ...data, applyForDate: [data.applyForDate[0]] });
              }
              setDateRangeMode(e.target.checked);
            }}
            color="primary"
          />
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.flexHorizontal}>
          <Box>
            <Typography>{t('specialDays.startDate')}</Typography>
            <DatePicker
              desktopView
              variant="inline"
              maxDate={moment(data.applyForDate[0]).add(12, 'M').endOf('month')}
              handleDateChange={(date) => {
                setData({ ...data, applyForDate: [date.format('YYYY-MM-DD'), data.applyForDate[1]] });
              }}
              selectedDate={data.applyForDate[0]}
              setIsOpen={() => {
              }}
            />
          </Box>
          {isDateRange && (
            <Box ml={2}>
              <Typography>{t('specialDays.endDate')}</Typography>
              <DatePicker
                desktopView
                variant="inline"
                handleDateChange={(date) => {
                  setData({ ...data, applyForDate: [data.applyForDate[0], date.format('YYYY-MM-DD')] });
                }}
                minDate={moment(data.applyForDate[0]).add(1, 'day')}
                maxDate={moment(data.applyForDate[0]).add(12, 'M').endOf('month')}
                selectedDate={data.applyForDate[1] || moment(data.applyForDate[0]).add(1, 'day')}
                setIsOpen={() => {
                }}
              />
            </Box>
          )}
          <Box width="100%" ml={2} minWidth={250}>
            <Typography>{t('specialDays.addComment')}</Typography>
            <SimpleTextInput
              type="textarea"
              id="new-date-comment"
              name={t('specialDays.Comment')}
              multiline
              style={{ width: '100%', height: '100%' }}
              value={data.comment}
              handleChange={(e) => setData({ ...data, comment: e.target.value })}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        <Button
          onClick={onClose}
          color="primary"
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddOrEditDeliveryDayDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

AddOrEditDeliveryDayDialog.defaultProps = {
  editData: null,
};

export default AddOrEditDeliveryDayDialog;
