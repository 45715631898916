import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { ReactComponent as NoResultsLightThemeImage } from 'assets/noResults.svg';
import { ReactComponent as NoResultsDarkThemeImage } from 'assets/noResultsDark.svg';

const useStyles = makeStyles(theme => ({
  noResultContainer: {
    height: 560
  },
  image: {
    width: 380,
    height: 280,
    marginTop: theme.spacing(10)
  },
  errorMessage: {
    width: 440
  }
}));

export default function EmptyTableData({ noResultMessage, tableIsLoading }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell colSpan="12">
        <Grid
          container
          className={classes.noResultContainer}
          direction="column"
          wrap="nowrap"
          alignItems="center"
          justifyContent="center"
        >
          {noResultMessage && !tableIsLoading && (
            <Typography
              className={classes.errorMessage}
              variant="h6"
              color="textSecondary"
              align="center"
            >
              {noResultMessage}
            </Typography>
          )}
          {theme?.palette?.type === 'dark' ?
            <NoResultsDarkThemeImage className={classes.image} />
            : <NoResultsLightThemeImage className={classes.image} />}
        </Grid>
      </TableCell>
    </TableRow>
  );
}

EmptyTableData.propTypes = {
  noResultMessage: PropTypes.string,
  tableIsLoading: PropTypes.bool,
};

EmptyTableData.defaultProps = {
  noResultMessage: '',
  tableIsLoading: false
};
