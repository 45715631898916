import React from 'react';
import PropTypes from 'prop-types';

import TableRow from '@material-ui/core/TableRow';

export default function EmptyTableRow({ customHeight }) {
  return <TableRow style={{ height: customHeight || 500 }} />;
}

EmptyTableRow.defaultProps = {
  customHeight: 500,
};

EmptyTableRow.propTypes = {
  customHeight: PropTypes.number,
};
