import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { CREATE_NEW_DEAL } from 'graphql/mutation/deals';
import { GET_DEALS } from 'graphql/query/deals';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import DateIcon from '@material-ui/icons/Event';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(theme => ({
  modalText: {
    width: 355,
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(1),
  },
  optionalMessage: {
    fontWeight: theme.typography.fontWeightLight,
    fontStyle: 'Italic',
    marginLeft: theme.spacing(0.5),
  },
  actionButton: {
    height: 50,
    marginTop: theme.spacing(4.5)
  },
  textInput: {
    width: '100%',
    border: theme.palette.border.lightGrey,
    borderRadius: 4,
    margin: `${theme.spacing(1)}px 0px`
  },
  input: {
    padding: `${theme.spacing(4.75)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  formControl: {
    top: 16
  }
}));

const DEFAULT_DEAL_STATE = {
  ids: '',
  startDate: moment().utc().toISOString(),
  endDate: moment().utc().toISOString()
};

export default function AddDealModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();
  const [createNewDeal] = useMutation(CREATE_NEW_DEAL);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [newDeal, setNewDeal] = React.useState({ ...DEFAULT_DEAL_STATE });

  const {
    isOpen,
    handleClose,
    page,
    rowsPerPage,
    sortBy,
    sortOrder
  } = props;

  const replaceDelimiters = (input) =>
    input.replace(/\s/g, ',').split(',').filter(value => value !== '');

  const handleCreateNewDeal = async () => {
    const { startDate, endDate, ids } = newDeal;
    if (moment(startDate).isAfter(endDate)) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('deals.date error'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    const isValidInput = !/[~`!#$%\\^&+=\\[\]\\';/{}|\\":<>\\?]/g.test(ids);
    if (!isValidInput) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('common.errors.special chars'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      return;
    }

    const products = replaceDelimiters(ids).map(id =>
      ({
        productId: id,
        start: startDate,
        end: endDate
      }));

    if (!products.length) {
      return;
    }

    try {
      await createNewDeal({
        variables: {
          products
        },
        refetchQueries: [{
          query: GET_DEALS,
          variables: {
            after: page + 1,
            pageSize: rowsPerPage,
            sortBy,
            sortOrder,
          }
        }],
        update: (_, { data: { addDeals } }) => {
          if (addDeals.notUpdated.length) {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('deals.add deal error', {
                  products: addDeals.notUpdated.join(', '), count: addDeals.notUpdated.length
                }),
                type: NOTIFICATION_STATUS.ALERT,
                isOpen: true,
              },
            });
          } else {
            setNotification({
              variables: {
                timeout: 4000,
                message: t('deals.add deal success'),
                type: NOTIFICATION_STATUS.SUCCESS,
                isOpen: true,
              },
            });
          }
        }
      });
      mixPanel.track('Create Deal', {
        products: products.map(product => product.productId).toString()
      });
      setNewDeal({ ...DEFAULT_DEAL_STATE });
    } catch (error) {
      console.error(error.message);
    }
    handleClose();
  };

  const handleChangeDate = (date, label) => {
    setNewDeal({ ...newDeal, [label]: moment(date).utc().startOf('day').toISOString() });
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      modalTitle={t('deals.add deal title')}
    >

      <TextField
        label={t('deals.inputHelperText')}
        variant="outlined"
        className={classes.textInput}
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.formControl
          }
        }}
        value={newDeal.ids}
        onChange={event => setNewDeal({ ...newDeal, ids: event.target.value })}
      />
      <Typography className={classes.modalText}>
        {t('deals.helperModalText')}
        <span className={classes.optionalMessage}>(Optional)</span>
      </Typography>
      <DatePicker
        variant="inline"
        inputVariant="outlined"
        value={newDeal.startDate}
        label={t('deals.start')}
        onChange={(date) => handleChangeDate(date, 'startDate')}
        autoOk
        format="DD-MM-YYYY"
        minDate={moment().subtract(1, 'year')}
        maxDate={moment().add(2, 'year')}
        minDateMessage={null}
        className={classes.textInput}
        InputProps={{
          classes: {
            input: classes.input,
          },
          endAdornment: (
            <InputAdornment position="end">
              <DateIcon fontSize="small" />
            </InputAdornment>
          )
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.formControl
          }
        }}
      />
      <DatePicker
        variant="inline"
        inputVariant="outlined"
        value={newDeal.endDate}
        label={t('deals.end')}
        onChange={(date) => handleChangeDate(date, 'endDate')}
        autoOk
        format="DD-MM-YYYY"
        minDate={newDeal.startDate}
        maxDate={moment().add(2, 'year')}
        minDateMessage={null}
        className={classes.textInput}
        InputProps={{
          classes: {
            input: classes.input,
          },
          endAdornment: (
            <InputAdornment position="end">
              <DateIcon fontSize="small" />
            </InputAdornment>
          )
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.formControl
          }
        }}
      />
      <Button
        className={classes.actionButton}
        fullWidth
        onClick={handleCreateNewDeal}
        variant="contained"
        color="primary"
      >
        {t('deals.submit')}
      </Button>
    </ModalLayout>
  );
}

AddDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
};
