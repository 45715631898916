import { gql } from '@apollo/client';
import { GROUP_FRAGMENT } from 'graphql/fragments';

export const GET_CUSTOMER_GROUPS = gql`
  ${GROUP_FRAGMENT}
  query getCustomerGroups {
    allCustomerGroups {
      ...GroupFragment
    }
  }
`;

export const SEARCH_CUSTOMER_GROUPS = gql`
  ${GROUP_FRAGMENT}
  query searchCustomerGroups(
    $searchPhrase: String
    $includeGenericGroups: Boolean = true
  ) {
    searchCustomerGroups(
      searchPhrase: $searchPhrase
      includeGenericGroups: $includeGenericGroups
    ) {
      ...GroupFragment
    }
  }
`;

export const GET_VENDOR_CUSTOMER_GROUPS = gql`
  query getAllVendorCustomerGroups {
    getAllVendorCustomerGroups
  }
`;

export const GET_CUSTOM_VENDOR_GROUP_BY_ID = gql`
  ${GROUP_FRAGMENT}
  query getCustomGroupById($_id: ID!) {
    getCustomGroupById(_id: $_id) {
      ...GroupFragment
    }
  }
`;

export const GET_CUSTOM_VENDOR_GROUPS = gql`
  ${GROUP_FRAGMENT}
  query getCustomVendorGroups($pageSize: Int, $after: Int, $withAssignedCustomers: Boolean = false) {
    getCustomVendorGroups(pageSize: $pageSize, after: $after, withAssignedCustomers: $withAssignedCustomers) {
      page
      hasNextPage
      totalPages
      nextPage
      totalResults
      groups {
        ...GroupFragment
        customers {
          _id
          groupId
          totalCustomers
          assignedCustomers {
            _id
            vendorId
            vendorClientId
            businessName
          }
        }
      }
    }
  }
`;

export const GET_CUSTOM_VENDOR_GROUP_CONNECTION_BY_ID = gql`
  query getCustomGroupConnectionById($_id: ID!, $pageSize: Int, $after: Int) {
    getCustomGroupConnectionById(
      _id: $_id
      pageSize: $pageSize
      after: $after
    ) {
      customers {
        _id
        clientId
        vendorClientId
        vendorId
        groups
        contactData {
          businessName
        }
      }
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
    }
  }
`;

export const SEARCH_CUSTOMERS_IN_CUSTOM_VENDOR_GROUP = gql`
  query searchCustomersInCustomVendorGroup(
    $groupId: ID!
    $searchPhrase: String
    $pageSize: Int
    $after: Int
  ) {
    searchCustomersInCustomVendorGroup(
      groupId: $groupId
      searchPhrase: $searchPhrase
      pageSize: $pageSize
      after: $after
    ) {
      customers {
        _id
        clientId
        vendorClientId
        vendorId
        groups
        contactData {
          businessName
        }
      }
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
    }
  }
`;
