import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { useMutation } from '@apollo/client';
import { GET_VENDOR_CUSTOMERS } from 'graphql/query/vendors';
import { SEND_VENDOR_INVITATION } from 'graphql/mutation/customer';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';

import DeliveryDays from 'components/CustomerTable/DeliveryDays';
import CustomerStatus from 'components/CustomerTable/CustomerStatus';
import CustomerGroup from 'components/CustomerTable/CustomerGroup';
import CustomerLists from 'components/CustomerTable/CustomerLists';
// import CustomerPricing from 'components/CustomerTable/CustomerPricing';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles((theme) => ({
  creationDateTitle: {
    color: theme.palette.secondary.darkGray,
  },
  connectionColumn: {
    width: 340,
  },
  tableCell: {
    padding: theme.spacing(0, 1.5),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 140,
    borderBottom: 'none',
  },
}));

export default function CustomerTableRowCells({
  tableData,
  allCustomerGroups,
  tableErrors,
  isEditMode,
  setFieldValue,
  handleOpenNewListEditor,
  handleCreateNewList,
  isNewCustomer,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [customerInvitationMutation] = useMutation(SEND_VENDOR_INVITATION);
  const mixPanel = useMixPanel();

  const handleSendInvite = async (notify = true) => {
    if (
      !tableData.lists.length ||
      tableData.deliveryDays.every((day) => !day.enabled)
    ) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('customers.invitation message'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    } else {
      try {
        await customerInvitationMutation({
          variables: {
            connectionIds: [tableData._id],
            notify,
          },
          update: (store, { data: { inviteCustomers } }) => {
            const invitedCustomer = inviteCustomers[0];
            const allCustomers = store.readQuery({
              query: GET_VENDOR_CUSTOMERS,
            });
            store.writeQuery({
              query: GET_VENDOR_CUSTOMERS,
              data: {
                vendorCustomers: {
                  ...allCustomers.vendorCustomers,
                  customers: allCustomers.vendorCustomers.customers.map(
                    (customer) =>
                      (invitedCustomer && customer._id === invitedCustomer._id
                        ? { ...invitedCustomer }
                        : customer)
                  ),
                },
              },
            });
            mixPanel.track('Invite Customer', {
              businessName: invitedCustomer?.contactData?.businessName,
              customerId: tableData._id,
              vendorId: invitedCustomer?.vendorId,
              vendorClientId: invitedCustomer?.vendorClientId,
              ccEmails: invitedCustomer?.ccEmails,
              contactsEmails: invitedCustomer?.contacts?.map(
                (item) => item?.email
              ),
            });
            if (invitedCustomer) {
              setNotification({
                variables: {
                  timeout: 5000,
                  // eslint-disable-next-line max-len
                  message: `${invitedCustomer.contactData.businessName} ${t(
                    `customers.status notification.${invitedCustomer.status}`
                  )}`,
                  type: NOTIFICATION_STATUS.SUCCESS,
                  isOpen: true,
                },
              });
            }
          },
        });
      } catch (error) {
        console.error(error.message);
        setNotification({
          variables: {
            timeout: 5000,
            message: t('common.something wrong'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }
  };

  return (
    <>
      <TableCell className={classes.tableCell}>
        {tableData.vendorClientId}
      </TableCell>
      <TableCell
        className={classNames(classes.tableCell, classes.connectionColumn)}
      >
        <Typography variant="body2" noWrap>
          {tableData.contactData.businessName}
        </Typography>
        <Typography variant="body2" className={classes.creationDateTitle}>
          {`${t('common.created')}: ${moment(tableData.createdAt).format(
            'DD/MM/YYYY'
          )}`}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <CustomerLists
          customerId={tableData._id}
          lists={tableData.lists}
          isEditMode={isEditMode}
          isNewCustomer={isNewCustomer}
          handleOpenNewListEditor={handleOpenNewListEditor}
          handleCreateNewList={handleCreateNewList}
        />
      </TableCell>
      {/* FIXME Temporary hide pricing
      <TableCell className={classes.tableCell}>
        <CustomerPricing isEditMode={isEditMode} />
      </TableCell> */}
      <TableCell className={classes.tableCell}>
        <CustomerStatus
          withList={!!tableData.lists.length}
          withDeliveryDays={tableData.deliveryDays.every((day) => !day.enabled)}
          customerStatus={tableData.status}
          orders={tableData.orders}
          handleSendInvite={handleSendInvite}
          withDropDown
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <CustomerGroup
          customerId={tableData._id}
          customerGroups={tableData.customerGroups}
          setFieldValue={setFieldValue}
          isEditMode={isEditMode}
          allCustomerGroups={allCustomerGroups}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <DeliveryDays
          days={tableData.deliveryDays}
          isEditMode={isEditMode}
          setFieldValue={setFieldValue}
          errors={tableErrors}
        />
      </TableCell>
    </>
  );
}

CustomerTableRowCells.propTypes = {
  tableData: PropTypes.object.isRequired,
  tableErrors: PropTypes.object.isRequired,
  allCustomerGroups: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleOpenNewListEditor: PropTypes.func.isRequired,
  handleCreateNewList: PropTypes.func.isRequired,
  isNewCustomer: PropTypes.bool,
};

CustomerTableRowCells.defaultProps = {
  isNewCustomer: false,
};
