import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { PRODUCTS_TABLE_SECTION } from 'helpers/constants';
import ProductAttributesTable from 'components/ProductsTable/ProductAttributes';
import ProductsTable from 'components/ProductsTable';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    fontFamily: '\'Manrope\', sans-serif',
    fontWeight: '700',
    '& *': {
      fontFamily: 'Manrope, sans-serif',
    }
  },
  tabs: {
    margin: theme.spacing(2, 0),
    '& .Mui-selected': {
      '& .MuiTab-wrapper': {
        backgroundColor: '#0F1217',
        color: '#fff',
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      padding: theme.spacing(0),
      maxWidth: 'none',
      minWidth: 'auto',
      marginRight: theme.spacing(1),
      opacity: '1',
      justifyContent: 'flex-start'
    },
    '& .MuiTab-wrapper': {
      border: '1px solid #C7C9CC',
      borderRadius: '48px',
      padding: theme.spacing(1, 3),
      backgroundColor: '#F6F6F6',
      color: '#0F1217',
      width: 'auto',
    },
  },
}));

export default function Products({ vendorHasFtpIntegration, isAdmin }) {
  const classes = useStyles();

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const { section } = useParams();

  useEffect(() => {
    if (!PRODUCTS_TABLE_SECTION[section]) {
      history.push('/products/products');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  const handleTabChange = (_, newValue) => {
    history.push(newValue);
  };

  return (
    <Grid className={classes.container}>
      <Tabs value={location.pathname} onChange={handleTabChange} className={classes.tabs} >
        <Tab disableTouchRipple label={t('products.title')} value="/products/products" />
        <Tab disableTouchRipple label={t('product attributes.title')} value="/products/attributes" />
      </Tabs>
      {section === PRODUCTS_TABLE_SECTION.attributes ? (
        <ProductAttributesTable />
      ) : (
        <ProductsTable
          vendorHasFtpIntegration={vendorHasFtpIntegration}
          isAdmin={isAdmin}
        />
      )}
    </Grid>
  );
}

Products.propTypes = {
  vendorHasFtpIntegration: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

Products.defaultProps = {
  vendorHasFtpIntegration: false,
  isAdmin: false,
};
