import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import EditCustomers from './EditCustomers';
import EditGroups from './EditGroups';
import { ADD_GROUP_TO_SPECIAL_DAY } from '../../graphql/mutation/specialDays';
import { NOTIFICATION_STATUS } from '../../helpers/constants';
import { SET_NOTIFICATION } from '../../graphql/mutation/user';

function EditDialog({ editModeId, setEditModeId, specialDays, classes, onUpdated }) {
  const [currentEditType, setCurrentEditType] = useState('customers');
  const { t } = useTranslation();
  const [data, setData] = useState({});

  const [toggleSpecialDayGroup] = useMutation(ADD_GROUP_TO_SPECIAL_DAY);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  useEffect(() => {
    if (specialDays) {
      setData({
        ...specialDays,
        customers: [...(specialDays?.customers || [])],
        customerGroups: [...specialDays?.customerGroups]
      });
    }
  }, [specialDays]);

  const toggleGroup = async (groupId) => {
    try {
      await toggleSpecialDayGroup({
        variables: {
          dayId: editModeId,
          groupId,
        },
      });
      await onUpdated();
      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Groups saved'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (e) {
      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Groups was not saved'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={!!editModeId}
      onClose={() => setEditModeId(0)}
    >
      <Grid container className={classes.drawerContainer}>
        <Grid item xs={12}>
          <Tabs
            value={currentEditType}
            onChange={(event, value) => setCurrentEditType(value)}
            className={classes.tabs}
          >
            <Tab label={t('specialDays.customers')} value="customers" />
            <Tab label={t('specialDays.groups')} value="groups" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {currentEditType === 'groups' && (
            <EditGroups
              data={data?.customerGroups?.map((group) => group._id) || []}
              toggleGroup={toggleGroup}
              classes={classes}
            />
          )}
          {currentEditType === 'customers' && (
            <EditCustomers
              dayId={data?._id}
              classes={classes}
              onUpdate={onUpdated}
            />
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
}

EditDialog.propTypes = {
  editModeId: PropTypes.string.isRequired,
  setEditModeId: PropTypes.func.isRequired,
  specialDays: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default EditDialog;
