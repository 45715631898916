import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import { EDIT_CAMPAIGN_RECIPIENT_CONTACT_STATUS } from 'graphql/mutation/campaigns';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/EditOutlined';

import EmptyTableField from 'components/shared/EmptyTableField';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import { EDIT_RECIPIENT_ACTIONS } from './campaignConstants';
import ManageRecipientsTableMultiContactRow from './ManageRecipientsTableMultiContactRow';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  tableBodyRow: {
    height: 56,
    borderTop: palette.border.lightGrey,
  },
  evenTableRow: {
    background: palette.background.default,
  },
  tableCell: {
    padding: spacing(1, 1.5),
    maxWidth: 150,
    color: palette.secondary.textBlack,
    borderBottom: 'none',
    [breakpoints.up('lg')]: {
      padding: spacing(1),
    },
  },
  firstColumn: {
    paddingLeft: spacing(4),
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: spacing(0, 0.5),
    backgroundColor: palette.background.button,
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: palette.secondary.darkGray,
  },
  formLabel: {
    width: 100
  }
}));

function ManageRecipientsTableRow({ rowData, campaignId, rowIndex }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { vendorSpecificId, contactData = {}, contacts } = rowData;

  const [editCampaignRecipientContactStatus, { loading: editStatusLoading }] = useMutation(
    EDIT_CAMPAIGN_RECIPIENT_CONTACT_STATUS
  );
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const handleChangeContactStatus = (email) => async (event) => {
    const isChecked = event.target.checked;
    const actionType = isChecked ?
      EDIT_RECIPIENT_ACTIONS.REMOVE
      : EDIT_RECIPIENT_ACTIONS.ASSIGN;

    try {
      await editCampaignRecipientContactStatus({
        variables: {
          campaignId,
          actionType,
          connectionId: {
            connectionId: rowData._id,
            email,
            disabled: !isChecked,
          },
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: error?.message || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const hasSingleContact = contacts?.length < 2;
  const firstContact = contacts ? contacts[0] : {};
  const isEvenTableRow = rowIndex % 2 === 0;

  return (
    <>
      <TableRow
        className={classNames(
          classes.tableBodyRow,
          isEvenTableRow && classes.evenTableRow
        )}
      >
        <TableCell
          className={classNames(classes.tableCell, classes.firstColumn)}
        >
          {vendorSpecificId ? (
            <Typography variant="body2">{vendorSpecificId}</Typography>
          ) : (
            <EmptyTableField />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {contactData.businessName ? (
            <Typography variant="body2">{contactData.businessName}</Typography>
          ) : (
            <EmptyTableField />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {firstContact.name ? (
            <Typography variant="body2">{firstContact.name}</Typography>
          ) : (
            <EmptyTableField />
          )}
        </TableCell>
        {/* <TableCell className={classes.tableCell}>
        {rowData.lastOrder ? (
          <Typography variant="body2">
            {moment(rowData.lastOrder).from()}
          </Typography>
        ) : (
          <EmptyTableField />
        )}
      </TableCell> */}
        <TableCell className={classes.tableCell}>
          {firstContact.phone ? (
            <Typography variant="body2">{firstContact.phone}</Typography>
          ) : (
            <EmptyTableField />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {firstContact.status ? (
            <Typography variant="body2">{firstContact.status}</Typography>
          ) : (
            <EmptyTableField />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {editStatusLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FormControlLabel
              classes={{ label: classes.formLabel }}
              label={
                firstContact.disabled
                  ? t('common.inactive')
                  : t('common.active')
              }
              control={
                <Switch
                  checked={!firstContact.disabled}
                  onChange={handleChangeContactStatus(firstContact.email)}
                  color="primary"
                />
              }
            />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Grid container wrap="nowrap">
            <Button
              variant="contained"
              classes={{ root: classes.tableActionButton }}
              onClick={() =>
                history.push(`/customers?search=${vendorSpecificId}`)}
            >
              <EditIcon className={classes.tableActionButtonIcon} />
            </Button>
          </Grid>
        </TableCell>
      </TableRow>
      {!hasSingleContact &&
        contacts
          ?.slice(1)
          .map((contact) => (
            <ManageRecipientsTableMultiContactRow
              key={contact._id}
              contact={contact}
              isEvenTableRow={isEvenTableRow}
              isEditLoading={editStatusLoading}
              handleChangeContactStatus={handleChangeContactStatus}
            />
          ))}
    </>
  );
}

ManageRecipientsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  campaignId: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
};

export default React.memo(
  ManageRecipientsTableRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.rowData, nextProps.rowData) &&
    prevProps.rowIndex === nextProps.rowIndex
);
