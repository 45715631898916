import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { useQuery } from '@apollo/client';
import { GET_CAMPAIGN_STATISTIC } from 'graphql/query/campaigns';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ palette, spacing }) => ({
  statisticContainer: {
    height: 45,
  },
  reportFiltersContainer: {
    padding: spacing(0, 4),
  },
  successContainer: {
    background: palette.success.main,
    height: 20,
  },
  failedContainer: {
    background: palette.error.light,
    height: 20,
  },
  statBarContainer: {
    overflow: 'hidden',
    borderRadius: 4,
  },
  filterSelect: {
    height: 45,
    width: '100%',
    marginRight: spacing(2.5),
    border: palette.border.lightGrey,
    borderRadius: 8,
    padding: spacing(0.5, 0),
  },
}));

const getSmsRate = (value, total) => {
  if (value > 0) {
    return `${value}/${total} (${Number((value / total) * 100).toFixed(1)}%)`;
  } else {
    return '0';
  }
};

function CampaignReportStatistic({ campaignId, searchPhrase, handleSearchBar }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = React.useState('');

  const { data: campaignStatData } = useQuery(GET_CAMPAIGN_STATISTIC, {
    variables: { campaignId },
    fetchPolicy: 'cache-and-network'
  });

  const campaignStatistic = campaignStatData?.getCampaignStatistic?.[0];

  const smsSent = campaignStatistic?.sent || 0;
  const smsFailed = campaignStatistic?.failed || 0;
  const smsTotal = smsSent + smsFailed || 0;

  const delayedQuery = useCallback(debounce(() => {
    if (searchPhrase !== searchInput) {
      handleSearchBar(searchInput);
    }
  }, 500), [searchInput]);

  useEffect(() => {
    delayedQuery();

    return delayedQuery.cancel;
  }, [searchInput, delayedQuery]);

  const handleSearchInput = e => {
    const { value } = e.target;
    setSearchInput(value);
  };

  return (
    <Grid
      className={classes.statisticContainer}
      alignItems="center"
      container
      wrap="nowrap"
    >
      <TextField
        value={searchInput}
        onChange={handleSearchInput}
        className={classes.filterSelect}
        disabled={smsTotal === 0}
        fullWidth
        variant="outlined"
        placeholder={t('campaigns.report.search placeholder')}
      />
      {smsTotal > 0 && (
        <Grid
          container
          className={classes.reportFiltersContainer}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="body2">
              {`${t('campaigns.report status.sent')}: ${getSmsRate(
                smsSent,
                smsTotal
              )}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {`${t('campaigns.report status.failed')}: ${getSmsRate(
                smsFailed,
                smsTotal
              )}`}
            </Typography>
          </Grid>
          <Grid
            className={classes.statBarContainer}
            container
            wrap="nowrap"
            direction="row"
          >
            <Grid
              style={{ width: `${Number((smsSent / smsTotal) * 100)}%` }}
              className={classes.successContainer}
            />
            <Grid
              style={{ width: `${Number((smsFailed / smsTotal) * 100)}%` }}
              container
              className={classes.failedContainer}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

CampaignReportStatistic.propTypes = {
  campaignId: PropTypes.string.isRequired,
  searchPhrase: PropTypes.string.isRequired,
  handleSearchBar: PropTypes.func.isRequired,
};

export default React.memo(
  CampaignReportStatistic,
  (prevProps, nextProps) =>
    prevProps.campaignId === nextProps.campaignId &&
    prevProps.searchPhrase === nextProps.searchPhrase
);
