import { gql } from '@apollo/client';

export const CHECK_USER = gql`
  mutation {
    checkUser @client
  }
`;

export const SET_NETWORK_STATUS = gql`
mutation 
  isOnline($isOnline: Boolean!) {
    isOnline(isOnline: $isOnline) @client
  }
`;

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!, $isRememberChecked: Boolean!) {
    login(email: $email, password: $password, isRememberChecked: $isRememberChecked) @client
  }
`;

export const SET_NOTIFICATION = gql`
mutation setNotification(
  $timeout: Int!
  $message: String!
  $type: String!
  $isOpen: Boolean!
  $isManual: Boolean!
  ) {
  setNotification(
    timeout: $timeout
    message: $message
    type: $type
    isOpen: $isOpen
    isManual: $isManual
  ) @client
}
`;

export const CHANGE_IMPERSONATE_ID = gql`
  mutation changeImpersonateId($impersonateId: String!, $name: String!) {
    changeImpersonateId(impersonateId: $impersonateId, name: $name) @client 
  }
`;

export const CHANGE_THEME_TYPE = gql`
  mutation changeTheme($type: String!) {
      changeTheme(type: $type) @client
    }
  `;
