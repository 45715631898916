import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import NewsletterModalLayout from 'components/Newsletter/Constructor/Modals/NewsletterModalLayout';
import NoFromEmailWarning from 'components/Newsletter/Constructor/NoFromEmailWarning';

const useStyles = makeStyles(({ palette, spacing }) => ({
  newsletterSendModalContentContainer: {
    '& *': {
      fontWeight: '700 !important'
    }
  },
  newsletterSendModalRecipients: {
    width: 360,
    height: 190,
    background: palette.background.mutedLight,
    borderRadius: 8,
    marginTop: spacing(3)
  },
  newsletterSendModalNoEmailRecipients: {
    padding: spacing(1.25, 2),
    background: 'rgba(204, 0, 0, 0.04)',
    border: '1px solid rgba(204, 0, 0, 0.16)',
    borderRadius: 8,
    marginTop: spacing(1),
    color: '#CC0000'
  },
  newsletterSendModalNoFromEmail: {
    padding: spacing(1.25, 2),
    border: '1px solid rgba(204, 0, 0, 0.16)',
    borderRadius: 8,
    marginTop: spacing(1),
    color: '#CC0000',
    width: 360
  },
  newsletterSendModalViewNoEmail: {
    padding: spacing(0.75, 2),
    border: '1px solid #CC0000',
    borderRadius: 40
  },
  newsletterSendModalButtonAction: {
    margin: spacing(3, 0),
    borderRadius: 40,
    height: 48,
    boxShadow: 'none'
  },
  newsletterSendModalTestAction: {
    textDecoration: 'underline'
  }
}));

export default function NewsletterSendEmailModal({
  fromEmailField,
  isOpen,
  isLoading,
  handleClose,
  totalRecipients,
  noEmailRecipients,
  handleOpenNoEmailRecipients,
  handleSendNewsletterEmail,
  handleOpenTest
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isDisabled = !totalRecipients || !fromEmailField || isLoading;

  const handleOnKeyUp = e => {
    if (e.key === 'Enter' && !isDisabled) {
      handleSendNewsletterEmail();
    }
  };

  return (
    <NewsletterModalLayout
      isOpen={isOpen}
      handleClose={handleClose}
      onKeyUp={handleOnKeyUp}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.newsletterSendModalContentContainer}
      >
        <Typography variant="h4" gutterBottom>
          {t('newsletter.confirming')}!
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('newsletter.send email warning')}
        </Typography>

        <Grid
          container
          className={classes.newsletterSendModalRecipients}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h2" paragraph>
            {totalRecipients}
          </Typography>
          <Typography variant="body2">
            {t('newsletter.recipients title')}
          </Typography>
        </Grid>

        {!!noEmailRecipients && (
          <Grid
            container
            className={classes.newsletterSendModalNoEmailRecipients}
            alignItems="center"
            justifyContent="space-around"
            wrap="nowrap"
          >
            <Typography variant="body2" color="inherit">
              {`${noEmailRecipients} ${t('newsletter.no emails warning')}`}
            </Typography>
            <Button
              className={classes.newsletterSendModalViewNoEmail}
              color="inherit"
              onClick={handleOpenNoEmailRecipients}
            >
              {t('newsletter.view')}
            </Button>
          </Grid>
        )}

        {!fromEmailField && (
          <NoFromEmailWarning />
        )}

        <Button
          className={classes.newsletterSendModalButtonAction}
          color="primary"
          variant="contained"
          disabled={isDisabled}
          onClick={handleSendNewsletterEmail}
          fullWidth
        >
          {t('newsletter.send now')}
        </Button>
        <Button
          className={classes.newsletterSendModalTestAction}
          onClick={handleOpenTest}
          variant="text"
        >
          {t('newsletter.send test first')}
        </Button>
      </Grid>
    </NewsletterModalLayout>
  );
}

NewsletterSendEmailModal.propTypes = {
  fromEmailField: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  totalRecipients: PropTypes.number.isRequired,
  noEmailRecipients: PropTypes.number.isRequired,
  handleOpenNoEmailRecipients: PropTypes.func.isRequired,
  handleSendNewsletterEmail: PropTypes.func.isRequired,
  handleOpenTest: PropTypes.func.isRequired,
};
