/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { GET_VENDOR_NEWSLETTER_BY_ID } from 'graphql/query/newsletters';
import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';

import {
  NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS,
  RECIPIENTS_FILTER_OPTIONS,
  DRAFT_NEWSLETTER_RECIPIENT_FILTER_OPTIONS,
  SENT_NEWSLETTER_RECIPIENTS_FILTER_OPTIONS,
} from 'components/Newsletter/localConstantsAndHelpers';
import RecipientAssignmentSection from 'components/Newsletter/Recipients/RecipientsTableActions/AssignmentSection';
import RecipientsTableFilter from 'components/Newsletter/Recipients/RecipientsTableActions/Filter';
import RecipientsTableSearch from 'components/Newsletter/Recipients/RecipientsTableActions/Search';
import useMixPanel from 'helpers/useMixPanel';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  recipientsTableActionsContainer: {
    [breakpoints.up('xl')]: {
      maxWidth: 1720,
    },
  },
  recipientsTableManageContainer: {
    margin: spacing(2, 0),
  },
  button: {
    borderRadius: 40,
    color: palette.text.primary,
    background: palette.background.paper,
    marginRight: spacing(1.5),
    transition: 'all 0.25s ease-in',
  },
  activeButton: {
    color: palette.background.paper,
    background: palette.text.primary,
    '&:hover': {
      color: palette.background.paper,
      background: palette.text.primary,
    },
  },
}));

function RecipientsTableActions({
  state,
  newsletterId,
  previousRoute,
  setState,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();

  const { filter, search, recipientAssignmentStatus } = state;

  const { data: newsletterResponseData } = useQuery(
    GET_VENDOR_NEWSLETTER_BY_ID,
    {
      variables: { id: newsletterId },
    }
  );

  const handleBackToNewsletters = () => {
    if (previousRoute) {
      history.push(previousRoute);
    } else {
      history.push('/newsletters');
    }
  };

  const handleChangeFilter = event => {
    const newFilter = event.target.value;
    setState(prevState => ({
      ...prevState,
      filter: newFilter,
    }));

    mixPanel.track('Newsletter - Recipients -> Change Filter', {
      newsletterId,
      search,
      recipientAssignmentStatus,
      filter: newFilter,
    });
    history.push(
      `${location.pathname}?assignmentStatus=${recipientAssignmentStatus}&rowsPerPage=${state.rowsPerPage}&filter=${newFilter}`
    );
  };

  const handleRecipientAssignmentTableStatus = status => async () => {
    setState(prevState => ({
      ...prevState,
      recipientAssignmentStatus: status,
      page: 0,
    }));

    mixPanel.track('Newsletter - Recipients -> Change Assignment View', {
      newsletterId,
      search,
      recipientAssignmentStatus: status,
      filter,
    });
    history.push(
      `${location.pathname}?assignmentStatus=${status}&rowsPerPage=${state.rowsPerPage}&filter=${filter}`
    );
  };

  const handleInputChange = input => {
    if (input === search) return;
    setState(prevState => ({
      ...prevState,
      search: input,
    }));
  };

  const newsletterData = newsletterResponseData?.getNewsletter || {};
  const isSentStatus =
    newsletterData?.status === RECIPIENTS_FILTER_OPTIONS.SENT;

  return (
    <Grid
      className={classes.recipientsTableActionsContainer}
      container
      direction="column"
      wrap="nowrap"
    >
      <Grid>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBackToNewsletters}>
          {t('common.back')}
        </Button>
      </Grid>

      <Grid container className={classes.recipientsTableManageContainer}>
        <Grid container item xs={7}>
          <Button
            className={classNames(
              classes.button,
              recipientAssignmentStatus ===
                NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED &&
                classes.activeButton
            )}
            variant="outlined"
            onClick={handleRecipientAssignmentTableStatus(
              NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.ASSIGNED
            )}
          >
            {t('newsletter.recipients title')}
          </Button>
          <Button
            className={classNames(
              classes.button,
              recipientAssignmentStatus ===
                NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.EXCLUDED &&
                classes.activeButton
            )}
            variant="outlined"
            onClick={handleRecipientAssignmentTableStatus(
              NEWSLETTER_RECIPIENT_ASSIGNMENT_STATUS.EXCLUDED
            )}
          >
            {t('newsletter.excluded users option')}
          </Button>
        </Grid>

        <Grid container item xs={5} wrap="nowrap">
          <RecipientsTableFilter
            activeFilter={filter}
            handleChangeFilter={handleChangeFilter}
            filters={isSentStatus ?
              Object.values(SENT_NEWSLETTER_RECIPIENTS_FILTER_OPTIONS)
              : Object.values(DRAFT_NEWSLETTER_RECIPIENT_FILTER_OPTIONS)}
          />
          <RecipientsTableSearch
            search={search}
            handleInputChange={handleInputChange}
          />
        </Grid>
      </Grid>

      {!isSentStatus && (
        <Grid container>
          <RecipientAssignmentSection
            state={state}
            recipientAssignmentStatus={recipientAssignmentStatus}
            newsletterId={newsletterId}
          />
        </Grid>
      )}
    </Grid>
  );
}

RecipientsTableActions.propTypes = {
  state: PropTypes.object.isRequired,
  newsletterId: PropTypes.string.isRequired,
  previousRoute: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};

export default memo(
  RecipientsTableActions,
  (prevProps, nextProps) =>
    isEqual(prevProps.newsletterId, nextProps.newsletterId) &&
    prevProps.state?.filter === nextProps.state?.filter &&
    prevProps.state?.search === nextProps.state?.search &&
    prevProps.state?.recipientAssignmentStatus ===
      nextProps.state?.recipientAssignmentStatus &&
    prevProps.previousRoute === nextProps.previousRoute
);
