import { gql } from '@apollo/client';
import { NEWSLETTER_FRAGMENT } from 'graphql/fragments';

export const CREATE_NEWSLETTER = gql`
  ${NEWSLETTER_FRAGMENT}
  mutation createNewsletter($template: NewsletterCreateInput!) {
    createNewsletter(template: $template) {
      ...NewsletterFragment
    }
  }
`;

export const UPDATE_NEWSLETTER = gql`
  ${NEWSLETTER_FRAGMENT}
  mutation updateNewsletter($id: ID!, $template: NewsletterUpdateInput!) {
    updateNewsletter(_id: $id, template: $template) {
      ...NewsletterFragment
    }
  }
`;

export const CLONE_NEWSLETTER = gql`
  ${NEWSLETTER_FRAGMENT}
  mutation cloneNewsletter($id: ID!) {
    cloneNewsletter(_id: $id) {
      ...NewsletterFragment
    }
  }
`;

export const DELETE_NEWSLETTER = gql`
  mutation deleteNewsletter($id: ID!) {
    deleteNewsletter(_id: $id) {
      _id
      title
      templateType
      status
      subject
      fromName
      fromEmail
      staticInfo {
        workingHours
        workingHoursCaption
        phone
        email
        disclaimer
      }
    }
  }
`;

export const UPDATE_NEWSLETTER_RECIPIENTS = gql`
  mutation updateNewsletterRecipients(
    $id: ID!
    $recipients: NewsletterRecipientInput!
    $action: UpdateNewsletterRecipientAction!
  ) {
    updateNewsletterRecipients(
      _id: $id
      recipients: $recipients
      action: $action
    ) {
    newsletter {
      _id
      title
      status
      subject
      fromName
      fromEmail
      assignedToAll
      assignedGroups {
        _id
        name
        color
        vendorSpecificId
        vendorId
        translations {
          lang
          value
        }
      }
      excludedGroups {
        _id
        name
        color
        vendorSpecificId
        vendorId
      }
      recipientCount
      excludedRecipientCount
    }
    statistics {
      added {
        groups
        customers
        emails
      }
      excluded {
        groups
        customers
        emails
      }
      deleted {
        groups
        customers
        emails
      }
      failed {
        groups
        customers
        emails
      }
      assignedToAll
      messages
    }
  }
}
`;

export const SEND_NEWSLETTER = gql`
  mutation sendNewsletter(
    $id: ID!
    $isTestSend: Boolean
    $testEmails: [String!]
  ) {
    sendNewsletter(_id: $id, isTestSend: $isTestSend, testEmails: $testEmails) {
      _id
      title
      status
      sentAt
    }
  }
`;
