/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const GET_LISTS = gql`
  query lists(
    $pageSize: Int
    $after: Int
    $sortBy: ListSortingOption
    $sortOrder: Int
  ) {
    vendorLists(
      after: $after
      pageSize: $pageSize
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) @connection(key: "vendorList", filter: ["_id"]) {
      lists {
        _id
        title
        owner
        products {
          totalResults
        }
        createdAt
        exclusiveClientId
        autoGenerated
        assignedToAll
        assignedToCustomers
        assignedToGroups {
          _id
          name
          color
          translations {
            lang
            value
          }
        }
      }
      page
      totalPages
      nextPage
      hasNextPage
      hasPrevPage
      prevPage
      totalResults
    }
  }
`;

export const GET_VENDOR_LIST = gql`
  query list($_id: ID!, $pageSize: Int, $after: Int) {
    vendorList(_id: $_id, after: $after, pageSize: $pageSize)
      @connection(key: "vendorList", filter: ["_id"]) {
      _id
      title
      assignedToAll
      assignedToGroups {
        _id
        name
        color
        translations {
          lang
          value
        }
      }
      products {
        totalResults
        page
        totalPages
        hasNextPage
        nextPage
        hasPrevPage
        products {
          _id
          name
          description
          vendorId
          vendorSpecificId
          enabled
          image
          categories
          variants {
            _id
            packaging
          }
        }
      }
      createdAt
    }
  }
`;
