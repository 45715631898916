import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PencilIcon from '@material-ui/icons/EditOutlined';
import TrashIcon from '@material-ui/icons/DeleteForeverOutlined';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { IconButton, Switch } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import ActionButton from '../shared/Buttons/ActionButton';
import DateRange from './DateRange';
import { NOTIFICATION_STATUS } from '../../helpers/constants';
import { SET_NOTIFICATION } from '../../graphql/mutation/user';
import { UPDATE_SPECIAL_DAY } from '../../graphql/mutation/specialDays';

function DeliveryDaysTableRow({ classes, rowData, setEditMode, onDelete, rowIndex, openEditDialog }) {
  const { t } = useTranslation();
  const [isPanelOpen/* , setIsPanelOpen */] = React.useState(false);
  const [isNotActiveRow/* , setIsNotActiveRow */] = React.useState(false);
  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [updateDeliveryDay] = useMutation(UPDATE_SPECIAL_DAY);
  const [isEnabled, setEnabled] = React.useState(rowData.enabled);

  const dateStart = moment(rowData.applyForDate[0]);
  const dateEnd = rowData.applyForDate[1] ? moment(rowData.applyForDate[1]) : null;

  const handleEnabledStateChange = async ({ enabled }) => {
    try {
      setEnabled(enabled);

      const dayData = await updateDeliveryDay({
        variables: {
          dayId: rowData._id,
          day: { enabled },
        },
      });

      if (typeof dayData.data?.updateSpecialDays?.[0]?.enabled === 'boolean') {
        setEnabled(dayData.data.updateSpecialDays[0].enabled);
      }

      setNotification({
        variables: {
          timeout: 4000,
          message: t('specialDays.Day updated'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (e) {
      setEnabled(!enabled);
      console.error(e);
    }
  };

  return (
    <TableRow className={classNames(
      classes.tableBodyRow,
      (isNotActiveRow && !isPanelOpen) && classes.isNotActiveRow,
      isPanelOpen && classes.tableWithOpenedPanel,
      rowIndex % 2 === 1 && classes.tableBodyRowOdd
    )}
    >
      <TableCell />
      <TableCell className={classes.tableBodyCell}>
        <Switch
          checked={isEnabled}
          onChange={(e) => handleEnabledStateChange({ enabled: e.target.checked })}
        />
      </TableCell>
      <TableCell
        className={
          classNames(classes.tableBodyCell, !rowData.enabled && classes.disabledDate)
        }
      >
        <DateRange startDate={dateStart} endDate={dateEnd} delimiter={t('specialDays.dateRangeDelimiter')} />
        <IconButton
          className={classes.editTableItemButton}
          onClick={() => openEditDialog({
            _id: rowData._id,
            applyForDate: rowData.applyForDate,
            enabled: rowData.enabled,
            type: rowData.type,
            comment: rowData.comment,
          })}
        >
          <PencilIcon />
        </IconButton>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        {rowData.customers?.length ? (
          <Grid item xs={12}>
            {t('specialDays.customers')}:&nbsp;
            {rowData.customers?.slice(0, 5).map((customer) => customer.contactData.businessName).join(', ')}<br />
            {rowData.customers?.length > 5 && ` +${rowData.customers.length - 5} ${t('specialDays.more')}`}
          </Grid>) : null}
        {rowData.customerGroups?.length ? (
          <Grid item xs={12} >
            {t('specialDays.groups')}:&nbsp;
            {rowData.customerGroups?.slice(0, 5)?.map((group) => group.name).join(', ')}
            {rowData.customerGroups?.length > 5 && ` +${rowData.customerGroups.length - 5} ${t('specialDays.more')}`}
          </Grid>) : null}
        {!rowData.customers?.length && !rowData.customerGroups?.length && <span>{t('specialDays.All customers')}</span>}
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <ActionButton onClick={setEditMode}>
          <Tooltip
            title={t('specialDays.editTooltip')}
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <PencilIcon fontSize="small" />
          </Tooltip>
        </ActionButton>
        <ActionButton onClick={onDelete}>
          <Tooltip
            title={t('specialDays.deleteTooltip')}
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <TrashIcon fontSize="small" />
          </Tooltip>
        </ActionButton>
      </TableCell>
    </TableRow>
  );
}

DeliveryDaysTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  openEditDialog: PropTypes.func.isRequired,
};

export default DeliveryDaysTableRow;
