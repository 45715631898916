import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { mainInfoSchema } from 'components/Settings/ValidationSchemas';
import SettingsInput from 'components/Settings/SettingsInput';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginTop: theme.spacing(3),
    height: 50,
    borderRadius: 8,
    boxShadow: 'none'
  },
}));

export default function MainInformationEditForm({
  contactData,
  handleUpdateVendor,
  setEditMode
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmitMainInformation = (values) => {
    handleUpdateVendor({ contactData: { ...values } });
    setEditMode(false);
  };

  return (
    <Formik
      initialValues={{
        ...contactData
      }}
      enableReinitialize
      onSubmit={(values) => handleSubmitMainInformation(values)}
      validateOnChange={false}
      validationSchema={mainInfoSchema}
    >
      {({
        values, handleChange, handleSubmit, errors, handleBlur
      }) => (
        <Grid container item xs={5} direction="column" wrap="nowrap" alignItems="center">
          <SettingsInput
            value={values.businessName}
            name="businessName"
            label={t('settings.business')}
            onChange={handleChange}
            error={errors.businessName}
            handleBlur={handleBlur}
          />
          <SettingsInput
            value={values.city}
            name="city"
            label={t('common.city')}
            onChange={handleChange}
            error={errors.city}
            handleBlur={handleBlur}
          />
          <SettingsInput
            value={values.country}
            name="country"
            label={t('common.country')}
            onChange={handleChange}
            error={errors.country}
            handleBlur={handleBlur}
          />
          <SettingsInput
            value={values.houseNumber}
            name="houseNumber"
            label={t('common.house')}
            onChange={handleChange}
            error={errors.houseNumber}
            handleBlur={handleBlur}
          />
          <SettingsInput
            value={values.street}
            name="street"
            label={t('common.street')}
            onChange={handleChange}
            error={errors.street}
            handleBlur={handleBlur}
          />
          <SettingsInput
            value={values.zip}
            name="zip"
            label={t('common.post code')}
            onChange={handleChange}
            error={errors.zip}
            handleBlur={handleBlur}
          />
          <Button
            className={classes.actionButton}
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            {t('common.confirm')}
          </Button>
        </Grid>
      )}
    </Formik>
  );
}

MainInformationEditForm.propTypes = {
  contactData: PropTypes.object.isRequired,
  handleUpdateVendor: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
};
