import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    padding: theme.spacing(3),
    width: 232
  },
  modalTitle: {
    padding: 0
  },
  modalMessageContainer: {
    padding: `${theme.spacing(1.5)}px 0px ${theme.spacing(5)}px`
  },
  modalMessage: {
    color: theme.palette.text.secondary
  },
  modalBackdrop: {
    background: theme.palette.action.disabledBackground
  },
  modalActions: {
    padding: 0,
    alignItems: 'flex-end',
  }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EditConfirmationModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isOpen,
    onConfirm,
    onCancel,
    handleCloseModal,
    modalContentMessage,
    modalTitleMessage,
    modalConfirmMessage,
    modalDiscardMessage,
  } = props;

  const handleModalConfirmation = () => {
    handleCloseModal(false);
    onConfirm();
  };

  return (
    <Dialog
      classes={{ paper: classes.modalContainer }}
      BackdropProps={{ classes: { root: classes.modalBackdrop } }}
      open={isOpen}
      TransitionComponent={Transition}
      aria-labelledby="edit-modal-confirmation"
      aria-describedby="edit-modal-confirmation-description"
    >
      <DialogTitle className={classes.modalTitle} id="edit-modal-confirmation">
        {modalTitleMessage || t('common.modal title')}
      </DialogTitle>
      <DialogContent className={classes.modalMessageContainer}>
        <DialogContentText
          id="edit-modal-confirmation-description"
          className={classes.modalMessage}
        >
          {modalContentMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        <Button onClick={handleModalConfirmation} color="primary">
          {modalConfirmMessage || t('common.modal save changes')}
        </Button>
        <Button onClick={onCancel} color="primary">
          {modalDiscardMessage || t('common.modal discard changes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  modalContentMessage: PropTypes.string.isRequired,
  modalTitleMessage: PropTypes.string,
  modalConfirmMessage: PropTypes.string,
  modalDiscardMessage: PropTypes.string,
};

EditConfirmationModal.defaultProps = {
  modalTitleMessage: null,
  modalConfirmMessage: null,
  modalDiscardMessage: null,
};
