import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import EditRoundedIcon from '@material-ui/icons/EditRounded';

const useStyles = makeStyles(({ spacing }) => ({
  newsletterSendModalFromEmailButton: {
    boxShadow: 'none',
    borderRadius: 40,
    height: 48,
    margin: spacing(1, 0),
    padding: spacing(1.25, 2),
    border: '1px solid rgba(204, 0, 0, 0.16)',
    marginTop: spacing(1),
    color: '#CC0000',
  },
}));

export default function NoFromEmailWarning() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const handleChangeMoveToSettings = () => {
    history.push('/settings');
  };

  return (
    <Button
      className={classes.newsletterSendModalFromEmailButton}
      color="inherit"
      fullWidth
      endIcon={<EditRoundedIcon fontSize="small" />}
      onClick={handleChangeMoveToSettings}
    >
      {t('newsletter.from email input placeholder')}
    </Button>
  );
}
