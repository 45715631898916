import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import EmptyTableField from 'components/shared/EmptyTableField';

const useStyles = makeStyles(({ palette, spacing }) => ({
  customerChip: {
    margin: spacing(0.5),
    color: palette.text.primary,
  },
  showAllChipLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const DEFAULT_CUSTOMERS_QTY_TO_SHOW = 3;
const DEFAULT_CUSTOMERS_QTY_TO_SHOW_ALL = 20;

function GroupsAssignedCustomersCell({
  customers,
  handleOpenAllCustomers,
}) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [showAllSelected, setShowAllSelected] = useState(false);

  const { totalCustomers = 0, assignedCustomers = [] } = customers || {};

  const toggleShowAllSelected = () => {
    setShowAllSelected(!showAllSelected);
  };

  if (totalCustomers === 0) {
    return <EmptyTableField />;
  }

  return (
    <Grid container direction="column">
      {totalCustomers > 0 && (
        <>
          <Typography variant="caption" gutterBottom>
            {t('customers.title')}: {totalCustomers}
          </Typography>
          <Grid container wrap="wrap" direction="row">
            {(showAllSelected
              ? assignedCustomers.slice(0, DEFAULT_CUSTOMERS_QTY_TO_SHOW_ALL)
              : assignedCustomers.slice(0, DEFAULT_CUSTOMERS_QTY_TO_SHOW)
            ).map(customer => (
              <Chip
                key={customer._id}
                variant="outlined"
                size="small"
                color="primary"
                className={classes.customerChip}
                label={customer?.businessName}
              />
            ))}
            {assignedCustomers.length > DEFAULT_CUSTOMERS_QTY_TO_SHOW && (
              <Chip
                key="showAllSelectedChip"
                classes={{
                  label: classes.showAllChipLabel,
                }}
                label={
                  showAllSelected ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <>
                      <span style={{ marginRight: 4 }}>{`+ ${assignedCustomers.length - DEFAULT_CUSTOMERS_QTY_TO_SHOW}`}</span>
                      <VisibilityIcon fontSize="small" />
                    </>
                  )
                }
                onClick={toggleShowAllSelected}
              />
            )}
            {totalCustomers > DEFAULT_CUSTOMERS_QTY_TO_SHOW_ALL && showAllSelected && (
              <Button size="small" color="primary" onClick={handleOpenAllCustomers}>
                {t('groups.view all customers', { count: totalCustomers })}
              </Button>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

GroupsAssignedCustomersCell.propTypes = {
  customers: PropTypes.object,
  handleOpenAllCustomers: PropTypes.func.isRequired,
};

GroupsAssignedCustomersCell.defaultProps = {
  customers: {},
};

export default React.memo(
  GroupsAssignedCustomersCell,
  (prevProps, nextProps) =>
    isEqual(prevProps.customers, nextProps.customers)
);
