import * as Yup from 'yup';
import i18next from 'i18next';

const HEX_TYPE_REGEX = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

export const mainInfoSchema = Yup.object().shape({
  businessName: Yup.string().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  houseNumber: Yup.string().required(),
  street: Yup.string().required(),
  zip: Yup.string().required(),
});

// Vlad suggested to do this
export const getNewsletterVendorSettingsSchema = (isAdmin) => {
  const fromEmailOptional = Yup.string().test(
    'fromEmail',
    () => i18next.t('newsletter.vendor from email set error'),
    value => !value || (value && /^[a-zA-Z0-9._]*$/.test(value))
  );

  return Yup.object().shape({
    fromEmail: isAdmin ? fromEmailOptional : fromEmailOptional.required(() => i18next.t('newsletter.vendor from email set error')),
    fromName: Yup.string().required(),
  });
};

export const newsletterSchema = Yup.object().shape({
  fromEmail: Yup.string().test(
    'fromEmail',
    () => i18next.t('newsletter.vendor from email set error'),
    value => !value || (value && /^[a-zA-Z0-9._]*$/.test(value))
  ).required(() => i18next.t('newsletter.vendor from email set error')),
  fromName: Yup.string().required(),
});

export const contactsSchema = Yup.object().shape({
  additionalPhones: Yup.array().nullable().notRequired(),
  email: Yup.string().required().email(),
  name: Yup.string().nullable().required(),
  isNewContact: Yup.boolean().nullable(),
  password: Yup.string()
    .min(8, 'password should be not less than 8 characters')
    .when('isNewContact', {
      is: true,
      then: Yup.string().min(8, 'password should be not less than 8 characters').required()
    }),
  phone: Yup.string()
    .test(
      'phone',
      'Die eingegebene Nummer ist ungültig',
      value => (value && (value.length >= 5 && value.length <= 16))
      // FIXME: add country code checking and phone validation
      // value => (value.length >= 7 && value.length <= 16 && isValidPhoneNumber(value))
    ).required(),
});

export const deliveryDaySchema = Yup.object().shape({
  deliveryDays: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        enabled: Yup.boolean().required(),
      })
    )
    .required()
});

export const brandingSchema = Yup.object().shape({
  primaryColor: Yup.string().test(
    'primaryColor',
    'Invalid color style',
    value => !value || (value && HEX_TYPE_REGEX.test(value))
  ).nullable().notRequired(),
  secondaryColor: Yup.string().test(
    'primaryColor',
    'Invalid color style',
    value => !value || (value && HEX_TYPE_REGEX.test(value))
  ).nullable().notRequired(),
  fontColor: Yup.string().test(
    'primaryColor',
    'Invalid color style',
    value => !value || (value && HEX_TYPE_REGEX.test(value))
  ).nullable().notRequired(),
});
