import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

function CustomCheckbox({ checked, name, label, classes, setFieldValue, disabled = false }) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={event => setFieldValue(name, event.target.checked)}
          name={name}
          color="primary"
          disabled={disabled}
        />
      }
      label={
        <Typography className={classes?.premiumSelectorLabel}>
          {label}
        </Typography>
      }
    />
  );
}

CustomCheckbox.defaultProps = {
  disabled: false,
};

CustomCheckbox.propTypes = {
  checked: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  setFieldValue: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

export default CustomCheckbox;
