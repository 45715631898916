/* eslint-disable no-continue */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import NotSentIcon from '@material-ui/icons/SendOutlined';
import NotDeliveredIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import NotOpenedIcon from '@material-ui/icons/DraftsOutlined';
import NotClickedIcon from '@material-ui/icons/TouchAppOutlined';
import SentIcon from '@material-ui/icons/SendRounded';
import DeliveredIcon from '@material-ui/icons/MarkunreadMailboxRounded';
import OpenedIcon from '@material-ui/icons/DraftsRounded';
import ClickedIcon from '@material-ui/icons/TouchAppRounded';
import DelayIcon from '@material-ui/icons/TimelapseRounded';
import NoIssues from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import LogIcon from '@material-ui/icons/FormatListBulletedRounded';

import { SES_EVENT_TYPE } from 'components/Newsletter/localConstantsAndHelpers';
import RecipientStatusIcons from 'components/Newsletter/Recipients/RecipientStatusIcons';
import { Button } from '@material-ui/core';

const PROGRESSION_STATUS = {
  NOT_SENT: -1,
  SENT: 0,
  DELIVERED: 1,
  OPENED: 2,
  CLICKED: 3,
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  iconValid: {
    color: '#16db5a',
    paddingRight: spacing(0.2),
  },
  iconInvalid: {
    color: '#f54065',
    paddingRight: spacing(0.2),
  },
  iconDelayed: {
    color: '#3498DB',
  },
  logButton: {
    background: palette.background.button,
    marginLeft: '0.5rem',
    padding: '0.2rem 0.6rem',
    border: palette.border.lightGrey,
  },
  iconMuted: {
    opacity: 0.3,
    transition: 'opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1)',
    paddingRight: spacing(0.2),

    '&:hover': {
      opacity: 1,
    },
  },
  iconsContainer: {
    marginRight: spacing(1.5),
  }
})
);

export default function NewsletterStatusIcons ({
  contactStatus,
  sendOutStatus,
  handleOpenEventLog,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { events, deliveryStatus } = sendOutStatus || {};

  if (!deliveryStatus) {
    return (
      <RecipientStatusIcons
        contactStatus={contactStatus}
        hideValidIcons
        message={t('newsletter.filter options.not_sent')}
      />
    );
  }

  let step = PROGRESSION_STATUS.NOT_SENT;
  let error = null;

  for (let i = 0; i < events.length; i += 1) {
    switch (events[i].eventType) {
      case SES_EVENT_TYPE.SEND:
        step = PROGRESSION_STATUS.SENT;
        break;
      case SES_EVENT_TYPE.DELIVERY:
        step = PROGRESSION_STATUS.DELIVERED;
        break;
      case SES_EVENT_TYPE.OPEN:
        step = PROGRESSION_STATUS.OPENED;
        break;
      case SES_EVENT_TYPE.CLICK:
        step = PROGRESSION_STATUS.CLICKED;
        break;
      case SES_EVENT_TYPE.REJECT:
      case SES_EVENT_TYPE.BOUNCE:
      case SES_EVENT_TYPE.COMPLAINT:
        error = events[i];
        break;
      case SES_EVENT_TYPE.DELIVERY_DELAY:
        error = i === events.length - 1 ? events[i] : null;
        break;
      default:
        break;
    }

    if (error) {
      break;
    }
  }

  const isSent = step >= PROGRESSION_STATUS.SENT;
  const isDelivered = step >= PROGRESSION_STATUS.DELIVERED;
  const isOpened = step >= PROGRESSION_STATUS.OPENED;
  const isClicked = step >= PROGRESSION_STATUS.CLICKED;

  let FinalIcon = NoIssues;
  let finalIconTooltip = t('newsletter.delivery statuses.no_issues');
  let finalIconClass = classes.iconValid;

  if (error) {
    if (error.eventType === SES_EVENT_TYPE.DELIVERY_DELAY) {
      FinalIcon = DelayIcon;
      finalIconClass = classes.iconDelayed;
    } else {
      FinalIcon = ErrorIcon;
      finalIconClass = classes.iconInvalid;
    }
    finalIconTooltip = t(`newsletter.event types.${error.eventType}`);
  }

  return (
    <Grid
      container
      alignItems="center"
      className={classes.iconsContainer}
    >
      <Tooltip title={t(`newsletter.delivery statuses.${isSent ? 'sent' : 'not_sent' }`)}>
        {isSent ? (
          <SentIcon className={classes.iconValid} />
        ) : (
          <NotSentIcon className={classes.iconMuted} />
        )}
      </Tooltip>
      <Tooltip title={t(`newsletter.delivery statuses.${isDelivered ? 'delivered' : 'not_delivered' }`)}>
        {isDelivered ? (
          <DeliveredIcon className={classes.iconValid} />
        ) : (
          <NotDeliveredIcon className={classes.iconMuted} />
        )}
      </Tooltip>
      <Tooltip title={t(`newsletter.delivery statuses.${isOpened ? 'opened' : 'not_opened' }`)}>
        {isOpened ? (
          <OpenedIcon className={classes.iconValid} style={{ transform: 'translateY(2px)' }}/>
        ) : (
          <NotOpenedIcon className={classes.iconMuted} style={{ transform: 'translateY(2px)' }}/>
        )}
      </Tooltip>
      <Tooltip title={t(`newsletter.delivery statuses.${isClicked ? 'clicked' : 'not_clicked' }`)}>
        {isClicked ? (
          <ClickedIcon className={classes.iconValid} />
        ) : (
          <NotClickedIcon className={classes.iconMuted} />
        )}
      </Tooltip>
      <Tooltip title={finalIconTooltip}>
        <FinalIcon className={finalIconClass} />
      </Tooltip>
      <Tooltip title={t('newsletter.open log')}>
        <Button
          className={classes.logButton}
          startIcon={<LogIcon />}
          onClick={handleOpenEventLog}
        >
          {t('newsletter.open log')}
        </Button>
      </Tooltip>
    </Grid>
  );
}

NewsletterStatusIcons.propTypes = {
  contactStatus: PropTypes.object,
  sendOutStatus: PropTypes.object,
  handleOpenEventLog: PropTypes.func,
};

NewsletterStatusIcons.defaultProps = {
  contactStatus: {},
  sendOutStatus: {},
  handleOpenEventLog: () => {},
};
