import moment from 'moment/moment';
import { DATE_STATUS } from './constants';

export function getDateStatus(start, end, isNotActiveRow = false) {
  if (isNotActiveRow) {
    return DATE_STATUS.INACTIVE;
  } else if (moment().isBetween(start, end)) {
    return DATE_STATUS.ACTIVE;
  } else if (moment().isAfter(end)) {
    return DATE_STATUS.OUT_OF_DATE;
  } else if (moment().isBefore(start)) {
    return DATE_STATUS.UPCOMING;
  }

  return DATE_STATUS.INACTIVE;
}
